import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {NavLink, Link} from 'react-router-dom';
import API_CALLS from 'API_CALLS/index';
import ViewPlan from './viewPlan/index';
//import RequestPlan from './requestPlan/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import LayoutBuilder from 'LayoutBuilder';

const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const QUERY_SINGLE_PLAN = API_CALLS.TREATMENT_PLANS.QUERIES.single();
const {Container, Row, Column} = LayoutBuilder;


//"started": "1634851943362"


class TreatementPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testCurrentUser: {}
    }
  }

  componentDidMount(){
    window.UpdateTestCurrentUser = (NewTestCurrentUser)=>{
      if(this.state.testCurrentUser._id) this.setState({testCurrentUser: {}});
      else this.setState({testCurrentUser: NewTestCurrentUser});
    };
  }

  componentWillUnmount(){
  }


  render(){
    var {testCurrentUser={}} = this.state;
    var {data={}} = this.props;
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    if(testCurrentUser._id) currentUser = testCurrentUser; 
    currentUser.id = currentUser._id;
    var TP_STEP = currentUser.treatmentPlan.step;
    var treatmentPlan = window.GlobalUtil.deepGetFromString(currentUser, "treatmentPlan", {});
    var createdAt = window.GlobalUtil.deepGetFromString(treatmentPlan, "meta,createAt", null);
    if(createdAt) createdAt = `${window.GlobalUtil.dateBasic(createdAt, "DAY")}`
    var PlanPDF = ((treatmentPlan && treatmentPlan.planFiles) ? treatmentPlan.planFiles.filter(obj=>obj.type === "application/pdf")[0] : {});
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    const confirmDic = {
      "ENGLISH": "Yes",
      "ESPAÑOL": "Si"
    }
          
    return (
      <div id="UserTreatmentPlan" className="scrollZone">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT[0]}
            </Column> 
          </Row>
        </Container> 
        <ViewPlan />
        {
        /*
          ((treatmentPlan.planFiles && (treatmentPlan.planFiles.length > 0)) || (treatmentPlan.step === "PURCHASE") || (treatmentPlan.status === "ACTIVE"))
          ? <ViewPlan />
          : <RequestPlan/>
        */
        }        
      </div>
    );
  }
}


export default TreatementPlan;
      




