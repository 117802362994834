//import React from 'react';
const PAGE = [
	{
		"type": "div",
    //"className": "step",
		"content": `
      <div class="stepHeader">
        <div class="stepHeaderStep">Step 1.</div>
        <div class="stepHeaderTitle">Buy an Impression Kit</div>
      </div>
      <div class="stepPostHeader">Purchase an impression kit above. When you get it you'll take the impressions of your teeth, take some pictures, and send the impressions back to us.</div>
      
    `
	},
	{
		"type": "div",
    //"className": "step",
		"content": `
      <div class="stepHeader">
        <div class="stepHeaderStep">Step 2.</div>
        <div class="stepHeaderTitle">Get your treatment plan offer</div>
      </div>
      <div class="stepPostHeader">Once we've gotten your impressions, we will create a custom treatment plan offer just for you. This will show how long it will take and how your teeth will move.</div>
      
    `
	},
	{
		"type": "div",
    //"className": "step",
		"content": `<div class="stepHeader">
                    <div class="stepHeaderStep">Step 3.</div>
                    <div class="stepHeaderTitle">Get your aligners!</div>
                  </div>
                  <div class="stepPostHeader">Once you purchase your custom treatment plan, we'll start sending you aligners to straighten your teeth. Get started right now!</div>
              `
	}
];




	
export default PAGE;