import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import ALIGNER_GROUPS from "../ALIGNER_GROUPS/index";
import TREATMENT_PLANS from "../TREATMENT_PLANS/index";
import QUERIES from "./QUERIES";
import MUTATIONS from "./MUTATIONS";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();

const FORM_FIELDS = (obj={}) => {
  const MUTATION_EDIT = MUTATIONS.update();
  const QUERY_GET_ALIGNER_GROUPS = ALIGNER_GROUPS.QUERIES.all(`TPID`);
  const QUERY_GET_TREATMENT_PLANS = TREATMENT_PLANS.QUERIES.all(` `);
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">Aligner Group</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The aligner group this aligner goes with</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_ALIGNER_GROUPS,
            variables: {
              status: "ACTIVE"
            }
          })
          .then(result => {
            var usersOptions = result.data.alignerGroups.map((alignerGroup, index)=>{
              return({
                "value": alignerGroup._id,
                "title": alignerGroup._id
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...usersOptions])
            // console.log("usersOptions");
            // console.log(usersOptions,"\n\n");
                  
          });
        },
        name: "AGID",
        required: false,
        defaultValue: "",
        defaultValuePath: "AGID",
        errorMessage: "Aligner Group ID is required"
      },
      {
        title: <div className="formTitle"><span className="">Treatment Plan ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The treatment plan that goes with this aligner</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_TREATMENT_PLANS,
            variables: {
              status: "ACTIVE"
            }
          })
          .then(result => {
            var usersOptions = result.data.treatmentPlans.map((treatmentPlan, index)=>{
              return({
                "value": treatmentPlan._id,
                "title": treatmentPlan._id
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...usersOptions])
            // console.log("usersOptions");
            // console.log(usersOptions,"\n\n");
                  
          });
        },
        name: "TPID",
        required: false,
        defaultValue: "",
        defaultValuePath: "TPID",
        errorMessage: "Treatment Plan ID is required"
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: false,
        defaultValue: "PENDING",
        defaultValuePath: "status"
      },
      {
        title: <div className="formTitle"><span className="">Files</span></div>,
        type: "fileUploadList",
        name: "files",
        maxNumber: 10,
        required: false,
        defaultValue: "",
        defaultValuePath: "files",
        maxWidth: "150px",
        filePath: [
          "USERS",
          obj.UID,
          "TREATMENT_PLANS",
          obj.TPID,
          "ALIGNERS"
        ],
        onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
          newFileList, 
          obj.id,
          "files", 
          MUTATION_EDIT,
          window.CurObj,
          ()=>{
            window.APIGetAgain()
          })
      }
    ]
  )
};






export default FORM_FIELDS;