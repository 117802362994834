//import React from 'react';
const PAGE = [
  {
    "content": `Complete para completar su solicitud de plan de tratamiento`
  },
  {
    "content": `Envíe cada una de las siguientes imágenes y escriba lo que desee en la "Queja principal". Esto nos dará toda la información que necesitamos para crear su plan personal de alineadores dentales.`
  },


  {
    "type": "div",
    "content": `<div><strong>Imagen 1:</strong> Selfy serio</div>`
  },
  {
    "content": `Tome un selfi de frente sin sonreír. Siga la imagen de ejemplo que se muestra.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Imagen 2:</strong> Selfy sonriente</div>`
  },
  {
    "content": `Tome un selfi de frente sin sonreír. Siga la imagen de ejemplo que se muestra.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Imagen 3:</strong> Perfil lateral</div>`
  },
  {
    "content": `Tome un selfi de lado sin sonreír. Siga la imagen de ejemplo que se muestra.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Imagen 1:</strong> Dientes delanteros</div>`
  },
  {
    "content": `Tome un selfi con el expansor de boca mostrando la parte FRONTAL de sus dientes mientras muerde hacia abajo.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Imagen 2:</strong> Dientes a la izquierda</div>`
  },
  {
    "content": `Tome un selfi con el expansor de boca mostrando el LADO IZQUIERDO de sus dientes mientras muerde.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Imagen 3:</strong> Dientes a la derecha</div>`
  },
  {
    "content": `Tome un selfi con el expansor de boca mostrando el LADO DERECHO de sus dientes mientras muerde.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Imagen 4:</strong> Los dientes de arriba</div>`
  },
  {
    "content": `Tome un selfi con el expansor de boca mostrando la PARTE SUPERIOR de sus dientes con la boca abierta.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Imagen 5:</strong> Los dientes de abajo</div>`
  },
  {
    "content": `Tome un selfi con el expansor de boca mostrando la PARTE INFERIOR de los dientes con la boca abierta.`
  },
  



  {
    "content": `Queja principal`
  },
  {
    "content": `¿Cuál es tu principal queja con tu sonrisa? ¿Es un grupo específico de dientes lo que le molesta o algo más general? Siéntase libre de ser tan detallado como desee.`
  }
];




  
export default PAGE;