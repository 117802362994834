//import React from 'react';
const PAGE = [
  {
    "content": `Create`
  },
  {
    "content": "Treatment Plan Offer"
  },
  {
    "content": "Cancel"
  },
  {
    "content": "Submit"
  },
  {
    "content": "Updating Order"
  },
  {
    "content": "Please stand by"
  },
  {
    "content": "Create Treatment Plan Offer"
  },
  {
    "content": "Total Number of Aligners"
  },
  {
    "content": "Estimation of the total number of aligners the customer will get."
  },
  {
    "content": "Plan Files"
  },
  {
    "content": "Drag the Offer PDF as well as any videos here."
  },
  {
    "content": "Notes"
  },
  {
    "content": "Notes you would like to leave for the patient about his treatment plan."
  },
  {
    "content": "Uploading"
  },
  {
    "content": "Please stand by"
  },
  {
    "content": "Close"
  },
  {
    "content": "Saving"
  },
  {
    "content": "Submit"
  }
];




  
export default PAGE;