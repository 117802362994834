import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import MUTATIONS from "./MUTATIONS";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();


const FORM_FIELDS = (obj={})=>{
  const MUTATION_EDIT = MUTATIONS.update();        
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">First Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a first name</span></div>,
        type: "text",
        name: "firstName",
        required: true,
        defaultValue: "",
        defaultValuePath: "firstName",
        errorMessage: "Each user needs a First Name"
      },
      {
        title: <div className="formTitle"><span className="">Last Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a last name</span></div>,
        type: "text",
        name: "lastName",
        required: true,
        defaultValue: "",
        defaultValuePath: "lastName",
        errorMessage: "Each user needs a Last Name"
      },
      {
        title: <div className="formTitle"><span className="">Email Address</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a last name</span></div>,
        type: "email",
        name: "email",
        required: true,
        defaultValue: "",
        defaultValuePath: "email",
        checkAvailable: true,
        errorMessage: "Each user needs an Email"
      },
      {
        title: <div className="formTitle"><span className="">Phone Number</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a phone number</span></div>,
        type: "phone",
        name: "phone",
        required: false,
        defaultValue: "",
        defaultValuePath: "phone",
        errorMessage: "Each user needs a Phone Number"
      },
      {
        title: <div className="formTitle"><span className="">Password</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Password for a user</span></div>,
        type: "password",
        name: "newPassword",
        required: true,
        defaultValue: "",
        defaultValuePath: "newPassword"
      }
    ]
  )
};





export default FORM_FIELDS;