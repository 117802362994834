//import React from 'react';
const PAGE = [
  {
    //NUM 0
    "content": "Ingrese el correo electrónico asociado con tu cuenta y le enviaremos un correo electrónico con instrucciones para restablecer tu contraseña."
  },
  {
    //NUM 1
    "content": "Enviar instrucciones"
  },
  {
    //NUM 2
    "content": "Tu correo electrónico de restablecimiento ha sido enviado"
  },
  {
    //NUM 3
    "content": "Vaya a tu dirección de correo electrónico para encontrar las instrucciones para restablecer la contraseña. Algunos correos electrónicos pueden tardar unos minutos en llegar. Asegúrese de revisar tu correo no deseado y otras carpetas antes de enviar la solicitud nuevamente."
  },
  {
    //NUM 4
    "content": "Gracias"
  },
  {
    //NUM 6
    "content": "Oh no, algo salió mal",
  },
  {
    //NUM 7
    "content": "Parece que nuestros servidores de correo electrónico pueden estar inactivos por mantenimiento. Vuelva a intentarlo más tarde para restablecer tu contraseña.",
  },
  {
    //NUM 8
    "content": "Perdón",
  },
  {
    //NUM 8
    "content": "Cerrar",
  }
];




  
export default PAGE;