//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="pageHeader"><i class="fas fa-user"></i> Perfil</h1>`
	},
  {
    "content": `Mi Perfil`
  },
  {
    "content": `Nombre: `
  },
  {
    "content": `Apellido: `
  },
  {
    "content": `Teléfono: `
  },
  {
    "content": `Email: `
  },
  {
    "content": `Email confirmado:`
  },
  {
    "content": `Fotos de Progess`
  },
  {
    "content": `Dirección de Envío`
  },
  {
    "content": `Dirección 1:`
  },
  {
    "content": `Dirección 2:`
  },
  {
    "content": `Ciudad:`
  },
  {
    "content": `Estado:`
  },
  {
    "content": `Código postal:`
  },
  {
    "content": `País:`
  },
  {
    "content": `Mi configuración`
  },
  {
    "content": `Enviar correos electrónicos promocionales`
  }
];








	
export default PAGE;








