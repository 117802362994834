//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_DELETE_FILE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteFile($filename: String!) {
    deleteS3File(filename: $filename)
  }
`;



const MUTATION_GET_S3_SIGN = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation GetS3Sign(
    $filename: String!,
    $filetype: String!,
  ) {
     getS3Sign(input:{
        filename: $filename,
        filetype: $filetype,
      }){ 
        ${whatToPull}
      }
  }
`;


const MUTATIONS = {
  deleteS3File: MUTATION_DELETE_FILE,
  getS3Sign: MUTATION_GET_S3_SIGN
}


export default MUTATIONS;