import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import USERS from "../USERS/index";
import ORDERS from "../ORDERS/index";
// import QUERIES from "./QUERIES";
import MUTATIONS from "./MUTATIONS";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();



const FORM_FIELDS = (obj={})=>{
  const QUERY_GET_ORDERS = ORDERS.QUERIES.all(`UID`);
  const MUTATION_EDIT = MUTATIONS.update();
  const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  return [
    {
      title: <div className="formTitle"><span className="">ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Product ID CANT BE CHANGED</span></div>,
      type: "text",
      name: "id",
      required: false,
      editable: false,
      defaultValue: "",
      defaultValuePath: "_id",
      hide: true
    },
    {
      title: <div className="formTitle"><span className="">User ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every task</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_USERS
        })
        .then(result => {
          var Options = result.data.users.map((user, index)=>{
            return({
              "value": user._id,
              "title": user.email
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...Options])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "UID",
      required: true,
      defaultValue: "",
      defaultValuePath: "UID",
      errorMessage: "User ID is required"
    },
    {
      title: <div className="formTitle"><span className="">Order ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Should have an user attached to every task</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_ORDERS
        })
        .then(result => {
          var Options = result.data.orders.map((user, index)=>{
            return({
              "value": user._id,
              "title": user._id
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...Options])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "OID",
      required: false,
      defaultValue: "",
      defaultValuePath: "OID"
    },
    {
      title: <div className="formTitle"><span className="">Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "PENDING",
          "title": "PENDING"
        },
        {
          "value": "PAUSED",
          "title": "PAUSED"
        },
        {
          "value": "CANCELED",
          "title": "CANCELED"
        }
      ],
      required: false,
      defaultValue: "ACTIVE",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Laboratory</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Who is creating this treatment plan?</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.USERS.QUERIES.all("email firstName lastName"),
          variables: {
            "status": "ACTIVE",
            "manual": [
              {
                path: "role",
                listValues: JSON.stringify(["LABORATORY", "ADMIN"])
              }
            ]
          }
        })
        .then(result => {
          var doctors = result.data.users.map((doc, index)=>{
            return({
              "value": doc._id,
              "title": `${doc.firstName} ${doc.lastName} - ${doc.email}`
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...doctors])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "LABORATORY",
      required: false,
      defaultValue: "",
      defaultValuePath: "LABORATORY",
      errorMessage: "Who to email is required"
    },
    {
      title: <div className="formTitle"><span className="">Approving Doctor</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What doctor will approve this once it's been uploaded by the lab?</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.USERS.QUERIES.all("email firstName lastName"),
          variables: {
            "status": "ACTIVE",
            "manual": [
              {
                path: "role",
                listValues: JSON.stringify(["DOCTOR", "ADMIN"])
              }
            ]
          }
        })
        .then(result => {
          var doctors = result.data.users.map((doc, index)=>{
            return({
              "value": doc._id,
              "title": `${doc.firstName} ${doc.lastName} - ${doc.email}`
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...doctors])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "DOCTOR",
      required: false,
      defaultValue: "",
      defaultValuePath: "DOCTOR",
      errorMessage: "Who to email is required"
    },
    {
      title: <div className="formTitle"><span className="">Notes</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Notes for this plan.</span></div>,
      type: "textArea",
      name: "notes",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "notes",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Step</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What step is the Treatment Plan at?</span></div>,
      type: "select",
      name: "step",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "REQUEST",
          "title": "REQUEST"
        },
        {
          "value": "UPLOAD",
          "title": "UPLOAD"
        },
        {
          "value": "APPROVE",
          "title": "APPROVE"
        },
        {
          "value": "PURCHASE",
          "title": "PURCHASE"
        },
        {
          "value": "REQUEST_FULL",
          "title": "REQUEST_FULL"
        },
        {
          "value": "UPLOAD_ALIGNERS",
          "title": "UPLOAD_ALIGNERS"
        },
        {
          "value": "SCHEDULE_ALIGNERS",
          "title": "SCHEDULE_ALIGNERS"
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "FINISHED",
          "title": "FINISHED"
        }
      ],
      required: false,
      defaultValue: "",
      defaultValuePath: "step"
    },
    {
      title: <div className="formTitle"><span className="">Reject Step Notes</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Notes for why this current step was rejected.</span></div>,
      type: "textArea",
      name: "rejectStepNotes",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "rejectStepNotes",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Plan Files</span></div>,
      type: "fileUploadList",
      name: "planFiles",
      maxNumber: 10,
      required: false,
      defaultValue: "",
      defaultValuePath: "planFiles",
      maxWidth: "150px",
      filePath: [
        "USERS",
        obj.id,
        "TREATMENT_PLANS",
        obj.TPID,
        "FILES"
      ],
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "planFiles", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain()
        })
    },


    
    {
      title: <div className="formTitle"><span className="">Aligner Schdule</span></div>,
      subTitle: <div className="formSubTitle">See when they started their aligners, how many they had, what step they're at and more.</div>,
      type: "conditional",
      styles: {"padding": "10px"},
      condition: (curObj)=>{            
        //if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
        return true;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">Number of Top Aligners</span></div>,
          subTitle: <div className="formSubTitle">The total number of top aligners the customer will get.</div>,
          type: "number",
          min: 1,
          max: 1000,
          name: "alignersSchedule,numOfAlignersTop",
          placeholder: 0,
          required: false,
          defaultValue: 0,
          defaultValuePath: "alignersSchedule,numOfAlignersTop"
        },
        {
          title: <div className="formTitle"><span className="">Number of Bottom Aligners</span></div>,
          subTitle: <div className="formSubTitle">The total number of bottom aligners the customer will get.</div>,
          type: "number",
          min: 1,
          max: 1000,
          name: "alignersSchedule,numOfAlignersBottom",
          placeholder: 0,
          required: false,
          defaultValue: 0,
          defaultValuePath: "alignersSchedule,numOfAlignersBottom"
        },
        {
          title: <div className="formTitle"><span className="">When their treatment started.</span></div>,
          subTitle: <div className="formSubTitle">This is when they clicked the button and started their aligner treatment.</div>,
          type: "datePicker",
          name: "alignersSchedule,started",
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
        {
          title: <div className="formTitle"><span className=""></span></div>,
          subTitle: <div className="formSubTitle"></div>,
          type: "customInput",
          name: "alignersSchedule,started",
          customFunction: (that)=>{
            var OnRecreateAllAlignerPlan = ()=>{
              window.Client.mutate({ //API UPDATE
                mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.startTreatmentPlan(),
                variables: {
                  id: that.props.currentObject.ID,
                  started: `${that.props.value}`
                }
              })
              .then((obj)=>{
                window.GlobalUtil.DialogStack.closeAll()
              })
              .catch((error)=>{
                console.log("error updating user");
                console.log(error,"\n\n");  
              });
            };
            return(
              <div className="">
                <button className="button button1 btn-color5" onClick={OnRecreateAllAlignerPlan}>RECREATE ALL SCHEDULE TO NEW TIME?</button>
              </div>
            )
          },
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
        {
          title: <div className="formTitle"><span className="">Aligner History</span></div>,
          subTitle: <div className="formSubTitle">This is the schdule of when they should start using their aligners and when they need to go to the next one.</div>,
          type: "customInput",
          name: "alignersSchedule,alignerHistory",
          customFunction: (that)=>{
            //console.log('that', that);
            //console.log('that.props.value', that.props.value);
            if(!that.props.value) return(
              <div>Test</div>
            )
            return(
              <div className="">
                {
                  that.props.value.map((obj, index)=>{
                    return(
                      <div className="" key={index}>
                        <div className="">Aligner Step: {obj.number}</div>
                        <div className="">Aligner Top to Use: {obj.alignerTop}</div>
                        <div className="">Aligner Bottom to Use: {obj.alignerBottom}</div>
                        <div className="">Aligner Bottom to Use: {obj.alignerBottom}</div>
                        <div className="">When to start using these aligners: {obj.startedAt}</div>
                        <div className="">When to finish using these aligners: {obj.endsAt}</div>
                        <div className="">Progress Pictures:</div>
                        <div className="pictures">
                          {
                            obj.pictures.map((picObj, index)=>{
                              return(
                                <div className="picture" key={index}>
                                  <img src={picObj.url} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          },
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
      ]
    },


    {
      title: <div className="formTitle"><span className="">History of Treatment Plan</span></div>,
      subTitle: <div className="formSubTitle">Save a history of when the lab created, uploaded, got paid, and more.</div>,
      type: "conditional",
      styles: {"padding": "10px"},
      condition: (curObj)=>{            
        //if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
        return true;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">Uploaded Treatment Plan Offer</span></div>,
          subTitle: <div className="formSubTitle">What was the date when the Lab Uploaded the Treatment Plan Offer?</div>,
          type: "datePicker",
          name: "HISTORY,LABORATORY,UPLOADED_OFFER",
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
        {
          title: <div className="formTitle"><span className="">Paid for Treatment Plan Offer</span></div>,
          subTitle: <div className="formSubTitle">What day did we pay the lab for uploading this treatment plan offer?</div>,
          type: "datePicker",
          name: "HISTORY,LABORATORY,PAID_FOR_OFFER",
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
        {
          title: <div className="formTitle"><span className="">Uploaded FULL Treatment Plan</span></div>,
          subTitle: <div className="formSubTitle">What was the date when the Lab Uploaded the FULL Treatment Plan.</div>,
          type: "datePicker",
          name: "HISTORY,LABORATORY,UPLOADED_FULL",
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
        {
          title: <div className="formTitle"><span className="">Paid for FULL Treatment Plan</span></div>,
          subTitle: <div className="formSubTitle">What day did we pay the lab for the FULL treatment plan?</div>,
          type: "datePicker",
          name: "HISTORY,LABORATORY,PAID_FOR_FULL",
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
        {
          title: <div className="formTitle"><span className="">Doctor Approved this Plan</span></div>,
          subTitle: <div className="formSubTitle">What day did the doctor approve this plan?</div>,
          type: "datePicker",
          name: "HISTORY,DOCTOR,APPROVED_PLAN",
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
        {
          title: <div className="formTitle"><span className="">Paid for Doctor Approval</span></div>,
          subTitle: <div className="formSubTitle">What day did we pay the doctor for the approval of the plan?</div>,
          type: "datePicker",
          name: "HISTORY,DOCTOR,PAID_FOR_APPROVAL",
          placeholder: 0,
          required: false,
          defaultValue: 0
        },
      ]
    },
    {
      title: <div className="formTitle"><span className="">Duration</span></div>,
      subTitle: <div className="formSubTitle">THIS WILL AUTO GENORATE WHEN THE USER BUYS THE PRODUCT AND FINISH WHEN WE FINISH.</div>,
      type: "conditional",
      condition: (curObj)=>{            
        //if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
        return true;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">Start At</span></div>,
          subTitle: <div className="formSubTitle">When this started after customer paid for it.</div>,
          type: "datePicker",
          startOfDay: true,
          min: 0,
          max: 1000,
          //name: "duration,startAt",
          name: "started",
          placeholder: 0,
          required: false,
          defaultValue: 0,
          defaultValuePath: "started"
          //defaultValuePath: "duration,startAt"
        },
        {
          title: <div className="formTitle"><span className="">End At</span></div>,
          subTitle: <div className="formSubTitle">When the customer has finished paying for everything.</div>,
          type: "datePicker",
          min: 0,
          max: 1000,
          name: "duration,endAt",
          placeholder: 0,
          required: false,
          defaultValue: 0,
          defaultValuePath: "duration,endAt"
        }
      ]
    },
    {
      title: <div className="formTitle"><span className="">Progress Logs</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Please list off every action taken. From contacting the user, to sending emails, to contacting doctors and more. ANY AND EVERY action taken for this treatment plan.</span></div>,
      type: "notesList",
      name: "progressLogs",
      defaultValue: "",
      required: false,
      defaultValuePath: "progressLogs",
      errorMessage: "This is required"
    }
  ]
};



export default FORM_FIELDS;