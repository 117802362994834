import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  CMIDS
  UID
  total
  checkNum
  voided
  paid
  paidDate
  mailedDate
  status
  address{
    ${MUTATION_FIELDS_DEFAULT.ADDRESS}
  }
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;

const FULL_MUTATION_FIELDS = (include=["user"])=>{
  var IncludeDic = {
    user: `
      user{
        _id
        email
        firstName
        lastName
      }
    `
  };
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  include.map((key, index)=>{
    if(IncludeDic[key]) FullMutation += IncludeDic[key];
  })
  return FullMutation;
};


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $CMIDS: [String],
  $UID: String,
  $total: Float,
  $checkNum: Int,
  $voided: Boolean,
  $paid: Boolean,
  $paidDate: String,
  $mailedDate: String,
  $status: String,
  $address: AddressInput
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  CMIDS: $CMIDS,
  UID: $UID,
  total: $total,
  checkNum: $checkNum,
  voided: $voided,
  paid: $paid,
  paidDate: $paidDate,
  mailedDate: $mailedDate,
  status: $status,
  address: $address
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "CMIDS": "string",
  "UID": "string",
  "total": "number",
  "checkNum": "number",
  "voided": "boolean",
  "paid": "boolean",
  "paidDate": "string",
  "mailedDate": "string",
  "status": "string",
  "address": CLEAN_FIELDS_DEFAULT.ADDRESS,
  "meta": CLEAN_FIELDS_DEFAULT.META
}


const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    "path": "user.firstName,user.lastName",
    "type": "VALUE",
    "toolTip": true,
    "name": "User Name",
    "sortable": false
  },
  {
    path: "UID",
    type: "ID",
    name: "UID",
    searchable: true
  },
  {
    path: "voided",
    type: "BOOLEAN",
    name: "Voided",
    searchable: true
  },
  {
    path: "paid",
    type: "BOOLEAN",
    name: "Paid",
    searchable: true
  },  
  {
    "path": "checkNum",
    "type": "VALUE",
    "name": "Check Number",
    searchable: true
  },
  {
    path: "total",
    type: "MONEY",
    name: "Total Paid",
    searchable: true
  },
  {
    path: "paidDate",
    type: "DATE",
    name: "Paid Date"
  },
  {
    path: "mailedDate",
    type: "DATE",
    name: "Mailed Date"
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  }
];












const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME":"ID"
          },
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "NAME": "User ID (UID)",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "BUTTON_TITLE": "User",
              "ID_PATH": "UID",
              "TITLE": "User", //TITLE OF SLIDE OUT
              "APINAME": "USERS",
              "EDITABLE": true
            }
          },
          {
            "PATH": "paid",
            "TYPE": "BOOLEAN",
            "NAME": "Check paid and sent?"
          },
          {
            "PATH": "CMIDS",
            "TYPE": "ARRAY_STRINGS",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Commission", //TITLE OF SLIDE OUT
              "APINAME": "COMMISSIONS",
              "ID_PATH": "CMIDS"
            },
            "NAME": "Commission IDS (CMIDS)"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "checkNum",
            "TYPE": "STRING",
            "NAME": "Check Number"
          },
          {
            "PATH": "total",
            "TYPE": "MONEY",
            "NAME": "Check Total"
          },
          {
            "PATH": "paidDate",
            "TYPE": "DATE",
            "FORMAT": "n/d/Y @ H:mA",
            "NAME": "Date Paid"
          },
          {
            "PATH": "mailedDate",
            "TYPE": "DATE",
            "FORMAT": "n/d/Y @ H:mA",
            "NAME": "Date Mailed"
          },
          {
            "PATH": "voided",
            "TYPE": "BOOLEAN",
            "NAME": "Has check been voided?"
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.ADDRESS(),
  PREVIEW_FORM_TEMPLATES.META()
];


const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  //if(!curCleanObj.whoToEmail) delete curCleanObj.whoToEmail;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  //if(!curCleanObj.whoToEmail) delete curCleanObj.whoToEmail;
  return curCleanObj;
}


const COMS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default COMS;
export {
  DEFAULT_MUTATION_FIELDS,
  FULL_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};