//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';




const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreateReferral(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createReferral(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation UpdateReferral(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateReferral(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_COMPLETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CompleteReferral(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    completeReferral(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteReferral(
    $id: String,
  ) {
    deleteReferral(id: $id)
  }
`;


const MUTATION_DELETE_FOREVER = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteReferralRemove(
    $id: String,
  ) {
    deleteReferralRemove(id: $id)
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteReferralList(
    $ids: [String]
  ) {
    deleteReferralList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateReferral(
    $id: String,
  ) {
    deactivateReferral(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateReferralList(
    $ids: [String]
  ) {
    deactivateReferralList(ids: $ids)
  }
`;



const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneReferral(
    $id: String,
  ) {
    cloneReferral(id: $id){
      _id
    }
  }
`;


const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneReferralList(
    $ids: [String]
  ) {
    cloneReferralList(ids: $ids)
  }
`;



const MUTATIONS = {
  create: MUTATION_ADD,
  update: MUTATION_EDIT,
  complete: MUTATION_COMPLETE,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  deleteRemove: MUTATION_DELETE_FOREVER,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;