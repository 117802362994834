//import React from 'react';
const PAGE = [
  {
    "content":`Carrito`,
  },
  {
    "content":`Cancelar`,
  },
  {
    "content":`Resumen`,
  },
  {
    "content":`Cupón / Código promocional`,
  },
  {
    "content":`Aplicar`,
  },
  {
    "content":`Subtotal`,
  },
  {
    "content":`Descuento`,
  },
  {
    "content":`Pago Inicial`,
  },
  {
    "content":`Impuesto`,
  },
  {
    "content":`Envío`,
  },
  {
    "content":`Total`
  }
];
 
 
 

  
export default PAGE;