import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/price.scss';
import TemplatePage from 'website/templates/templatePage/index.js';
//import {NavLink, Link} from 'react-router-dom';
import API_CALLS from 'API_CALLS/index';
import Main2 from 'global/img/main_pngs/main2.png';
import Main2_500 from 'global/img/main_pngs/main2_500.png';
import Main2_400 from 'global/img/main_pngs/main2_400.png';
import Main2_250 from 'global/img/main_pngs/main2_250.png';
import Affirm from 'global/img/payments/affirm_slim.png';
import Payment1 from 'global/img/payments/amex.png';
import Payment2 from 'global/img/payments/stripe.png';
import Payment3 from 'global/img/payments/affirm.png';
import Payment4 from 'global/img/payments/cashapp.png';
import Payment5 from 'global/img/payments/discover.png';
import Payment6 from 'global/img/payments/googlepay.png';
import Payment7 from 'global/img/payments/amazonpay.png';
import Payment8 from 'global/img/payments/afterpay.png';
import Payment9 from 'global/img/payments/visa.png';
import Payment10 from 'global/img/payments/master.png';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
import CONTENTS from "./CONTENTS";
import LayoutBuilder from 'LayoutBuilder';



const {Container, Row, Column} = LayoutBuilder;
class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   //this.state = {...this.state,
  //   //};    
  // }

  didMount(){
    this.getProducts("NORMAL", (products=[])=>{
      this.setState({product1: products[0]});
    });
    // this.getProducts("SUBSCRIPTION", (products=[])=>{            
    //   this.setState({product2: products[0]});
    // });
  }

  willUnmount(){
  }


  getProducts = (type="NORMAL", callBack=()=>{}) => {
    //return true; //FOR NOW DON'T GET PRODUCTS JUST HAVE THEM CONTACT US
    if(!window.Client || !window.Client.query) return false;          
    if(!this.gettingProductsRef) this.gettingProductsRef = {};
    if(this.gettingProductsRef[type]) return true;
    this.gettingProductsRef[type] = true;
    this.setState({loading: true});
    return window.Client.query({
      query: API_CALLS.PRODUCTS.QUERIES.all(),
      variables: {
        type: type,
        manual: [
          {
            path: "language",
            val: window.Session.Language
          },
          {
            path: "ref",
            val: "TREATMENT_PLAN"
          }
        ],
        status: "ACTIVE",
      }
    })
    .then(result => {
      //console.log("result");
      //console.log(result,"\n\n");
      //if(!this._ismounted) return;
      callBack(result.data.products)
      this.setState({loading: false});
      return true;      
    })
    .catch((error)=>{
      console.log("catch ", error);
      return false;     
    })
  }

  render(){
    var {product1, product2} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PagePrice">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div id="TopMainSection" className="containerSection backgroundImage3">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="bigPicture">
                  <img 
                    src={Main2_500} 
                    alt={CONTENT.TopMainSectionImages[0].alt}
                    srcSet={`
                      ${Main2_250} 250w,
                      ${Main2_400} 400w,
                      ${Main2_500} 500w,
                      ${Main2} 800w
                    `}
                    sizes="(max-width: 500px) 250px, (max-width: 767px) 400px, (max-width: 990px) 500px, 100vw"
                  />
                </div>
              </Column>
              <Column className="contentCenter topTextZone" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                <div className="">
                  {CONTENT.TopMainSection[0]}
                </div>
              </Column>
            </Row>
          </Container>
        </div>
         
        <div id="HowToPay">
          <Container>
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="text-center">
                  {CONTENT.HowToPay[0]}
                  <br/>
                </div>
              </Column>
            </Row>
            <Row id="PaymentOptions" className="paymentTypes text-center">
              <Column className="paymentType" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  {CONTENT.PaymentOptions[0]}
                  <div className="buttonGroup center">
                    {
                      product1 && <button className={"button button1 dontCloseCart"} onClick={()=>{
                          window.GlobalUtil.addToCart({product: product1, replaceAll: true}, ()=>{
                            window.GlobalUtil.triggerEvent("cartEvent", "show");
                          });
                        }}>
                        {CONTENT.PaymentOptionsButtons[0]}
                      </button>
                    }
                    {/*<button className={"button button2"} style={{"margin": "20px 5px 0"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT[10]}</button>*/}
                    {/*<NavLink className={"button button1"} to={"/getstarted"}>{}</NavLink>*/}
                  </div>
                  {CONTENT.PaymentOptionsButtons[2]}
                </div>
              </Column>
              <Column className="separatorColumn" col="" xs="" sm="" md="" lg="" xl=""><div className="separator"></div></Column>
              <Column className="paymentType" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  <div className="paymentLogo"><img className="affirmLogoBig" src={Affirm} alt={CONTENT.PaymentTypesImages[2].alt} /></div>
                  {CONTENT.PaymentOptions[1]}
                  <div className="buttonGroup center">
                    {
                      product1 && <button className={"button button2 dontCloseCart"}  onClick={()=>{
                          window.GlobalUtil.addToCart({product: product1, replaceAll: true}, ()=>{
                            window.GlobalUtil.triggerEvent("cartEvent", "show");
                          });
                        }}>
                        {CONTENT.PaymentOptionsButtons[1]}
                      </button>
                    }
                    {/*
                      product2 && <button className={"button button1 dontCloseCart"} style={{"margin": "20px 5px 0"}} onClick={()=>{
                          window.GlobalUtil.addToCart({product: product2, replaceAll: true}, ()=>{
                            window.GlobalUtil.triggerEvent("cartEvent", "show");
                          });
                        }}>
                        {CONTENT[21]}
                      </button>
                    */}
                    {/*<button className={"button button2"} style={{"margin": "20px 5px 0"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT[15]}</button>*/}
                    {/*<NavLink className={"button button2"} to={"/getstarted"}>{}</NavLink>*/}
                  </div>
                  {CONTENT.PaymentOptionsButtons[2]}
                </div>
              </Column>
            </Row>
          </Container>
          <Container style={{"paddingTop":"10px","paddingBottom":"30px","color":"#7d7d7d"}}>
            <Row className=" text-center">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">   
                  {CONTENT.PaymentOptions[2]}
                </Column>
              </Row>  
          </Container>
        </div>
        <TreatmentPlanBar/>    

        <div className="backgroundColor1">
          <Container id="PaymentTypes">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  {CONTENT.PaymentTypes[0]}
                  <div className="buttonGroup">
                    <button className={"button button2"} style={{"margin": "20px 5px 0"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT.PaymentTypesButton[0]}</button>
                    {/*<NavLink className={"button button1"} to={"/getstarted"}>{}</NavLink>*/}
                  </div>
                  <br/>
                </div>
              </Column>
              <Column className="" col="" xs="12" sm="12" md="3" lg="4" xl="5">
                <div className="paymentLogos">
                  <div className="paymentLogo"><img src={Payment1} alt={CONTENT.PaymentTypesImages[0].alt} /></div>
                  <div className="paymentLogo"><img src={Payment2} alt={CONTENT.PaymentTypesImages[1].alt} /></div>
                  <div className="paymentLogo"><img src={Payment3} alt={CONTENT.PaymentTypesImages[2].alt} /></div>
                  <div className="paymentLogo"><img src={Payment4} alt={CONTENT.PaymentTypesImages[3].alt} /></div>
                  <div className="paymentLogo"><img src={Payment5} alt={CONTENT.PaymentTypesImages[4].alt} /></div>
                  <div className="paymentLogo"><img src={Payment6} alt={CONTENT.PaymentTypesImages[5].alt} /></div>
                  <div className="paymentLogo"><img src={Payment7} alt={CONTENT.PaymentTypesImages[6].alt} /></div>
                  <div className="paymentLogo"><img src={Payment8} alt={CONTENT.PaymentTypesImages[7].alt} /></div>
                  <div className="paymentLogo"><img src={Payment9} alt={CONTENT.PaymentTypesImages[8].alt} /></div>
                  <div className="paymentLogo"><img src={Payment10} alt={CONTENT.PaymentTypesImages[9].alt} /></div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}


export default Index;
      