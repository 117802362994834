const PAGE = {
	"ENGLISH": {
	  "HEADER": {
		  "BASEURL": "https://smiles.club",
		  "TITLE": "Smiles Club | Purchase your Full Clear Aligners Kit Here! No risk invisible aligners",
		  "DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Purchase invisible orthodontics without BPA here.",
		  "KEYWORDS": "Teeth straightening at home, affordable, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
	  },
	  "CONTENT": {
		  "PageHeader": [
			  {
				  "type": "div",
				  //"className": "d-none",
				  "content": `
				  <div class="preHeader">Products</div>
					<h1 class="sectionTitle">Store</h1>
					<div class="">Click on the product to see it's full description and what it includes.</div>
					<br/>
				  `
			  }
		  ],
		  "Product": [
			{
				"content": `Cost`
			},
			{
				"content": `You Save`
			},
			{
				"content": `Go to Product`
			}
		  ],
		  "Single": [
			{
				"content": "Buy Now"
			},
			{
				"type": "div",
				"className": "riskFree",
				"content": `
					<div class="award"><i class="fas fa-award"></i></div>
					<div class="text">
						<div class="header">Completely Risk Free</div>
						<div class="guarantee">We‘ll refund you your money if you do not qualify. <span class="emph">AND we'll even send you a <strong>FREE IMPRESSION KIT!</strong></span><br/><span class="emph2">DON'T WAIT, BUY NOW WHILE SUPPLIES LAST!</span></div>
					</div>
				`
			}
		  ]
	  }
  },
  "ESPAÑOL": {
	  "HEADER": {
		  "BASEURL": "https://sonrisas.club",
		  "TITLE": "Sonrisas Club | ¡Adquiere aquí tu kit completo de alineadores transparentes! Alineadores invisibles sin riesgo",
		  "DESCRIPTION": "Sonrisas Club proporciona un enderezamiento fácil y hermoso de los dientes desde casa. Compra ortodoncia invisible sin BPA aquí.",
		  "KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
	  },
	  "CONTENT": {
		  "PageHeader": [
			  {
				  "type": "div",
				  "className": "d-none",
				  "content": `
					  <h1 className="sectionTitled-none">¡Compre alineadores transparentes!</h1>
				  `
			  }
		  ],
		  "Single": [
			{
				"content": "Compra Ahora"
			},
			{
			"type": "div",
			"className": "riskFree",
			"content": `<div class="award"><i class="fas fa-award"></i></div>
						<div class="text">
							<div class="header">¡Completamente libre de riesgos!</div>
							<div class="guarantee">Le devolveremos su dinero si no califica. <span class="emph">¡E incluso le enviaremos un <strong>KIT DE IMPRESIÓN GRATUITO!</strong></span><br/><span class="emph2">¡NO ESPERE, COMPRE AHORA HASTA AGOTAR EXISTENCIAS! </span></div>
						</div>`
			}
		  ]
	  }
  }
};


  
export default PAGE;
