//import React from 'react';
const PAGE = [
  {
    "type": "div",
    "content": `<h1 class="sectionTitle">Cancelar la Suscripción de Email</h1>`
  },
  {
    "content": "Actualizando"
  },
  {
    "content": "Actualizar su configuración de usuario"
  },
  {
    "type": "div",
    "content": `<h4 class="">
      ERROR: No se pudo cancelar la suscripción a la dirección de correo electrónico. Algo debe estar mal con nuestros servidores. Por favor, inténtelo de nuevo más tarde.
    </h4>`
  },
  {
    "type": "div",
    "content": `<h4 class="">
      ÉXITO: Su correo electrónico ha sido ANULADO.
    </h4>`
  }
];




  
export default PAGE;