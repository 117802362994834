//import React from 'react';
const PAGE = {
  "StartTreatment": [
    //COUNT DOWN PART
    { //0
      "content": `Start Your Treatment`
    },
    { //1
      "content": `When you get your aligners click below to start your treatment plan.`
    },
    { //2
      "content": `START`
    },
    { //3
      "content": `Creating You Plan`
    },
    { //4
      "content": `Once your plan has been created, you'll be able to start your treatment plan here.`
    },
  ],
  "CurAlignerComponent1": [
    { //0
      "content": `Step`
    },
    { //1
      "content": `Current Aligner Step`
    },
    { //2
      "content": `Not Started`
    },
    { //3
      "content": `Started`
    },
  ],
  "CurAlignerComponent2": [
    { //0
      "content": `Aligner Alert`
    },
    { //1
      "content": `When to change to your next aligner.`
    },
    { //2
      "content": `Not Started`
    },
    { //3
      "content": `TREATMENT FINISHED!`
    },
  ],
  "AlignerScheduleComponent": [
    { //0
      "content": `Aligner Schedule`
    },
    { //1
      "content": `View your full aligner schedule.`
    },
    { //2
      "content": `Has Not Started`
    },
    { //3
      "type": "span",
      "content": `<span style="font-weight: 600;font-size: 18px;">Start</span>`
    },
    { //4
      "content": `Upper`
    },
    { //5
      "content": `and`
    },
    { //6
      "content": `Lower`
    },
    { //7
      "content": `History`
    },
    { //8
      "content": `Start Aligner`
    }
  ],
  "CountDown": [
    { //0
      "content": "Days"
    },
    { //1
      "content": "Hrs"
    },
    { //2
      "content": "Mins"
    },
    { //3
      "content": "Secs"
    },
  ]
};




	
export default PAGE;