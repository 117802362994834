//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="sectionTitle">Privacy Policy</h1>`
	},
	{
		"type": "div",
		"content": `<div class="textTemplate">     
      <div class="basicText">Last Revision – May 01, 2021</div>

      <h2 class="sectionTitle2">1. Please Read Carefully</h2>
      <div class="basicText">This Privacy Policy describes the information Smiles Club (“Company”) collects about you through our websites and all other services we provide (collectively, the “Services”), how we use and share that information, and the privacy choices we offer. This policy applies to information we collect when you access or use our websites (collectively, the “Site”), when you use our Services or when you otherwise interact with us. This Privacy Policy does not cover the privacy practices of the Dental Network described in the Company’s Terms of Use.</div>


      <h2 class="sectionTitle2">2. Changes to this Privacy Policy</h2>
      <div class="basicText">We may change this Privacy Policy from time to time. If we make changes, we will notify you by posting the updated policy on our Site and revising the “Last Revision” date above. We encourage you to review the Privacy Policy whenever you use our Services to stay informed about our information practices and about ways you can help protect your privacy.</div>


      <h2 class="sectionTitle2">3. Confidentiality of Health Information</h2>
      <div class="basicText">Health information that Company receives and/or creates about you that identifies you personally and relates to your past, present, or future health, treatment, or payment for healthcare services, may be “protected health information” under the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”) and the Health Information Technology for Economic and Clinical Health Act (“HITECH Act”). Your health information may also be protected by state privacy laws and regulations.</div>
      <div class="basicText">Company understands that health information about you and your health is personal. We support your privacy and make sure that the transmittal and use of your health information complies with all laws. In this regard, where applicable, we comply with HIPAA, HITECH, and other relevant state laws and regulations by entering into This Privacy Policy does not address how dental providers will use and disclose your health information. Each dental provider has its own Notice of Privacy Practices. If you have questions about the privacy of the information collected by the dental providers who provide services to you, please ask them for a copy of their Notice of Privacy Practices.</div>
      <div class="basicText">We are not GDPR or CPRA compliant. Therefore, Smiles Club exclusively serves residents and citizens of the United States of America, excluding those from the state of California. If you are a resident or citizen of some other country or the state of California we cannot serve you and make no guarantees as to your privacy beyond what is relevant and required for our service area. You will therefore not disclose any privacy information to Smiles Club and waive any and all claims whatever against Smiles Club for its non-compliance with outside jurisdictions. </div>



      <h2 class="sectionTitle2">4. Use of Services</h2>
      <div class="basicText">Your access to and use of our Services are subject to certain terms and conditions, which are set forth in our Terms of Use.</div>


      <h2 class="sectionTitle2">5. Collection of Information</h2>
      <div class="basicText">
        <h5 class="sectionTitle3">5.1 Information You Provide</h5>
        <div class="basicText">We collect information you provide, such as when you email us, sign up through our Site, or submit information through our Site. We may collect, but are not limited to collecting, the following information: your name, gender, email address, mailing address, phone number, date of birth, insurance provider information, payment card numbers, bank account number, bank routing number, history of medical and dental care, and issues we can help you with.
        </div>


        <h5 class="sectionTitle3">5.2 Children</h5>
        <div class="basicText">Company does not knowingly collect or maintain personally identifiable information from persons under 18 years of age without verifiable parental consent, and no part of the Services are directed at persons under 18. If you are under 18 years of age, then please do not use the Services. If Company learns that personally identifiable information of persons less than 18 years of age has been collected without verifiable parental consent, then Company will take the appropriate steps to delete this information. To make such a request, please contact us at support@Smiles.club.
        </div>
        <div class="basicText">Notwithstanding this section 5.2’s prohibition on the Company not directing offers and engagement directly with minors, Company does provide Services to minors with express parental consent. These records are all kept in compliance with all applicable laws relevant to our service area (United States of America, excluding California).
        </div>


        <h5 class="sectionTitle3">5.3 Information We Collect from Your Use of the Services</h5>
        <div class="basicText">We collect information about you when you use our Site, including, but not limited to the following:</div>
          <ul>
            <li>Account Information. When you register with us using the Site to create an account and become a registered user, you will need to provide us with certain personally identifiable information to complete the registration, including information that can be used to contact or identify you and credit card or other billing information in some cases.</li>
            <li>Device Information. We may automatically collect certain information about the computer or devices (including mobile devices) you use to access the Services. For example, we may collect and analyze information such as (a) IP addresses, geolocation information (as described in the next section below), unique device identifiers and other information about your mobile phone or other mobile device(s), browser types, browser language, operating system, the state or country from which you accessed the Services; and (b) information related to the ways in which you interact with the Services, such as: referring and exit pages and URLs, platform type, the number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, the amount of time spent on particular pages, the date and time you used the Services, the frequency of your use of the Services, error logs, and other similar information. As described further below, we may use third-party analytics providers and technologies, including cookies and similar tools, to assist in collecting this information.</li>
            <li>Location Information. We may collect different types of information about your location, including general information (e.g., IP address, zip code) and more specific information (e.g., GPS-based functionality on mobile devices used to access the Services), and may use that information to customize the Services with location-based information, advertising, and features. For example, if your IP address indicates an origin in Los Angeles, California, the Services may be customized with Los Angeles-specific information and offers. In order to do this, your location information may be shared with our agents, vendors or advertisers. If you access the Services through a mobile device and you do not want your device to provide us with location-tracking information, you can disable the GPS or other location-tracking functions on your device, provided your device allows you to do this. See your device manufacturer’s instructions for further details.</li>
            <li>Cookies and Other Electronic Technologies. We may use the tools outlined below in order to better understand users. As we adopt additional technologies, we may also gather additional information through other methods.</li>
            <li>Cookies: “Cookies” are small computer files transferred to your computing device that contain information such as user ID, user preferences, lists of pages visited and activities conducted while using the Services. We use Cookies to help us improve or tailor the Services by tracking your navigation habits, storing your authentication status so you do not have to re-enter your credentials each time you use the Services, customizing your experience with the Services, and for analytics and fraud prevention.</li>
          </ul>
        <div class="basicText">We may use a type of advertising commonly known as interest-based or online behavioral advertising. This means that some of our business partners use Cookies to display Company ads on other websites and services based on information about your use of the Services and on your interests (as inferred from your online activity). Other Cookies used by our business partners may collect information when you use the Services, such as the IP address, mobile device ID, operating system, browser, web page interactions, the geographic location of your internet service provider, and demographic information such as gender and age range. These Cookies help Company learn more about our users’ demographics and internet behaviors.
        </div>
        <div class="basicText">For more information on cookies, visit http://www.allaboutcookies.org.</div>
          <ul>
            <li>Web Beacons: “Web Beacons” (a.k.a. clear GIFs or pixel tags) are tiny graphic image files embedded invisibly in a web page or email that may be used to collect anonymous information about your use of our Services, the websites of selected advertisers, and the emails, special promotions or newsletters that we send you. The information collected by Web Beacons allows us to analyze how many people are using the Services, using the selected advertisers’ websites or opening our emails, and for what purpose, and also allows us to enhance our interest-based advertising.</li>
            <li>Website Analytics: We may use third-party website analytics services in connection with the website, including, for example, to register mouse clicks, mouse movements, scrolling activity and text that you type into the website or mobile application. These website analytics services generally do not collect personal information unless you voluntarily provide it and generally do not track your browsing habits across websites which do not use their services. We use the information collected from these services to help make the website easier to use.</li>
            <li>Mobile Device Identifiers: Mobile device identifiers are data stored on your mobile device that may track mobile device and data and activities occurring on and through it, as well as the applications installed on it. Mobile device identifiers enable collection of personal information (such as media access control, address and location) and traffic data. Mobile device identifiers help Company learn more about our users’ demographics and internet behaviors.</li>
          </ul>


        <h5 class="sectionTitle3">5.4 Information from Third Parties</h5>
        <div class="basicText">
        We may obtain additional information about you from third parties such as marketers, partners, researchers, and others. We may combine information that we collect from you with information about you that we obtain from such third parties and information derived from any other subscription, product, or service we provide.
        </div>


        <h5 class="sectionTitle3">5.5 Aggregate or De-identified Data</h5>
        <div class="basicText">
          We may aggregate and/or de-identify information collected by the Services or via other means so that the information is not intended to identify you. Our use and disclosure of aggregated and/or de-identified information is not subject to any restrictions under this Privacy Policy, and we may disclose it to others without limitation for any purpose, in accordance with applicable laws and regulations.
        </div>
      </div>


      <h2 class="sectionTitle2">6. Use of Information</h2>
      <div class="basicText">We use the information that we collect for the following purposes:</div>
      <div class="basicText">
        <ul>
          <li>For the purposes for which you provided the information;</li>
          <li>To contact you when necessary or requested;</li>
          <li>To personalize your experience with the Services by informing you of products, programs, events, services, and promotions of Company, our affiliates, our partners and/or third parties that we believe may be of interest to you (see the “Opt-In Policy” below);</li>
          <li>To fulfill your purchase from us, including, to process your payments, communicate with you regarding your purchase or provide you with related customer service;</li>
          <li>To provide, maintain, administer, improve, or expand the Services, perform business analysis, or for other internal purposes to support, improve or enhance our business, the Services, and other products and services we offer;</li>
          <li>To customize and tailor your experience of the Services;</li>
          <li>To send mobile notifications (you may opt-out of this service);</li>
          <li>To send emails and other communications that display content that we think will interest you and according to your preferences (you may opt-out of this service);</li>
          <li>To send you news and information about our Services;</li>
          <li>To track and analyze trends and usage in connection with our Services;</li>
          <li>To better understand who uses the Services and how we can deliver a better user experience;</li>
          <li>To combine information received from third parties with the information that we have from or about you and use the combined information for any of the purposes described in this Privacy Policy;</li>
          <li>To use statistical information that we collect in any way permitted by law, including from third parties in connection with their commercial and marketing efforts;</li>
          <li>To prevent, detect, and investigate security breaches, fraud, and other potentially illegal or prohibited activities;</li>
          <li>To enforce the legal terms that govern your use of the Services;</li>
          <li>To protect our rights or property;</li>
          <li>To administer and troubleshoot the Services; and</li>
          <li>For any other purpose disclosed to you in connection with our Services.</li>
        </ul>
      </div>
      <div class="basicText">We may use third-party service providers to process and store personal information in the United States and other countries.</div>


      <h2 class="sectionTitle2">7. Sharing of Information</h2>
        <div class="basicText">We may share personal information about you as follows:</div>
        <ul>
          <li>With dental providers, in connection with the Services you request;</li>
          <li>With third parties to provide, maintain, and improve our Services, including service providers who access information about you to perform services on our behalf;</li>
          <li>With our affiliates and partners so that they may use such information for the purposes described in this Privacy Policy;</li>
          <li>With our affiliates, partners or other third parties to allow them to contact you regarding products, programs, services, and promotions that we and/or they believe may be of interest to you (See the “Opt-In Policy” below);</li>
          <li>In connection with, or during the negotiation of, any merger, sale of company stock or assets, financing, acquisition, bankruptcy, divestiture or dissolution of all or a portion of our business (but only under non-disclosure and confidentiality agreements and protections);</li>
          <li>If we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request; to enforce applicable user agreements or policies; to protect the security or integrity of our Services; and to protect us, our users or the public from harm or illegal activities; and</li>
          <li>With your consent.</li>
        </ul>
        <div class="basicText">We may also share aggregated, non-personally identifiable information with third parties.</div>


      <h2 class="sectionTitle2">8. Opt-In Policy</h2>
      <div class="basicText">When you supply us with personally identifiable information in connection with your use of the Services, you may be asked to indicate whether you are interested in receiving information from us about our product and service offerings and if you would like us to share personally identifiable information about you with our affiliates, partners or other third parties for their marketing purposes. If you do choose to opt-in, you will receive such communications and/or we will share your information in accordance with your “opt-in” consent.</div>
      <div class="basicText">You may, of course, choose not to receive additional marketing information from us or choose not to allow our sharing of your personally identifiable information as follows: At any time, you can follow a link provided in our marketing-related email messages (but excluding e-commerce confirmations and other administrative emails) to opt out from receiving such communications; or at any time, you can contact us in accordance with the “Contact Us” section below to opt out from receiving such communications.</div>
      <div class="basicText">If you decide to contact us to change your contact preferences to opt out of receiving communications from us, please specify clearly which of the following choices you are opting out of: (a) Receiving marketing communications from us; (b) Allowing us to share personally identifiable information about you with our affiliates and partners for their marketing purposes; and/or (c) Allowing us to share personally identifiable information about you with other third parties for their marketing purposes.</div>
      <div class="basicText">We will endeavor to implement your requested change as soon as reasonably practicable after receiving your request. Please be aware that your requested change will not be effective until we implement such change. Please note that if you choose not to allow our sharing of your personally identifiable information, we are not responsible for removing your personally identifiable information from the databases of third parties with which we have already shared your personally identifiable information as of the date that we implement your request. If you wish to cease receiving marketing-related emails from these third parties, please contact them directly or utilize any opt-out mechanisms in their privacy policies or marketing-related emails.</div>
      <div class="basicText">Please note that if you do opt-out of receiving marketing-related messages from us, we may still send you important administrative messages. You cannot opt-out from receiving these administrative messages. We reserve the right, from time to time, to contact former customers or users of the Services for administrative purposes or in order to comply with applicable laws, rules or regulations.</div>


      <h2 class="sectionTitle2">9. Social Media and Third Party Platforms</h2>
      <div class="basicText">Certain sections or functionalities on our Services may permit you to share information on third party social media sites or platforms such as Facebook, Instagram, LinkedIn, Twitter, Google+, or other similar sites (collectively, “Social Media Sites”). Company does not own or control such Social Media Sites and posting your information on Social Media Sites is subject to the third party’s privacy policy and other legal terms, which may not provide privacy protections with which you agree. Company is not responsible for any act or omission of any Social Media Site, nor are we responsible for the consequences of your choosing to share your information on Social Media Sites.</div>


      <h2 class="sectionTitle2">10. Security</h2>
        <div class="basicText">We take reasonable measures, including administrative, technical, and physical safeguards, to help protect personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, Company cannot ensure or warrant the security of any information you transmit to us or from our online products or services, and you do so at your own risk.</div>


      <h2 class="sectionTitle2">11. Your Privacy Choices</h2>


      <h5 class="sectionTitle3">11.1 How You Can Access and Update Your Information</h5>
      <div class="basicText">You may update or correct information about yourself at any time or by emailing us at <a href="mailto:support@Smiles.club">support@Smiles.club</a>.</div>


      <h5 class="sectionTitle3">11.2 Cookies</h5>
      <div class="basicText">Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject cookies; however, our Services may not function properly if you do so.</div>


      <h5 class="sectionTitle3">11.3 Options for Opting out of Cookies and Mobile Device Identifiers</h5>
      <div class="basicText">If you are interested in more information about interest-based advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising, you may visit the Network Advertising Initiative’s Consumer Opt-Out link, the Digital Advertising Alliance’s Consumer Opt-Out link or TRUSTe’s Advertising Choices Page to opt-out of receiving tailored advertising from companies that participate in those programs.
      Please note that even after opting out of interest-based advertising, you may still see Company’s advertisements that are not interest-based (i.e., not targeted toward you). Also, opting out does not mean that Company is no longer using its tracking tools—Company still may collect information about your use of the Services even after you have opted out of interest-based advertisements and may still serve advertisements to you via the Services based on information it collects via the Services.</div>


      <h5 class="sectionTitle3">11.4 How Company Responds to Browser “Do Not Track” Signals</h5>
      <div class="basicText">We are committed to providing you with meaningful choices about the information collected on our Site for third-party purposes, and that is why we provide above the Network Advertising Initiative’s “Consumer Opt-out” link, Digital Advertising Alliance’s Consumer Opt-Out Link, and TRUSTe’s Advertising Choices page. </div>
      

      <h5 class="sectionTitle3">11.5 Links to Other Websites</h5>
      <div class="basicText">Our Services may contain links to other websites and those websites may not follow the same privacy practices as Company. We are not responsible for the privacy practices of third-party websites. We encourage you to read the privacy policies of such third parties to learn more about their privacy practices.</div>
      

      <h5 class="sectionTitle3">11.6 No Rights of Third Parties</h5>
      <div class="basicText">This Privacy Policy does not create rights enforceable by third parties.</div>
      <br/>
      <h5 class="sectionTitle3">11.7 How to Contact Us</h5>
      <div class="basicText">Please contact us with any questions or concerns regarding this Privacy Policy at: <a href="mailto:support@Smiles.club">support@Smiles.club</a></div>
    </div>`
	}
];




	
export default PAGE;