//import React from 'react';
const PAGE = [
  {
    "content": "New Patient Form"
  },
  {
    "content": `Cancel`
  },
  {
    "content": `Create`
  },
  {
    "content": `New Patient`
  },
  {
    "content": `Patient`
  },
  {
    "content": `First Name`
  },
  {
    "content": `Every user needs a first name`
  },
  {
    "content": "Each user needs a First Name"
  },
  {
    "content": `Last Name`
  },
  {
    "content": `Every user needs a last name`
  },
  {
    "content": "Each user needs a Last Name"
  },
  {
    "content": `Choose a Doctor`
  },
  {
    "content": `What doctor will be assigned this patient?`
  },
  {
    "content": "Who to email is required"
  },
  {
    "content": "Update Patient"
  },
  {
    "content": "Create Patient"
  },
  {
    "content": "Creating Patient"
  },
  {
    "content": "Please stand by"
  },
  {
    "content": `Treatment Plan Request`
  },
  {
    "content": `Initial User Mouth Pictures`
  },
  {
    "content": `We need to see the new user smiling from the front, the left, and the right side. Then we need to see the user's upper teeth and the lower teeth. You should have at least 5 pictures of the user before we can begin.`
  },
  {
    "content": `Mouth Scan Files`
  },
  {
    "content": `We need good STL scans of the upper and lower teeth. Drag and drop them here.`
  },
  {
    "content": `Chief Complaint`
  },
  {
    "content": `What is the chief complaint this user has? Over bite? Under bite? Certain teeth are crooked, etc.`
  },
  {
    "content": `Notes`
  },
  {
    "content": `Add any additional notes you may wish to leave for this treatment plan request. This could be anything you like, from something for us to keep in mind, to something you wish to remember about this order.`
  },
  {
    "content": "Update Request"
  },
  {
    "content": "Create Request"
  },
  {
    "content": "Updating Order"
  },
  {
    "content": "Please stand by"
  }  
];




  
export default PAGE;