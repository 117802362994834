import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';


/*
*/

const Index = (props)=>{
  var {TemplateDBObj} = props;
  var {TYPE, ID, APINAME, EDITABLE} = TemplateDBObj;
  //console.log(`TemplateDBObj`,"\n\n",TemplateDBObj,"\n\n");
        
  if(!APINAME) return null;
  const PREVIEW = API_CALLS[APINAME].PREVIEW;
  return (
    <PREVIEW
      VARIABLES={{
        id: ID
      }}
      EDITABLE={EDITABLE}
    />
  );
}










export default Index;