import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ReactSortable } from "react-sortablejs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from 'react-tooltip';
import LayoutBuilder from 'LayoutBuilder';


const {Container, Row, Column} = LayoutBuilder;


const SortObjByName = (items)=>{
  items.sort(function(a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return items;
}





class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupList: [],
      defaultList: SortObjByName(this.props.optionList.slice()),
      collapseArray: [],
      newTempGroup: {},
      monthSkip: 2
    }; 
    this.AddNewGroup = this.AddNewGroup.bind(this);
    this.SetDefault = this.SetDefault.bind(this);
  }

  componentDidMount(){
    //this.SetDefault();
  }

  AddNewGroup(){
    var {groupList, newTempGroup, defaultList, monthSkip} = this.state;
    var newTempGroupArray = Object.keys(newTempGroup).map(key=>newTempGroup[key]);
    var dt = ((groupList.length > 0) ? new Date(groupList[groupList.length-1].date) : new Date()); //IF GROUPLIST HAS ITEMS, THEN TAKE THE LAST ITEM AND GET IT'S DATE AS A STARTING POINT
    dt.setHours(0,0,0,0); //from start of day
    if(groupList.length > 0) dt.setMonth(dt.getMonth()+Number(monthSkip));
    groupList.push({
      id: window.GlobalUtil.getRandomId(),
      items: (newTempGroupArray ? newTempGroupArray : []), 
      date: dt.getTime(),
      collapse: true
    })
    if(newTempGroupArray.length > 0){
      defaultList = defaultList.filter(obj=>{
        var curID = (obj._id ? obj._id : obj.id)
        return(newTempGroup[curID] ? false : true)
      })      
    }
    this.setState({groupList: groupList, newTempGroup: {}, defaultList: defaultList})
  }

  SetDefault(){
    var {groupList} = this.state;
    if(this.props.startingNumber) {
      var newListOfObj = new Array(Number(Number(this.props.startingNumber/2).toFixed())).fill({});
      var newArray = [];
      groupList = newListOfObj.map((obj, index)=>{
        var dt = new Date();
        dt.setHours(0,0,0,0); //from start of day
        obj.list = [];
        obj.id = window.GlobalUtil.getRandomId();
        dt.setMonth(dt.getMonth()+(2*index));
        obj.date = dt.getTime();    
        obj.collapse = true;                
        return({...obj});   
      }); 
    }
          
    this.setState({groupList})
  }


  toggleTempGroup = (item)=>{
    var {newTempGroup={}} = this.state;
    if(newTempGroup[item.id]) delete newTempGroup[item.id];
    else newTempGroup[item.id] = item;
    this.setState({newTempGroup})
  }


  render(){
    var {groupList=[], defaultList=[], collapseArray=[], newTempGroup, monthSkip} = this.state; 
    var {optionList=[]} = this.props;     
    return (
      <div className="SortableListGroupWrapper">
        <div className="SortableListGroups">
          <div className="SortableListGroupsLeft">
            {groupList.length ? <button className="button button1" style={{"marginBottom": "15px"}} onClick={this.AddNewGroup}>New Group</button> : ''}
            {
              groupList.map((obj, index)=>{
                //obj.items = optionList      
                return(
                  <ListItem 
                    key={index} 
                    obj={{...obj}} 
                    onChange={newObj=>{
                      groupList[index] = {...newObj};
                      this.setState({groupList: groupList.slice()});
                      this.props.onChangeGroupList(groupList);
                    }}
                    index={index} 
                  />
                )
              })
            }
            <button className="button button1" onClick={this.AddNewGroup}>New Group</button>
          </div>
          <DefaultList
            defaultList={defaultList.slice()}
            onReverseList={()=>{
              defaultList.reverse()
              this.setState({defaultList})
            }}
            onSetList={(newDefaultList) => this.setState({ defaultList: newDefaultList })}
            newTempGroup={newTempGroup}
            toggleTempGroup={this.toggleTempGroup}
            monthSkip={monthSkip}
            onMonthChange={(newNumber)=>this.setState({monthSkip: newNumber})}
          />
        </div>
        <ReactTooltip />
      </div>
    );
  }
}




class DefaultList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paddingTop: 10
    };       
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount(){
    this.pointerToSideMenuBody = document.querySelector(".SlideInRight #SlideInRightContent #Body");
    //this.pointerToSideMenuBody.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount(){
    //this.pointerToSideMenuBody.removeEventListener("scroll", this.onScroll)
  }

  onScroll(e){
    var maxScroll = 130;
    var scrollTopDistance = this.pointerToSideMenuBody.scrollTop;
    if(scrollTopDistance > maxScroll){
      this.setState({paddingTop: (scrollTopDistance-maxScroll)+10})
    } else {
      this.setState({paddingTop: 10})
    }    
  }

  render(){ 
    var {paddingTop=10} = this.state;
    var {defaultList, newTempGroup, monthSkip} = this.props;                         
    return (
      <div className="SortableListGroupsRight" style={{paddingTop: `${paddingTop}px`}}>
        <div className="SLGRTitle">
          <div className="title">
            Aligner STL's
          </div>
           <div className="listNav">
            <i className="reverse fas fa-random" data-tip={"Reverse List"} onClick={this.props.onReverseList}/>
            <input type="number" className="numberInput" data-tip={"Months between aligner groups"} min="1" max="12" value={monthSkip} onChange={e=>this.props.onMonthChange(e.target.value)}/>
          </div>
        </div>
        <ReactSortable 
          list={defaultList} 
          group='shared'
          className="SortableItemContainer"
          ghostClass='blue-background-class'
          animation={150}
          handle='.my-handle'
          setList={this.props.onSetList}>
          {
            defaultList && 
            defaultList.map((item, index) => {
              var itemID = (item._id ? item._id : item.id);
              item.id = itemID;
              return(
                <div key={itemID} className="SortableItem withHandle">
                  <i className="fas fa-grip-lines my-handle"></i>
                  <div className="name">{item.customName ? item.customName : item.name}</div>
                  <div className={`selectBoxSortable ${newTempGroup[itemID] ? 'selected' : ''}`} onClick={()=>this.props.toggleTempGroup(item)}>
                    <i className="fas fa-check-square"></i>
                    <i className="fas fa-square"></i>
                    <i className="far fa-square"></i>
                  </div>
                </div>
              )
            })
          }
        </ReactSortable>
      </div>
    );
  }
}








const ListItem = props => {
  //var {groupList} = this.state;    
  var {obj={}, index} = props;             
  var {items=[]} = obj;                      
  return (
    <div className="SortableListGroup">
      <div className="SLGTitle" onClick={()=>{
            var objNew = {...obj, collapse: !obj.collapse};
            props.onChange(objNew);
          }}>
        <div className="SLGTitleText">
          Shipping Group {index+1}
        </div>
        <div className="SLGTitleIcon">
          <i className={`fas fa-chevron-${obj.collapse ? "up" : "down"}`}></i>
        </div>
      </div>
      <div className={`SLGFields ${obj.collapse ? "collapse" : ""}`}>
        <div className="SLGDate">
          <div className="SLGDateTitle">
            Select Date:
          </div>
          <div className="">
            <DatePicker
              selected={(!obj.date ? new Date() : new Date(obj.date))}
              onSelect={(newValue)=>{
                
              }} //when day is clicked
              showTimeSelect={false}
              className="form-control "
              onChange={(newValue)=>{
                const newTime = (newValue ? newValue.getTime() : undefined);
                var objNew = {...obj, date: newTime};
                props.onChange(objNew);
              }} //only when value has changed
              onCalendarClose={()=>{
                //this.setState({zIndex: 1})
              }}
              onCalendarOpen={()=>{
                //this.setState({zIndex: 1000})
              }}
            />
          </div>
        </div>
        <div className="SLGItems">
          <div className="SLGItemTitle">Items:</div>
          <ReactSortable 
            list={items} 
            group='shared'
            className="SortableItemContainer"
            ghostClass='blue-background-class'
            animation={150}
            setList={(items) => {                      
              var objNew = {...obj, items};
              props.onChange(objNew);
            }}>
            {
              (items.length > 0) && 
              items.map((item) => (
                <div key={item.name} className="SortableItem">{item.customName ? item.customName : item.name}</div>
              ))
            }
          </ReactSortable>
        </div>
      </div>
    </div>
  );
}



export default Index;