import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  CCID
  UID
  user{
    email
    firstName
    lastName
  }
  OID
  OIID
  CPID
  CODE
  coupon{
    name
    description
    summary
  }
  EARNED
  APPROVED
  APPROVED_DATE
  ADMIN_PAID
  ADMIN_PAID_DATE
  status
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $CCID: String,
  $UID: String,
  $user: CommissionUserInput,
  $OID: String,
  $OIID: String,
  $CPID: String,
  $CODE: String,
  $coupon: CommissionCouponInput,
  $EARNED: String,
  $APPROVED: String,
  $APPROVED_DATE: String,
  $ADMIN_PAID: Boolean,
  $ADMIN_PAID_DATE: String,
  $status: String
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  CCID: $CCID,
  UID: $UID,
  user: $user,
  OID: $OID,
  OIID: $OIID,
  CPID: $CPID,
  CODE: $CODE,
  coupon: $coupon,
  EARNED: $EARNED,
  APPROVED: $APPROVED,
  APPROVED_DATE: $APPROVED_DATE,
  ADMIN_PAID: $ADMIN_PAID,
  ADMIN_PAID_DATE: $ADMIN_PAID_DATE,
  status: $status
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "CCID": "string",
  "UID": "string",
  "user": {
    "email": "string",
    "firstName": "string",
    "lastName": "string"
  },
  "OID": "string",
  "OIID": "string",
  "CPID": "string",
  "CODE": "string",
  "coupon": {
    "name": "string",
    "description": "string",
    "summary": "string"
  },
  "EARNED": "string",
  "APPROVED": "string",
  "APPROVED_DATE": "string",
  "ADMIN_PAID": "boolean",
  "ADMIN_PAID_DATE": "string",
  "status": "string",
  "meta": CLEAN_FIELDS_DEFAULT.META
}


const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    "path": "user.firstName,user.lastName",
    "type": "VALUE",
    "name": "User Name",
    searchable: true
  },
  {
    "path": "coupon.name",
    "type": "VALUE",
    "name": "Coupon",
    searchable: true
  },
  {
    path: "EARNED",
    type: "MONEY",
    name: "Earned",
    searchable: true
  },
  {
    path: "APPROVED",
    type: "VALUE",
    name: "Approved",
    searchable: true
  },
  {
    path: "APPROVED_DATE",
    type: "DATE",
    name: "Date Approved",
    searchable: true
  },
  {
    path: "ADMIN_PAID",
    type: "BOOLEAN",
    name: "Admin Paid",
    searchable: true
  },
  {
    path: "ADMIN_PAID_DATE",
    type: "DATE",
    name: "Date Admin Paid",
    searchable: true
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  }
];




const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME":"ID"
          },
          {
            "PATH": "CCID",
            "TYPE": "STRING",
            "NAME": "Commission Check ID (CCID)",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "BUTTON_TITLE": "Commission Check",
              "ID_PATH": "CCID",
              "TITLE": "Commission Check", //TITLE OF SLIDE OUT
              "APINAME": "COMMISSION_CHECKS",
              "EDITABLE": true
            }
          },
          {
            "PATH": "OID",
            "TYPE": "STRING",
            "NAME": "Order ID (OID)",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "BUTTON_TITLE": "Order",
              "ID_PATH": "OID",
              "TITLE": "Order", //TITLE OF SLIDE OUT
              "APINAME": "ORDERS",
              "EDITABLE": true
            }
          },
          {
            "PATH": "OIID",
            "TYPE": "STRING",
            "NAME": "Order Item ID (OIID)",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "BUTTON_TITLE": "Order Item",
              "ID_PATH": "OIID",
              "TITLE": "Order Item", //TITLE OF SLIDE OUT
              "APINAME": "ORDER_ITEMS",
              "EDITABLE": true
            }
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "EARNED",
            "TYPE": "MONEY",
            "NAME": "Earned"
          },
          {
            "PATH": "APPROVED",
            "TYPE": "STRING",
            "NAME": "Approved"
          },
          {
            "PATH": "APPROVED_DATE",
            "TYPE": "DATE",
            "FORMAT": "n/d/Y @ H:mA",
            "NAME": "Date commission was approved"
          },
          {
            "PATH": "ADMIN_PAID",
            "TYPE": "BOOLEAN",
            "NAME": "Admin_paid"
          },
          {
            "PATH": "ADMIN_PAID_DATE",
            "TYPE": "DATE",
            "FORMAT": "n/d/Y @ H:mA",
            "NAME": "Day the Admin wrote a check for this commission"
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE":"User who earned the commission:",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "NAME": "User ID (UID)",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "BUTTON_TITLE": "User",
              "ID_PATH": "UID",
              "TITLE": "User", //TITLE OF SLIDE OUT
              "APINAME": "USERS",
              "EDITABLE": true
            }
          },
          {
            "PATH": "user,firstName",
            "TYPE": "STRING",
            "NAME": "First Name"
          },
          {
            "PATH": "user,lastName",
            "TYPE": "STRING",
            "NAME": "Last Name"
          },
          {
            "PATH": "user,email",
            "TYPE": "STRING",
            "NAME": "Email"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "TITLE":"Coupon Used:",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "CPID",
            "TYPE": "STRING",
            "NAME": "Coupon ID (CPID)",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "BUTTON_TITLE": "Coupon",
              "ID_PATH": "CPID",
              "TITLE": "Coupon", //TITLE OF SLIDE OUT
              "APINAME": "COUPONS",
              "EDITABLE": true
            }
          },
          {
            "PATH": "CODE",
            "TYPE": "STRING",
            "NAME": "Coupon Code"
          },
          {
            "PATH": "coupon,name",
            "TYPE": "STRING",
            "NAME": "Name"
          },
          {
            "PATH": "coupon,description",
            "TYPE": "STRING",
            "NAME": "Description"
          },
          {
            "PATH": "coupon,summary",
            "TYPE": "STRING",
            "NAME": "Summary"
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.META()
];


const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  //if(!curCleanObj.whoToEmail) delete curCleanObj.whoToEmail;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  //if(!curCleanObj.whoToEmail) delete curCleanObj.whoToEmail;
  return curCleanObj;
}


const COMS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default COMS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};