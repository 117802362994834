import React from 'react';
import PAGES from './pages.json'; //ADD NEW PAGES HERE
import {PAGES as AlignersLinks} from './aligners/index';
import {PAGES as AffiliatesLinks} from './affiliates/index';
import {PAGES as BlogsLinks} from './blogs/index';
import {PAGES as ComsLinks} from './coms/index';
import {PAGES as CouponsLinks} from './coupons/index';
//import {PAGES as FailedLinks} from './failed/index';
import {PAGES as GiveawaysLinks} from './giveaways/index';
import {PAGES as OrdersLinks} from './orders/index';
import {PAGES as ProductsLinks} from './products/index';
import {PAGES as ReferralsLinks} from './referrals/index';
import {PAGES as ShippingLinks} from './shipping/index';
import {PAGES as TrackingLinks} from './tracking/index';
import {PAGES as TreatmentPlansLinks} from './treatmentPlans/index';
import {PAGES as UsersLinks} from './users/index';
import {PAGES as SettingsLinks} from './settings/index';

const PagesGetLinks = (Page) => {
  return Page().map((object, index)=>{
    var toReturn = {
      "Name": object.Name,
      "PageName": object.PageName,
      "Path": object.Path,
      "SubPaths": object.SubPaths
    };
    if(object.GlobalStateVar) toReturn.GlobalStateVar = object.GlobalStateVar
    return toReturn;
  });
}



const LocationDictionary = {
  Aligners: PagesGetLinks(AlignersLinks),
  Affiliates: PagesGetLinks(AffiliatesLinks),
  Blogs: PagesGetLinks(BlogsLinks),
  Coms: PagesGetLinks(ComsLinks),
  Coupons: PagesGetLinks(CouponsLinks),
  //Failed: PagesGetLinks(FailedLinks),
  Giveaways: PagesGetLinks(GiveawaysLinks),
  Orders: PagesGetLinks(OrdersLinks),
  Products: PagesGetLinks(ProductsLinks),
  Referrals: PagesGetLinks(ReferralsLinks),
  Shipping: PagesGetLinks(ShippingLinks),
  Tracking: PagesGetLinks(TrackingLinks),
  TreatmentPlans: PagesGetLinks(TreatmentPlansLinks),
  Users: PagesGetLinks(UsersLinks),
  Settings: PagesGetLinks(SettingsLinks),
}


const Pages = ()=>{
  var FullPages = PAGES.slice();
  FullPages = FullPages.map((Obj, index)=>{ //IF SUBFIELD EXIST THEN ADD IT
    if(LocationDictionary[Obj.Name]){
      Obj.SubPaths = LocationDictionary[Obj.Name];
      var listOfGlobalStateVar = [];
      LocationDictionary[Obj.Name].map((object, index)=>{ //GET ALL CHILDREN GLOBAL_STATE_VAR
        if(!object.GlobalStateVar) return undefined;
        listOfGlobalStateVar = [...listOfGlobalStateVar, ...object.GlobalStateVar];
      });
      listOfGlobalStateVar = listOfGlobalStateVar.filter(obj=>obj); //REMOVE ANY EMPTY AREAS
      Obj.GlobalStateVar = [
        ...(Obj.GlobalStateVar ? Obj.GlobalStateVar : []), 
        ...listOfGlobalStateVar
      ];  //COMBINE VAR
      Obj.GlobalStateVar = window.GlobalUtil.arrayOnlyUnique(Obj.GlobalStateVar); //REMOVE ANY DUPLICATES
    }
    return Obj;
  })        
  return(
    FullPages
  );
}
export default Pages;
      
