import React from 'react';
const SocialObj = ()=>[
  {
    "name": "Facebook",
    "url": "www.facebook.com",
    "icon": <i className="fab fa-facebook-square"></i>
  },
  {
    "name": "Twitter",
    "url": "www.twitter.com",
    "icon": <i className="fab fa-twitter-square"></i>
  },
  {
    "name": "Instagram",
    "url": "www.instagram.com",
    "icon": <i className="fab fa-instagram-square"></i>
  },
  {
    "name": "Youtube",
    "url": "www.youtube.com",
    "icon": <i className="fab fa-youtube-square"></i>
  },
]

export default SocialObj;