import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import USERS from "../USERS/index";// import QUERIES from "./QUERIES";
// import MUTATIONS from "./MUTATIONS";
// import ImageUtils from "global/utils/image-utils";
// const IMAGE_UTILS =  new ImageUtils();

// UID: window.Session.user._id,
// paymentOrderID: orderID,
// status: "ACTIVE",
// hasImpressionKit: hasImpressionKit,
// hasTreatmentPlan: hasTreatmentPlan,
// products: products.map(product=>{
//   var productSingle = {
//     id: `${product._id}`, //THIS WILL TELL ME WHAT PRODUCT IT WAS WHILE THE OTHER INFO TELLS ME HOW MAY OF THOSE AND WHAT THE COST WAS AT THAT TIME
//     costWithDiscount: `${(Number(product.cost)-Number(product.discount)).toFixed(2)}`,
//     type: product.type,
//     ref: product.ref,
//     category: product.category,
//     name: product.name,
//     quantity: `${product.quantity}`,
//     img: product.images[0].url
//   }
//   if(productSingle.ref === "TREATMENT_PLAN") productSingle.TPID = window.Session.user.TPID; //THE USER SHOULD HAVE 1 TPID FOR THIS TO WORK
//   return(productSingle)
// }), //ARRAY OF PRODUCT OBJECTS, THIS LETS US SEE WHAT THE PRODUCTS LOOKED LIKE BACK THEN
// tax: Number(Number(tax).toFixed(3)),
// shipping: Number(Number(shipping).toFixed(2)),
// couponID: (coupon ? coupon._id : null),
// subTotalWithDis: Number(subTotalWithDis),
// discounts: Number((!coupon ? 0 : window.GlobalUtil.discountCalculator(subtotal, coupon.discount)).toFixed(2)),
// total: Number(Number(total).toFixed(2)),
// paidAt: `${todayTime}`,
// chargingAt: `${todayTime}`,


const FORM_FIELDS = (obj={}) => {
  // const QUERY_GET_DISTINCT = QUERIES.distinct();
  // const MUTATION_EDIT = MUTATIONS.update();
  const QUERY_GET_USERS = USERS.QUERIES.all();
  return [
    {
      title: <div className="formTitle"><span className="">Choose a User</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every order</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_USERS
        })
        .then(result => {
          var usersOptions = result.data.users.map((user, index)=>{
            return({
              "value": user._id,
              "title": `${user.firstName} ${user.lastName} - ${user.email}`
            })
          });
          
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...usersOptions])
          // console.log("usersOptions");
          // console.log(usersOptions,"\n\n");
                
        });
      },
      name: "UID",
      required: true,
      defaultValue: "",
      defaultValuePath: "UID",
      errorMessage: "User ID is required"
    },
    {
      ///Users/danielezaldivar/Sites/sonrisas/src/global/components/FormBuilder/comp-AddProducts.js
      condition: (obj)=>obj.UID,
      title: <div className="formTitle"><span className="">Product</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Select products attached to this order.</span></div>,
      type: "addProducts",
      name: "productsStringArray",
      required: true,
      defaultValue: "",
      errorMessage: "User ID is required"
    },
    {
      type: "customText",
      condition: curObj=>curObj.cost,
      customFunction: (curObj)=>{
        //console.log(`curObj`,"\n\n",curObj,"\n\n");
        var {cost={}} = curObj;
        return(<div style={{"borderTop":"1px solid #2196f3","borderBottom":"1px solid #2196f3","padding":"20px 0","margin":"0 0 0 auto","maxWidth":"330px"}}>
          <div className="" style={{"fontWeight":"500","fontSize":"16px"}}>Cost:</div>
          <div className="" style={{"padding":"5px 10px"}}>
            <div className="" style={{"borderBottom":"2px solid #ddd","paddingBottom":"10px","marginBottom":"10px"}}>
              <div style={{"borderBottom":"1px solid #eee","paddingBottom":"5px","marginBottom":"5px","display":"flex"}}>
                <div style={{"flex": "1"}}>Subtotal:</div> 
                <div style={{}}>{window.GlobalUtil.convertToMoney(cost.subtotal)}</div>
              </div>
              <div style={{"borderBottom":"1px solid #eee","paddingBottom":"5px","marginBottom":"5px","display":"flex"}}>
                <div style={{"flex": "1"}}>Discount:</div> 
                <div style={{}}>{window.GlobalUtil.convertToMoney(cost.discount)}</div>
              </div>
              <div style={{"borderBottom":"1px solid #eee","paddingBottom":"5px","marginBottom":"5px","display":"flex"}}>
                <div style={{"flex": "1"}}>SubtotalMinusDis:</div> 
                <div style={{}}>{window.GlobalUtil.convertToMoney(cost.subtotalMinusDis)}</div>
              </div>
              <div style={{"borderBottom":"1px solid #eee","paddingBottom":"5px","marginBottom":"5px","display":"flex"}}>
                <div style={{"flex": "1"}}>Tax:</div> 
                <div style={{}}>{window.GlobalUtil.convertToMoney(cost.tax)}</div>
              </div>
              <div style={{"borderBottom":"1px solid #eee","paddingBottom":"5px","marginBottom":"5px","display":"flex"}}>
                <div style={{"flex": "1"}}>SetupFee:</div> 
                <div style={{}}>{window.GlobalUtil.convertToMoney(cost.setupFee)}</div>
              </div>
              <div style={{"display":"flex"}}>
                <div style={{"flex": "1"}}>Shipping:</div> 
                <div style={{}}>{window.GlobalUtil.convertToMoney(cost.shipping)}</div>
              </div>
            </div>
            <div className="" style={{"fontSize":"20px", "display":"flex"}}>
              <div style={{"flex": "1"}}>Total:</div> 
              <div style={{}}>{window.GlobalUtil.convertToMoney(cost.total)}</div>
            </div>
          </div>
        </div>)
      }
    },
    {
      title: <div className="formTitle"><span className="">Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "PENDING",
          "title": "PENDING"
        },
        {
          "value": "INACTIVE",
          "title": "INACTIVE"
        }
      ],
      required: false,
      defaultValue: "ACTIVE",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Payment Subscription ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">If they bought this via paypal subscription please add the subscription id here.</span></div>,
      type: "text",
      name: "paymentSubscriptionID",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "paymentSubscriptionID",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Payment Type</span></div>,
      subTitle: <div className="formSubTitle"><span className="">How did they pay for this?</span></div>,
      type: "select",
      name: "paymentType",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "paypal",
          "title": "PAYPAL"
        },
        {
          "value": "card",
          "title": "CARD"
        },
        {
          "value": "affirm",
          "title": "AFFIRM"
        },
        {
          "value": "afterpay_clearpay",
          "title": "AFTERPAY CLEARPAY"
        },
        {
          "value": "apple_pay",
          "title": "APPLE PAY"
        },
        {
          "value": "google_pay",
          "title": "GOOGLE PAY"
        },
        {
          "value": "amazon_pay",
          "title": "AMAZON PAY"
        },
        {
          "value": "cash",
          "title": "CASH"
        },
        {
          "value": "other",
          "title": "OTHER"
        }
      ],
      required: false,
      defaultValue: "",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Comments</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Comment that shows up for this product.</span></div>,
      type: "textArea",
      name: "comment",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "comment",
      errorMessage: "Comments is required"
    },
    // {
    //   title: <div className="formTitle"><span className="">Cost</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Initial Cost of the product.</span></div>,
    //   type: "money",
    //   name: "total",
    //   required: false,
    //   defaultValue: 0,
    //   defaultValuePath: "total",
    //   errorMessage: "Cost is required"
    // },
    {
      title: <div className="formTitle"><span className="">Date Paid</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When it was Paid</span></div>,
      type: "datePicker",
      name: "paidAt",
      required: false,
      defaultValue: "",
      defaultValuePath: "paidAt"
    },
    {
      title: <div className="formTitle"><span className="">Charged Date</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When it WILL be charged</span></div>,
      type: "datePicker",
      name: "chargingAt",
      required: false,
      defaultValue: "",
      defaultValuePath: "chargingAt",
      errorMessage: "The Task Name is required"
    },
    {
      title: <div className="formTitle"><span className="">Send Email</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Should we send an email to user telling them their order was sent?</span></div>,
      type: "toggle",
      name: "sendEmail",
      borderBottom: true,
      borderTop: true,
      defaultValue: false,
      defaultValuePath: "sendEmail"
    }
  ]
};






export default FORM_FIELDS;