import React, { useState, useRef } from 'react';
import "styles/scss/lab/components/viewTreatmentPlanRequest.scss";
import { gql, useQuery, useMutation } from '@apollo/client';
import DownloadAllButton from 'global/components/DownloadAllButton';
import API_CALLS from 'API_CALLS/index';
import SectionValue from 'user/normal/components/SectionValue/index';

import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}








/*
FIRST CREATE NEW USER (PATIENT) (MUST HAVE DOCTOR ID)
THEN AUTO CREATE NEW TREATMENT PLAN FOR THAT USER

THEN UPLOAD ALL THINGS NEEDED FOR TREATMENT PLAN REQUEST
OBJECT TO CREATE NEW PATIENT
{
  DOCID
  firstName
  lastName
}

OBJECT TO CREATE TREATMENT PLAN REQUEST
{
  TPID
  offerRequest{
    chiefComplaint
    notes
    mouthPictures{
    }
    otherFiles{

    }
  }
}

*/



const Index = props => {
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  var OnClick = (e)=>{
    e.preventDefault();
    window.GlobalUtil.triggerEvent("SlideInRightEvent", {
      TITLE: CONTENT[0],
      closeButtonText: CONTENT[1],
      // saveButtonText: "Create",
      customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog darkHeader",
      width: "1200px",
      //hideSaveButton: true,
      CUSTOMBUTTONS: (curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2" onClick={window.GlobalUtil.DialogStack.closeTop}>{CONTENT[1]}</button>
            {props.customButton && props.customButton()}
          </React.Fragment>
        )
      },
      children: (propsChildren)=>{                                      
        return(
          <div className="dialogCustomZone">
            <ViewCurrentTreatmentPlanRequest 
              {...props}
              CONTENT={CONTENT}
            />
          </div>
        )
      }
    });
  }
  return(
    <React.Fragment>
      {
        props.customButtonText
        ? props.customButtonText(OnClick)
        : <div className="button button1 d-flex btn-color1 previewButtonIcon" onClick={OnClick}>
            <div className="buttonIcon">
              <i className="fas fa-eye" style={{"cursor": "pointer"}}></i>
            </div>
          </div>
      }
    </React.Fragment>
  )
}






  

const ViewCurrentTreatmentPlanRequest = (props) => {
  var {isValid, loading, curUser, curTreatmentPlan, CONTENT=[]} = props;          
  var createdAt = window.GlobalUtil.deepGetFromString(curTreatmentPlan, "meta,createAt", null);
  if(createdAt) createdAt = `${window.GlobalUtil.dateBasic(createdAt, "DAY")}`
  var images = (curTreatmentPlan && curTreatmentPlan.offerRequest && curTreatmentPlan.offerRequest.mouthPictures) 
    ? curTreatmentPlan.offerRequest.mouthPictures.map((mouthPicture, index)=>{
        var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
        return {
          url: mouthPicture.url,
          title: `${userName}${mouthPicture.name}`,
          name: `${userName}${mouthPicture.name}`
        }
      })
    : [];

  var mouthScanFilesArray = (curTreatmentPlan && curTreatmentPlan.offerRequest && curTreatmentPlan.offerRequest.otherFiles) 
    ? curTreatmentPlan.offerRequest.otherFiles.map((mouthPicture, index)=>{
        var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
        return {
          url: mouthPicture.url,
          title: `${userName}${mouthPicture.name}`,
          name: `${userName}${mouthPicture.name}`
        }
      })
    : [];

  var PlanPDF = ((curTreatmentPlan && curTreatmentPlan.planFiles) ? curTreatmentPlan.planFiles.filter(obj=>obj.type === "application/pdf")[0] : {});
  if(!PlanPDF) PlanPDF = {};

  return (
    <div id="LabViewTreatmentPlanRequest" className="animated slideInRight">
      <div className="sectionDivider"><div className="sectionDividerBasic"><span className="sectionDividerText">{CONTENT[2]}</span><span className="sectionDividerBorder"></span></div></div>
      <div className="currentInfoZones">
        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[5]}</div>
          <div className="info">
            <div className="sectionValueZone">
              <SectionValue
                section={"Name"}
                value={`${curUser.firstName} ${curUser.lastName}`}
              />
              <SectionValue
                section={"User ID"}
                value={curUser._id}
              />
            </div>
          </div>
        </div>


        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[6]}</div>
          <div className="info">
            <div className="sectionValueZone">
              <SectionValue
                section={"Treatment Plan ID"}
                value={curTreatmentPlan._id}
              />
              <SectionValue
                section={"Created At"}
                value={createdAt}
              />
              <SectionValue
                section={"Status"}
                value={curTreatmentPlan.status}
              />
            </div>
          </div>
        </div>

        {
          PlanPDF && 
          <div className="currentInfoZone">
            <div className="sectionTitle">{CONTENT[7]}</div>
            <div className="info" style={{"paddingTop": "10px"}}>
              <iframe width="100%" height="1000px" src={PlanPDF.url}></iframe>
            </div>
          </div>
        }

        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[9]}</div>
          <div className="mouthScanFiles">
            {
              curTreatmentPlan && curTreatmentPlan.planFiles && curTreatmentPlan.planFiles.length &&
              curTreatmentPlan.planFiles.map((planFile, index)=>{
                var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
                var fulName = `${userName}${planFile.name}`;
                return(
                  <div className="downloadFile" key={index}>
                    <div className="fileName">{fulName}</div>
                    <a className="button button2 downloadButton" href={planFile.url} target="_blank" download={fulName}>{CONTENT[10]}</a>
                  </div>
                )
              })
            }
          </div>
        </div>


        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[8]}</div>
          <div className="initalPictures">
            {
              curTreatmentPlan && curTreatmentPlan.offerRequest && curTreatmentPlan.offerRequest.mouthPictures.length &&
              curTreatmentPlan.offerRequest.mouthPictures.map((mouthPicture, index)=>{
                var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
                return(
                  <div className="initalPicture" key={index}>
                    <img src={mouthPicture.url} onClick={()=>{
                      var imagesNew = [...images.slice(index), ...images.slice(0, index)];
                      window.GlobalUtil.triggerEvent("ShowLightboxImages", imagesNew)
                    }} />
                  </div>
                )
              })
            }
          </div>
        </div>




        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[11]}</div>
          <div className="notes">
            {window.GlobalUtil.deepGetFromString(curTreatmentPlan, "offerRequest,chiefComplaint", "")}
          </div>
        </div>

        {
          window.GlobalUtil.deepGetFromString(curTreatmentPlan, "offerRequest,notes", false) && 
          <div className="currentInfoZone">
            <div className="sectionTitle">{CONTENT[12]}</div>
            <div className="notes">
              {window.GlobalUtil.deepGetFromString(curTreatmentPlan, "offerRequest,notes", "")}
            </div>
          </div>
        }
      </div>
    </div>
  );
}








export default Index;



