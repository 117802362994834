//import React from 'react';
const PAGE = [
  {
    "content": `Inicio`
  },
  {
    "content": `Crear: Solicitudes de pacientes`
  },
  {
    "content": `Creando: Planes de Pacientes`
  },
  {
    "content": `Aprobar: Planes de los pacientes`
  },
  {
    "content": `Terminado: Pacientes`
  }
];




  
export default PAGE;
