
const PAGE = [
  {
    "content": `Inicio`
  },
  { 
    "type": "div",
    "className": "itemHeaderText",
    "content": `CREAR: <span style="font-weight:400">Ofertas de planes de tratamiento</span>`
  },
  { 
    "type": "div",
    "className": "itemHeaderText",
    "content": `ENVIAR: <span style="font-weight:400">Planes de tratamiento completo</span>`
  }
];




  
export default PAGE;