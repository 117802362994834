//import React from 'react';
const PAGE = {
  "ENGLISH": {
    "HEADER": {
      "BASEURL": "https://smiles.club",
      "TITLE": "Smiles Club | Clear aligners made of premium materials without harmful toxins",
      "DESCRIPTION": "Which clear aligners are the best? Smiles Club's! Safest USA made clear aligners with no hidden fees. Risk free orthodontics.",
      "KEYWORDS": "Best in Clear Aligners, Teeth Straightening Treatments, Smiles.Club, Smiles Club, clear treatment, state-of-the-art Clear Braces, risk free experience, flexible payment options, compare aligners, price compare"
    },
    "CONTENT": {
      "Title": [
        {
          "type": "div",
          "content": `<h1 class="pageHeader"><i class="fas fa-money-check"></i> Billing</h1>`
        },
      ],
      "Pending": [
        {
          "type": "div",
          "content": `<div class="">Pending Payments.</div>`
        },
        {
          "content": "Date Charged"
        },
        {
          "content": "Order ID"
        },
        {
          "content": "Products"
        },
        {
          "content": "Sub Total"
        },
        {
          "content": "Total"
        },
        {
          "content": "Preview"
        },
      ],
      "Subscription": [
        {
          "type": "div",
          "content": `Upcoming Subscription Payment`
        },
        {
          "content": "Schedualed For"
        },
        {
          "content": "Order ID"
        },
        {
          "content": "Products"
        },
        {
          "content": "Sub Total"
        },
        {
          "content": "Total"
        },
        {
          "content": "Preview"
        },
      ],
      "BillingHistory": [
        {
          "type": "div",
          "content": `Billing History`
        },
        {
          "content": "Paid Date"
        },
        {
          "content": "Order ID"
        },
        {
          "content": "Products"
        },
        {
          "content": "Sub Total"
        },
        {
          "content": "Total"
        },
        {
          "content": "Preview"
        }
      ],
      "Commissions": [
        {
          "type": "div",
          "content": `Commissions Paid`
        },
        {
          "content": "Paid Date"
        },
        {
          "content": "Check Number"
        },
        {
          "content": "Total"
        },
        {
          "content": "Preview"
        }
      ]
    }
  },
  "ESPAÑOL": {
    "HEADER": {
      "BASEURL": "https://sonrisas.club",
      "TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
      "DESCRIPTION": "¿Qué alineadores transparentes son los mejores? ¡Club Sonrisas! Safest USA fabricó alineadores transparentes sin tarifas ocultas. Ortodoncia sin riesgos.",
      "KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
    },
    "CONTENT": {
      "Title": [
        {
          "type": "div",
          "content": `<h1 class="pageHeader"><i class="fas fa-money-check"></i> Facturación</h1>`
        },
      ],
      "Pending": [
        {
          "type": "div",
          "content": `<div class="">Pagos pendientes</div>`
        },
        {
          "content": "Fecha de carga"
        },
        {
          "content": "Orden ID"
        },
        {
          "content": "Productos"
        },
        {
          "content": "Sub Total"
        },
        {
          "content": "Total"
        },
        {
          "content": "Avance"
        },
      ],
      "Subscription": [
        {
          "type": "div",
          "content": `Próximo pago de suscripción`
        },
        {
          "content": "Schedualed For"
        },
        {
          "content": "Orden ID"
        },
        {
          "content": "Productos"
        },
        {
          "content": "Sub Total"
        },
        {
          "content": "Total"
        },
        {
          "content": "Avance"
        },
      ],
      "BillingHistory": [
        {
          "type": "div",
          "content": `Historial de facturación`
        },
        {
          "content": "Paid Date"
        },
        {
          "content": "Orden ID"
        },
        {
          "content": "Productos"
        },
        {
          "content": "Sub Total"
        },
        {
          "content": "Total"
        },
        {
          "content": "Avance"
        }
      ],
      "Commissions": [
        {
          "type": "div",
          "content": `Commissions Paid`
        },
        {
          "content": "Paid Date"
        },
        {
          "content": "Check Number"
        },
        {
          "content": "Total"
        },
        {
          "content": "Preview"
        }
      ]
    }
  }
};



export default PAGE;


