import React from 'react';
// OIID
// UID: Schema.Types.ObjectId, 
// scheduledAt: {type: Date}, //WHEN THE ALIGNER SHOULD BE MADE
// shippedAt: {type: Date},
// createdAt: {type: Date}, //THIS IS WHEN THE PHYSICAL ALIGNER WAS CREATED NOT WHEN THE DB OBJECT WAS LIKE IN META
// createdBy: Schema.Types.ObjectId,
// status: {type: String, default: "PENDING"}, //PENDING, ACTIVE, INACTIVE, PAUSED, 
// step: {type: String, default: "MAKE"}, 
// aligners {
//   TPID: Schema.Types.ObjectId, 
//   AGID: Schema.Types.ObjectId, 
//   UID: Schema.Types.ObjectId, 
//   files: [
//     {
//       createAt: {type: Date, default: Date.now()},
//       url: {type: String, default: ""},
//       name: {type: String, default: ""},
//       type: {type: String, default: ""},
//       id: {type: String, default: mongoose.Types.ObjectId()}
//     }
//   ],
//   status: {type: String, default: "PENDING"}, //PENDING, ACTIVE, INACTIVE, PAUSED, 
//   meta: {
//     updateAt: {type: Date, default: Date.now()},
//     createAt: {type: Date, default: Date.now()}
//   }
// }
const PREVIEW_FORM = ()=>[
  {
    "TYPE": "CUSTOM_CODE",
    "CODE": (obj)=>{
      if(!obj) return null;
      //console.log(`obj`,"\n\n",obj.alignerGroups,"\n\n");
      return(<OrderPreview order={obj} />)
    }
  }
]




const OrderInfo = [
  {
    path: "_id",
    name: "Order ID",
    type: "TEXT"
  },
  {
    path: "chargingAt",
    name: "Charging At",
    type: "DATE"
  },
  {
    path: "paidAt",
    name: "Paid At",
    type: "DATE"
  },
  // {
  //   path: "couponID",
  //   name: "Coupon ID",
  //   type: "TEXT"
  // },
  // {
  //   path: "comment",
  //   name: "Comment",
  //   type: "TEXT"
  // },
  // {
  //   path: "status",
  //   name: "Status",
  //   type: "TEXT"
  // }
];

const COST = [
  {
    "PATH": "subtotal",
    "NAME": "Subtotal"
  },
  {
    "PATH": "discount",
    "NAME": "Discount"
  },
  {
    "PATH": "tax",
    "NAME": "Tax"
  },
  {
    "CONDITIONAL": true,
    "PATH": "setupFee",
    "NAME": "Setup Fee"
  },
  {
    "PATH": "shipping",
    "NAME": "Shipping"
  },
  {
    "PATH": "total",
    "NAME": "Total"
  }
];

const OrderPreview = props => {
  const {order} = props;
  const productsArray = JSON.parse(order.productsStringArray);
  return (
    <div id="OrderPreview">
      <div className="sectionHeader">Order info</div>
      <div className="orderInfo">
        {
          OrderInfo.map((orderInfoItem, index)=>{
            return(
              <InfoList 
                key={index}
                name={orderInfoItem.name}
                value={window.GlobalUtil.deepGetFromString(order, orderInfoItem.path, "")}
              />
            )
          })
        }
      </div>
      <hr/>
      <br/>
      <div className="sectionHeader">Order Poduct List</div>
      <PoductList
        productsArray={productsArray}
      />
      <br/>
      <div className="reciptZone">
        <div className="sectionHeader">Total Cost</div>
        <div className="recipt">
          {
            COST.map((costItem, index)=>{
              if(costItem.CONDITIONAL && !order.cost[costItem.PATH]) return null; 
              return(
                <div className={`reciptItem ${COST.length-1 === index ? "total" : ''}`} key={index}>
                  <div className="name">
                    {costItem.NAME}
                  </div>
                  <div className="value">
                    {window.GlobalUtil.convertToMoney(Number(order.cost[costItem.PATH]))}
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}






          



const InfoList = ({name, value})=>{
  return(
    <div className="infoList">
      <div className="infoName">
        {name}
      </div>
      <div className="infoValue">
        {value}
      </div>
    </div>
  )
}



const PoductList = ({productsArray})=>{
  return(
    <table className="table orderProducts">
      <thead className="thead-dark">
        <tr>
          <th className="">Image</th>
          <th className="">Name</th>
          <th className="">Quantity</th>
          <th className="">Base Price</th>
          <th className="">Discount</th>
          <th className="">Product Total</th>
        </tr>
      </thead>
      <tbody>
        {
          productsArray.map((product, index)=>{
            console.log(`product`,"\n\n",product,"\n\n");
                  
            var total = Number(product.cost)-Number(product.discount);
            if(product.costWithDiscount) total = Number(product.costWithDiscount)
            var cost = product.cost ? Number(product.cost) : (product.costWithDiscount ? Number(product.costWithDiscount) : 0);
            var discount = product.discount ? Number(product.discount) : 0;
            return(
              <tr key={index}>
                <th><img style={{"width":"50px"}} src={(product.images && product.images[0] && product.images[0].url) ? product.images[0].url : ""}/></th>
                <th>{product.name}</th>
                <th>{product.quantity}</th>
                <th>{window.GlobalUtil.convertToMoney(cost)}</th>
                <th>{window.GlobalUtil.convertToMoney(discount)}</th>
                <th>{window.GlobalUtil.convertToMoney(total)}</th>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}






export default PREVIEW_FORM;