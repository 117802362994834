import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';



ChartJS.register(
  ArcElement,
  Tooltip,
  //Legend
);

const CouponColors = [
  '#00bae3',
  //'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  "#eb008b",
  'rgba(255, 99, 132, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
]


const Index = (props) => {
  var {
    labels=[],
    dataArray=[],
    affiliateSales={},
    totalConversions=0,
    CONTENT=[]
  } = props;
  
  return (
    <div id="Chart" className="">
      <div className="itemHeader">
        <div className="itemHeaderTextZone">
          <div className="itemHeaderText withIcon itemHeaderTextCombo">
            <div className="icon"><i className="fas fa-chart-pie"></i></div>
            <div className="text">{CONTENT.Chart[0]}</div>
          </div>
        </div>
      </div>
      <div className="chart">
        <Doughnut 
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
                //position: 'top',
              },
              tooltip: {
                // Disable the on-canvas tooltip
                enabled: false,
              },
              title: {
                display: false,
                // text: "Number of Sales",
              },
            },
          }} 
          data={{
            //labels: labels,
            labels: ((labels && labels.length) ? labels : ["none"]),
            datasets: [
              {
                label: CONTENT.Chart[1],
                data: ((dataArray && dataArray.length) ? dataArray : [1]),
                backgroundColor: (labels.length ? CouponColors.slice().splice(0,labels.length) : ['rgba(224, 224, 224, 1)']),
                // borderColor: [
                //   'rgba(255, 99, 132, 1)',
                //   'rgba(54, 162, 235, 1)',
                //   'rgba(255, 206, 86, 1)',
                //   'rgba(75, 192, 192, 1)',
                //   'rgba(153, 102, 255, 1)',
                //   'rgba(255, 159, 64, 1)',
                // ],
                borderWidth: 1,
                //offset: 5,
                spacing: 2
              },
            ],
          }} 
        />
        <div className="numApproved">
          <div className="title">{CONTENT.Chart[2]}</div>
          <div className="number">{totalConversions}</div>
        </div>
      </div>
      <div className="labels">
          {
            (affiliateSales && affiliateSales.length)
            ? affiliateSales.map((coupon, index)=>{
              var couponCode = coupon[0].CODE;
              var EARNED = coupon.reduce((cur, newVal)=>{return(cur+Number(newVal.EARNED ? newVal.EARNED : 0))},0);
              return(
                <div className="label" key={index}>
                  <div className="colorBox" style={{"backgroundColor": CouponColors[index]}}></div>
                  <div className="title">{couponCode}</div>
                  <div className="earned">{coupon.length}</div>
                </div>
              )
            })
            : null
          }
      </div>
    </div>
  );
}


export default Index;
      
