import React from 'react';
import 'styles/scss/website/components/simpleSteps.scss';
import Step1 from 'global/img/infoVectors/Step1.jpg';
// import Step2 from 'global/img/infoVectors/Step2.jpg';
// import Step3 from 'global/img/infoVectors/Step3.jpg';
import Step4 from 'global/img/infoVectors/Step4.jpg';
import Step5 from 'global/img/infoVectors/Step5.jpg';


import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import LayoutBuilder from 'LayoutBuilder';

const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = LayoutBuilder;

const Index = props => {
  const CONTENT = (props.CONTENT ? window.GlobalUtil.dangerSetGlobal(props.CONTENT) :  window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language])); 
        
  return (     
    <div id="SimpleSteps" className="containerSection">
      <Container>
        {/*STEP 1*/}
        <Row className="howItWorks">
          <Column className="contentCenter" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <div className="">
              <div className="fullSizeImage">
                <img 
                  src={Step1} 
                  alt="women researching clear aligners on her laptop"
                />
                <br/>  
                <br/> 
              </div>
              <div className="step">
                {CONTENT[0]}
              </div>
            </div>
          </Column>
          <Column className="contentCenter" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <div className="">
              <div className="fullSizeImage">
                <img 
                  src={Step4} 
                  alt="women researching clear aligners on her laptop"
                />
                <br/>  
                <br/> 
              </div>
              <div className="step">
                {CONTENT[1]}
              </div>
            </div>
          </Column>
          <Column className="contentCenter" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <div className="">
              <div className="fullSizeImage">
                <img 
                  src={Step5} 
                  alt="women getting clear aligners at her home"
                />
                <br/>  
                <br/> 
              </div>
              <div className="step">
                {CONTENT[2]}
              </div>
            </div>
          </Column>
        </Row>
        <br/> 
        <br/>
      </Container>
    </div>
  );
}


export default Index;
