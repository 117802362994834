//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';

//import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';





const MUTATIONS = {
}


export default MUTATIONS;