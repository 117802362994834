import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import QUERIES from "./QUERIES";
import FormPreview from 'global/components/FormPreview/index';
///Users/danielezaldivar/Sites/sonrisas/src/global/components/FormPreview.js
/*
<PREVIEW
  VARIABLES={{
    id: TPID
  }}
  PREVIEW_FORM, 
  TITLE, 
  SUB_TITLE
/>
*/

const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "CODES",
            "TYPE": "CUSTOM_CODE",
            "NAME": "",
            "CODE": (obj)=>{       
              var newDate = new Date(Number(obj.ADMIN_PAID_DATE));       
              return(
                <div className="">
                  <div style={{"fontWeight":"600","marginBottom":"5px","fontSize":"16px","borderBottom":"2px solid #03A9F4","paddingBottom":"5px"}}>Summary:</div>
                  <div id="CommissionSummary" className="tableWrapper">
                    <table className='table'>
                      <thead>
                        <tr>
                          <th style={{"fontSize":"12px","width": "100px"}}>Date Paid</th>
                          <th style={{"fontSize":"12px"}}>Check Number</th>
                          <th style={{"fontSize":"12px", "width": "150px", "textAlign":"right"}}>Total Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{"fontSize":"12px","width": "100px"}}>
                            <span>{newDate.formatDate("n/d/Y")}</span>
                          </td>
                          <td style={{"fontSize":"12px"}}><span className="">{obj.ADMIN_CHECK_NUMBER}</span></td>
                          <td style={{"fontSize":"12px", "width": "150px", "textAlign":"right"}}><span className="">{window.GlobalUtil.convertToMoney(obj.TOTAL)}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            }
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "CODES",
            "TYPE": "CUSTOM_CODE",
            "NAME": "",
            "CODE": (obj)=>{              
              return(
                <div className="">
                  <div style={{"fontWeight":"600","marginBottom":"5px","fontSize":"16px","borderBottom":"2px solid #03A9F4","paddingBottom":"5px"}}>Commissions:</div>
                  <div id="CommissionsList" className="tableWrapper">
                    {
                      (obj.CODES && obj.CODES.length)
                      ?  <table className='table'>
                            <thead>
                              <tr>
                                <th style={{"fontSize":"12px","width": "100px"}}>Date Sold</th>
                                <th style={{"fontSize":"12px"}}>Code</th>
                                <th style={{"fontSize":"12px", "width": "100px", "textAlign":"right"}}>Commission</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                (obj.CODES && obj.CODES.length)
                                ? obj.CODES.map((code, index) => {
                                  var newDate = new Date(Number(code.DATE));
                                    return(
                                      <tr key={index}>
                                        <td style={{"fontSize":"12px","width": "100px"}}>
                                          <span>{newDate.formatDate("n/d/Y")}</span>
                                        </td>
                                        <td style={{"fontSize":"12px"}}><span className="">{code.CODE}</span></td>
                                        <td style={{"fontSize":"12px", "width": "100px", "textAlign":"right"}}><span className="">{window.GlobalUtil.convertToMoney(code.EARNED)}</span></td>
                                      </tr>
                                    )
                                  })
                                : null
                              }
                            </tbody>
                          </table>
                      : null
                    }
                  </div>
                </div>
              )
            }
          }
        ]
      }
    ]
  }
];

function PREVIEW(props) {     
  var {VARIABLES={}, PREVIEW_FORM, TITLE, SUB_TITLE} = props;
        
  var NewObj = {...VARIABLES};  
  window.CurObj = {...NewObj, id: NewObj._id};
  window.CurRefetchEdit = ()=>{};        
  if(props.onLoad) props.onLoad(window.CurObj);                
  return (
    <FormPreview
      TITLE={(TITLE !== undefined) ? TITLE : "Preview"}
      SUB_TITLE={(SUB_TITLE !== undefined) ? SUB_TITLE : "Full Preview of current order."}
      NewObj={NewObj}
      PREVIEW_FORM={(PREVIEW_FORM ? PREVIEW_FORM : DEFAULT_PREVIEW_FORM())}
    />
  );
}



export default PREVIEW;