import React from 'react';


const Index = {
  title: (TemplateDBObj)=>{
    return "New "+TemplateDBObj.TITLE;
  },
  onClose: (that, TEMP_UID, TemplateObj)=>{ //THIS FUNCTION RUNS AFTER THE ONCLOSE FUNCTION INSIDE TemplateSlideInRight RUNS, AND IT'S ALL CLOSED.
    var TemplateDBObj = {...that.state.TemplateDBObj};
    delete TemplateDBObj.NEW[TEMP_UID];
    that.setState({TemplateDBObj}, ()=>{
      if(that.SHOULDRELOAD) window.GlobalUtil.triggerEvent("APIMutationCalled", {
        NAME: TemplateObj.NAME,
        MUTATION: "create"
      });
      that.SHOULDRELOAD = false;
    });
  },
  onSave: (that, TEMP_UID)=>{
    if(that.TemplateDBNewRef[TEMP_UID]) that.TemplateDBNewRef[TEMP_UID].onSave(
      (onSuccess)=>{
        if(that.SlideInRightRef[TEMP_UID]){
          that.SHOULDRELOAD = true;
          that.SlideInRightRef[TEMP_UID].onClose();
        }
      },
      (onFail)=>{
        if(that.SlideInRightRef[TEMP_UID]){
          that.SHOULDRELOAD = true;
          that.SlideInRightRef[TEMP_UID].onClose();
        }
      }
    );
  },
  customButtons: (that, TEMP_UID, TemplateDBObj)=>{
    return (TemplateDBObj.CUSTOMBUTTONS 
      ? ()=>{                    
        return TemplateDBObj.CUSTOMBUTTONS(that.SlideInRightRef[TEMP_UID], that.TemplateDBNewRef[TEMP_UID], TemplateDBObj);
      } 
      : null
    )
  }
}
export default Index;
      
