import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import API_CALLS from 'API_CALLS/index';


const MUTATION_DELETE_FILE = API_CALLS.S3.MUTATIONS.deleteS3File();
const MUTATION_GET_S3_SIGN = API_CALLS.S3.MUTATIONS.getS3Sign();


class FileUploads {
  constructor() {
    this.events = {};
  }
  
  UploadFile(filePath, file, callBack=()=>{}){
    var fileType = (file.type ? file.type : file.name.split(".").slice(-1)[0]);
    //console.log("fileType","\n\n",fileType,"\n\n");

    function API_UPLOAD_FILE(file, url, signedRequest){
      const options = {
        headers: {
          "Content-Type": fileType,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET'
        }
      };
            
      return axios.put(signedRequest, file, options)
        .then(obj=>{
          return callBack({
            name: file.name,
            type: fileType,
            url: url
          });
        })
        .catch(error=>{
          console.log("API_UPLOAD_FILE error", error);
        });
    }

    

    function API_GET_SIGNATURE(filePath, file){
      //console.log("API_GET_SIGNATURE: ", filePath.join("/"), file);
      const CurrentTime = new Date().getTime();
      var fileName = `${filePath.slice(0,-1).join("/")}/${CurrentTime}_${filePath.slice(-1)[0]}`;
      if(process.env.NODE_ENV === 'development') fileName = 'TEST/'+fileName;
      return window.Client.mutate({
        mutation: MUTATION_GET_S3_SIGN,
        variables: {
          filename: fileName,
          filetype: fileType,
        }
      })
      .then(result => {
        var {signedRequest, url} = window.GlobalUtil.deepGetFromString(result, "data,getS3Sign", {});
        // console.log("signedRequest, url");
        // console.log(signedRequest, url,"\n\n");
        if(!signedRequest || !url){
          console.log("FAILED TO GET UPLOAD SIGNED REQUEST");
          return;
        }
        // console.log("result.data.getS3Sign");
        // console.log(result.data,"\n\n");
        return API_UPLOAD_FILE(file, url, signedRequest);      
      })
      .catch((error)=>{
        console.log("API_GET_SIGNATURE ERROR");
        console.log(error,"\n\n");
              
      });
    }


    return API_GET_SIGNATURE(filePath, file)
  }


  DeleteFile(fileURL, category="files", callBack=()=>{}){
    if(!fileURL) return callBack("failed", "DeleteFile Failed No fileURL", fileURL);
    //const fileName = fileURL.slice(fileURL.search(category)) //THIS IS WHERE THE LINK START EXAMPLE: ("https://sonrisas1.s3.amazonaws.com/files/UID/FILE_NAME") BECOMES ("/files/UID/FILE_NAME")
    var fileUrlArray = fileURL.split("/")
    var fileName = fileUrlArray.slice(3).join("/");
    if(!fileName) return callBack("success", "DeleteFile Failed No fileName", fileName);
    window.GlobalUtil.consoleLogNew({LOCATION:`src/global/utils/file-uploads.js`, NAME:"fileName", CONTENT:[fileName]});
    if((typeof fileName !== "string") || (fileName.length < 5)) return callBack("failed", "DeleteFile Failed No fileName found", fileName);
    //console.log(`fileName`,"\n\n",fileName,"\n\n");
          
    return window.Client.mutate({
      mutation: MUTATION_DELETE_FILE,
      variables: {
        filename: fileName
      }
    })
    .then(obj=>{
      return callBack("success");
    })
    .catch(error=>{
      console.log("error", error);
      return callBack("failed", "DeleteFile window.Client.mutate Failed catch", error);
    });
  }
}



export default FileUploads;

