import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import {FULL_MUTATION_FIELDS as FULL_MUTATION_ALIGNER_GROUPS} from "../ALIGNER_GROUPS/index";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_FIELDS_USER} from "../USERS/index";
import PREVIEW_FORMS from "./PREVIEW_FORMS/index";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
// var GetUSER = async () => {
//   let {DEFAULT_MUTATION_FIELDS} = await import('../USERS/index');
//   return DEFAULT_MUTATION_FIELDS;
// };

// var USER_DEFAULT_MUTATION_FIELDS = import("../USERS/index");
// var DEFAULT_FIELDS_USERS = USER_DEFAULT_MUTATION_FIELDS.DEFAULT_MUTATION_FIELDS;
const DEFAULT_MUTATION_FIELDS = `
  ID
  UID
  OID
  AGIDS
  status
  type
  started
  whenLastPaid
  subscriptionID
  planFiles{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  LABORATORY
  DOCTOR
  createdByName
  approvedByName
  totalNumOfAligners

  alignersSchedule{
    numOfAlignersTop
    numOfAlignersBottom
    started
    alignerHistory {
      number
      alignerTop
      alignerBottom
      startedAt
      endsAt
      pictures{
        ${MUTATION_FIELDS_DEFAULT.FILE}
      }
    }
  }

  offerRequest{
    chiefComplaint
    notes
    createAt
    mouthPictures{
      ${MUTATION_FIELDS_DEFAULT.FILE}
    }
    otherFiles{
      ${MUTATION_FIELDS_DEFAULT.FILE}
    }
  }
  notes
  rejectionNotes
  rejectStepNotes
  step

  HISTORY{
    LABORATORY{
      UPLOADED_OFFER
      UPLOADED_FULL
      PAID_FOR_OFFER
      PAID_FOR_FULL
    }
    DOCTOR{
      APPROVED_PLAN
      PAID_FOR_APPROVAL
    }
  }

  progressLogs{
    ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
  }
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
  alignerFilesTemp{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
`;


const FULL_MUTATION_FIELDS = (include=["alignerGroups"])=>{
  var IncludeDic = {
    user: `
      user{
        _id
        ${DEFAULT_MUTATION_FIELDS_USER}
      }
    `,
    alignerGroups: `
      alignerGroups {
        _id
        ${FULL_MUTATION_ALIGNER_GROUPS()}
      }
    `
  };
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  include.map((key, index)=>{
    if(IncludeDic[key]) FullMutation += IncludeDic[key];
  })
  return FullMutation;
};


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;

const DEFAULT_MUTATION_INPUT_VAR = ` 
  $UID: String,
  $OID: String,
  $AGIDS: [String],
  $status: String,
  $type: String,
  $started: String,
  $progressLogs: [NotesListItemInput], 
  $whenLastPaid: String,
  $subscriptionID: String,
  $planFiles: [FileObjectInput],
  $offerRequest: OfferRequestInput,
  $LABORATORY: String,
  $DOCTOR: String,
  $totalNumOfAligners: Int,
  $alignersSchedule: AlignersScheduleInput,
  $notes: String,
  $rejectionNotes: String,
  $rejectStepNotes: String,
  $step: String,
  $HISTORY: HISTORYInput,
  $alignerFilesTemp: [FileObjectInput],
  $changeStep: Int,
`;


const DEFAULT_MUTATION_INPUT = ` 
  UID: $UID,
  OID: $OID,
  AGIDS: $AGIDS,
  status: $status,
  type: $type,
  started: $started,
  progressLogs: $progressLogs,
  whenLastPaid: $whenLastPaid,
  subscriptionID: $subscriptionID,
  planFiles: $planFiles,
  offerRequest: $offerRequest,
  LABORATORY: $LABORATORY,
  DOCTOR: $DOCTOR,
  totalNumOfAligners: $totalNumOfAligners,
  alignersSchedule: $alignersSchedule,
  notes: $notes,
  rejectionNotes: $rejectionNotes,
  rejectStepNotes: $rejectStepNotes,
  step: $step,
  HISTORY: $HISTORY,
  alignerFilesTemp: $alignerFilesTemp,
  changeStep: $changeStep
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "UID": "string",
  "OID": "string",
  "status": "string",
  "type": "string",
  "started": "string",
  "whenLastPaid": "string",
  "subscriptionID": "string",
  "LABORATORY": "string",
  "DOCTOR": "string",  
  "rejectStepNotes": "string",
  "step": "string",
  "planFiles": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "progressLogs": [
    CLEAN_FIELDS_DEFAULT.NOTESLIST
  ], 
  "offerRequest,chiefComplaint": "string",
  "offerRequest,notes": "string",
  "offerRequest,createAt": "string",
  "offerRequest,mouthPictures": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "offerRequest,otherFiles": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "totalNumOfAligners": "number",
  "alignersSchedule": {
    "numOfAlignersTop": "number",
    "numOfAlignersBottom": "number",
    "started": "string",
    "alignerHistory": [
      {
        "number": "number",
        "alignerTop": "number",
        "alignerBottom": "number",
        "startedAt": "string",
        "endsAt": "string",
        "pictures": [
          CLEAN_FIELDS_DEFAULT.FILE
        ]
      }
    ]
  },
  "notes": "string",
  "rejectionNotes": "string",
  "alignerFilesTemp": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "HISTORY": {
    "LABORATORY": {
      "UPLOADED_OFFER": "string",
      "UPLOADED_FULL": "string",
      "PAID_FOR_OFFER": "string",
      "PAID_FOR_FULL": "string"
    },
    "DOCTOR": {
      "APPROVED_PLAN": "string", 
      "PAID_FOR_APPROVAL": "string"
    }
  }
}





const LIST_HEADER_FIELDS = [
  {
    type: "VALUE",
    name: "Step",
    defaultValue: "",
    path: "step"
  },
  {
    "path": "user.firstName,user.lastName",
    "type": "VALUE",
    "toolTip": true,
    "name": "User Name",
    "sortable": false
  },
  {
    "path": "user.email",
    "type": "VALUE",
    "name": "Email",
    "sortable": false
  },
  {
    "path": "user.role",
    "type": "VALUE",
    "toolTip": true,
    "name": "User Type",
    "sortable": false
  },
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "ID",
    name: "UID",
    defaultValue: "",
    path: "UID",
    searchable: true
  },
  {
    type: "ID",
    name: "OID",
    defaultValue: "NA",
    path: "OID",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "ACTIVE",
    path: "status"
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: ""
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: ""
  }
];



const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.planFiles) delete curCleanObj.planFiles;
  if(!curCleanObj.alignerFilesTemp) delete curCleanObj.alignerFilesTemp;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  console.log(`curCleanObj`,"\n\n",curCleanObj,"\n\n");
  if(!curCleanObj.progressLogs || (curCleanObj.progressLogs.length < 1)) delete curCleanObj.progressLogs;    

  if(!curCleanObj.planFiles) delete curCleanObj.planFiles;
  else curCleanObj.planFiles = curCleanObj.planFiles.filter(file=>(file.url));

  if(!curCleanObj.alignerFilesTemp) delete curCleanObj.alignerFilesTemp;
  else curCleanObj.alignerFilesTemp = curCleanObj.alignerFilesTemp.filter(file=>(file.url));

  if(curCleanObj.offerRequest){
    if(!curCleanObj.offerRequest.mouthPictures) delete curCleanObj.offerRequest.mouthPictures;
    else curCleanObj.offerRequest.mouthPictures = curCleanObj.offerRequest.mouthPictures.filter(file=>(file.url));

    if(!curCleanObj.offerRequest.otherFiles) delete curCleanObj.offerRequest.otherFiles;
    else curCleanObj.offerRequest.otherFiles = curCleanObj.offerRequest.otherFiles.filter(file=>(file.url));
  }

  if(!curCleanObj.alignersSchedule){ //CLEAR ANY MISSING alignersSchedule
    delete curCleanObj.alignersSchedule;
  } else {
    if(!curCleanObj.alignersSchedule.numOfAlignersTop) curCleanObj.alignersSchedule.numOfAlignersTop = 1;
    else curCleanObj.alignersSchedule.numOfAlignersTop = Number(curCleanObj.alignersSchedule.numOfAlignersTop);

    if(!curCleanObj.alignersSchedule.numOfAlignersBottom) curCleanObj.alignersSchedule.numOfAlignersBottom = 1;
    else curCleanObj.alignersSchedule.numOfAlignersBottom = Number(curCleanObj.alignersSchedule.numOfAlignersBottom);

    if(!curCleanObj.alignersSchedule.started) delete curCleanObj.alignersSchedule.started;
    if(!curCleanObj.alignersSchedule.alignerHistory){
      delete curCleanObj.alignersSchedule.alignerHistory;
    } else {
      curCleanObj.alignersSchedule.alignerHistory = curCleanObj.alignersSchedule.alignerHistory.map((obj, index)=>{
        return  {
          "number": (obj.number ? Number(obj.number) : index+1),
          "alignerTop": (obj.number ? Number(obj.number) : index+1),
          "alignerBottom": (obj.number ? Number(obj.number) : index+1),
          "startedAt": (obj.startedAt ? `${obj.startedAt}` : ""),
          "endsAt": (obj.endsAt ? `${obj.endsAt}` : ""),
          "pictures": (obj.pictures ? obj.pictures.filter(file=>(file.url)) : "")
        }
      });
    }
  }


  if(!curCleanObj.HISTORY){ //CLEAR ANY MISSING HISTORY FROM INPUT
    delete curCleanObj.HISTORY;
  } else {
    if(!curCleanObj.HISTORY.LABORATORY){
      delete curCleanObj.HISTORY.LABORATORY;
    } else {
      if(!curCleanObj.HISTORY.LABORATORY.UPLOADED_OFFER) delete curCleanObj.HISTORY.LABORATORY.UPLOADED_OFFER;
      if(!curCleanObj.HISTORY.LABORATORY.UPLOADED_FULL) delete curCleanObj.HISTORY.LABORATORY.UPLOADED_FULL;
      if(!curCleanObj.HISTORY.LABORATORY.PAID_FOR_OFFER) delete curCleanObj.HISTORY.LABORATORY.PAID_FOR_OFFER;
      if(!curCleanObj.HISTORY.LABORATORY.PAID_FOR_FULL) delete curCleanObj.HISTORY.LABORATORY.PAID_FOR_FULL;
    }

    if(!curCleanObj.HISTORY.DOCTOR){
      delete curCleanObj.HISTORY.DOCTOR;
    } else {
      if(!curCleanObj.HISTORY.DOCTOR){
        delete curCleanObj.HISTORY.DOCTOR;
      } else {
        if(!curCleanObj.HISTORY.DOCTOR.APPROVED_PLAN) delete curCleanObj.HISTORY.DOCTOR.APPROVED_PLAN;
        if(!curCleanObj.HISTORY.DOCTOR.PAID_FOR_APPROVAL) delete curCleanObj.HISTORY.DOCTOR.PAID_FOR_APPROVAL;
      }
    }
  } 
        
  return curCleanObj;
}



const TREATMENT_PLANS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}


const DEFAULT_FIELDS_TREATMENT_PLANS = {
  PREVIEW_FORMS,
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
}


export default TREATMENT_PLANS;
export {
  PREVIEW_FORMS,
  FULL_MUTATION_FIELDS,
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_FIELDS_TREATMENT_PLANS
};