import React, { useRef, useEffect } from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import {AddOrderToUser} from 'global/utils/helper-functions';  
/*
SECURITY IDEA:
RIGHT BEFORE I OPEN THE PAYPAL BUTTON, I SHOULD QUERY THE CART PRODUCT AGAIN AND GET THEM FRESH
THEN I SHOULD GET THE COUPON FRESH TOO
THEN I SHOULD RUN THE COUPON PRODUCT GLOBAL UTIL TO CALCULATE THE COST
THIS SHOULD NOT CHANGE ANYTHING UNLESS THEY'VE MESSED WITH THE GLOBAL WINDOW VAR
IF THEY HAVE MODIFIED THE GLOBAL WINDOW VAR (POSSIBLY TO REDUCE OR CHANGE THE PRODUCT PRICE) THEN THIS WILL RESET IT TO IT'S CORRECT PRICE
~MAYBE THERE'S AN EASIER WAY 
*/

//<script src="https://www.paypal.com/sdk/js?client-id=AePM7Mx2qN1TSQo4ERTanQTTa2UTZpr6peCJ2f6phvQ8ky5cIwP_2trh27vkFxgGF5JQQgSu_If5NALH&vault=true&intent=subscription" 



class Paypal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: undefined
    };    

  }

  componentDidMount(){
    //console.log(`Paypal loaded`,"\n\n","\n\n");
          
    var {checkoutCalculator, onSuccess=()=>{}, onFail=()=>{}} = this.props;
    //enable-funding=paylater
    window.paypal
      .Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'paypal'
        },
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            //enable-funding: "paylater",
            purchase_units: [
              {
                description: checkoutCalculator.orderSummary,
                amount: {
                  currency_code: "USD",
                  value: checkoutCalculator.total.toFixed(2),
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log("Paypal onApprove order", order);
          window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/PaypalButtons/index.js`, NAME:"Paypal data", CONTENT:[data]});
          var curOrders = window.GlobalUtil.State.get("newOrder");
          return await AddOrderToUser({
            "paymentOrderID": order.id, 
            "paymentType": "PAYPAL",
            "subscriptionID": null, 
            "curOrders": curOrders,
            "UID": window.Session.user._id, 
            onSuccess, 
            onFail
          })
        },
        onError: (err) => {
          console.log(err);
          if(this.props.onFail) this.props.onFail(err);
        },
      })
      .render(this.PaypalRef);
  }


  render(){
    return (
      <div ref={e=>this.PaypalRef=e}></div>
    );
  }
}


export default Paypal;
