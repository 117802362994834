import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';

  

const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAllProducts(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    products(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAllProductsAndCount(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    productCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    products(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    productDistinct(field:$field)
  }`;



const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query CountAllProducts(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    productCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query Single($id: String){
    product(id:$id){
      _id
      ${whatToPull}
    }
  }`







const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  count: COUNT,
  single: QUERY_SINGLE,
  distinct: QUERY_GET_DISTINCT
}


export default QUERIES;