//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';
import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';











const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreateActivity(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createActivity(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation UpdateActivity(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateActivity(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_COMPLETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CompleteActivity(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    completeActivity(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteActivity(
    $id: String,
  ) {
    deleteActivity(id: $id)
  }
`;


const MUTATION_DELETE_FOREVER = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteActivityRemove(
    $id: String,
  ) {
    deleteActivityRemove(id: $id)
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteActivityList(
    $ids: [String]
  ) {
    deleteActivityList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateActivity(
    $id: String,
  ) {
    deactivateActivity(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateActivityList(
    $ids: [String]
  ) {
    deactivateActivityList(ids: $ids)
  }
`;




const MUTATIONS = {
  create: MUTATION_ADD,
  update: MUTATION_EDIT,
  complete: MUTATION_COMPLETE,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  deleteRemove: MUTATION_DELETE_FOREVER,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;