import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';

  

const GET_SITE_SETTINGS = (whatToPull=DEFAULT_QUERY_FIELDS)=>gql`
  query GetSiteSetting($id: String, $include: [Include]){
    siteSetting(id:$id, include:$include){
      _id
      ${whatToPull}
    }
  }
`;

const GET_HAND_SHAKE_TOKEN = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetHandShakeToken{
    getHandShakeToken
  }
`;

//GIVE IT A TOKEN AND IT WILL RETURN 'true' OR THE ERROR WITH THAT TOKEN
// 'true', 'TokenExpiredError', 'TokenUsedError' OR ANY OTHER ERROR
const CHECK_TOKEN_ACTIVE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query CheckToken($token: String){
    tokenCheck(token: $token)
  }
`;


const QUERIES = {
  single: GET_SITE_SETTINGS,
  getHandShakeToken: GET_HAND_SHAKE_TOKEN,
  tokenCheck: CHECK_TOKEN_ACTIVE
}


export default QUERIES;