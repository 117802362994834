import React, { useRef, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {AddOrderToUser} from 'global/utils/helper-functions'; 
import API_CALLS from 'API_CALLS/index';
const NEW_ORDER = API_CALLS.ORDERS.MUTATIONS.create();



function TestCheckoutButton(props) {
  var {checkoutCalculator={}, paypalID} = props;
  console.log(`props`,"\n\n",props,"\n\n");
        
  return (
    <div className="center">
      <button className="button button1" onClick={()=>{
        var FakeOrderID = `fake-${window.GlobalUtil.getRandomId()}`;
        var curOrders = window.GlobalUtil.State.get("newOrder");
        AddOrderToUser({
          "paymentType": "PAYPAL",
          "curOrders": curOrders,
          "UID": window.Session.user._id, 
          "paymentOrderID": FakeOrderID, 
          "subscriptionID": null, 
          "onSuccess": (order)=>{
            console.log('order', order)
            if(!order) return;
            order.id = order._id;
            props.onSuccess(order);
          },
          "onFail":(fail)=>{
            console.log(`fail`,"\n\n",fail,"\n\n");
                  
            props.onFail();
          }
        })
      }}>Test Buy Now</button>
      {/* <div className="text-center">{paypalID ? "Subscription" : "Not Subscription"}</div> */}
    </div>
  );
}
  
export default TestCheckoutButton;