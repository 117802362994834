import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from 'global/templates/templatePage/index';
import ActionPreview from "./actions/ActionPreview";



const QUERY = API_CALLS.TRACKING.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.TRACKING.QUERIES.single();
const QUERY_COUNT = API_CALLS.TRACKING.QUERIES.count();
const MUTATION_EDIT = API_CALLS.TRACKING.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.TRACKING.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);
const FORM_FIELDS = API_CALLS.TRACKING.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.TRACKING.LIST_HEADER_FIELDS;

const PREVIEW = API_CALLS.TRACKING.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};








const PAGES = () => [
  {
    "id": "TRACKING01",
    "Name":"Active",
    "PageName": "Active",
    "BaseName": "tracking",
    "Path": "active",
    "TITLE": "Lead Tracking",
    "DATA_FIELD": "trackings",
    "GlobalStateVar": ["trackingCountActive"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "TRACKING",
      TITLE: "Tracking",
      FIELD: "tracking"
    },
    "EDITBUTTON": {
      NAME: "TRACKING",
      TITLE: "Tracking",
      FIELD: "tracking"
    },
    "DELETEBUTTON": {
      NAME: "TRACKING"
    },
    "CLONEBUTTON": {
      NAME: "TRACKING",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("tracking/history");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Tracking",
            "TITLE": "Tracking", //TITLE OF SLIDE OUT
            "APINAME": "TRACKING",
            "NAME": "Tracking",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "TRACKING02",
    "Name":"History",
    "PageName": "History",
    "BaseName": "tracking",
    "Path": "history",
    "TITLE": "Tracking History",
    "DATA_FIELD": "trackings",
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "TRACKING",
      TITLE: "Tracking",
      FIELD: "tracking"
    },
    "EDITBUTTON": {
      NAME: "TRACKING",
      TITLE: "Tracking",
      FIELD: "tracking"
    },
    "DELETEBUTTON": {
      NAME: "TRACKING"
    },
    "CLONEBUTTON": {
      NAME: "TRACKING",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("tracking/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Tracking",
            "TITLE": "Tracking", //TITLE OF SLIDE OUT
            "APINAME": "TRACKING",
            "NAME": "Tracking",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  }
];








const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminTracking"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "trackingCount",
            TITLE: "New Tracking Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "trackingCount",
            TITLE: "Tracking this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "trackingCount",
            TITLE: "Tracking this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "trackingCount",
            TITLE: "Tracking this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/",
        "to": "active"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
