//import React from 'react';
const PAGE = [
  {
    "content": `Pacientes`
  },
  {
    "content": `Órdenes activas`
  },
  {
    "content": `Pedidos sin pagar`
  },
  {
    "content": `Pedidos terminados`
  }
];




  
export default PAGE;
