import React, { useState, useRef } from 'react';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";
import NumberFormat from 'react-number-format';
import FileUploadList from  "./comp-fileUploadList";
import DatePicker from "./comp-datePicker";
import WizyWig from "./comp-wysiwyg";
import APISelectBox from "./comp-APISelectBox";
import APIUpdateCouponUserCode from "./comp-APIUpdateCouponUserCode";
import AddProducts from "./comp-AddProducts";
import APISelectBoxWithAdd from "./comp-APISelectBoxWithAdd"; //SAME AS API SELECT BOX BUT THIS ONE LETS YOU ADD A NEW FIELD TOO
import EditableImages from "./comp-editableImages";
import EditableImage from "./comp-editableImage";
import NotesList from "./comp-notesList";
import API_CALLS from 'API_CALLS/index';
///Users/danielezaldivar/Sites/smiles_club/src/styles/scss/global/FormBuilder.scss


class FormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: (this.props.initialValues ? {...this.props.initialValues} : {}),
      id: window.GlobalUtil.getRandomId(),
      touched: {},
      isValid: {}
    };


    var SetFieldValues = (listOfFields) => {
      listOfFields.map((field, index)=>{ //SET ALL REQUIRED AS INVALID
        if(!field.name){
          if(field.listOfFields) SetFieldValues(field.listOfFields)
          return;
        }
        if(field.required) this.state.isValid[field.name] = false;
        this.state.touched[field.name] = false;        
        var curValue = window.GlobalUtil.deepGetFromString(this.state.currentObject, field.name, undefined);
        if(curValue === undefined){          
          if(field.defaultValue != undefined) window.GlobalUtil.deepSetFromString(this.state.currentObject, field.name, field.defaultValue);
          else if(field.defaultValueFunction != undefined)  window.GlobalUtil.deepSetFromString(this.state.currentObject, field.name, field.defaultValueFunction(curValue));
        }
      });          
    }
    SetFieldValues(this.props.listOfFields);
  }

  componentDidMount(){
    if(this.props.didMount) this.props.didMount()    
    //this.Form.querySelectorAll("input")
    //addEventListener("focus", (event) => {});
  }

  componentWillUnmount(){
    clearTimeout(this.ValidateTimeout);
  }


  overrideCurrentObject = (currentObject) => {
    this.setState({currentObject})
  }

  onSubmit = (e) => {
    if(e && e.preventDefault) e.preventDefault();
    var {currentObject} = this.state; 
    if(this.props.onSubmit) this.props.onSubmit(currentObject);
  };

  handleOnChange = (path, newValue) => {
    var currentObject = {...this.state.currentObject};
    // console.log("path, newValue");
    // console.log(path, newValue,"\n\n");
    // console.log("currentObject");
    // console.log(currentObject,"\n\n");    
    
    window.GlobalUtil.deepSetFromString(currentObject, path, newValue);
          
    this.setState({currentObject});
    if(this.props.onChange) this.props.onChange(currentObject);
  }

  trigggerAllTouched = () => { //THIS IS USED FOR IF YOU WANT TO SHOW THE USER WHAT AREAS THEY'VE LEFT OUT THAT THEY NEED TO FILL OUT
    var {touched} = this.state;
    Object.keys(touched).map((key, index)=>{
      touched[key] = true;
    })
    this.setState({touched})
  }

  handleOnTouched = (path) => {
    var {touched} = this.state;
    window.GlobalUtil.deepSetFromString(touched, path, true);
    //touched[name] = true;
    this.setState({touched});
  }

  handleChangeValid = (name, newState) => {          
    var {isValid} = this.state;
    //if(isValid[name] && isValid[name] === newState ) return; //
    isValid[name] = newState;
    this.setState({isValid});

    var isValidSimple = Object.keys(isValid).filter(key=>isValid[key] === false);          
    if(this.props.onValidate){            
      clearTimeout(this.ValidateTimeout);            
      this.ValidateTimeout = setTimeout(() => {              
        if(this) this.props.onValidate(isValidSimple.length === 0);
      }, 100);
    }
    //console.log("isValid");
    //console.log(isValid,"\n\n");
          
  }

  onReload = () => {
    this.setState({reload: true}, ()=>{
      this.setState({reload: false})
    });
  }

  render(){
    var {currentObject, id, touched, isValid, reload} = this.state;
    var {listOfFields=[], values={}, useExternalValues, disableKeySubmit} = this.props;   
           
    if(useExternalValues) currentObject = {...values};          
    if(reload) return null;
    return (
      <form 
        action="" 
        className="FormBuilder" 
        onSubmit={this.onSubmit} 
        onKeyPress={(event)=>{
          if(event.key === 'Enter'){
            event.preventDefault(); //Without this the submit gets called regardless of diableKeySubmit     
            event.target.blur(); //WHENEVER THEY USE ENTER BLUR THE CURRENT FIELD TO TEST IT'S WORKING
            if(!disableKeySubmit) this.onSubmit(event)
          } 
        }}>
        <LoopThroughList
          listOfFields={listOfFields}
          currentObject={currentObject}
          id={id}
          touched={touched}
          isValid={isValid}
          handleOnTouched={this.handleOnTouched}
          handleChangeValid={this.handleChangeValid}
          handleOnChange={this.handleOnChange}
          onReload={this.onReload}
        />
      </form>
    );
  }
}



const LoopThroughList = (props) => {
  var {
    listOfFields,
    currentObject,
    id,
    touched,
    isValid,
    handleOnTouched,
    handleChangeValid,
    handleOnChange,
    onReload
  } = props;
        
  return listOfFields.map((field, index)=>{
    if(field.hide) return;
    if(field.condition){
      let conditionStatus = field.condition(currentObject)
      if(!conditionStatus) return;
      else if(field.listOfFields){
        return(
          <div className={(field.type !== "sectionDivider" ? "form-group" : "")} key={index}>
            <div className="tabedFields">
              <div className="tabedFieldsTitle">{field.title}{field.required && <span className="orange-text requiredStar"><i className="fas fa-star-of-life" /></span>}</div>
              {field.subTitle && <div className="tabedFieldsSubTitle">{field.subTitle}</div>}
              <LoopThroughList
                id={id}
                listOfFields={field.listOfFields}
                currentObject={currentObject}
                touched={touched}
                isValid={isValid}
                handleOnTouched={handleOnTouched}
                handleChangeValid={handleChangeValid}
                handleOnChange={handleOnChange}
                onReload={onReload}
              />
            </div>
          </div>
        );
      }
    }
    if(field.dynamicText){
      return(<div key={index}>{field.dynamicText(currentObject)}</div>)
    }
    if(!FIELD_MAPPER[field.type]) return null;
    var FieldObject = FIELD_MAPPER[field.type];
    var value = (field.name ? window.GlobalUtil.deepGetFromString(currentObject, field.name, (field.defaultValue ? field.defaultValue : '')) : 0);
    if((value == undefined) && (field.defaultValueFunction != undefined)) value = field.defaultValueFunction(currentObject);
          
    return(
      <div className={(field.type !== "sectionDivider" ? "form-group" : "")} key={index}>
        <FieldObject
          field={field}
          id={`${id}${index}`}
          currentObject={currentObject}
          value={value}
          meta={{
            touched: touched[field.name],
            error: (isValid[field.name] != undefined ? !isValid[field.name] : false),
          }}
          onTouch={handleOnTouched}
          onChangeValid={handleChangeValid} //SET IF ERROR OR NOT, FOR REQUIRED FIELDS
          onChange={handleOnChange}
          onReload={onReload}
        />
      </div>
    )
  })  
}




class FileUploadBasic extends ExtenderClass {
  constructor(props) {
    super(props);
    //this.extraCheck = (newValue)=>{return((!newValue || !newValue.name || !newValue.url) ? false : true)}
  }

  render(){
    var {field={}, value=[{name:"",url:""}], onChange, onTouch, meta} = this.props;
    var {name="", required, title, options=[], add, accept, def, id, className} = field;
    var tempStyles = {
      "height": "auto",
      "display": "block",
      "padding": "15px 10px",
      "margin": "0 0 15px 0",
      "borderColor": "#ddd"
    }
    if(!value || value.length < 1){
      console.log("Yup");
      value=[{name:""}];    
    } 
    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        {/*<div className="">{value.url}</div>*/}
        {
          value.length > 1
          ? value.map((curValue, index)=>{
              return(
                <div style={{display: "flex", "alignItems": "center"}} key={index}>
                  <div style={{maxWidth: "100px"}}>
                    <img src={curValue.url} alt="" style={{width: "100%"}}/>
                  </div>
                  <div style={{flex: 1, paddingLeft: "10px"}}>
                    <div className="">Select New Image</div>
                    <input 
                      type="file" 
                      accept={accept} 
                      className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`} 
                      style={tempStyles}
                      value={curValue.value} 
                      name={curValue.name} 
                      onBlur={()=>{
                        this.props.onTouch(curValue.name);
                        if(required) this.Validate(value);
                      }}
                      onChange={e=>{
                        value[index] = e.target.files[0];
                        this.props.onChange(name, value);
                        if(required) this.Validate(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              )
            })
          : value.map((curValue, index)=>{
              return(
                <input 
                  key={index}
                  type="file" 
                  accept={accept} 
                  className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`} 
                  style={tempStyles}
                  value={curValue.value} 
                  name={curValue.name} 
                  onBlur={()=>{
                    this.props.onTouch(curValue.name);
                    if(required) this.Validate(curValue);
                  }}
                  onChange={e=>{
                    // console.dir("e.target.files");
                    // console.dir(e.target.files,"\n\n");
                    // console.dir("e.target.value");
                    // console.dir(e.target.value,"\n\n");
                    
                    value[index] = e.target.files[0];  
                    value[index].value = e.target.value;      
                    this.props.onChange(name, value);
                    if(required) this.Validate(curValue);
                  }}
                />
              )
            })
        }
        
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}







const SectionDivider = React.forwardRef(({field={}, value='', onChange, onTouch, onChangeValid, meta}, ref)=>{
  var {title="", className="", styles={}, id} = field;
  return(
    <div className={`sectionDivider ${className}`} style={styles}>{title}</div>
  )
})






class RadioBox extends ExtenderClass {
  constructor(props) {
    super(props);
    this.state = {
      id: window.GlobalUtil.getRandomId()
    }
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, options=[], add, isBool, id, className} = field;
    if(isBool) value = window.GlobalUtil.inputToBool(value)
    if(!id) id = this.state.id;
    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className={`radioListInline radio-form-control ${((meta.error && meta.touched) ? 'error' : '')}`}>
          {
            options.length > 0 && 
            options.map((option, index)=>{
              var isActive = false;
              return(
                <div key={index} className="radioListItem">
                  <input 
                    id={`${id}${index}`} 
                    type="radio" 
                    value={option.value} 
                    checked={(value === option.value)} 
                    onChange={(e)=>{
                      this.props.onChange(name, e.target.value);
                      if(required) this.Validate(e.target.value);
                    }}
                    onBlur={()=>{
                      this.props.onTouch(name);
                      if(required) this.Validate(value);
                    }}
                  />
                  <label htmlFor={`${id}${index}`}>
                    <div className={"box " + (isActive ? 'active' : '')}>&nbsp;&nbsp;{option.title}</div>
                  </label>
                </div>
              )
            })
          }
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


class ToggleInput extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title="", options=[], add, id, className} = field;
    if(field.interpretValue) value = field.interpretValue(value);
    let TEMPSTYLES = {
      "wrapper": {},
      "header": {
        "padding":"10px 0",
        "flex":"1",
        "overflow":"hidden",
        "display":"flex",
        "alignItems":"center",
        "margin":"0px"
      },
      "span": {
        flex: "1 1 0%"
      },
      "label": {
        margin: "0px"
      },
      "input": {}
    }; 
    return (
      <div className="form-toggle">
        {field.borderTop && <hr/>}
        <h5 className="header" style={TEMPSTYLES.header}>
          <div className="text">
            {TITLE(field.title, field.required, field.requiredMessage)}
            {SUBTITLE(field.subTitle)}
          </div>
          <label className="label switch" style={TEMPSTYLES.label}>
            <input 
              type="checkbox" 
              onChange={e=>{
                this.props.onChange(name, !value);
                if(required) this.Validate(!value);
              }}
              name="visible" 
              value={value ? "checked" : ""}
              //checked={(value ? true : false)}
              onBlur={()=>{
                this.props.onTouch(name);
                if(required) this.Validate(value);
              }}
              style={TEMPSTYLES.input}
            />
            <div className={`slider ${value ? "checked" : ""}`}></div>
          </label>
        </h5>
        {field.borderBottom && <hr/>}
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    );
  }
}



class SelectBox extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, options=[], add, id, className} = field;
    if(!options.filter(obj=>(!obj.disabled && (obj.value === value)))[0]) value = ""; //IF CURRENT VALUE IS NOT ONE OF THE OPTIONS THEN SET CURRENT VALUE TO NOTHING.

    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <select 
          type="select" 
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}
          value={value}
          name={name}
          onChange={e=>{
            this.props.onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            this.props.onTouch(name);
            if(required) this.Validate(value);
          }}>
          {
            options.length > 0 && 
            options.map((object, index)=>{
              if(object.disabled) return(<option key={index} value={""} disabled>{object.title}</option>)
              return(
                <option key={index} value={object.value}>{object.title}</option>
              )
            })
          }
        </select>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}



class SelectState extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    const STATES = window.GlobalUtil.states();
    var options = Object.keys(STATES).map(stateKey=>{return({title: STATES[stateKey], value: STATES[stateKey]})})
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, add, id, className} = field;
    if(!options.filter(obj=>(!obj.disabled && obj.value === value))[0]) value = ""; //IF CURRENT VALUE IS NOT ONE OF THE OPTIONS THEN SET CURRENT VALUE TO NOTHING.

    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <select 
          type="select" 
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}
          value={value}
          name={name}
          onChange={e=>{
            this.props.onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            this.props.onTouch(name);
            if(required) this.Validate(value);
          }}>
          <option disabled value={""}>Please select a State</option>
          {
            options.length > 0 && 
            options.map((object, index)=>{
              if(object.disabled) return(<option key={index} value={""} disabled>{object.title}</option>)
              return(
                <option key={index} value={object.value}>{object.title}</option>
              )
            })
          }
        </select>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}




class CustomText extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    var {field={}, value, currentObject} = this.props;
    var {text="", customFunction, id, className} = field;

    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        { 
          customFunction
          ? customFunction(currentObject)
          : <div className="form-control">
            {
              text 
              ? text 
              : value
            }
            </div>
        }
      </div>
    )
  }
}




class TextBox extends ExtenderClass {
  constructor(props) {
    super(props);
    this.state = {
      customError: ''
    };
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta, currentObject} = this.props;
    var {name="", required, placeholder="", editable, id, className, formatInput, onBlur} = field;
    var {customError} = this.state;
    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        {field.showNumChar ? <div className="numbChar">Characters: {value.length}</div> : null}
        {
          editable !== undefined && editable === false
          ? <div className="form-control">{value}</div>
          : <input 
              type="text" 
              className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
              placeholder={placeholder} 
              value={value}
              name={field.name}
              onChange={e=>{
                e.preventDefault()
                var newVal = e.target.value;
                if(formatInput) newVal = formatInput(newVal); //THIS IS USED TO MAKE ALL CAPS ETC.
                var maxNumChar = (field.maxNumChar ? Number(field.maxNumChar) : false);
                if(maxNumChar && (newVal.length > maxNumChar)) newVal = value; //IF EXCEEDED MAX CHAR THEN RETURN LAST VALUE NOT NEW ONE
                this.props.onChange(field.name, newVal);
                this.setState({customError: ""});
                if(field.required) this.Validate(newVal);
              }}
              onBlur={()=>{
                this.props.onTouch(field.name);
                if(field.required) this.Validate(value);
                if(onBlur) onBlur({
                  newValue:value, 
                  currentObject: currentObject,
                  onChangeValid: this.props.onChangeValid, 
                  newErrorFunction: (newError)=>{
                    this.setState({customError: newError})
                  }
                });
              }}
            />
        }
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
        {(customError) && 
            <div className="">
              <small className="form-text red-text errorMessage">
                {
                  customError
                }
              </small>
            </div>
          }
      </div>
    )
  }
}





class WordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value ? this.props.value.slice() : []),
      newWord: ''
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateList = this.updateList.bind(this);
    this.removeFromList = this.removeFromList.bind(this);
  }

  componentDidMount(){
    var {field={}} = this.props;
    if(field.onMount) field.onMount(this, this.props.currentObject);
    if(this.props.field.required){
      this.props.onChangeValid(this.props.field.name, (this.state.value ? true : false));
    }
  }

  onSubmit(values){
    var {currentObject} = this.state;
    if(this.props.onSubmit) this.props.onSubmit(currentObject);
    if(this.props.field.required && currentObject){
      this.props.onChangeValid(this.props.field.name, true);
    }
  };

  handleChange(e){
    var newValue = e.target.value;
    if(this.props.field.formatText) newValue = this.props.field.formatText(newValue)
    this.setState({newWord: newValue})
  }

  updateList(){
    var {newWord, value=[]} = this.state;
    if(!newWord) return;
    if(newWord.split(",")[1]){
      var newList = newWord.toLowerCase().split(", ");
      value = [...value, ...newList];
    } else {
      value.push(newWord);
    }
    this.props.onChange(this.props.field.name, value.slice());
    this.setState({newWord: "", value: value}, ()=>{  
      this.wordListRef.focus()
    })
    if(this.props.field.required){
      this.props.onChangeValid(this.props.field.name, (value ? true : false));
    }
  }

  removeFromList(index){
    var {newWord, value=[]} = this.state;
    value.splice(index,1);
    this.props.onChange(this.props.field.name, value.slice());
    this.setState({value: value})
  }

  render(){
    var {field={}, meta} = this.props;
    var {name="", required, placeholder="", editable, id, className} = field;
    var {newWord, value} = this.state;
          
    return (
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="wordList">
          <div className="d-flex wordListInput">
            <input 
              ref={e=>this.wordListRef=e}
              type="text" 
              className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
              value={newWord}
              name={field.name}
              onChange={this.handleChange}
              onKeyPress={e=>{
                if (e.key === 'Enter'){
                  e.preventDefault()
                  this.updateList();
                } 
              }}
            />
            <div className="btn button button1" onClick={this.updateList}>+</div>
          </div>
          <div className="wordListCurrent">
            {
              value.map((word, index)=>{
                return(
                  <div className="d-flex wordListCurrentItem" key={index}>
                    <div>{word}</div>
                    <i className="fas fa-trash-alt" onClick={()=>this.removeFromList(index)}></i>
                  </div>
                )
              })
            }
          </div>
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    );
  }
}






//THIS TAKES IN A STRINGIFIED OBJECT OF KEY VALUE PARS
//THEN IT PARSES THAT AND USES IT
//WHEN IT SAVES ANY CHANGE IT SAVES IT AS STRINGIFIED AGAIN
class KeyValueList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newKeyValuePair: []
    };
    this.parseValue = this.parseValue.bind(this);
    this.turnAllUppercase = this.turnAllUppercase.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleTouched = this.handleTouched.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateList = this.updateList.bind(this);
    this.removeFromList = this.removeFromList.bind(this);
    this.field = this.props.field;
  }

  componentDidMount(){
    if(this.field.required) this.props.onChangeValid(this.field.name, (this.props.value ? true : false));      
  }

  parseValue(){
    var {value} = this.props;
    return (value ? JSON.parse(value) : {});
  }

  turnAllUppercase(curObj){
    var newObj = {};
    Object.keys(curObj).map((key, index)=>{
      newObj[key.toUpperCase()] = curObj[key].toUpperCase()
    })
    return newObj;
  };


  onSubmit(values){
    var {currentObject} = this.state;
    if(this.props.onSubmit) this.props.onSubmit(currentObject);
  };

  handleChange(e, index){
    var {newKeyValuePair=[]} = this.state;
    newKeyValuePair[index] = e.target.value;
    this.setState({newKeyValuePair: newKeyValuePair})
  }

  handleTouched(){
    if(this.touched && this.field.required) return;
    this.touched = true;
    this.props.onTouch(this.field.name);
  }

  updateList(){
    this.handleTouched();
    var {newKeyValuePair} = this.state;
    if(!newKeyValuePair[0] || !newKeyValuePair[1]) return;
    var curObj = this.parseValue();
    curObj[newKeyValuePair[0]] = newKeyValuePair[1];
    if(this.field.upparcase) curObj = this.turnAllUppercase(curObj);
    var NewValueString = JSON.stringify(curObj);
    this.props.onChange(this.field.name, NewValueString);
    if(this.field.required) this.props.onChangeValid(this.field.name, (NewValueString ? true : false));
    this.setState({newKeyValuePair: []}, ()=>{
      if(this.firstItemRef) this.firstItemRef.select()
    });
  }

  removeFromList(key){
    this.handleTouched();
    var curObj = this.parseValue();
    delete curObj[key];
    var NewValueString = ((Object.keys(curObj).length < 1) ? "" : JSON.stringify(curObj));
    this.props.onChange(this.field.name, NewValueString);
    if(this.field.required) this.props.onChangeValid(this.field.name, (NewValueString ? true : false));
    this.setState({date: new Date()}, ()=>{
      if(this.firstItemRef) this.firstItemRef.select()
    });
  }

  render(){
    var {field={}, meta} = this.props;
    var {name="", required, placeholder="", editable, upparcase, id, className} = field;
    var {newKeyValuePair=[]} = this.state;
    var curObj = this.parseValue();
    var disableNew = ((newKeyValuePair[0] === undefined) || (newKeyValuePair[0] === undefined)) ? true : false;
          
    return (
      <div className={"keyValueList " +className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="wordList">
          <div className="d-flex keyValueListInput">
            <div className="keyValueListInputWrapper">
              <input 
                ref={e=>this.firstItemRef=e}
                type="text" 
                className={`form-control keyValueListInputKey ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
                value={(newKeyValuePair[0] === undefined) ? "" : newKeyValuePair[0]}
                name="newKey"
                onChange={e=>this.handleChange(e, 0)}
                placeholder="Key"
                onBlur={this.handleTouched}
                onKeyPress={e=>{
                  if(disableNew) return;
                  if (e.key === 'Enter'){
                    e.preventDefault()
                    this.updateList();
                  } 
                }}
              />
              <input 
                type="text" 
                className={`form-control keyValueListInputValue ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
                value={(newKeyValuePair[1] === undefined) ? "" : newKeyValuePair[1]}
                name="newValue"
                onChange={e=>this.handleChange(e, 1)}
                placeholder="Value"
                onBlur={this.handleTouched}
                onKeyPress={e=>{
                  if(disableNew) return;
                  if (e.key === 'Enter'){
                    e.preventDefault()
                    this.updateList();
                  } 
                }}
              />
            </div>
            <div className={`btn button button1 ${(disableNew) ? "disabled" : ""}`} onClick={()=>{if(!disableNew) this.updateList()}}><i className="fas fa-plus"></i></div>
          </div>
          {
            (Object.keys(curObj).length > 0) && 
            <div className="keyValueListCurrent">
              <div className="keyValueListCurrentTitle">Current List</div>
              <div className="keyValueListItemWrapperHeader">
                <div className="keyValueListItem">
                  <div className="keyValueListKey">Param</div>
                  <div className="keyValueListValue">Value</div>
                </div>
              </div>
              {
                Object.keys(curObj).map((key, index)=>{
                  return(
                    <div className="keyValueListItemWrapper" key={index}>
                      <div className="keyValueListItem">
                        <div className="keyValueListKey">{key}</div>
                        <div className="dividerVer"></div>
                        <div className="keyValueListValue">{curObj[key]}</div>
                      </div>
                      <i className="fas fa-trash-alt" onClick={()=>this.removeFromList(key)}></i>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    );
  }
}







class TextAreaBox extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, placeholder="", rows, columns, editable, id, className, formatInput, onBlur, maxNumChar=0} = field;
    maxNumChar = Number(maxNumChar);
    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        {field.showNumChar ? <div className="numbChar">Characters: {value.length}/{maxNumChar}</div> : null}
        <textarea 
          value={value}
          name={name}
          onChange={e=>{
            e.preventDefault()
            var newVal = e.target.value;
            if(formatInput) newVal = formatInput(newVal); //THIS IS USED TO MAKE ALL CAPS ETC.
            if(maxNumChar && (maxNumChar > 0) && (newVal.length > maxNumChar)) newVal = newVal.slice(0,maxNumChar); //IF EXCEEDED MAX CHAR THEN RETURN LAST VALUE NOT NEW ONE
            this.props.onChange(name, newVal);
            this.setState({customError: ""});
            if(field.required) this.Validate(newVal);
          }}
          onBlur={()=>{
            this.props.onTouch(name);
            if(required) this.Validate(value);
          }}
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
          placeholder={placeholder} 
          rows={rows} 
          columns={columns}>
        </textarea>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}







const MustBeEmail = value => {        
  //var valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) //WORKS ONLY WITH .3LETTER SO .COM.NET.GOV ETC
  var valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(value))
  console.log(`valid`,"\n\n",valid,"\n\n");
        
  return(valid ? true : false)
}




class EmailBox extends ExtenderClass {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isNotEmail: false,
      emailExistError: false
    }; 
    //this.extraCheck = MustBeEmail
  }

  extraCheck = value => {      
    if(this.state.emailExistError) return false;
    //var valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) //WORKS ONLY WITH .3LETTER SO .COM.NET.GOV ETC
    var valSplit = value.split("@");
    if(valSplit.length !== 2){
      this.setState({isNotEmail: true});            
      return false;
    }
    valSplit = [valSplit[0], ...valSplit[1].split('.')];
    if(valSplit.length === 3 && valSplit[2].length > 1){ //NEEDS 3 ZONES (NAME, @, .WHATEVER), AND AFTER ENDING . SHOULD BE MORE THAN JUST 1 CHAR
      this.setState({isNotEmail: false});            
      return true;
    } else {
      this.setState({isNotEmail: true});            
      return false;
    }
    //return((valSplit.length === 3) ? true : false)
  }



  API_CHECK_EMAIL_AVAILABLE = (emailAddress) => {
    //return true;
    var cleanObj = {email: emailAddress};   
    window.Client.mutate({
      mutation: API_CALLS.USERS.QUERIES.emailchecker(),
      variables: cleanObj
    })
    .then(result => {
      if(result.data.emailchecker){ //EMAIL HAS BEEN CHOSEN CHOSE ANOTHER
        this.setState({emailExistError: true}, ()=>{                
          this.props.onChangeValid(this.props.field.name, false);
        });              
      }
    })
    .catch((error)=>{
      console.log("EmailWithCheckBox API_CALLS.USERS.QUERIES.emailchecker FAIL catch", error);
    })
  }

  render(){
    var {emailExistError, isNotEmail} = this.state;
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, placeholder, emailHelpMessage, emailErrorMessage, checkAvailable, customError, id, className} = field;                                                        
    return(
      <div id={id} className={className}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="form-control-wrapper">
          <input 
            type="email" 
            className={`form-control emailInput ${INVALID_CLASS_CHECK(meta, field.required, "error", "")} ${customError ? "error" : ''}`}  
            placeholder={placeholder} 
            value={value}
            name={name}
            onChange={e=>{
              var newVal = e.target.value;
              newVal = newVal.replace(/\s/g, '');     
              if(emailExistError) this.setState({emailExistError: false});
              this.props.onChange(name, newVal);
              if(required) this.Validate(newVal);
            }}
            onBlur={()=>{
              this.props.onTouch(name);
              if(required) this.Validate(value);
              if(checkAvailable) this.API_CHECK_EMAIL_AVAILABLE(value);
            }}
          />
          {//!emailExistError && INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)
          }
          {(required && meta.touched && emailExistError) && 
            <div className="">
              <small className="form-text red-text errorMessage">
                {
                  (window.Session.Language === "ESPAÑOL") 
                  ?  "Esta dirección de correo electrónico ya existe"
                  :  "This Email Address already Exist"
                }
              </small>
            </div>
          }
          {(required && meta.touched && isNotEmail) && 
            <div className="">
              <small className="form-text red-text errorMessage">
                {
                  (window.Session.Language === "ESPAÑOL") 
                  ?  "Este debe ser un correo electrónico"
                  : "This must be an Email"
                }
              </small>
            </div>
          }
          {(customError) && 
            <div className="">
              <small className="form-text red-text errorMessage">
                {
                  customError
                }
              </small>
            </div>
          }
          {(emailHelpMessage) && 
            <div className="">
              <small id="emailHelp" className="form-text text-muted">
                {
                  emailHelpMessage
                }
              </small>
            </div>
          }
        </div>
      </div>
    )
  }
}


//must have valueToCheck value and that value to be the same name as the email it is checking
class EmailConfirmBox extends ExtenderClass {
  constructor(props) {
    super(props);
    this.extraCheck = (value)=>{
      var {currentObject={}, field={}} = this.props;
      if(!field.valueToCheck) return false;
      if(!currentObject || !currentObject[field.valueToCheck] || currentObject[field.valueToCheck] !== value) return false;
      return MustBeEmail(value);
    }
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, placeholder, emailHelpMessage, emailErrorMessage, id, className} = field;

    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="form-control-wrapper">
          <input 
            type="email" 
            className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
            placeholder={placeholder} 
            value={value}
            name={name}
            onChange={e=>{
              this.props.onChange(name, e.target.value);
              if(required) this.Validate(e.target.value);
            }}
            onBlur={()=>{
              this.props.onTouch(name);
              if(required) this.Validate(value);
            }}
          />
          {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
          {emailHelpMessage && <small id="emailHelp" className="form-text text-muted">{emailHelpMessage}</small>}
        </div>
      </div>
    )
  }
}







class PhoneBox extends ExtenderClass {
  constructor(props) {
    super(props);
    this.extraCheck = (newValue)=>{
      return(newValue && `${newValue}`.length === 10)
    }
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, placeholder, subText, id, className} = field;
    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <NumberFormat 
          format="(###) ###-####" 
          mask="_" 
          placeholder={(placeholder ? placeholder : "(XXX) XXX - XXXX")}
          value={value}
          name={name}
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
          onValueChange={newValue=>{
            this.props.onChange(name, newValue.value);
            if(required) this.Validate(newValue.value);
          }}
          onBlur={()=>{
            if(!meta.touched) this.props.onTouch(name);                
            //onTouch(name);
            if(required) this.Validate(value);
          }}
        />
        {subText}
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}







class NumberInput extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name, required, title, placeholder, min, max, step=1, id, className, inputPostDiv, inputPreDiv} = field;

    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="input-input-wrapper">
          {inputPreDiv && <div className="input-input-pre">{inputPreDiv(this.props.currentObject)}</div>}
          <input 
            type="number" 
            className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
            placeholder={placeholder} 
            value={value}
            name={name}
            min={min}
            max={max}
            step={step}
            onKeyPress={(e)=>{if(e.key === 'Enter') e.preventDefault()}}
            onChange={e=>{
              var newValue = e.target.value;
              // var newValueClean = newValue.replace(/[^0-9.]/g,"");
              // var trueValue = (newValueClean ? newValueClean : value);
              // if(trueValue && max && Number(trueValue) > max) trueValue = max;
              this.props.onChange(name, newValue);
              if(required) this.Validate(newValue);
            }}
            onBlur={()=>{
              this.props.onTouch(name);
              if(required) this.Validate(value);
            }}
            pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
          />
          {inputPostDiv && <div className="input-input-post">{inputPostDiv(this.props.currentObject)}</div>}
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


class ZipcodeInput extends ExtenderClass {
  constructor(props) {
    super(props);
    this.extraCheck = (newValue)=>{
      return(newValue && `${newValue}`.length === 5)
    }
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name, required, title, placeholder, id, className} = field;

    return(
      <div 
        className={className} 
        id={id} 
        ref={e=>this.numberRef=e} 
        onClick={()=>{if(this.numberRef){
         this.numberRef.querySelectorAll("input")[0].select(); //HIGHLIGHT THE MONEY TO EASY CHANGE IT    
      }}}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <NumberFormat 
          format="#####" 
          placeholder={placeholder}
          value={value}
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
          onValueChange={newValue=>{
            this.props.onChange(name, newValue.value);
            if(required) this.Validate(newValue.value);
          }}
          thousandSeparator={true} 
          prefix={''} 
          onBlur={()=>{
            if(!meta.touched) this.props.onTouch(name);                
            //this.props.onTouch(name);
            if(required) this.Validate(value);
          }}
        />
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}







class MoneyInput extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name, required, title, placeholder, min, max, step=1, id, className} = field;

    return(
      <div 
        id={id}
        className={className} 
        ref={e=>this.numberRef=e} 
        onClick={()=>{if(this.numberRef){
         this.numberRef.querySelectorAll("input")[0].select(); //HIGHLIGHT THE MONEY TO EASY CHANGE IT    
      }}}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <NumberFormat 
          placeholder={(placeholder ? placeholder : "$0.00")}
          value={value}
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
          onValueChange={newValue=>{
            this.props.onChange(name, newValue.value);
            if(required) this.Validate(newValue.value);
          }}
          thousandSeparator={true} 
          prefix={'$'} 
          onBlur={()=>{
            if(!meta.touched) this.props.onTouch(name);                
            //this.props.onTouch(name);
            if(required) this.Validate(value);
          }}
        />
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}







const PasswordText = {
  tooShort: {
    "ESPAÑOL": (minChar)=>`La contraseña debe tener al menos ${minChar} caracteres`, 
    "ENGLISH": (minChar)=>`Password must be at least ${minChar} characters long`
  },
  capslockMessage: {
    "ESPAÑOL": `ADVERTENCIA: CapsLock está activado`,
    "ENGLISH": `WARNING: CapsLock is on`
  },
}
class Password extends ExtenderClass {
  constructor(props) {
    super(props);
    this.state = {
      tooShort: false,
      capslock: false
    }
  }

  componentDidMount(){
    this.CompRef.addEventListener("keyup", this.capslockListener);
  }

  componentWillUnmount(){
    this.CompRef.removeEventListener("keyup", this.capslockListener);
  }


  capslockListener = (e) => {          
    // If "caps lock" is pressed, display the warning text
    if (e && e.getModifierState && e.getModifierState("CapsLock")) {
      this.setState({capslock: true})
    } else {
      this.setState({capslock: false})
    }
  }

  extraCheck = (newValue)=>{
    return this.checkLongEnough(newValue);
  }

  checkLongEnough = (curVal) => {
    var {minChar} = this.props.field;
    if(!minChar) return true;
    if(curVal.length < minChar){
      this.setState({
        tooShort: PasswordText.tooShort[window.Session.Language](minChar)
      });
      return false;
    } else {
      if(this.state.tooShort){
        this.setState({
          tooShort: false
        })
        return true;
      } 
    }
  }

  render(){
    var {field={}, value='', onChange, onTouch, meta} = this.props;
    var {name="", required, title, placeholder="", minChar, customError, id, className=''} = field;
    var {tooShort, capslock} = this.state;
    return(
      <div className={'formPassword '+className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <input 
          ref={e=>this.CompRef=e}
          type="password" 
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
          placeholder={placeholder} 
          value={value}
          name={name}
          onChange={e=>{
            //console.log('e.target.value', e.target.value);
            this.props.onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            this.props.onTouch(name);
            if(required) this.Validate(value);
          }}
        />
        {(capslock 
          ? <div className="passwordCapslockMessage">
              <small className="red-text errorMessage">{PasswordText.capslockMessage[window.Session.Language]}</small>
              <div className="arrow-down"></div>
              <div className="arrow-down2"></div>
            </div> 
          : null
        )}
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
        {(tooShort && meta.touched ? <div><small className="form-text red-text errorMessage">{tooShort}</small></div> : null)}
        {(customError && meta.touched ? <div><small className="form-text red-text errorMessage">{customError}</small></div> : null)}
      </div>
    )
  }
}





class Stars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverValue: (this.props.value ? this.props.value : 0)
    };
    this.validate = this.validate.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }
  componentDidMount(){
    this.validate(this.props.value);
  }

  validate(newValue){
    if(!this.props.field.required){
      this.props.onChangeValid(this.props.field.name, true);
      return;
    }
    var valid = true;
    if(!newValue) valid = false;
    this.props.onChangeValid(this.props.field.name, valid);
  };

  onHover(newValue){
    this.setState({hoverValue: newValue});
  }

  onClick(newValue){
    this.props.onChange(this.props.field.name, newValue);
    this.setState({hoverValue: newValue})
    this.validate(newValue);
  }

  onMouseLeave(event){
    var e = event.toElement || event.relatedTarget;
    if (e.parentNode == this || e == this) {
       return;
    }
    this.setState({hoverValue: this.props.value})
  }

  render(){
    var {field, value=0, onChange, onTouch, onChangeValid, meta, styles={}} = this.props;
    var {name="", required, title, placeholder="", id, className} = field;
    var {hoverValue} = this.state;
    value = Number(value)
    return(
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="reviewStars" onMouseLeave={this.onMouseLeave}>
          <div className="star" 
            onMouseEnter={()=>this.onHover(0.5)} 
            onClick={()=>this.onClick(0.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue > 0 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(1)} 
            onClick={()=>this.onClick(1)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 1 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(1.5)} 
            onClick={()=>this.onClick(1.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 1.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(2)} 
            onClick={()=>this.onClick(2)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 2 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(2.5)} 
            onClick={()=>this.onClick(2.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 2.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(3)} 
            onClick={()=>this.onClick(3)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 3 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(3.5)} 
            onClick={()=>this.onClick(3.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 3.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(4)} 
            onClick={()=>this.onClick(4)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 4 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(4.5)} 
            onClick={()=>this.onClick(4.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 4.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(5)} 
            onClick={()=>this.onClick(5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 5 ? 'active' : '')}`} />
          </div>
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}




class URLPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialURL: (this.props.value ? this.props.value : ''),
      newURL: (this.props.value ? this.props.value : ''),
      errorMessage: ""
    };
  }

  componentDidMount(){
    var {field={}} = this.props;
    if(field.onMount) field.onMount(this, this.props.currentObject);
    if(this.props.field.required){
      this.props.onChangeValid(this.props.field.name, (this.state.newURL ? true : false));
    }
  }

  handleChange = (e) => {
    var newValue = e.target.value;
    if(!newValue) return newValue;
    var newInputValue = newValue.toLowerCase();
    newInputValue = newInputValue.split(" ").join("-");
    if(this.props.field.formatText) newInputValue = this.props.field.formatText(newValue)
    this.setState({newURL: newInputValue})
    this.props.onChange(this.props.field.name, newInputValue);
  }

  
  checkAvailable = () => {
    if(this.state.initialURL === this.state.newURL){
      this.props.onChangeValid(this.props.field.name, true);
      this.setState({errorMessage: ""});
      return; //DON'T RUN IF URL HAS NOT CHANGED
    }
    window.Client.mutate({
      mutation: this.props.field.urlAPI(),
      variables: {
        "manual": [
          {
            path: this.props.field.urlPATH ? this.props.field.urlPATH : "url",
            val: this.state.newURL
          }
        ]
      }
    })
    .then(result => {
      var foundResults = result.data[this.props.field.urlFIELD];
      if(foundResults && (Number(foundResults) > 0)){ //IF EXIST                    
        this.props.onChangeValid(this.props.field.name, false);
        this.setState({errorMessage: (this.props.field.errorMessage ? this.props.field.errorMessage : "This URL has already been taken")});
      } else {
        this.props.onChangeValid(this.props.field.name, true);
        this.setState({errorMessage: ""});
      }
      //console.log(`result.data`,"\n\n",result.data,"\n\n");
    })
    .catch((error)=>{
      console.log("FORMBUILDER -> URLPicker -> checkAvailable -> FAIL catch", error);
    })
  }

  render(){
    var {field={}, meta} = this.props;
    var {name="", required, placeholder="", editable, id, className} = field;
    var {newURL, value, errorMessage} = this.state;
    
          
    return (
      <div className={className} id={id}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="urlPicker">
          <div className="d-flex wordListInput">
            <input 
              type="text" 
              className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
              value={newURL}
              name={field.name}
              onChange={this.handleChange}
              onBlur={this.checkAvailable}
            />
          </div>
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (errorMessage && <small className="form-text red-text errorMessage">{errorMessage}</small>), null)}
        {errorMessage ? <small className="form-text red-text errorMessage">{errorMessage}</small> : null}
      </div>
    );
  }
}



/*
type="CustomInput"
customFunction=()=>{
  
}



this.props.onChange
this.props.onTouch
this.props.currentObject
this.props.value
this.Validate
*/

class CustomInput extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){
    var {field={}, value, currentObject} = this.props;
    var {text="", customFunction, id, className} = field;

    return(
      <div className={className} id={id}>
        {
          field.customPreField
          ? field.customPreField(this)
          : null
        }
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        {
          field.customFunction
          ? field.customFunction(this)
          : null
        }
      </div>
    )
  }
}


const FIELD_MAPPER = {
  text: TextBox,
  textArea: TextAreaBox,
  select: SelectBox,
  selectState: SelectState,
  selectAPI: APISelectBox,
  addProducts: AddProducts,
  selectAPIWithAdd: APISelectBoxWithAdd,
  updateCouponUserCode: APIUpdateCouponUserCode, //VERY SPECIFIC CODE
  radio: RadioBox,
  toggle: ToggleInput,
  sectionDivider: SectionDivider,
  editableImages: EditableImages,
  editableImage: EditableImage,
  fileUploadList: FileUploadList,
  number: NumberInput,
  zipcode: ZipcodeInput,
  money: MoneyInput,
  email: EmailBox,
  emailConfirm: EmailConfirmBox,
  phone: PhoneBox,
  wordList: WordList,
  keyValueList: KeyValueList,
  password: Password,
  stars: Stars,
  urlPicker: URLPicker,
  datePicker: DatePicker,
  wizyWig: WizyWig,
  notesList: NotesList,
  customText: CustomText,
  customInput: CustomInput
};



export default FormBuilder;