import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/store.scss';
// import React, { useRef, useEffect } from 'react';
// import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import CONTENTS from "./CONTENTS";
import {NavLink} from 'react-router-dom';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
const QUERY = API_CALLS.PRODUCTS.QUERIES.all();


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: []
    };    
    this.getProducts = this.getProducts.bind(this);
    this.ClientEventRef =  window.GlobalUtil.subscribeEvent("ClientEvent", (newValue)=>{ //WAIT UNTIL window.Client EXIST
      window.GlobalUtil.delay(500).then(()=>{
        this.getProducts();
      })
    });
  }

  componentDidMount(){
    if(window.Client) this.getProducts();
  }

  componentWillUnmount(){
    this.ClientEventRef.unsubscribe();
  }

  getProducts(){
    window.Client.query({
      query: API_CALLS.PRODUCTS.QUERIES.all(),
      variables: {
        type: "NORMAL",
        sortBy: "cost",
        manual: [
          // {
          //   path: "language",
          //   val: window.Session.Language
          // },
          {
            path: "type",
            val: "NORMAL"
          },
          {
            path: "status",
            val: "ACTIVE"
          },
          {
            path: "showWhere",
            val: "STORE"
          },
          // {
          //   path: "ref",
          //   val: (this.props.API_ref ? this.props.API_ref : "TREATMENT_PLAN")
          // },
          {
            path: "website",
            val: (window.Session.Language === "ENGLISH") ? "SMILES" : "SONRISAS"
          }
        ],
        status: "ACTIVE",
      }
    })
    .then(result => {
      console.log("result");
      console.log(result,"\n\n");
            
      this.setState({products: result.data.products, loading: false})
    })
    .catch(()=>{
      console.log("catch");
            
      this.setState({curStatus: "failed", loading: false})
    })
  }


 

  render(){
    var {products} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PageStore">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <Container className="">
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                {CONTENT.PageHeader[0]}
              </div>
            </Column>
          </Row>
          <Row className="">
            {
              products.map((product, index)=>{
                return(
                  <Product 
                    key={index}
                    product={product}
                    CONTENT={CONTENT.Product}
                    storeLink={CONTENT.PageStoreLink[0]}
                    addToCart={()=>{
                      window.GlobalUtil.addToCart({product: product});
                      window.GlobalUtil.triggerEvent("cartEvent", "show");
                    }}
                  />
                )
              })
            }
          </Row>
        </Container>
      </div>
    );
  }
}



const Product = (props)=>{
  var {product={}, CONTENT, storeLink} = props;
  var url = (product.url) ? `/${storeLink}/`+product.url : storeLink;
  return(
    <Column className="" col="12" xs="12" sm="12" md="6" lg="4" xl="3">
        <div className="product">
          <div className="productImage">
            {
              product.images.map((img, index)=>{
                if(index > 0) return;
                return(
                    <NavLink className={""} to={url} key={index}>
                      <div className="productImageWrapper">
                        <img src={img.url} alt=""/>
                      </div>
                    </NavLink>
                  )
              })
            }
          </div>
          <div className="productText">
            <div className="productTextName">
              {product.name}
            </div>
            <div className="productTextDesc">
              {window.GlobalUtil.ulify(window.GlobalUtil.htmlTextStripper(product.shortDesc), 200)}
            </div>
            <div className="productTextCost d-flex">
              <div className="originalPrice">{window.GlobalUtil.convertToMoney(product.cost)}</div>
              <div className="cost">
                {window.GlobalUtil.convertToMoney(product.cost-product.discount)}
              </div>
            </div>
            <div className="savings">
              {CONTENT[1]}: <strong>{window.GlobalUtil.convertToMoney(product.discount)}</strong>
            </div>
            {/*<div className="">
              Category: {product.category}
            </div>*/}
            <div className="buttonGroup">
              <NavLink className={"button button1 dontCloseCart"} to={url}>
                {CONTENT[2]}
              </NavLink>
            </div>
          </div>
        </div>
    </Column>
  )
}  




export default Index;
      

