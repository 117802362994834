//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `Actúa ya, no tienes nada que perder. Te devolveremos el dinero si no eres candidato para el tratamiento y no tienes ninguna obligación de aceptarlo después de recibirlo.`
	},
	{
		"content": `Todos los Derechos Reservados`
	},
	{
		"type": "span",
		"content": `Nosotros`
	},
	{
		"type": "span",
		"content": `Informacíon`
	},
	{
		"type": "span",
		"content": `Social`
	}
];




	
export default PAGE;