import React, { useState, useRef } from 'react';
//import { GoogleLogin } from 'react-google-login';
import SignUp from 'global/components/SignUp/index';
import GoogleVector from 'global/img/googleLogo.svg';
import {LoginWith3rdPary} from 'global/utils/helper-functions';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}








class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };    
  }

  componentDidMount(){
    if (typeof window === "undefined" || !window.google || !this.ButtonRef) {
      return;
    }

    try {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_OATH_CLIENT,
        callback: async (res) => {
          await console.log(`callback res`,"\n\n",res,"\n\n");
          await this.onSuccess(res);     
        },
      });            
      window.google.accounts.id.renderButton(this.ButtonRef, { 
        theme: "outline", 
        size: "large",
        width: this.ButtonRef.offsetWidth,
        text: "continue_with"
      }); //width: this.ButtonRef.offsetWidth+"px"
      //console.log(`this.ButtonRef.offsetWidth`,"\n\n",this.ButtonRef.offsetWidth,"\n\n");
      //document.querySelector(".googleButton iframe #container > div").attr("style","border: none;")      
    } catch (error) {
      console.log(`error`,"\n\n",error,"\n\n");
      this.onFailure(error)
    }
  }


  onSuccess=(success)=>{
    var {
      autoCreate=true
    } = this.props;
    //console.log(`GoogleLogin success`);
    //console.log(`success.tokenId`,"\n\n",success.tokenId,"\n\n");
    LoginWith3rdPary({
      token: success.credential,//success.tokenId, 
      type: "GOOGLE", 
      autoCreate: autoCreate,
      callBackSuccess: (login)=>{
        if(this.props.onSuccess) this.props.onSuccess(login)
      }, 
      callBackFailed: ()=>{
        if(this.props.onFailed) this.props.onFailed()
      }
    });
  }


  onFailure=(failed)=>{
    //if(props.onFailed) props.onFailed(failed);
    //else window.GlobalUtil.logout(false);
    console.log(`failed default`,"\n\n",failed,"\n\n");
  }


  render(){
    var {
      buttonText="Login with Google",
      autoCreate=true
    } = this.props;
    var {clickBoxStyles={}} = this.state;
          
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    return(
      <div className="googleButton">
        <div className="icon"><img src={GoogleVector} alt="" /></div>
        <div className="text">{buttonText}</div>
        <div ref={e=>this.ButtonRef=e} className="trueButton" style={clickBoxStyles}/>
      </div>
    )
  }
}


// const Index = (props) => {
//   var {
//     buttonText="Login with Google",
//     autoCreate=true
//   } = props;
//   const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
//   return(
//     <GoogleLogin
//       clientId={process.env.REACT_APP_GOOGLE_OATH_CLIENT}
//       //buttonText="Login with Google"
//       render={renderProps => (
//         <button className="googleButton" onClick={renderProps.onClick} disabled={renderProps.disabled}>
//           <div className="icon"><img src={GoogleVector} alt="" /></div>
//           <div className="text">{buttonText}</div>
//         </button>
//       )}
//       onSuccess={(success)=>{
//         console.log(`GoogleLogin success`);
//         //console.log(`success.tokenId`,"\n\n",success.tokenId,"\n\n");
//         LoginWith3rdPary({
//           token: success.tokenId, 
//           type: "GOOGLE", 
//           autoCreate: autoCreate,
//           callBackSuccess: (login)=>{
//             if(props.onSuccess) props.onSuccess(login)
//           }, 
//           callBackFailed: ()=>{
//             if(props.onFailed) props.onFailed()
//           }
//         });
//       }}
//       onFailure={(failed)=>{
//         //if(props.onFailed) props.onFailed(failed);
//         //else window.GlobalUtil.logout(false);
//         console.log(`failed default`,"\n\n",failed,"\n\n");
//       }}
//       cookiePolicy={'single_host_origin'}
//     />
//   )
// }


export default Index;