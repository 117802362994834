//import React from 'react';
const PAGE = [
	{
		"content": `See who won each giveaway!`
	},
	{
		"content": `Checkout our Winners`
	},
	{
    "content": `Down below you can see our list of winners as well as the value of what they saved. You can also see how much we've given away. Our goal is to try to give away $100,000 or MORE in free aligners by the end of the year!`
  }
];




	
export default PAGE;