import React, { useState, useRef } from 'react';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}



const Index = props => {
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  return(
    <div className="currentInfoZone" style={{"border":"1px solid #ffd2d2","padding":"10px","borderRadius":"5px"}}>
      <div className="sectionTitle">{CONTENT[0]}</div>
      <div className="input-header-sub"><div className="formSubTitle" style={{"fontSize":"13px","paddingBottom":"5px"}}><span className="">{CONTENT[1]}</span></div></div>
      <div className="notes">
        {props.rejectStepNotes}
      </div>
    </div>
  )
}


export default Index;



