import React, { useState, useRef } from 'react';
import EditableImage from 'global/components/EditableImage/index';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;

class EditableImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.value ? (Array.isArray(this.props.value) ? this.props.value : [this.props.value]) : []
    }
    //this.extraCheck = (newValue)=>{return((!newValue || !newValue.name || !newValue.url) ? false : true)}
    this.extraCheck = (newValue)=>{
      return((!newValue || newValue.length === 0) ? false : true)
    }
    this.Validate = this.Validate.bind(this);
  }

  componentDidMount(){
    this.Validate(this.props.value);
  }


  Validate(newValue){
    var {field={}} = this.props;
    var {required, name=""} = field;
    if(!required){
      this.props.onChangeValid(name, true);
      return;
    }

    var valid = true;
    if(!newValue) valid = false;
    if(valid && this.extraCheck) valid = this.extraCheck(newValue)
    this.props.onChangeValid(name, valid);
  }



  render(){
    var {images=[]} = this.state;          
    var {field={}, id, value="", onTouch, meta} = this.props;
    var {name="", required, title, options=[], maxNumber=1, maxWidth, placeHolderImage, filePath, colSettings={
      col:12,
      xs:12,
      sm:12,
      md:6,
      lg:6,
      xl:4,
      style:{},
      placeHolderImage: undefined
    }} = field;
    var imageDiv = {...STYLES.imageDiv, ...(maxWidth ? {"maxWidth": maxWidth, "minWidth": maxWidth} : {})};
    return(
      <div className={`imageUpload ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <Row className="">
          {
            images.map((imageObj={}, index)=>{
              return(
                <Column className="" {...colSettings} key={index}>
                  <EditableImage
                    placeHolderImage={placeHolderImage}
                    images={[imageObj]}
                    filePath={filePath}
                    onChangeImage={(newImageObj, callBack)=>{
                      this.props.field.onChangeImage(index, newImageObj);
                      callBack();
                    }}
                    showDeleteButton={true}
                  />
                  <br/>
                </Column>
              )
            })
          }
          {
            (images.length < Number(maxNumber)) &&
            <Column className="" {...colSettings}>
              <EditableImage
                placeHolderImage={placeHolderImage}
                images={[]}
                filePath={filePath}
                onChangeImage={(newImageObj, callBack)=>{
                  this.props.field.onChangeImage(images.length, newImageObj);
                  callBack();
                }}
                showDeleteButton={false}
              />
              <div className="underLay"></div>
              <br/>
            </Column>
          }
        </Row>  
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}




const STYLES = {
  image:{
    "maxWidth": "100%",
    "cursor": "pointer"
  },
  dropZone: {
    "position":'relative',
    "pointerEvents": "none"
  },
  dropZoneText: {
    "position": "absolute",
    "inset": "0px",
    "background": "hsl(199 98% 48% / .5)",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "color": "white",
    "fontSize": "20px",
    "pointerEvents": "none"
  },
  imageWrapper: {
    "display": "flex", 
    "alignItems": "center", 
    "margin": "0 -1%",
    "flexWrap": "wrap"
  },
  tempStyles: {
    "height": "auto",
    "display": "block",
    "padding": "15px 10px",
    "margin": "0 0 15px 0",
    "borderColor": "#ddd"
  },
  imageDiv: {
    "maxWidth":"100%",
    "minWidth":"100%",
    "border":"1px solid #ddd",
    "margin":"0 1% 30px",
    "position":"relative",
  },
  deleteDiv: {
    "background": "red",
    "width": "30px",
    "height": "30px",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "position": "absolute",
    "right": "0px",
    "top": "0px",
    "cursor": "pointer"
  },
  deleteIcon: {
    "color": "#FFF"
  }
}

export default EditableImages;