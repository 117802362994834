//import React from 'react';
const PAGE = [
	{
		"content": `Ayuda`
	},
  {
    "content": `Formulario de contacto`
  },
  {
    "content": `Información`
  }
];




	
export default PAGE;