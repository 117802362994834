//import React from 'react';
const PAGE = [
  {
    "type": "span",
    "content": "<span style=\"color:red,font-weight:700\">WARNING THIS HAS BEEN REJECTED</span> - Rejection Notes"
  },
  {
    "content": "The text below SHOULD explain why this was sent back to you. Please fix any problems and submit it again."
  }
];




  
export default PAGE;