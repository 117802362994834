import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT, FULL_MUTATION_FIELDS } from './index';

  

const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    treatmentPlans(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    treatmentPlanCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    treatmentPlans(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const QUERYANDCOUNTLISTFULL = ()=>QUERYANDCOUNT(FULL_MUTATION_FIELDS(["user"]));

const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    treatmentPlanCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE_FULL = ()=> gql`
  query GetPlan($id: String){
    treatmentPlan(
      id:$id, 
      include: [
        {
          name: "user"
        },
        {
          name: "alignerGroups",
          include: [
            {
              name: "aligners"
            }
          ]
        }
      ]
    ){
      _id
      ${FULL_MUTATION_FIELDS(["user", "alignerGroups"])}
    }
  }`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetPlan($id: String, $include: [Include]){
    treatmentPlan(id:$id, include: $include){
      _id
      ${whatToPull}
    }
  }`;


const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    treatmentPlanDistinct(field:$field)
  }
`;







const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  allAndCountListFull: QUERYANDCOUNTLISTFULL,
  count: COUNT,
  single: QUERY_SINGLE,
  singleFull: QUERY_SINGLE_FULL,
  distinct: QUERY_GET_DISTINCT
}


export default QUERIES;