//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"CONTENT": {
			"ProductButtons": [
				{
					"content": "Buy Now"
				}
			],
			"ProductSubText": [
				{
					"type": "div",
					"className": "subProductTitle",
					"content": `
						Flexibility that fits you!
					`
				},
				{
					"type": "div",
					"className": "hightLightAffirmText",
					"content": `
						As low as <strong>$68</strong> per month with <svg id="Affirm_2_Color_-_HEX" data-name="Affirm 2 Color - HEX" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428.55 171" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 70px;display: inline;vertical-align: baseline;padding: 0 4px;">
							<defs>
							<style>
							.cls-1,.cls-2{fill:#101820;}.cls-1,.cls-3{fill-rule:evenodd;}.cls-3{fill:#4a4af4;}
							</style>
							</defs>
							<path class="cls-1" d="M116.62,449.32c-5.3,0-8-2.61-8-6.9,0-8,8.92-10.69,25.19-12.42,0,10.66-7.21,19.32-17.24,19.32Zm7-59.95c-11.63,0-25,5.47-32.26,11.26l6.63,14c5.82-5.33,15.23-9.89,23.72-9.89,8.07,0,12.53,2.7,12.53,8.14,0,3.65-2.95,5.5-8.53,6.23-20.86,2.7-37.21,8.46-37.21,24.53,0,12.74,9.07,20.45,23.24,20.45,10.12,0,19.12-5.62,23.4-13v11H154V416c0-19-13.19-26.67-30.37-26.67Z" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M312.91,391.44V462h20.18V428c0-16.15,9.78-20.9,16.59-20.9a15.47,15.47,0,0,1,8.63,2.55L362,391a22.84,22.84,0,0,0-9-1.63c-10.37,0-16.89,4.59-21.19,13.93V391.44H312.91" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M455.52,389.37c-10.67,0-18.65,6.3-22.8,12.38-3.85-7.85-12-12.38-21.8-12.38-10.66,0-18.05,5.92-21.46,12.74V391.44H370V462H390.2V425.67c0-13,6.83-19.29,13.2-19.29,5.77,0,11.07,3.73,11.07,13.36V462h20.16V425.67c0-13.19,6.66-19.29,13.33-19.29,5.34,0,11,3.88,11,13.22V462h20.16V413.22c0-15.85-10.67-23.85-23.56-23.85" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M263.8,391.44H245.52v-7.17c0-9.34,5.33-12,9.92-12a20.18,20.18,0,0,1,9,2.25l6.22-14.23s-6.31-4.12-17.78-4.12c-12.89,0-27.56,7.27-27.56,30.08v5.19H194.77v-7.17c0-9.34,5.32-12,9.92-12a19.12,19.12,0,0,1,9,2.25l6.22-14.23c-3.71-2.17-9.68-4.12-17.77-4.12-12.89,0-27.56,7.27-27.56,30.08v5.19H162.9V407h11.71v55h20.16V407h30.59v55h20.16V407H263.8V391.44" transform="translate(-88.52 -293.05)">
							</path>
							<rect class="cls-2" x="187.32" y="98.39" width="20.14" height="70.53">
							</rect>
							<path class="cls-3" d="M276.58,379.45h19.73c11.51-36.19,50.56-68,97-68,56.48,0,105.29,43,105.29,109.94A137.58,137.58,0,0,1,493,462h19.15l.19-.66a162.2,162.2,0,0,0,4.74-39.89c0-74.65-54.4-128.38-123.73-128.38-54.46,0-103,37.8-116.76,86.4Z" transform="translate(-88.52 -293.05)">
							</path>
							</svg> at checkout*
					`
				}
			],
			"ProductTextCost": [
				{
					"type": "div",
					"className": "",
					"content": `Now`
				},
				{
					"content": `savings promotion`
				}
			],
			"ProductGuarantee": [
				{
					"type": "div",
					"className": "riskFree",
					"content": `<div class="award"><i class="fas fa-award"></i></div>
								<div class="text">
								  <div class="header">Completely Risk Free</div>
								  <div class="guarantee">We‘ll refund you your money if you do not qualify. <span class="emph">AND we'll even send you a <strong>FREE IMPRESSION KIT!</strong></span><br/><span class="emph2">DON'T WAIT, BUY NOW WHILE SUPPLIES LAST!</span></div>
								</div>`
				},
			],
			"AffirmSmallPrint": [
				{
					"type": "div",
					"className": "smallPrint",
					"content": `
						*The monthly payment you see displayed for an Affirm purchase is just an estimate and could change based on several factors, including how long you choose to pay for the item, the total price of the product, your creditworthiness as determined by Affirm, and other internal calculations Affirm uses to set your loan terms. The monthly price shown is not a guaranteed amount and may be higher or lower when you finalize your purchase with Affirm.
					`
				}
			],
		}
	},
	"ESPAÑOL": {
		"CONTENT": {
			"ProductButtons": [
				{
					"content": "Compra Ahora"
				}
			],
			"ProductSubText": [
				{
					"type": "div",
					"className": "subProductTitle",
					"content": `
						Flexibilidad que se adapta a ti!
					`
				},
				{
					"type": "div",
					"className": "hightLightAffirmText",
					"content": `
						Tan bajo como <strong>$68</strong> por mes con <svg id="Affirm_2_Color_-_HEX" data-name="Affirm 2 Color - HEX" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428.55 171" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 70px;display: inline;vertical-align: baseline;padding: 0 4px;">
							<defs>
							<style>
							.cls-1,.cls-2{fill:#101820;}.cls-1,.cls-3{fill-rule:evenodd;}.cls-3{fill:#4a4af4;}
							</style>
							</defs>
							<path class="cls-1" d="M116.62,449.32c-5.3,0-8-2.61-8-6.9,0-8,8.92-10.69,25.19-12.42,0,10.66-7.21,19.32-17.24,19.32Zm7-59.95c-11.63,0-25,5.47-32.26,11.26l6.63,14c5.82-5.33,15.23-9.89,23.72-9.89,8.07,0,12.53,2.7,12.53,8.14,0,3.65-2.95,5.5-8.53,6.23-20.86,2.7-37.21,8.46-37.21,24.53,0,12.74,9.07,20.45,23.24,20.45,10.12,0,19.12-5.62,23.4-13v11H154V416c0-19-13.19-26.67-30.37-26.67Z" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M312.91,391.44V462h20.18V428c0-16.15,9.78-20.9,16.59-20.9a15.47,15.47,0,0,1,8.63,2.55L362,391a22.84,22.84,0,0,0-9-1.63c-10.37,0-16.89,4.59-21.19,13.93V391.44H312.91" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M455.52,389.37c-10.67,0-18.65,6.3-22.8,12.38-3.85-7.85-12-12.38-21.8-12.38-10.66,0-18.05,5.92-21.46,12.74V391.44H370V462H390.2V425.67c0-13,6.83-19.29,13.2-19.29,5.77,0,11.07,3.73,11.07,13.36V462h20.16V425.67c0-13.19,6.66-19.29,13.33-19.29,5.34,0,11,3.88,11,13.22V462h20.16V413.22c0-15.85-10.67-23.85-23.56-23.85" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M263.8,391.44H245.52v-7.17c0-9.34,5.33-12,9.92-12a20.18,20.18,0,0,1,9,2.25l6.22-14.23s-6.31-4.12-17.78-4.12c-12.89,0-27.56,7.27-27.56,30.08v5.19H194.77v-7.17c0-9.34,5.32-12,9.92-12a19.12,19.12,0,0,1,9,2.25l6.22-14.23c-3.71-2.17-9.68-4.12-17.77-4.12-12.89,0-27.56,7.27-27.56,30.08v5.19H162.9V407h11.71v55h20.16V407h30.59v55h20.16V407H263.8V391.44" transform="translate(-88.52 -293.05)">
							</path>
							<rect class="cls-2" x="187.32" y="98.39" width="20.14" height="70.53">
							</rect>
							<path class="cls-3" d="M276.58,379.45h19.73c11.51-36.19,50.56-68,97-68,56.48,0,105.29,43,105.29,109.94A137.58,137.58,0,0,1,493,462h19.15l.19-.66a162.2,162.2,0,0,0,4.74-39.89c0-74.65-54.4-128.38-123.73-128.38-54.46,0-103,37.8-116.76,86.4Z" transform="translate(-88.52 -293.05)">
							</path>
							</svg> al finalizar la compra*
					`
				}
			],
			"ProductTextCost": [
				{
					"type": "div",
					"className": "",
					"content": `Ahora`
				},
				{
					"content": `promoción de ahorro`
				}
			],
			"ProductGuarantee": [
				{
					"type": "div",
					"className": "riskFree",
					"content": `
								<div class="award"><i class="fas fa-award"></i></div>
								<div class="text">
									<div class="header">¡Completamente libre de riesgos!</div>
									<div class="guarantee">Le devolveremos su dinero si no califica. <span class="emph">¡E incluso le enviaremos un <strong>KIT DE IMPRESIÓN GRATUITO!</strong></span><br/><span class="emph2">¡NO ESPERE, COMPRE AHORA HASTA AGOTAR EXISTENCIAS! </span></div>
								</div>
							`
				},
			],
			"AffirmSmallPrint": [
				{
					"type": "div",
					"className": "smallPrint",
					"content": `
						*El pago mensual que ve mostrado para una compra de Affirm es solo una estimación y podría cambiar según varios factores, incluido cuánto tiempo elige pagar el artículo, el precio total del producto, su solvencia según lo determine Affirm y otros factores internos. Cálculos que Affirm utiliza para establecer los términos de su préstamo. El precio mensual que se muestra no es una cantidad garantizada y puede ser mayor o menor cuando finalice su compra con Affirm.
					`
				}
			]
		}
	}
};


	
export default PAGE;
