import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_FIELDS_ALIGNERS} from "../ALIGNERS/index";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_FIELDS_USER} from "../USERS/index";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
import PREVIEW_FORMS from "./PREVIEW_FORMS/index";

const DEFAULT_MUTATION_FIELDS = `
  ID
  AIDS
  OIID
  TPID
  UID
  shippedAt
  scheduledAt
  createdAt
  createdBy
  progressLogs{
    ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
  }
  step
  status
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const FULL_MUTATION_FIELDS = (include=["aligners"])=>{
  var IncludeDic = {
    aligners: `
      aligners{
        _id
        ${DEFAULT_MUTATION_FIELDS_ALIGNERS}
      }
    `,
    user: `
      user{
        _id
        ${DEFAULT_MUTATION_FIELDS_USER}
      }
    `
  };
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  include.map((key, index)=>{
    if(IncludeDic[key]) FullMutation += IncludeDic[key];
  })
  return FullMutation;
};

const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $TPID: String,
  $UID: String,
  $AIDS: [String],
  $OIID: String,
  $shippedAt: String,
  $scheduledAt: String,
  $createdAt: String,
  $createdBy: String,
  $progressLogs: [NotesListItemInput], 
  $status: String,
  $step: String,
  $meta: MetaInput
`;


const DEFAULT_MUTATION_INPUT = ` 
  UID: $UID,
  TPID: $TPID,
  AIDS: $AIDS,
  OIID: $OIID,
  shippedAt: $shippedAt,
  scheduledAt: $scheduledAt,
  createdAt: $createdAt,
  createdBy: $createdBy,
  progressLogs: $progressLogs,
  step: $step,
  status: $status,
  meta: $meta,
  
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "UID": "string",
  "TPID": "string",
  "OIID": "string",
  "AIDS": ["string"],
  "shippedAt": "string",
  "scheduledAt": "string",
  "createdAt": "string",
  "createdBy": "string",
  "step": "string",
  "status": "string",
  "progressLogs": [
    CLEAN_FIELDS_DEFAULT.NOTESLIST
  ], 
  "meta": CLEAN_FIELDS_DEFAULT.META
}




const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "ID",
    name: "TPID",
    defaultValue: "",
    path: "TPID",
    searchable: true
  },
  {
    "path": "user.firstName,user.lastName",
    "type": "VALUE",
    "toolTip": true,
    "name": "User Name",
    sortable: false
  }, 
  
  // {
  //   type: "VALUE",
  //   name: "createdBy",
  //   defaultValue: "",
  //   path: "createdBy"
  // },
  // {
  //   type: "VALUE",
  //   name: "UID",
  //   defaultValue: "",
  //   path: "treatmentPlan.user.UID"
  // },
  {
    type: "VALUE",
    name: "Step",
    defaultValue: "",
    path: "step"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "",
    path: "status"
  },
  {
    type: "DATE",
    name: "scheduledAt",
    defaultValue: "",
    path: "scheduledAt"
  },
  {
    type: "DATE",
    name: "createdAt",
    defaultValue: "",
    path: "createdAt"
  },
  {
    type: "DATE",
    name: "shippedAt",
    defaultValue: "",
    path: "shippedAt"
  }
];

const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.progressLogs || (curCleanObj.progressLogs.length < 1)) delete curCleanObj.progressLogs;      
  if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.progressLogs || (curCleanObj.progressLogs.length < 1)) delete curCleanObj.progressLogs;      
  if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}



const ALIGNER_GROUPS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default ALIGNER_GROUPS;
export {
  PREVIEW_FORMS,
  FULL_MUTATION_FIELDS,
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
};