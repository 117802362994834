import React from 'react';
import ReactDOM from 'react-dom';

const CHANGE_LANG = () => { //ADD ANY NEW PAGES HERE
  var CHANG_LANG_VALUES = {
    "/home": {
      "convert": "/inicio",
      "len": "ENGLISH"
    },
    "/getstarted": {
    	"convert": "/comencemos",
    	"len": "ENGLISH"
    },
    "/bestaligners": {
    	"convert": "/mejoresalineadores",
    	"len": "ENGLISH"
    },
    "/pricing": {
    	"convert": "/precios",
    	"len": "ENGLISH"
    },
    "/testimonials": {
    	"convert": "/testimonios",
    	"len": "ENGLISH"
    },
    "/store": {
    	"convert": "/tienda",
    	"len": "ENGLISH"
    },
    "/blog": {
    	"convert": "/elblog",
    	"len": "ENGLISH"
    },
    "/login": {
    	"convert": "/acceso",
    	"len": "ENGLISH"
    },
    "/confirmemail": {
    	"convert": "/confirmaremail",
    	"len": "ENGLISH"
    },
    "/faq": {
    	"convert": "/pmf",
    	"len": "ENGLISH"
    },
    "/about": {
    	"convert": "/sobrenosotros",
    	"len": "ENGLISH"
    },
    "/contact": {
    	"convert": "/contacto",
    	"len": "ENGLISH"
    },
    "/consent": {
    	"convert": "/consentimiento",
    	"len": "ENGLISH"
    },
    "/privacy": {
    	"convert": "/privacidad",
    	"len": "ENGLISH"
    },
    "/termsofuse": {
    	"convert": "/condicionesdeuso",
    	"len": "ENGLISH"
    },
    "/winners": {
      "convert": "/ganadores",
      "len": "ENGLISH"
    },
    "/guarantees": {
    	"convert": "/garantias",
    	"len": "ENGLISH"
    },
    "/guarantees": {
      "convert": "/garantias",
      "len": "ENGLISH"
    },
    "/giveaways": {
      "convert": "/regalos",
      "len": "ENGLISH"
    },
    "/buykit": {
      "convert": "/comprakit",
      "len": "ENGLISH"
    },
    "/buy": {
      "convert": "/compra",
      "len": "ENGLISH"
    },
    "/impressionKit": {
      "convert": "/remisión",
      "len": "ENGLISH"
    },
    "/promo": {
      "convert": "/promocion",
      "len": "ENGLISH"
    },
    "/promoReports": {
      "convert": "/promocionReportes",
      "len": "ENGLISH"
    },
    "/promoReport": {
      "convert": "/promocionReportes",
      "len": "ENGLISH"
    },
    "/schools": {
      "convert": "/escuelas",
      "len": "ENGLISH"
    },
    "/user": {
      "convert": "/user/inicio",
      "len": "ENGLISH"
    },
    "/user/": {
      "convert": "/user/inicio",
      "len": "ENGLISH"
    },
    "/user/billing": {
      "convert": "/user/facturacion",
      "len": "ENGLISH"
    },
    "/user/treatmentplan": {
      "convert": "/user/tratamiento",
      "len": "ENGLISH"
    },
    "/user/profile": {
      "convert": "/user/perfil",
      "len": "ENGLISH"
    },
    "/user/help": {
      "convert": "/user/ayuda",
      "len": "ENGLISH"
    },
    "/lab": {
      "convert": "/lab/inicio",
      "len": "ENGLISH"
    },
    "/lab/": {
      "convert": "/lab/inicio",
      "len": "ENGLISH"
    },
    "/lab/history": {
      "convert": "/lab/historia",
      "len": "ENGLISH"
    },
    "/lab/profile": {
      "convert": "/lab/perfil",
      "len": "ENGLISH"
    },
    "/lab/help": {
      "convert": "/lab/ayuda",
      "len": "ENGLISH"
    },
    "/doctor": {
      "convert": "/doctor/inicio",
      "len": "ENGLISH"
    },
    "/doctor/": {
      "convert": "/doctor/inicio",
      "len": "ENGLISH"
    },
    "/doctor/patients": {
      "convert": "/doctor/pacientes",
      "len": "ENGLISH"
    },
    "/doctor/billing": {
      "convert": "/doctor/facturacion",
      "len": "ENGLISH"
    },
    "/doctor/profile": {
      "convert": "/doctor/perfil",
      "len": "ENGLISH"
    },
    "/doctor/help": {
      "convert": "/doctor/ayuda",
      "len": "ENGLISH"
    },






    //ESPAÑOL


    "/inicio": {
    	"convert": "/home",
    	"len": "ESPAÑOL"
    },
    "/comencemos": {
    	"convert": "/getstarted",
    	"len": "ESPAÑOL"
    },
    "/mejoresalineadores": {
    	"convert": "/bestaligners",
    	"len": "ESPAÑOL"
    },
    "/precios": {
    	"convert": "/pricing",
    	"len": "ESPAÑOL"
    },
    "/testimonios": {
    	"convert": "/testimonials",
    	"len": "ESPAÑOL"
    },
    "/tienda": {
    	"convert": "/store",
    	"len": "ESPAÑOL"
    },
    "/elblog": {
    	"convert": "/blog",
    	"len": "ESPAÑOL"
    },
    "/acceso": {
    	"convert": "/login",
    	"len": "ESPAÑOL"
    },
    "/confirmaremail": {
    	"convert": "/confirmemail",
    	"len": "ESPAÑOL"
    },
    "/pmf": {
    	"convert": "/faq",
    	"len": "ESPAÑOL"
    },
    "/sobrenosotros": {
    	"convert": "/about",
    	"len": "ESPAÑOL"
    },
    "/contacto": {
    	"convert": "/contact",
    	"len": "ESPAÑOL"
    },
    "/consentimiento": {
    	"convert": "/consent",
    	"len": "ESPAÑOL"
    },
    "/privacidad": {
    	"convert": "/privacy",
    	"len": "ESPAÑOL"
    },
    "/condicionesdeuso": {
    	"convert": "/termsofuse",
    	"len": "ESPAÑOL"
    },
    "/garantias": {
    	"convert": "/guarantees",
    	"len": "ESPAÑOL"
    },
    "/regalos": {
      "convert": "/giveaways",
      "len": "ESPAÑOL"
    },
    "/comprakit": {
      "convert": "/buykit",
      "len": "ESPAÑOL"
    },
    "/kitDeImpresion": {
      "convert": "/impressionKit",
      "len": "ESPAÑOL"
    },
    "/promocion": {
      "convert": "/promo",
      "len": "ESPAÑOL"
    },
    "/promocionReportes": {
      "convert": "/promoReport",
      "len": "ESPAÑOL"
    },
    "/ganadores": {
      "convert": "/winners",
      "len": "ESPAÑOL"
    },
    "/escuelas": {
      "convert": "/schools",
      "len": "ESPAÑOL"
    },
    "/user/inicio": {
      "convert": "/user",
      "len": "ESPAÑOL"
    },
    "/user/facturacion": {
      "convert": "/user/billing",
      "len": "ESPAÑOL"
    },
    "/user/tratamiento": {
      "convert": "/user/treatmentplan",
      "len": "ESPAÑOL"
    },
    "/user/perfil": {
      "convert": "/user/profile",
      "len": "ESPAÑOL"
    },
    "/user/ayuda": {
      "convert": "/user/help",
      "len": "ESPAÑOL"
    },
    "/lab/inicio": {
      "convert": "/lab",
      "len": "ESPAÑOL"
    },
    "/lab/historia": {
      "convert": "/lab/history",
      "len": "ESPAÑOL"
    },
    "/lab/perfil": {
      "convert": "/lab/profile",
      "len": "ESPAÑOL"
    },
    "/lab/ayuda": {
      "convert": "/lab/help",
      "len": "ESPAÑOL"
    },
    "/doctor/inicio": {
      "convert": "/doctor",
      "len": "ESPAÑOL"
    },
    "/doctor/pacientes": {
      "convert": "/doctor/patients",
      "len": "ESPAÑOL"
    },
    "/doctor/facturacion": {
      "convert": "/doctor/billing",
      "len": "ESPAÑOL"
    },
    "/doctor/perfil": {
      "convert": "/doctor/profile",
      "len": "ESPAÑOL"
    },
    "/doctor/ayuda": {
      "convert": "/doctor/help",
      "len": "ESPAÑOL"
    },
    "/restablecerlacontrasena": {
      "convert": "/resetpassword",
      "len": "ESPAÑOL"
    }
  };

  if((typeof window !== 'undefined') && (window.DefaultLang == "ESPAÑOL")){
    CHANG_LANG_VALUES["/"] = {
      "convert": "/home",
      "len": "ESPAÑOL"
    }
  } else {
    CHANG_LANG_VALUES["/"] = {
      "convert": "/inicio",
      "len": "ENGLISH"
    }
  }
  return CHANG_LANG_VALUES;
}


const ChangeLang = ({newLang="ENGLISH"}) => { 
  if(typeof window === 'undefined')	return; //DON'T CHANGE LANGUEGES IF ON SERVER SIDE
  var CHAN_LANG = CHANGE_LANG();
  // if(SOME WAY TO TELL NOT READY){ //IF ROUTER NOT READY THEN SCHEDULE FOR THIS TO RUN AGAIN WHEN IT IS READY
  //   window.GlobalUtil.subscribeEventOnce("RouterEvent", ()=>ChangeLang(newLang));
  //   return;
  // }
        
	var curPathName = window.location.pathname;	 
  //console.log(`curPathName`,"\n\n",curPathName,"\n\n");
  if((curPathName.length > 1) && (curPathName.slice(-1) === "/")){ //IF ENDS IN / THEN REMOVE IT UNLESS HOME
    curPathName = curPathName.slice(0,-1);
  }
  if(!CHAN_LANG[curPathName] || (CHAN_LANG[curPathName].len === newLang)) return;
	var newPath = (CHAN_LANG[curPathName] ? CHAN_LANG[curPathName].convert : undefined);	 
  //console.log(`curPathName`,"\n\n",curPathName,"\n\n");
  //console.log(`newPath`,"\n\n",newPath,"\n\n");
                                   	
	if(newPath) {
    window.NoScroll = true; //IF ONLY CHANGING LANGUAGES THEN DO NOT SCROLL TO TOP
    window.location.href = (newLang === "ENGLISH" ? `https://Smiles.club` : `https://Sonrisas.club`)+newPath;
  }
}


const GetLan = (justGetLan=false) => {
  if(typeof window === 'undefined')	return "ENGLISH"; //DON'T RUN ANYTHING WITH WINDOW UNTIL BROWSER SIDE
  var CHAN_LANG = CHANGE_LANG();
  // if(SOME WAY TO TELL NOT READ){ //IF ROUTER NOT READY THEN SCHEDULE FOR THIS TO RUN AGAIN WHEN IT IS READY
  //   window.GlobalUtil.subscribeEventOnce("RouterEvent", GetLan);
  //   return;
  // }
	var curPathName = window.location.pathname;
  if((curPathName.length > 1) && (curPathName.slice(-1) === "/")){ //IF ENDS IN / THEN REMOVE IT UNLESS HOME
    curPathName = curPathName.slice(0,-1);
  }
  //console.log("GetLan","\n\n",curPathName,"\n\n");
	var curLan = (CHAN_LANG[curPathName] ? CHAN_LANG[curPathName].len : null);//"ENGLISH");
  if(justGetLan) return curLan;
  if(curLan && (window.Session.Language === curLan)) return; //SKIP IF CURRENT LANG IS THE SAME
	window.GlobalUtil.triggerEvent("LanguageEvent", (curLan ? curLan : (window.Session.Language ? window.Session.Language : window.DefaultLang))); //UPDATE CURRENT LANG IF NOT SAME
}

export {
	ChangeLang,
	GetLan
};