//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="pageHeader"><i class="fas fa-file-contract"></i><span>Plan de Tratamiento</span></h1>`
	},
  {
    "content": `Tu plan personal`
  },
  {
    "content": `La siguiente es información sobre su plan de tratamiento personal único. Una vez que haya elaborado su plan, podrá ver un PDF del mismo a continuación.`
  },
  {
    "content": `ID del Plan:`
  },
  {
    "content": `Fecha de creación:`
  },
  {
    "content": `Plan para:`
  },
  {
    "content": `Creado por:`
  },
  {
    "content": `Aprobado por:`
  },
  {
    "content": `Alineadores Arriba:`
  },
  {
    "content": `Alineadores Abajo:`
  },
  {
    "content": `Duración:`
  },
  {
    "content": `Notas del Doctor:`
  },
  {
    "content": `Plan inicializado:`
  },
  {
    "content": `Plan comprado` //IF THEY HAVE NOT PURCHASED IT YET THEN THIS WILL BE THE TEXT FOR THEM TO START THAT
  },
  {
    "content": `Archivos del plan:`
  },
  {
    "content": `Vista` //VIEW BUTTON TEXT
  },
  {
    "content": `Plan PDF:`
  },


  { //17
    "content": `Inicializado Plan de Tratamiento`
  },
  { //18
    "content": `Estamos creando tu oferta de plan de tratamiento personalizado.`
  },
];




	
export default PAGE;