import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_ORDER_ITEM} from "../ORDER_ITEMS/index";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import DEFAULT_PREVIEW_FORM from "./ORDER_PREVIEW_FORM";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  UID
  OIIDS
  paymentOrderID
  paymentType
  paymentSubscriptionID
  couponID
  status
  comment
  website
  cost {
    subtotal
    discount
    subtotalMinusDis
    tax
    setupFee
    shipping
    total
  }
  paidAt
  chargingAt
  shipped
  progressLogs{
    ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
  }
  productsStringArray
  user {
    _id
    firstName 
    lastName 
    email 
  }
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;

const FULL_MUTATION_FIELDS = (include=["orderItems"])=>{
  var IncludeDic = {
    user: `
      user {
        _id
        firstName 
        lastName 
        email 
      }
    `,
    orderItems: `
      orderItems{
        _id
        ${DEFAULT_MUTATION_ORDER_ITEM}
      }
    `
  };
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  include.map((key, index)=>{
    if(IncludeDic[key]) FullMutation += IncludeDic[key];
  })
  return FullMutation;
};


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;



const DEFAULT_MUTATION_INPUT_VAR = ` 
  $UID: String,
  $OIIDS: [String],
  $paymentOrderID: String,
  $paymentType: String,
  $paymentSubscriptionID: String,
  $couponID: String,
  $status: String,
  $comment: String,
  $progressLogs: [NotesListItemInput], 
  $cost: CostInput,
  $productsStringArray: String,
  $paidAt: String,
  $shipped: String,
  $chargingAt: String,
  $website: String,
  $orderItemsStringArray: String,
  $sendEmail: Boolean
`;


const DEFAULT_MUTATION_INPUT = ` 
  UID: $UID,
  OIIDS: $OIIDS,
  paymentOrderID: $paymentOrderID,
  paymentType: $paymentType,
  paymentSubscriptionID: $paymentSubscriptionID,
  couponID: $couponID,
  cost: $cost,
  status: $status,
  comment: $comment,
  progressLogs: $progressLogs,
  productsStringArray: $productsStringArray,
  shipped: $shipped,
  paidAt: $paidAt,
  chargingAt: $chargingAt,
  website: $website,
  orderItemsStringArray: $orderItemsStringArray,
  sendEmail: $sendEmail
`;

const CLEAN_FIELDS = { 
  "id": "string",
  "UID": "string",
  "OIIDS": ["string"],
  "paymentOrderID": "string",
  "paymentType": "string",
  "paymentSubscriptionID": "string",
  "couponID": "string",
  "status": "string",
  "comment": "string",
  "cost,subtotal": "number",
  "cost,discount": "number",
  "cost,subtotalMinusDis": "number",
  "cost,tax": "number",
  "cost,setupFee": "number",
  "cost,shipping": "number",
  "cost,total": "number",
  "sendEmail": "boolean",
  "progressLogs": [
    CLEAN_FIELDS_DEFAULT.NOTESLIST
  ], 
  "productsStringArray": "string",
  "website": "string",
  "paidAt": "string",
  "shipped": "string",
  "chargingAt": "string",
  "meta": CLEAN_FIELDS_DEFAULT.MET
}


const LIST_HEADER_FIELDS = [
  {
    "path": "user.firstName,user.lastName",
    "type": "VALUE",
    "toolTip": true,
    "name": "User Name",
    "minWidth": "200px",
    "sortable": false
  },
  {
    "path": "user.email",
    "type": "VALUE",
    "name": "Email",
    "sortable": false
  },
  {
    path: "ID",
    type: "ID",
    name: "Order ID",
    searchable: true
  },
  {
    type: "ID",
    name: "User ID",
    defaultValue: "",
    path: "UID",
    searchable: true
  },
  {
    type: "ID",
    name: "Order Item IDS",
    defaultValue: "",
    path: "OIIDS",
    searchable: true
  },
  {
    path: "paymentOrderID",
    type: "VALUE",
    name: "Payment ID",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "paymentType",
    type: "VALUE",
    name: "Payment TYPE",
    defaultValue: "NONE",
    searchable: true
  },
  
  // {
  //   type: "VALUE",
  //   name: "Status",
  //   defaultValue: "ACTIVE",
  //   path: "status"
  // },
  {
    type: "MONEY",
    name: "Total",
    defaultValue: 0,
    path: "cost.total",
    searchable: true
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  }
];




// const DEFAULT_PREVIEW_FORM = () => [
//   {
//     "TYPE": "ROW",
//     "FIELDS": [
//       {
//         "TYPE": "COLUMN",
//         "TITLE":"Products",
//         //"SUB_TITLE":"Current user mouth ",
//         "FIELDS": [
//           {
//             "PATH": "productsStringArray",
//             "PARSE": true,
//             "TYPE": "ARRAY_CUSTOM",
//             "CUSTOM": (obj)=>{
//               //console.log(`obj`,"\n\n",obj,"\n\n");
//               var imageUrl = (obj.images ? obj.images[0].url : (obj.img ? obj.img : ""))
//               return(
//                 <div className="productsList">
//                   {
//                     imageUrl && 
//                     <div className="image">
//                       <img src={imageUrl} alt=""/>
//                     </div>
//                   }
//                   <div className="textWrapper">
//                     <div className="text">
//                       <div className="titleText">Name: </div>
//                       <div className="value">{obj.name}</div>
//                     </div>
//                     <div className="text">
//                       <div className="titleText">Id: </div>
//                       <div className="value">{obj.id}</div>
//                     </div>
//                     <div className="text">
//                       <div className="titleText">Quantity: </div>
//                       <div className="value">{obj.quantity}</div>
//                     </div>
//                     <div className="text">
//                       <div className="titleText">Ref: </div>
//                       <div className="value">{obj.ref}</div>
//                     </div>
//                     <div className="text">
//                       <div className="titleText">Category: </div>
//                       <div className="value">{obj.category}</div>
//                     </div>
//                     <div className="text">
//                       <div className="titleText">Type: </div>
//                       <div className="value">{obj.type}</div>
//                     </div>
//                     <div className="text">
//                       <div className="titleText">Price: </div>
//                       <div className="value">{window.GlobalUtil.convertToMoney(Number(obj.cost)-Number(obj.discount))}</div>
//                     </div>
//                   </div>
//                 </div>
//               )
//             }
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "TYPE": "ROW",
//     "FIELDS": [
//       {
//         "TYPE": "COLUMN",
//         "FIELDS": [
//           {
//             "PATH": "chargingAt",
//             "TYPE": "DATE",
//             "NAME": "Charging At"
//           },
//           {
//             "PATH": "paidAt",
//             "TYPE": "DATE",
//             "NAME": "Paid At"
//           },
//           {
//             "PATH": "_id",
//             "TYPE": "STRING",
//             "NAME": "ID"
//           },
//           {
//             "PATH": "UID",
//             "TYPE": "STRING",
//             "PREVIEW_LINK": {
//               "TYPE": "PREVIEW_BUTTON",
//               "TITLE": "User", //TITLE OF SLIDE OUT
//               "APINAME": "USERS",
//               "ID_PATH": "UID"
//             },
//             "NAME": "UID"
//           },
//           {
//             "PATH": "OIIDS",
//             "TYPE": "ARRAY_STRINGS",
//             "PREVIEW_LINK": {
//               "TYPE": "PREVIEW_BUTTON",
//               "TITLE": "Order Item", //TITLE OF SLIDE OUT
//               "APINAME": "ORDER_ITEMS",
//               "ID_PATH": "OIIDS"
//             },
//             "NAME": "ORDER ITEMS ID"
//           },
//           {
//             "PATH": "paymentOrderID",
//             "TYPE": "STRING",
//             "NAME": "PaymentOrderID"
//           },
//           {
//             "CONDITION": obj=>obj.couponID,
//             "PATH": "couponID",
//             "TYPE": "STRING",
//             "PREVIEW_LINK": {
//               "TYPE": "PREVIEW_BUTTON",
//               "TITLE": "Coupon", //TITLE OF SLIDE OUT
//               "APINAME": "COUPONS",
//               "ID_PATH": "couponID"
//             },
//             "NAME": "CouponID"
//           },
//           {
//             "PATH": "comment",
//             "TYPE": "STRING",
//             "NAME": "Comment"
//           },
//           {
//             "PATH": "status",
//             "TYPE": "STRING",
//             "NAME": "Status"
//           }
//         ]
//       },
//       {
//         "TYPE": "COLUMN",
//         "FIELDS": [
//           {
//             "PATH": "shipped",
//             "TYPE": "STRING",
//             "NAME": "Shipped",
//             "DEFAULT": "FALSE"
//           },
//           {
//             "PATH": "cost,subtotal",
//             "TYPE": "MONEY",
//             "NAME": "Subtotal"
//           },
//           {
//             "PATH": "cost,discount",
//             "TYPE": "MONEY",
//             "NAME": "Discount"
//           },
//           {
//             "PATH": "cost,tax",
//             "TYPE": "MONEY",
//             "NAME": "Tax"
//           },
//           {
//             "PATH": "cost,setupFee",
//             "TYPE": "MONEY",
//             "NAME": "Setup Fee"
//           },
//           {
//             "PATH": "cost,shipping",
//             "TYPE": "MONEY",
//             "NAME": "Shipping"
//           },
//           {
//             "PATH": "cost,total",
//             "TYPE": "MONEY",
//             "NAME": "Total"
//           }
//         ]
//       }
//     ]
//   },
//   PREVIEW_FORM_TEMPLATES.META()
// ];


const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.progressLogs || (curCleanObj.progressLogs.length < 1)) delete curCleanObj.progressLogs;      
  if(curCleanObj.productsStringArray && (typeof curCleanObj.productsStringArray !== "string")) curCleanObj.productsStringArray = JSON.stringify(curCleanObj.productsStringArray);
  //if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.progressLogs || (curCleanObj.progressLogs.length < 1)) delete curCleanObj.progressLogs;      
  if(curCleanObj.productsStringArray && (typeof curCleanObj.productsStringArray !== "string")) curCleanObj.productsStringArray = JSON.stringify(curCleanObj.productsStringArray);
  //if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const ORDERS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

const DEFAULT_FIELDS_ORDERS = {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
}

export default ORDERS;
export {
  DEFAULT_MUTATION_FIELDS,
  FULL_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_FIELDS_ORDERS,
  DEFAULT_PREVIEW_FORM
};