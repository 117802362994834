import React, { useState, useRef } from 'react';
import {NavLink, Link} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from 'global/components/FormBuilder/index';
import Loading from 'global/components/Loading/index';
import API_CALLS from 'API_CALLS/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curStatus: false,
      status: 'start',
      curObj: {}
    };    
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  APISendPWRest = (newObj) => {  
    return window.Client.mutate({
      mutation: API_CALLS.USERS.MUTATIONS.forgotPassword(),
      variables: {
        email: newObj.email,
        website: window.SiteSettings.siteNameAllCaps,
        language: window.Session.Language,
      }
    })
    .then(result => {
      console.log(`APISendPWRest result`,"\n\n",result,"\n\n");
      return result.data.signup;
    })
    .catch((error)=>{
      console.log("APISendPWRest SIGNUP FAIL catch", error);
    })
  }


  onSubmitContact = () => {
    var {curObj} = this.state;
    this.setState({status: 'loading'});
    const newVar = JSON.parse(JSON.stringify(curObj));
    this.APISendPWRest(newVar)
      .then((signup)=>{
        this.setState({status: 'success'});
        if(this.props.onSuccess) this.props.onSuccess(true); //UPDATE THE DATABASE PAGE AREA 
      })
      .catch((error)=>{
        this.setState({status: 'failed'});
        console.log(`error`,"\n\n",error,"\n\n");    
      });
        
  }

  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    var {status, curObj, curStatus} = this.state;       
                
    return (
      <div id="ResetPassword" className=''>
        <div className="icon">
          <i className="fas fa-envelope-open-text"></i>
        </div>
        {
          status === 'loading' && 
          <Loading
            header={(window.Session.Language === "ESPAÑOL") ? "Enviando" : "Sending"}
            body={undefined}
            floating={false}
            overlay={false}
            inlineStyles={{
              LoadingScreen: {
                "minHeight": "190px",
                //"border": "1px solid rgb(221, 221, 221)",
                "borderRadius": "10px",
                "position": "relative",
                "paddingTop": "50px"
              },
              loadingIcon: {
                "maxWidth": "80px"
              },
              loadingHeader: {
                "fontSize":"18px",
                "marginBottom":"0",
                "paddingTop":"20px"
              }
            }}
          />
        }
        {
          status === 'start' && 
          <div className="">
            <div className="text">
               {CONTENT[0]}
            </div>
            <br/>
            <FormBuilder
              ref={e => this.formRef = e}
              initialValues={curObj}
              listOfFields={[
                {
                  title: <div className="formTitle"><span className="">Email</span></div>,
                  type: "email",
                  name: "email",
                  required: true
                }
              ]}
              onSubmit={this.onSubmitContact}
              onChange={newObj => {
                this.setState({
                  curObj: newObj,
                  emailError: ''
                });
              }}
              onValidate={(isValid)=>{
                this.setState({curStatus: isValid});
              }}
            />
            <button className={`button button1 ${(curStatus) ? '' : 'disabled'}`} onClick={this.onSubmitContact} disabled={!curStatus}>{CONTENT[1]}</button>
          </div>
        }

        {
          status === 'success' && 
          <div className="">
            <div className="finishedMessage">
              <div className="title">{CONTENT[2]}</div>
              <div className="text">{CONTENT[3]}</div>
              <div className="last">{CONTENT[4]}</div>
              <br/>
              <button className="button button1" onClick={window.GlobalUtil.DialogStack.closeTop}>{CONTENT[8]}</button>
            </div>
          </div>
        }


        {
          status === 'failed' && 
          <div className="">
            <div className="finishedMessage">
              <div className="title">{CONTENT[5]}</div>
              <div className="text">{CONTENT[6]}</div>
              <div className="last">{CONTENT[7]}</div>
              <br/>
              <button className="button button1" onClick={window.GlobalUtil.DialogStack.closeTop}>{CONTENT[8]}</button>
            </div>
          </div>
        }
      </div>
    );
  }
}

//((window.Session.Language === "ESPAÑOL") ? "" : "Send Instructions")


export default Index;