import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from 'global/templates/templatePage/index';



const QUERY = API_CALLS.USERS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.USERS.QUERIES.single();
const QUERY_COUNT = API_CALLS.USERS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.USERS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.USERS.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`user`);

const FORM_FIELDS = API_CALLS.USERS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.USERS.LIST_HEADER_FIELDS;
const PREVIEW = API_CALLS.USERS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  console.log("newObject");
  console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};







const PAGES = () => [
  {
    "id": "USERS01",
    "Name":"Users",
    "PageName": "Users",
    "BaseName": "users",
    "Path": "users",
    "TITLE": "Users",
    "SUBTITLE": "Users are customers who come and purchase our products. They have a user page, treatment plan, login, and more. They can ONLY access their own information and nothing else.",
    "DATA_FIELD": "users",
    "GlobalStateVar": [""],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false, 
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "role",
          val: "USER"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "EDITBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "INACTIVEBUTTON": {
      NAME: "USERS"
    },
    "CLONEBUTTON": {
      NAME: "USERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("users/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "USERS02",
    "Name":"Admins",
    "PageName": "Admins",
    "BaseName": "users",
    "Path": "admins",
    "TITLE": "Admins",
    "SUBTITLE": "Admins are SUPER USERS with full super user priveledges. NO ONE should be an admin but those in charge of the full backed.",
    "DATA_FIELD": "users",
    "GlobalStateVar": [""],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false, 
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "role",
          val: "ADMIN"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "EDITBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "INACTIVEBUTTON": {
      NAME: "USERS"
    },
    "CLONEBUTTON": {
      NAME: "USERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("users/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "USERS03",
    "Name":"Doctors",
    "PageName": "Doctors",
    "BaseName": "users",
    "Path": "doctors",
    "TITLE": "Doctors",
    "SUBTITLE": "Doctors are users that get patients attached to them. Also Doctors have to purchase their patients treatment plans instead of the patients purchasing then on their own.",
    "DATA_FIELD": "users",
    "GlobalStateVar": [""],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false, 
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "role",
          val: "DOCTOR"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "EDITBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "INACTIVEBUTTON": {
      NAME: "USERS"
    },
    "CLONEBUTTON": {
      NAME: "USERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("users/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "USERS04",
    "Name":"Patients",
    "PageName": "Patients",
    "BaseName": "users",
    "Path": "patients",
    "TITLE": "Patients",
    "SUBTITLE": "Patients are like normal users BUT they need a Doctor assigned to them. Also the Doctor purchases the treatment plan instead of the patient. They also don't need login credentials because they cannot login themselves.",
    "DATA_FIELD": "users",
    "GlobalStateVar": [""],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false, 
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "role",
          val: "PATIENT"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "EDITBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "INACTIVEBUTTON": {
      NAME: "USERS"
    },
    "CLONEBUTTON": {
      NAME: "USERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("users/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "USERS05",
    "Name":"Labs",
    "PageName": "Labs",
    "BaseName": "users",
    "Path": "labs",
    "TITLE": "Labs",
    "SUBTITLE": "Laboratories are needed to every treatment plan. They create the plans and upload them to each treatment plan when they are ready.",
    "DATA_FIELD": "users",
    "GlobalStateVar": [""],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false, 
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "role",
          val: "LABORATORY"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "EDITBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "INACTIVEBUTTON": {
      NAME: "USERS"
    },
    "CLONEBUTTON": {
      NAME: "USERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("users/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "USERS06",
    "Name":"Pending",
    "PageName": "Pending",
    "BaseName": "users",
    "Path": "pending",
    "TITLE": "Pending Users",
    "DATA_FIELD": "users",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "PENDING",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "EDITBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "INACTIVEBUTTON": {
      NAME: "USERS"
    },
    "CLONEBUTTON": {
      NAME: "USERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("users/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "USERS06",
    "Name":"History",
    "PageName": "History",
    "BaseName": "users",
    "Path": "history",
    "TITLE": "User History",
    "SUBTITLE": "Once we are no longer woking with the user THEN we can put them in the inactive tab. We don't delete them because we may need their information in the future.",
    "DATA_FIELD": "users",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "INACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "EDITBUTTON": {
      NAME: "USERS",
      TITLE: "User",
      FIELD: "user"
    },
    "DELETEBUTTON": {
      NAME: "USERS"
    },
    "CLONEBUTTON": {
      NAME: "USERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("users/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  }
];





const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminPlans"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "userCount",
            TITLE: "New Users Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "userCount",
            TITLE: "Users this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "userCount",
            TITLE: "Users this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "userCount",
            TITLE: "Users this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/",
        "to": "users"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
