import React, { useState, useRef } from 'react';
import SlideInRight from 'global/components/SlideInRight/index';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;

class TemplateSlideInRight extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};    
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount(){
    //this.DialogStackRef = window.GlobalUtil.DialogStack.add(this.onClose) 
  }
  componentWillUnmount(){
    //this.DialogStackRef.close();
  }
  onClose(){
    if(this.slideInRightRef) this.slideInRightRef.onClose()
  }
  render(){
    var {
      title, 
      icon, 
      closeIcon,
      isValid, 
      saveButtonText="Save",
      closeButtonText="Close",
      hideSaveButton, 
      onSave=()=>{
        this.onClose();
      },
      width="1150px",
      customClass,
      noHeader,
      noFooter
    } = this.props;
    return (
      <SlideInRight 
        ref={e=>this.slideInRightRef=e}
        showOverlay={true}
        customClass={customClass}
        width={width}
        maxWidth="90%"
        header={(
          noHeader 
          ? null 
          : <React.Fragment>
              <div className="title">
                {icon ? <div className="icon">{icon}</div> : null}
                {title}
              </div>
              {closeIcon ? <div className="close" onClick={this.onClose}><i className="fas fa-times"></i></div> : null}
            </React.Fragment>
        )}
        footer={
          noFooter
          ? null
          : <div className="buttonGroup split">
              {
                this.props.customButtons
                ? this.props.customButtons(this.slideInRightRef)
                : <React.Fragment>
                    <button className="button button2" onClick={this.onClose}>{closeButtonText}</button>
                    {
                      !hideSaveButton && <button className={`button button1 ${isValid ? "" : "disabled"}`} onClick={()=>{ 
                        onSave();
                      }}>{saveButtonText}</button>
                    }
                    {
                      this.props.otherButtons
                      ? this.props.otherButtons.map((object, index)=>{
                          return(
                            <button 
                              key={index}
                              className={`button ` + object.className} 
                              onClick={()=>object.onClick(this.slideInRightRef)}>
                              {object.buttonText}
                            </button>
                          )
                        })
                      : null
                    }
                  </React.Fragment>
              }
            </div>
        }
        onClose={()=>{
          if(this.props.onClose) this.props.onClose();
        }}>
          {
            this.props.children
          }
      </SlideInRight>
    );
  }
}




export default TemplateSlideInRight;