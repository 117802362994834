//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Purchase your Full Clear Aligners Kit Here! No risk invisible aligners",
			"DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Purchase invisible orthodontics without BPA here.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
		},
		"CONTENT": {
			"PageBuyImpressionKit": [
				{
					"type": "div",
					"className": "d-none",
					"content": `
						<h1 className="sectionTitled-none">Buy Clear Aligners!</h1>
					`
				}
			],
			"SimpleStepsHeaderContainer": [
				{
					"type": "div",
					"content": `
					  <div class="preHeader">Quick rundown</div>
					  <h2 class="sectionTitle2">How it Works</h2>
					  <div class="subHeader">Buy the Full Treatment Plan and We'll Send You Your Impression Kit!</div>
					`
				  }
			],
			"SimpleStepsList": [
				{
					"type": "div",
					"className": "text",
					"content": `
						<div class="textHeader">
						<div class="textHeaderStep">Step 1.</div>
						<div class="textHeaderTitle">Buy the FULL Treatment Plan</div>
						</div>
						<div class="textPostHeader">Purchased the full plan we'll send you an impression kit in the mail.</div>
						
					`
				},
				{
					"type": "div",
					"className": "text",
					"content": `
						<div class="textHeader">
						<div class="textHeaderStep">Step 2.</div>
						<div class="textHeaderTitle">Get your Impression Kit</div>
						</div>
						<div class="textPostHeader">Take your impressions with some pictures and send them back to us.</div>
						
					`
				},
				{
					"type": "div",
					"className": "text",
					"content": `<div class="textHeader">
									<div class="textHeaderStep">Step 3.</div>
									<div class="textHeaderTitle">Start your Aligners!</div>
									</div>
									<div class="textPostHeader">We'll send you your clear aligners with instructions so you can get started.</div>
								`
					}
			],
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | ¡Adquiere aquí tu kit completo de alineadores transparentes! Alineadores invisibles sin riesgo",
			"DESCRIPTION": "Sonrisas Club proporciona un enderezamiento fácil y hermoso de los dientes desde casa. Compra ortodoncia invisible sin BPA aquí.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"PageBuyImpressionKit": [
				{
					"type": "div",
					"className": "d-none",
					"content": `
						<h1 className="sectionTitled-none">¡Compre alineadores transparentes!</h1>
					`
				}
			],
			"SimpleStepsHeaderContainer": [
				{
					"type": "div",
					"content": `
					  <div class="preHeader">Resumen rápido</div>
					  <h2 class="sectionTitle2">Cómo funciona</h2>
					  <div class="subHeader">Le enviaremos un kit de impresión para que pueda comenzar.</div>
					  <br/>  
					  <br/>
					`
				  }
			],
			"SimpleStepsList": [
				{
					"type": "div",
					"className": "text",
					"content": `
					  <div class="textHeader">
						<div class="textHeaderStep">Paso 1.</div>
						<div class="textHeaderTitle">Compra el Plan de Tratamiento.</div>
					  </div>
					  <div class="textPostHeader">Una vez que haya comprado el plan completo, le enviaremos un kit de impresión. </div>
					  
					`
				  },
				  {
					"type": "div",
					"className": "text",
					"content": `
					  <div class="textHeader">
						<div class="textHeaderStep">Paso 2.</div>
						<div class="textHeaderTitle">Tome las impresiones.</div>
					  </div>
					  <div class="textPostHeader">Luego tome las impresiones de tus dientes, tome algunas fotos y envíenos las impresiones.</div>
					  
					`
				  },
				  {
					"type": "div",
					"className": "text",
					"content": `<div class="textHeader">
									<div class="textHeaderStep">Paso 3.</div>
									<div class="textHeaderTitle">Consigue tus alineadores!</div>
								  </div>
								  <div class="textPostHeader">Le enviaremos tus alineadores transparentes para enderezar tus dientes.</div>
							  `
				  }
			]
		}
	}
};


	
export default PAGE;
