//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation Update(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateTreatmentPlan(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation NewTreatmentPlan(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createTreatmentPlan(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const MUTATION_UPLOADPLAN = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation UploadTreatmentPlan(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    uploadTreatmentPlan(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_APPROVEPLAN = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation ApproveTreatmentPlan(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    approveTreatmentPlan(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;




const MUTATION_REQUEST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreateTreatmentPlanRequest(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createTreatmentPlanRequest(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_REJECT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation RejectTreatmentPlan(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    rejectTreatmentPlan(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneTreatmentPlan(
    $id: String,
  ) {
    cloneTreatmentPlan(id: $id){
      _id
    }
  }
`;
const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneTreatmentPlanList(
    $ids: [String]
  ) {
    cloneTreatmentPlanList(ids: $ids)
  }
`;



const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteTreatmentPlan(
    $id: String,
  ) {
    deleteTreatmentPlan(id: $id)
  }
`;

const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteTreatmentPlanList(
    $ids: [String]
  ) {
    deleteTreatmentPlanList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateTreatmentPlan(
    $id: String,
  ) {
    deactivateTreatmentPlan(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateTreatmentPlanList(
    $ids: [String]
  ) {
    deactivateTreatmentPlanList(ids: $ids)
  }
`;



const MUTATION_STARTED_TREATMENT_PLAN_SCHEDULE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation StartTreatmentPlanSchedule(
    $id: String,
    $started: String
  ) {
    startTreatmentPlanSchedule(input: {
      id: $id, 
      started: $started
    }){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATIONS = {
  update: MUTATION_EDIT,
  create: MUTATION_ADD,
  upload: MUTATION_UPLOADPLAN,
  approve: MUTATION_APPROVEPLAN,
  createRequest: MUTATION_REQUEST,
  reject: MUTATION_REJECT,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST,
  startTreatmentPlan: MUTATION_STARTED_TREATMENT_PLAN_SCHEDULE
}


export default MUTATIONS;