import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import FormBuilder from 'global/components/FormBuilder/index';
import LayoutBuilder from 'LayoutBuilder';

const MUTATION_EDIT = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();
const {Container, Row, Column} = LayoutBuilder;


const Index = (props) => {
  var {CONTENT, curObj} = props;
  return (
    <div id="FormChiefComplaint">
      <FormBuilder
        disableKeySubmit={true}
        initialValues={curObj}
        listOfFields={FORM_FIELDS({CONTENT: CONTENT, obj: curObj})}
        onSubmit={()=>{}}
        onChange={newObj => props.onChange(newObj.offerRequest.chiefComplaint)}
        onValidate={(isValid)=>{ 
          //setCurStatus(isValid);
          //onCheckValid(isValid);
        }}
      />
    </div>
  );
}



const FORM_FIELDS = ({CONTENT, obj})=>{
  return(
    [ //defaultValuePath used to set default value form CONTENTect
      {
        title: <div className="formTitle"><span className="">{CONTENT.title}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT.desc}</span></div>,
        type: "textArea",
        name: "offerRequest,chiefComplaint",
        rows: 6,
        required: false,
        defaultValue: "",
        defaultValuePath: "offerRequest,chiefComplaint",
        errorMessage: "Chief Complaint is required"
      }
    ]
  )
};

export default Index;
      




