//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Terms of Use Page. Checkout your Medical Disclaimer and more.",
			"DESCRIPTION": "A page covering all things legal concerning applicable law, disputes, transactions and more.",
			"KEYWORDS": "Terms of Use, Clear Aligners, Braces alternative, Legal Terms,	Legal Info,	Smiles Club, Privacy policy, Medical Disclaimer, Age Restrictions, Intellectual Property, Applicable law, Disputes"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `<h1 class="sectionTitle">Terms of Use</h1>`
				}
			],
			"Section2": [
				{
					"type": "div",
					"content": `<div class="textTemplate">
				  <div class="basicText">Last Revision – May 01, 2021</div>
			
				  <div class="basicText">Welcome to the website belonging to Smiles Club, Inc. (“Company”).  Please read and review the Terms of Use found herein ("terms of use", "agreement") carefully concerning your use of the website before moving forward.  These terms will govern your use of Smiles.club [“Smiles Club”] website (“website”, “site”, "service"), any affiliated mobile applications (the “Applications”), any affiliated shopping and/or other service/s offered through this website and the Applications (collectively, the “Services”). </div>
				  <div class="basicText">The use of this website, Applications, and/or registration for any Services will constitute your agreement to comply with and be bound by the terms of this Agreement. By using this website in any way, including accessing, using or downloading any information and/or materials from the website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you cannot agree to and comply with this Agreement and its requirements if you do not want to be bound by the terms of this Agreement, or if you disagree with one or more of the terms found herein, or find them unacceptable in any way, including without limitation the indemnity and arbitration provisions, you are advised to leave the website accordingly. Smiles Club only grants use and access of this website, its products, and its services to those who have accepted its terms.</div>
				  <div class="basicText">The terms "you," "your," and "yours" refer to you, the visitor to or user of the website. The terms "Align," "we," "us," and “our” refer to Smiles Club. </div>
			
			
			
				  <h2 class="sectionTitle2">Changes to Terms of Use and Information Provided “As Is”</h2>
				  <div class="basicText">Smiles Club reserves the right, at any time without prior notice, to edit, change, add to, modify, alter or update the terms of this Agreement. The date of the most recent revision will appear on this page. We shall let our users know of these changes through electronic mail when our users submit their email information to <a href="mailto:support@Smiles.club">support@Smiles.club</a>, otherwise it is their responsibility to review these changes by revising this page. The updated Agreement will supersede and replace all prior agreements, and continued access and use of the Services after publication of any such changes shall constitute your acceptance of the modified Terms. </div>
				  <div class="basicText">The Website and all Information associated herein, is provided “as is” with any and all faults. Your continued use of the Website and affiliated Services, as authorized herein, is at your own risk. The information contained on the Website or any affiliated Services may contain errors, omissions, or typographical errors, or may be out of date.</div> 
				  
			
			
				  <h2 class="sectionTitle2">Suspension or Termination of Your Use</h2>
				  <div class="basicText">Please be aware that your failure to adhere to the requirements set forth in this Agreement may result in the suspension or termination of your access to the Services, without notice, in addition to Smiles Club' other remedies. Smiles Club further reserves the right to terminate, without notice, any user's access to or use of the Site and Applications for any reason.</div>      
				  
			
			
				  <h2 class="sectionTitle2">Privacy policy</h2>
				  <div class="basicText">Before moving forward with the use of this website or any affiliated Applications, we advise you to read our privacy policy regarding our user data collection, including the collection and use of your information. Upon acceptance of these Terms you agree that any information that you submit through the Sites or Applications that is communicated to our affiliated medical professionals, or is otherwise intended or reasonably expected to be used for clinical assessment, will be accurate and complete.</div>
				  <div class="basicText">Your use of the Site and Applications constitutes your agreement to the terms and conditions of the Privacy Policy. Upon acceptance of these Terms you confirm that you have read, understood, and accepted Sonrisa’s Online Privacy Policy.</div>
			
			
			
				  <h2 class="sectionTitle2">Medical Disclaimer </h2>
				  <div class="basicText">All information contained on this website or on any affiliated product or Application is not exhaustive and is provided for informational purposes only as educational content. This includes any data, links, and/or other materials, together with information relating to orthodontic, dental, medical and health conditions, and products and treatments. </div>
				  <div class="basicText">The educational content is therefore not intended to be used for diagnosis or treatment of any medical condition and is not intended to serve as dental or other professional health advice. <span class="weight500">YOU SHOULD NEVER DISREGARD PROFESSIONAL ADVICE OR DELAY IN SEEKING TREATMENT BASED ON THE EDUCATIONAL CONTENT FOUND HEREIN OR OTHER WEBSITES LINKED TO OR FROM THIS WEBSITE.</span> The educational content found herein should not replace a patient visit, call, consultation or advice of an orthodontist, dentist or medical professional, and does not constitute the provision or practice of dentistry or professional health care advice or service. As such, the use of the Services does not create a doctor-patient relationship with Smiles Club.</div>
				  <div class="basicText"><span class="weight500">THIS WEBSITE IS NOT AN EMERGENCY-RESPONSE OR EMERGENCY-MONITORING SERVICE AND ANY PERSON WHO IS AWARE OF AN EMERGENCY SITUATION OR BELIEVES THAT A PERSON MAY BE AT RISK OF INJURY OR DEATH OR WHO MAY HARM THEMSELVES OR ANOTHER PERSON SHOULD DIAL “911” OR AN APPROPRIATE EMERGENCY RESPONDER. COMPANY IS UNDER NO OBLIGATION TO MONITOR OR RESPOND TO COMS MADE TO THIS WEBSITE.</span></div>
				   
			
			
				  <h2 class="sectionTitle2">Age Restrictions for the Use of this Site, Applications, and Services</h2>
				  <div class="basicText">You must be at least 18 (eighteen) years of age to independently visit and use this website or register for any Services offered through the Website and/or any affiliated Applications; however, users under the age of 18 may access the Site, Applications and the Services, only as detailed below. Otherwise, by using this Website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. Smiles Club hereby disclaims all liability and assumes no responsibility for liabilities related to age misrepresentation.</div>
				  <div class="basicText">If you are a minor between the ages of 13 and 17, you may only use the Site and/or Applications in conjunction with the permission or consent of your parent(s) or guardian(s). By moving forward with the use of the website, you represent to us that, you have consent from a parent or legal guardian to do so, and that your parent or legal guardian has read and agreed to these Terms of Use. CHILDREN UNDER 13 ARE NOT PERMITTED TO USE THIS WEBSITE. Smiles Club does not knowingly collect personally identifiable information from users under the age of 13.  </div>
			
			
			
				  <h2 class="sectionTitle2">Intellectual Property of Website and its Affiliates</h2>
				  <div class="basicText">You agree that all design and content features on the Site and Applications, including information and other materials, illustrations, product layout and design, icons, navigational buttons, images, artwork, graphics, photography, text, data, audio sound, software and the like, as well as the selection, assembly and arrangement thereof (the "Site and App Information"), and services provided on this Website are the property of Smiles Club, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, service mark, patents, and other intellectual property. You also agree that you will not reproduce or redistribute Smiles Club’ intellectual property in any way, including electronic, digital, or new trademark registrations.</div>
				  <div class="basicText">You grant Smiles Club a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</div>
			
			
			
				  <h2 class="sectionTitle2">User Accounts and Registration</h2>
				  <div class="basicText">Although registration is not required to view certain content on the Website, as a user of this Website and any affiliated Services or Apps, you may be asked to register with us and provide private information, including your e-mail address and password. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password. As such you should keep your Credentials private and not share your Credentials with anyone else.</div>
				  <div class="basicText">If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly. We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.</div>
				  
			
			
				  <h2 class="sectionTitle2">Restricted Use to the United States and Governing Law</h2>
				  <div class="basicText">You may use this website only to the extent that you obey all laws, rules, and regulations applicable to your use of this website. </div>
				  <div class="basicText">The Site and any associated Applications are hosted in the United States and are only intended for users located in the United States. If you are a Site user or purchaser of Service that is not based in the United States, by visiting the Site, purchasing Service, or providing User Content, you agree to comply with all applicable laws, rules, and regulations governing the Site, the Services, online conduct and acceptable content. You further agree to comply with all applicable laws regarding the transmission of data exported from the United States or from your country of residence. </div>
				  <div class="basicText">Please note that our Services are intended for personal use only and may not be used in connection with commercial endeavors, unless otherwise permitted by written agreement.</div>
			
			
			
				  <h2 class="sectionTitle2">Applicable law</h2>
				  <div class="basicText">The information provided on the Smiles Club website is not targeted to users in any particular locality nor is it intended to constitute the doing of business in any jurisdiction.</div>
				  <div class="basicText">This Website is a service provided by Smiles Club and does not constitute any contact with any jurisdiction outside the State of Utah, U.S.A. Those who choose to access the Website and all any affiliated Services and products offered through the Website and Applications do so of their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. Use of this website is prohibited in any jurisdiction having laws that would void this Agreement in whole or essential part or which makes accessing the website illegal. Users in such jurisdictions visit and use this website entirely at their own risk. Note: the essential parts of this Agreement include, without limitation, the exclusive remedy provisions and the warranty disclaimers.</div>
				  <div class="basicText">This Agreement is entered into and performed in the State of Utah, United States of America. It is governed by and shall be construed under the laws of Utah, exclusive of any choice of law or conflict of law provisions.</div>
				  
			
			
				  <h2 class="sectionTitle2">Disputes</h2>
				  <div class="basicText">You and Smiles Club agree to arbitrate any and all Disputes arising out of or related to this agreement or any aspect of the relationship between you and Smiles Club. Arbitration is more informal than a lawsuit in court and seeks to resolve disputes more quickly. Instead of a judge or a jury, the case will be decided by a neutral arbitrator who has the power to award the same damages and relief that a court can. </div>
				  <div class="basicText"><span class="weight500">THIS ARBITRATION CLAUSE INCLUDES ALL DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU, ON THE ONE HAND, AND SMILES CLUB OR ITS CONTRACTORS, PERSONNEL, DIRECTORS, AFFILIATES OR SUPPLIERS, ON THE OTHER HAND, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION OR ANY OTHER LEGAL THEORY, THAT IT WILL BE RESOLVED THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY AND YOU AGREE THAT YOU ARE WAIVING THE RIGHT TO TRIAL BY A JURY. YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL BE ONLY ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH OTHER ARBITRATIONS ARE NOT PERMITTED, AND YOU ARE WAIVING YOUR RIGHTS TO HAVE YOUR CASE DECIDED BY A JURY AND TO PARTICIPATE IN A CLASS ACTION AGAINST SMILES CLUB.</span></div>
				  <div class="basicText">If any provision of this arbitration agreement is found unenforceable, the unenforceable provision shall be severed, and the remaining arbitration terms shall be enforced (but in no case shall there be a class arbitration). All Disputes shall be resolved finally and exclusively by binding individual arbitration with a single arbitrator administered by Utah ADR Services, according to this provision and the applicable arbitration rules for said forum. Consumer claimants (individuals whose transaction is intended for personal, family, or household use) may elect to pursue their claims in their local small-claims court rather than through arbitration. The Federal Arbitration Act, 9 U.S.C. §§ 1-16, fully applies. If you are a consumer bringing a claim relating to personal, household, or family use, any arbitration hearing will occur within the county or parish where you reside or at another mutually agreed upon location. </div>
				  <div class="basicText">The arbitrator will conduct hearings, if any, by teleconference, videoconference, or based solely on written submissions, rather than by personal appearances, unless the arbitrator determines upon request by you or by us that an in-person hearing is necessary. Any in-person appearances will be held at a location which is reasonably convenient to both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, such determination should be made by Utah ADR Services, or a location ordered by the arbitrator. </div>
				  <div class="basicText">The arbitrator’s award shall be binding on the parties and may be entered as a judgment in any court of competent jurisdiction. If you prevail on any claim for which you are legally entitled to attorney’s fees, you may recover those fees from the arbitrator. For any claim where you are seeking relief, Smiles Club will not seek to have you pay its attorney’s fees, even if fees might otherwise be awarded, unless the arbitrator determines that your claim was frivolous. The entire arbitration, including the arbitrator’s decision, shall be confidential unless otherwise agreed upon by the parties. For purposes of this arbitration provision, references to you and Smiles Club also include respective subsidiaries, affiliates, agents, employees, predecessors, successors, and assigns.</div>
			
			
			
				  <h2 class="sectionTitle2">Indemnification</h2>
				  <div class="basicText"><span class="weight500">BY VISITING THIS WEBSITE, YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS SMILES CLUB AND THEIR AFFILIATES, FROM AND AGAINST ALL CLAIMS, ACTIONS, DEMANDS, LIABILITIES, JUDGMENTS, SETTLEMENTS, COSTS, LOSSES, DAMAGES, TAX ASSESSMENTS, PENALTIES, INTEREST AND EXPENSES (INCLUDING, WITHOUT LIMITATION, REASONABLE ATTORNEYS’ FEES) ARISING OUT OF ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR ANY OTHER CLAIM YOU MAY INCUR IN CONNECTION WITH YOUR USE OF THIS WEBSITE OR WEBSITE CONTENT, YOUR VIOLATION  OR INFRINGEMENT OF THESE TERMS OF USE, OR INFRINGEMENT BY ANY OTHER USE OF YOUR ACCOUNT, OR YOUR VIOLATION OF ANY RIGHTS OF ANOTHER PERSON OR ENTITY. THIS INCLUDES, WITHOUT LIMITATION, ANY CLAIMS FOR DEFAMATION, VIOLATION OF PUBLICITY OR PRIVACY, COPYRIGHT OR TRADEMARK INFRINGEMENT RESULTING FROM YOUR SUBMISSIONS OF ANY CONTENT, TAGGED CONTENT OR OTHER MATERIALS, ANY ECONOMIC HARM, LOST PROFITS, DAMAGES TO BUSINESS, DATA OR COMPUTER SYSTEMS, OR ANY DAMAGES RESULTING FROM RELIANCE ON ANY CONTENT OR RESULTING FROM ANY INTERRUPTIONS, WORK STOPPAGES, COMPUTER FAILURES, DELETION OF FILES, ERRORS, OMISSIONS, INACCURACIES, DEFECTS, VIRUSES, DELAYS OR MISTAKES OF ANY KIND, EVEN IF YOU HAVE PREVIOUSLY ADVISED COMPANY OF THE POSSIBILITY OF SUCH CLAIM.</span></div>
			
			
			
				  <h2 class="sectionTitle2">Limitation on liability</h2>
				  <div class="basicText">We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</div>
				  <div class="basicText">You expressly agree that your use of, or inability to use, the service is at your sole risk. <span class="weight500">THE USER’S SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE AND ANY OF ITS SERVICES IS TO STOP USING THE WEBSITE OR SERVICE.</span> The service (and all products and services delivered to you through the service) are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title and non-infringement.</div>
				  <div class="basicText">Because some states or jurisdictions do not allow the exclusion or the limitation of liability or implied warranties for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</div>
				  <div class="basicText">Nothing in this section “Disclaimer of Warranties; Limitation of Liability” is intended to apply to individuals who are licensed to practice dentistry or orthodontics, in the rendering of professional services by such individuals, with respect to the provision of services for which licensure is required, except to the extent otherwise permitted by applicable state law.</div>
			
			
			
				  <h2 class="sectionTitle2">Limitation on Dispute Initiation </h2>
				  <div class="basicText">Unless otherwise required by law, an action or proceeding by you relating to any Dispute must commence within one year after the cause of action accrues.</div>
				  
			
			
				  <h2 class="sectionTitle2">Transactions</h2>
				  <div class="basicText">If you wish to purchase products or services described or linked to on the website, you will be asked to provide specific information from Smiles Club or the third-party provider of the product or service. This information will be applicable to your Transaction, and will include, without limitation, credit card (or other applicable payment mechanism) and other payment as well as your shipping information. You understand that, if any such information is provided to Smiles Club, then Smiles Club shall treat any such information in the manner described in our Online Privacy Policy. By supplying such information, you agree to allow Smiles Club to share your information with applicable third parties for the sole purpose of facilitating the completion of Transaction(s) initiated by you or on your behalf. You agree to pay all charges incurred by you or any users on your membership account or credit card (or other applicable payment mechanism) at the price(s) in effect when such charges are incurred, including, without limitation, all shipping and handling charges, as well as any applicable taxes relating to your purchase(s). <span class="weight500">YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) OR OTHER PAYMENT MECHANISM USED IN CONNECTION WITH ANY TRANSACTION UNDERTAKEN ON OR THROUGH YOUR ACCOUNT.</span></div>
				  <div class="basicText">Smiles Club reserves the right, without prior notification, to change website descriptions or references, to limit the order quantity on any product or service and/or to refuse service to any customer. Verification of information applicable to a purchase may be required prior to Company’s acceptance of any order. Price and availability of any product or service are subject to change without notice. Smiles Club is not responsible for errors in the prices or descriptions of any product or service. Refunds and exchanges shall be subject to Company’s and/or any applicable third party’s refund and exchange policies in effect at the time of the applicable Transaction. Current rates for any product or service available through the website may be obtained by communicating with us via e-mail. </div>
				  <div class="basicText">By purchasing a product through Smiles Club you consent to electronic communications in the form of e-mail at the e-mail address that you provide at the time of purchase. You further consent that electronic communications, including any authorizations that you provide, shall satisfy any legal requirements for a writing.</div>
			
			
			
				  <h2 class="sectionTitle2">ELECTRONIC COMS</h2>
				  <div class="basicText">For certain kinds of messages, we may ask you to opt-in by providing your phone number. In those instances we will send you a confirming message that you have subscribed. This will generally be for things like appointment reminders or reminders about changing your aligners. You will have the ability to opt-out or cancel those reminders at any time by texting “STOP”. Your opt-out will only be effective as to the cell phone number from which you send the STOP request or that you specify. However, if you stop those messages, we won’t be able to easily send you those reminders that would be useful for your journey.</div>
				  <div class="basicText">Message and data rates may apply to each text message sent or received in connection with Align Technology text messages, as provided in your mobile telephone service rate plan (please contact your mobile telephone carrier for pricing plans), in addition to any applicable roaming charges. Smiles Club does not impose a separate fee for sending text messages.</div>
			
			
				  <h2 class="sectionTitle2">Conflicting Terms</h2>
				  <div class="basicText">Anything on the website inconsistent or in conflict with the terms of this Agreement is superseded by the terms of this Agreement.</div>
				</div>`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Página de términos de uso. Consulte su descargo de responsabilidad médica y más.",
			"DESCRIPTION": "Una página que cubre todo lo legal relacionado con la ley aplicable, disputas, transacciones y más.",
			"KEYWORDS": "Términos de uso, Alineadores transparentes, Alternativa de brackets, Términos legales, Información legal, Smiles Club, Política de privacidad, Descargo de responsabilidad médica, Restricciones de edad, Propiedad intelectual, Ley aplicable, Disputas"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `<h1 class="sectionTitle">Condiciones de uso</h1>`
				}
			],
			"Section2": [
				{
					"type": "div",
					"content": `<div class="textTemplate">
				  <div class="basicText">Última revisión: 01 de mayo de 2021</div>
			
				  <div class="basicText">Bienvenido al sitio web de Smiles Club, Inc. ("Compañía"). Lea y revise los Términos de Uso que se encuentran en este documento ("términos de uso", "acuerdo") con atención en relación con su uso del sitio web antes de continuar. Estos términos regirán su uso del sitio web Smiles.club ["Smiles Club"] ("sitio web", "sitio", "servicio"), cualquier aplicación móvil afiliada (las "Aplicaciones"), cualquier compra afiliada y / u otro servicio / s ofrecidos a través de este sitio web y las Aplicaciones (colectivamente, los "Servicios").</div>
				  <div class="basicText">El uso de este sitio web, las Aplicaciones y / o el registro de cualquier Servicio constituirá su acuerdo para cumplir y estar sujeto a los términos de este Acuerdo. Al usar este sitio web de cualquier manera, incluido el acceso, el uso o la descarga de cualquier información y / o materiales del sitio web, usted certifica que ha leído y revisado este Acuerdo y que acepta cumplir con sus términos. Si no puede aceptar y cumplir con este Acuerdo y sus requisitos, si no desea estar sujeto a los términos de este Acuerdo, o si no está de acuerdo con uno o más de los términos que se encuentran en este documento, o los encuentra inaceptables de alguna manera, incluidas, entre otras, las disposiciones de indemnización y arbitraje, se le aconseja que abandone el sitio web en consecuencia. Smiles Club solo otorga el uso y acceso de este sitio web, sus productos y sus servicios a aquellos que han aceptado sus términos.</div>
				  <div class="basicText">Los términos "usted", "su" y "suyo" se refieren a usted, el visitante o usuario del sitio web. Los términos "Alinear", "nosotros", "nos" y "nuestro" se refieren a Smiles Club.</div>
			
			
			
				  <h2 class="sectionTitle2">Cambios en los Términos de uso y la información proporcionada "tal cual"</h2>
				  <div class="basicText">Smiles Club se reserva el derecho, en cualquier momento y sin previo aviso, de editar, cambiar, agregar, modificar, alterar o actualizar los términos de este Acuerdo. La fecha de la revisión más reciente aparecerá en esta página. Informaremos a nuestros usuarios de estos cambios a través del correo electrónico cuando nuestros usuarios envíen su información de correo electrónico a <a href="mailto:support@Smiles.club">support@Smiles.club</a> ; de lo contrario, es su responsabilidad revisar estos cambios revisando esta página. El Acuerdo actualizado reemplazará el presente y reemplazará todos los acuerdos anteriores, y el acceso y uso continuo de los Servicios después de la publicación de dichos cambios constituirá su aceptación de los Términos modificados. </div>
				  <div class="basicText">El sitio web y toda la información asociada en este documento se proporciona "tal cual" con todas y cada una de las fallas. Su uso continuado del sitio web y los servicios afiliados, según lo autorizado en el presente, es bajo su propio riesgo. La información contenida en el sitio web o cualquier servicio afiliado puede contener errores, omisiones o errores tipográficos, o puede estar desactualizada. </div> 
				  
			
			
				  <h2 class="sectionTitle2">Suspensión o terminación de su uso</h2>
				  <div class="basicText">Tenga en cuenta que su incumplimiento de los requisitos establecidos en este Acuerdo puede resultar en la suspensión o terminación de su acceso a los Servicios, sin previo aviso, además de otros recursos de Smiles Club. Smiles Club se reserva además el derecho de rescindir, sin previo aviso, el acceso de cualquier usuario o el uso del Sitio y las Aplicaciones por cualquier motivo. </div>      
				  
			
			
				  <h2 class="sectionTitle2">Política de privacidad</h2>
				  <div class="basicText">Antes de seguir adelante con el uso de este sitio web o cualquier Aplicación afiliada, le recomendamos que lea nuestra política de privacidad con respecto a nuestra recopilación de datos de usuario, incluida la recopilación y el uso de su información. Tras la aceptación de estos Términos, usted acepta que cualquier información que envíe a través de los Sitios o Aplicaciones que se comunique a nuestros profesionales médicos afiliados, o que de otro modo se pretenda o se espere razonablemente que se use para la evaluación clínica, será precisa y completa.</div>
				  <div class="basicText">Su uso del Sitio y las Aplicaciones constituye su aceptación de los términos y condiciones de la Política de privacidad. Al aceptar estos Términos, usted confirma que ha leído, comprendido y aceptado la Política de privacidad en línea de Sonrisa.</div>
			
			
			
				  <h2 class="sectionTitle2">Renuncia de responsabilidad médica</h2>
				  <div class="basicText">Toda la información contenida en este sitio web o en cualquier producto o aplicación afiliada no es exhaustiva y se proporciona solo con fines informativos como contenido educativo. Esto incluye cualquier dato, enlace y / u otro material, junto con información relacionada con las condiciones de ortodoncia, dentales, médicas y de salud, y productos y tratamientos.</div>
				  <div class="basicText">Por lo tanto, el contenido educativo no está destinado a ser utilizado para el diagnóstico o tratamiento de ninguna condición médica y no está destinado a servir como asesoramiento dental u otro consejo profesional de salud. <span class="weight500">USTED NUNCA DEBE IGNORAR LA BUSQUEDA DE CONSEJOS PROFESIONALES O DEMORARSE EN BUSCAR TRATAMIENTO DE SALUD, BASADO EN EL CONTENIDO EDUCATIVO QUE SE ENCUENTRA EN ESTE DOCUMENTO O EN OTROS SITIOS WEB VINCULADOS A ESTE SITIO WEB.</span> El contenido educativo encontrado en este documento no debe reemplazar una visita, llamada, consulta o consejo de un ortodoncista, dentista o profesional dental o de la medicina, y no constituye el suministro o la práctica de la odontología o consejo profesional de la salud o servicio. Como tal, el uso de los Servicios no crea una relación médico-paciente o dentista-paciente con Smiles Club.   </div>
				  <div class="basicText"><span class="weight500">ESTE SITIO WEB NO ES UN SERVICIO DE RESPUESTA A EMERGENCIAS O DE MONITOREO DE EMERGENCIAS Y CUALQUIER PERSONA QUE ESTÉ CONSCIENTE DE UNA SITUACIÓN DE EMERGENCIA O CREE QUE UNA PERSONA PUEDE ESTAR EN RIESGO DE LESIÓN O MUERTE O QUE PUEDA DAÑARSE A SÍ MISMA O A OTRA PERSONA DEBE MARCAR "911" U OTRA ENTIDAD  RESPONSABLE DE EMERGENCIA APROPIADA. LA COMPAÑÍA NO TIENE LA OBLIGACIÓN DE MONITOREAR O RESPONDER A LAS COMUNICACIONES REALIZADAS A ESTE SITIO WEB.</span></div>
				   
			
			
				  <h2 class="sectionTitle2">Restricciones de edad para el uso de este sitio, aplicaciones y servicios</h2>
				  <div class="basicText">Debe tener al menos 18 (dieciocho) años de edad para visitar y utilizar este sitio web de forma independiente o registrarse para cualquier Servicio ofrecido a través del Sitio web y / o cualquier Aplicación afiliada; sin embargo, los usuarios menores de 18 años pueden acceder al Sitio, las Aplicaciones y los Servicios, solo como se detalla a continuación. De lo contrario, al utilizar este sitio web, garantiza que tiene al menos 18 años de edad y que puede adherirse legalmente a este Acuerdo. Smiles Club por la presente renuncia a toda responsabilidad y no asume ninguna responsabilidad por las responsabilidades relacionadas con la tergiversación de la edad.</div>
				  <div class="basicText">Si es menor de edad entre 13 y 17 años, solo puede usar el Sitio y / o las Aplicaciones junto con el permiso o consentimiento de sus padres o tutores. Al seguir adelante con el uso del sitio web, nos declara que tiene el consentimiento de un padre o tutor legal para hacerlo, y que su padre o tutor legal ha leído y aceptado estos Términos de Uso. A LOS NIÑOS MENORES DE 13 AÑOS NO SE LES PERMITE UTILIZAR ESTE SITIO WEB. Smiles Club no recopila a sabiendas información de identificación personal de usuarios menores de 13 años.</div>
			
			
			
				  <h2 class="sectionTitle2">Propiedad intelectual del sitio web y sus afiliados</h2>
				  <div class="basicText">Usted acepta que todas las funciones de diseño y contenido del Sitio y las Aplicaciones, incluida la información y otros materiales, ilustraciones, diseño y diseño de productos, iconos, botones de navegación, imágenes, ilustraciones, gráficos, fotografías, texto, datos, sonido de audio, software y como, así como la selección, el montaje y la disposición de los mismos (el "Sitio y la información de la aplicación"), y los servicios proporcionados en este sitio web son propiedad de Smiles Club, sus afiliados, directores, funcionarios, empleados, agentes, proveedores o licenciatarios incluidos todos los derechos de autor, secretos comerciales, marcas comerciales, marcas de servicio, patentes y otra propiedad intelectual. También acepta que no reproducirá ni redistribuirá la propiedad intelectual de Smiles Club de ninguna manera, incluidos los registros electrónicos, digitales o de nuevas marcas comerciales.</div>
				  <div class="basicText">Usted otorga a Smiles Club una licencia no exclusiva y libre de regalías para mostrar, usar, copiar, transmitir y difundir el contenido que cargue y publique. Para cuestiones relacionadas con reclamaciones de propiedad intelectual, debe comunicarse con la empresa para llegar a un acuerdo.</div>
			
			
			
				  <h2 class="sectionTitle2">Cuentas de usuario y registro</h2>
				  <div class="basicText">Aunque no es necesario registrarse para ver cierto contenido en el sitio web, como usuario de este sitio web y de cualquier servicio o aplicación afiliada, es posible que se le solicite que se registre con nosotros y proporcione información privada, incluida su dirección de correo electrónico. Usted es responsable de garantizar la exactitud de esta información y es responsable de mantener la seguridad de su información de identificación. También es responsable de todas las actividades que se realicen con su cuenta o contraseña. Como tal, debe mantener sus Credenciales en privado y no compartir sus Credenciales con nadie más.</div>
				  <div class="basicText">Si cree que existen posibles problemas relacionados con la seguridad de su cuenta en el sitio web, infórmenos de inmediato para que podamos abordarlos en consecuencia. Nos reservamos todos los derechos de cancelar cuentas, editar o eliminar contenido y cancelar pedidos usando nuestro exclusivo criterio.</div>
				  
			
			
				  <h2 class="sectionTitle2">Uso restringido a los Estados Unidos y la ley aplicable</h2>
				  <div class="basicText">Puede usar este sitio web solo en la medida en que obedezca todas las leyes, reglas y regulaciones aplicables a su uso de este sitio web.</div>
				  <div class="basicText">El Sitio y cualquier Aplicación asociada están alojados en los Estados Unidos y solo están destinados a usuarios ubicados en los Estados Unidos. Si usted es un usuario del Sitio o un comprador de Servicio que no tiene su sede en los Estados Unidos, al visitar el Sitio, comprar el Servicio o proporcionar Contenido de usuario, acepta cumplir con todas las leyes, reglas y regulaciones aplicables que rigen el Sitio, el Servicios, conducta en línea y contenido aceptable. Además, acepta cumplir con todas las leyes aplicables con respecto a la transmisión de datos exportados desde los Estados Unidos o desde su país de residencia.</div>
				  <div class="basicText">Tenga en cuenta que nuestros Servicios están destinados únicamente para uso personal y no pueden utilizarse en relación con actividades comerciales, a menos que se permita lo contrario mediante un acuerdo escrito.</div>
			
			
			
				  <h2 class="sectionTitle2">Ley aplicable</h2>
				  <div class="basicText">La información proporcionada en el sitio web de Smiles Club no está dirigida a usuarios en ninguna localidad en particular ni pretende constituir la realización de negocios en ninguna jurisdicción.</div>
				  <div class="basicText">Este sitio web es un servicio proporcionado por Smiles Club y no constituye ningún contacto con ninguna jurisdicción fuera del estado de Utah, EE. UU. Aquellos que eligen acceder al sitio web y a todos los servicios y productos afiliados ofrecidos a través del sitio web y las aplicaciones lo hacen por su cuenta. iniciativa y son responsables del cumplimiento de las leyes locales, si y en la medida en que las leyes locales sean aplicables. El uso de este sitio web está prohibido en cualquier jurisdicción que tenga leyes que invaliden este Acuerdo en su totalidad o en parte esencial o que hagan que el acceso al sitio web sea ilegal. Los usuarios de dichas jurisdicciones visitan y utilizan este sitio web por su cuenta y riesgo. Nota: las partes esenciales de este Acuerdo incluyen, sin limitación, las disposiciones de recurso exclusivo y las renuncias de garantía.</div>
				  <div class="basicText">Este Acuerdo se celebra y ejecuta en el estado de Utah, Estados Unidos de América. Se rige y se interpretará de acuerdo con las leyes de Utah.</div>
				  
			
			
				  <h2 class="sectionTitle2">Disputas</h2>
				  <div class="basicText">Usted y Smiles Club acuerdan arbitrar todas y cada una de las disputas que surjan de, o estén relacionadas con este acuerdo o cualquier aspecto de la relación entre usted y Smiles Club. El arbitraje es más informal que una demanda judicial y busca resolver disputas más rápidamente. En lugar de un juez o un jurado, el caso será decidido por un árbitro neutral que tiene el poder de otorgar los mismos daños y reparaciones que un tribunal.</div>
				  <div class="basicText"><span class="weight500">ESTA CLÁUSULA DE ARBITRAJE INCLUYE TODAS LAS DISPUTAS QUE SURJAN O ESTÁN RELACIONADAS CON ESTE ACUERDO O CUALQUIER ASPECTO DE LA RELACIÓN ENTRE USTED, POR UN LADO, Y SMILES CLUB O SUS CONTRATISTAS, PERSONAL, DIRECTORES, AFILIADOS O PROVEEDORES, POR OTRO LADO, SIEMPRE EN CONTRATO, AGRAVIO, ESTATUTO, FRAUDE, DECLARACIÓN INCORRECTA O CUALQUIER OTRA TEORÍA LEGAL, QUE SE RESOLVERÁ MEDIANTE ARBITRAJE FINAL Y VINCULANTE ANTE UN ÁRBITRO NEUTRAL EN LUGAR DE UN TRIBUNAL POR UN JUEZ O JURADO Y USTED ACEPTA QUE USTED ESTÁ DE ACUERDO CON EL DERECHO DE RENUNCIAR  A JUICIO POR UN JURADO. USTED ACEPTA QUE CUALQUIER ARBITRAJE BAJO ESTE ACUERDO SERÁ ÚNICAMENTE INDIVIDUAL; LOS ARBITRAJES COLECTIVOS, LAS ACCIONES COLECTIVAS, LAS ACCIONES PRIVADAS DEL ABOGADO GENERAL, Y LA CONSOLIDACIÓN CON OTROS ARBITRAJES NO ESTÁN PERMITIDAS, Y USTED RENUNCIA A SUS DERECHOS A QUE UN JURADO DECIDA SU CASO Y A PARTICIPAR EN UNA DEMANDA COLECTIVA CONTRA SMILES CLUB.</span></div>
				  <div class="basicText">Si alguna disposición de este acuerdo de arbitraje se considera inaplicable, la disposición inaplicable se cortará y los términos de arbitraje restantes se harán cumplir (pero en ningún caso habrá un arbitraje colectivo). Todas las disputas se resolverán final y exclusivamente mediante arbitraje individual vinculante con un solo árbitro administrado por Servicios de Utah ADR [a], de acuerdo con esta disposición y las reglas de arbitraje aplicables para dicho foro. Los consumidores demandantes (individuos cuya transacción está destinada a uso personal, familiar o doméstico) pueden optar por presentar sus reclamos en su tribunal local de reclamos menores en lugar de hacerlo mediante arbitraje. La Ley Federal de Arbitraje, 9 U.S.C. §§ 1-16, se aplica en su totalidad. Si usted es un consumidor que presenta un reclamo relacionado con el uso personal, doméstico o familiar, cualquier audiencia de arbitraje se llevará a cabo dentro del condado donde reside o en otro lugar acordado mutuamente.</div>
				  <div class="basicText">El árbitro llevará a cabo audiencias, si las hubiera, por teleconferencia, videoconferencia o basándose únicamente en presentaciones escritas, en lugar de comparecencias personales, a menos que el árbitro determine, a petición suya o nuestra, que es necesaria una audiencia en persona. Cualquier comparecencia en persona se llevará a cabo en un lugar que sea razonablemente conveniente para ambas partes con la debida consideración de su capacidad para viajar y otras circunstancias pertinentes. Si las partes no pueden ponerse de acuerdo sobre una ubicación, dicha determinación debe ser realizada por los Servicios de Utah ADR, o una ubicación ordenada por el árbitro.</div>
				  <div class="basicText">El laudo del árbitro será vinculante para las partes y podrá ser presentado como sentencia en cualquier tribunal de jurisdicción competente. Si prevalece sobre cualquier reclamo por el cual tiene derecho legal a los honorarios de abogado, puede recuperar esos honorarios del árbitro. Para cualquier reclamo en el que esté buscando reparación, Smiles Club no buscará que usted pague los honorarios de sus abogados, incluso si los honorarios pudieran otorgarse, a menos que el árbitro determine que su reclamo fue frívolo. Todo el arbitraje, incluida la decisión del árbitro, será confidencial a menos que las partes acuerden lo contrario. Para los propósitos de esta disposición de arbitraje, las referencias a usted y a Smiles Club también incluyen las respectivas subsidiarias, afiliadas, agentes, empleados, predecesores, sucesores y cesionarios.</div>
			
			
			
				  <h2 class="sectionTitle2">Indemnización</h2>
				  <div class="basicText"><span class="weight500">AL VISITAR ESTE SITIO WEB, USTED ACEPTA INDEMNIZAR, DEFENDER Y MANTENER A SMILES CLUB Y SUS AFILIADOS, DE Y CONTRA TODAS LAS RECLAMACIONES, ACCIONES, DEMANDAS, RESPONSABILIDADES, JUICIOS, ACUERDOS, COSTOS, PÉRDIDAS, DAÑOS, PAGOS, IMPUESTOS GASTOS (INCLUYENDO, SIN LIMITACIÓN, HONORARIOS DE ABOGADOS RAZONABLES) QUE SURJAN DE CUALQUIER RECLAMO DIRECTO, INDIRECTO, INCIDENTAL, CONSECUENTE, ESPECIAL, EJEMPLAR, PUNITIVO O CUALQUIER OTRO RECLAMO EN RELACIÓN CON EL USO DE ESTE SITIO WEB O CONTENIDO DEL SITIO WEB, O INFRACCIÓN DE ESTOS TÉRMINOS DE USO, O INFRACCIÓN POR CUALQUIER OTRO USO DE SU CUENTA, O SU VIOLACIÓN DE CUALQUIER DERECHO DE OTRA PERSONA O ENTIDAD. ESTO INCLUYE, SIN LIMITACIÓN, CUALQUIER RECLAMO POR DIFAMACIÓN, VIOLACIÓN DE LA PUBLICIDAD O LA PRIVACIDAD, DERECHOS DE AUTOR O INFRACCIÓN DE MARCA REGISTRADA QUE RESULTEN DE SUS ENVÍOS DE CUALQUIER CONTENIDO, CONTENIDO ETIQUETADO U OTROS MATERIALES, CUALQUIER DAÑO ECONÓMICO, PÉRDIDA DE BENEFICIOS DEL NEGOCIO, DAÑOS , O CUALQUIER DAÑO RESULTADO DE LA CONFIANZA EN CUALQUIER CONTENIDO O DE CUALQUIER INTERRUPCIÓN, PARADAS DE TRABAJO, FALLAS DE LA COMPUTADORA, ELIMINACIÓN DE ARCHIVOS, ERRORES, OMISIONES, INEXACTITUDES, DEFECTOS, VIRUS, RETRASOS O ERRORES DE CUALQUIER TIPO DE COMPAÑÍA, INCLUSO. DE LA POSIBILIDAD DE DICHA RECLAMACIÓN.</span></div>
			
			
			
				  <h2 class="sectionTitle2">Limitación de responsabilidad</h2>
				  <div class="basicText">No representamos ni garantizamos que su uso de nuestro servicio sea ininterrumpido, oportuno, seguro o libre de errores.</div>
				  <div class="basicText">Usted acepta expresamente que el uso o la imposibilidad de usar el servicio es bajo su propio riesgo. <span class="weight500">LA ÚNICA SOLUCION DEL USUARIO EN CASO DE INSATISFACCIÓN CON EL SITIO WEB Y CUALQUIERA DE SUS SERVICIOS ES DEJAR DE UTILIZAR EL SITIO WEB O EL SERVICIO.</span> El servicio (y todos los productos y servicios que se le brindan a través del servicio) se brindan (salvo que nosotros expresamente lo indiquemos) 'tal como están' y 'según estén disponibles' para su uso, sin ninguna representación, garantía o condición de ningún tipo, ya sea expresa o implícita, incluidas todas las garantías o condiciones implícitas de comerciabilidad, calidad comercial, idoneidad para un propósito particular, durabilidad, título o no infracción.  </div>
				  <div class="basicText">Debido a que algunos estados o jurisdicciones no permiten la exclusión o limitación de responsabilidad o garantías implícitas por daños consecuentes o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitará al máximo permitido por la ley.</div>
				  <div class="basicText">Nada en esta sección “Renuncia de garantías; Limitación de responsabilidad” está destinado a aplicarse a las personas que tienen licencia para practicar odontología u ortodoncia, en la prestación de servicios profesionales por dichas personas, con respecto a la prestación de servicios para los que se requiere una licencia, excepto en la medida en que lo permita la ley del Estado en que se aplique.</div>
			
			
			
				  <h2 class="sectionTitle2">Limitación a la iniciación de una disputa</h2>
				  <div class="basicText">A menos que la ley exija lo contrario, una acción o procedimiento por su parte en relación con cualquier disputa debe comenzar dentro de un año después de que surja la causa de la acción. </div>
				  
			
			
				  <h2 class="sectionTitle2">Actas</h2>
				  <div class="basicText">Si desea comprar productos o servicios descritos o vinculados en el sitio web, se le pedirá que proporcione información específica de Smiles Club o del proveedor externo del producto o servicio. Esta información será aplicable a su Transacción e incluirá, sin limitación, tarjeta de crédito (u otro mecanismo de pago aplicable) y otros pagos, así como su información de envío. Usted comprende que, si se proporciona dicha información a Smiles Club, Smiles Club tratará dicha información de la manera descrita en nuestra Política de Privacidad en línea. Al proporcionar dicha información, usted acepta permitir que Smiles Club comparta su información con los terceros correspondientes con el único propósito de facilitar la finalización de las transacciones iniciadas por usted o en su nombre. Usted acepta pagar todos los cargos incurridos por usted o cualquier usuario en su cuenta de membresía o tarjeta de crédito (u otro mecanismo de pago aplicable) al precio (s) vigente cuando se incurra en dichos cargos, incluidos, entre otros, todos los cargos de envío y manejo. , así como cualquier impuesto aplicable relacionado con su (s) compra (s). <span class="weight500">USTED DECLARA Y GARANTIZA QUE TIENE EL DERECHO LEGAL DE USAR CUALQUIER TARJETA DE CRÉDITO U OTRO MECANISMO DE PAGO UTILIZADO EN RELACIÓN CON CUALQUIER TRANSACCIÓN REALIZADA EN SU CUENTA O A TRAVÉS DE SU CUENTA. </span></div>
				  <div class="basicText">Smiles Club se reserva el derecho, sin notificación previa, de cambiar las descripciones o referencias del sitio web, limitar la cantidad de pedidos de cualquier producto o servicio y / o rechazar el servicio a cualquier cliente. Es posible que se requiera la verificación de la información aplicable a una compra antes de que la Compañía acepte cualquier pedido. El precio y la disponibilidad de cualquier producto o servicio están sujetos a cambios sin previo aviso. Smiles Club no se hace responsable por errores en los precios o descripciones de cualquier producto o servicio. Los reembolsos e intercambios estarán sujetos a las políticas de reembolsos y cambios de la Compañía y / o de cualquier tercero aplicable que estén vigentes en el momento de la Transacción correspondiente. Las tarifas actuales para cualquier producto o servicio disponible a través del sitio web se pueden obtener comunicándose con nosotros por correo electrónico.</div>
				  <div class="basicText">Al comprar un producto a través de Smiles Club, usted acepta las comunicaciones electrónicas en forma de correo electrónico a la dirección de correo electrónico que proporcionó en el momento de la compra. Además, da su consentimiento para que las comunicaciones electrónicas, incluidas las autorizaciones que proporcione, satisfagan los requisitos legales de un escrito.</div>
			
			
			
				  <h2 class="sectionTitle2">COMUNICACIONES ELECTRONICAS</h2>
				  <div class="basicText">Para ciertos tipos de mensajes, es posible que le pidamos que se inscriba proporcionando su número de teléfono. En esos casos, le enviaremos un mensaje de confirmación de que se ha suscrito. Esto generalmente será para cosas como recordatorios de citas o recordatorios sobre el cambio de alineadores. Podrá optar por no participar o cancelar esos recordatorios en cualquier momento enviando un mensaje de texto con la palabra "STOP". Su exclusión voluntaria solo será efectiva para el número de teléfono celular desde el cual envía la solicitud de PARO o que usted especifique. Sin embargo, si detiene esos mensajes, no podremos enviarle fácilmente esos recordatorios que serían útiles para su tratamiento.</div>
				  <div class="basicText">Es posible que se apliquen tarifas de mensajes y datos a cada mensaje de texto enviado o recibido en relación con los mensajes de texto, según lo dispuesto en su plan de tarifas de servicio de telefonía móvil (comuníquese con su proveedor de telefonía móvil para conocer los planes de precios), además de cualquier cargo de “fuera de la red” aplicable. Smiles Club no impone una tarifa adicional por el envío de mensajes de texto.</div>
			
			
				  <h2 class="sectionTitle2">Términos en conflicto</h2>
				  <div class="basicText">Cualquier cosa en el sitio web que sea inconsistente o que esté en conflicto con los términos de este Acuerdo es reemplazada por los términos de este Acuerdo.</div>
				</div>`
				}
			]
		}
	}
};


	
export default PAGE;
