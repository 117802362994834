import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  APICALL
  NAME
  NOTES
  CATEGORY
  WHO
  status
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $APICALL: String,
  $NAME: String,
  $NOTES: String,
  $CATEGORY: String,
  $WHO: String,
  $status: String,
  $meta: MetaInput
`;


const DEFAULT_MUTATION_INPUT = ` 
  APICALL: $APICALL,
  NAME: $NAME,
  NOTES: $NOTES,
  CATEGORY: $CATEGORY,
  WHO: $WHO,
  status: $status,
  meta: $meta
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "APICALL": "string",  
  "NAME": "string",
  "NOTES": "string",
  "CATEGORY": "string",
  "WHO": "string",
  "status": "string",  
  "meta": CLEAN_FIELDS_DEFAULT.META
}




const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "VALUE",
    name: "API Call",
    defaultValue: "",
    path: "APICALL"
  },
  {
    type: "VALUE",
    name: "Name",
    defaultValue: "",
    path: "NAME"
  },
  {
    type: "VALUE",
    name: "Notes",
    defaultValue: "",
    path: "NOTES"
  },
  {
    type: "VALUE",
    name: "Category",
    defaultValue: "",
    path: "CATEGORY"
  },
  {
    type: "VALUE",
    name: "Who",
    defaultValue: "",
    path: "WHO"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "",
    path: "status"
  },
  {
    type: "DATE",
    name: "Create At",
    defaultValue: "",
    path: "meta.createAt"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "",
    path: "status"
  }
];



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"About",
        "SUB_TITLE":"Id's and who created it",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME":"Aligner ID"
          },
          {
            "PATH": "APICALL",
            "TYPE": "STRING",
            "NAME": "API Call",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "NAME",
            "TYPE": "STRING",
            "NAME": "Name",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "NOTES",
            "TYPE": "STRING",
            "NAME": "Notes",
            "DEFAULT": "NULL"
          },
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Schedule",
        "SUB_TITLE":"Time Information for Aligner",
        "FIELDS": [
          {
            "PATH": "CATEGORY",
            "TYPE": "STRING",
            "NAME": "Category",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "WHO",
            "TYPE": "STRING",
            "NAME": "Who",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME": "Status",
            "DEFAULT": "NULL"
          },
        ]
      },
    ]
  },

  // {
  //   "TYPE": "ROW",
  //   "FIELDS": [
  //     {
  //       "TYPE": "COLUMN",
  //       "className": "borderColor5",
  //       "TITLE":"Meta Data",
  //       "SUB_TITLE":"Info about when created and last time updated",
  //       "FIELDS": [
  //         {
  //           "PATH": "meta,createAt",
  //           "TYPE": "DATE",
  //           "NAME":"When Aligner was First Uploaded",
  //           "FORMAT": "M d Y - H:n:s A"
  //         },
  //         {
  //           "PATH": "meta,updateAt",
  //           "TYPE": "DATE",
  //           "NAME":"Last Update At",
  //           "FORMAT": "M d Y - H:n:s A"
  //         }
  //       ]
  //     },
  //   ]
  // },
  PREVIEW_FORM_TEMPLATES.META()
]



const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  return curCleanObj;
}


const ACTIVITYS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default ACTIVITYS;
export {
  DEFAULT_PREVIEW_FORM,
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
};