import React, { useState, useRef } from 'react';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";


/*
APISelectBox EXAMPLE:
{
  title: <div className="formTitle"><span className="">UID</span></div>,
  subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every task</span></div>,
  type: "selectAPI",
  onMount: (callBack) => { //when mounts run this then add it to "options" value
    window.Client.query({
      query: QUERY_GET_USERS
    })
    .then(result => {
      var usersOptions = result.data.users.map((user, index)=>{
        return({
          "value": user._id,
          "title": user.email
        })
      });
      callBack("options", [{
        "value": "",
        "title": "Choose one",
        "disabled": true
      }, ...usersOptions])
      // console.log("usersOptions");
      // console.log(usersOptions,"\n\n");
            
    });
  },
  name: "UID",
  required: true,
  defaultValue: "",
  defaultValuePath: "UID",
  errorMessage: "User ID is required"
},
*/

class APISelectBoxWithAdd extends ExtenderClass { 
  constructor(props) {
    super(props);
    this.state={
      options: [],
      showInput: false,
      newTempValue: ""
    };
    this.SubmitNewTempValue = this.SubmitNewTempValue.bind(this);
  }

  SubmitNewTempValue(){
    var {newTempValue, options} = this.state;
    if(newTempValue){
      options.push({
        "value": newTempValue,
        "title": newTempValue
      })
      this.props.onChange(this.props.field.name, newTempValue);
      if(this.props.field.required) this.Validate(newTempValue);
    }
    this.setState({
      showInput: false,
      newTempValue: "",
      options: options
    });
  }

  render(){
    var {field={}, id, value='', onChange, onTouch, meta} = this.props;
    var {options=[], showInput, newTempValue} = this.state;
    var {name="", required, title, add} = field;
    if(!showInput && !options.filter(obj=>(!obj.disabled && obj.value === value))[0]) value = ""; //IF CURRENT VALUE IS NOT ONE OF THE OPTIONS THEN SET CURRENT VALUE TO NOTHING.

    return(
      <div className="">
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="APISelectBoxWithAdd">
          <select 
            type="select" 
            className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}
            value={value}
            name={name}
            onChange={e=>{
              this.props.onChange(name, e.target.value);
              if(required) this.Validate(e.target.value);
            }}
            onBlur={()=>{
              this.props.onTouch(name);
              if(required) this.Validate(value);
            }}>
            {
              options.length > 0 && 
              options.map((object, index)=>{
                if(object.disabled) return(<option key={index} value={""} disabled>{object.title}</option>)
                return(
                  <option key={index} value={object.value}>{object.title}</option>
                )
              })
            }
          </select>
          <div className={`APISelectBoxWithAddCustomInput ${showInput ? "active" : ""}`}>
            <div className={`inputField`}>
              <div className="inputWrapper">
                <input 
                  type="text" 
                  className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}  
                  placeholder={"Enter Custom Value"} 
                  value={newTempValue}
                  name={"newTempValue"}
                  onChange={e=>{
                    this.setState({newTempValue: e.target.value})
                  }}
                />
              </div>
              <button className="AddButton" onClick={(e)=>{
                e.preventDefault();
                if(!showInput) this.setState({showInput: true}); 
                else this.SubmitNewTempValue()
              }}>
                <i className={!showInput ? "fas fa-plus" : "fas fa-save" }></i>
              </button>
            </div>
          </div>
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


export default APISelectBoxWithAdd;