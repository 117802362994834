import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import QUERIES from "./QUERIES";
// import MUTATIONS from "./MUTATIONS";
// import ImageUtils from "global/utils/image-utils";
// const IMAGE_UTILS =  new ImageUtils();
import PRODUCTS from "../PRODUCTS/index";

const FORM_FIELDS = (obj={}) => {
  const QUERY_GET_PRODUCTS_DISTINCT = PRODUCTS.QUERIES.distinct();
  const QUERY_GET_COUPONS = QUERIES.couponFinder()
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Coupon ID CANT BE CHANGED</span></div>,
        type: "text",
        name: "id",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "_id",
        hide: true
      },
      {
        title: <div className="formTitle"><span className="">Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The name of the coupon for our reference.</span></div>,
        type: "text",
        name: "name",
        required: true,
        defaultValue: "",
        defaultValuePath: "name"
      },
      // {
      //   title: <div className="formTitle"><span className="">Codes</span></div>,
      //   subTitle: <div className="formSubTitle"><span className="">This is the code used to find and add the coupon.</span></div>,
      //   type: "text",
      //   name: "codes",
      //   required: true,
      //   defaultValue: "",
      //   defaultValuePath: "codes",
      //   onBlur: ({newValue, currentObject, onChangeValid=()=>{}, newErrorFunction=()=>{}})=>{  
      //     window.Client.mutate({
      //       mutation: QUERY_GET_COUPONS,
      //       variables: {
      //         "manual": [
      //           {
      //             path: "codes",
      //             val: newValue
      //           }
      //         ],
      //       }
      //     })
      //     .then(result => {
      //       var coupons = result.data.coupons;
      //       if(coupons && coupons.length){ //IF CODE EXIST                    
      //         if(!currentObject._id || (coupons[0]._id !== currentObject._id)){ //IF CODE IS NOT CURRENT CODE
      //           onChangeValid("CODE", false);
      //           newErrorFunction("THIS CODE IS BEING USED");
      //         } else {
      //           onChangeValid("CODE", true);
      //           newErrorFunction("");
      //         }
      //       } else {
      //         if(newValue){ //AS LONG AS A CODE EXIST THEN ALLOW
      //           onChangeValid("CODE", true);
      //           newErrorFunction("");
      //         }
      //       }
      //       //console.log(`result.data`,"\n\n",result.data,"\n\n");
      //     })
      //     .catch((error)=>{
      //       console.log("API_CALLS.COUPONS.QUERIES.all FAIL catch", error);
      //     })
      //   }
      // },
      {
        title: <div className="formTitle"><span className="">Category</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Basic is normal coupon, Promo is one that shows up for all Influencers, etc.</span></div>,
        name: "category",
        required: true,
        defaultValue: "",
        type: "select",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "BASIC",
            "title": "BASIC"
          },
          {
            "value": "PROMO",
            "title": "PROMO"
          },
          {
            "value": "COMPANY_REFERRAL",
            "title": "COMPANY_REFERRAL"
          }
        ],
        required: true,
        defaultValue: "BASIC",
        errorMessage: "Status is required"
      },
      {
        title: <div className="formTitle"><span className="">Type</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Different types of coupons are (Percent Off, Money Off, Fixed Price)</span></div>,
        name: "type",
        required: true,
        defaultValue: "",
        type: "select",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "PERCENT_OFF",
            "title": "PERCENT_OFF"
          },
          {
            "value": "MONEY_OFF",
            "title": "MONEY_OFF"
          },
          {
            "value": "FIXED_PRICE",
            "title": "FIXED_PRICE"
          }
        ],
        required: true,
        defaultValue: "MONEY_OFF",
        errorMessage: "Status is required"
      },
      {
        title: <div className="formTitle"><span className="">Summary</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This will be our admin quick reference summary of what the deal is.</span></div>,
        type: "textArea",
        name: "summary",
        required: false,
        defaultValue: "",
        defaultValuePath: "summary"
      },
      {
        title: <div className="formTitle"><span className="">Description</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This text will show up when people add the code. So make it clear what they get.</span></div>,
        type: "textArea",
        name: "description",
        required: false,
        defaultValue: "",
        defaultValuePath: "description"
      },
      {
        title: <div className="formTitle"><span className="">DEFAULT CODE</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This is the code to use this Coupon. By default it'll be paired with the Admin UID.</span></div>,
        type: "text",
        name: "codes,0,CODE",
        formatInput: (inputValue)=>{ //MAKE ALL INPUT UPPERCASE
          if(!inputValue) return inputValue;
          return inputValue.toUpperCase();
        },
        onBlur: ({newValue, currentObject, onChangeValid=()=>{}, newErrorFunction=()=>{}})=>{  
          window.Client.mutate({
            mutation: QUERY_GET_COUPONS,
            variables: {
              code: newValue
            }
          })
          .then(result => {
            var coupon = result.data.couponFinder;
            //console.log('coupon', coupon);
            if(coupon){ //IF CODE EXIST                    
              if(coupon.ID){ //IF CODE IS NOT CURRENT CODE
                onChangeValid("CODE", false);
                newErrorFunction("THIS CODE IS BEING USED");
              } else {
                onChangeValid("CODE", true);
                newErrorFunction("");
              }
            }
            //console.log(`result.data`,"\n\n",result.data,"\n\n");
          })
          .catch((error)=>{
            console.log("API_CALLS.REFERRALS.QUERIES.all FAIL catch", error);
          })
        },
        required: true,
        defaultValue: "",
        defaultValuePath: "codes,0,CODE"
      },
      {
        title: <div className="formTitle"><span className="">Discount</span></div>,
        subTitle: <div className="formSubTitle"><span className="">How much will be discounted.</span></div>,
        type: "number",
        name: "discount",
        inputPreDiv: (currentObject)=>{
          if(currentObject.type === "MONEY_OFF") return(<div>$ &nbsp;</div>)
          if(currentObject.type === "FIXED_PRICE") return(<div>$ &nbsp;</div>)
          return null;
        },
        inputPostDiv: (currentObject)=>{
          if(currentObject.type === "PERCENT_OFF") return(<div>&nbsp; % OFF</div>)
          if(currentObject.type === "MONEY_OFF") return(<div>&nbsp; OFF</div>)
          if(currentObject.type === "FIXED_PRICE") return(<div>&nbsp; TOTAL</div>)
          return null;
        },
        required: true,
        defaultValue: "",
        defaultValuePath: "discount",
      },
      {
        title: <div className="formTitle"><span className="">What we pay Affiliates.</span></div>,
        subTitle: <div className="formSubTitle"><span className="">How much do affiliates make when this coupon is used?</span></div>,
        type: "number",
        name: "commission",
        required: false,
        defaultValue: 0,
        defaultValuePath: "commission",
      },
      {
        title: <div className="formTitle"><span className="">Applies only to what product category?</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Select a category and this coupon will be limited to that category.</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_PRODUCTS_DISTINCT,
            variables: {
              field: "category" //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {                  
            var fullOptionList = result.data.productDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "ALL",
                "title": "ALL"
              },
              {
                "value": "RETAINERS",
                "title": "RETAINERS"
              },
              {
                "value": "IMPRESSION_KITS",
                "title": "IMPRESSION_KITS"
              },
              {
                "value": "TREATMENT_PLANS",
                "title": "TREATMENT_PLANS"
              }
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            window.GlobalUtil.consoleLogNew({LOCATION:`src/API_CALLS/COUPONS/FORM_FIELDS_NEW`, NAME:`uniqueArray`, CONTENT:[uniqueArray]});
            callBack("options", uniqueArray)
          });
        },
        name: "appliesOnlyTo",
        required: true,
        defaultValue: "ALL",
        defaultValuePath: "appliesOnlyTo",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Limit Quantity</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Limit number of coupons available?</span></div>,
        type: "toggle",
        name: "limit",
        borderBottom: true,
        borderTop: true,
        defaultValue: false,
        defaultValuePath: "limit",
        // interpretValue: (value)=>{
        //   if(!value) return false;
        //   if(value === true) return true;
        //   if(value === "ACTIVE") return true;
        //   if(value === "INACTIVE") return false;
        // }
      },
      {
        condition: (curObj)=>curObj.limit,
        title: <div className="formTitle"><span className="">Limit Total</span></div>,
        subTitle: <div className="formSubTitle"><span className="">How many do we allow</span></div>,
        type: "number",
        name: "limitNumber",
        required: false,
        defaultValue: 100000,
        defaultValuePath: "limitNumber"
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose a Status for your Coupon. Active means it'll go live.</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: true,
        defaultValue: "ACTIVE",
        defaultValuePath: "status",
        errorMessage: "Status is required"
      },
      {
        title: <div className="formTitle"><span className="">Date Start</span></div>,
        subTitle: <div className="formSubTitle"><span className="">When will the coupon start working</span></div>,
        type: "datePicker",
        name: "duration,startAt",
        required: true,
        defaultValue: "",
        defaultValuePath: "duration,startAt"
      },
      {
        title: <div className="formTitle"><span className="">Date End</span></div>,
        subTitle: <div className="formSubTitle"><span className="">When will the coupon stop working</span></div>,
        type: "datePicker",
        name: "duration,endAt",
        required: true,
        defaultValue: "",
        defaultValuePath: "duration,endAt"
      }
    ]
  )
};






export default FORM_FIELDS;