//import React from 'react';
const PAGE = [
  {
    "type": "span",
    "content": "<span style=\"color:red,font-weight:700\">ADVERTENCIA ESTO HA SIDO RECHAZADO</span> - Notas de rechazo"
  },
  {
    "content": "El texto a continuación DEBERÍA explicar por qué se le devolvió esto. Solucione cualquier problema y envíelo de nuevo."
  }
];




  
export default PAGE;