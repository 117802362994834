import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
//import USERS from "../USERS/index";
import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();




const FORM_FIELDS = (obj)=>{
  const MUTATION_EDIT = MUTATIONS.update();  
  return [ //defaultValuePath used to set default value form object
    {
      title: <div className="formTitle"><span className="">Name</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Event Name, or school name to find who this appies to.</span></div>,
      type: "text",
      name: "name",
      required: true,
      defaultValue: "",
      defaultValuePath: "name",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Group</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This is needed to allow the same giveaway to happen with the same name but in a diffrent group. EX: 2021FAllGIVEAWAY, 2022SUMMERGIVEAWAY.</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERIES.distinct(),
          variables: {
            field: "group", //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          //console.log("result.data","\n\n",result.data,"\n\n");
          var fullOptionList = result.data.giveawayDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          callBack("options", uniqueArray)
        });
      },
      name: "group",
      required: true,
      defaultValue: "",
      defaultValuePath: "group",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Description</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Be as detailed as possible here. But not as detailed as the legal part.</span></div>,
      type: "wizyWig",
      name: "description",
      required: false,
      defaultValue: "",
      defaultValuePath: "description",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Category</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This is used to group together any similar giveaways for search reasons. Ex: School</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERIES.distinct(),
          variables: {
            field: "category", //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          //console.log("result.data","\n\n",result.data,"\n\n");
          var fullOptionList = result.data.giveawayDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          callBack("options", uniqueArray)
        });
      },
      name: "category",
      required: true,
      defaultValue: "",
      defaultValuePath: "category",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Subcategory</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This will help FURTHER filter and should be a bit more detailed. Ex: Highschool</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERIES.distinct(),
          variables: {
            field: "subcategory", //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          //console.log("result.data","\n\n",result.data,"\n\n");
          var fullOptionList = result.data.giveawayDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          callBack("options", uniqueArray)
        });
      },
      name: "subcategory",
      required: true,
      defaultValue: "",
      defaultValuePath: "subcategory",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Keywords</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This can help futher filter if we have a bunch later.</span></div>,
      type: "wordList",
      name: "keywords",
      required: false,
      defaultValue: "",
      defaultValuePath: "keywords",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Date Starts</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When will this event begin. Not when will we be choosing winners but when will it be visible.</span></div>,
      type: "datePicker",
      name: "startAt",
      required: true,
      defaultValue: "",
      defaultValuePath: "startAt"
    },
    {
      title: <div className="formTitle"><span className="">Date Ends</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When will this event END. After this point people won't be able to see the event any more, or sign up.</span></div>,
      type: "datePicker",
      name: "endAt",
      required: true,
      defaultValue: "",
      defaultValuePath: "endAt"
    },
    {
      title: <div className="formTitle"><span className="">Legal</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This is where your legal stuff would go that would excuse us of any liability.</span></div>,
      type: "wizyWig",
      name: "legal",
      required: false,
      defaultValue: "",
      defaultValuePath: "legal",
      errorMessage: "This is required"
    }
  ]
};






export default FORM_FIELDS;