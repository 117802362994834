import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';


const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR}
  ){
    faileds(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR}
  ){
    failedCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    faileds(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR}
  ){
    failedCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query FailedSingle($id: String, $include: [Include]){
    failed(id:$id, include: $include){
      _id
      ${whatToPull}
    }
  }
`;


const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    failedDistinct(field:$field)
  }
`;






const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  count: COUNT,
  single: QUERY_SINGLE,
  distinct: QUERY_GET_DISTINCT
}


export default QUERIES;
