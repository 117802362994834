import React from 'react';

//BY DEFAULT IT WILL FIND ALL THOSE WITH "active" AS TRUE
const FILE = {
  "createAt": "string",
  "url": "string",
  "name": "string",
  "type": "string",
  "id": "string"
}; 



const ADDRESS = {
  street: 'string',
  street2: 'string',
  city: 'string',
  state: 'string',
  country: 'string',
  zipcode: 'string'
}; 


const META = {
  createAt: "string",
  updateAt: "string",
};


const NOTESLIST = {
  "note": "string",
  "createAt": "string",
  "updateAt": "string",
  "title": "string",
  "category": "string",
  "status": "string"
}; 

const CLEAN_FIELDS_DEFAULT = {
  FILE,
  ADDRESS,
  META,
  NOTESLIST
}




export default CLEAN_FIELDS_DEFAULT;