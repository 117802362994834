import React from 'react';

const PREVIEW_FORM = [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"About",
        "SUB_TITLE":"This is a list of all connected ID's",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME":"TREATMENT PLAN ID"
          },
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "User", //TITLE OF SLIDE OUT
              "APINAME": "USERS",
              "ID_PATH": "UID",
              "EDITABLE": true
            },
            "NAME":"USER ID"
          },
          {
            "PATH": "OID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Order", //TITLE OF SLIDE OUT
              "APINAME": "ORDERS",
              "ID_PATH": "OID",
              "EDITABLE": true
            },
            "NAME":"ORDER ID",
            "DEFAULT": "Not Purchased yet"
          },
          {
            "PATH": "LABORATORY",
            "TYPE": "STRING",
            "NAME":"Laboratory"
          },
          {
            "PATH": "DOCTOR",
            "TYPE": "STRING",
            "NAME":"Approved By Doctor"
          },
          
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Payments",
        "SUB_TITLE":"Info about last payment and status",
        "FIELDS": [
          {
            "PATH": "step",
            "TYPE": "STRING",
            "NAME":"Step"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME":"Status"
          },
          {
            "PATH": "type",
            "TYPE": "STRING",
            "NAME":"Type"
          },
          {//IF TYPE SUBSCRIPTION THEN SHOW SUBSCRIPTION ID
            "CONDITION": (curObj)=>curObj.subscriptionID, 
            "PATH": "subscriptionID",
            "TYPE": "STRING",
            "NAME":"Subscription ID",
            "DEFAULT": "NA"
          },
          {
            "PATH": "started",
            "TYPE": "DATE",
            "NAME":"Purchased",
            "DEFAULT": "Not Purchased yet"
          },
          {
            "PATH": "whenLastPaid",
            "TYPE": "STRING",
            "NAME": "When Last Paid",
            "DEFAULT": "Not Purchased yet"
          }
        ]
      }
    ]
  },


  
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE": "Plan Files and Images",
        "SUB_TITLE":"Show all existing plan files and images",
        "FIELDS": [
          {
            "PATH": "planFiles",
            "TYPE": "ARRAY_OBJECT",
            "NAME":"Plan Files",
            "col": 12,
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12, 
            "xl": 12,
            "STYLE": {paddingBottom: "10px"},
            "FIELDS": [
              {
                "TYPE": "INLINE_FILE",
                "PATH": "ALL",
                "DOWNLOAD": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "CONDITION": (curObj)=>curObj.notes, 
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Additional Info",
        "SUB_TITLE":"Any information left by us or the doctor",
        "FIELDS": [
          {
            "PATH": "notes",
            "TYPE": "STRING",
            "NAME": "Notes"
          }
        ]
      }
    ]
  },

  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor5",
        "TITLE":"Aligner Schdule",
        "SUB_TITLE":"When the user started using their aligners. What step they'r on. And how far along they are.",
        "FIELDS": [
          {
            "TYPE": "ROW",
            "FIELDS": [
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "alignersSchedule,started",
                    "TYPE": "DATE",
                    "NAME": <span><span>Started</span></span>,
                    "FORMAT": "M d Y - H:n:s A"
                  }
                ]
              },
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "alignersSchedule,numOfAlignersTop",
                    "TYPE": "NUMBER",
                    "NAME": <span><span>Number of TOP Aligners</span></span>
                  }
                ]
              }
            ]
          },
          {
            "TYPE": "ROW",
            "FIELDS": [
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "alignersSchedule,numOfAlignersBottom",
                    "TYPE": "NUMBER",
                    "NAME": <span><span>Number of BOTTOM Aligners</span></span>
                    
                  }
                ]
              }
            ]
          },
          {
            "CONDITION": (curObj)=>(curObj.alignersSchedule && curObj.alignersSchedule.alignerHistory && curObj.alignersSchedule.alignerHistory.length), 
            "TYPE": "ROW",
            "FIELDS": [
              {
                "TYPE": "COLUMN",
                "className": "borderColor1",
                "TITLE": "Aligner Schdule History",
                "SUB_TITLE":"When to start and stop using aligners.",
                
                "PATH": "alignersSchedule,alignerHistory",
                "TYPE": "ARRAY_OBJECT",
                //"NAME":"Aligner Files",
                "col": 12,
                "xs": 12,
                "sm": 12,
                "md": 12,
                "lg": 12, 
                "xl": 12,
                "STYLE": {paddingBottom: "10px"},
                "FIELDS": [
                  {
                    "TYPE": "ROW",
                    "FIELDS": [
                      {
                        "TYPE": "COLUMN",
                        "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                        "className": "borderColor5",
                        "FIELDS": [
                          {
                            "PATH": "number",
                            "TYPE": "STRING",
                            "NAME": "Number"
                          },
                          {
                            "CONDITION": (curObj)=>curObj.alignerTop, 
                            "PATH": "alignerTop",
                            "TYPE": "STRING",
                            "NAME": "Top Aligner Number to Use"
                          },
                          {
                            "CONDITION": (curObj)=>curObj.alignerBottom, 
                            "PATH": "alignerBottom",
                            "TYPE": "STRING",
                            "NAME": "Bottom Aligner Number to Use"
                          },
                          {
                            "PATH": "startedAt",
                            "TYPE": "DATE",
                            "NAME": "Started Using Alginers",
                            "FORMAT": "M d Y - H:n:s A"
                          },
                          {
                            "PATH": "endsAt",
                            "TYPE": "DATE",
                            "NAME": "Finished Using Alginers",
                            "FORMAT": "M d Y - H:n:s A"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "TYPE": "ROW",
                    "FIELDS": [
                      {
                        "TYPE": "COLUMN",
                        "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                        "className": "borderColor5",
                        "FIELDS": [
                          {
                            "PATH": "pictures",
                            "TYPE": "ARRAY_OBJECT",
                            "NAME":"Pictures",
                            "col": 12,
                            "xs": 12,
                            "sm": 12,
                            "md": 12,
                            "lg": 12, 
                            "xl": 12,
                            "STYLE": {paddingBottom: "10px"},
                            "FIELDS": [
                              {
                                "TYPE": "INLINE_FILE",
                                "PATH": "ALL",
                                "DOWNLOAD": true
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor5",
        "TITLE":"Plan Backend History",
        "SUB_TITLE":"Info about when the treatment plan was created by the lab, approved by a doctor and more.",
        "FIELDS": [
          {
            "TYPE": "ROW",
            "FIELDS": [
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "HISTORY,LABORATORY,UPLOADED_OFFER",
                    "TYPE": "DATE",
                    "NAME": <span><span style={{"color": "#00bae3"}}>LABORATORY - </span><span style={{"fontWeight": "500"}}>UPLOADED OFFER</span></span>,
                    "FORMAT": "M d Y - H:n:s A"
                  }
                ]
              },
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "HISTORY,LABORATORY,PAID_FOR_OFFER",
                    "TYPE": "DATE",
                    "NAME": <span><span style={{"color": "#00bae3"}}>LABORATORY - </span><span style={{"fontWeight": "500"}}>PAID FOR OFFER</span></span>,
                    "FORMAT": "M d Y - H:n:s A"
                  }
                ]
              }
            ]
          },
          {
            "TYPE": "ROW",
            "FIELDS": [
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "HISTORY,LABORATORY,UPLOADED_FULL",
                    "TYPE": "DATE",
                    "NAME": <span><span style={{"color": "#00bae3"}}>LABORATORY - </span><span style={{"fontWeight": "500"}}>UPLOADED FULL</span></span>,
                    "FORMAT": "M d Y - H:n:s A"
                  }
                ]
              },
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "HISTORY,LABORATORY,PAID_FOR_FULL",
                    "TYPE": "DATE",
                    "NAME": <span><span style={{"color": "#00bae3"}}>LABORATORY - </span><span style={{"fontWeight": "500"}}>PAID FOR FULL</span></span>,
                    "FORMAT": "M d Y - H:n:s A"
                  }
                ]
              }
            ]
          },
          {
            "TYPE": "ROW",
            "FIELDS": [
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "HISTORY,DOCTOR,APPROVED_PLAN",
                    "TYPE": "DATE",
                    "NAME": <span><span style={{"color": "#00bae3"}}>DOCTOR - </span><span style={{"fontWeight": "500"}}>APPROVED PLAN</span></span>,
                    "FORMAT": "M d Y - H:n:s A"
                  }
                ]
              },
              {
                "TYPE": "COLUMN",
                "STYLE": {"paddingBottom":"0", "marginBottom": "-15px"},
                "className": "borderColor5",
                "FIELDS": [
                  {
                    "PATH": "HISTORY,DOCTOR,PAID_FOR_APPROVAL",
                    "TYPE": "DATE",
                    "NAME": <span><span style={{"color": "#00bae3"}}>DOCTOR - </span><span style={{"fontWeight": "500"}}>PAID FOR APPROVAL</span></span>,
                    "FORMAT": "M d Y - H:n:s A"
                  }
                ]
              }
            ]
          }
        ]
      }

    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor5",
        "TITLE":"Meta Data",
        "SUB_TITLE":"Info about when created and last time updated",
        "FIELDS": [
          {
            "PATH": "meta,createAt",
            "TYPE": "DATE",
            "NAME":"Created At",
            "FORMAT": "M d Y - H:n:s A"
          },
          {
            "PATH": "meta,updateAt",
            "TYPE": "DATE",
            "NAME":"Last Update At",
            "FORMAT": "M d Y - H:n:s A"
          }
        ]
      }
    ]
  }
]


export default PREVIEW_FORM;