//import React from 'react';
const PAGE = [
  {
    "content": "View Treatment Plan Request"
  },
  {
    "content": "Close"
  },
  {
    "content": "Patient Information"
  },
  {
    "content": "Initial User Mouth Pictures"
  },
  {
    "content": "Download"
  },
  {
    "content": "Mouth Scan Files"
  },
  {
    "content": "Chief Complaint"
  },
  {
    "content": "Other Notes"
  }
];




  
export default PAGE;