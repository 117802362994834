import React from 'react';
// import {NavLink, Link} from 'react-router-dom';
//import PAGES from '../pages/pages.js'; //ADD NEW PAGES HERE
import API_CALLS from 'API_CALLS/index';
import AreYouSurePopup from 'global/components/AreYouSurePopup/index';
//import  'styles/scss/website/components/TreatmentPlanBar.scss'; ///Users/danielezaldivar/Sites/sonrisas/src/styles/scss/website/components/TreatmentPlanBar.scss
import LayoutBuilder from 'LayoutBuilder';
import withNavigateHook from 'global/components/WithNavigateHook';
import CONTENTS from "./CONTENTS";


const {Container, Row, Column} = LayoutBuilder;




class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loading: true
    };    
  }

  componentDidMount(){                 
    this.RunAPIOnceWhenReady = window.GlobalUtil.runAPIOnceWhenReady(this.getProducts);
  }

  componentWillUnmount(){
    this.RunAPIOnceWhenReady.unsubscribe();
  }

  onFailed = () => {
    this.setState({curStatus: "failed", loading: false});
  }



  getProducts = () => {
    //return true; //FOR NOW DON'T GET PRODUCTS JUST HAVE THEM CONTACT US
    if(!window.Client || !window.Client.query) return false;          
    if(this.gettingProductsRef) return true;
    this.gettingProductsRef = true;
    this.setState({loading: true});    
    return window.Client.query({
      query: API_CALLS.PRODUCTS.QUERIES.all(),
      variables: {
        type: "NORMAL",
        manual: [
          // {
          //   path: "language",
          //   val: window.Session.Language
          // },
          {
            path: "type",
            val: "NORMAL"
          },
          {
            path: "ref",
            val: "TREATMENT_PLAN_BAR" //(this.props.API_ref ? this.props.API_ref : "TREATMENT_PLAN_BAR")
          },
          {
            path: "website",
            val: (window.Session.Language === "ENGLISH") ? "SMILES" : "SONRISAS"
          },
          {
            path: "showWhere",
            val: "TREATMENT_PLAN_BAR"
          }
        ],
        status: "ACTIVE",
      }
    })
    .then(result => {
      //console.log("result");
      window.GlobalUtil.consoleLogNew({LOCATION:`src/website/components/TreamentPlanBar/index`, NAME:`WEBSITE -> COMPONENT -> TREATMENT_PLAN_BAR -> getProducts() -> SUCCESS`, CONTENT:result});
      //if(!this._ismounted) return;

      var coupon = (this.props.showCouponInTreatmentPlanBar) ? window.GlobalUtil.State.get("newOrder").coupon : {}
      var products = window.GlobalUtil.productCouponDisCal(window.GlobalUtil.APICleanObj(result.data.products), coupon); //THIS SHOULD GET ALL PRODUCTS AND APPLY ANY COUPONS

      this.setState({products: products, loading: false});
      return true;      
    })
    .catch((error)=>{
      window.GlobalUtil.consoleLogNew({LOCATION:`src/website/components/TreamentPlanBar/index`, NAME:`WEBSITE -> COMPONENT -> TREATMENT_PLAN_BAR -> getProducts() -> ERROR`, CONTENT:error});
      console.log("catch ", error);
      return false;     
    })
  }


  render(){
    var {products=[], loading, showAreYouSurePopup} = this.state;
    if(typeof window === 'undefined') return <div id="TreatmentPlanBar" className={`containerSection`}></div>;
    var {productTitleAdd, className="", showAreYouSurePopupDialog} = this.props;
    if(loading) return <div id="TreatmentPlanBar" className="containerSection "></div>;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const CUR_PRODUCT = products[0];   //THIS
    if(!CUR_PRODUCT) return <div id="TreatmentPlanBar" className="containerSection "></div>
    const ImagesURL = window.GlobalUtil.deepGetFromString(CUR_PRODUCT, "images", []);
    //const ImageURL = window.GlobalUtil.deepGetFromString(CUR_PRODUCT, "images,0,url", null);          
    var {discount, cost, originalCost} = window.GlobalUtil.productCostCal(CUR_PRODUCT);  
    var savings = (originalCost - (cost-discount))
    
    return (
      <div id="TreatmentPlanBar" className={`containerSection ${className}`}>
        <Container className="impressionKitContainer">
          <Row id="BuyNow" className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div id="product" className="productLayout1 d-flex">
                <ProductImage className="d-none d-lg-block d-x-block" Images={ImagesURL} />
                <div className="productText">
                  <ProductImage className="d-block d-lg-none" Images={ImagesURL} />
                  {productTitleAdd}
                  <div className="productTextName">
                    {CUR_PRODUCT.name}
                  </div>
                  {CONTENT.ProductSubText[0]}
                  
                  <div className="productTextCost d-flex">
                    {CONTENT.ProductTextCost[0]}
                    <div className="cost">{window.GlobalUtil.convertToMoney(cost-discount, false, true)}</div>
                    <div className="originalPrice">{window.GlobalUtil.convertToMoney(originalCost, false, true)}</div>
                    {
                      savings
                      ? <div className='savings'><strong>{window.GlobalUtil.convertToMoney(savings, false, true)}</strong> {CONTENT.ProductTextCost[1]}</div>
                      : null
                    }
                  </div>
                  {CONTENT.ProductSubText[1]}
                  {window.GlobalUtil.dangerSet(CUR_PRODUCT.description, "div", "productTextDesc")}
                  {/*<div className="">
                    Category: {product.category}
                  </div>*/}
                  <div className="buttonGroup">
                    {
                      showAreYouSurePopupDialog
                      ? <button className="button button1 dontCloseCart" onClick={()=>{this.setState({showAreYouSurePopup: true})}}>
                        {
                          CONTENT.ProductButtons[0]
                        }
                        </button>
                      : <button className="button button1 dontCloseCart" onClick={()=>{
                          if(this.props.onClickAddToCart){
                            this.props.onClickAddToCart().then(()=>{
                              window.GlobalUtil.addToCart({product: {...CUR_PRODUCT}, replaceAll: true, onlyOne: true}, ()=>{
                                //window.GlobalUtil.triggerEvent("cartEvent", "show");
                                this.props.navigation('/checkout');
                              });
                            });
                          } else {
                            window.GlobalUtil.addToCart({product: {...CUR_PRODUCT}, replaceAll: true, onlyOne: true}, ()=>{
                              //window.GlobalUtil.triggerEvent("cartEvent", "show");
                              this.props.navigation('/checkout');
                            });
                          }
                        }}>
                        {
                          CONTENT.ProductButtons[1]
                        }
                        </button>
                    }
                  </div>
                  {
                    CONTENT.ProductGuarantee[0]
                  }
                </div>
              </div>   
            </Column>
          </Row>
          {
            CONTENT.AffirmSmallPrint[0]
          }
        </Container>
        {
          showAreYouSurePopup && 
          <AreYouSurePopup 
            onClose={()=>{
              this.setState({showAreYouSurePopup: false})
            }}
            onSubmit={()=>{              
              if(this.props.onClickAddToCart){
                this.props.onClickAddToCart().then(()=>{
                  window.GlobalUtil.addToCart({product: {...CUR_PRODUCT}, replaceAll: true, onlyOne: true}, ()=>{
                    //window.GlobalUtil.triggerEvent("cartEvent", "show");
                    this.props.navigation('/checkout');
                  });
                });
              } else {
                window.GlobalUtil.addToCart({product: {...CUR_PRODUCT}, replaceAll: true, onlyOne: true}, ()=>{
                  //window.GlobalUtil.triggerEvent("cartEvent", "show");
                  this.props.navigation('/checkout');
                });
              }
            }}
          />
        }
      </div>
    );
  }
}






class ProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImage: (this.props.Images ? this.props.Images[0] : {})
    };    
  }

  componentDidMount(){    
  }

  componentWillUnmount(){
  }


  render(){
    var {activeImage={}} = this.state;
    var {Images, className} = this.props;
    return (
      <div className={"productImageWrapper " + className}>
        <div className="productImage">
          <div className="activeImage"><img src={activeImage.url} alt="Sonrisas Club Impression Kit"/></div>
          {
            false //Images && Images.length
            ? <div className="smollImages">
                {
                  Images.map((imgObj, index)=>{
                    return(
                      <div className="smollImage" key={index} onClick={()=>{
                        this.setState({activeImage: imgObj})
                       }}>
                        <img src={imgObj.url} alt="Sonrisas Club Impression Kit"/>
                      </div>
                    )
                  })
                }
              </div>
            : null
          }
        </div>
      </div>
    );
  }
}
export default withNavigateHook(Index);
