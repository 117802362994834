import React, { useState, useRef } from 'react';
import "styles/scss/doctor/components/newOrderDialog.scss";
import API_CALLS from 'API_CALLS/index';
import Loading from 'global/components/Loading/index';
import ImageUtils from "global/utils/image-utils";
import FormBuilder from 'global/components/FormBuilder/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const IMAGE_UTILS =  new ImageUtils();







/*
FIRST CREATE NEW USER (PATIENT) (MUST HAVE DOCTOR ID)
THEN AUTO CREATE NEW TREATMENT PLAN FOR THAT USER

THEN UPLOAD ALL THINGS NEEDED FOR TREATMENT PLAN REQUEST
OBJECT TO CREATE NEW PATIENT
{
  DOCID
  firstName
  lastName
}

OBJECT TO CREATE TREATMENT PLAN REQUEST
{
  TPID
  offerRequest{
    chiefComplaint
    notes
    mouthPictures{
    }
    otherFiles{

    }
  }
}

*/

const Index = (props) => {    
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  if(props.customButton){
    return(
      props.customButton({
        onClick: (e)=>{
          e.preventDefault();
          window.GlobalUtil.triggerEvent("SlideInRightEvent", {
            TITLE: props.TITLE ? props.TITLE : CONTENT[0],
            closeButtonText: CONTENT[1],
            saveButtonText: CONTENT[2],
            customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog",
            width: "1200px",
            noFooter: true,
            children: (propsChildren)=>{                                      
              return(
                <HandleForms
                  {...propsChildren} 
                  curTreatmentPlan={props.curTreatmentPlan}
                  curUser={props.curUser}
                  DOCID={props.DOCID}
                  CONTENT={CONTENT}
                />
              )
            }
          });
        }
      })
    )
  }
  return(
    <button className="button button1" onClick={(e)=>{
      e.preventDefault();
      window.GlobalUtil.triggerEvent("SlideInRightEvent", {
        TITLE: CONTENT[0],
        closeButtonText: CONTENT[1],
        saveButtonText: CONTENT[2],
        customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog",
        width: "1200px",
        noFooter: true,
        children: (propsChildren)=>{                                      
          return(
            <HandleForms
              {...propsChildren} 
              curTreatmentPlan={props.curTreatmentPlan}
              curUser={props.curUser}
              DOCID={props.DOCID}
              CONTENT={CONTENT}
            />
          )
        }
      });
    }}>{CONTENT[3]} <i className="fas fa-plus"></i></button>
  )
}









class HandleForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curTreatmentPlan: this.props.curTreatmentPlan,
      curUser: this.props.curUser
    };    
  }

  render(){
    var {curUser, curTreatmentPlan} = this.state;  
    var {CONTENT} = this.props;
    //console.log(`this.props.curUser`,"\n\n",this.props.curUser,"\n\n");
          
          
    return(
      <div className="dialogCustomZone">
        {
          (!curUser || (curUser.role === "PATIENT")) && //ONLY LET THEM EDIT THE PATIENT IF IT IS A PATIENT OR THEY ARE CREATING A NEW PATIENT. IF NOT AND IS A USER, THEN DON'T LET THEM EDIT THE USER INFORMATION
           <NewPatient
            DOCID={this.props.DOCID}
            onCreateUser={(newUser)=>{
              this.setState({curUser: newUser});                    
            }}
            curUser={curUser}
            CONTENT={CONTENT}
          />
        }
        {
          (curUser && curUser._id) && 
          <React.Fragment>
            {(curUser.role === "PATIENT") && <hr/>}
            <NewTreatmentPlan 
              curUser={curUser}
              curTreatmentPlan={curTreatmentPlan}
              CONTENT={CONTENT}
            />
          </React.Fragment>
        }
      </div>
    )
  }
}









class NewPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curObj: {
        firstName: (this.props.curUser && this.props.curUser.firstName) ? this.props.curUser.firstName :  "",
        lastName: (this.props.curUser && this.props.curUser.lastName) ? this.props.curUser.lastName :  "",
        DOCID: this.props.DOCID
      }
    }; 
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  OnSubmit = async() => {
    var {curObj} = this.state;
    this.setState({loading: true})

    // //FOR NOW JUST DO THIS INSTEAD OF ANY API CALLS
    // return window.GlobalUtil.delay(1000).then(()=>{
    //   this.props.onCreateUser({
    //     _id: "1232123123",
    //     TPID: "239894u29834",
    //     ...curObj
    //   });
    //   this.setState({loading: false})
    // });


    //WE'LL DO THIS LATER

    if(this.props.curUser) await this.APIUpdatePatient();
    else await this.APICreatePatient();

    //window.ForceUserReload
    return true;
  }


  APICreatePatient = () => {  
    var {curObj} = this.state;          
    if(curObj.undefined) delete curObj.undefined;
    return window.Client.mutate({
      mutation: API_CALLS.USERS.MUTATIONS.createPatient(),
      variables: {
        ...curObj
      }
    })
    .then(result=>{
      this.props.onCreateUser(result.data.createPatient);
      this.setState({loading: false});
    })
    .catch(failed=>{
      console.log(`Failed to create patient`,"\n\n",failed,"\n\n");
    });
  }


  APIUpdatePatient = () => {  
    var {curObj} = this.state;
    return window.Client.mutate({
      mutation: API_CALLS.USERS.MUTATIONS.update(),
      variables: {
        ...curObj,
        _id: this.props.curUser._id,
      }
    })
    .then(result=>{
      this.props.onCreateUser(result.data.update);
      console.log(`result`,"\n\n",result,"\n\n");
      this.setState({loading: false});
    })
    .catch(failed=>{
      console.log(`Failed to create patient`,"\n\n",failed,"\n\n");
    });
  }

  render(){
    var {curObj, isValid, loading} = this.state;
    const {CONTENT} = this.props;
    return (
      <React.Fragment>
        {/*<div className="previewForm">
          <div className="previewFormTitle">{(this.props.curUser) ? "Edit" : "New"} Patient</div>
          <div className="previewFormSubTitle">Create a new patient before creating a new order.</div>
        </div>*/}
        <div className="editPatient">
          <FormBuilder
            {...this.props} 
            disableKeySubmit={true}
            initialValues={curObj}
            listOfFields={[
              {
                title: <div className="sectionDividerBasic"><span className="sectionDividerText">{CONTENT[4]}</span><span className="sectionDividerBorder"></span></div>,
                type: "sectionDivider",
                required: false,
                defaultValue: ""
              },
              {
                title: <div className="formTitle"><span className="">{CONTENT[5]}</span></div>,
                subTitle: <div className="formSubTitle"><span className="">{CONTENT[6]}</span></div>,
                type: "text",
                name: "firstName",
                required: true,
                defaultValue: "",
                errorMessage: CONTENT[7]
              },
              {
                title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
                subTitle: <div className="formSubTitle"><span className="">{CONTENT[9]}</span></div>,
                type: "text",
                name: "lastName",
                required: true,
                defaultValue: "",
                errorMessage: CONTENT[10]
              },
              {
                condition: ()=>!this.props.DOCID,
                title: <div className="formTitle"><span className="">{CONTENT[11]}</span></div>,
                subTitle: <div className="formSubTitle"><span className="">{CONTENT[12]}</span></div>,
                type: "selectAPI",
                onMount: (callBack) => {
                  window.Client.query({
                    query: API_CALLS.USERS.QUERIES.all("firstName lastName role"),
                    variables: {
                      "status": "NOTINACTIVE",
                      "manual": [
                        {
                          path: "role",
                          listValues: JSON.stringify(["DOCTOR","ADMIN"])
                        }
                      ]
                    }
                  })
                  .then(result => {
                    var doctors = result.data.users.map((doc, index)=>{
                      return({
                        "value": doc._id,
                        "title": `${doc.firstName} ${doc.lastName} (${doc.role})`
                      })
                    });
                    callBack("options", [{
                      "value": "",
                      "title": "Choose one",
                      "disabled": true
                    }, ...doctors])
                    // console.log("usersOptions");
                    // console.log(usersOptions,"\n\n");
                          
                  });
                },
                name: "DOCID",
                required: true,
                defaultValue: "",
                errorMessage: CONTENT[13]
              },
            ]}
            onSubmit={this.OnSubmit}
            onChange={newObj => this.setState({curObj: newObj})}
            onValidate={(newIsValid)=>{
              this.setState({isValid: newIsValid})
            }}
          />
        </div>
        <div className="footer">
          <div className="buttonGroup">
            <button className={`button button1 ${isValid ? '' : 'disabled'}`} onClick={this.OnSubmit}>
              {this.props.curUser ? CONTENT[14] : CONTENT[15]}
            </button>
          </div>
        </div>
        {
          loading &&
          <Loading 
            header={CONTENT[16]}
            body={CONTENT[17]}
            inlineStyles={{
              "loadingIcon": {
                "maxWidth": "100px",
                "paddingBottom": "20px"
              },
              "loadingHeader": {
                "marginBottom": "5px",
                "fontSize": "40px"
              },
              "loadingBody": {
                "marginBottom": "0px",
                "fontSize": "20px"
              }
            }}
            floating={true}
            overlay={true}
          />
        }
      </React.Fragment>
    );
  }
}










class NewTreatmentPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curObj: 
        this.props.curTreatmentPlan
        ? {
            _id: this.props.curTreatmentPlan._id,
            id: this.props.curTreatmentPlan._id,
            UID: this.props.curTreatmentPlan.UID,
            offerRequest: this.props.curTreatmentPlan.offerRequest
          }
        : { 
            _id: this.props.curUser.TPID, //MUST HAVE TPID 
            id: this.props.curUser.TPID,
            UID: this.props.curUser._id,
            offerRequest: {} 
          }
    }; 
  }

  componentDidMount(){
    this.APIGetTreatmentPlan();
  }

  componentWillUnmount(){
  }


  OnSubmit = async() => {
    await this.APIUpdateTreatmentPlanRequest();
    //window.ForceUserReload
    return true;
  }

  APIGetTreatmentPlan =() => {  
    var {curObj} = this.state;
    window.Client.query({
      query: API_CALLS.TREATMENT_PLANS.QUERIES.single(),
      fetchPolicy: "no-cache",
      variables: {
        id: curObj._id
      }
    })
    .then(result => {
      var NewObj = window.GlobalUtil.stripOutFromObj(result.data.treatmentPlan, "__typename"); 
      NewObj.id = NewObj._id;
      if(!NewObj.id) return;
      window.CurObj = {...NewObj};
      this.setState({curObj: NewObj})   
    })
    .catch(failed=>{
      console.log(`Failed to get treatment plan`,"\n\n",failed,"\n\n");
    });
  }

  APIUpdateTreatmentPlanRequest = () => {  
    var {curObj} = this.state;
    this.setState({loading: true})
    return window.Client.mutate({
      mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
      variables: {
        ...curObj,
        id: curObj._id
      }
    })
    .then(data=>{
     window.GlobalUtil.DialogStack.closeTop(); 
    })
    .catch(failed=>{
      console.log(`Failed to create patient`,"\n\n",failed,"\n\n");
    });
  }



  render(){
    var {curObj, isValid, loading} = this.state;
    const {CONTENT} = this.props;
    return (
      <div className="animated slideInRight editTreatmentPlan">
        {/*<div className="previewForm">
          <div className="previewFormTitle">{(this.props.curTreatmentPlan) ? "Edit" : "New"} Treatment Plan Request</div>
          <div className="previewFormSubTitle">Please fill out completely to create a new order for a full treatment plan.</div>
        </div>*/}
        <FormBuilder
          {...this.props} 
          disableKeySubmit={true}
          initialValues={curObj}
          listOfFields={[
            {
              title: <div className="sectionDividerBasic"><span className="sectionDividerText">{CONTENT[18]}</span><span className="sectionDividerBorder"></span></div>,
              type: "sectionDivider",
              required: false,
              defaultValue: "",
              defaultValuePath: ""
            },
            {
              title: <div className="formTitle"><span className="">{CONTENT[19]}</span></div>,
              subTitle: <div className="formSubTitle"><span className="">{CONTENT[20]}</span></div>,
              type: "fileUploadList",
              name: "offerRequest,mouthPictures",
              maxNumber: 100,
              required: false,
              defaultValue: "",
              defaultValuePath: "offerRequest,mouthPictures",
              maxWidth: "150px",
              filePath: [
                "USERS",
                curObj.UID,
                "PLAN_REQUEST",
                "INITAL_MOUTH"
              ],
              onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
                newFileList, 
                curObj._id,
                "offerRequest,mouthPictures", 
                API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
                window.CurObj,
                (newObj)=>{
                  this.APIGetTreatmentPlan();
                  //curObj
                  callBack();
                })
            },
            {
              title: <div className="formTitle"><span className="">{CONTENT[21]}</span></div>,
              subTitle: <div className="formSubTitle"><span className="">{CONTENT[22]}</span></div>,
              type: "fileUploadList",
              name: "offerRequest,otherFiles",
              maxNumber: 100,
              required: false,
              defaultValue: "",
              defaultValuePath: "offerRequest,otherFiles",
              maxWidth: "150px",
              filePath: [
                "USERS",
                curObj.UID,
                "PLAN_REQUEST",
                "INITAL_SCANS"
              ],
              onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
                newFileList, 
                curObj._id,
                "offerRequest,otherFiles", 
                API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
                window.CurObj,
                (newObj)=>{
                  this.APIGetTreatmentPlan();
                  //curObj
                  callBack();
                })
            },
            {
              title: <div className="formTitle"><span className="">{CONTENT[23]}</span></div>,
              subTitle: <div className="formSubTitle"><span className="">{CONTENT[24]}</span></div>,
              type: "textArea",
              name: "offerRequest,chiefComplaint",
              rows: 6,
              required: true,
              defaultValue: "",
              defaultValuePath: "offerRequest,chiefComplaint",
              errorMessage: "Chief Complaint is required"
            },
            {
              title: <div className="formTitle"><span className="">{CONTENT[25]}</span></div>,
              subTitle: <div className="formSubTitle"><span className="">{CONTENT[26]}</span></div>,
              type: "textArea",
              name: "offerRequest,notes",
              rows: 6,
              required: false,
              defaultValue: "",
              defaultValuePath: "offerRequest,notes",
              errorMessage: "Notes is required"
            }
          ]}
          onSubmit={this.OnSubmit}
          onChange={newObj => this.setState({curObj: newObj})}
          onValidate={(newIsValid)=>{
            this.setState({isValid: newIsValid})
          }}
        />
        <div className="footer">
          <div className="buttonGroup">
            <button className={`button button1`} onClick={this.OnSubmit}>
              {this.props.curTreatmentPlan ? CONTENT[27] : CONTENT[28]}
            </button>
          </div>
        </div>
        {
          loading &&
          <Loading 
            header={CONTENT[29]}
            body={CONTENT[30]}
            inlineStyles={{
              "loadingIcon": {
                "maxWidth": "100px",
                "paddingBottom": "20px"
              },
              "loadingHeader": {
                "marginBottom": "5px",
                "fontSize": "40px"
              },
              "loadingBody": {
                "marginBottom": "0px",
                "fontSize": "20px"
              }
            }}
            floating={true}
            overlay={true}
          />
        }
      </div>
    );
  }
}



export default Index;
      
