import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT, FULL_MUTATION_FIELDS } from './index';

  






const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query Single($id: String){
    referral(id:$id){
      _id
      ${whatToPull}
    }
  }`



const QUERY_REFERRAL_CODE_FINDER = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query ReferralCodeFinder($CODE: String!, $include: [Include]){
    referralCodeFinder(CODE:$CODE, include: $include){
      _id
      ${FULL_MUTATION_FIELDS(["user","coupon"])}
    }
  }`




const QUERY_REFERRAL_CODE_PASSWORD_FINDER = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query referralCodePasswordFinder($CODE: String!, $PASSWORD: String!, $include: [Include]){
    referralCodePasswordFinder(CODE:$CODE, PASSWORD:$PASSWORD, include: $include){
      _id
      ${FULL_MUTATION_FIELDS(["user","coupon"])}
    }
  }`



const QUERY_SINGLE_FULL = ()=> gql`
  query GetRef($id: String){
    referral(
      id:$id, 
      include: [
        {
          name: "user"
        },
        {
          name: "coupon"
        }
      ]
    ){
      _id
      ${FULL_MUTATION_FIELDS(["user","coupon"])}
    }
  }`;



const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    referrals(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const QUERYFULL = ()=>QUERY(FULL_MUTATION_FIELDS(["coupon"]));


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    referralCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    referrals(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const QUERYANDCOUNTLISTFULL = ()=>QUERYANDCOUNT(FULL_MUTATION_FIELDS(["user","coupon"]));


const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    referralCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;



const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    referralDistinct(field:$field)
  }
`;







const QUERIES = {
  all: QUERY,
  allFull: QUERYFULL,
  allAndCount: QUERYANDCOUNT,
  allAndCountListFull: QUERYANDCOUNTLISTFULL,
  count: COUNT,
  single: QUERY_SINGLE,
  singleFull: QUERY_SINGLE_FULL,
  distinct: QUERY_GET_DISTINCT,
  referralCodeFinder: QUERY_REFERRAL_CODE_FINDER,
  referralCodePasswordFinder: QUERY_REFERRAL_CODE_PASSWORD_FINDER
}


export default QUERIES;