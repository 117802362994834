import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import QUERIES from "./QUERIES";
//import MUTATIONS from "./MUTATIONS";
//import ImageUtils from "global/utils/image-utils";
//const IMAGE_UTILS =  new ImageUtils();



const FORM_FIELDS = (obj={}) => {
  const QUERY_GET_DISTINCT = QUERIES.distinct();
  //const MUTATION_EDIT = MUTATIONS.update();
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">ID CANT BE CHANGED</span></div>,
        type: "text",
        name: "id",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "_id",
        hide: true
      },
      {
        title: <div className="formTitle"><span className="">From</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Where this message is from</span></div>,
        type: "text",
        name: "from",
        defaultValue: "",
        required: true,
        defaultValuePath: "from",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">To</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Where this message went to</span></div>,
        type: "text",
        name: "to",
        defaultValue: "",
        required: true,
        defaultValuePath: "to",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Type</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What sort of communication was it?</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "type" //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {
            //console.log("result.data","\n\n",result.data,"\n\n");
            var fullOptionList = result.data.comDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "EMAIL",
                "title": "EMAIL"
              },
              {
                "value": "PHONE",
                "title": "PHONE"
              },
              {
                "value": "TEXT_MESSAGE",
                "title": "TEXT_MESSAGE"
              },
              {
                "value": "IN_PERSON",
                "title": "IN_PERSON"
              },
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            callBack("options", uniqueArray)
          });
        },
        name: "type",
        required: true,
        defaultValue: "",
        defaultValuePath: "type",
        errorMessage: "This is required"
      },
      // {
      //   title: <div className="formTitle"><span className="">Lead came from</span></div>,
      //   subTitle: <div className="formSubTitle"><span className="">What sort of communication was it?</span></div>,
      //   type: "selectAPIWithAdd",
      //   onMount: (callBack) => {
      //     window.Client.query({
      //       query: QUERY_GET_DISTINCT,
      //       variables: {
      //         field: "lead" //FIELD TO GET ALL OPTIONS
      //       },
      //       fetchPolicy: "no-cache"
      //     })
      //     .then(result => {
      //       //console.log("result.data","\n\n",result.data,"\n\n");
      //       var fullOptionList = result.data.comDistinct.map((DisOption, index)=>{
      //         return({
      //           "value": DisOption,
      //           "title": DisOption
      //         })
      //       });
      //       var DefaultValues = [
      //         {
      //           "value": "",
      //           "title": "Choose one",
      //           "disabled": true
      //         },
      //         {
      //           "value": "ORGANIC",
      //           "title": "ORGANIC"
      //         }
      //       ];
      //       const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
      //       callBack("options", uniqueArray)
      //     });
      //   },
      //   name: "type",
      //   required: true,
      //   defaultValue: "",
      //   defaultValuePath: "type",
      //   errorMessage: "This is required"
      // },
      {
        title: <div className="formTitle"><span className="">Language</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What sort of communication was it?</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "type" //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {
            //console.log("result.data","\n\n",result.data,"\n\n");
            var fullOptionList = result.data.comDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "ENGLISH",
                "title": "ENGLISH"
              },
              {
                "value": "ESPAÑOL",
                "title": "ESPAÑOL"
              }
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            callBack("options", uniqueArray)
          });
        },
        name: "language",
        required: true,
        defaultValue: "",
        defaultValuePath: "language",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Full Notes</span></div>,
        subTitle: <div className="formSubTitle"><span className="">General info about this communication can go here. Like when is best to contact, etc.</span></div>,
        type: "textArea",
        name: "notes",
        rows: 6,
        defaultValue: "",
        defaultValuePath: "notes",
      },
      {
        title: <div className="formTitle"><span className="">Communication History</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Any Action you've taken should go here.</span></div>,
        type: "notesList",
        name: "notesList",
        defaultValue: "",
        required: false,
        defaultValuePath: "notesList",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Message</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Message that shows up for this product.</span></div>,
        type: "textArea",
        name: "message",
        rows: 6,
        defaultValue: "",
        defaultValuePath: "message",
      },
      {
        title: <div className="formTitle"><span className="">Email</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Optional email address if they want to be emailed back</span></div>,
        type: "email",
        name: "email",
        rows: 6,
        defaultValue: "",
        defaultValuePath: "email",
      },
      {
        title: <div className="formTitle"><span className="">Phone</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Optional phone number if they want to be called back</span></div>,
        type: "phone",
        name: "phone",
        rows: 6,
        defaultValue: "",
        defaultValuePath: "phone",
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose a Status for your Product. Active means it'll go live.</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: true,
        defaultValue: "ACTIVE",
        defaultValuePath: "status",
        errorMessage: "Status is required"
      }
    ]
  )
};






export default FORM_FIELDS;