//import React from 'react';
const PAGE = [
  {
    "content": `Drag 'n' drop some files here, or click to select files`
  },
  {
    "content": `Current Files`
  }
];




  
export default PAGE;