import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';

  

const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    commissions(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    commissionCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    commissions(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
   ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    commissionCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query Single($id: String){
    commission(id:$id){
      _id
      ${whatToPull}
    }
  }
`;



const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    commissionDistinct(field:$field)
  }
`;


const QUERY_GET_COMMISSIONS_BY_USER = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query CommissionsByUser($id: String){
    # commissionDistinct(field:"UID")
    commissionsByUser(id:$id){
      user{
        firstName
        lastName
        email
        UID
      }
      EARNED
      commissionsCount
      commissions{
        ${whatToPull}
      }
    }
  }
`;

const QUERY_GET_COMMISSIONS_BY_USERS = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query CommissionsByUsers(${DEFAULT_QUERY_INPUT_VAR}){
    # commissionDistinct(field:"UID")
    commissionCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    commissionsByUsers(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      user{
        firstName
        lastName
        email
        UID
      }
      EARNED
      commissionsCount
 
    }
  }
`;

const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  count: COUNT,
  single: QUERY_SINGLE,
  distinct: QUERY_GET_DISTINCT,
  commissionsByUser: QUERY_GET_COMMISSIONS_BY_USER, //SINGLE
  commissionsByUsers: QUERY_GET_COMMISSIONS_BY_USERS //GET ALL
}


export default QUERIES;