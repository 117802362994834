import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/getstarted.scss';
import HeaderImage from 'website/components/HeaderImage/index';
import TemplatePage from 'website/templates/templatePage/index.js';
import CONTENTS from "./CONTENTS";
import {NavLink, Link} from 'react-router-dom';
import Step1 from 'global/img/steps/Steps1.jpg';
// import Step1_400 from 'global/img/steps/Steps1_400.jpg';
import Step1_500 from 'global/img/steps/Steps1_500.jpg';

import Step2 from 'global/img/steps/Steps2.jpg';
// import Step2_400 from 'global/img/steps/Steps2_400.jpg';
import Step2_500 from 'global/img/steps/Steps2_500.jpg';

import Step3 from 'global/img/steps/Steps3.jpg';
// import Step3_400 from 'global/img/steps/Steps3_400.jpg';
import Step3_500 from 'global/img/steps/Steps3_500.jpg';

import Step4 from 'global/img/steps/Steps4.jpg';
// import Step4_400 from 'global/img/steps/Steps4_400.jpg';
import Step4_500 from 'global/img/steps/Steps4_500.jpg';

import Step5 from 'global/img/steps/Steps5.jpg';
// import Step5_400 from 'global/img/steps/Steps5_400.jpg';
import Step5_500 from 'global/img/steps/Steps5_500.jpg';

import Step6 from 'global/img/steps/Steps6.jpg';
// import Step6_400 from 'global/img/steps/Steps6_400.jpg';
import Step6_500 from 'global/img/steps/Steps6_500.jpg';


// import HIW_Image1 from 'global/img/HIW_Image/HIW_Image1.jpg';
// import HIW_Image2 from 'global/img/HIW_Image/HIW_Image2.jpg';

import Main1_500 from 'global/img/squareGirlDaughter_500.jpg';
// import API_CALLS from 'API_CALLS/index';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';

//const QUERY = API_CALLS.PRODUCTS.QUERIES.all();
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      products: []
    };    
    this.ClientEvent =  window.GlobalUtil.subscribeEvent("ClientEvent", (newValue)=>{ //WAIT UNTIL window.Client EXIST
      if(this.LoopRef) this.LoopRef.abort();
      this.LoopRef = window.GlobalUtil.retryLoop(this.getProducts, 6, 500, this.onFailed);
    });
    this.onFailed = this.onFailed.bind(this);
    this.getProducts = this.getProducts.bind(this);
  }

  didMount(){          
    if(window.Client && window.Client.query) this.LoopRef = window.GlobalUtil.retryLoop(this.getProducts, 6, 500, this.onFailed);
  }

  willUnmount(){
    this.LoopRef.abort();
    this.ClientEvent.unsubscribe();
  }

  onFailed(){
    this.setState({curStatus: "failed", loading: false});
  }

  getProducts = async () => {
    return true; //FOR NOW DON'T GET PRODUCTS JUST HAVE THEM CONTACT US
    // if(!window.Client || !window.Client.query) return false;
    // return await window.Client.query({
    //   query: QUERY,
    //   variables: {
    //     type: "NORMAL",
    //     manual: [
    //       {
    //         path: "language",
    //         val: window.Session.Language
    //       },
    //       {
    //         path: "ref",
    //         val: "IMPRESSION_KIT"
    //       }
    //     ],
    //     status: "ACTIVE",
    //   }
    // })
    // .then(result => {
    //   console.log("result");
    //   console.log(result,"\n\n");
    //   //if(!this._ismounted) return;
    //   this.setState({products: result.data.products, loading: false});
    //   return true;      
    // })
    // .catch((error)=>{
    //   console.log("catch ", error);
    //   return false;     
    // })
  }


  render(){
    var {products=[]} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    const Product = products[0];
    const ImageURL = window.GlobalUtil.deepGetFromString(Product, "images,0,url", null);
    return (
      <div className="contentPage" id="PageGetStarted">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
          {/*<title>My Title</title>*/}
        <div id="TopMainSection" className="containerSection backgroundSVG3">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="bigPicture">
                  <HeaderImage
                    img={Main1_500}
                    alt={CONTENT.TopMainSectionImage[0].alt}
                    //right={true}
                  />
                </div>
              </Column>
              <Column className="contentCenter topTextZone" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                <div className="">
                  {CONTENT.TopMainSection[0]}
                </div>
              </Column>
            </Row>
          </Container>
        </div>
     
        <div id="FullSteps" className="containerSection ">
          <div className="backgroundSVG1">
            <Container className="noPaddingContainer">
              <Row className="">
                <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="">
                    {CONTENT.FullSteps[0]}
                  </div>
                </Column>
              </Row>
              {/*STEP 1*/}
              <Row className="stepRow"  id="Step1">
                <Column className="imageColumn contentCenter">
                  <div className="">
                    <img 
                      src={Step4}
                      alt={CONTENT.Step1Image[0].alt}
                      srcSet={`
                        ${Step4_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                </Column>
                <Column className="contentCenter textZone">
                    {CONTENT.Step1[0]}
                </Column>
              </Row>
            </Container>
          </div>

          <div className="">
            <Container className="noPaddingContainer">
              {/*STEP 2*/}
              <Row className="stepRow"  id="Step2">
                <Column className="imageColumn contentCenter d-flex-800">
                  <div className="">
                    <img 
                      src={Step1}
                      alt={CONTENT.Step2Image[0].alt}
                      srcSet={`
                        ${Step1_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                </Column>
                <Column className="contentCenter textZone textZoneLeft">
                    {CONTENT.Step2[0]}
                </Column>
                <Column className="imageColumn contentCenter d-none-800">
                  <div className="">
                    <img 
                      src={Step1}
                      alt={CONTENT.Step2Image[0].alt}
                      srcSet={`
                        ${Step1_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                </Column>
              </Row>
            </Container>
          </div>



          <div className="backgroundSVG2">
            <Container className="noPaddingContainer">
              {/*STEP 3*/}
              <Row className="stepRow"  id="Step3">
                <Column className="imageColumn contentCenter">
                  <div className="">
                    <img 
                      src={Step2}
                      alt={CONTENT.Step3Image[0].alt}
                      srcSet={`
                        ${Step2_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                </Column>
                <Column className="contentCenter textZone">
                    {CONTENT.Step3[0]}
                </Column>
              </Row>
              
            </Container>
          </div>



          <div className="">
            <Container className="noPaddingContainer">
              {/*STEP 4*/}
              <Row className="stepRow"  id="Step4">
                <Column className="imageColumn contentCenter d-flex-800">
                  <div className="">
                    <img 
                      src={Step3}
                      alt={CONTENT.Step4Image[0].alt}
                      srcSet={`
                        ${Step3_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                </Column>
                <Column className="contentCenter textZone textZoneLeft">
                    {CONTENT.Step4[0]}
                </Column>
                <Column className="imageColumn contentCenter d-none-800">
                  <div className="">
                    <img 
                      src={Step3}
                      alt={CONTENT.Step4Image[0].alt}
                      srcSet={`
                        ${Step3_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                  <br/>
                </Column>
              </Row>
              
            </Container>
          </div>



          <div className="backgroundSVG5">
            <Container className="noPaddingContainer">
              {/*STEP 5*/}
              <Row className="stepRow"  id="Step5">
                <Column className="imageColumn contentCenter">
                  <div className="">
                    <img 
                      src={Step5}
                      alt={CONTENT.Step5Image[0].alt}
                      srcSet={`
                        ${Step5_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                </Column>
                <Column className="contentCenter textZone">
                    {CONTENT.Step5[0]}
                </Column>
              </Row>
            </Container>
          </div>


          <div className="">
            <Container className="noPaddingContainer">
              {/*STEP 6*/}
              <Row className="stepRow"  id="Step6">
                <Column className="imageColumn contentCenter d-flex-800">
                  <div className="">
                    <img 
                      src={Step6}
                      alt={CONTENT.Step6Image[0].alt}
                      srcSet={`
                        ${Step6_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                </Column>
                <Column className="contentCenter textZone textZoneLeft">
                    {CONTENT.Step6[0]}
                </Column>
                <Column className="imageColumn contentCenter d-none-800">
                  <div className="">
                    <img 
                      src={Step6}
                      alt={CONTENT.Step6Image[0].alt}
                      srcSet={`
                        ${Step6_500} 500w
                      `}
                      sizes={`
                        (max-width: 800px) 500px, 
                        100vw
                      `}
                    />
                  </div>
                  <br/>
                </Column>
              </Row>
              
              <Row className="text-center"  id="FinalText">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="">
                    {CONTENT.FinalText[0]}
                    <div className="buttonGroup center">
                      <NavLink className={"button button2 btn-color5"} to={CONTENT.FinalTextButton[1]}>{CONTENT.FinalTextButton[0]}</NavLink>
                      {/* <button className={"button button2"} style={{"margin": "20px 5px 0"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT[23]}</button> */}
                    </div>           
                  </div>
                </Column>
              </Row>
            </Container>
          </div>
          <TreatmentPlanBar/>
          <div id="BuyNowProduct" style={{"fontSize": "0px"}}>Buy Now</div>
        </div>
      </div>
    );
  }
}





const Product = (props)=>{
  var {product={}} = props;
  return(
    <div className="product productLayout2">
      <div className="productImages">
        <div className="productImage">
          <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
        </div>
        {/*<div className="smollImages">
          {
            product.images && product.images.map((img, index)=>{
              if(!img) return null
              return(
                <div className="smollImage" key={index} >
                  <img src={img.url} alt=""/>
                </div>
              )
            })
          }
        </div>*/}
      </div>
      <div className="productText">
        <div className="productTextName">
          {product.name}
        </div>
        <div className="productTextDesc">
          {product.description}
        </div>
        <div className="productTextCost d-flex">
          Cost: {window.GlobalUtil.convertToMoney(product.cost-product.discount)} <small>{window.GlobalUtil.convertToMoney(product.cost)}</small>
        </div>
        <div className="productTextSave">
          You Save: {window.GlobalUtil.convertToMoney(product.discount)}
        </div>
        {/*<div className="">
          Category: {product.category}
        </div>*/}
        <div className="buttonGroup">
          <button className="button button1 dontCloseCart" onClick={()=>{
            window.GlobalUtil.addToCart({product: product, replaceAll: false}, ()=>{
              window.GlobalUtil.triggerEvent("cartEvent", "show");
            });
          }}>Add to Cart</button>
        </div>
      </div>
    </div>
  )
}  

export default Index;
