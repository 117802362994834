import React, { useState, useEffect, useRef } from "react";
import FormBuilder from 'global/components/FormBuilder/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import {
  APIProvider,
  useMapsLibrary
} from "@vis.gl/react-google-maps";

const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

const REQUIREDMESSAGE = ()=>{
  return(
    <span style={{"color":"red","fontSize":"9px","paddingLeft":"10px","paddingTop":"6px"}}><i className="fas fa-star-of-life" style={{"fontSize":"7px"}}  /> <span style={{"paddingLeft":"4px"}}>{window.Session.Language === "ENGLISH" ? "REQUIRED" : "REQUERIDA"}</span></span>
  )
}
const ERRORMESSAGE = (customMessage="This field is required before submitting")=>{
  return(
    <div style={{"color":"red","fontSize":"12px","paddingLeft":"10px","paddingTop":"6px"}}>{customMessage}</div>
  )
}

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValues ? window.GlobalUtil.formatAddress(this.props.initialValues) : "",
      curObj: {
        ...(this.props.initialValues ? this.props.initialValues : {})
      },
      showAddress: true
    };
    // this.currentObject = {
    //   ...(this.props.initialValues ? this.props.initialValues : {})
    // };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  onChangeAutoFill = (newValue) => {
    var {curObj, value} = this.state;
    const Address = newValue.formatted_address;
    if(Address){
      value = Address;
      const States = window.GlobalUtil.states();
      var labelArray = Address.split(", ");
      var stateAndZip = labelArray[2].split(" ");
      curObj.street = labelArray[0];
      curObj.city = labelArray[1];
      curObj.country = labelArray[3];
      curObj.state = States[stateAndZip[0]];
      curObj.zipcode = stateAndZip[1];
      this.setState({
        value,
        curObj,
        showAddress: false
      }, ()=>{
        this.setState({showAddress: true});
        if(this.props.onChange) this.props.onChange(curObj);
      });
    }
  } 

  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);      
    var {initialValues, phoneNumber} = this.props;
    var {curObj, value, showAddress} = this.state;
    const STATES = window.GlobalUtil.states();
    //var {data} = this.props;
    var ListOfFields = [
      // {
      //   title: <div className="formTitle"><span className="">{CONTENT[0]}</span></div>,
      //   type: "text",
      //   name: "street",
      //   //placeholder: "",
      //   required: true,
      //   requiredMessage: (REQUIREDMESSAGE()),
      //   errorMessage: (ERRORMESSAGE(CONTENT[1])),
      // },
      {
        title: <div className="formTitle"><span className="">{CONTENT[2]}</span></div>,
        type: "text",
        name: "street2",
        //placeholder: "",
        required: false,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[3])),
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[4]}</span></div>,
        type: "text",
        name: "city",
        //placeholder: "",
        required: true,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[5])),
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[6]}</span></div>,
        type: "select",
        name: "state",
        options: Object.keys(STATES).map(stateKey=>{return({title: STATES[stateKey], value: STATES[stateKey]})}),
        //placeholder: "",
        required: true,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[7])),
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
        subTitle: <small className="">{CONTENT[9]}</small>,
        type: "customText",
        name: "country",
        //placeholder: "",
        text: "USA",
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[10]}</span></div>,
        type: "zipcode",
        name: "zipcode",
        //placeholder: "",
        required: true,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[11])),
      }
    ]
    if(phoneNumber) ListOfFields.push({
      title: <div className="formTitle"><span className="">{CONTENT[12]}</span></div>,
      type: "phone",
      name: "phone",
      //placeholder: "",
      required: true,
      requiredMessage: (REQUIREDMESSAGE()),
      errorMessage: (ERRORMESSAGE(CONTENT[13])),
    })
    return(
      <div className="componentShippingForm">
        {
          showAddress
          && <React.Fragment>
              <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY}>
                <PlaceAutocomplete onPlaceSelect={this.onChangeAutoFill} defaultValue={curObj.street} />
              </APIProvider>
              <FormBuilder
                initialValues={curObj}
                listOfFields={ListOfFields}
                onChange={newObj =>{
                  //this.currentObject = newObj;
                  this.setState({curObj: newObj});
                  if(this.props.onChange) this.props.onChange(newObj);
                }}
                onValidate={(isValid)=>{
                  this.setState({isValid: isValid});
                  if(this.props.onValidate) this.props.onValidate(isValid);
                }}
              />
            </React.Fragment>
        }
      </div>
    )
  }
}



const PlaceAutocomplete = ({ onPlaceSelect, defaultValue }) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const [inputValue, setinputValue] = useState(defaultValue);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="form-group" style={{"marginBottom":"1.5rem"}}>
      <div>
        <div className="input-header">
          <div className="formTitle">
            <span style={{"fontWeight":"500","fontSize":"16px"}}>Street</span>
            <span style={{"color":"red","fontSize":"9px","paddingLeft":"10px","paddingTop":"6px"}}><i className="fas fa-star-of-life" style={{"fontSize":"7px"}}></i> <span style={{"paddingLeft":"4px"}}>REQUIRED</span></span>
          </div>
        </div>
        <input ref={inputRef} type="text" className="form-control" value={inputValue} onChange={e=>setinputValue(e.value)}/>
      </div>
    </div>
  );
};



export default Component;