import React, { useEffect, useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import API_CALLS from 'API_CALLS/index';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentType, setPaymentType] = useState("creditCard");

  const Changedpayment = (e) => {//UPDATE PAYMENT TYPE WHEN THEY CHANGE SOMETHING
    if(paymentType !== e.value.type) console.log("change event", e);
    setPaymentType(e.value.type);
  }

  //CREATE API ON SERVER TO SAVE TEMP CART, AND APPROVE CART SOLD
  const SaveCheckoutSession = async (clientSecret) => {
    const Variables = {
      ...props.tempCartObj,
      "CLNTSCRT": clientSecret,
      "paymentType": paymentType
    };
    await console.log("Variables", Variables.CLNTSCRT)
    await window.Client.mutate({
        mutation: API_CALLS.TEMP_CART.MUTATIONS.create(),
        variables: Variables//THIS IS IN THE STRIPE PAYMENT PAGE AND GET'S THE CURRENT ORDER PLUS A CLIENT SECRET ID (CLNTSCRT)
      })
      .then((obj) => {
        //window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/PaypalButtons/index.js`, NAME:"Success obj", CONTENT:[obj]});
        //onSuccess(obj.data.createOrder)
        return true;
      })
      .catch((error)=>{
        return false;
      });
  }


  const handleSubmit = async (event) => {
    setIsProcessing(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    //SAVE THE CHECKOUT WITH THE USER TO SOME PERSONAL DB
    //WHEN REDIRECT
    //IF SUCESS: THEN RUN API TO GET THAT CHECKOUT AND USER AND MARK AS PAID
    //IF FAIL: DO NOTHING
    //DELETE ALL OF THOSE PENDING CHECKOUTS EVERY SO OFTEN
    //console.log('elements._commonOptions.clientSecret.clientSecret', elements._commonOptions.clientSecret.clientSecret);
    await SaveCheckoutSession(elements._commonOptions.clientSecret.clientSecret);
    // console.log('elements', elements);
    // console.dir(elements);
  
    var confirmParams = {
      return_url: ((process.env.NODE_ENV === 'development') ? "http://localhost:3000/checkout/complete/" : `${window.location.origin}/checkout/complete/`),
      //return_url: `${window.location.origin}/paymentsuccess`,
    }
    if((paymentType === "affirm") || (paymentType === "afterpay_clearpay")) confirmParams.shipping = props.shipping; //IF AFFIRM OR afterpay_clearpay THEN ADD SHIPPING INFO
    const {error, newPaymentIntent} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: confirmParams,
      //redirect: "if_required"
    });

    if (error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log("stripe.confirmPayment error", error.message);
      if(error.code === "payment_intent_invalid_parameter"){
        alert("Your Shipping Address is Invalid.");
        setIsProcessing(false); //SHOULD STOP "PROCESSING" IF FAILED
      }
    } else {
      // await console.log("RUN THIS WHEN SUCCESS")
      // await window.GlobalUtil.delay(4000).then(()=>true)
      // await console.log("UPDATED USER TO SHOW PAYMENT WORKED: SUCCESS RAN")
      // setPaymentIntent(newPaymentIntent);
      // setIsProcessing(false);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  var disabled = ((!stripe || isProcessing) ? true : false);
  var buttonText = (isProcessing ? 'Processing...Please Wait' : 'Pay Now');
  //(paymentType === "affirm")
  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="sectionTitle" style={{"padding":"20px 0"}}>Payment Method:</div> */}
      <PaymentElement onChange={Changedpayment} />
      <button type="submit" className={`button button1 buttonFull ${disabled ? "disabled" : ""}`} disabled={disabled}>
        {buttonText}
      </button>
    </form>
  )
};

export default CheckoutForm;


// {
//   payment_method: {
//     // Optional but recommended
//     billing_details: {
//       email: emailInput.value,
//       name: nameInput.value,
//       address: {
//         line1: line1Input.value,
//         city: cityInput.value,
//         state: stateInput.value,
//         postal_code: postalCodeInput.value,
//         country: countryInput.value,
//       }
//     },

//   },

//   // Required
//   shipping: {
//     name: shippingNameInput.value,
//     address: {
//       line1: shippingLine1Input.value,
//       city: shippingCityInput.value,
//       state: shippingStateInput.value,
//       postal_code: shippingPostalCodeInput.value,
//       country: shippingCountryInput.value,
//     }
//   },
//   return_url: `${window.location.origin}/return.html`,
// }