import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import SignUp from 'global/components/SignUp/index';
import LoginButton from 'global/components/LoginButton/index';
import LoginWithGoogleButton from 'global/components/LoginWithGoogleButton/index';
import LoginWithFacebookButton from 'global/components/LoginWithFacebookButton/index';

//THIS SHOWS A SIGNUP BUTTONS AND FORM WITH A LOGIN BUTTON AT THE BOTTOM

const Index = (props) => {    
  var {CONTENT} = props;     
  return (
    <div id={props.id} className='loginForm'>
      <LoginWithGoogleButton
        buttonText={(CONTENT[0] ? CONTENT[0] :  "Continue with Google")} 
        autoCreate={true}
        onSuccess={props.onSuccess}
        onFail={props.onFail}
      />
      <LoginWithFacebookButton
        buttonText={(CONTENT[1] ? CONTENT[1] :  "Continue with Facebook")} 
        autoCreate={true}
        onSuccess={props.onSuccess}
        onFail={props.onFail}
      />
      <div className="orSpacer" style={{"display":"flex","borderBottom":"1px solid #ccc","alignItems":"center","justifyContent":"center", "margin": "20px 0"}}>
        <div style={{"background":"#FFF","width":"60px","textAlign":"center","marginBottom":"-10px"}}>{(CONTENT[2] ? CONTENT[2] :  "OR")}</div>
      </div>
      <SignUp 
        //{...propsInner}
        id=""
        className=""
        buttonText={(CONTENT[3] ? CONTENT[3] :  "Sign Up")}
        emailOrPasswordWrongMessage={(CONTENT[4] ? CONTENT[4] : (<p className="">{props.emailOrPasswordWrongMessage}</p>))} 
        onSuccess={props.onSuccess}
        onFail={props.onFail}
      />
      <div className="orSpacer" style={{"display":"flex","borderBottom":"1px solid #ccc","alignItems":"center","justifyContent":"center", "margin": "20px 0"}}>
        <div style={{"background":"#FFF","width":"60px","textAlign":"center","marginBottom":"-10px"}}>{(CONTENT[2] ? CONTENT[2] :  "OR")}</div>
      </div>
      <LoginButton {...props}/>
    </div>
  );
}


export default Index;