//import React from 'react';
const PAGE = [
	{
		"content": `Dashboard`
	},
  {
    "content": `Create: Patients' Requests`
  },
  {
    "content": `Creating: Patients' Plans`
  },
  {
    "content": `Approve: Patients' Plans`
  },
  {
    "content": `Unpaid: Patients`
  }
];




	
export default PAGE;