//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation UpdateGiveaway(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateGiveaway(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreateGiveaway(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createGiveaway(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteGiveaway(
    $id: String,
  ) {
    deleteGiveaway(id: $id)
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteGiveawayList(
    $ids: [String]
  ) {
    deleteGiveawayList(ids: $ids)
  }
`;


const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneGiveaway(
    $id: String,
  ) {
    cloneGiveaway(id: $id){
      _id
    }
  }
`;

const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneGiveawayList(
    $ids: [String]
  ) {
    cloneGiveawayList(ids: $ids)
  }
`;

const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateGiveaway(
    $id: String,
  ) {
    deactivateGiveaway(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateGiveawayList(
    $ids: [String]
  ) {
    deactivateGiveawayList(ids: $ids)
  }
`;



const MUTATIONS = {
  update: MUTATION_EDIT,
  create: MUTATION_ADD,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;