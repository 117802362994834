//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Use a promo code here to get your Clear Aligners EVEN CHEAPER!",
			"DESCRIPTION": "Got a promotional code from your favorite influencer? Use it here to get the full Straightening Treatments at a significant discounted price!",
			"KEYWORDS": "Promotional Code, Influencer Clear Aligners, Teeth Straightening Treatments, Smiles.Club, Smiles Club, clear treatment code, non-toxic aligners, risk free experience, flexible payment options, compare aligners, price compare"
		},
		"CONTENT": {
			"PageCheckout": [
				{
					"type": "div",
					"content": `<h1 className="sectionTitle">Checkout</h1>`
				}
			],
			"loading": [
				{
					"type": "div",
					"id": "StatusIcon",
					"content": `<i class="fas fa-spinner animated"></i>`
				},
				{
					"content": `Loading`
				},
				{
					"content": `Please wait a minute while we load your payment progress.`
				},
				{
					"content": `/`
				},
				{
					"content": `Home`
				}
			],
			"not_found": [
				{
					"type": "div",
					"id": "StatusIcon",
					"content": `<i class="fas fa-bomb"></i>`
				},
				{
					"content": `No Payment Found!`
				},
				{
					"content": `Looks like something went wrong`
				},
				{
					"content": `/`
				},
				{
					"content": `Home`
				}
			],
			"succeeded": [
				{
					"type": "div",
					"id": "StatusIcon",
					"content": `<i class="fas fa-smile-wink"></i>`
				},
				{
					"content": `Order Success!`
				},
				{
					"content": `Congratulations, You've successfully placed your order. Thank you for your purchase, you'll get an email confirmation soon. Have a good day`
				},
				{
					"content": `/`
				},
				{
					"content": `Home`
				}
			],
			"processing": [
				{
					"type": "div",
					"id": "StatusIcon",
					"content": `<i class="fas fa-search-dollar"></i>`
				},
				{
					"content": `Processing!`
				},
				{
					"content": `We'll finish processing the payment and send you a confirmation email once it goes through.`
				},
				{
					"content": `/`
				},
				{
					"content": `Home`
				}
			],
			"requires_payment_method": [
				{
					"type": "div",
					"id": "StatusIcon",
					"content": `<i class="fas fa-exclamation-triangle"></i>`
				},
				{
					"content": `Error: Requires Payment Method`
				},
				{
					"content": `I'm sorry but it looks like we are having payment problems. Please try again later.`
				},
				{
					"content": `/`
				},
				{
					"content": `Home`
				}
			],
			"failed": [
				{
					"type": "div",
					"id": "StatusIcon",
					"content": `<i class="fas fa-exclamation-circle"></i>`
				},
				{
					"content": `Error: Oh No!`
				},
				{
					"content": `We may be experiancing technical problems, please wait a few minutes and try again. Thank you!`
				},
				{
					"content": `/`
				},
				{
					"content": `Home`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Use un código de promoción aquí para obtener sus alineadores transparentes AÚN MÁS BARATOS",
			"DESCRIPTION": "¿Tienes un código promocional de uno de tus influencers favoritos? ¡Úselo en este sitio web para obtener los tratamientos de alisado completos a un precio con un descuento significativo!",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"PageCheckout": [
				{
					"type": "div",
					"content": `<h1 className="sectionTitle">La Caja</h1>`
				}
			]
		}
	}
};


	
export default PAGE;


