import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import USERS from "../USERS/index";
import ORDERS from "../ORDERS/index";
// import QUERIES from "./QUERIES";
import MUTATIONS from "./MUTATIONS";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();



const FORM_FIELDS = (obj={})=>{
  const QUERY_GET_ORDERS = ORDERS.QUERIES.all(`UID`);
  const MUTATION_EDIT = MUTATIONS.update();
  const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  return [
    {
      title: <div className="formTitle"><span className="">ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Product ID CANT BE CHANGED</span></div>,
      type: "text",
      name: "id",
      required: false,
      editable: false,
      defaultValue: "",
      defaultValuePath: "_id",
      hide: true
    },
    {
      title: <div className="formTitle"><span className="">User ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every task</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_USERS
        })
        .then(result => {
          var Options = result.data.users.map((user, index)=>{
            return({
              "value": user._id,
              "title": user.email
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...Options])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "UID",
      required: true,
      defaultValue: "",
      defaultValuePath: "UID",
      errorMessage: "User ID is required"
    },
    {
      title: <div className="formTitle"><span className="">Order ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Should have an user attached to every task</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_ORDERS
        })
        .then(result => {
          var Options = result.data.orders.map((user, index)=>{
            return({
              "value": user._id,
              "title": user._id
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...Options])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "OID",
      required: false,
      defaultValue: "",
      defaultValuePath: "OID"
    },
    {
      title: <div className="formTitle"><span className="">Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "PENDING",
          "title": "PENDING"
        },
        {
          "value": "PAUSED",
          "title": "PAUSED"
        },
        {
          "value": "CANCELED",
          "title": "CANCELED"
        }
      ],
      required: false,
      defaultValue: "ACTIVE",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Laboratory</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Who is creating this treatment plan?</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.USERS.QUERIES.all("email firstName lastName"),
          variables: {
            "status": "ACTIVE",
            "manual": [
              {
                path: "role",
                listValues: JSON.stringify(["LABORATORY", "ADMIN"])
              }
            ]
          }
        })
        .then(result => {
          var doctors = result.data.users.map((doc, index)=>{
            return({
              "value": doc._id,
              "title": `${doc.firstName} ${doc.lastName} - ${doc.email}`
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...doctors])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "LABORATORY",
      required: false,
      defaultValue: "",
      defaultValuePath: "LABORATORY",
      errorMessage: "Who to email is required"
    },
    {
      title: <div className="formTitle"><span className="">Approving Doctor</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What doctor will approve this once it's been uploaded by the lab?</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.USERS.QUERIES.all("email firstName lastName"),
          variables: {
            "status": "ACTIVE",
            "manual": [
              {
                path: "role",
                listValues: JSON.stringify(["DOCTOR", "ADMIN"])
              }
            ]
          }
        })
        .then(result => {
          var doctors = result.data.users.map((doc, index)=>{
            return({
              "value": doc._id,
              "title": `${doc.firstName} ${doc.lastName} - ${doc.email}`
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...doctors])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "DOCTOR",
      required: false,
      defaultValue: "",
      defaultValuePath: "DOCTOR",
      errorMessage: "Who to email is required"
    },
    {
      title: <div className="formTitle"><span className="">Number of Top Aligners</span></div>,
      subTitle: <div className="formSubTitle">The total number of top aligners the customer will get.</div>,
      type: "number",
      min: 1,
      max: 1000,
      name: "alignersSchedule,numOfAlignersTop",
      placeholder: 0,
      required: false,
      defaultValue: 0,
      defaultValuePath: "alignersSchedule,numOfAlignersTop"
    },
    {
      title: <div className="formTitle"><span className="">Number of Bottom Aligners</span></div>,
      subTitle: <div className="formSubTitle">The total number of bottom aligners the customer will get.</div>,
      type: "number",
      min: 1,
      max: 1000,
      name: "alignersSchedule,numOfAlignersBottom",
      placeholder: 0,
      required: false,
      defaultValue: 0,
      defaultValuePath: "alignersSchedule,numOfAlignersBottom"
    },
    {
      title: <div className="formTitle"><span className="">Notes</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Notes for this plan.</span></div>,
      type: "textArea",
      name: "notes",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "notes",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Step</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What step is the Treatment Plan at?</span></div>,
      type: "select",
      name: "step",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "REQUEST",
          "title": "REQUEST"
        },
        {
          "value": "UPLOAD",
          "title": "UPLOAD"
        },
        {
          "value": "APPROVE",
          "title": "APPROVE"
        },
        {
          "value": "PURCHASE",
          "title": "PURCHASE"
        },
        {
          "value": "REQUEST_FULL",
          "title": "REQUEST_FULL"
        },
        {
          "value": "UPLOAD_ALIGNERS",
          "title": "UPLOAD_ALIGNERS"
        },
        {
          "value": "SCHEDULE_ALIGNERS",
          "title": "SCHEDULE_ALIGNERS"
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "FINISHED",
          "title": "FINISHED"
        }
      ],
      required: false,
      defaultValue: "",
      defaultValuePath: "step"
    },
    {
      title: <div className="formTitle"><span className="">Reject Step Notes</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Notes for why this current step was rejected.</span></div>,
      type: "textArea",
      name: "rejectStepNotes",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "rejectStepNotes",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Plan Files</span></div>,
      type: "fileUploadList",
      name: "planFiles",
      maxNumber: 10,
      required: false,
      defaultValue: "",
      defaultValuePath: "planFiles",
      maxWidth: "150px",
      filePath: [
        "USERS",
        obj.id,
        "TREATMENT_PLANS",
        obj.TPID,
        "FILES"
      ],
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "planFiles", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain()
        })
    },
    {
      title: <div className="formTitle"><span className="">Duration</span></div>,
      subTitle: <div className="formSubTitle">THIS WILL AUTO GENORATE WHEN THE USER BUYS THE PRODUCT AND FINISH WHEN WE FINISH.</div>,
      type: "conditional",
      condition: (curObj)=>{            
        //if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
        return true;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">Start At</span></div>,
          subTitle: <div className="formSubTitle">When this started after customer paid for it.</div>,
          type: "datePicker",
          min: 0,
          max: 1000,
          name: "duration,startAt",
          placeholder: 0,
          required: false,
          defaultValue: 0,
          defaultValuePath: "duration,startAt"
        },
        {
          title: <div className="formTitle"><span className="">End At</span></div>,
          subTitle: <div className="formSubTitle">When the customer has finished paying for everything.</div>,
          type: "datePicker",
          min: 0,
          max: 1000,
          name: "duration,endAt",
          placeholder: 0,
          required: false,
          defaultValue: 0,
          defaultValuePath: "duration,endAt"
        }
      ]
    },
  ]
};






export default FORM_FIELDS;