import React, {useCallback, useEffect} from 'react';
import DialogWrapper from 'global/components/DialogWrapper/index';
import Logo from 'global/components/Logo/index';
import CONTENTS from "./CONTENTS";
import MouthMovement from 'global/img/mouthMoving/Faster_Mouth_Movement.webp';
import happyAligners1 from 'global/img/squareGirlAlignerHappy_500.jpg';
import socialImage from 'global/img/socialImage_500.png';
import LayoutBuilder from 'LayoutBuilder';



const {Container, Row, Column} = LayoutBuilder;

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    var {noDialog} = this.props;
    var {isValid=false} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    
    //var {data} = this.props;
    return(
      <div className="componentContactForm">
        <DialogWrapper
          ref={e => this.DialogWrapperRef = e}
          styles={{
            "buttonGroup": {
              "flex": "1",
              "textAlign": "right"
            }
          }}
          dontScrollToTop={true}
          clickToCloseOverlay={true}
          renderToBody={true}
          containerId={"AreYouSurePopup"}
          darkBackground={true}
          headerText={""}
          width="1000"
          height="1000"
          onCancel={this.props.onClose}
          customSubmitButton={(<button className={`button button1 button-accept`} onClick={()=>this.props.onSubmit()}>Add to Cart</button>)}
          //acceptButtonText="Submit"
          //acceptButtonEnabled={true}
        >
          <div className="areYouSurePopup">
            <Container id="SimpleStepsHeaderContainer" className="simpleStepsHeader">
              <Row id="HeaderText">
                <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <div className="column1 textZone">
                    <div className="title">About Our Treatment</div>
                  </div>
                </Column>
              </Row>
              
              <Row className="row1">
                <Column className="" col="12" xs="12" sm="12" md="12" lg="7" xl="7">
                  <div className="column1 textZone">
                    <div className="title">Risk Free!</div>
                    <div className="subTitle">We’ll give you a <strong>FULL</strong> refund if you're not a candidate</div>
                  </div>
                </Column>
                <Column className="" col="12" xs="12" sm="12" md="12" lg="5" xl="5">
                  <div className="column2">
                    <img 
                      id="areYouSurePopupImage1"
                      src={socialImage} 
                      alt="Clear Aligner Doctor holding tablet"
                    />
                  </div>
                </Column>
              </Row>

              <Row className="row2">
                <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <div className="column1">
                    <div className="subTitle">Get your Clear Aligners NOW! Pay later!</div>
                  </div>
                </Column>
              </Row>

              <Row className="row3">
                <Column className="" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                  <div className="column1">
                    <img 
                      id="areYouSurePopupImage2"
                      src={happyAligners1} 
                      alt="Clear Aligner Doctor holding tablet"
                    />
                  </div>
                </Column>
                <Column className="" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                  <div className="column2 textZone">
                    <div className="title">Flexible Pricing Options!</div>
                    <div className="subTitle">Time and flexibility are on your side. With Affirm, pay at a pace that works for you. Up to 36-month payment plans.</div>
                    <div className="subTitle2">About $75 - $85 per month*</div>
                    <div className="miniText">* depending on PayPal's option you choose, length of financing, interest rates, etc.</div>
                  </div>
                </Column>
              </Row>
              
              <Row className="row4">
                <Column className="" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                  <div className="column1 textZone">
                    <div className="title">New smile preview!</div>
                    <div className="subTitle">After we receive your impression, we'll give you a 3D preview of your entire smile transformation — so you can get a glimpse of your new Smile!</div>
                  </div>
                </Column>
                <Column className="" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                  <div className="column2">
                    <img 
                      id="areYouSurePopupImage3"
                      src={MouthMovement} 
                      alt="Clear Aligner Doctor holding tablet"
                    />
                  </div>
                </Column>
              </Row>
            </Container>
          </div>
        </DialogWrapper>
      </div>
    )
  }
}


export default Component;