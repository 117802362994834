import React, { useState } from 'react';
import IconImage from 'global/img/logo_icon.svg';

const INLINE_STYLES_DEFAULT = {
  LoadingScreen: {},
  overlay: {},
  loadingScreen: {},
  loadingIcon: {},
  loadingImage: {},
  loadingHeader: {},
  loadingBody: {},
};


function Loading (props){
  var {header="Loading...", body="Please stand by", floating, overlay, inlineStyles={}} = props;
  inlineStyles = {...INLINE_STYLES_DEFAULT, ...inlineStyles}
  return (
    <div id="LoadingScreen" className={`animated fadeIn ${floating ? "floating" : ""}`} style={inlineStyles.LoadingScreen}>
      {overlay && <div className="overlay" style={inlineStyles.overlay}></div>}
      <div className="loadingScreen" style={inlineStyles.loadingScreen}>
        <div className="loadingIcon" style={inlineStyles.loadingIcon}>
          <img className="loadingImage" src={IconImage} alt="" style={inlineStyles.loadingImage}/>
        </div>
        <h3 className="loadingHeader" style={inlineStyles.loadingHeader}>{header}</h3>
        <p className="loadingBody" style={inlineStyles.loadingBody}>{body}</p>
      </div>
    </div>
  );
}


export default Loading;