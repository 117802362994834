//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Use a promo code here to get your Clear Aligners EVEN CHEAPER!",
			"DESCRIPTION": "Got a promotional code from your favorite influencer? Use it here to get the full Straightening Treatments at a significant discounted price!",
			"KEYWORDS": "Promotional Code, Influencer Clear Aligners, Teeth Straightening Treatments, Smiles.Club, Smiles Club, clear treatment code, non-toxic aligners, risk free experience, flexible payment options, compare aligners, price compare"
		},
		"CONTENT": {
			"PageCheckout": [
				{
					"type": "div",
					"content": `<h1 className="sectionTitle">Checkout</h1>`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Use un código de promoción aquí para obtener sus alineadores transparentes AÚN MÁS BARATOS",
			"DESCRIPTION": "¿Tienes un código promocional de uno de tus influencers favoritos? ¡Úselo en este sitio web para obtener los tratamientos de alisado completos a un precio con un descuento significativo!",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"PageCheckout": [
				{
					"type": "div",
					"content": `<h1 className="sectionTitle">La Caja</h1>`
				}
			]
		}
	}
};


	
export default PAGE;


