//import React from 'react';
const PAGE = {
  "ENGLISH": {
    "HEADER": {
      "BASEURL": "https://smiles.club",
      "TITLE": "Smiles Club | Clear aligners made of premium materials without harmful toxins",
      "DESCRIPTION": "Which clear aligners are the best? Smiles Club's! Safest USA made clear aligners with no hidden fees. Risk free orthodontics.",
      "KEYWORDS": "Best in Clear Aligners, Teeth Straightening Treatments, Smiles.Club, Smiles Club, clear treatment, state-of-the-art Clear Braces, risk free experience, flexible payment options, compare aligners, price compare"
    },
    "CONTENT": {
      "Title": [
        {
          "type": "div",
          "content": `<h1 class="pageHeader"><i class="fas fa-user-astronaut"></i> Affiliate Program</h1>`
        }
      ],
      "HeaderPaymentInfo": [
        {
          "content": `Conversions`
        },
        {
          "content": `Commissions`
        }
      ],
      "PromoCodesLoading": [
        {
          "content": `Loading`
        },
        {
          "content": `Loading Changes`
        }
      ],
      "PromoCodes": [
        {
          "content": `Promo Codes`
        },
        {
          "content": `List of codes with names, quick summary, how much they make, a button to read more, and a button to edit users code.`
        }
      ],
      "PromoCodeTable": [
        {
          "content": `Code`
        },
        {
          "content": `Coupon`
        },
        {
          "content": `Summary`
        },
        {
          "content": `Commission`
        },
        {
          "content": `Edit`
        },
        {
          "content": `No Affiliates Codes have been found`
        }
      ],
      "EditPromoCode": [
        {
          "content": `Change Promo Code` //SLIDE OUT TITLE
        },
        {
          "content": `Cancel` //SLIDE OUT BUTTON TEXT
        },
        {
          "content": `Save` //SLIDE OUT BUTTON TEXT
        },
        {
          "content": `Promo Info:`
        },
        {
          "content": `Commission:`
        },
        {
          "type": "div",
          "className": "warningText",
          "content": `<strong>Warning:</strong><span> Once your code has been changed your old code may be taken by someone else.</span>`
        }
      ],
      "EditCodeFormFields": [
        {
          "content": `Edit Promo Code`
        },
        {
          "content": `Change text below to edit your promotion code.`
        },
        {
          "content": `THIS CODE IS BEING USED`
        }
      ],
      "AffiliatesWhy": [
        {
          "content": `Why Become an Affiliate?`
        },
        {
          "content": `Passive Income:`
        },
        {
          "content": `Affiliates can earn money while they sleep, as their promotional efforts can continue to generate revenue.`
        },
        {
          "content": `Great Commissions:`
        },
        {
          "content": `Our affiliates can make $350 OR MORE on some commissions. Earn great commission promoting great products.`
        },
        {
          "content": `Flexibility:`
        },
        {
          "content": `Affiliates can choose the products to promote and which code to advertise. Each code has a different discount for the customer and different commission for you.`
        },
        {
          "content": `Low Entry Barrier:`
        },
        {
          "content": `Unlike some other companies, we don't only work with Influencers who have millions of followers. We love working with smaller influencers and others too.`
        }
      ],
      "JoinAffiliates": [
        {
          "content": `Join our Affiliates`
        },
        {
          "type": "div",
          "className": "process",
          "content": `<span>Sign-up</span> <i class="fas fa-arrow-right"></i> <span>Promote</span>  <i class="fas fa-arrow-right"></i>  <span>Track</span>  <i class="fas fa-arrow-right"></i>  <span>Earn Commission</span> `
        },
        {
          "content": `Are you ready to join our affiliates program and start making money?`
        },
        {
          "content": `Your Request has been Sent!`
        }
      ],
      "EditShipping": [
        {
          "content": `Send Request` //SLIDE IN HEADER TEXT
        },
        {
          "content": `Required Information` //SLIDE IN HEADER TEXT
        },
        {
          "content": `Close` //SLIDE IN BUTTONS TEXT
        },
        {
          "content": `Submit` //SLIDE IN BUTTONS TEXT
        },
        {
          "type": "p",
          "className": "formInfo",
          "content": `All affiliates must have an address to ship any proceeds and a working phone number to use as contact in case of any problems. Please fill out and confirm the information below.` //SLIDE IN HEADER TEXT
        }
      ],
      "EditShippingForm": [
        {
          "content": `Street`
        },
        {
          "content": `Street address where things will be shipped`
        },
        {
          "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
        },
        {
          "content": `Street 2`
        },
        {
          "content": `You can use this for floor, suite, and unit numbers, etc.`
        },
        {
          "content": `City`
        },
        {
          "content": `In what city are you located`
        },
        {
          "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
        },
        {
          "content": `State`
        },
        {
          "content": `Please choose a state`
        },
        {
          "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
        },
        {
          "content": `Country`
        },
        {
          "content": `What country is this going to`
        },
        {
          "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
        },
        {
          "content": `Zipcode`
        },
        {
          "content": `Please include your zipcode too`
        },
        {
          "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
        },
        {
          "content": `Phone Number`
        },
        {
          "content": `Every user needs a phone number`
        },
        {
          "content": `Each user needs a Phone Number`
        }
      ],
      "UnpaidEarnings": [
        {
          "content": `Unpaid Commissions`
        },
        {
          "content": `All commission that haven't been paid yet. Once a check has been issue and sent through the mail, this value will reflect the new pending commissions. Please allow a few days for the check to arrive before filing a missing payment complaint.`
        }
      ],
      "UnpaidEarningsTable": [
        {
          "content": `Code`
        },
        {
          "content": `Conversion`
        },
        {
          "content": `Commission`
        },
        {
          "content": `No sales using our Affiliates Codes have been found`
        }
      ],
      "Chart": [
        {
          "content": `Reports`
        },
        {
          "content": `# Sales`
        },
        {
          "content": `TOTAL CONVERTED`
        }
      ]
    }
  },
  "ESPAÑOL": {
    "HEADER": {
      "BASEURL": "https://sonrisas.club",
      "TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
      "DESCRIPTION": "¿Qué alineadores transparentes son los mejores? ¡Club Sonrisas! Safest USA fabricó alineadores transparentes sin tarifas ocultas. Ortodoncia sin riesgos.",
      "KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
    },
    "CONTENT": {
      "Title": [
        {
          "type": "div",
          "content": `<h1 class="pageHeader"><i class="fas fa-user-astronaut"></i> Programa de Afiliación</h1>`
        }
      ],
      "HeaderPaymentInfo": [
        {
          "content": `Conversiones Mensuales`
        },
        {
          "content": `Comisiones Mensuales`
        }
      ],
      "PromoCodesLoading": [
        {
          "content": `Cargando`
        },
        {
          "content": `Cargando cambios`
        }
      ],
      "PromoCodes": [
        {
          "content": `Códigos Promocionales`
        },
        {
          "content": `Lista de códigos con nombres, resumen rápido, cuánto ganan, un botón para leer más y un botón para editar el código de los usuarios.`
        }
      ],
      "PromoCodeTable": [
        {
          "content": `Código`
        },
        {
          "content": `Cupón`
        },
        {
          "content": `Resumen`
        },
        {
          "content": `Comisión`
        },
        {
          "content": `Editar`
        },
        {
          "content": `No se han encontrado códigos de afiliados`
        }
      ],
      "EditPromoCode": [
        {
          "content": `Cambiar código promocional` //SLIDE OUT TITLE
        },
        {
          "content": `Cancelar` //SLIDE OUT BUTTON TEXT
        },
        {
          "content": `Ahorrar` //SLIDE OUT BUTTON TEXT
        },
        {
          "content": `Información promocional:`
        },
        {
          "content": `Comisión:`
        },
        {
          "type": "div",
          "className": "warningText",
          "content": `<strong>Advertencia:</strong><span> Una vez que se haya cambiado su código, otra persona puede tomar su código anterior.</span>`
        }
      ],
      "EditCodeFormFields": [
        {
          "content": `Editar código promocional`
        },
        {
          "content": `Cambie el texto a continuación para editar su código de promoción.`
        },
        {
          "content": `ESTE CÓDIGO ESTÁ SIENDO UTILIZADO`
        }
      ],
      "AffiliatesWhy": [
        {
          "content": `¿Por qué convertirse en afiliado?`
        },
        {
          "content": `Ingresos pasivos:`
        },
        {
          "content": `Los afiliados pueden ganar dinero mientras duermen, ya que sus esfuerzos promocionales pueden seguir generando ingresos.`
        },
        {
          "content": `Grandes Comisiones:`
        },
        {
          "content": `Nuestros afiliados pueden ganar $350 O MÁS con algunas comisiones. Gana grandes comisiones promocionando excelentes productos.`
        },
        {
          "content": `Flexibilidad:`
        },
        {
          "content": `Los afiliados pueden elegir los productos que promocionarán y qué código anunciar. Cada código tiene un descuento diferente para el cliente y una comisión diferente para ti.`
        },
        {
          "content": `Barrera de entrada baja:`
        },
        {
          "content": `A diferencia de otras empresas, no sólo trabajamos con Influencers que tienen millones de seguidores. Nos encanta trabajar con influencers más pequeños y con otros también.`
        }
      ],
      "JoinAffiliates": [
        {
          "content": `Únete a nuestros afiliados`
        },
        {
          "type": "div",
          "className": "process",
          "content": `<span>Registrarse</span> <i class="fas fa-arrow-right"></i> <span>Promocionar</span> <i class="fas fa-arrow-right"></ i> <span>Seguimiento</span> <i class="fas fa-arrow-right"></i> <span>Gane comisiones</span>`
        },
        {
          "content": `¿Estás listo para unirte a nuestro programa de afiliados y comenzar a ganar dinero?`
        },
        {
          "content": `¡Tu solicitud ha sido enviada!`
        }
      ],
      "EditShipping": [
        {
          "content": `Enviar petición` //SLIDE IN HEADER TEXT
        },
        {
          "content": `Información requerida` //SLIDE IN HEADER TEXT
        },
        {
          "content": `Cerca` //SLIDE IN BUTTONS TEXT
        },
        {
          "content": `Entregar` //SLIDE IN BUTTONS TEXT
        },
        {
          "type": "p",
          "className": "formInfo",
          "content": `Todos los afiliados deben tener una dirección para enviar los ingresos y un número de teléfono que funcione para utilizarlo como contacto en caso de cualquier problema. Por favor complete y confirme la información a continuación.` //SLIDE IN HEADER TEXT
        }
      ],
      "EditShippingForm": [
        {
          "content": `Calle`
        },
        {
          "content": `Dirección postal donde se enviarán las cosas.`
        },
        {
          "content": `Cada usuario necesita esto para el envío.` //ERROR INPUT MESSAGE
        },
        {
          "content": `Calle # 2`
        },
        {
          "content": `Puede utilizar esto para números de piso, suite y unidad, etc.`
        },
        {
          "content": `Ciudad`
        },
        {
          "content": `En que ciudad estas ubicado`
        },
        {
          "content": `Cada usuario necesita esto para el envío.` //ERROR INPUT MESSAGE
        },
        {
          "content": `Estado`
        },
        {
          "content": `Por favor elige un estado`
        },
        {
          "content": `Cada usuario necesita esto para el envío.` //ERROR INPUT MESSAGE
        },
        {
          "content": `País`
        },
        {
          "content": `¿A qué país va esto?`
        },
        {
          "content": `Cada usuario necesita esto para el envío.` //ERROR INPUT MESSAGE
        },
        {
          "content": `Código postal`
        },
        {
          "content": `Por favor incluya también su código postal`
        },
        {
          "content": `Cada usuario necesita esto para el envío` //ERROR INPUT MESSAGE
        },
        {
          "content": `Número de teléfono`
        },
        {
          "content": `Cada usuario necesita un número de teléfono`
        },
        {
          "content": `Cada usuario necesita un número de teléfono`
        }
      ],
      "UnpaidEarnings": [
        {
          "content": `Comisiones Impagas`
        },
        {
          "content": `Todas las comisiones que aún no han sido pagadas. Una vez emitido y enviado un cheque por correo, este valor reflejará las nuevas comisiones pendientes. Espere unos días hasta que llegue el cheque antes de presentar una queja por pago faltante.`
        }
      ],
      "UnpaidEarningsTable": [
        {
          "content": `Código`
        },
        {
          "content": `Conversión`
        },
        {
          "content": `Comisión`
        },
        {
          "content": `No se han encontrado ventas utilizando nuestros Códigos de Afiliados`
        }
      ],
      "Chart": [
        {
          "content": `Informes`
        },
        {
          "content": `# Ventas`
        },
        {
          "content": `TOTAL CONVERTIDO`
        }
      ]
    }
  }
};



export default PAGE;


