//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="pageHeader"><i class="fas fa-file-contract"></i><span>Treatment Plan</span></h1>`
	},
  {
    "content": `Your Personal Plan`
  },
  {
    "content": `The following is information concerning your personal unique treatment plan. Once your plan has been made you will be able to see a PDF of it below.`
  },
  {
    "content": `Treatment Plan ID:`
  },
  {
    "content": `Create at Date:`
  },
  {
    "content": `Plan for:`
  },
  {
    "content": `Created by:`
  },
  {
    "content": `Approved by:`
  },
  {
    "content": `Total Aligners Top:`
  },
  {
    "content": `Total Aligners Bottom:`
  },
  {
    "content": `Est. Duration:`
  },
  {
    "content": `Dr. Notes:`
  },
  {
    "content": `Plan Initialized:`
  },
  {
    "content": `Purchase Plan` //IF THEY HAVE NOT PURCHASED IT YET THEN THIS WILL BE THE TEXT FOR THEM TO START THAT
  },
  {
    "content": `Plan Files:`
  },
  {
    "content": `View` //VIEW BUTTON TEXT
  },
  {
    "content": `Plan PDF:`
  },





  { //17
    "content": `Creating Treatment Plan`
  },
  { //18
    "content": `We are now creating your custom treatment plan offer.`
  },

];




	
export default PAGE;