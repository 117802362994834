import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID
  AGID
  TPID
  UID
  files{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  step
  status
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $UID: String,
  $AGID: String,
  $TPID: String,
  $step: String,
  $status: String,
  $files: [FileObjectInput],
  $meta: MetaInput
`;


const DEFAULT_MUTATION_INPUT = ` 
  UID: $UID,
  AGID: $AGID,
  TPID: $TPID,
  files: $files,
  step: $step,
  status: $status,
  meta: $meta,
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "UID": "string",
  "AGID": "string",
  "TPID": "string",
  "status": "string",
  "step": "string",
  "files": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "meta": CLEAN_FIELDS_DEFAULT.META
}




const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "ID",
    name: "AGID",
    defaultValue: "",
    path: "AGID",
    searchable: true
  },
  {
    type: "ID",
    name: "TPID",
    defaultValue: "",
    path: "TPID",
    searchable: true
  },
  {
    type: "DATE",
    name: "Created At",
    defaultValue: "",
    path: "meta.createdAt"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "",
    path: "status"
  }
];



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Aligner Connections",
        "SUB_TITLE":"Id's and who created it",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME":"Aligner ID"
          },
          
          {
            "PATH": "TPID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Treatment Plan", //TITLE OF SLIDE OUT
              "APINAME": "TREATMENT_PLANS",
              "ID_PATH": "TPID",
              "EDITABLE": true
            },
            "NAME":"Treatment Plan ID"
          },
          {
            "PATH": "AGID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Aligner Group", //TITLE OF SLIDE OUT
              "APINAME": "ALIGNER_GROUPS",
              "ID_PATH": "AGID",
              "EDITABLE": true
            },
            "NAME":"Aligner Group ID"
          },
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "User", //TITLE OF SLIDE OUT
              "APINAME": "USERS",
              "ID_PATH": "UID",
              "EDITABLE": true
            },
            "NAME":"User ID"
          },
          {
            "PATH": "step",
            "TYPE": "STRING",
            "NAME": "Step"
          },
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Schedule",
        "SUB_TITLE":"Time Information for Aligner",
        "FIELDS": [
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME":"Status",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "meta,createAt",
            "TYPE": "DATE",
            "NAME":"When Aligner was First Uploaded",
            "FORMAT": "M d Y - H:n:s A"
          },
          {
            "PATH": "meta,updateAt",
            "TYPE": "DATE",
            "NAME":"Last Update At",
            "FORMAT": "M d Y - H:n:s A"
          }
        ]
      },
    ]
  },

  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE":"Files and Images",
        "SUB_TITLE":"Show all existing plan files and images",
        "FIELDS": [
          {
            "PATH": "files",
            "TYPE": "ARRAY_OBJECT",
            "NAME":"Aligner Files",
            "col": 12,
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12, 
            "xl": 12,
            "STYLE": {paddingBottom: "10px"},
            "FIELDS": [
                {
                "TYPE": "ROW",
                "FIELDS": [
                  {
                    "TYPE": "COLUMN",
                    "STYLE": {margin: "-10px 0",paddingBottom: "0px"},
                    "FIELDS": [
                      {
                        "PATH": "url",
                        "TYPE": "LINK",
                        "LINK_NAME_PATH": "name",
                        "NAME":"File",
                        "DOWNLOAD": true
                      }
                    ]
                  },
                  {
                    "TYPE": "COLUMN",
                    "STYLE": {margin: "-10px 0",paddingBottom: "0px"},
                    "FIELDS": [
                      {
                        "PATH": "createAt",
                        "TYPE": "DATE",
                        "NAME":"Create At"
                      }
                    ]
                  },
                  {
                    "TYPE": "COLUMN",
                    "STYLE": {margin: "-10px 0",paddingBottom: "0px"},
                    "FIELDS": [
                      {
                        "PATH": "type",
                        "TYPE": "STRING",
                        "NAME":"Type"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]



const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}



const ALIGNERS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default ALIGNERS;
export {
  DEFAULT_PREVIEW_FORM,
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
};