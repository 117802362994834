// import React from 'react';
// import 'styles/scss/website/pages/checkout.scss';
// import {Helmet} from "react-helmet-async";
// import API_CALLS from 'API_CALLS/index';
// import 'styles/scss/website/pages/checkout.scss';
// import CONTENTS from "./CONTENTS";
// const {Container, Row, Column} = window.LayoutBuilder;

//http://127.0.0.1:3000/checkout/complete/?payment_intent=pi_3QDq5zP4Z9yBkjlo0CftoYzh&payment_intent_client_secret=pi_3QDq5zP4Z9yBkjlo0CftoYzh_secret_t0Zz1nfPOg0T6AeT6JA8qFI26&redirect_status=succeeded
import React, { useEffect, useState } from "react";
import {
  useStripe,
} from "@stripe/react-stripe-js";
import Loading from 'global/components/Loading/index';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import API_CALLS from 'API_CALLS/index';
import {AddOrderToUser} from 'global/utils/helper-functions'; 
import CONTENTS from "./CONTENTS";
import 'styles/scss/website/pages/complete.scss';
import {NavLink} from 'react-router-dom';



//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC)
const stripePromise = (process.env.NODE_ENV === 'development') ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC) : loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE);
function Index(props) {
  // var curOrders = window.GlobalUtil.State.get("newOrder");
  // var {products=[], coupon} = curOrders;
  // const CHECKOUT_TOTAL = window.GlobalUtil.checkoutCalculatorNew(products, coupon);  
  const [clientSecret, setClientSecret] = useState("");
  const [noPaymentFound, setNoPaymentFound] = useState(false);
  const [paypalStatus, setPaypal] = useState(false);
  // var Session = window.GlobalUtil.State.get("Session");
  // var curOrders = window.GlobalUtil.State.get("newOrder");
  const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
  
  useEffect(() => {
    if(!window.location.search){
      setNoPaymentFound(true);
      return;
    } else {
      const clientSecretTemp = new URLSearchParams(window.location.search).get(
        //const clientSecretTemp = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
      const paypalStatus = new URLSearchParams(window.location.search).get(
        //const clientSecretTemp = new URLSearchParams(window.location.search).get(
          "paypal"
        );


      if(clientSecretTemp) setClientSecret(clientSecretTemp);
      else if(paypalStatus) setPaypal(paypalStatus);
      else setNoPaymentFound(true);
    }
  }, []);
  
  

  const appearance = {
    theme: 'stripe',
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';

  if(paypalStatus){
    return(
      <div className="contentPage" id="PaymentComplete">
        <div id="PaymentStatus" className={`${paypalStatus}`}>
          <div id="StatusIcon">
            {CONTENT[paypalStatus][0]}
          </div>
          <div id="StatusText">
            <h2 id="StatusTitle">{CONTENT[paypalStatus][1]}</h2>
            <p id="StatusDes">{CONTENT[paypalStatus][2]}</p>
          </div>
          <div id="ButtonGroup" className="buttonGroup">
            <NavLink className={"button button1"} onClick={()=>{}} to={CONTENT[paypalStatus][3]}>{CONTENT[paypalStatus][4]}</NavLink>
          </div>
        </div>
      </div>
    )
  }


  if(noPaymentFound){
    return(
      <div className="contentPage" id="PaymentComplete">
        <div id="PaymentStatus" className={`not_found`}>
          <div id="StatusIcon">
            {CONTENT.not_found[0]}
          </div>
          <div id="StatusText">
            <h2 id="StatusTitle">{CONTENT.not_found[1]}</h2>
            <p id="StatusDes">{CONTENT.not_found[2]}</p>
          </div>
          <div id="ButtonGroup" className="buttonGroup">
            <NavLink className={"button button1"} onClick={()=>{}} to={CONTENT.not_found[3]}>{CONTENT.not_found[4]}</NavLink>
          </div>
        </div>
      </div>
    )
  }
  //<Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
  return (
    <div className="contentPage" id="PaymentComplete">
      {
        clientSecret
        ? <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
            <CompletePage clientSecret={clientSecret} CONTENT={CONTENT}/>
          </Elements>
        : <Loading
            header={undefined}
            body={undefined}
            floating={false}
            overlay={false}
            inlineStyles={{
              loadingIcon: {
                "maxWidth": "150px"
              }
            }}
          />
      }
    </div>
  );
};







function CompletePage(props) {
  const stripe = useStripe();
  const {clientSecret} = props;
  const [status, setStatus] = useState("loading");
  const [intentId, setIntentId] = useState(null);
  
  
  
  
  const ResetCart = ()=>{
    window.GlobalUtil.State.set("newOrder", {products: [], coupon: {}});
    window.GlobalUtil.clearLocalStorageCart();
  }
  /*
    if the payment comes up complete
    run an api using the clientSecret as id
    to mark the product as paid for the customer
    -then delete that item so it won't be used twice by accident
    -then reset the users local browser info to no longer have the cart items
  */
  const RunPaymentComplete = async () => {
    await window.Client.query({
      query: API_CALLS.TEMP_CART.QUERIES.all(),
      variables: {
        "manual": [
          {
            path: "CLNTSCRT",
            val: clientSecret
          },
          // {
          //   path: "status",
          //   val: "ACTIVE"
          // }
        ]
      }
    })
    .then(async (obj) => {
      //window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/PaypalButtons/index.js`, NAME:"Success obj", CONTENT:[obj]});
      //onSuccess(obj.data.createOrder)
      //console.log('obj.data', obj.data);

      //ONCE FOUND, ADD ORDER AND DONE TO USER
      const savedCheckout = obj.data.tempCarts[0]
      console.log('savedCheckout', savedCheckout);
      
      if(savedCheckout){
        const curOrder = JSON.parse(savedCheckout.curOrders);
        await AddOrderToUser({
          paymentOrderID: savedCheckout.CLNTSCRT, 
          paymentType: savedCheckout.paymentType,
          curOrders: curOrder,
          UID: savedCheckout.UID, 
          onSuccess: ()=>{}, 
          onFail: ()=>{}
        })
  
  
        //ONCE ORDER IS ADDED TO USER UPDATE SAVED CART SO IT IS NOT DONE TWICE
        await window.Client.mutate({
            mutation: API_CALLS.TEMP_CART.MUTATIONS.deactivate(),
            variables: {
              id: savedCheckout._id
            } //THIS IS IN THE STRIPE PAYMENT PAGE AND GET'S THE CURRENT ORDER PLUS A CLIENT SECRET ID (CLNTSCRT)
          })
          .then((obj) => {
            console.log('API_CALLS.TEMP_CART.MUTATIONS.complete');
            //CLEAR CART CACHE
            ResetCart()
            //window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/PaypalButtons/index.js`, NAME:"Success obj", CONTENT:[obj]});
            //onSuccess(obj.data.createOrder)
            return true;
          })
          .catch((error)=>{
            console.log('API_CALLS.TEMP_CART.MUTATIONS.ERROR', error);
            return false;
          });
      }

      return true;
    })
    .catch((error)=>{
      return false;
    });
  }


  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (!clientSecret) {
      return;
    }    
    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      if (!paymentIntent) {
        setStatus("default");
        return;
      } else {
        if(paymentIntent.status === "succeeded") RunPaymentComplete();
        setStatus(paymentIntent.status);
        setIntentId(paymentIntent.id);
      } 
      //console.log('paymentIntent', paymentIntent);
    });
  }, [stripe]);

  return (
    <div id="PaymentStatus" className={`${status}`}>
      <div id="StatusIcon">
        {props.CONTENT[status][0]}
      </div>
      <div id="StatusText">
        <h2 id="StatusTitle">{props.CONTENT[status][1]}</h2>
        <p id="StatusDes">{props.CONTENT[status][2]}</p>
      </div>
      <div id="ButtonGroup" className="buttonGroup">
        <a className={"button button1"} onClick={ResetCart} href={props.CONTENT[status][3]}>{props.CONTENT[status][4]}</a>
        {/* <NavLink className={"button button1"} onClick={ResetCart} to={props.CONTENT[status][3]}>{props.CONTENT[status][4]}</NavLink> */}
      </div>
    </div>
  );
}

//payment_intent=pi_3QCRSaP4Z9yBkjlo03saLPU7&payment_intent_client_secret=pi_3QCRSaP4Z9yBkjlo03saLPU7_secret_NGNon082O9N4zubFqWPmyHzqZ&redirect_status=succeeded



// class Index extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       curStatus: "loading"
//     };    
//     this.getProduct = this.getProduct.bind(this);
//     this.ClientEventRef = window.GlobalUtil.subscribeEventOnce("ClientEvent", this.getProduct);
//   }

//   componentDidMount(){
//     //console.log(`this.props.match.params.PID`,"\n\n",this.props.match.params.PID,"\n\n");
//     if(window.Client) this.getProduct()
//   }

//   componentWillUnmount(){
//     this.ClientEventRef.unsubscribe();
//   }
// //http://localhost:3000/rd/?REDIR=/checkout/6137bf2032779f47383d2c83
//   getProduct = async () => {
//     if(!this.props.match || !this.props.match.params || !this.props.match.params.PID){
//       return;
//     }
//     //console.log(`getProduct`,"\n\n","\n\n");
//     if(!window.Client || !window.Client.query) return false;
//     // console.log("this.props.match.params.PID");
//     // console.log(this.props.match.params.PID,"\n\n");
//     return await window.Client.query({
//       query: API_CALLS.PRODUCTS.QUERIES.single(),
//       variables: {id: this.props.match.params.PID}
//     })
//     .then(result => {
//       //console.log("result.data.product");
//       //console.log(result.data.product,"\n\n");
//       window.GlobalUtil.addToCart({product: result.data.product, replaceAll: true}, ()=>{
//         if(result.data.product.language) window.GlobalUtil.triggerEvent("LanguageEvent", result.data.product.language)
//         else if(this.state) this.setState({date: new Date()});
//       })     
//       return true;
//     })
//     .catch((error)=>{
//       console.log("catch", error);
//       return false;
//     })
//   }


//   onSuccessCheckout=(order)=>{
//     //window.GlobalUtil.consoleLog("onSuccessCheckout RAN", [order]);
//     //const NewOrderID = order.id;
//   }

//   onFailCheckout=(failed)=>{
//     //window.GlobalUtil.consoleLog("onFailCheckout RAN", [failed]);
//   }

//   render(){
//     var {curStatus} = this.state;          
//     const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
//     const HEADER = CONTENTS[window.Session.Language].HEADER; 
//     //var {showSideMenu} = this.state;
//     return (
//       <div className="contentPage" id="PaymentSuccess">
//         <Helmet>
//             <meta charSet="utf-8" />
//             <title>{HEADER.TITLE}</title>
//             <meta name="description" content={HEADER.DESCRIPTION}/>
//             <link  href={`${this.props.location.pathname}`} />
//             <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
//             <meta name="keywords" content={HEADER.KEYWORDS} />

//             {"<!-- Google / Search Engine Tags -->"}
//             <meta itemprop="name" content={HEADER.TITLE} />
//             <meta itemprop="description" content={HEADER.DESCRIPTION}/>
//             <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

//             {/*<!-- Facebook Meta Tags -->*/}
//             <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
//             <meta property="og:type" content="website" />
//             <meta property="og:title" content={HEADER.TITLE} />
//             <meta property="og:description" content={HEADER.DESCRIPTION}/>
//             <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

//             {/*<!-- Twitter Meta Tags -->*/}
//             <meta name="twitter:card" content="summary_large_image" />
//             <meta name="twitter:title" content={HEADER.TITLE} />
//             <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
//             <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
            
//             <meta name="robots" content="noindex" /> {/*<!-- This will prevent indexing -->*/}
//         </Helmet>
//         <div className="pageCheckoutHeader">
//           <Container className="">
//             <Row className="title">
//               <Column className="" col="" xs="" sm="" md="" lg="" xl="">
//                 {CONTENT.PageCheckout[0]}
//               </Column>
//             </Row>
//           </Container>
//         </div>
//       </div>
//     );
//   }
// }











export default Index;
      
