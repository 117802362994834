import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';

  

const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAllPosts(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    blogs(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAllAndCountPost(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    blogCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    blogs(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query CountAllPosts(
   ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    blogCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query SinglePost($id: String){
    blog(id:$id){
      _id
      ${whatToPull}
    }
  }
`;




const QUERY_BLOG_PAGE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query BlogPage($url: String){
    blogPage(url:$url){
      _id
      ${whatToPull}
    }
  }
`;


const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!, $otherParams: String){
    blogDistinct(field:$field, otherParams: $otherParams)
  }
`;







const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  count: COUNT,
  single: QUERY_SINGLE,
  distinct: QUERY_GET_DISTINCT,
  blogPage: QUERY_BLOG_PAGE
}


export default QUERIES;