import React, { useState, useRef } from 'react';
import Login from 'global/components/Login/index';
import LoginWithGoogleButton from 'global/components/LoginWithGoogleButton/index';
import LoginWithFacebookButton from 'global/components/LoginWithFacebookButton/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}



const Index = (props) => {
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  const OnSignUp = async (login)=>{   
    login = window.GlobalUtil.APICleanObj(login)
    if(props.onSuccess) props.onSuccess(login); //HERE IS WHERE WE WILL CALL GLOBAL UTIL LOGIN
    window.GlobalUtil.DialogStack.closeTop()
  }
  const OnFail = ()=>{
    if(props.onFail) props.onFail();
    window.GlobalUtil.DialogStack.closeTop()
  }
  return(
    <div className="resetPWButton">
      <button className="button button1 buttonFull" onClick={(e)=>{
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: CONTENT[1],
          customClass: "frontEndSlideInRight shortSlideInRight",
          width: " ",
          noFooter: true,
          children: (propsInner)=>{                                      
            return(
              <div className="">
                <Login 
                  {...propsInner}
                  id=""
                  className=""
                  emailOrPasswordWrongMessage={(<p className="">{CONTENT[2]}</p>)}
                  onFail={OnFail}
                  onSuccess={OnSignUp}
                  buttonText={CONTENT[3]}
                />
              </div>
            )
          }
        });
      }}>
        {props.resetText ? props.resetText : CONTENT[0]}
      </button>
    </div>
  )
}


export default Index;