import React from 'react';
import Nav from 'admin/components/Nav/index';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animatedClass: ['fadeIn','slideInRight']
    };    
    this.onClose = this.onClose.bind(this);
  }

  componentWillUnmount(){
    clearTimeout(this.TimeoutPointer);
  }

  onClose(){
    this.setState({animatedClass: ['fadeOut','slideOutRight']},
      ()=>{
        clearTimeout(this.TimeoutPointer);
        this.TimeoutPointer = setTimeout(this.props.onHideSideMenu, 500);
      });
  }

  render(){
    var {animatedClass} = this.state;
    return (
      <div id="MobileMenuAdmin">
        <div className={`overlay animated ${animatedClass[0]}`} onClick={this.onClose}></div>
        <div className={`sideMenu animated ${animatedClass[1]}`}>
          <div className="menuTitle">
            <div className="title">Menu</div>
            <div className="exist" onClick={this.onClose}><i className="fas fa-times"></i></div>
          </div>
          <div className="menus">
            <Nav onClickLink={this.onClose} MenuToShow={window.Session.user.role} />
          </div>
        </div>
      </div>
    );
  }
}

export default Component;