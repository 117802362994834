//import React from 'react';
const PAGE = [
  {
    "content": `Patients`
  },
  {
    "content": `Active Orders`
  },
  {
    "content": `Unpaid Orders`
  },
  {
    "content": `Finished Orders`
  }
];




  
export default PAGE;
