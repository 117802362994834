import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
// import QUERIES from "./QUERIES";
// import MUTATIONS from "./MUTATIONS";
// import ImageUtils from "global/utils/image-utils";
// const IMAGE_UTILS =  new ImageUtils();

const FORM_FIELDS = (obj) => {
  // const QUERY_GET_DISTINCT = QUERIES.distinct();
  // const MUTATION_EDIT = MUTATIONS.update();
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">Address</span></div>,
        subTitle: <div className="formSubTitle">User Shipping Address</div>,
        type: "conditional",
        condition: (curObj)=>{            
          //if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
          return true;
        },
        listOfFields: [
          {
            title: <div className="formTitle"><span className="">Street</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Street address where things will be shipped</span></div>,
            type: "text",
            name: "address,street",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,street",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Street 2</span></div>,
            subTitle: <div className="formSubTitle"><span className="">You can use this for floor, suite, and unit numbers, etc.</span></div>,
            type: "text",
            name: "address,street2",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,street2",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">City</span></div>,
            subTitle: <div className="formSubTitle"><span className="">What is the locations city</span></div>,
            type: "text",
            name: "address,city",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,city",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">State</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Please choose a state</span></div>,
            type: "selectState",
            name: "address,state",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,state",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Country</span></div>,
            subTitle: <div className="formSubTitle"><span className="">What country is this going to</span></div>,
            type: "text",
            name: "address,country",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,country",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Zipcode</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Please include your zipcode too</span></div>,
            type: "text",
            name: "address,zipcode",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,zipcode",
            errorMessage: "Each user needs this for shipping"
          }
        ]
      },
    ]
  )
};






export default FORM_FIELDS;