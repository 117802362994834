//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_QUERY_FIELDS, DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT, FULL_MUTATION_FIELDS } from './index';


const MUTATION_COMPLETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CompleteFailed(
    $id: String
  ) {
    completeFailed(
      input: {
        id: $id
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;




const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteFailed(
    $id: String,
  ) {
    deleteFailed(id: $id)
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteFailedList(
    $ids: [String]
  ) {
    deleteFailedList(ids: $ids)
  }
`;


const MUTATION_DELETE_REMOVE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteRemoveFailedList(
    $ids: [String]
  ) {
    deleteFailedRemoveList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateFailed(
    $id: String,
  ) {
    deactivateFailed(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateFailedList(
    $ids: [String]
  ) {
    deactivateFailedList(ids: $ids)
  }
`;


const MUTATIONS = {
  complete: MUTATION_COMPLETE,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  deleteRemoveList: MUTATION_DELETE_REMOVE_LIST,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;