import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_PREVIEW_FORM } from './index';
import QUERIES from "./QUERIES";
import FormPreview from 'global/components/FormPreview/index';
///Users/danielezaldivar/Sites/sonrisas/src/global/components/FormPreview.js
/*
<PREVIEW
  VARIABLES={{
    id: TPID
  }}
  PREVIEW_FORM, 
  TITLE, 
  SUB_TITLE
/>
*/

function PREVIEW(props) {     
  var {VARIABLES={}, PREVIEW_FORM, TITLE, SUB_TITLE} = props;
  const { loading, error, data, refetch } = useQuery(QUERIES.single(), {
    variables: VARIABLES,
    fetchPolicy: "no-cache"
  });
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  //console.log(`data`,"\n\n",data,"\n\n");
        
  var NewObj = window.GlobalUtil.stripOutFromObj(data.coupon, "__typename");  
  window.CurObj = {...NewObj, id: NewObj._id};
  window.CurRefetchEdit = refetch;        
  if(props.onLoad) props.onLoad(window.CurObj);                
  return (
    <FormPreview
      TITLE={(TITLE !== undefined) ? TITLE : "Coupon Preview"}
      SUB_TITLE={(SUB_TITLE !== undefined) ? SUB_TITLE : "Full Preview of current coupon."}
      NewObj={NewObj}
      PREVIEW_FORM={(PREVIEW_FORM ? PREVIEW_FORM : DEFAULT_PREVIEW_FORM())}
    />
  );
}



export default PREVIEW;