import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import "styles/scss/global/DownloadButton.scss";
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
/*
<DownloadAllButton 
  id={}
  className={}
  files={
    [
      {
        url: "", //url for server to download before zipping
        name: "" //name of file
      }
    ]
  }
  textStart={"Download All"}
  textDownloading={"Downloading"}
  textDownloaded={"Downloaded"}
/>
*/
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'new'
    };    
  }

  componentDidMount(){}

  APIGetImageFromURL = async (urlArray)=>{      
    return await window.Client.query({
      query: API_CALLS.REPORTS.QUERIES.getZipLink(),
      variables: {
        fileListString: urlArray
      }
    })
    .then(result => {
      console.log(`result`,"\n\n",result,"\n\n");
      return result.data.getZipLink;
    })
    .catch(failed=>{
      console.log(`Failed APIGetImageFromURL`,"\n\n",failed,"\n\n");
    });
  }

  ZipGroup = async (e)=>{
    e.preventDefault()
    this.setState({status: 'downloading'});
    window.GlobalUtil.delay(1000).then(()=>{
      this.setState({status: 'downloaded'});
    })
    var files = this.props.files;
    await this.APIGetImageFromURL(JSON.stringify(files))
    .then(async linkToZip=>{
      //ref.href = linkToZip;
      this.setState({
        status: 'downloaded'
      });
      //console.log(`linkToZip`,"\n\n",linkToZip,"\n\n");
            
      window.open(`${window.location.origin}${linkToZip}`, '_blank');
    });  
  }

  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    var {
      id='',
      className="", 
      files, 
      textStart=CONTENT[0],
      textDownloading=CONTENT[1],
      textDownloaded=CONTENT[2]
    } = this.props;
    var {status} = this.state;  
    return(
      <a 
        id={id} 
        className={`downloadButtonComponent button ${className} ${status}`} 
        href="#" 
        onClick={this.ZipGroup}>
          {(status === 'new') && textStart}
          {(status === 'downloading') && textDownloading}
          {(status === 'downloaded') && textDownloaded}
          {(status === 'downloading') ? <span>&nbsp;<i style={{"color": "white"}} className="fas fa-spinner iconSpin"></i></span> : null}
      </a>
    )
  }
}



export default Index;
      
