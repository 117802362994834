//import React from 'react';
const PAGE = [
  {
    "content": `User Name`
  },
  {
    "content": `Treatment Plan ID`
  },
  {
    "content": `Requested`
  },
  {
    "content": `Last Update`
  },
  {
    "content": "View"
  },
  {
    "content": "Action"
  },
  {
    "content": `Upload`
  },
  {
    "content": "No Items Found"
  }
];




  
export default PAGE;