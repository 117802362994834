import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import USERS from "../USERS/index";
// import QUERIES from "./QUERIES";
// import MUTATIONS from "./MUTATIONS";
// import ImageUtils from "global/utils/image-utils";
// const IMAGE_UTILS =  new ImageUtils();

const FORM_FIELDS = (obj={}) => {
  // const QUERY_GET_DISTINCT = QUERIES.distinct();
  // const MUTATION_EDIT = MUTATIONS.update();
  const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  return [
    {
      title: <div className="formTitle"><span className="">ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Product ID CANT BE CHANGED</span></div>,
      type: "text",
      name: "id",
      required: false,
      editable: false,
      defaultValue: "",
      defaultValuePath: "_id",
      hide: true
    },
    {
      title: <div className="formTitle"><span className="">UID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every task</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_USERS
        })
        .then(result => {
          var usersOptions = result.data.users.map((user, index)=>{
            return({
              "value": user._id,
              "title": user.email
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...usersOptions])
          // console.log("usersOptions");
          // console.log(usersOptions,"\n\n");
                
        });
      },
      name: "UID",
      required: true,
      defaultValue: "",
      defaultValuePath: "UID",
      errorMessage: "User ID is required"
    },
    {
      title: <div className="formTitle"><span className="">Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "PENDING",
          "title": "PENDING"
        },
        {
          "value": "INACTIVE",
          "title": "INACTIVE"
        }
      ],
      required: false,
      defaultValue: "ACTIVE",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Payment Order ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This is the ID Paypal gives when they pay.</span></div>,
      type: "text",
      name: "paymentOrderID",
      defaultValue: "",
      defaultValuePath: "paymentOrderID",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Payment Subscription ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This is an id papal gives IF they are subscribed to payments.</span></div>,
      type: "text",
      name: "paymentSubscriptionID",
      defaultValue: "",
      defaultValuePath: "paymentSubscriptionID",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Payment Type</span></div>,
      subTitle: <div className="formSubTitle"><span className="">How did they pay for this?</span></div>,
      type: "select",
      name: "paymentType",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "paypal",
          "title": "PAYPAL"
        },
        {
          "value": "card",
          "title": "CARD"
        },
        {
          "value": "affirm",
          "title": "AFFIRM"
        },
        {
          "value": "afterpay_clearpay",
          "title": "AFTERPAY CLEARPAY"
        },
        {
          "value": "apple_pay",
          "title": "APPLE PAY"
        },
        {
          "value": "google_pay",
          "title": "GOOGLE PAY"
        },
        {
          "value": "amazon_pay",
          "title": "AMAZON PAY"
        },
        {
          "value": "cash",
          "title": "CASH"
        },
        {
          "value": "other",
          "title": "OTHER"
        }
      ],
      required: false,
      defaultValue: "",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Comments</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Comment that shows up for this product.</span></div>,
      type: "textArea",
      name: "comment",
      rows: 6,
      required: true,
      defaultValue: "",
      defaultValuePath: "comment",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Subtotal</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The full order basic subtotal</span></div>,
      type: "money",
      name: "cost,subtotal",
      required: true,
      defaultValue: 0,
      errorMessage: "Cost is required"
    },
    {
      title: <div className="formTitle"><span className="">Discount</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The full order discount</span></div>,
      type: "money",
      name: "cost,discount",
      required: true,
      defaultValue: 0,
      errorMessage: "Cost is required"
    },
    {
      title: <div className="formTitle"><span className="">Subtotal - Discount</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The full order cost for the subtotal minus discount</span></div>,
      type: "money",
      name: "cost,subtotalMinusDis",
      required: true,
      defaultValue: 0,
      errorMessage: "Cost is required"
    },
    {
      title: <div className="formTitle"><span className="">Tax</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The full order cost for tax</span></div>,
      type: "money",
      name: "cost,tax",
      required: true,
      defaultValue: 0,
      errorMessage: "Cost is required"
    },
    {
      title: <div className="formTitle"><span className="">SetupFee</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The full order cost for the setup Fee</span></div>,
      type: "money",
      name: "cost,setupFee",
      required: true,
      defaultValue: 0,
      errorMessage: "Cost is required"
    },
    {
      title: <div className="formTitle"><span className="">Shipping</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The full order cost for shipping</span></div>,
      type: "money",
      name: "cost,shipping",
      required: true,
      defaultValue: 0,
      errorMessage: "Cost is required"
    },
    {
      title: <div className="formTitle"><span className="">Total</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The full order cost for total</span></div>,
      type: "money",
      name: "cost,total",
      required: true,
      defaultValue: 0,
      errorMessage: "Cost is required"
    },
    {
      title: <div className="formTitle"><span className="">Date Paid</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When it was Paid</span></div>,
      type: "datePicker",
      name: "paidAt",
      required: false,
      defaultValue: "",
      defaultValuePath: "paidAt"
    },
    {
      title: <div className="formTitle"><span className="">Charged Date</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When it WILL be charged</span></div>,
      type: "datePicker",
      name: "chargingAt",
      required: false,
      defaultValue: "",
      defaultValuePath: "chargingAt",
      errorMessage: "The Task Name is required"
    },
  ]
};






export default FORM_FIELDS;