//import React, { useState, useRef } from 'react';
const EMAIL_DESCRIPTIONS = {
  "WELCOME": "This email should welcome the customer and link them to our site.",
  //"CONTACTMESSAGE": "",
  //"REJECTIONMESSAGE": "If the customer cannot be helped this will tell them that and that we're reinburing them whatever", //we may just want to do this manualy for now
  "ORDER_CONFIRMATION": "This should tell the purchaser that we've recived their payment and give them a recept for the order.",
  "PAYMENT_FAILED": "This email should inform the user that their payment failed and what the consequences to that are. It should also have a link for them to contact us when they fix it.",
  "REQUEST_TREATMENT_PLAN": "This email is sent to a doctor to request a treatment plan for a user. It should be sent by the admin and include the email address of a doctor and a Treatment Plan ID.",
  "REQUEST_APPROVE_TREATMENT_PLAN": "This email is sent from a doctor who creates the treatment plan to a doctor who will be approving the plan. The licensed dentist should see all the information and approve or reject it.",
  "TASK_REJECTED": "IF THE DENTIST REJECTS THE REQUEST, OR APPROVE TASK, THEN IT SENDS AN EMAIL TO OUR SYSTEM ADMIN TO FIX THOSE PROBLEMS AND RESEND IT",
  "REQUEST_FULL_TREATMENT_PLAN": "This email is sent to a doctor to request a treatment plan be checked out and offically approved. It should be sent by an admin and include everything needed so the doctor can create a treatment plan.",
  "TREATMENT_PLAN_READY": "This email will go to the User connected to the Treatment Plan. It will tell them the plan is ready and for them to login and purchase it.",
  "SENT_IMPRESSION_KIT": "This email should tell the user that we've sent their impression kit. Please make sure the tracking number has been added.",
  "SENT_PRODUCTS": "This email should tell the user that we've sent their product. Please make sure the tracking number has been added.",
  "SENT_ALIGNERS": "This email should tell the user that we've sent their aligners. Please make sure the tracking number has been added.",
  "SEND_INVOICE": "This email should encorage the user to follow the link given to purchase the chosen product.",
  "RESET_PASSWORD": "This email will link the person to a page to reset their password.",
  "ADMIN_NOTIFICATION": "Notification on anything we want to admin to know asap.",
  "SEND_RECEIPT": "This email will send an invoice to so anyone for any order you choose.",
  "CUSTOM_EMAIL": "Choose the address, language, and add email string object"
}


const Index = (props) => {
  return(
    [
      {
        title: <div className="formTitle"><span className="">Email Type</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose one of the email templates in the dropdown.</span></div>,
        type: "select",
        name: "whatEmail",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "WELCOME",
            "title": "WELCOME"
          },
          {
            "value": "IMPRESSION_KIT_INFO",
            "title": "Impression Kit Info (1st Contact)"
          },
          // { //THIS IS THE TEMPLATE FOR WHEN WE GET A CONTACT AND THE EMAIL COMES TO US
          //   "value": "CONTACTMESSAGE",
          //   "title": "Contact Message"
          // },
          // { //THIS IS A TEMPLATE FOR WHEN WE REJECT THE USER AND WHAT WE SAY
          //   "value": "REJECTIONMESSAGE",
          //   "title": "Rejection Message"
          // },
          {
            "value": "ORDER_CONFIRMATION",
            "title": "Order Confirmation"
          },
          {
            "value": "PAYMENT_FAILED",
            "title": "Payment Failed"
          },
          {
            "value": "REQUEST_TREATMENT_PLAN",
            "title": "Request Treatment Plan"
          },
          {
            "value": "REQUEST_APPROVE_TREATMENT_PLAN",
            "title": "Request Approve Treatment Plan"
          },
          {
            "value": "REQUEST_FULL_TREATMENT_PLAN",
            "title": "Request Full Treatment Plan"
          },
          {
            "value": "TASK_REJECTED",
            "title": "REJECT - TASK"
          },
          {
            "value": "TREATMENT_PLAN_READY",
            "title": "Treatment Plan Ready"
          },
          {
            "value": "SENT_IMPRESSION_KIT",
            "title": "Sent Impression Kit"
          },
          {
            "value": "SENT_PRODUCTS",
            "title": "Sent Products"
          },
          {
            "value": "SENT_ALIGNERS",
            "title": "Sent Aligners"
          },
          {
            "value": "SEND_INVOICE",
            "title": "Send Invoice"
          },
          {
            "value": "RESET_PASSWORD",
            "title": "Reset Password"
          },
          {
            "value": "ADMIN_NOTIFICATION",
            "title": "Admin Notification"
          },
          {
            "value": "SEND_RECEIPT",
            "title": "Send Receipt"
          },
          {
            "value": "COUPON_TP_FIXED_EMAIL",
            "title": "Coupon TP Fixed Email"
          },
          {
            "value": "CUSTOM_EMAIL",
            "title": "Custom Email"
          }
        ],
        required: true,
        defaultValue: "",
        defaultValuePath: "whatEmail",
        errorMessage: "Type is required"
      },
      {
        type: "customText",
        condition: (obj)=>{
          return (obj.whatEmail) ? true : false;
        },
        customFunction: (curObj)=>{       
          if(!curObj.whatEmail) return;
          return(
            <div className="">
              {EMAIL_DESCRIPTIONS[curObj.whatEmail]}
            </div>
          )
        }
      },
      {
        title: <div className="formTitle"><span className="">TO: Email Address</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Who's getting this email.</span></div>,
        type: "email",
        name: "emailData,whoToEmail",
        required: true
      },
      {
        title: <div className="formTitle"><span className="">CC: Email Address</span></div>,
        subTitle: <div className="formSubTitle"><span className="">CC to anyone you want.</span></div>,
        type: "email",
        name: "emailData,whoToCC",
        required: false
      },
      {
        title: <div className="formTitle"><span className="">Language</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose one of the email languages in the dropdown.</span></div>,
        type: "select",
        name: "emailData,language",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ENGLISH",
            "title": "ENGLISH"
          },
          {
            "value": "ESPAÑOL",
            "title": "ESPAÑOL"
          },
        ],
        required: true,
        defaultValue: "",
        defaultValuePath: "emailData,language",
        errorMessage: "Type is required"
      },
      {
        title: <div className="formTitle"><span className="">Website</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Must select a website for this email.</span></div>,
        type: "select",
        options: [
          {
            "value": "",
            "title": "Select a Website",
            "disabled": true
          },
          {
            "value": "SMILESCLUB",
            "title": "Smiles Club"
          },
          {
            "value": "SONRISASCLUB",
            "title": "Sonrisas Club"
          },
          {
            "value": "SMILEPROFESSOR",
            "title": "Smile Professor"
          },
        ],
        name: "emailData,website",
        required: true,
        defaultValue: "",
        errorMessage: "Website is required"
      }
    ]
  )
}


export default Index;