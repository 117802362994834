import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
//import {DEFAULT_MUTATION_FIELDS as DEFAULT_FIELDS_TREATMENT_PLANS} from "../TREATMENT_PLANS/index";
//import {FULL_MUTATION_FIELDS as FULL_MUTATION_FIELDS_TREATMENT_PLANS} from "../TREATMENT_PLANS/index";
//import {DEFAULT_MUTATION_FIELDS as DEFAULT_FIELDS_ORDERS} from "../ORDERS/index";

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID
  GID
  name
  email
  language
  phone
  address{
    ${MUTATION_FIELDS_DEFAULT.ADDRESS}
  }
  notes
  pictures{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  won
  aboutWinnings{
    name
    description
    cost
    notesList{
      ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
    }
    wonAt
    accepted
  }
  giveaway{
    _id
    name
  }
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
  status
  active
`;


const FULL_MUTATION_FIELDS = (include=["giveaway"])=>{
  var IncludeDic = {
    giveaway: `
      giveaway{
        _id
        name
      }
    `
  };
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  include.map((key, index)=>{
    if(IncludeDic[key]) FullMutation += IncludeDic[key];
  })
  return FullMutation;
};


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;

 
  

const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $GID: String,
  $name: String,
  $email: String,
  $phone: String,
  $language: String,
  $address: AddressInput,
  $notes: String,
  $pictures: [FileObjectInput],
  $won: Boolean,
  $aboutWinnings: AboutWinningsInput,
  $meta: MetaInput,
  $status: String,
  $active: Boolean
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  GID: $GID,
  name: $name,
  email: $email,
  phone: $phone,
  language: $language,
  address: $address,
  notes: $notes,
  pictures: $pictures,
  won: $won,
  aboutWinnings: $aboutWinnings,
  meta: $meta,
  status: $status,
  active: $active
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "GID": "string",
  "name": "string",
  "email": "string",
  "phone": "string",
  "language": "string",
  "address": CLEAN_FIELDS_DEFAULT.ADDRESS,
  "notes": "string",
  "pictures": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "won": "boolean",
  "aboutWinnings": {
    "name": "string",
    "description": "string",
    "cost": "string",
    "notesList": [
      CLEAN_FIELDS_DEFAULT.NOTESLIST
    ], 
    "wonAt": "string",
    "accepted": "string",
  },
  "status": "string",
  "meta": CLEAN_FIELDS_DEFAULT.META,
  "active": "boolean"
}



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "CONDITION": (curObj)=>{
          if(!curObj.pictures[0]) return false;
          return true;
        },
        "TYPE": "COLUMN",
        "col": 12,
        "xs": 12,
        "sm": 12,
        "md": 2,
        "lg": 2,
        "xl": 2,
        "FIELDS": [
          {
            "NAME": "Image",
            "PATH": "pictures,0,url",
            "TYPE": "IMAGE"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME": "ID"
          },
          {
            "PATH": "name",
            "TYPE": "STRING",
            "NAME": "Name"
          },
          {
            "PATH": "language",
            "TYPE": "STRING",
            "NAME": "Preferred Language"
          },
          {
            "PATH": "email",
            "TYPE": "EMAIL",
            "MAKE_LINK": true,
            "NAME": "Email",
            "DEFAULT": ""
          },
          {
            "PATH": "phone",
            "TYPE": "PHONE",
            "NAME": "Phone",
            "MAKE_LINK": true,
            "DEFAULT": "FALSE"
          },
          {
            "PATH": "GID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "BUTTON_TITLE": "Giveaway",
              "TITLE": "Giveaway", //TITLE OF SLIDE OUT
              "APINAME": "GIVEAWAYS",
              "ID_PATH": "GID",
              "EDITABLE": true
            },
            "NAME": "Giveaway ID"
          },
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.HTML_FULL({
    "CONDITION": (curObj)=>(curObj.notes),
    "PATH": "notes",
    "TITLE": "General Notes",
    "SUB_TITLE": "Notes we've left about this contestant"
  }),
  // PREVIEW_FORM_TEMPLATES.NOTES_LIST({
  //   TITLE:"Communication History",
  //   SUB_TITLE:"Full history of our back and forth communication. Please fill out as good as you can.",
  //   PATH:"aboutWinnings,notesList",
  // }),
  {
    "CONDITION": (curObj)=>{
      if(!curObj) return false;
      return curObj.won;
    },
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE": "WINNER!",
        "SUB_TITLE": "Info about what the user won and any notes about that.",
        "FIELDS": [
          {
            "PATH": "aboutWinnings,name",
            "TYPE": "STRING",
            "NAME": "Name"
          },
          {
            "PATH": "aboutWinnings,description",
            "TYPE": "HTML",
            "NAME": "Description"
          },
          {
            "PATH": "aboutWinnings,cost",
            "TYPE": "MONEY",
            "NAME": "Cost"
          },
          {
            "PATH": "aboutWinnings,wonAt",
            "TYPE": "DATE",
            "NAME": "When they Won"
          },
          {
            "PATH": "aboutWinnings,accepted",
            "TYPE": "BOOLEAN",
            "NAME": "Did they Accept?"
          }
        ]
      }
    ]
  },
  {
    "CONDITION": (curObj)=>(curObj.address && curObj.address.street),
    "TYPE": "ACCORDION",
    "TITLE": "Address",
    "FIELDS": [
      PREVIEW_FORM_TEMPLATES.ADDRESS()
    ]
  },
  PREVIEW_FORM_TEMPLATES.PICTURES({
    "CONDITION": (curObj)=>(curObj.pictures && curObj.pictures[0]),
    "PATH": "pictures",
    "TITLE": "Pictures",
    "SUB_TITLE": "Current user mouth condition",
  }),
  PREVIEW_FORM_TEMPLATES.META()
];


const LIST_HEADER_FIELDS = [
  {
    path: "pictures.0.url",
    type: "IMAGE",
    name: "Pictures",
    defaultValue: "NONE"
  },
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    path: "name",
    type: "VALUE",
    name: "Name",
    searchable: true,
    minWidth: "180px"
  },
  {
    path: "email",
    type: "EMAIL",
    name: "Email",
    searchable: true
  },
  {
    path: "phone",
    type: "PHONE",
    name: "Phone",
    searchable: true,
    minWidth: "120px"
  },
  {
    path: "giveaway.name",
    type: "VALUE",
    name: "Giveaway",
    sortable: false
  },
  {
    path: "GID",
    type: "VALUE",
    name: "GID",
    searchable: true
  },
  {
    path: "won",
    type: "VALUE",
    name: "Won"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: ""
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: ""
  }
];

const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.pictures || (curCleanObj.pictures.length < 1)) delete curCleanObj.pictures;
  if(!curCleanObj.giveaway) delete curCleanObj.giveaway;
  if(!curCleanObj.notes) delete curCleanObj.notes;
  if(curCleanObj.meta) delete curCleanObj.meta;
  if(!curCleanObj.aboutWinnings || !curCleanObj.aboutWinnings.name) delete curCleanObj.aboutWinnings;
  curCleanObj.active = true;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.pictures || (curCleanObj.pictures.length < 1)) delete curCleanObj.pictures;
  if(!curCleanObj.giveaway) delete curCleanObj.giveaway;
  if(!curCleanObj.notes) delete curCleanObj.notes;
  if(curCleanObj.meta) delete curCleanObj.meta;
  if(!curCleanObj.aboutWinnings || !curCleanObj.aboutWinnings.name) delete curCleanObj.aboutWinnings;
  return curCleanObj;
}


const GIVEAWAY_CONTS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export {
  DEFAULT_MUTATION_FIELDS,
  FULL_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};
export default GIVEAWAY_CONTS;