import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import FormFieldsNew from 'API_CALLS/USERS/FORM_FIELDS_NEW';
import FormBuilder from 'global/components/FormBuilder/index';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curObj: {
        role: "DOCTOR",
        createRequestTreatmentPlanTask: false,
        sendWelcomeEmail: true
      }
    };    
  }

  APINewUser = async () => {  
    var {curObj} = this.state;
    await window.Client.mutate({
      mutation: API_CALLS.USERS.MUTATIONS.create(),
      variables: {
        ...curObj
      }
    })
    .catch(failed=>{
      console.log(`Failed to get treatment plan`,"\n\n",failed,"\n\n");
      window.GlobalUtil.DialogStack.closeTop()
    });
    return true;
  }

  render(){
    var {curObj, curStatus} = this.state;          
    return(
      <button 
        className="button button1 btn-color4 bigIconButtons" 
        onClick={(e)=>{
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: "New Doctor",
          closeButtonText: "Cancel",
          saveButtonText: "Create",
          customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog",
          width: "1200px",
          onClose: ()=>{
            // this.setState({activeNote: {
            //   note: '',
            //   createAt: new Date(),
            //   updateAt: new Date(),
            //   status: 'ACTIVE'
            // }}) 
          },
          onSave: async ()=>{
            await this.APINewUser();
            return;
          },
          children: (propsChildren)=>{                                      
            return(
              <div className="dialogCustomZone">
                <FormBuilder
                  ref={e => this.formRef = e}
                  initialValues={curObj}
                  listOfFields={FormFieldsNew()}
                  onSubmit={()=>{}}
                  onChange={(newObj)=>{
                    this.setState({curObj: newObj})
                  }}
                  onValidate={propsChildren.onChangeValid}
                />
              </div>
            )
          }
        });
      }}><span>New Doctor</span>&nbsp;&nbsp;<i className="fas fa-user-md" /></button>
    )
  }
}


export default Index;
      
