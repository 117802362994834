import React, { useState, useRef } from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import TemplateSlideInRight from 'global/templates/templateSlideInRight/index';
import API_CALLS from 'API_CALLS/index';
import ImageUtils from "global/utils/image-utils";
import FormBuilder from 'global/components/FormBuilder/index';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import LayoutBuilder from 'LayoutBuilder';

const IMAGE_UTILS =  new ImageUtils();
const {Container, Row, Column} = LayoutBuilder;
const MUTATION_EDIT = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();
/*

//GET AND SHOW CURRENT USER
//CREATE NEW OBJECT FOR NEW TREATMENT PLAN
//SAVE NEW PLAN
//SEND DOCTOR AN EMAIL WITH A LINK
//CC DAD ON THE EMAIL WITH THE LINK
//CLOSE CURRENT TASK
//CREATE NEW TASK WITH (UPLOAD_TREATMENT_PLAN)
  //USER ID (UID)
  //ALIGNMENT PLAN ID (TPID)
  //


*/






const FORM_FIELDS = (obj)=>{
  return [
    {
      title: <div className="formTitle"><span className="">Number of Top Aligners</span></div>,
      subTitle: <div className="formSubTitle">The total number of top aligners the customer will get.</div>,
      type: "number",
      min: 1,
      max: 1000,
      name: "alignersSchedule,numOfAlignersTop",
      placeholder: 0,
      required: false,
      defaultValue: 0,
      defaultValuePath: "alignersSchedule,numOfAlignersTop"
    },
    {
      title: <div className="formTitle"><span className="">Number of Bottom Aligners</span></div>,
      subTitle: <div className="formSubTitle">The total number of bottom aligners the customer will get.</div>,
      type: "number",
      min: 1,
      max: 1000,
      name: "alignersSchedule,numOfAlignersBottom",
      placeholder: 0,
      required: false,
      defaultValue: 0,
      defaultValuePath: "alignersSchedule,numOfAlignersBottom"
    },
    {
      title: <div className="formTitle"><span className="">Plan Files</span></div>,
      type: "fileUploadList",
      name: "planFiles",
      maxNumber: 10,
      required: false,
      defaultValue: "",
      defaultValuePath: "planFiles",
      maxWidth: "150px",
      filePath: [
        "USERS",
        obj.UID,
        "TREATMENT_PLANS",
        obj.id,
        "PLAN_FILES"
      ],
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "planFiles", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain();
          callBack();
        })
    },
    {
      title: <div className="formTitle"><span className="">Notes</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Please summarize what you will be doing and what will change. (The user CAN see these notes.)</span></div>,
      type: "textArea",
      name: "notes",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "notes",
      errorMessage: "Comments is required"
    },
    // {
    //   title: <div className="formTitle"><span className="">Progress Logs</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Please list off every action taken. From contacting the user, to sending emails, to contacting doctors and more. ANY AND EVERY action taken for this treatment plan.</span></div>,
    //   type: "notesList",
    //   name: "progressLogs",
    //   defaultCategoriesProps: [
    //     {
    //       "value": "UPLOAD_FILES",
    //       "title": "UPLOAD_FILES"
    //     },
    //     {
    //       "value": "EMAIL_UPLOAD",
    //       "title": "EMAIL_UPLOAD"
    //     },
    //     {
    //       "value": "EMAIL_APPROVE",
    //       "title": "EMAIL_APPROVE"
    //     },
    //     {
    //       "value": "EMAIL_USER",
    //       "title": "EMAIL_USER"
    //     },
    //     {
    //       "value": "EMAIL_REQUEST_FULL",
    //       "title": "EMAIL_REQUEST_FULL"
    //     },
    //     {
    //       "value": "OTHER",
    //       "title": "OTHER"
    //     }
    //   ],
    //   defaultValue: "",
    //   required: false,
    //   defaultValuePath: "progressLogs",
    //   errorMessage: "This is required"
    // }
  ]
};



class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    };       
    this.validate = this.validate.bind(this);
    this.APIGet = this.APIGet.bind(this);
    this.APIUpdate = this.APIUpdate.bind(this);
    this.APISendTaskBack = this.APISendTaskBack.bind(this);
  }


  validate(dontFinish){
    var curObj = {...this.curObj};
    Object.keys(curObj).map((key, index)=>{
      if((curObj[key]  === undefined) || (curObj[key]  === "")) delete curObj[key] 
    })
    if(curObj.totalNumOfAligners) curObj.totalNumOfAligners = Number(curObj.totalNumOfAligners)
    curObj = window.GlobalUtil.stripOutFromObj(curObj, "__typename")
    console.log("Saved","\n\n",curObj,"\n\n");
    /*
      SHOULD HAVE
        _id
        UID
        status
        offerRequest: {
          chiefComplaint String
          notes String
          createAt Date
        }
    */
    //return false;
    this.APIUpdate(curObj, dontFinish);
  }


  APIGet(){       
    //this.APIGetDREmail();
    var inputObj = {
      query: this.props.API_CALLS.QUERY_SINGLE,
      variables: {
        id: this.props.curObj._id,
        include: [
          {
            name: "user"
          }
        ]
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;            
      var treatmentPlan = window.GlobalUtil.stripOutFromObj(data.treatmentPlan, "__typename");
      //console.log("window.CurObj","\n\n",window.CurObj,"\n\n");
      this.curObj = {
        ...this.curObj, 
        ...treatmentPlan,
        id: treatmentPlan._id
      };
      window.CurObj = {...this.curObj};
      if(!this) return;
      this.setState({
        Plan: treatmentPlan,
        User: window.GlobalUtil.deepGetFromString(treatmentPlan, "user", false),
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false})
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }



  APIUpdate(newObj, dontFinish){        
    var curObjClean = window.GlobalUtil.APISubmitObjCleaner(API_CALLS.TREATMENT_PLANS.CLEAN_FIELDS, newObj);
    if(API_CALLS.TREATMENT_PLANS.FINAL_MOD_EDIT) curObjClean = API_CALLS.TREATMENT_PLANS.FINAL_MOD_EDIT(curObjClean);

    if(!dontFinish){
      curObjClean.rejectStepNotes = "";//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      curObjClean.changeStep = 1;//MOVE TO NEXT STEP WHEN DONE
      window.GlobalUtil.deepSetFromString(curObjClean, "HISTORY,LABORATORY,UPLOADED_OFFER", `${Date.now()}`);
    }
    var inputObj = {
      mutation: (dontFinish ? this.props.API_CALLS.MUTATION_UPDATE : this.props.API_CALLS.MUTATION_UPLOADPLAN),
      variables: curObjClean
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      if(!dontFinish) {
        window.FlashMenuItem("plans/approve");
        window.CurRefetchList(true);
        this.onClose(this.CurRef);
      }
    })
    .catch((error)=>{
      console.log("onCloneCatch catch", error);
      this.onClose(this.CurRef);
    });
  }

  APISendTaskBack(rejectStepNotes, callback=()=>{}){
    var newObj = {
      id: this.props.curObj._id,
      rejectStepNotes: rejectStepNotes,//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      changeStep:  -1//MOVE TO NEXT STEP WHEN DONE
    }

    var inputObj = {
      mutation: this.props.API_CALLS.MUTATION_UPDATE,
      variables: newObj
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
        callback();
        window.FlashMenuItem("plans/request");
        window.CurRefetchList(true);
        this.onClose(this.CurRef);
    })
    .catch((error)=>{
      console.log("Reject APISendTaskBack catch", error);
      this.onClose(this.CurRef);
    });
  }


  /*
    REJECT EMAIL
    APISendEmail = (newObj) => {  
      var emailData = newObj.emailData;
      return window.Client.mutate({
        mutation: API_CALLS.EMAILS.MUTATIONS.sendEmail(),
        variables: {
          whatEmail: "TASK_REJECTED",
          emailData: JSON.stringify({
            whoToEmail: , //LEAVE BLANK TO EMAIL THE ADMIN
            whoToCC: , //LEAVE BLANK TO CC THE EMAILS ON SYSTEM CC
            rejectedTask: "UPLOAD", //"APPROVE", "UPLOAD_ALIGNERS"
            language: "ENGLISH",
            reasonForRejection: "" //WHY ARE WE REJECTING THIS TASK
          })
        }
      });
    }
  */
  // APIGetDREmail = (doctorID) =>{
  //   window.Client.query({
  //     query: API_CALLS.REPORTS.QUERIES.getDoctorNames(),
  //     fetchPolicy: "no-cache"
  //   })
  //   .then((result) => {
  //     var {data} = result;          
  //     var getDoctorNames = window.GlobalUtil.stripOutFromObj(data.getDoctorNames, "__typename");
  //     var getDoctorNamesByID = {};
  //     getDoctorNames.map((doc, index)=>{
  //       getDoctorNamesByID[doc._id] = doc;
  //     })
  //     this.setState({getDoctorNamesByID: getDoctorNamesByID}); 
  //   })
  //   .catch((error)=>{
  //     console.log("query plan failed", error);
  //   });
  // }


  render(){
    var {showSlideIn, isValid, Plan={}, User, hideForm, isLoading, showRejectTask} = this.state;              
    return (
      this.SlideInRight(
        "Upload Treatment Plan", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <div className="buttonGroup">
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
                <button className={`button btn-color5`} onClick={()=>this.setState({showRejectTask: true})}>
                  Send Back <i className="fas fa-undo" />
                </button>
                
              </div>
            <div className="buttonGroup">
              <button className={`button button1 ${(isValid && !isLoading) ? "" : "disabled"}`} onClick={()=>{ 
                  this.validate();
                }}>Upload Complete</button>
              <DialogProgressLogs
                ID={this.props.curObj._id}
                API_NAME={'TREATMENT_PLANS'}
                FIELD={'treatmentPlan'}
                asButton={true}
                className={'button button1 btn-color4'}
                style={{"width":"100px","minWidth":"0"}}
                customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
              />
            </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div className="">
              <div className="PreviewForm">
                {
                  Plan.rejectStepNotes &&
                  <div id="ErrorNotes">
                    <h2>REJECTED REASON:</h2>
                    {
                      Plan.rejectStepNotes
                    }
                  </div>
                }
                {
                  User &&
                  this.previewUser(User)
                }
                {
                  Plan &&
                  this.previewPlan(Plan)
                }
                <Row>
                  <Column 
                    className={"borderColor5"}
                    style={{"paddingBottom":"0px"}}>
                  <div className="previewFormFieldTitles2">
                    <div className="previewFormFieldTitle">Needed Input</div>
                    <div className="previewFormFieldSubTitle">Fill out as much of the fields below as possible</div>
                  </div>
                  </Column>
                </Row>
              </div>
              {this.FormBuilderZone(FORM_FIELDS)}
              <SlideOutRejectTask
                showRejectTask={showRejectTask}
                onCancel={()=>this.setState({showRejectTask: false})}
                onReject={this.APISendTaskBack}
              />
            </div>
          )
        }
      )
    );
  }
}





class SlideOutRejectTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }; 
  }

  render(){
    var {rejectStepNotes="", isValid} = this.state;
    var {TPID, whoToEmail} = this.props;
    return(
      <div>
        {
          this.props.showRejectTask &&
          <TemplateSlideInRight
            ref={e=>this.slideInRightRef=e}
            title={"Reject Task"}
            onClose={this.props.onCancel}
            onSave={()=>{}}
            customButtons={(curRef)=>{
              return(
                <React.Fragment>
                  <button className="button button2" onClick={()=>{
                    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                  }}>Cancel</button>
                  <button className={`button btn-color5 ${isValid ? "" : "disabled"}`} onClick={()=>{ 
                    this.props.onReject(rejectStepNotes, ()=>{
                      if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                    });
                  }}>Send Back Complete <i className="fas fa-undo"></i></button>
                </React.Fragment>
              )
            }}>
            <div>
              <Container>
                <Row>
                  <Column 
                    className={""}>
                      <div >
                        <Container className="" style={{"padding": "30px 10px"}}>
                          <FormBuilder
                            disableKeySubmit={true}
                            initialValues={{}}
                            listOfFields={[
                              {
                                title: <div className="formTitle"><span className="">Reason for Rejection</span></div>,
                                subTitle: <div className="formSubTitle"><span className="">Tell the doctor why are we rejecting this task.</span></div>,
                                type: "textArea",
                                name: "rejectStepNotes",
                                rows: 6,
                                required: true,
                                defaultValue: "",
                                defaultValuePath: "rejectStepNotes",
                                errorMessage: "Rejection reason is required"
                              }
                            ]}
                            onSubmit={()=>{
                            }}
                            onChange={newObj => {
                              this.setState({rejectStepNotes: newObj.rejectStepNotes}) 
                            }}
                            onValidate={(isValid)=>{
                              this.setState({isValid})
                            }}
                          />
                        </Container>
                      </div>
                  </Column>
                </Row>
              </Container>
            </div>
          </TemplateSlideInRight>
        }
      </div>
    )
  }
}




export default Index;