//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Clear aligners made of premium materials without harmful toxins",
			"DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Number one safe affordable clear aligners for discreet invisible orthodontics.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h1 class="sectionTitle">404 Page Not Found</h1>
						<p>Sorry the page you have entered could not be found.</p>
					`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
			"DESCRIPTION": "El programa de Sonrisas Club hace que sea fácil tener una hermosa sonrisa, sin la molestia de la cirugía o los frenos tradicionales.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h1 class="sectionTitle">404 Page Not Found</h1>
						<p>Sorry the page you have entered could not be found.</p>
					`
				}
			]
		}
	}
};


	
export default PAGE;
