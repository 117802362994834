//import React from 'react';
const ENGLISH = [
	{
    "content": `Edit User` //SLIDE IN HEADER TEXT
  },
  {
    "content": `Close` //SLIDE IN BUTTONS TEXT
  },
  {
    "content": `Update` //SLIDE IN BUTTONS TEXT
  },
  {
    "content": `First Name`
  },
  {
    "content": `Every user needs a first name`
  },
  {
    "content": `Each user needs a First Name`
  },
  {
    "content": `Last Name`
  },
  {
    "content": `Every user needs a last name`
  },
  {
    "content": `Each user needs a Last Name`
  },
  {
    "content": `Email Address`
  },
  {
    "content": `Every user needs an email address`
  },
  {
    "content": `Each user needs an Email`
  },
  {
    "content": `Phone Number`
  },
  {
    "content": `Every user needs a phone number`
  },
  {
    "content": `Each user needs a Phone Number`
  },
  {
    "content": `New Password`
  },
  {
    "content": `Use this to change your password`
  }
];




	
export default ENGLISH;
export {ENGLISH}