import React from 'react';
import {NavLink} from 'react-router-dom';
import PAGES from 'website/pages/pages.js'; //ADD NEW PAGES HERE

const Component = (props)=>{
  var {onClickLink=()=>{}, MenuToShow, inline} = props;
  if(!MenuToShow) return null;
  const CleanLinks = PAGES().filter(Page=>Page.Menu.indexOf(MenuToShow) !== -1);        
        
  return(
    <div className={`menu ${inline ? "nav" : ""}`}>
      {
        CleanLinks.map((Link, index)=>{
          if((Link.PageName === "Home") || Link.PageName === "Inicio") return(
            <div className="menu-item" key={index}>
              <NavLink 
                className={({ isActive, isPending }) =>{
                  var classString = "nav-link";
                  if(isPending) classString += " pending";
                  if(isActive) classString += " active";
                  return classString;
                }}
                onClick={onClickLink} 
                to={Link.Paths[0]}>
                  {Link.PageName}
              </NavLink>
            </div>
          )
          return(
            <div className="menu-item" key={index}>
              <NavLink 
                className={({ isActive, isPending }) =>{
                  var classString = "nav-link";
                  if(isPending) classString += " pending";
                  if(isActive) classString += " active";
                  return classString;
                }}
                onClick={onClickLink} 
                to={Link.Paths[0]}>{Link.PageName}</NavLink>
            </div>
          )
        })
      }
    </div>
  )
}

export default Component;