//import React from 'react';
const PAGE = [
  {
    "content": `Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos`
  },
  {
    "content": `Archivos actuales`
  }
];




  
export default PAGE;