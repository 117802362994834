import React, {useCallback, useEffect} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import FormBuilder from 'global/components/FormBuilder/index';
import Loading from 'global/components/Loading/index';
import DialogWrapper from 'global/components/DialogWrapper/index';
import Logo from 'global/components/Logo/index';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import APISaveForTracking from 'API_CALLS/TRACKING/TRACKING_FUNCTIONS';
import IconImage from 'global/img/logo_icon.svg';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}




const REQUIREDMESSAGE = ()=>{
  return(
    <span style={{"color":"red","fontSize":"9px","paddingLeft":"10px","paddingTop":"6px"}}><i className="fas fa-star-of-life" style={{"fontSize":"7px"}}  /> <span style={{"paddingLeft":"4px"}}>REQUIRED</span></span>
  )
}
const ERRORMESSAGE = (customMessage="This field is required before submitting")=>{
  return(
    <div style={{"color":"red","fontSize":"12px","paddingLeft":"10px","paddingTop":"6px"}}>{customMessage}</div>
  )
}





//APISaveForTracking({page: "HOME",type: "PAGE"})
class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this._reCaptchaRef = React.createRef();
    this.currentObject = {
      ...(this.props.defaultObj ? this.props.defaultObj : {})
    };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  onRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    this.setState({captchaToken: value})
  };


  onCanelContact = () => { 
    this.setState({showContact: false});
    this.currentObject = {};
    if(this.props.onClose) this.props.onClose();
  }


  onSubmitContact = () => {     
    const recaptchaValue = this._reCaptchaRef.current.getValue();
    if(!recaptchaValue){
      //alert("Please click the ReCAPTCHA at bottom of form.");
      return;
    }  

    var {isValid} = this.state;
    if(!isValid) return;
    console.log("this.currentObject");
    console.log(this.currentObject,"\n\n");  
    this.setState({loading: true, isValid: false});
    //UPPERCASE ANY VALUES
    var urlParams = (window.Session.urlParams ? window.Session.urlParams : {});    //{"lead": "ORGANIC"}
    var ComObj = {
      from: this.currentObject.name,
      to: "SMILESCLUB",
      email: this.currentObject.email,
      phone: this.currentObject.phone,
      language: window.Session.Language,
      type: "CONTACT_FORM",
      leadData:  JSON.stringify(urlParams),
      message: this.currentObject.message,
    }

    window.Client.mutate({
      mutation: API_CALLS.COMS.MUTATIONS.create(),
      variables: ComObj
    })
    .then(result => {
      var data = window.GlobalUtil.deepGetFromString(result, "data", {});
      console.log("data");
      console.log(data,"\n\n"); 
      this.setState({ 
        submitError: false, 
        show: "success",
        loading: false
      });
    })
    .catch((error)=>{
      window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/ContactForm/index onSubmitContact()`, NAME:"error", CONTENT:[error]});
      this.setState({ submitError: error.message, loading: false, show: "fail" });
    });
  }

  render(){
    if(typeof window === 'undefined') return(
      <div className="componentContactForm"></div>
    )
    var {noDialog, showButton=false, STYLES={}, hideLogo=false} = this.props;
    var {isValid=false, show=false, submitError, loading, captchaToken} = this.state;
    
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    const ContactFormContent = (<React.Fragment>
      {
        show
        ? show === "success"
          ? <div id="SuccessMessage" className="animated fadeIn message">
              <div className="messageText">
                {!hideLogo && <div className="logoHeader" style={STYLES.logoHeader}>
                    <Logo
                      noLink={true}
                    />
                  </div>
                }
                {CONTENT[17]}
              </div>
            </div>
          : <div id="SuccessMessage" className="animated fadeIn message">
              <div className="messageText">
                {!hideLogo && <div className="logoHeader" style={STYLES.logoHeader}>
                    <Logo
                      noLink={true}
                    />
                  </div>
                }
                {CONTENT[18]}
              </div>
            </div>
        : null
      }
      {
        !show && 
        <div className="formWrapper">
          {
            loading
            ? <Loading 
                header={undefined}
                body={undefined}
                floating={true}
                overlay={true}
                inlineStyles={{
                  loadingIcon: {
                    "maxWidth": "250px"
                  }
                }}
              />
            : <ErrorBoundary>
                  <FormBuilder
                    initialValues={this.currentObject}
                    listOfFields={[
                      {
                        title: <div className="formTitle"><span className="">{CONTENT[5]}</span></div>,
                        type: "text",
                        name: "name",
                        subTitle: CONTENT[6],
                        //placeholder: "",
                        required: true,
                        requiredMessage: (REQUIREDMESSAGE()),
                        errorMessage: (ERRORMESSAGE(CONTENT[7])),
                      },
                      {
                        title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
                        type: "phone",
                        name: "phone",
                        subTitle: CONTENT[9],
                        //placeholder: "",
                        required: false,
                        requiredMessage: (REQUIREDMESSAGE()),
                        errorMessage: (ERRORMESSAGE(CONTENT[10])),
                      },
                      {
                        title: <div className="formTitle"><span className="">{CONTENT[11]}</span></div>,
                        type: "email",
                        name: "email",
                        subTitle: CONTENT[12],
                        //placeholder: "",
                        required: true,
                        requiredMessage: (REQUIREDMESSAGE()),
                        errorMessage: (ERRORMESSAGE(CONTENT[13])),
                      },
                      {
                        title: <div className="formTitle"><span className="">{CONTENT[14]}</span></div>,
                        type: "textArea",
                        rows: 7,
                        name: "message",
                        subTitle: CONTENT[15],
                        //placeholder: "",
                        required: true,
                        requiredMessage: (REQUIREDMESSAGE()),
                        errorMessage: (ERRORMESSAGE(CONTENT[16])),
                      }
                    ]}
                    onChange={newObj => {                            
                      if(newObj.name && newObj.phone && !this.FormStarted){
                        this.FormStarted = true;
                        if(!window.Session || !window.Session.user || !window.Session.user._id) APISaveForTracking({"type":"FORM_STARTED", page: "HOME"});
                      }
                      this.currentObject = newObj;
                    }}
                    onValidate={(isValid)=>{this.setState({isValid: isValid})}}
                  />
                  {/*{
                    (isValid && this.captchaToken === undefined) && <GoogleReCaptcha onVerify={(token)=>{
                      this.captchaToken = token;
                      console.log(`token`,"\n\n",token,"\n\n");
                    }} />
                  }*/}
                  <div style={{"margin": "20px 0 0","width": "100%"}}>
                    <ReCAPTCHA
                      ref={this._reCaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={this.onRecaptchaChange}
                    />
                  </div>
                  {showButton && <button disabled={(!isValid || !captchaToken) ? true : false} className={`button button1 button-accept ${(captchaToken && isValid) ? "" : "disabled"}`} onClick={this.onSubmitContact}>{CONTENT[3]}</button>}
              </ErrorBoundary>
          }
        </div>
      }
    </React.Fragment>)
    if(noDialog) return(
      <div className="componentContactForm" style={STYLES.componentContactForm}>
        {ContactFormContent}
      </div>
    )
    //var {data} = this.props;
    return(
      <div className="componentContactForm" style={STYLES.componentContactForm}>
        <DialogWrapper
          ref={e => this.DialogWrapperRef = e}
          dontScrollToTop={true}
          clickToCloseOverlay={true}
          renderToBody={true}
          containerId={"ContactDialog"}
          darkBackground={true}
          headerText={CONTENT[2]}
          width="700"
          height="700"
          onCancel={this.onCanelContact}
          customSubmitButton={(
            submitError === undefined
            ? <button 
              disabled={(!isValid || !captchaToken) ? true : false}
              className={`button button1 button-accept ${(captchaToken && isValid) ? "" : "disabled"}`} 
              onClick={this.onSubmitContact}>{CONTENT[3]}</button>
            : <button className={`button button1 button-accept`} onClick={()=>this.DialogWrapperRef.callbackData(false)}>{CONTENT[4]}</button>
          )}
          acceptButtonText="Submit"
          acceptButtonEnabled={isValid}
        >
          {ContactFormContent}
        </DialogWrapper>
      </div>
    )
  }
}


export default Component;