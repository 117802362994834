import React from 'react';
import {Helmet} from "react-helmet-async";

import Header from './components/Header/index';
import Footer from './components/Footer/index';
import MobileMenu from './components/MobileMenu/index';
import ContactForm from './components/ContactForm/index';
import CandidateQuiz from 'global/components/CandidateQuiz/index';

import {NavLink} from 'react-router-dom';
import API_CALLS from 'API_CALLS/index';
import APISaveForTracking, {APISaveForTrackingUnique} from 'API_CALLS/TRACKING/TRACKING_FUNCTIONS';
import 'styles/scss/website/pages/customLandingPage3.scss';


import backgroundBanner from 'global/img/candidateQuiz/backgroundBanner1_4x1.jpg';
import moneyBack from 'global/img/candidateQuiz/girlBanner4_4x3.jpg';

// import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
import MouthMoving from 'website/components/MouthMoving/index';
// import SimpleStepsList from './components/SimpleSteps';
import CONTENTS from "./CONTENTS_PAGE3";
//EXAMPLE: http://localhost:3000/deals001?CODE=SUMMERDEAL3

import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
class Index extends  React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      CODE: undefined
    };    
    this.ShowContactFormKey = window.GlobalUtil.subscribeEvent("ShowContactForm", showOrNot=>{
      this.setState({showContactForm: showOrNot});
    });
  }

  componentDidMount = () => {    
    this.RunAPIOnceWhenReady = window.GlobalUtil.runAPIOnceWhenReady(this.getURLCode);
    //APISaveForTrackingUnique({page: "CUSTOM_LANDING_1", url: "/deals001", type: "PAGE"});
    this.onScroll();
    //if(!window.Client) console.log("No Client Yet")
  }

  componentWillUnmount(){
    this.RunAPIOnceWhenReady.unsubscribe();
    this.ShowContactFormKey.unsubscribe();
    //if(this.DelayPointer) this.DelayPointer.abort(); //IF WENT BACK BEFORE FOUND THEN CANCEL ANY API SEARCH
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      //console.log(`this.curPage`,"\n\n",this.curPage,"\n\n");        
      window.GlobalUtil.triggerEvent("PageChangedEvent", this.curPage);
    }
  }


  getURLCode = () => {    
    var queryString = this.props.location.search;
    var urlParams = new URLSearchParams(queryString);
    var CODE = urlParams.get('CODE');
    
    if(CODE){
      if(CODE.slice(-1) === "/") CODE = CODE.slice(0,-1);
      //console.log(`CODE`,"\n\n",CODE,"\n\n");
      var newRefCode = CODE.split("-").join("")
      this.setState({
        CODE: newRefCode
      }, this.onCheckReferral); 
    } else {
      window.GlobalUtil.removeCoupon().then(()=>{
        this.setState({loading: false});
      });
    }
  }


  onCheckReferral = () => {
    var {CODE} = this.state;
    if(!CODE) return this.setState({ loading: false});
    this.setState({ loading: true});
    window.Client.query({
      query: API_CALLS.COUPONS.QUERIES.couponFinder(),
      variables: {
        "code": CODE
      }
    })
    .then(async ({data={}}) => {              
      var statusMessage = "success";
      //console.log('onCheckReferral data', data);
      //console.log(`data`,"\n\n",data,"\n\n");
      if(!data.couponFinder || !data.couponFinder._id) {
        statusMessage = "failed";
      }
      var coupon = ((statusMessage !== "failed") ? window.GlobalUtil.APICleanObj(data.couponFinder) : undefined);              
      window.GlobalUtil.consoleLogNew({LOCATION:`src/website/pages/customLandingPages/page3`, NAME:`WEBSITE -> PAGE -> BENEFITS -> ReferralCodeSearch -> onCheckReferral() -> SUCCESS`, CONTENT:coupon});
      window.GlobalUtil.LocalStorage.set("CustomPageCoupon", true);
      await window.GlobalUtil.setCoupon(coupon);
      if(this.state) this.setState({ loading: false});
    })
    .catch(async (error)=>{
      let errorMessage = error.message;
      window.GlobalUtil.consoleLogNew({LOCATION:`src/website/pages/customLandingPages/page3`, NAME:`WEBSITE -> PAGE -> BENEFITS -> ReferralCodeSearch -> onCheckReferral() -> ERROR`, CONTENT:error});
      await console.log("onSubmitLogin errorMessage");
      await console.dir(errorMessage,"\n\n");
      await console.dir(error,"\n\n");
      await window.GlobalUtil.removeCoupon();
      if(this.state) this.setState({ loading: false});
      //if(errorMessage === "Unexpected error value: \"CODE NOT FOUND\"")
    });
  }


  onScroll = () => {
    if(!window) return;
    if(!this.props.location || !this.props.location.hash) return;
    if(!document.querySelector(this.props.location.hash)) return;
    var top = document.querySelector(this.props.location.hash).scrollHeight;
    window.scrollTo({
      top: top,
      left: 0,
      //behavior: 'instant'
    });   
  }



  render(){
    var {showSideMenu, loading, showContactForm, showQuiz} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    var pathname = window.location.pathname;
    if(this.props.location.search) pathname += this.props.location.search;
    
    
    if(pathname.slice(-1) ==="/") pathname = pathname.slice(0,-1);
    
    const CleanLinks=[
      {
        "PageName":"Home",
        "Name":"Home",
        "Paths": [`${pathname}`],
        "Hash": null
      },
      {
        "PageName":"Flexible Pricing",
        "Name":"Flexible Pricing",
        "Paths":  [`${pathname}/#FlexiblePricing`],
        "Hash":"#FlexiblePricing"
      },    
      {
        "PageName":"Smile Preview",
        "Name":"Smile Preview",
        "Paths": [`${pathname}/#SmilePreview`],
        "Hash": "#SmilePreview"
      },
    ];     

    if(showQuiz) return (
      <div id="Website" className="customLandingPages">
        <Container id="CandidateQuizFullScreen">
          <Row className="row1">
            <Column className="text-center" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <CandidateQuiz onClickAddToCart={()=>APISaveForTracking({page: "CUSTOM_LANDING_3", url: "/deals003", type: "ADD_TO_CART"})}/>
            </Column>
          </Row>
        </Container>
      </div>
    )

    return (
      <div id="Website" className="customLandingPages">
        {
          showSideMenu && <MobileMenu onHideSideMenu={()=>this.setState({showSideMenu: false})} CleanLinks={CleanLinks}/>
        }
        <Header 
          onShowSideMenu={()=>this.setState({showSideMenu: true})}
          onClickLink={()=>{}}
          CleanLinks={CleanLinks}
          homeLink={pathname}
        />
        <div id="Content">
          <div className="contentPage" id="CustomLandingPage3" className="page2">        
            <Helmet>
                <meta charSet="utf-8" />
                <title>{HEADER.TITLE}</title>
                <meta name="description" content={HEADER.DESCRIPTION}/>
                <link  href={`${window.location.pathname}`} />
                <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
                <meta name="keywords" content={HEADER.KEYWORDS} />

                {"<!-- Google / Search Engine Tags -->"}
                <meta itemprop="name" content={HEADER.TITLE} />
                <meta itemprop="description" content={HEADER.DESCRIPTION}/>
                <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

                {/*<!-- Facebook Meta Tags -->*/}
                <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={HEADER.TITLE} />
                <meta property="og:description" content={HEADER.DESCRIPTION}/>
                <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

                {/*<!-- Twitter Meta Tags -->*/}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={HEADER.TITLE} />
                <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
                <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
            </Helmet>
            <div id="ProductTop" style={{"display":"none"}}></div>
            <div id="HeaderZone">
              <img id="BackgroundBanner" src={backgroundBanner}/>
              <Container id="BuyItToday">
                <Row>
                  <Column className="spaceHolderCol d-none d-lg-block" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="spaceHolder">
                      space
                    </div>
                  </Column>
                  <Column className="contentCenter topTextZone topTextZoneLeft" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="textZoneWrapper">
                      <div className="preHeader">Discover your True Smile</div>
                      <h1 className="sectionTitle">Your new Smile is closer than you think. Get your Clear Aligners <br/><strong>NOW and pay it off in 6-36 payments with Affirm!</strong></h1>
                      <p>A quick quiz can tell you if clear aligners are right for you!</p>
                      <button className="button button1" onClick={()=>{
                          APISaveForTracking({page: "CUSTOM_LANDING_3", url: "/deals003", type: "STARTED_QUIZ"});
                          this.setState({showQuiz: true})
                        }}>Let's see if you're a CANDIDATE!</button>

                      <hr className="spacerHR d-block d-md-none"/>
                    </div>
                  </Column>
                </Row>
              </Container>
            </div>
            
            <Container id="SimpleStepsHeaderContainer" className="simpleStepsHeader">
              
              <Row className="row1">
                <Column className="text-center" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <div className="column1 textZone">
                    <div className="preHeader">Risk Free!</div>
                    <h2 className="sectionTitle2">Get your Clear Aligners NOW! Pay later with Affirm or Afterpay!</h2>
                    <p className="subTitle">We’ll give you a <strong>FULL</strong> refund if you're not a candidate</p>
                  </div>
                </Column>
              </Row>

              <Row className="row2" id="FlexiblePricing">
                <Column className="" col="12" xs="12" sm="12" md="12" lg="5" xl="5">
                  <div className="column1">
                    <img 
                      id="AreYouSurePopupImage2"
                      src={moneyBack} 
                      alt="Clear Aligner Doctor holding tablet"
                    />
                  </div>
                </Column>
                <Column className="contentCenter" col="12" xs="12" sm="12" md="12" lg="7" xl="7">
                  <div className="column2 textZone">
                    <h2 className="sectionTitle2">Flexible Pricing Options!</h2>
                    <p className="subTitle">Time and flexibility are on your side. With Affirm, pay at a pace that works for you. Up to 36-month payment plans.</p>
                    <p className="bigText1">About $75 - $200 per month*</p>
                    <div className="miniText">* depending on Affirm's option you choose, length of financing, interest rates, etc.</div>
                  </div>
                </Column>
              </Row>
              
              <Row className="row3" id="SmilePreview">
                <Column className="contentCenter d-none d-md-block" col="12" xs="12" sm="12" md="12" lg="7" xl="7">
                  <div className="column1 textZone">
                    <h2 className="sectionTitle2">New smile preview!</h2>
                    <p className="subHeader">After we receive your impression, we'll give you a 3D preview of your entire smile transformation — so you can get a glimpse of your new Smile! The "Treatment Plan" includes your FREE Impression Kit, your full step by step doctor approved treament plan, and all your aligners from start to finish.</p>
                    <p>You will receive the most affordable price in the market. Also, you will have the option to finance the whole treatment. No credit history will be checked. You can get financing regardless of your credit score.</p>
                  </div>
                </Column>
                <Column className="" col="12" xs="12" sm="12" md="12" lg="5" xl="5">
                  <div className="column2">
                    <MouthMoving />                    
                  </div>
                </Column>
                <Column className="contentCenter d-block d-md-none" col="12" xs="12" sm="12" md="12" lg="7" xl="7">
                  <div className="column1 textZone">
                    <h2 className="sectionTitle2">New smile preview!</h2>
                    <p className="subHeader">After we receive your impression, we'll give you a 3D preview of your entire smile transformation — so you can get a glimpse of your new Smile! The "Treatment Plan" includes your FREE Impression Kit, your full step by step doctor approved treament plan, and all your aligners from start to finish.</p>
                    <p>You will receive the most affordable price in the market. Also, you will have the option to finance the whole treatment. No credit history will be checked. You can get financing regardless of your credit score.</p>
                  </div>
                </Column>
              </Row>
            </Container>
          </div>
        </div>
        <Footer onShowContact={()=>{if(this.HomeRef) this.HomeRef.onShowContact();}}/>
        {
          showContactForm && 
          <ContactForm 
            urlParams={{
              ...this.props.location, 
              "PageName":"CUSTOM_LANDING_3",
              "Name":"CUSTOM_LANDING_3",
              "Paths": [`${pathname}`]
            }}
            onClose={()=>{
              this.setState({showContactForm: false})
            }}
          />
        }
      </div>
    );
  }
}




export default Index;
