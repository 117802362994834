import React, { useState, useRef } from 'react';



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNote: undefined, 
      hideInactive: true
    };    
    this.onUpdateSiteSettings = this.onUpdateSiteSettings.bind(this);
    this.onNew = this.onNew.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }



  onUpdateSiteSettings(notesList, callback=()=>{}){
    var cleanObj = {notesList: notesList};   
    cleanObj = window.GlobalUtil.stripOutFromObj(cleanObj, "__typename");
    this.setState({hideAll: true, activeNote: undefined});      
    this.props.onUpdateAPI(cleanObj, 
      ()=>{
        this.setState({hideAll: false}, ()=>{
          callback()
        });
      },
      ()=>{
        this.setState({hideAll: false});
      }
    );
  }

  onNew(){
    var newNotes = [{
      note: "New Note",
      createAt: `${Date.now()}`,
      updateAt: `${Date.now()}`,
      status: "ACTIVE"
    }, ...this.props.notesList];
    this.onUpdateSiteSettings(newNotes, ()=>{
      this.setState({activeNote: 0})
    })
  }

  render(){
    var {activeNote, hideAll, hideInactive} = this.state;
    var {notesList=[]} = this.props;
    if(!notesList || !Array.isArray(notesList)) notesList = [];
          
    var ActiveNotes = notesList.slice().filter(note=>(note.status === "ACTIVE")).sort((a, b) => Number(a.updateAt) - Number(b.updateAt)).reverse();
    // .sort((a, b) => {
    //   var value1 = Number(a.note.updateAt);
    //   var value2 = Number(b.note.updateAt);
    //   if (value1 < value2) {
    //     return -1;
    //   }
    //   if (value1 > value2) {
    //     return 1;
    //   }
    //   return 0;
    // });

    var InActiveNotes = notesList.slice().filter(note=>(note.status != "ACTIVE")).sort((a, b) => Number(a.updateAt) - Number(b.updateAt)).reverse();
    // console.log("data");
    // console.log(data,"\n\n");
    if(hideAll) return null;     
    return (
      <div id="TodoList">
        <div className="lists">
          <div className="activeNotes">
            {
              (ActiveNotes && ActiveNotes.length > 0)
              ? ActiveNotes.map((note, index)=>{
                  return(
                    <QuickNote
                      key={index}
                      note={note}
                      onSetActive={()=>this.setState({activeNote: index})}
                      editing={((activeNote !== undefined) && (activeNote === index)) ? true : false}
                      onUpdate={newNote=>{
                        if(!newNote) return;
                        var newNotes = ActiveNotes.slice();
                        newNotes[index] = newNote;
                        this.onUpdateSiteSettings([...newNotes, ...InActiveNotes])
                      }}
                      skipHR={ActiveNotes.length === (index+1)}
                    />
                  )
                })
              : <div className="clickToAddNew" onClick={this.onNew}>Click to Add New</div>
            }
          </div>
          <div className="inactiveNotes">
            <div className="inactiveNotesTitle" onClick={()=>this.setState({hideInactive: !hideInactive})}><div className="text">Inactive List</div><i className={`fas fa-minus ${!hideInactive ? 'active' : ''}`}></i></div>
            <div className={(hideInactive ? "hidden" : "")}>
              {
                InActiveNotes.map((note, index)=>{
                  var noteNumber = (index+ActiveNotes.length+1);
                  return(
                    <QuickNote
                      key={index}
                      note={note}
                      onSetActive={()=>this.setState({activeNote: noteNumber})}
                      editing={((activeNote !== undefined) && (activeNote === noteNumber)) ? true : false}
                      onUpdate={newNote=>{
                        if(!newNote) return;
                        var newNotes = InActiveNotes.slice();
                        newNotes[index] = newNote;
                        this.onUpdateSiteSettings([...ActiveNotes, ...newNotes])
                      }}
                      onRemove={()=>{
                        InActiveNotes.splice(index, 1);
                        this.onUpdateSiteSettings([...ActiveNotes, ...InActiveNotes])
                      }}
                      skipHR={InActiveNotes.length === (index+1)}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="newNoteButtonWrapper">
          <button className="newNoteButton button button2" onClick={this.onNew}><i className="fas fa-notes-medical"></i> <span>New Note</span></button>
        </div>
      </div>  
    );
  }
}


class QuickNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };    
    this.onCheckBox = this.onCheckBox.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  onCheckBox(){
    var {note} = this.props;
    if(note.status === "ACTIVE") note.status = "INACTIVE";
    else note.status = "ACTIVE";
    if(!note.createAt) note.createAt = `${Date.now()}`;
    note.updateAt = `${Date.now()}`;
    this.props.onUpdate(note)
  }

  render(){
    var {skipHR, editing, note} = this.props;
    var curDate = new Date(Number(note.updateAt));
    var NoteStatusActive = ((note.status === "ACTIVE") ? true : false);
    return (
      <div className={`quickNoteWrapper ${NoteStatusActive ? 'active' : 'inactive'}`}>
        <div className="quickNote">
          <div className="icon" onClick={this.onCheckBox}>
            <i className="fas fa-square"></i>
            <i className={`far fa-${NoteStatusActive ? 'square' : 'check-square'}`}></i>
          </div>
          <div className="timeAndDate">
            <div className="title" ref={e=>this.titleHeightRef=e}>
              <div className="text">
                {
                  editing
                  ? <InputZone 
                      note={note} 
                      minHeight={(this.titleHeightRef ? this.titleHeightRef.offsetHeight : 0) + 20}
                      onUpdate={this.props.onUpdate}
                    />
                  : <span onClick={this.props.onSetActive}>{note.note}</span>
                }
              </div>
              {
                !NoteStatusActive && 
                <div className="trash" onClick={this.props.onRemove}>
                  <i className="fas fa-trash"></i>
                </div>
              }
            </div>
            <div className="date">
              <i className="fas fa-calendar"></i> <span>{curDate.formatDate('n/d/Y')}&nbsp;&nbsp;&nbsp;{curDate.formatDate('H:mA')}</span>
            </div>
          </div>
        </div>
        {!skipHR ? <hr/> : null}
      </div>
    );
  }
}



class InputZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: this.props.note,
    };    
  }

  componentDidMount(){
    if(this.inputRef) {
      this.inputRef.focus();
      this.inputRef.selectionStart = this.inputRef.selectionEnd = this.inputRef.value.length;
    }
  }

  componentWillUnmount(){
  }

  render(){
    var {note} = this.state;    
    return (
      <textarea 
        ref={e=>this.inputRef=e}
        type="text" 
        value={note.note} 
        style={{"minHeight":`${this.props.minHeight}px`}}
        onChange={(e)=>{
          note.note = e.target.value;
          if(!note.createAt) note.createAt = `${Date.now()}`;
          note.updateAt = `${Date.now()}`;
          this.setState({note: note});
        }}
        onBlur={()=>this.props.onUpdate(note)}
      />
    )
  }
}





export default Index;
      
