//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Clear aligners financial options and cost for invisible braces.",
			"DESCRIPTION": "Smiles Club provides Stripe pay later financial orthodontics options. See our clear aligner prices and discounts.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, clearbraces, clear-braces, invisible braces, best aligners, Stripe, Affirm, Afterpay, discount aligners, safest aligners, number one clear aligners, invisible aligners, safe plastics, discreetly, clearaligners alternative"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Price and Plans</div>
						<h1 class="sectionTitle">Custom treatment plan at less than half the price.</h1>
						<div class="">An Orthodontist will design a customized treatment plan and will show you some videos about the transformation of your smile along the treatment. You will decide on a payment option and your clear aligners will be fabricated and delivered to you. </div>
					`
				}
			],
			"TopMainSectionImages": [
				{
					"alt": "A woman's smile pulling out her clear aligners."
				}
			],
			"HowToPay": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PAYMENT OPTIONS</div>
						<h2 class="sectionTitle2">Your Choice, Your Way!</h2>
						<h3 class="sectionTitle3">Two easy ways to pay!</h3>
						<div class="subHeader">We offer the same or higher quality treatment you will get in a regular dental office at less than half the price. Our clear aligners are far less expensive than traditional braces. So say goodbye to financial barriers and embrace the possibilities of achieving your dream smile through our partnership with Affirm and Afterpay!</div>
					`
				}
			],
			"PaymentOptions": [
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fas fa-cash-register" style="color: #0070e0;"></i></div>
						<div class="typeTitle">Pay Now and Save Big!</div>
						<div class="paymentAmmount">
							<div class="paymentAmmountText"><span class="crossOut">$2,199</span> Grab it for <span class="discountPrice">$1,949</span></div>
							<div class="paymentAmmountText smallerText">(That's a $250 discount!)</div>
						</div>
						<div class="summary">Pay upfront using your Credit cards, bank account, FSA/HSA card, or simply pay with Affirm payment plans. Lock this amazing deal today and save more by paying all at once! Contact us if you have any questions or for more details.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<!--<div class="bigIcon"><i class="fab fa-stripe" style="color: #003087;"></i></div>-->
						<div class="typeTitle">SMILE NOW, Pay Later!</div>
						<div class="paymentAmmount">
							<div class="paymentAmmountText">Pay over time with Affirm</div>
							<div class="paymentAmmountText smallerText">(As low as $68/month!*)</div>
						</div>
						<div class="summary">Act Fast and choose the option that works best for YOU! Smiles Club is integrated with Affirm's "Buy now, pay over time" program. You can choose to split the cost into 6-36 monthly payments. Checking your eligibility won't affect your credit.**</div>
					`
				},
				{
					"type": "div",
					"className": "smallPrint",
					"content": `
						<span class="responsiveSmall">*The monthly payment you see displayed for an Affirm purchase is just an estimate and could change based on several factors, including how long you choose to pay for the item, the total price of the product, your creditworthiness as determined by Affirm, and other internal calculations Affirm uses to set your loan terms. The monthly price shown is not a guaranteed amount and may be higher or lower when you finalize your purchase with Affirm.</span>
						<br/>
						<span class="responsiveSmall">**Visit Affirm's official website at www.affirm.com, to learn more about their "Buy now, pay over time" program.</span>
					`
				}
			],
			"PaymentOptionsButtons": [
				{
					"type": "span",
					"content": `Buy Now`
				},
				{
					"type": "span",
					"content": `Buy Now`
				},
				{
					"type": "div",
					"content": `
						<div style="padding-top: 25px;">
							Choose
							<svg id="Affirm_2_Color_-_HEX" data-name="Affirm 2 Color - HEX" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428.55 171" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 70px;display: inline;vertical-align: baseline;padding: 0 4px;">
							<defs>
							<style>
							.cls-1,.cls-2{fill:#101820;}.cls-1,.cls-3{fill-rule:evenodd;}.cls-3{fill:#4a4af4;}
							</style>
							</defs>
							<path class="cls-1" d="M116.62,449.32c-5.3,0-8-2.61-8-6.9,0-8,8.92-10.69,25.19-12.42,0,10.66-7.21,19.32-17.24,19.32Zm7-59.95c-11.63,0-25,5.47-32.26,11.26l6.63,14c5.82-5.33,15.23-9.89,23.72-9.89,8.07,0,12.53,2.7,12.53,8.14,0,3.65-2.95,5.5-8.53,6.23-20.86,2.7-37.21,8.46-37.21,24.53,0,12.74,9.07,20.45,23.24,20.45,10.12,0,19.12-5.62,23.4-13v11H154V416c0-19-13.19-26.67-30.37-26.67Z" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M312.91,391.44V462h20.18V428c0-16.15,9.78-20.9,16.59-20.9a15.47,15.47,0,0,1,8.63,2.55L362,391a22.84,22.84,0,0,0-9-1.63c-10.37,0-16.89,4.59-21.19,13.93V391.44H312.91" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M455.52,389.37c-10.67,0-18.65,6.3-22.8,12.38-3.85-7.85-12-12.38-21.8-12.38-10.66,0-18.05,5.92-21.46,12.74V391.44H370V462H390.2V425.67c0-13,6.83-19.29,13.2-19.29,5.77,0,11.07,3.73,11.07,13.36V462h20.16V425.67c0-13.19,6.66-19.29,13.33-19.29,5.34,0,11,3.88,11,13.22V462h20.16V413.22c0-15.85-10.67-23.85-23.56-23.85" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M263.8,391.44H245.52v-7.17c0-9.34,5.33-12,9.92-12a20.18,20.18,0,0,1,9,2.25l6.22-14.23s-6.31-4.12-17.78-4.12c-12.89,0-27.56,7.27-27.56,30.08v5.19H194.77v-7.17c0-9.34,5.32-12,9.92-12a19.12,19.12,0,0,1,9,2.25l6.22-14.23c-3.71-2.17-9.68-4.12-17.77-4.12-12.89,0-27.56,7.27-27.56,30.08v5.19H162.9V407h11.71v55h20.16V407h30.59v55h20.16V407H263.8V391.44" transform="translate(-88.52 -293.05)">
							</path>
							<rect class="cls-2" x="187.32" y="98.39" width="20.14" height="70.53">
							</rect>
							<path class="cls-3" d="M276.58,379.45h19.73c11.51-36.19,50.56-68,97-68,56.48,0,105.29,43,105.29,109.94A137.58,137.58,0,0,1,493,462h19.15l.19-.66a162.2,162.2,0,0,0,4.74-39.89c0-74.65-54.4-128.38-123.73-128.38-54.46,0-103,37.8-116.76,86.4Z" transform="translate(-88.52 -293.05)">
							</path>
							</svg>
							at Checkout
						</div>
					`
				}
			],
			"PaymentTypes": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PAYMENT TYPES</div>
						<h2 class="sectionTitle2">We accept </h2>
						<div class="subHeader">Regular credit cards, Stripe, Affirm, Cashapp, Amazon Pay, Google Pay, Afterpay, FSA, HSA, Bank Accounts ACH, And Bank wires.</div>
					`
				}
			],
			"PaymentTypesButton": [
				{
					"type": "span",
					"content": `Get Started`
				}
			],
			"PaymentTypesImages": [
				{
					"alt": "Amex payment Icon"
				},
				{
					"alt": "Stripe payment Icon"
				},
				{
					"alt": "Affirm payment Icon"
				},
				{
					"alt": "Cashapp payment Icon"
				},
				{
					"alt": "Discover payment Icon"
				},
				{
					"alt": "Google payment Icon"
				},
				{
					"alt": "Amazon payment Icon"
				},
				{
					"alt": "Afterpay payment Icon"
				},
				{
					"alt": "Visa payment Icon"
				},
				{
					"alt": "Mastercard payment Icon"
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
			"DESCRIPTION": "Sonrisas Club proporciona opciones de ortodoncia financiera de pago posterior de Affirm.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenostransparentes, frenos transparentes, frenos-transparentes, frenos invisibles, los mejores alineadores, pago posterior de Affirm, Stripe, alineadores con descuento, los alineadores más seguros, los alineadores transparentes número uno, los alineadores-invisibles, los plásticos seguros, discretamente, la alternativa de los alineadores transparentes"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PRECIO Y PLANES</div>
						<h1 class="sectionTitle">Plan de tratamiento personalizado a menos de la mitad del precio.</h1>
						<div class="">Un Ortodoncista diseñará un plan de tratamiento personalizado y te mostrará algunos videos sobre la transformación de tu sonrisa a lo largo del tratamiento. Tu decidirás una opción de pago y tus alineadores transparentes serán fabricados y enviados a tu domicilio.</div>`
				}
			],
			"TopMainSectionImages": [
				{
					"alt": "La sonrisa de una mujer sacándose los alineadores transparentes."
				}
			],
			"HowToPay": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">OPCIONES DE PAGO</div>
						<h2 class="sectionTitle2">¡Tu elección, a tu manera!</h2>
						<h3 class="sectionTitle3">¡Dos formas fáciles de pagar!</h3>
						<div class="subHeader">Ofrecemos el mismo tratamiento de calidad o superior que recibirá en un consultorio dental habitual por menos de la mitad del precio. Nuestros alineadores transparentes son mucho menos costosos que los aparatos ortopédicos tradicionales. ¡Diga adiós a las barreras financieras y aproveche las posibilidades de lograr la sonrisa de sus sueños a través de nuestra asociación con Affirm y Afterpay!</div>
					`
				}
			],
			"PaymentOptions": [
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fas fa-cash-register" style="color: #0070e0;"></i></div>
						<div class="typeTitle">¡Pague ahora y ahorre en grande!</div>
						<div class="paymentAmmount">
							<div class="paymentAmmountText"><span class="crossOut">$2,199</span> Cómpralo por <span class="discountPrice">$1,949</span></div>
							<div class="paymentAmmountText smallerText">(Eso es un descuento de $250!)</div>
						</div>
						<div class="summary">Pague por adelantado con sus tarjetas de crédito, cuenta bancaria, tarjeta FSA/HSA o simplemente pague con los planes de pago Affirm. ¡Bloquee esta increíble oferta hoy y ahorre más pagando todo de una vez! Contáctenos si tiene alguna pregunta o para más detalles.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<!--<div class="bigIcon"><i class="fab fa-stripe" style="color: #003087;"></i></div>-->
						<div class="typeTitle">¡SONRÍE AHORA, paga después!</div>
						<div class="paymentAmmount">
							<div class="paymentAmmountText">Pague a lo largo del tiempo con Affirm</div>
							<div class="paymentAmmountText smallerText">(¡Tan bajo como $68/mes!*)</div>
						</div>
						<div class="summary">¡Actúa rápido y elige la opción que mejor funcione para TI! Smiles Club está integrado con el programa "Compre ahora, pague con el tiempo" de Affirm. Puede optar por dividir el costo en entre 6 y 36 pagos mensuales. Verificar su elegibilidad no afectará su crédito.**</div>
					`
				},
				{
					"type": "div",
					"className": "smallPrint",
					"content": `
						<span class="responsiveSmall">*El pago mensual que ve para una compra de Affirm es solo una estimación y podría cambiar según varios factores, incluido el tiempo que elija pagar por el artículo, el precio total del producto, su solvencia crediticia determinada por Affirm y otros cálculos internos que Affirm utiliza para establecer los términos de su préstamo. El precio mensual que se muestra no es una cantidad garantizada y puede ser mayor o menor cuando finalice su compra con Affirm.</span>
						<br/>
						<span class="responsiveSmall">**Visite el sitio web oficial de Affirm en www.affirm.com para obtener más información sobre su programa "Compre ahora, pague con el tiempo".</span>
					`
				}
			],
			"PaymentOptionsButtons": [
				{
					"type": "span",
					"content": `Comprar`
				},
				{
					"type": "span",
					"content": `Comprar`
				},
				{
					"type": "div",
					"content": `
						<div style="padding-top: 25px;">
							Elija
							<svg id="Affirm_2_Color_-_HEX" data-name="Affirm 2 Color - HEX" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428.55 171" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 70px;display: inline;vertical-align: baseline;padding: 0 4px;">
							<defs>
							<style>
							.cls-1,.cls-2{fill:#101820;}.cls-1,.cls-3{fill-rule:evenodd;}.cls-3{fill:#4a4af4;}
							</style>
							</defs>
							<path class="cls-1" d="M116.62,449.32c-5.3,0-8-2.61-8-6.9,0-8,8.92-10.69,25.19-12.42,0,10.66-7.21,19.32-17.24,19.32Zm7-59.95c-11.63,0-25,5.47-32.26,11.26l6.63,14c5.82-5.33,15.23-9.89,23.72-9.89,8.07,0,12.53,2.7,12.53,8.14,0,3.65-2.95,5.5-8.53,6.23-20.86,2.7-37.21,8.46-37.21,24.53,0,12.74,9.07,20.45,23.24,20.45,10.12,0,19.12-5.62,23.4-13v11H154V416c0-19-13.19-26.67-30.37-26.67Z" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M312.91,391.44V462h20.18V428c0-16.15,9.78-20.9,16.59-20.9a15.47,15.47,0,0,1,8.63,2.55L362,391a22.84,22.84,0,0,0-9-1.63c-10.37,0-16.89,4.59-21.19,13.93V391.44H312.91" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M455.52,389.37c-10.67,0-18.65,6.3-22.8,12.38-3.85-7.85-12-12.38-21.8-12.38-10.66,0-18.05,5.92-21.46,12.74V391.44H370V462H390.2V425.67c0-13,6.83-19.29,13.2-19.29,5.77,0,11.07,3.73,11.07,13.36V462h20.16V425.67c0-13.19,6.66-19.29,13.33-19.29,5.34,0,11,3.88,11,13.22V462h20.16V413.22c0-15.85-10.67-23.85-23.56-23.85" transform="translate(-88.52 -293.05)">
							</path>
							<path class="cls-1" d="M263.8,391.44H245.52v-7.17c0-9.34,5.33-12,9.92-12a20.18,20.18,0,0,1,9,2.25l6.22-14.23s-6.31-4.12-17.78-4.12c-12.89,0-27.56,7.27-27.56,30.08v5.19H194.77v-7.17c0-9.34,5.32-12,9.92-12a19.12,19.12,0,0,1,9,2.25l6.22-14.23c-3.71-2.17-9.68-4.12-17.77-4.12-12.89,0-27.56,7.27-27.56,30.08v5.19H162.9V407h11.71v55h20.16V407h30.59v55h20.16V407H263.8V391.44" transform="translate(-88.52 -293.05)">
							</path>
							<rect class="cls-2" x="187.32" y="98.39" width="20.14" height="70.53">
							</rect>
							<path class="cls-3" d="M276.58,379.45h19.73c11.51-36.19,50.56-68,97-68,56.48,0,105.29,43,105.29,109.94A137.58,137.58,0,0,1,493,462h19.15l.19-.66a162.2,162.2,0,0,0,4.74-39.89c0-74.65-54.4-128.38-123.73-128.38-54.46,0-103,37.8-116.76,86.4Z" transform="translate(-88.52 -293.05)">
							</path>
							</svg>
							al finalizar la compra
						</div>
					`
				}
			],
			"PaymentTypes": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">FORMAS DE PAGO</div>
						<h2 class="sectionTitle2">Aceptamos</h2>
						<div class="subHeader">Tarjetas de crédito regulares, Stripe, Affirm, Cashapp, Amazon Pay, Google Pay, Afterpay, FSA, HSA, cuentas bancarias ACH y transferencias bancarias</div>
						`
				}
			],
			"PaymentTypesButton": [
				{
					"type": "span",
					"content": `Contáctanos`
				}
			],
			"PaymentTypesImages": [
				{
					"alt": "El symbolo de Amex"
				},
				{
					"alt": "El symbolo de Stripe"
				},
				{
					"alt": "El symbolo de Affirm"
				},
				{
					"alt": "El symbolo de Cashapp"
				},
				{
					"alt": "El symbolo de Discover"
				},
				{
					"alt": "El symbolo de Google"
				},
				{
					"alt": "El symbolo de Amazon"
				},
				{
					"alt": "El symbolo de Afterpay"
				},
				{
					"alt": "El symbolo de Visa"
				},
				{
					"alt": "El symbolo de Mastercard"
				}
			]
		}
	}
}


	
export default PAGE;
