import React, {forwardRef} from 'react';
import { useNavigate } from "react-router-dom";
//import { gql, useQuery, useMutation } from '@apollo/client';
//import TemplateSlideInRight from "../templateSlideInRight/index";
import List from "./list";
//import SlideInRight from 'global/components/SlideInRight/index';
//?FILTER=

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      filterVariables: {
        "status": "NOTINACTIVE",
        "sortBy": "meta.createAt",
        "descending": false,
        "limit": 20,
        "offset": 0,
        "fbd": {
          "active": false,
          "field": false,
          "gt": undefined,
          "gte": false,
          "lt": undefined,
          "lte": false
        },
        ...(props.VARIABLES ? props.VARIABLES : {}),
      },
      filterIsValid: true,
      listHeaderFields: this.props.LIST.LIST_HEADER_FIELDS.slice(),
      PAGEID: this.props.PAGEID
    };    
          
    if(this.props.query) this.state.filterVariables.query = this.props.query;
  }
  
  componentDidMount(){
    this.TemplateListStateKey = window.GlobalUtil.State.subscribe("templateList", (newState)=>{ //THIS SETS AND LISTENS TO SITE STATE
      this.setState(newState);
    });
    window.GlobalUtil.State.set("templateList", this.state);
  }

  componentWillUnmount(){
    this.TemplateListStateKey.unsubscribe()
  }


  getRedirectLocation = () => {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var REDIR = urlParams.get('FILTER');
    if(REDIR.slice(0,1) !== "/") REDIR = "/"+REDIR;
    //console.log("REDIR","\n\n",REDIR,"\n\n");
    //console.log(`REDIR`,"\n\n",REDIR,"\n\n");
    if(REDIR){
      window.GlobalUtil.setGlobalVariable("REDIR", REDIR);
      this.getUser()
    } else {
      this.props.navigation(((window.Session.Language === "ENGLISH") ? "/login" : "/acceso"));
    }
  }

  setStateCallback = ()=>{
    window.CurRefetchList(true);
    window.GlobalUtil.State.set("templateList", this.state);
  }

  updateQuery = (argument, query) => {
    var {filterVariables} = this.state;
    filterVariables.query = query;
    filterVariables.argument = argument;
    this.setState({filterVariables}, this.setStateCallback);
  }

  addAnyFilterDates = (filterVariables) => {
    if(!filterVariables.fbd.active) return filterVariables;
          
    var tempNewDateFilter = (filterVariables.dates ? filterVariables.dates.slice() : []);
    var tempNewDateToAdd = {
      field: filterVariables.fbd.field
    }
    if(filterVariables.fbd.gt){ //ADD GREATER THAN DATE IF IT EXIST
      if(filterVariables.fbd.gte) tempNewDateToAdd.gte = `${filterVariables.fbd.gt}`;
      else tempNewDateToAdd.gt = `${filterVariables.fbd.gt}`;
    }
    if(filterVariables.fbd.lt){ //ADD LESS THAN DATA IF IT EXIST
      if(filterVariables.fbd.lte) tempNewDateToAdd.lte = `${filterVariables.fbd.lt}`;
      else tempNewDateToAdd.lt = `${filterVariables.fbd.lt}`;
    }
    tempNewDateFilter.push(tempNewDateToAdd)
    return {
      ...filterVariables,
      dates: tempNewDateFilter
    };
  }

  onUpdateFilter = (newFilter) => {
    newFilter.descending = window.GlobalUtil.inputToBool(newFilter.descending);
    if(newFilter.limit !== undefined) newFilter.limit = Number(newFilter.limit);
    this.setState({filterVariables: newFilter}, this.setStateCallback);
  }


  getTrueListOfFields = (CurTableSettings, LIST_HEADER_FIELDS) => {
    var TrueListOfFields = [];
    CurTableSettings.map((PATH, index)=>{ //GET'S A LIST OF VISIBLE PATHS AND THEIR ORDER
      var FoundItem = LIST_HEADER_FIELDS.filter(obj=>(obj.path === PATH))[0];
      if(FoundItem) TrueListOfFields.push(FoundItem);
    })
    return(TrueListOfFields)
  }


  render(){
    var {filterVariables, filterIsValid, showFilter, showTableSettings} = this.state;
    var {LIST, BUTTONS, PAGEID, ACTIVEITEM, SHOWSELECTBOXES} = this.props;
    var tableSettingsObj = (window.SiteSettings.tableSettings ? JSON.parse(window.SiteSettings.tableSettings) : {});
    var CurTableSettings = tableSettingsObj[PAGEID];
    var TrueListOfFields = CurTableSettings ? this.getTrueListOfFields(CurTableSettings, LIST.LIST_HEADER_FIELDS) : LIST.LIST_HEADER_FIELDS; 
              
    return (
      <div className="template1">
        <List 
          {...LIST}
          ACTIVEITEM={ACTIVEITEM}
          LIST_HEADER_FIELDS={TrueListOfFields}
          onUpdateFilter={this.onUpdateFilter}
          filterVariables={this.addAnyFilterDates(filterVariables)}
          Buttons={BUTTONS}
          SHOWSELECTBOXES={SHOWSELECTBOXES}
          //changeSort={this.onChangeSort}
        /> 
      </div>
    );
  }
}



const withNavigateHook = forwardRef(function WithNavigateHook(props, ref) {
  const navigation = useNavigate();
  return (
    <Index {...props} navigation={navigation} ref={ref} />
  );
});


export default withNavigateHook;