//import React from 'react';
const PAGE = [
  {
    "content": "Download All"
  },
  {
    "content": "Downloading"
  },
  {
    "content": "Downloaded"
  }
];




  
export default PAGE;