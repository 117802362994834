import React from 'react';
import SpanishFlag from "global/img/Bandera_de_España2.svg";
import USAFlag from "global/img/Flag_of_the_United_States2.svg";



class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Language: this.props.Language
    };
  }

  componentDidMount(){
    if(!this.state.Language) this.setState({Language: window.Session.Language})
  }

  componentWillUnmount(){
  }



  render(){
    var {isValid=false, Language} = this.state;
    if(typeof window === 'undefined') return( //SERVER SIDE
      <div className="languageMenu d-flex">
        {
          (Language === "ESPAÑOL")
          ? <div className={"languageMenuItem "}><div>English</div><img src={USAFlag} alt="" /></div>
          : <div className={"languageMenuItem "}><div>ESPAÑOL</div><img src={SpanishFlag} alt="" /></div>
        }
      </div>
    );


    //console.log(`Language`,"\n\n",Language,"\n\n");
          
    //var {data} = this.props;
    return(
      <div className="languageMenu d-flex">
        {
          (Language === "ESPAÑOL")
          ? <div className={"languageMenuItem "} 
              onClick={()=>{
                // if(this.props.onChangeLanguage) this.props.onChangeLanguage("ENGLISH"); else 
                window.GlobalUtil.triggerEvent("LanguageEvent", "ENGLISH");
              }}><div>English</div><img src={USAFlag} alt="" />
            </div>
          : <div className={"languageMenuItem "} 
              onClick={()=>{
                // if(this.props.onChangeLanguage) this.props.onChangeLanguage("ESPAÑOL"); else 
                window.GlobalUtil.triggerEvent("LanguageEvent", "ESPAÑOL");
              }}><div>ESPAÑOL</div><img src={SpanishFlag} alt="" />
            </div>
        }
      </div>
    )
  }
}





// class Component extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     };
//   }

//   componentDidMount(){
//   }

//   componentWillUnmount(){
//   }



//   render(){
//     var {isValid=false} = this.state;
//     var {Language} = this.props;
//     //console.log(`Language`,"\n\n",Language,"\n\n");
          
//     if(!Language) Language = window.Session.Language;
//     //var {data} = this.props;
//     return(
//       <div className="languageMenu d-flex">
//         <div className={"languageMenuItem " + (Language === "ESPAÑOL" ? "active" : "")} onClick={()=>{
//           if(Language !== "ESPAÑOL"){
//             if(this.props.onChangeLanguage) this.props.onChangeLanguage("ESPAÑOL");
//             else window.GlobalUtil.triggerEvent("LanguageEvent", "ESPAÑOL");
//           }
//         }}><div>Esp</div><img src={SpanishFlag} alt="" /></div>
//         <div className={"languageMenuItem " + (Language === "ENGLISH" ? "active" : "")} onClick={()=>{
//           if(Language !== "ENGLISH"){
//             if(this.props.onChangeLanguage) this.props.onChangeLanguage("ENGLISH");
//             else window.GlobalUtil.triggerEvent("LanguageEvent", "ENGLISH");
//           }
//         }}><div>Eng</div><img src={USAFlag} alt="" /></div>
//       </div>
//     )
//   }
// }

export default Component;