import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import API_CALLS from 'API_CALLS/index';
import ImpressionKit from 'global/img/impressionKit/impressionKit.jpg';
import ReactToPrint from 'react-to-print';
import {CodeScanner} from './SendAligners';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
/*

//GET AND SHOW CURRENT USER
//CREATE NEW OBJECT FOR NEW TREATMENT PLAN
//SAVE NEW PLAN
//SEND DOCTOR AN EMAIL WITH A LINK
//CC DAD ON THE EMAIL WITH THE LINK
//CLOSE CURRENT TASK
//CREATE NEW TASK WITH (UPLOAD_TREATMENT_PLAN)
  //USER ID (UID)
  //ALIGNMENT PLAN ID (TPID)
  //


*/
const PREVIEW = API_CALLS.ORDER_ITEMS.PREVIEW;

const FORM_FIELDS = (obj)=>{
  return [
    {
      title: <div className="formTitle"><span className="">Tracking Number</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The number for the user to track their shipment</span></div>,
      type: "text",
      name: "trackingNumber",
      required: false,
      defaultValue: "",
      formatInput: (inputValue)=>{
        if(!inputValue) return inputValue;
        return inputValue.toUpperCase();
      },
      errorMessage: "Name is required"
    },
    {
      title: <div className="formTitle"><span className="">Tracking Company</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The name of the company who we used to ship the package.</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.ORDER_ITEMS.QUERIES.distinct(),
          variables: {
            field: "trackingCompany" //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          //console.log("result.data","\n\n",result.data,"\n\n");
          var fullOptionList = result.data.orderItemDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          fullOptionList = fullOptionList.filter((obj) => (obj.value));
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
            {
              "value": "USPS",
              "title": "USPS"
            },
            {
              "value": "UPS",
              "title": "UPS"
            }
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          window.GlobalUtil.consoleLogNew({LOCATION:`src/admin/pages/shipping/actions/SendProduct`, NAME:`uniqueArray`, CONTENT:[uniqueArray]});
          callBack("options", uniqueArray)
        });
      },
      name: "trackingCompany",
      required: false,
      defaultValue: "",
      defaultValuePath: "trackingCompany",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Send Email</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Should we send an email to user telling them their order was sent?</span></div>,
      type: "toggle",
      name: "sendEmail",
      borderBottom: true,
      borderTop: true,
      defaultValue: false,
      defaultValuePath: "sendEmail"
    }
  ]
}



class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    }; 
    this.validate = this.validate.bind(this);
    this.APIGet = this.APIGet.bind(this);
    this.APIUpdate = this.APIUpdate.bind(this);
  }

  validate(dontFinish, callback){
    var curObj = {...this.curObj};
    curObj = window.GlobalUtil.stripOutFromObj(curObj, "__typename")
    Object.keys(curObj).map((key, index)=>{
      if((curObj[key]  === undefined) || (curObj[key]  === "")) delete curObj[key] 
    })
    //console.log("Saved","\n\n",curObj,"\n\n");
    this.APIUpdate(curObj, dontFinish, callback);
  }

  APIGet(){       
    this.curObj = {
      id: this.props.curObj._id, 
      trackingNumber: this.props.curObj.trackingNumber, 
      trackingCompany: this.props.curObj.trackingCompany,
      sendEmail: false
    };    
    window.CurObj = {...this.curObj};
    var inputObj = {
      query: API_CALLS.USERS.QUERIES.single(),
      variables: {
        id: this.props.curObj.UID
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;            
      var CurUserObj = window.GlobalUtil.stripOutFromObj(data.user, "__typename");
      console.log("CurUserObj","\n\n",CurUserObj,"\n\n");
      if(!this) return;
      this.setState({User: CurUserObj, hideForm: true}, ()=>{
        this.setState({hideForm: false})
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }


  APIUpdate(newObj, dontFinish, callback=()=>{}){
    var inputObj = {
      mutation: (dontFinish ? API_CALLS.ORDER_ITEMS.MUTATIONS.update() : API_CALLS.ORDER_ITEMS.MUTATIONS.shipped()),
      variables: (dontFinish ? newObj : {id: newObj.id, sendEmail: newObj.sendEmail})
    }
    console.log("What we're sending","\n\n",inputObj.variables,"\n\n");
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      if(!dontFinish) {
        window.CurRefetchList(true);
        callback()
        if(this.onClose) this.onClose(this.CurRef);
      }
    })
    .catch((error)=>{
      console.log("Reject APIUpdate shipped catch catch", error);
      if(this.onClose) this.onClose(this.CurRef);
    });
  }

  render(){
    var Table = this.Table;
    var ComponentToPrint = this.ComponentToPrint;
    var ToLabel = this.ToLabel;
    var {isValid, User={}, hideForm, ShowPreview} = this.state;    
    var OrderItem = this.props.curObj;
    var ItemsArray = (OrderItem.itemsStringArray ? JSON.parse(OrderItem.itemsStringArray) : []);
    var {address={}} = User;
    
    return (
      this.SlideInRight(
        "Ship Product", 
        (curRef)=>{
          return(
            <React.Fragment>
              <div className="buttonGroup">
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
                <button className={`button button1`} onClick={()=>{ 
                  this.validate();
                }}>Shipped <i className="fas fa-shipping-fast"></i></button>
              </div>
              <DialogProgressLogs
                ID={this.props.curObj._id}
                API_NAME={'ORDER_ITEMS'}
                FIELD={'orderItem'}
                asButton={true}
                className={'button button1 btn-color4'}
                style={{"width":"100px","minWidth":"0"}}
                customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
              />
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div className="">
              <div className="PreviewForm">
                {
                  User.address &&
                  this.previewUser(User)
                }
                <Row className="">
                  <Column className="" sm="" md="" lg="" xl="">
                    <div className="previewFormFieldTitles">
                      <div className="previewFormFieldTitle">What to ship</div>
                    </div>
                    <Table
                      HEADERS={["Image", "Name", "Aligner ID", "Check QR"]}
                      ROWS={ItemsArray.map((item, index)=>{
                        return(
                          [
                            (<img src={item.img} alt="" className="" style={{width: "80px", "margin": "0 0 0 -12px"}} />),
                            "Aligner",
                            <a href="" style={{"fontWeight": "500"}} onClick={(e)=>{
                              e.preventDefault();
                              this.setState({
                                ShowPreview: ()=><PREVIEW
                                  VARIABLES={{
                                    id: item.id
                                  }}
                                />
                              });
                            }}>{item.id}</a>,
                            (<CodeScanner id={item.id}/>)
                          ]
                        )
                      })}
                    />
                  </Column>
                </Row>
                <Row className="">
                  <Column className="" sm="" md="" lg="" xl="">
                    <div className="previewFormFieldTitles">
                      <div className="previewFormFieldTitle">Shipping Label</div>
                    </div>
                    <div className="">
                      <Row className="">
                        {/*<Column className="" col="12" sm="12" md="6" lg="6" xl="6">
                          <FromLabel />
                          <br/>
                        </Column>*/}
                        <Column className="" col="12" sm="12" md="12" lg="12" xl="12">
                          <ToLabel User={User}/>
                          <br/>
                          <div className="buttonGroup right">
                            <ReactToPrint
                              trigger={() => <button className="button button1">Print Shipping Address</button>}
                              content={() => this.CompToPrintRef}
                            />
                          </div>
                        </Column>
                      </Row>
                      <div style={{ display: "none" }}>
                        <ComponentToPrint
                          ref={e=>this.CompToPrintRef=e}
                          copyStyles={true}
                          User={User}
                        />
                      </div>
                    </div>
                  </Column>
                </Row>
                <div className="previewFormFieldTitles">
                  <div className="previewFormFieldTitle">Tracking Information</div>
                </div>
              </div>
              {this.FormBuilderZone(FORM_FIELDS)}
            </div>
          )
        }
      )
    );
  }
}






export default Index;