import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import LogoIcon from 'global/img/logo_icon.svg';
import LogoDark from 'global/img/logo-dark.svg';
import CartButton from 'website/components/CartButton/index';
//import LanguageToggle from 'global/components/LanguageToggle/index';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;


export default class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  resetNav(){
  }

  render(){
    return (
      <div id="Header">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="mobileHeader" col="" sm="" sm="" md="" lg="" xl="">
              <NavLink className="siteLogo" to={"/user"}>
                <img src={LogoDark} alt=""/>
              </NavLink>
              {/* <CartButton /> */}
              <div className="mobileButtonBox">
                <i className="fas fa-bars" onClick={this.props.onShowMobileMenu} />
              </div>
            </Column>
            <Column className="mainHeader" col="" sm="" sm="" md="" lg="" xl="">
              <div className="info d-flex">
                {/*<div id="UserSideLanguageToggle">
                  <LanguageToggle 
                    Language={window.Session.user.preferredLanguage}
                    onChangeLanguage={(newLan)=>{
                      this.props.onUpdateUser({
                        preferredLanguage: newLan,
                        id: window.Session.user._id
                      }, ()=>{
                        window.GlobalUtil.triggerEvent("LanguageEvent", newLan)
                      })
                  }}/>
                </div>*/}
                {/* <CartButton /> */}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}




