import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
/*

//GET AND SHOW CURRENT USER
//CREATE NEW OBJECT FOR NEW TREATMENT PLAN
//SAVE NEW PLAN
//SEND DOCTOR AN EMAIL WITH A LINK
//CC DAD ON THE EMAIL WITH THE LINK
//CLOSE CURRENT TASK
//CREATE NEW TASK WITH (UPLOAD_TREATMENT_PLAN)
  //USER ID (UID)
  //ALIGNMENT PLAN ID (TPID)
  //


*/



class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    }; 
    this.validate = this.validate.bind(this);
    this.APIGet = this.APIGet.bind(this);
    this.APIUpdate = this.APIUpdate.bind(this);
  }


  validate(dontFinish){
    var curObj = this.curObj;
    console.log("curObj","\n\n",curObj,"\n\n");
    this.APIUpdate(curObj, dontFinish);
  }



  APIGet(){       
    var inputObj = {
      query: this.props.API_CALLS.QUERY_SINGLE,
      variables: {
        id: this.props.curObj._id,
        include: [
          {
            name: "user"
          }
        ]
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;            
      var treatmentPlan = window.GlobalUtil.stripOutFromObj(data.treatmentPlan, "__typename");
      //console.log("window.CurObj","\n\n",window.CurObj,"\n\n");
      this.curObj = {
        ...this.curObj, 
        ...treatmentPlan,
        id: treatmentPlan._id
      };
      window.CurObj = {...this.curObj};
      if(!this) return;
      this.setState({
        Plan: treatmentPlan,
        User: window.GlobalUtil.deepGetFromString(treatmentPlan, "user", false),
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false})
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }


  APIUpdate(newObj, dontFinish){
    if(!dontFinish){
      newObj.rejectStepNotes = "";//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      newObj.changeStep = 1;//MOVE TO NEXT STEP WHEN DONE
    }
    var inputObj = {
      mutation: this.props.API_CALLS.MUTATION_UPDATE,
      variables: newObj
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      if(!dontFinish) {
        window.CurRefetchList(true);
        window.FlashMenuItem("aligners");
        this.onClose(this.CurRef);
      }
    })
    .catch((error)=>{
      console.log("onCloneCatch catch", error);
      this.onClose(this.CurRef);
    });
  }

  render(){
    var PREVIEWPLAN = this.PREVIEWPLAN;
    var {isValid, Plan={}, User, hideForm, ShowPreview} = this.state;    
    return (
      this.SlideInRight(
        "Request Full Treatment Plan", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <div className="buttonGroup">
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
              </div>
              <div className="buttonGroup">
                <button className={`button button1`} onClick={()=>{ 
                  this.validate();
                }}>Sent Request <i className="fas fa-dollar-sign"></i></button>
                <DialogProgressLogs
                  ID={this.props.curObj._id}
                  API_NAME={'TREATMENT_PLANS'}
                  FIELD={'treatmentPlan'}
                  asButton={true}
                  className={'button button1 btn-color4'}
                  style={{"width":"100px","minWidth":"0"}}
                  customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                />
              </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div className="">
              {
                Plan.rejectStepNotes &&
                <div id="ErrorNotes">
                  <h2>REJECTED REASON:</h2>
                  {Plan.rejectStepNotes}
                </div>
              }
              <Row>
                <Column 
                  className={""}>
                    <PREVIEWPLAN
                      VARIABLES={{
                        id: Plan._id
                      }}
                      //PREVIEW_FORM={DEFAULT_PLAN_PREVIEW_FORM}
                    />
                </Column>
              </Row>
              <div className="PreviewForm">
                {
                  User &&
                  this.previewUser(User)
                }
              </div>
            </div>
          )
        }
      )
    );
  }
}









export default Index;