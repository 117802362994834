//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="sectionTitle">Confirmación de Email</h1>`
	},
	{
		"content": "Actualizando"
	},
	{
		"content": "Actualizando Su correo electrónico."
	},
	{
		"type": "div",
		"content": `<h4 class="">
      HA FALLADO: No se pudo confirmar la dirección de correo electrónico.
    </h4>`
	},
	{
		"type": "div",
		"content": `<h4 class="">
      ÉXITO: Su dirección de correo electrónico ha sido confirmada.
    </h4>`
	}
];




	
export default PAGE;