import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import QUERIES from "./QUERIES";
import MUTATIONS from "./MUTATIONS";
import GIVEAWAYS from "../GIVEAWAYS/index";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();


const FORM_FIELDS = (obj={})=>{
  //const MUTATION_EDIT = MUTATIONS.update();      
  const QUERY_GET_DISTINCT = QUERIES.distinct();  
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">Giveaway ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Must have a Giveaway attached to every contestant</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: GIVEAWAYS.QUERIES.all(`name`)
          })
          .then(result => {
            var Options = result.data.giveaways.map((user, index)=>{
              return({
                "value": user._id,
                "title": user.name
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...Options])
            // console.log("Options");
            // console.log(Options,"\n\n");
                  
          });
        },
        name: "GID",
        required: true,
        defaultValue: "",
        defaultValuePath: "GID",
        errorMessage: "User ID is required"
      },
      {
        title: <div className="formTitle"><span className="">Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Name of Contestant</span></div>,
        type: "text",
        name: "name",
        required: true,
        defaultValue: "",
        defaultValuePath: "name",
        errorMessage: "Each contestant needs a First Name"
      },
      {
        title: <div className="formTitle"><span className="">Preferred Language</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What sort of communication was it?</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "type" //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {
            //console.log("result.data","\n\n",result.data,"\n\n");
            var fullOptionList = result.data.giveawayContDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "ENGLISH",
                "title": "ENGLISH"
              },
              {
                "value": "ESPAÑOL",
                "title": "ESPAÑOL"
              }
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            callBack("options", uniqueArray)
          });
        },
        name: "language",
        required: true,
        defaultValue: "",
        defaultValuePath: "language",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Email Address</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every contestant should have an email</span></div>,
        type: "email",
        name: "email",
        required: false,
        defaultValue: "",
        defaultValuePath: "email",
        errorMessage: "Each contestant needs an Email"
      },
      {
        title: <div className="formTitle"><span className="">Phone Number</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every contestant should have a phone number</span></div>,
        type: "phone",
        name: "phone",
        required: false,
        defaultValue: "",
        defaultValuePath: "phone",
        errorMessage: "Each contestant needs a Phone Number"
      },
      {
        title: <div className="formTitle"><span className="">Notes</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Add any general notes about this contestant</span></div>,
        type: "wizyWig",
        name: "notes",
        required: false,
        defaultValue: "",
        defaultValuePath: "notes",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Won?</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Did this contestant win?</span></div>,
        type: "toggle",
        name: "won",
        required: false,
        defaultValue: "",
        defaultValuePath: "won",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Winner</span></div>,
        subTitle: <div className="formSubTitle">Info about what they won, when, and what happened</div>,
        type: "conditional",
        condition: (curObj)=>{            
          return curObj.won;
          //return true;
        },
        listOfFields: [
          {
            title: <div className="formTitle"><span className="">Prize Name</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Name what they won here</span></div>,
            type: "text",
            name: "aboutWinnings,name",
            required: false,
            defaultValue: "",
            defaultValuePath: "aboutWinnings,name",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Description</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Describe what they won</span></div>,
            type: "wizyWig",
            name: "aboutWinnings,description",
            required: false,
            defaultValue: "",
            defaultValuePath: "aboutWinnings,description",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Cost</span></div>,
            subTitle: <div className="formSubTitle"><span className="">How much was this worth?</span></div>,
            type: "money",
            name: "aboutWinnings,cost",
            required: false,
            defaultValue: "",
            defaultValuePath: "aboutWinnings,cost",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Won At</span></div>,
            subTitle: <div className="formSubTitle"><span className="">When did they win this?</span></div>,
            type: "datePicker",
            name: "aboutWinnings,wonAt",
            required: false,
            defaultValue: "",
            defaultValuePath: "aboutWinnings,wonAt",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Accepted</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Did they accept the prize?</span></div>,
            type: "toggle",
            name: "aboutWinnings,accepted",
            required: false,
            defaultValue: "",
            defaultValuePath: "aboutWinnings,accepted",
            errorMessage: "Each contestant needs this for shipping"
          },
          // //THIS IS NEEDED BUT I'M LAZY SO ILL DO IT LATER
          // {
          //   title: <div className="formTitle"><span className="">Notes</span></div>,
          //   subTitle: <div className="formSubTitle"><span className="">Add any additional notes like records of contacting and what happened.</span></div>,
          //   type: "text",
          //   name: "aboutWinnings,notes",
          //   required: false,
          //   defaultValue: "",
          //   defaultValuePath: "aboutWinnings,notes",
          //   errorMessage: "Each contestant needs this for shipping"
          // }
        ]
      },
      {
        title: <div className="formTitle"><span className="">Address</span></div>,
        subTitle: <div className="formSubTitle">Contestant Address</div>,
        type: "conditional",
        condition: (curObj)=>{            
          //return curObj.won;
          return true;
        },
        listOfFields: [
          {
            title: <div className="formTitle"><span className="">Street</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Street address where things will be shipped</span></div>,
            type: "text",
            name: "address,street",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,street",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Street 2</span></div>,
            subTitle: <div className="formSubTitle"><span className="">You can use this for floor, suite, and unit numbers, etc.</span></div>,
            type: "text",
            name: "address,street2",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,street2",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">City</span></div>,
            subTitle: <div className="formSubTitle"><span className="">What is the locations city</span></div>,
            type: "text",
            name: "address,city",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,city",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">State</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Please choose a state</span></div>,
            type: "selectState",
            name: "address,state",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,state",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Country</span></div>,
            subTitle: <div className="formSubTitle"><span className="">What country is this going to</span></div>,
            type: "text",
            name: "address,country",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,country",
            errorMessage: "Each contestant needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Zipcode</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Please include your zipcode too</span></div>,
            type: "text",
            name: "address,zipcode",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,zipcode",
            errorMessage: "Each contestant needs this for shipping"
          }
        ]
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose one of the following status. INACTIVE means they were not interested.</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: true,
        defaultValue: "ACTIVE",
        defaultValuePath: "status",
        errorMessage: "Status is required"
      }
    ]
  )
};





export default FORM_FIELDS;