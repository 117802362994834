import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import API_CALLS from 'API_CALLS/index';
import ImageUtils from "global/utils/image-utils";
import LayoutBuilder from 'LayoutBuilder';
const IMAGE_UTILS =  new ImageUtils();

const {Container, Row, Column} = LayoutBuilder;
const MUTATION_EDIT = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();
/*

//DUMP ALL STL'S TO PLAN->alignerFilesTemp
//THEN IN THE NEXT SchedualAligners we will decided what goes where and what does not

*/






const FORM_FIELDS = (obj)=>{
  return [
    {
      title: <div className="formTitle"><span className="">All Aligner STL Files</span></div>,
      type: "fileUploadList",
      name: "alignerFilesTemp",
      maxNumber: 1,
      required: false,
      defaultValue: "",
      defaultValuePath: "alignerFilesTemp",
      maxWidth: "150px",
      rename: true,
      filePath: [
        "USERS",
        obj.UID,
        "TREATMENT_PLANS",
        obj.id,
        "ALIGNERS"
      ],
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "alignerFilesTemp", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain();
          callBack();
        })
    }
  ]
};

const MIN_TO_PULL = `
  _id
  UID
  OID
  alignerFilesTemp{
    createAt
    url
    name
    type
    id
    customName
  }
  HISTORY{
    LABORATORY{
      UPLOADED_OFFER
      UPLOADED_FULL
      PAID_FOR_OFFER
      PAID_FOR_FULL
    }
    DOCTOR{
      APPROVED_PLAN
      PAID_FOR_APPROVAL
    }
  }
  rejectStepNotes
`;


class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    };       
    this.validate = this.validate.bind(this);
    this.APIGet = this.APIGet.bind(this);
    this.APIUpdate = this.APIUpdate.bind(this);
  }


  validate(dontFinish=false){
    var curObj = {...this.curObj};
    Object.keys(curObj).map((key, index)=>{
      if((curObj[key]  === undefined) || (curObj[key]  === "")) delete curObj[key] 
    })
    curObj = window.GlobalUtil.stripOutFromObj(curObj, "__typename")
    //console.log("Saved","\n\n",curObj,"\n\n");
    /*
      SHOULD HAVE
        _id
        UID
        status
        offerRequest: {
          chiefComplaint String
          notes String
          createAt Date
        }
    */
    //return false;
    this.APIUpdate(curObj, dontFinish);
    //this.APICompleteTask(curObj);
  }


  APIGet(){       
    var inputObj = {
      query: API_CALLS.TREATMENT_PLANS.QUERIES.single(MIN_TO_PULL),
      variables: {
        id: this.props.curObj._id,
        include: [
          {
            name: "user"
          }
        ]
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;            
      var treatmentPlan = window.GlobalUtil.stripOutFromObj(data.treatmentPlan, "__typename");
      this.curObj = {
        ...this.curObj, 
        ...treatmentPlan,
        id: treatmentPlan._id
      };
      window.CurObj = {...this.curObj};
      //console.log("window.CurObj","\n\n",window.CurObj,"\n\n");
      if(!this) return;
      this.setState({
        Plan: treatmentPlan,
        User: window.GlobalUtil.deepGetFromString(treatmentPlan, "user", false),
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false})
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }


  


  APIUpdate(newObj, dontFinish){
    if(!dontFinish){
      newObj.rejectStepNotes = "";//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      newObj.changeStep = 1;//MOVE TO NEXT STEP WHEN DONE
      window.GlobalUtil.deepSetFromString(newObj, "HISTORY,LABORATORY,UPLOADED_FULL", `${Date.now()}`);
    }
    var inputObj = {
      mutation: MUTATION_EDIT,
      variables: newObj
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      if(!dontFinish) {
        window.CurRefetchList(true);
        this.onClose(this.CurRef);
      }
    })
    .catch((error)=>{
      console.log("onCloneCatch catch", error);
      this.onClose(this.CurRef);
    });
  }



  render(){
    var {showSlideIn, isValid, Plan={}, User, hideForm, isLoading} = this.state;                  
    return (
      this.SlideInRight(
        "Upload Aligners", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <button className="button button2 btn-color6" onClick={()=>{
                if(curRef) curRef.onClose()
              }}>Close</button>
              <div className="buttonGroup">
                <button className={`button button1 ${(isValid && !isLoading) ? "" : "disabled"}`} onClick={()=>{ 
                  this.validate();
                }}>Complete <i className="fas fa-tooth"></i></button>
                <DialogProgressLogs
                  ID={this.props.curObj._id}
                  API_NAME={'TREATMENT_PLANS'}
                  FIELD={'treatmentPlan'}
                  asButton={true}
                  className={'button button1 btn-color4'}
                  style={{"width":"100px","minWidth":"0"}}
                  customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                />
              </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div className="">
              <div className="PreviewForm">
                {
                  Plan.rejectStepNotes &&
                  <div id="ErrorNotes">
                    <h2>REJECTED REASON:</h2>
                    {Plan.rejectStepNotes}
                  </div>
                }
                {
                  User &&
                  this.previewUser(User)
                }
                {
                  Plan &&
                  this.previewPlan(Plan)
                }
                <Row>
                  <Column 
                    className={"borderColor5"}
                    style={{"paddingBottom":"0px"}}>
                  <div className="previewFormFieldTitles2">
                    <div className="previewFormFieldTitle">Needed Input</div>
                    <div className="previewFormFieldSubTitle">Fill out as much of the fields below as possible</div>
                  </div>
                  </Column>
                </Row>
              </div>
              {
                (this.curObj && this.curObj.id) && 
                this.FormBuilderZone(FORM_FIELDS)
              }
            </div>
          )
        }
      )
    );
  }
}




export default Index;