import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import TemplateListBasic from 'global/templates/templateListBasic/index';
import UploadAligners from './UploadAligners/index';
import ViewTreatmentPlanRequest from './ViewTreatmentPlanRequest/index';
import LayoutBuilder from 'LayoutBuilder';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Table} = LayoutBuilder;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideList: false,
      variables: {
        "status": "NOTINACTIVE",
        "sortBy": "meta.createAt",
        "descending": false,
        "limit": 10,
        "offset": 0,
        "manual": [
          {
            path: "LABORATORY",
            val: window.Session.user._id
          },
          {
            path: "step",
            val: "UPLOAD_ALIGNERS"
          }
        ],
        "include": [
          {
            name: "user"
          }
        ]
      }
    }; 
    //window.CurRefetchList
    // window.APIListGetAgain = ()=>{
    //   this.setState({hideList: true}, ()=>{
    //     this.setState({hideList: false})
    //   })
    // };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  onUpdateFilter = (newVariables)=>{
    this.setState({
      variables: newVariables,
    }, ()=>window.CurRefetchListVar2(true))
  }

  render(){
    var {hideList, variables} = this.state;
    var {TPList=[]} = this.props;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    return(
      <div className="orderList">
        <div className="template1">
          <TemplateListBasic 
            CurRefetchListVar={"CurRefetchListVar2"}
            QUERY={API_CALLS.TREATMENT_PLANS.QUERIES.allAndCountListFull()}
            VARIABLES={variables}
            dataField="treatmentPlans"
            onUpdateFilter={this.onUpdateFilter}
            CreateList={(data)=>{                    
              return(
                <div className="">
                  <OfferListFull
                    CONTENT={CONTENT}
                    fullList={data.treatmentPlans}
                    reloadList={()=>{
                      window.CurRefetchListVar2(true)
                    }}
                  />
                </div>
              )
            }}
            //changeSort={this.onChangeSort}
          /> 
        </div>
      </div>
    )
  }
}








function OfferListFull(props) {     
  var {fullList=[], CONTENT=[]} = props;
  return (
    <Table
      className="table"
      thead={{
        className:"thead-dark",
        tr: {
          th: [
            {
              //style: {"width": "210px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[0]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "210px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[1]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "91px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[2]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "121px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[3]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "50px"},
              className: "text-center buttonCategory",
              value: CONTENT[4]
            },
            {
              style: {"width": "110px"},
              className: "text-center buttonCategory",
              value: CONTENT[5]
            }
          ]
        }
      }}
      tbody={{
        tr: (
          fullList.length
          ? fullList.map((treatmentPlan, index)=>{
              var createdAt = new Date(Number(treatmentPlan.meta.createAt));
              var updateAt = new Date(Number(treatmentPlan.meta.updateAt));
              return(
                {
                  className: "animated fadeIn",
                  th: [
                    {
                      value: (
                        <div className="rowText">
                          <div className="">{treatmentPlan.user.firstName} {treatmentPlan.user.lastName}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="rowText">
                          <div className="value">{treatmentPlan._id}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="rowText">
                          <div className="value">{createdAt.formatDate("n/d/y")}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="rowText">
                          <div className="value">{updateAt.formatDate("n/d/y")}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="text-center buttonColumn">
                          <div className="rowText">
                            <ViewTreatmentPlanRequest 
                              curTreatmentPlan={{...treatmentPlan}}
                              curUser={{
                                ...treatmentPlan.user,
                                TPID: treatmentPlan._id
                              }}
                              reloadMainList={props.reloadList}
                              onCreate={()=><UploadAligners 
                                curTreatmentPlan={{...treatmentPlan}}
                                curUser={{
                                  ...treatmentPlan.user,
                                  TPID: treatmentPlan._id
                                }}
                                customButtonText={(onClick)=>{
                                  return(
                                    <div className="button button1 btn-color5 actionButton" style={{"margin":"0 5px 20px","display":"flex","alignItems":"center","justifyContent":"center"}} onClick={onClick}>
                                      <div className="text">{CONTENT[6]}&nbsp;</div>
                                      <div className="buttonIcon">
                                        <i className="fas fa-pencil-alt" style={{"cursor": "pointer"}}></i>
                                      </div>
                                    </div>
                                  )
                                }}
                                reloadMainList={props.reloadList}
                              />
                              }
                            />
                          </div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="text-center buttonColumn">
                          <div className="rowText">
                            <UploadAligners 
                              curTreatmentPlan={{...treatmentPlan}}
                              curUser={{
                                ...treatmentPlan.user,
                                TPID: treatmentPlan._id
                              }}
                              reloadMainList={props.reloadList}
                            />
                          </div>
                        </div>
                      )
                    }
                  ] 
                }
              )
            })
          :  [{
              className: "animated fadeIn",
              th: [
                {
                  value: (
                    <div className="rowText">
                      <div className="">{CONTENT[7]}</div>
                    </div>
                  )
                },
                {},
                {},
                {},
                {},
                {}
              ] 
            }]
        )
      }}
    />
  );
}





export default Index;
