import React, { useState, useRef } from 'react';
import Dropzone, {useDropzone} from 'react-dropzone'
import Loading from 'global/components/Loading/index';
import { gql, useQuery, useMutation } from '@apollo/client';
import axios from 'axios';
//import DownloadAllButton from 'global/components/DownloadAllButton';
import FileUploads from "global/utils/file-uploads";
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const FILE_UPLOAD_UTILS =  new FileUploads();
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}



/*
<FileDropZone
  fileList={[]}
  filePath={[ //ARRAY OF PATH, THE FILE NAME WILL BE TAKEN FROM THE FILE OBJ
    "USERS",
    obj.UID,
    "TREATMENT_PLANS",
    obj.TPID,
    "ALIGNERS"
  ]}
  DONT_DELETE_FROM_AMAZON=false //IF THIS IS TRUE THEN IT WILL ONLY DELETE THE LINK, NOT THE REAL FILE IN AMAZON
  maxFiles
  maxSize
  accept='image/jpeg, image/png, application/pdf'
  onChange={(fileList, callBack)=>{
    callBack(fullNewFileList);
  }}
  hideDeleteButtons={false}
/>
*/

class FileDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: this.props.fileList ? this.props.fileList : [],
      loading: true,
      activityClass: '',
      loadingArray: new Array(((this.props.fileList) ? this.props.fileList.length : 0)).fill(false)
    }
    this.InputRef = [];
  }

  componentDidMount(){          
    this.setState({loading: false}, ()=>{
      if(this.InputRef && window.curFocus && this.InputRef[window.curFocus]) {
        this.InputRef[window.curFocus].focus();
        this.InputRef[window.curFocus].select();
        window.curFocus = undefined;
      }
    });      
  }

  updateFiles = () => {       
    var fileList = this.state.fileList.slice();
    this.props.onChange(fileList, () => this.setState({fileList: fileList, loading: false, loadingArray: new Array(fileList.length).fill(false)}));      
  }

  uploadNewFile = (file) => {       
    var {loadingArray=[], fileList=[]} = this.state;   
    window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/FileDropZone/index uploadNewFile()`, NAME:"onChange", CONTENT:[file]});
    //if(acceptedFiles && acceptedFiles.length > 0 && addUpdateIndex !== undefined && this.props.field.onNewImageSelected) this.props.field.onNewImageSelected(acceptedFiles[acceptedFiles.length-1])
    //this.setState({loading: true});
    var currentFilePlace = fileList.length;
    var fileListNew = fileList.slice();
    fileListNew.push({name: file.name});
    this.setState({
      fileList: fileListNew
    });     

    return new Promise( (resolve, reject) => {            
      FILE_UPLOAD_UTILS.UploadFile([...this.props.filePath, file.name], file, 
        (newFileObj)=>{  //AFTER WE FINISH UPLOADING THE NEW FILE
          var fileListNew = this.state.fileList.slice();
          loadingArray[currentFilePlace] = false;
          fileListNew.splice(currentFilePlace, 1, newFileObj);
          window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/FileDropZone/index FILE_UPLOAD_UTILS()`, NAME:"FILE_UPLOAD_UTILS uploadNewFile", CONTENT:[currentFilePlace]});
          this.setState({
            fileList: fileListNew,
            loadingArray: loadingArray
          }, ()=>{
            resolve(true)
          });        
        }
      ); //END OF UPLOAD NEW IMAGE AND SAVE TO DB
    });
  }

  deleteFile = (index) => {      
    var fileList = this.state.fileList.slice();
    this.setState({loading: true});
    window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/FileDropZone/index deleteFile()`, NAME:"deleteFile fileList", CONTENT:[fileList]});
    if(this.props.DONT_DELETE_FROM_AMAZON){ //IF DONT_DELETE_FROM_AMAZON THEN JUST REMOVE THE URL FROM OUR DB AND NOT THE FILE FROM AMAZON
      console.log(`Deleting link and not real file found in amazon`,"\n\n");
      fileList.splice(index, 1); //SPLICE REMOVES A FILE AND UPDATES THE EXISITNG ARRAY
      this.props.onChange(fileList, () => this.setState({fileList: fileList, loading: false}));       
    } else {
      FILE_UPLOAD_UTILS.DeleteFile(fileList[index].url, "files", (condition, reason, val)=>{
        if(condition === "failed"){
          console.log(reason,"\n\n",val,"\n\n");
        } else {
          fileList.splice(index, 1);
        }
        this.props.onChange(fileList, () => this.setState({fileList: fileList, loading: false}));         
      });
    }
  }

  handleOnDrop = (acceptedFiles, fileRejections) => {
    window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/FileDropZone/index handleOnDrop()`, NAME:"acceptedFiles", CONTENT:[acceptedFiles]});
    window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/FileDropZone/index handleOnDrop()`, NAME:"fileRejections", CONTENT:[fileRejections]});
    window.GlobalUtil.triggerEvent("UploadingFiles", true);
    var newLoadingArray = new Array(acceptedFiles.length).fill(true);
    if(this.props.functionOnDrop) this.props.functionOnDrop()
    this.uploading = true;
    this.setState({
      activityClass: "",
      loadingArray: [...this.state.loadingArray, ...newLoadingArray] 
    });


    Promise.all(acceptedFiles.map((file, index)=>{
      return this.uploadNewFile(file)
    }))
    .then((values) => {   
      if(this.props.functionOnDropDone) this.props.functionOnDropDone()         
      this.uploading = false;
      var fileListNew = this.state.fileList.slice();
      this.props.onChange(fileListNew, () => this.setState({
        fileList: fileListNew, 
        loading: false
      }));  
      window.GlobalUtil.triggerEvent("UploadingFiles", false);
    });
  }

  render(){
    var {fileList=[], loading, activityClass, loadingArray} = this.state;          
    var {id, maxFiles, maxSize, accept, disabled, maxWidth, hideDeleteButtons, rename, currentFilesText, CustomFilePreview, hideBlanks, showCopyURL, showDownload, showDownloadAllButton} = this.props;
    var imageDiv = {...STYLES.imageDiv, ...(maxWidth ? {"maxWidth": maxWidth, "minWidth": maxWidth} : {})};
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 


    // var allDownload = fileList.slice();
    // if(showDownloadAllButton && (fileList.length > 0)){
    //   allDownload = allDownload.filter(obj=>obj.url);
    //   allDownload = allDownload.map((file, index)=>{
    //     var customName = (file.customName ? file.customName : file.name);
    //     return {
    //       url: file.url,
    //       title: `${customName}`,
    //       name: `${customName}`
    //     }
    //   });      
    // }
    // var styles = {
    //   ...STYLES.baseStyle,
    //   ...(isDragActive ? STYLES.activeStyle : {}),
    //   ...(isDragAccept ? STYLES.acceptStyle : {}),
    //   ...(isDragReject ? STYLES.rejectStyle : {})
    // };
    if(loading) return (
      <div className={`editableImage`}>
        <Loading
          header={undefined}
          body={undefined}
          floating={false}
          overlay={false}
          disabled={disabled}
          inlineStyles={{
            LoadingScreen: {
              "minHeight": "190px",
              "border": "1px solid rgb(221, 221, 221)",
              "borderRadius": "10px",
              "position": "relative",
              "paddingTop": "50px"
            },
            loadingIcon: {
              "maxWidth": "80px"
            },
            loadingHeader: {
              "fontSize":"18px",
              "marginBottom":"0",
              "paddingTop":"20px"
            }
          }}
        />
      </div>
    )
          
    return(
      <div className={`fileDropZone ${activityClass}`}>
        <Dropzone 
          maxFiles={maxFiles}
          maxSize={maxSize}
          accept={accept}
          onDrop={this.handleOnDrop} 
          onDragEnter={()=>{
            console.log("onDragEnter true")
            this.setState({activityClass: "active"});
          }}
          onDragLeave={()=>{
            console.log("onDragLeave true")
            this.setState({activityClass: ""});
          }}
        >
          {({getRootProps, getInputProps, isDragActive}) => (
            <section className="dropZoneWrapper">
              <div {...getRootProps({className:"dropZone"})}>
                <input {...getInputProps()} />
                <p>{CONTENT[0]}</p>
              </div>
            </section>
          )}
        </Dropzone>
        {
          (fileList.length > 0)
          ? <div className="currentFiles">
              <div className="header">{currentFilesText ? currentFilesText : CONTENT[1]}</div>
              <div className="files">
                {
                  fileList.map((file, index)=>{
                    if(hideBlanks && !file.url) return null; 
                    var customName = (file.customName ? file.customName : file.name);
                    return(
                      <div className="file" key={index}>
                        {
                          CustomFilePreview &&
                          CustomFilePreview(file)
                        }
                        {
                          (rename && !this.uploading)
                          ? <div className="fileName">
                              <input 
                                ref={e=> this.InputRef[index] = e}
                                type="text" 
                                style={{"width":"100%"}}
                                value={customName} 
                                onChange={(e)=>{
                                  var val = e.target.value;
                                  var fileListCopy = fileList.slice();
                                  fileListCopy[index].customName = val;
                                  this.setState({fileList: fileListCopy})
                                }}
                                onBlur={()=>{
                                  loadingArray[index] = true;
                                  this.setState({loadingArray})
                                  this.updateFiles();
                                }}
                                onFocus={()=>{
                                  window.curFocus=index;
                                }}
                              />
                            </div>
                          : <div className="fileName">{customName}</div>
                        }
                        {
                          loadingArray[index]
                          ? <div className="loading">
                              <div className="text">Loading </div>
                              <div className="iconSpin"><i className="fas fa-circle-notch"></i></div>
                            </div>
                          : <React.Fragment>
                              <div className="actionButtons">
                                <div className="trash">
                                  <i className="fas fa-trash-alt" onClick={()=>{if(!this.props.hideDeleteButtons) this.deleteFile(index)}}></i>
                                </div>
                                {
                                  showDownload && file.url && 
                                  <div className="download">
                                    <a href={file.url} target="_blank" download={customName}><i className="fas fa-solid fa-download"></i></a>
                                  </div>
                                }
                                {
                                  showCopyURL && file.url && 
                                  <div className="download">
                                    <a onClick={()=>{
                                      window.GlobalUtil.copyToClipboard(file.url)
                                    }}><i className="fas fa-solid fa-clipboard"></i></a>
                                  </div>
                                }
                              </div>
                          </React.Fragment> 
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          : null
        }
      </div>
    )
  }
}
//

  // showDownloadAllButton && 
  // <DownloadAllButton 
  //   files={allDownload}
  // />

//isDragActive, isDragAccept, isDragReject



const STYLES = {
  imageDiv: {
    "maxWidth":"100%",
    "minWidth":"100%",
    "border":"1px solid #ddd",
    "margin":"0 1% 30px",
    "position":"relative",
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  activeStyle: {
    borderColor: '#2196f3'
  },
  acceptStyle: {
    borderColor: '#00e676'
  },
  rejectStyle: {
    borderColor: '#ff1744'
  }
}

export default FileDropZone;