import React from 'react';

//BY DEFAULT IT WILL FIND ALL THOSE WITH "active" AS TRUE
const FILE = ` 
  createAt
  url
  name
  type
  order
  id
  customName
`; 



const ADDRESS = ` 
  street
  street2
  city
  state
  country
  zipcode
`; 


const META = ` 
  createAt 
  updateAt
`; 


const NOTESLIST = ` 
  note
  title
  createAt
  updateAt 
  category
  status
`; 

const MUTATION_FIELDS_DEFAULT = {
  FILE,
  ADDRESS,
  META,
  NOTESLIST
}




export default MUTATION_FIELDS_DEFAULT;