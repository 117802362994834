//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `Act today, you have nothing to lose. We’ll refund you if you are not a candidate and there is no obligation or commitment from your part after receiving and reviewing our proposed treatment.`
	},
	{
		"content": `All Rights Reserved`
	},
	{
		"type": "span",
		"content": `Nav`
	},
	{
		"type": "span",
		"content": `Info`
	},
	{
		"type": "span",
		"content": `Social`
	}
];




	
export default PAGE;