import React from 'react';
import OFFER_REQUEST from './OFFER_REQUEST';
import PLAN from './PLAN';
import ALIGNER_GROUPS from './ALIGNER_GROUPS';

const PREVIEW_FORM = [
  {
    "CONDITION": (curObj)=>curObj.rejectionNotes,
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Reason for Rejection",
        "SUB_TITLE":"Why was this Treatment Plan Rejected",
        "FIELDS": [
          {
            "PATH": "rejectionNotes",
            "TYPE": "STRING",
            "NAME":"Reason"
          }
        ]
      }
    ]
  },
  {
    "CONDITION": (curObj)=>curObj.rejectStepNotes,
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Reason for Step Rejection",
        "SUB_TITLE":"Why was this Current Step was Rejected",
        "FIELDS": [
          {
            "PATH": "rejectStepNotes",
            "TYPE": "STRING",
            "NAME":"Reason step Rejected"
          }
        ]
      }
    ]
  },
  ...PLAN,
  {
    "CONDITION": (curObj)=>(curObj.alignerGroups && curObj.alignerGroups.length > 0),
    "TYPE": "ACCORDION",
    "TITLE": "ALIGNER GROUPS",
    "FIELDS": [
      ...ALIGNER_GROUPS
    ]
  },
  {
    "TYPE": "ACCORDION",
    "TITLE": "INITAL REQUEST",
    "FIELDS": [
      ...OFFER_REQUEST
    ]
  }
]


export default PREVIEW_FORM;