import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from 'global/templates/templatePage/index';
import TemplatePreview from 'global/templates/templatePreview/index';
import { useQuery } from '@apollo/client';
import EmailSender from 'admin/components/EmailSender/index';
import RequestTP from "./actions/RequestTP";
import UploadTP from "./actions/UploadTP";
import ApproveTP from "./actions/ApproveTP";
import RequestFullTP from "./actions/RequestFullTP";
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';

const QUERY = API_CALLS.TREATMENT_PLANS.QUERIES.allAndCountListFull(); //ILL USE THIS SO I CAN INCLUDE THE USER IN THE LISTS
const QUERY_SINGLE = API_CALLS.TREATMENT_PLANS.QUERIES.single();
const QUERY_COUNT = API_CALLS.TREATMENT_PLANS.QUERIES.count();
const MUTATION_UPLOADPLAN = API_CALLS.TREATMENT_PLANS.MUTATIONS.upload();
const MUTATION_APPROVEPLAN = API_CALLS.TREATMENT_PLANS.MUTATIONS.approve();
const MUTATION_REJECT = API_CALLS.TREATMENT_PLANS.MUTATIONS.reject();
const MUTATION_ADD = API_CALLS.TREATMENT_PLANS.MUTATIONS.create();
const MUTATION_PLAN_REQUEST = API_CALLS.TREATMENT_PLANS.MUTATIONS.createRequest();
const MUTATION_UPDATE = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();


const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email, TPID`);
const QUERY_GET_USER = API_CALLS.USERS.QUERIES.single();


const FORM_FIELDS = API_CALLS.TREATMENT_PLANS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.TREATMENT_PLANS.LIST_HEADER_FIELDS;
const PREVIEW = API_CALLS.TREATMENT_PLANS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT: MUTATION_UPDATE,
  MUTATION_UPLOADPLAN,
  MUTATION_APPROVEPLAN,
  MUTATION_REJECT,
  MUTATION_ADD,
  MUTATION_PLAN_REQUEST,
  MUTATION_UPDATE,
  FORM_FIELDS,
  QUERY_GET_USER,
  QUERY_GET_USERS,
  PREVIEW
};

/*
//TO SEND EMAIL BUTTON

*/

const Preview = (props)=>{        
  var {curObj} = props;
  return(
    <TemplatePreview
      title="Preview Treatment Plan"
      onClose={props.onClose}
      onSave={props.onSave}
      customButtons={(curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2 btn-color6" onClick={()=>{
              if(curRef) curRef.onClose()
            }}>Close</button>
            <DialogProgressLogs
              ID={curObj._id}
              API_NAME={'TREATMENT_PLANS'}
              FIELD={'treatmentPlan'}
              asButton={true}
              className={'button button1 btn-color4'}
              style={{"width":"100px","minWidth":"0"}}
              customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
            />
          </React.Fragment>
        )
      }}>
      <PREVIEW
        VARIABLES={{
          id: curObj._id
        }}
      />
    </TemplatePreview>
  )
}


const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;              
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};



function GetDREmail(props) {     
  var {UID} = props; 
  const { loading, error, data, refetch } = useQuery(API_CALLS.USERS.QUERIES.single("email preferredLanguage"), {
    variables: {id: UID},
    fetchPolicy: "no-cache"
  });
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;        
  //console.log(`data`,"\n\n",data,"\n\n");
  return (
    <EmailSender
      asButton={true}
      className={`button button1 btn-color4 iconButton`}
      //customLinkText={<span><i className="fas fa-paper-plane"></i> Send Approve Email</span>}
      curObj={{
        whatEmail: "TREATMENT_PLAN_READY",
        emailData: {
          whoToEmail: data.user.email,
          whoToCC: "",
          language: (data.user.preferredLanguage ? data.user.preferredLanguage : "ENGLISH"),
          UID: UID,
        }
      }}
    />
  );
}



const LIST_HEADER_FIELDS_CUSTOM = (headerText="", whatToFind="") => [
  {
    type: "VALUE",
    name: "Step",
    defaultValue: "",
    path: "step"
  },
  {
    "path": "user.firstName,user.lastName",
    "type": "VALUE",
    "toolTip": true,
    "name": "User Name"
  },
  {
    "path": "user.email",
    "type": "VALUE",
    "name": "Email"
  },
  {
    type: "ID",
    name: "ID",
    defaultValue: "",
    path: "_id",
    searchable: true
  },
  {
    type: "ID",
    name: "UID",
    defaultValue: "",
    path: "UID",
    searchable: true
  },
  {
    type: "CUSTOM",
    path: headerText,
    name: headerText,
    CUSTOM: val=>{            
      //var sentEmail
      //console.log(`val`,"\n\n",val,"\n\n");
      if(!val.progressLogs || (val.progressLogs.length < 1)) return(<div className="">False</div>)
      var emailSent = val.progressLogs.filter(obj=>obj.category === whatToFind);
      return(<div className="text-center"><strong>{emailSent[0] ? "TRUE" : "FALSE"}</strong></div>)
    }
  },
  {
    "path": "user.role",
    "type": "VALUE",
    "toolTip": true,
    "name": "User Type"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "ACTIVE",
    path: "status"
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  }
];




const PAGES = () => [
  {
    "id": "TP01",
    "Name":"Request",
    "PageName": "Request",
    "BaseName": "plans",
    "Path": "request",
    "GlobalStateVar": ["TreatmentPlan,REQUEST"],
    "TITLE": "Request Treatment Plan",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": RequestTP,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "API_CALLS": API_CALLS_FULL,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "REQUEST"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "DELETEBUTTON": {
      NAME: "TREATMENT_PLANS"
    },
    "CLONEBUTTON": {
      NAME: "TREATMENT_PLANS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("plans/request");
      }
    },
    "BUTTONS": [
      {
        buttonText: "Request",
        category: "Request",
        className: "btn-color1",
        iconClass: "fas fa-envelope",
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "TP02",
    "Name":"Upload",
    "PageName": "Upload",
    "BaseName": "plans",
    "Path": "upload",
    "GlobalStateVar": ["TreatmentPlan,UPLOAD"],
    "TITLE": "Upload Treatment Plan Offer",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": UploadTP,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS_CUSTOM("Sent (EMAIL_UPLOAD)?", "EMAIL_UPLOAD"),
    "API_CALLS": API_CALLS_FULL,
    "DELETEBUTTON": {
      NAME: "TREATMENT_PLANS"
    },
    "CLONEBUTTON": {
      NAME: "TREATMENT_PLANS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("plans/upload");
      }
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "UPLOAD"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Upload",
        category: "Upload",
        className: "btn-color2",
        iconClass: "fas fa-clipboard",
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "TP03",
    "Name":"Approve",
    "PageName": "Approve",
    "BaseName": "plans",
    "Path": "approve",
    "GlobalStateVar": ["TreatmentPlan,APPROVE"],
    "TITLE": "Approve Treatment Plan Offer",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": ApproveTP,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS_CUSTOM("Sent (EMAIL_APPROVE)?", "EMAIL_APPROVE"),
    "API_CALLS": API_CALLS_FULL,
    "DELETEBUTTON": {
      NAME: "TREATMENT_PLANS"
    },
    "CLONEBUTTON": {
      NAME: "TREATMENT_PLANS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("plans/approve");
      }
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "APPROVE"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        category: "Approve",
        buttonText: "Approve",
        className: "btn-color4",
        iconClass: "fas fa-clipboard-check",
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "TP04",
    "Name":"User Purchase",
    "PageName": "User Purchase",
    "BaseName": "plans",
    "Path": "userpurchase",
    "GlobalStateVar": ["TreatmentPlan,PURCHASE"],
    "UserProblem": true,
    "TITLE": "Waiting for User to Purchase Treatment Plan",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": Preview,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS_CUSTOM("Sent (EMAIL_USER)?", "EMAIL_USER"),
    "API_CALLS": API_CALLS_FULL,
    "DELETEBUTTON": {
      NAME: "TREATMENT_PLANS"
    },
    "CLONEBUTTON": {
      NAME: "TREATMENT_PLANS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("plans/userpurchase");
      }
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "PURCHASE"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        width: "0px",
        category: "Email",
        CUSTOMBUTTON: true,
        CUSTOM: (item)=><GetDREmail UID={item.UID}/>
      },
      {
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "TP05",
    "Name":"Request Full",
    "PageName": "Request Full",
    "BaseName": "plans",
    "Path": "requestFull",
    "GlobalStateVar": ["TreatmentPlan,REQUEST_FULL"],
    "TITLE": "Request Full Treatment Plan",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": RequestFullTP,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS_CUSTOM("Sent (EMAIL_REQUEST_FULL)?", "EMAIL_REQUEST_FULL"),
    "API_CALLS": {
      ...API_CALLS_FULL
    },
    "DELETEBUTTON": {
      NAME: "TREATMENT_PLANS"
    },
    "CLONEBUTTON": {
      NAME: "TREATMENT_PLANS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("plans/requestFull");
      }
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "REQUEST_FULL"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Request",
        category: "Request",
        iconClass: "fas fa-dollar-sign",
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "TP06",
    "Name":"Pending",
    "PageName": "Pending",
    "BaseName": "plans",
    "Path": "pending",
    "TITLE": "Pending Treatment Plans",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": Preview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "PENDING",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "user"
        }
      ]
    },
    "EDITBUTTON": {
      NAME: "TREATMENT_PLANS",
      TITLE: "Treatment Plan",
      FIELD: "treatmentPlan"
    },
    "DELETEBUTTON": {
      NAME: "TREATMENT_PLANS"
    },
    "CLONEBUTTON": {
      NAME: "TREATMENT_PLANS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("plans/pending");
      }
    },
    "BUTTONS": [
      {
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
      }
      // {
      //   width: "110px",
      //   category: "Preview",
      //   buttonText: "View",
      //   className: "btn-color3",
      //   iconClass: "fas fa-eye",
      //   onClick: (obj)=>{
      //     window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      //       "TYPE": "PREVIEW",
      //       "BUTTON_TITLE": "Treatment Plan",
      //       "TITLE": "Treatment Plan", //TITLE OF SLIDE OUT
      //       "APINAME": "TREATMENT_PLANS",
      //       "NAME": "Treatment Plan",
      //       "ID": obj._id
      //     }); 
      //   }
      // }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "TP07",
    "Name":"History",
    "PageName": "History",
    "BaseName": "plans",
    "Path": "history",
    "TITLE": "Treatment Plan History",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": Preview,
    "API_CALLS": {
      ...API_CALLS_FULL,
      QUERY_SINGLE: API_CALLS.TREATMENT_PLANS.QUERIES.singleFull()
    },
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "user"
        },
        {
          name: "alignerGroups",
          "include": [
            {
              name: "aligners"
            }
          ]
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "TREATMENT_PLANS",
      TITLE: "Treatment Plan",
      FIELD: "treatmentPlan"
    },
    "EDITBUTTON": {
      NAME: "TREATMENT_PLANS",
      TITLE: "Treatment Plan",
      FIELD: "treatmentPlan"
    },
    "DELETEBUTTON": {
      NAME: "TREATMENT_PLANS"
    },
    "CLONEBUTTON": {
      NAME: "TREATMENT_PLANS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("plans/history");
      }
    },
    "BUTTONS": [
      {
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
      }
      // {
      //   width: "110px",
      //   category: "Preview",
      //   buttonText: "View",
      //   className: "btn-color3",
      //   iconClass: "fas fa-eye",
      //   onClick: (obj)=>{
      //     window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      //       "TYPE": "PREVIEW",
      //       "BUTTON_TITLE": "Treatment Plan",
      //       "TITLE": "Treatment Plan", //TITLE OF SLIDE OUT
      //       "APINAME": "TREATMENT_PLANS",
      //       "NAME": "Treatment Plan",
      //       "ID": obj._id
      //     }); 
      //   }
      // }
    ],
    SHOWSELECTBOXES:true
  }
];





const Index = (props) => {
  //var {data} = props;
  var today = new Date();        
  return (
     <TemplatePage
      ID={"AdminPlans"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "treatmentPlanCount",
            TITLE: "New Plans today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "treatmentPlanCount",
            TITLE: "Plans this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "treatmentPlanCount",
            TITLE: "Plans this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "treatmentPlanCount",
            TITLE: "Plans this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/",
        "to": "request"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   
