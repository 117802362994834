//import React from 'react';
const PAGE = [
	{
		"content": `Dashboard`
	},
  { 
    "type": "div",
    "className": "itemHeaderText",
    "content": `CREATE: <span style="font-weight:400">Treament Plan Offers</span>`
  },
  { 
    "type": "div",
    "className": "itemHeaderText",
    "content": `UPLOAD: <span style="font-weight:400">Full Treatment Plans</span>`
  }
];




	
export default PAGE;