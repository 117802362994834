import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const withLocationHook = (Component) => {
  return (props) => {
      const location = useLocation();
      const navigation = useNavigate();

      return <Component location={location} navigation={navigation} {...props} />
  }
}


export default withLocationHook;