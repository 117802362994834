//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Purchase your Impression Kit for your Clear Aligners Here! No risk treatment",
			"DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Number one safe affordable clear aligners for discreet invisible orthodontics.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
		},
		"CONTENT": {
			"PageBuyImpressionKit": [
				{
					"type": "div",
					"className": "d-none",
					"content": `
						<h1 className="sectionTitled-none">Get your Kit</h1>
					`
				}
			],
			"SimpleStepsHeaderContainer": [
				{
					"type": "div",
					"content": `
					  <div class="preHeader">Quick rundown</div>
					  <h2 class="sectionTitle2">How it Works</h2>
					  <div class="subHeader">Before our doctors can create your custom treatment plan offer, we need an impression of your teeth. So your first step is to purchase an impression kit!</div>
					  <br/>  
					  <br/>
					`
				  }
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | ¡Adquiere aquí tu kit de impresión para tus alineadores transparentes! Tratamiento sin riesgos",
			"DESCRIPTION": "El programa de Sonrisas Club hace que sea fácil tener una hermosa sonrisa, sin la molestia de la cirugía o los frenos tradicionales.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"PageBuyImpressionKit": [
				{
					"type": "div",
					"className": "d-none",
					"content": `
						<h1 className="sectionTitled-none">Get your Kit</h1>
					`
				}
			],
			"SimpleStepsHeaderContainer": [
				{
					"type": "div",
					"content": `
					  <div class="preHeader">Quick rundown</div>
					  <h2 class="sectionTitle2">How it Works</h2>
					  <div class="subHeader">Before our doctors can create your custom treatment plan offer, we need an impression of your teeth. So your first step is to purchase an impression kit!</div>
					  <br/>  
					  <br/>
					`
				  }
			]
		}
	}
};


	
export default PAGE;
