//import React from 'react';
const PAGE = [
  {
    "content": `Enviar`
  },
  {
    "content": "Cargar archivos de planes de tratamiento"
  },
  {
    "content": "Cancelar"
  },
  {
    "content": "Enviar"
  },
  {
    "content": "Orden de actualización"
  },
  {
    "content": "Por favor espere"
  },
  {
    "type": "span",
    "content": "<span style=\"color:red,font-weight:700\">ADVERTENCIA ESTO HA SIDO RECHAZADO</span> - Notas de rechazo"
  },
  {
    "content": "El texto a continuación DEBERÍA explicar por qué se le devolvió esto. Solucione cualquier problema y envíelo de nuevo."
  },
  {
    "content": "Cargue los archivos del plan de tratamiento COMPLETO"
  },
  {
    "content": "Cargue los Alineadores STL's"
  },
  {
    "content": "Arrastra todos los archivos STL de los alineadores a continuación. Cuando haya terminado, presione el botón Enviar en la parte inferior de la página."
  },
  {
    "content": "Guardando"
  },
  {
    "content": "Por favor espere"
  },
  {
    "content": "Cerrar"
  },
  {
    "content": "Guardar"
  },
  {
    "content": "Enviar"
  }
];




  
export default PAGE;