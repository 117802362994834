import React from 'react';
import {Helmet} from "react-helmet-async";
import TemplatePage from 'website/templates/templatePage/index.js';
import 'styles/scss/website/pages/buyImpressionKit.scss';
import Compare from "../bestAligners/compare.js";

// import Step1 from 'global/img/infoVectors/Step1.jpg';
// import Step2 from 'global/img/infoVectors/Step2.jpg';
// import Step3 from 'global/img/infoVectors/Step3.jpg';
// import Step4 from 'global/img/infoVectors/Step4.jpg';
// import Step5 from 'global/img/infoVectors/Step5.jpg';

import ImpressionKitBar from 'website/components/ImpressionKitBar/index';
import SimpleSteps from 'website/components/SimpleSteps/index';
import CONTENTS from "./CONTENTS";
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      products: []
    };    
  }

  didMount(){          
  }

  willUnmount(){
  }

  onFailed(){
  }



  render(){
    var {products=[]} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
          
    return (
      <div className="contentPage" id="PageBuyImpressionKit">        
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        {CONTENT.PageBuyImpressionKit[0]}
        <ImpressionKitBar />
        <Container id="SimpleStepsHeaderContainer" className="simpleStepsHeader">
          <Row className="">
            <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT.SimpleStepsHeaderContainer[0]}
            </Column>
          </Row>
        </Container>
        <SimpleSteps />
        <Compare/>
      </div>
    );
  }
}



// const Product = (props)=>{
//   var {product={}} = props;
//   return(
//     <div className="product productLayout1">
//       <div className="productImages d-none d-lg-block">
//         <div className="productImage">
//           <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
//         </div>
//       </div>
//       <div className="productText">
//         <div className="productImage d-block d-lg-none">
//           <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
//           <br/>
//         </div>
//         <div className="productTextName">
//           {product.name}
//         </div>
//         <div className="productTextCost d-flex">
//           <div className="cost">{window.GlobalUtil.convertToMoney(product.cost-product.discount)}</div>
//           <div className="originalPrice">{window.GlobalUtil.convertToMoney(product.cost)}</div>
//         </div>
//         <div className="productTextDesc">
//           {window.GlobalUtil.dangerSet(product.description)}
//         </div>
//         {/*<div className="">
//           Category: {product.category}
//         </div>*/}
//         <div className="buttonGroup">
//           <button className="button button2 btn-color5 dontCloseCart" onClick={()=>{
//             window.GlobalUtil.addToCart({product: product, replaceAll: true, onlyOne: true}, ()=>{
//               window.GlobalUtil.triggerEvent("cartEvent", "show");
//             });
//           }}>
//           {
//             (window.Session.Language === "ENGLISH")
//             ? "Buy Now"
//             : "Compra Ahora"
//           }
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }  

export default Index;
