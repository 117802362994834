//import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';


const Index = (type)=>{
  const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);
  const QUERY_GET_ORDERS = API_CALLS.ORDERS.QUERIES.all();
  const QUERY_GET_TREATMENT_PLANS = API_CALLS.TREATMENT_PLANS.QUERIES.all();
  const QUERY_GET_PRODUCTS = API_CALLS.PRODUCTS.QUERIES.all('name');
  const QUERY_GET_COUPONS = API_CALLS.COUPONS.QUERIES.all(`name`);
  const QUERY_GET_COUPON = API_CALLS.COUPONS.QUERIES.single();
  const USER_EMAIL_GET = {
    query: QUERY_GET_USERS,
    variables: {
      "status": "ACTIVE",
      "manual": [
        {
          path: "role",
          val: "PATIENT",
          not: true
        }
      ]
    }
  }
  const FIELDSBYTYPE = {
      "WELCOME": [
        {
          title: <div className="formTitle"><span className="">User ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have a user for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query(USER_EMAIL_GET)
            .then(result => {
              //console.log(`result.data`,"\n\n",result.data,"\n\n");    
              var Options = result.data.users.map((user, index)=>{
                return({
                  "value": user._id,
                  "title": user.email
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,UID",
          required: true,
          defaultValue: "",
          errorMessage: "User ID is required"
        },
        {
          title: <div className="formTitle"><span className="">Send Welcome Reset Password Email</span></div>,
          subTitle: <div className="formSubTitle"><span className="">If false it will send the normal email. If true, it will send the reset password welcome email.</span></div>,
          type: "toggle",
          name: "emailData,sendWelcomeResetPasswordEmail",
          required: false,
          defaultValue: false
        }
      ],
      // "CONTACTMESSAGE": [
      // ],
      // "REJECTIONMESSAGE": [
      // ],
      "IMPRESSION_KIT_INFO": [
        {
          title: <div className="formTitle"><span className="">User ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have a user for this email. His name will show up on top.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query(USER_EMAIL_GET)
            .then(result => {
              var Options = result.data.users.map((user, index)=>{
                return({
                  "value": user._id,
                  "title": user.email
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,UID",
          required: true,
          defaultValue: "",
          errorMessage: "User ID is required"
        }
      ],
      "ORDER_CONFIRMATION": [
        {
          title: <div className="formTitle"><span className="">Order ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What order is connected to this email</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_ORDERS
            })
            .then(result => {
              var Options = result.data.orders.map((order, index)=>{
                return({
                  "value": order._id,
                  "title": order._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,OID",
          required: true,
          defaultValue: "",
          errorMessage: "Order ID is required"
        }
      ],
      "PAYMENT_FAILED": [
        {
          title: <div className="formTitle"><span className="">Order ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What order is connected to this email</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_ORDERS
            })
            .then(result => {
              var Options = result.data.orders.map((order, index)=>{
                return({
                  "value": order._id,
                  "title": order._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,OID",
          required: true,
          defaultValue: "",
          defaultValuePath: "emailData,OID",
          errorMessage: "Order ID is required"
        }
      ],
      "REQUEST_TREATMENT_PLAN": [
        {
          title: <div className="formTitle"><span className="">Treatment Plan ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What treatment plan is connected to this email</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_TREATMENT_PLANS
            })
            .then(result => {
              var Options = result.data.treatmentPlans.map((treatmentPlan, index)=>{
                return({
                  "value": treatmentPlan._id,
                  "title": treatmentPlan._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,TPID",
          required: true,
          defaultValue: "",
          errorMessage: "Treatment Plan ID is required"
        }
      ],
      "REQUEST_FULL_TREATMENT_PLAN": [
        {
          title: <div className="formTitle"><span className="">Treatment Plan ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What treatment plan is connected to this email</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_TREATMENT_PLANS
            })
            .then(result => {
              var Options = result.data.treatmentPlans.map((treatmentPlan, index)=>{
                return({
                  "value": treatmentPlan._id,
                  "title": treatmentPlan._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,TPID",
          required: true,
          defaultValue: "",
          errorMessage: "Treatment Plan ID is required"
        }
      ],
      "REQUEST_APPROVE_TREATMENT_PLAN": [
        {
          title: <div className="formTitle"><span className="">Treatment Plan ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What treatment plan is connected to this email</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_TREATMENT_PLANS
            })
            .then(result => {
              var Options = result.data.treatmentPlans.map((treatmentPlan, index)=>{
                return({
                  "value": treatmentPlan._id,
                  "title": treatmentPlan._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,TPID",
          required: true,
          defaultValue: "",
          errorMessage: "Treatment Plan ID is required"
        },
      ],
      "TREATMENT_PLAN_READY": [
        {
          title: <div className="formTitle"><span className="">User ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have a user for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query(USER_EMAIL_GET)
            .then(result => {
              var Options = result.data.users.map((user, index)=>{
                return({
                  "value": user._id,
                  "title": user.email
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,UID",
          required: true,
          defaultValue: "",
          errorMessage: "User ID is required"
        }
      ],
      "SENT_IMPRESSION_KIT": [
        {
          title: <div className="formTitle"><span className="">Order Item ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have an order item for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: API_CALLS.ORDER_ITEMS.QUERIES.all(`name`),
              variables: {
                "manual": [
                  {
                    path: "name",
                    val: "IMPRESSION_KIT"
                  }
                ]
              }
            })
            .then(result => {
              var Options = result.data.orderItems.map((orderItem, index)=>{
                return({
                  "value": orderItem._id,
                  "title": orderItem.name
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,OIID",
          required: true,
          defaultValue: "",
          errorMessage: "Order Item ID is required"
        }
      ],
      "SENT_PRODUCTS": [
        {
          title: <div className="formTitle"><span className="">Order Item ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have an order item for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: API_CALLS.ORDER_ITEMS.QUERIES.all(`name`)
            })
            .then(result => {
              var Options = result.data.orderItems.map((orderItem, index)=>{
                return({
                  "value": orderItem._id,
                  "title": orderItem.name
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,OIID",
          required: true,
          defaultValue: "",
          errorMessage: "Order Item ID is required"
        }
      ],
      "SENT_ALIGNERS": [
        {
          title: <div className="formTitle"><span className="">Order Item ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have an order item for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: API_CALLS.ORDER_ITEMS.QUERIES.all(`name`),
              variables: {
                "manual": [
                  {
                    path: "name",
                    val: "ALIGNERS"
                  }
                ]
              }
            })
            .then(result => {                    
              var Options = result.data.orderItems.map((orderItem, index)=>{
                return({
                  "value": orderItem._id,
                  "title": orderItem._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,OIID",
          required: true,
          defaultValue: "",
          errorMessage: "Order Item ID is required"
        }
      ],
      "SEND_INVOICE": [
        {
          title: <div className="formTitle"><span className="">Product</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must select a product for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_PRODUCTS
            })
            .then(result => {
              var Options = result.data.products.map((product, index)=>{
                return({
                  "value": product._id,
                  "title": product.name
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,PID",
          required: true,
          defaultValue: "",
          errorMessage: "User ID is required"
        }
      ],
      "RESET_PASSWORD": [
        {
          title: <div className="formTitle"><span className="">User ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have a user for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query(USER_EMAIL_GET)
            .then(result => {
              var Options = result.data.users.map((user, index)=>{
                return({
                  "value": user._id,
                  "title": user.email
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,UID",
          required: true,
          defaultValue: "",
          errorMessage: "User ID is required"
        }
      ],
      "TASK_REJECTED": [
        {
          title: <div className="formTitle"><span className="">Treatment Plan ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What treatment plan is connected to this email</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_TREATMENT_PLANS
            })
            .then(result => {
              var Options = result.data.treatmentPlans.map((treatmentPlan, index)=>{
                return({
                  "value": treatmentPlan._id,
                  "title": treatmentPlan._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,TPID",
          required: true,
          defaultValue: "",
          errorMessage: "Treatment Plan ID is required"
        },
        {
          title: <div className="formTitle"><span className="">Rejected Task Name</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Task we're rejecting.</span></div>,
          type: "text",
          name: "emailData,rejectedTask",
          required: true,
          defaultValue: "",
          errorMessage: "Rejected Task is required"
        },
        {
          title: <div className="formTitle"><span className="">Reason for Rejection</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Why are you rejecting this task?</span></div>,
          type: "textArea",
          row: 10,
          name: "emailData,reasonForRejection",
          required: true,
          defaultValue: "",
          errorMessage: "Rejected Task is required"
        }
      ],
      "ADMIN_NOTIFICATION": [
        {
          title: <div className="formTitle"><span className="">Subject</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Email Subject</span></div>,
          type: "textArea",
          rows: 1,
          name: "emailData,subject",
          required: true,
          defaultValue: "",
          errorMessage: "Subject is required"
        },
        {
          title: <div className="formTitle"><span className="">Title</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Email Title.</span></div>,
          type: "text",
          name: "emailData,title",
          required: true,
          defaultValue: "",
          errorMessage: "Title is required"
        },
        {
          title: <div className="formTitle"><span className="">Message</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Message in Alert Email to admin.</span></div>,
          type: "textArea",
          rows: 10,
          name: "emailData,message",
          required: true,
          defaultValue: "",
          errorMessage: "Message is required"
        }
      ],
      "SEND_RECEIPT": [
        {
          title: <div className="formTitle"><span className="">Order ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What order is connected to this email</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_ORDERS
            })
            .then(result => {
              var Options = result.data.orders.map((order, index)=>{
                return({
                  "value": order._id,
                  "title": order._id
                })
              });
              callBack("options", [{
                "value": "",
                "title": "Choose one",
                "disabled": true
              }, ...Options])
              // console.log("Options");
              // console.log(Options,"\n\n");
                    
            });
          },
          name: "emailData,OID",
          required: true,
          defaultValue: "",
          defaultValuePath: "emailData,OID",
          errorMessage: "Order ID is required"
        }
      ],
      "COUPON_TP_FIXED_EMAIL": [ //userName code CPID
        {
          title: <div className="formTitle"><span className="">Subject</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Email Subject</span></div>,
          type: "textArea",
          rows: 1,
          name: "emailData,subject",
          required: true,
          defaultValue: "",
          errorMessage: "Subject is required"
        },
        {
          title: <div className="formTitle"><span className="">User Name</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Name of user that will show up on the email</span></div>,
          type: "text",
          name: "emailData,userName",
          required: true,
          defaultValue: "",
          errorMessage: "Subject is required"
        },
        {
          title: <div className="formTitle"><span className="">Coupon ID</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Must have a user for this email.</span></div>,
          type: "selectAPI",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_COUPONS
            }).then(result => {
                var Options = result.data.coupons.map((coupon, index)=>{
                  return({
                    "value": coupon._id,
                    "title": coupon.name
                  })
                });
                callBack("options", [{
                  "value": "",
                  "title": "Choose one",
                  "disabled": true
                }, ...Options])
                // console.log("Options");
                // console.log(Options,"\n\n");
                      
              });
          },
          name: "emailData,CPID",
          required: true,
          defaultValue: "",
          errorMessage: "User ID is required"
        },
        {
          title: <div className="formTitle"><span className="">Coupon Code</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Code to send user</span></div>,
          type: "customInput",
          customFunction: (that)=>{
            if(!that.props.currentObject.emailData.CPID) return ""
            else{
              if(!that.state || (that.state.CPID && (that.state.CPID !== that.props.currentObject.emailData.CPID))){
                window.Client.query({
                  query: QUERY_GET_COUPON,
                  variables: {
                    "id": that.props.currentObject.emailData.CPID
                  }
                }).then(result => {
                    var Options = result.data.coupon.codes.map((code, index)=>{
                      return({
                        "value": code.CODE,
                        "title": code.CODE
                      })
                    });

                    that.setState({
                      CPID: that.props.currentObject.emailData.CPID,
                      codes: [{
                        "value": "",
                        "title": "Choose one",
                        "disabled": true
                      }, ...Options]
                    }, ()=>{
                      that.props.onChange("emailData,code", "");
                    })      
                  });
              }

              if(that.state && that.state.codes){
                return (
                  <select 
                    type="select" 
                    className={`form-control`}
                    value={that.props.currentObject.emailData.code}
                    name={"code"}
                    onChange={e=>{
                      that.props.onChange("emailData,code", e.target.value);
                    }}>
                    {
                      that.state.codes.length > 0 && 
                      that.state.codes.map((object, index)=>{
                        if(object.disabled) return(<option key={index} value={""} disabled>{object.title}</option>)
                        return(
                          <option key={index} value={object.value}>{object.title}</option>
                        )
                      })
                    }
                  </select>
                )
                  
              }
              return ""
            }
          },
          name: "emailData,code",
          required: false,
          defaultValue: "",
          errorMessage: "Subject is required"
        }
      ],
      "CUSTOM_EMAIL": [
        {
          title: <div className="formTitle"><span className="">Subject</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Email Subject</span></div>,
          type: "textArea",
          rows: 1,
          name: "emailData,subject",
          required: true,
          defaultValue: "",
          errorMessage: "Subject is required"
        },
        {
          title: <div className="formTitle"><span className="">Email String</span></div>,
          subTitle: <div className="formSubTitle"><span className="">HTML string of the full email we want to send.</span></div>,
          type: "textArea",
          rows: 10,
          name: "emailData,emailString",
          required: true,
          defaultValue: "",
          errorMessage: "Email String is required"
        }
      ]
    };
  return (FIELDSBYTYPE[type])
}



export default Index;