//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';




//deleteOrderItemRemove





const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation NewOrderItem(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createOrderItem(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation Update(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateOrderItem(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const MUTATION_COMPLETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CompleteOrderItem(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    completeOrderItem(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const MUTATION_SHIPPED = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation ShippedOrderItem(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    shippedOrderItem(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteOrderItem($id: String) {
    deleteOrderItem(id: $id)
  }
`;


const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneOrderItem($id: String) {
    cloneOrderItem(id: $id){
      _id
    }
  }
`;

const MUTATION_RESHIP = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation ReshipOrderItem($id: String) {
    reshipOrderItem(id: $id){
      _id
    }
  }
`;

const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteOrderItemList($ids: [String]) {
    deleteOrderItemList(ids: $ids)
  }
`;


const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneOrderItemList($ids: [String]) {
    cloneOrderItemList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateOrderItem(
    $id: String,
  ) {
    deactivateOrderItem(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateOrderItemList(
    $ids: [String]
  ) {
    deactivateOrderItemList(ids: $ids)
  }
`;


const MUTATIONS = {
  create: MUTATION_ADD,
  update: MUTATION_EDIT,
  complete: MUTATION_COMPLETE,
  shipped: MUTATION_SHIPPED,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  reshipOrderItem: MUTATION_RESHIP,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;