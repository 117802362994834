import React, { useState, useRef } from 'react';
import Accordion from 'global/components/Accordion/index';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;



const PREVIEW_BASIC_LAYOUT = (props)=>{
  return(
    <div className="previewFormField">
      {props.NAME && <div className="previewFormFieldName">{props.NAME}</div>}
      <div className="previewFormFieldValue" style={(props.NOBORDER ? {"border":"none"} : {})}>{props.children}</div>
    </div>
  ) 
}


const PREVIEW_INLINE_LAYOUT = (props)=>{
  return(
    <div className="previewFormField previewFormFieldInline">
      {props.NAME && <div className="previewFormFieldName">{props.NAME}</div>}
      <div className="previewFormFieldValue" style={(props.NOBORDER ? {"border":"none"} : {})}>{props.children}</div>
    </div>
  ) 
}

const FormPreview = (props)=>{
  var {NewObj={}, PREVIEW_FORM} = props;
  return(
    <React.Fragment>
    {
      (PREVIEW_FORM.length > 0) && 
      PREVIEW_FORM.map((obj, index)=>{
        if((obj.TYPE==="CUSTOM_CODE")) return(
          <div className="" key={index}>{obj.CODE(NewObj)}</div>
        );
        var fieldObjValue = (obj.PATH 
          ? (obj.PATH === "ALL")
            ? NewObj
            : window.GlobalUtil.deepGetFromString(NewObj, obj.PATH, obj.DEFAULT) 
          : obj.DEFAULT)
        if((obj.CONDITION) && (!obj.CONDITION(NewObj))) return;
        var FieldAndValue = TYPE_DICTIONARY[obj.TYPE];
        if(!FieldAndValue) return;
        if((obj.TYPE==="ROW")||(obj.TYPE==="COLUMN")) return(
          <FieldAndValue
            key={index}
            fieldObj={obj}
            fieldObjValue={fieldObjValue}
            NewObj={NewObj}
          />
        )
        if(obj.INLINE){
          return(
            <PREVIEW_INLINE_LAYOUT
              key={index}
              NAME={obj.NAME}
              NOBORDER={obj.NOBORDER}
            >
              <FieldAndValue
                fieldObj={obj}
                fieldObjValue={fieldObjValue}
                NewObj={NewObj}
              />
            </PREVIEW_INLINE_LAYOUT>
          )
        }
        return(
          <PREVIEW_BASIC_LAYOUT
            key={index}
            NAME={obj.NAME}
            NOBORDER={obj.NOBORDER}
          >
            <FieldAndValue
              fieldObj={obj}
              fieldObjValue={fieldObjValue}
              NewObj={NewObj}
            />
          </PREVIEW_BASIC_LAYOUT>
        )
      })
    }
    </React.Fragment>
  ) 
}



const ROW = (props)=>{
  var {fieldObj={}, fieldObjValue="", NewObj} = props;
  return(
    <Row className={fieldObj.className}>
      <FormPreview
        NewObj={NewObj}
        PREVIEW_FORM={fieldObj.FIELDS} 
      />
    </Row>
  ) 
}


const COLUMN = (props)=>{
  var {fieldObj={}, fieldObjValue="", NewObj} = props;
  return(
    <Column 
      className={fieldObj.className}
      col={fieldObj.col}
      xs={fieldObj.xs}
      sm={fieldObj.sm}
      md={fieldObj.md}
      lg={fieldObj.lg}
      xl={fieldObj.xl}
      style={fieldObj.STYLE}
    >
      {
        fieldObj.TITLE &&
        ((fieldObj.TITLE_TYPE2)
          ? <div className="previewFormFieldTitles2">
              <div className="previewFormFieldTitle">{fieldObj.TITLE}</div>
              <div className="previewFormFieldSubTitle">{fieldObj.SUB_TITLE}</div>
            </div>
          : <div className="previewFormFieldTitles">
              <div className="previewFormFieldTitle">{fieldObj.TITLE}</div>
              <div className="previewFormFieldSubTitle">{fieldObj.SUB_TITLE}</div>
            </div>
        )
      }
      <FormPreview
        NewObj={NewObj}
        PREVIEW_FORM={fieldObj.FIELDS}
      />
    </Column>
  ) 
}




const KEYVALUELISTSTRING = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  if(!fieldObjValue) return "";
  var curObj = JSON.parse(fieldObjValue);
  var keyValueList = Object.keys(curObj).map((key, index)=>{
    return(
      <div className="keyValueListPreviewItem d-flex" key={index}>
        <div className="keyValueListPreviewItemKey">
          {key}
        </div>
        <div className="keyValueListPreviewItemValue">
          {curObj[key]}
        </div>
      </div>
    )
  })
  return(<div className="keyValueListPreview">
    {keyValueList}
  </div>) 
}

const STRING = (props)=>{
  var {fieldObj={}, fieldObjValue="", NewObj} = props;
  if(fieldObj.PREVIEW_LINK && NewObj[fieldObj.PREVIEW_LINK.ID_PATH]) return(<a href="" onClick={(e)=>{
    e.preventDefault();
    window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      ...fieldObj.PREVIEW_LINK,
      TYPE: "PREVIEW",
      ID: NewObj[fieldObj.PREVIEW_LINK.ID_PATH]
    });
  }}>{fieldObjValue}</a>)
  return(fieldObjValue) 
}


const HTML = (props)=>{
  var {fieldObj={}, fieldObjValue="", NewObj} = props;
  return(<div className="HTML" style={{"border": "1px solid #ddd", "padding": "15px", "marginTop": "0px"}}>{window.GlobalUtil.dangerSet(fieldObjValue)}</div>) 
}


const DATE = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  var newDate = window.GlobalUtil.dateBasic(fieldObjValue);
  
  if(fieldObj.FORMAT && fieldObjValue) newDate = window.GlobalUtil.convertToDate(`${fieldObjValue}`, fieldObj.FORMAT)
  return(newDate ? newDate : "NA") 
}


const IMAGE = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  return(
    <div className="image">
      <img src={fieldObjValue} alt="" />
    </div>
  ) 
}




const FILE = (props)=>{
  var {fieldObj={}, fieldObjValue="", type} = props;
  return(
    <div className="file">
      <div><a href={fieldObjValue} className="" target="_blank">{fieldObjValue}</a></div>
    </div>
  ) 
}


const INLINE_FILE = (props)=>{
  var {fieldObj={}, fieldObjValue={}, type} = props;
  var newDate = window.GlobalUtil.dateBasic(fieldObjValue.createAt);        
  return(
    <div className="inlineFile">
      <div className="textWrapper">
        <div className="title">
          <a href={fieldObjValue.url} className="" target="_blank">{fieldObjValue.name}</a>
        </div>
        <div className="createAt">
          {newDate}
        </div>
      </div>
      <div className="type">
        {fieldObjValue.type}
      </div>
      {
        (fieldObj.DOWNLOAD) && <div className="buttonWrapper"><a href={fieldObjValue.url} className="button button1" download={fieldObjValue.name}>Download</a></div>
      }  
    </div>
  ) 
}


const NUMBER = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  return(Number(fieldObjValue)) 
}

const PHONE = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  if(!fieldObjValue || isNaN(fieldObjValue)) return '';
  var basePhone = window.GlobalUtil.formatPhone(Number(fieldObjValue));
  var phoneString = `(${basePhone[0]}) ${basePhone[1]} - ${basePhone[2]}`;

  if(fieldObj.MAKE_LINK) return (<a href={`tel:${basePhone.join("")}`}>{phoneString}</a>)
  else return phoneString;
}


const EMAIL = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  if(fieldObj.MAKE_LINK) return (<a href={`mailto:${fieldObjValue}`}>{fieldObjValue}</a>)
  else return fieldObjValue;
}

const MONEY = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  return(window.GlobalUtil.convertToMoney(fieldObjValue)) 
}


const BOOLEAN = (props)=>{
  var {fieldObj={}, fieldObjValue=""} = props;
  if(!fieldObjValue || (fieldObjValue === undefined) || (fieldObjValue === 'false') || (fieldObjValue === false) || (fieldObjValue === '')) return 'false';
  else return 'true';
}



const LINK = (props)=>{
  var {fieldObj={}, fieldObjValue="", NewObj={}} = props;
  var curName = (fieldObj.linkName ? fieldObj.linkName : "Download");        
  if(fieldObj.LINK_NAME_PATH) curName = NewObj[fieldObj.LINK_NAME_PATH];
  //console.log("NewObj","\n\n",NewObj,"\n\n");
  if(fieldObj.DOWNLOAD) return(<a target="_blank" href={fieldObjValue} download={curName}>{curName}</a>)      
  return(<a target="_blank" href={fieldObjValue} download={curName}>{curName}</a>) 
}





const ARRAY_OBJECT = (props)=>{
  var {fieldObj={}, fieldObjValue=[]} = props;
  return(
    <Row>
    {
      fieldObjValue && 
      fieldObjValue.map((newObj, index)=>{
        return(
          <Column
            key={index}
            className="previewFormFieldArrayItem" 
            col={fieldObj.col ? fieldObj.col : "12"}
            xs={fieldObj.xs ? fieldObj.xs : "12"}
            sm={fieldObj.sm ? fieldObj.sm : "6"}
            md={fieldObj.md ? fieldObj.md : "6"}
            lg={fieldObj.lg ? fieldObj.lg : "4"}
            xl={fieldObj.xl ? fieldObj.xl : "4"}
            style={fieldObj.STYLE}
            >
            <FormPreview
              NewObj={newObj}
              PREVIEW_FORM={fieldObj.FIELDS}
              TITLE={fieldObj.TITLE}
              SUB_TITLE={fieldObj.SUB_TITLE}
            />
          </Column>
        )
      })
    }
    </Row>
  ) 
}


const ARRAY_CUSTOM = (props)=>{
  var {fieldObj={}, fieldObjValue=[]} = props;
  if(fieldObj.PARSE) fieldObjValue = JSON.parse(fieldObjValue);
  return(fieldObjValue.map((newObj, index)=>{
    return(
      <div key={index}>
        {fieldObj.CUSTOM(newObj)}
      </div>
    )
  })) 
}


const ARRAY_STRINGS = (props)=>{
  var {fieldObj={}, fieldObjValue=[], NewObj} = props;
  if(fieldObj.INROW) return fieldObjValue.join(", ")
  return(
    <React.Fragment>
      {
        fieldObjValue && 
        fieldObjValue.map((value, index)=>{
          var newVal = value;
          if(fieldObj.PREVIEW_LINK && value) newVal = (<a href="" onClick={(e)=>{
            e.preventDefault();
            window.GlobalUtil.triggerEvent("TemplateDBEvent", {
              ...fieldObj.PREVIEW_LINK,
              TYPE: "PREVIEW",
              ID: value
            });
          }}>{value}</a>)
          return(
            <div key={index}>
              {newVal}
            </div>
          )
        })
      }
    </React.Fragment>
  ) 
}




// window.GlobalUtil.triggerEvent("TemplateDBEvent", {
//   "BUTTON_TITLE": "Treatment Plan",
//   "TITLE": "Treatment Plan", //TITLE OF SLIDE OUT
//   "APINAME": "TREATMENT_PLANS",
//   "ID_PATH": "TPID"
//   TYPE: "PREVIEW",
//   ID: NewObj[fieldObj.ID_PATH]
// });
/*
{
  "TYPE": "PREVIEW_BUTTON",
  "BUTTON_TITLE": "Treatment Plan",
  "TITLE": "Treatment Plan", //TITLE OF SLIDE OUT
  "APINAME": "TREATMENT_PLANS",
  "ID_PATH": "TPID"
}
*/
const PREVIEW_BUTTON = (props)=>{
  var {fieldObj={}, fieldObjValue="", NewObj} = props;
  //console.log(`fieldObj`,"\n\n",fieldObj,"\n\n","\n\n");
  //console.log(`NewObj`,"\n\n",NewObj,"\n\n");
  if(!NewObj.TPID) return null;
  return(
    <Row>
      <Column style={{"paddingBottom":"0px"}}>
        <div className="previewFormFieldTitles d-flex">
          <div style={{"flex":"1"}}>
            <div className="previewFormFieldTitle">{fieldObj.BUTTON_TITLE}</div>
            <div className="previewFormFieldSubTitle">{fieldObj.BUTTON_TITLE} ID:&nbsp;{NewObj[fieldObj.ID_PATH]}</div>
          </div>
          <div className="viewFullButtonWrapper">
            <button className="button button1 btn-color5 viewFullButton" onClick={()=>{
              window.GlobalUtil.triggerEvent("TemplateDBEvent", {
                ...fieldObj,
                TYPE: "PREVIEW",
                ID: NewObj[fieldObj.ID_PATH]
              });
            }}>View Full <i className="fas fa-eye"></i></button>
          </div>
        </div>
      </Column>
    </Row>
  )
}

// class Index extends TemplatePage {
//   constructor(props) {
//     super(props);
//     //this.state = {...this.state,
//     //};    
//   }

//   didMount(){
//   }

//   willUnmount(){
//   }

//   render(){
//     //var {showSideMenu} = this.state;
//     const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
//     return (
//       <div id="PageAbout">
//         <Container className="">
//           <Row className="title">
//             <Column className="" col="" xs="" sm="" md="" lg="" xl="">
//               <div className="">
//                 {CONTENT[0]}
//               </div>
//             </Column>
//           </Row>
//           <Row className="title">
//             <Column className="" col="" xs="" sm="" md="" lg="" xl="">
//             <div className="textTemplate">
//                 {CONTENT[1]}
//                 {CONTENT[2]}
//                 <br/>
//                 {CONTENT[3]}
//                 {CONTENT[4]}
//               </div>
//             </Column>
//           </Row>
//         </Container>
//       </div>
//     );
//   }
// }



const ACCORDION = (props)=>{
  var {fieldObj={}, fieldObjValue="", NewObj} = props;
  return(
    <div className={`accordion ${fieldObj.className}`}>
      <Accordion
        fields={[
          {
            "title": fieldObj.TITLE,
            "text": <FormPreview
              NewObj={NewObj}
              PREVIEW_FORM={fieldObj.FIELDS} 
            />
          }
        ]}
      />
    </div>
  ) 
}




const NOTES_LIST = (props)=>{
  var {fieldObj={}, fieldObjValue=[], fieldObj={}} = props;        
  return(
    <div className="notesList">
      <div className="previewFormFieldTitles">
        <div className="previewFormFieldTitle">{fieldObj.TITLE}</div>
        <div className="previewFormFieldSubTitle">{fieldObj.SUB_TITLE}</div>
      </div>
      <div className="notesListInputWrapper">
        {
          fieldObjValue && 
          fieldObjValue.map((note, index)=>{
            console.log(`note`,"\n\n",note,"\n\n");
                  
            var createAt = `${window.GlobalUtil.convertToDate(note.createAt, `n/d/Y @ H:mA`)}`
            var updateAt = `${window.GlobalUtil.convertToDate(note.updateAt, `n/d/Y @ H:mA`)}`
            return(
              <div
                key={index}
                className="notesListForm" 
                style={fieldObj.STYLE}>
                <div className="noteForm">
                  <div className="noteTitle">
                    {note.title}
                  </div>
                  <div className="noteMessage">
                    {note.note}
                  </div>
                  <div className="noteDates">
                    <div className="noteDate noteDateFirst"><span className="emph">Last Update</span>: {updateAt}</div>
                    <div className="noteDate"><span className="emph">Created</span>: {createAt}</div>
                  </div>
                  <div className="noteDates">
                    <div className="noteDate noteDateFirst"><span className="emph">Status</span>: {note.status}</div>
                    <div className="noteDate"><span className="emph">Category</span>: {note.category}</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  ) 
}


const PICTURES = (props)=>{
  var {fieldObj={}, fieldObjValue=[], fieldObj={}} = props;        
  return(
    <div className="">
      <div className="previewFormFieldTitles">
        <div className="previewFormFieldTitle">{fieldObj.TITLE}</div>
        <div className="previewFormFieldSubTitle">{fieldObj.SUB_TITLE}</div>
      </div>
      <div className="previewFormPictureList">
        {
          fieldObjValue && 
          fieldObjValue.map((picture, index)=>{
            var {createAt, url, name, type, id} = picture;
            createAt = `${window.GlobalUtil.convertToDate(createAt, `n/d/Y @ H:mA`)}`;
            return(
              <div
                key={index}
                className="previewFormPictureListPic" 
                style={fieldObj.STYLE}>
                <div className="imageWrapper">
                  <div className="image">
                    <img src={url} alt=""/>
                  </div>
                  <div className="nameAndDate">
                    <div className="name"><span className="emph">Name</span>: {name}</div>
                    <div className="status"><span className="emph">Status</span>: {type}</div>
                    <div className="date"><span className="emph">Created</span>: {createAt}</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  ) 
}




const TYPE_DICTIONARY = {
  ACCORDION,
  ARRAY_OBJECT,
  ARRAY_STRINGS,
  BOOLEAN,
  COLUMN,
  DATE,
  EMAIL,
  FILE,
  HTML,
  IMAGE,
  INLINE_FILE,
  KEYVALUELISTSTRING,
  LINK,
  MONEY,
  NOTES_LIST,
  NUMBER,
  PHONE,
  PICTURES,
  PREVIEW_BUTTON,
  ROW,
  STRING,
  ARRAY_CUSTOM
}



const Index = (props)=>{
  var {NewObj={}, PREVIEW_FORM, TITLE="", SUB_TITLE=""} = props;
  return(
    <div className="PreviewForm">
      {TITLE && <div className="previewFormTitle">{TITLE}</div>}
      {SUB_TITLE && <div className="previewFormSubTitle">{SUB_TITLE}</div>}
      {
        <FormPreview
          NewObj={NewObj}
          PREVIEW_FORM={PREVIEW_FORM} 
        />
      }
    </div>
  ) 
}





export default Index;