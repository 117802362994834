//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="sectionTitle">Contact Us</h1>`
	},
	{
		"type": "div",
		"content": `<span></span>`
	},
	{
		"content": `Contact Us`
	},
	{
		"content": `Submit`
	},
	{
		"content": `Close`
	},
	{
		"content": `Name`
	},
	{
		"content": `Please include your full name`
	},
	{
		"content": `Full Name is required before submitting`
	},
	{
		"content": `Phone`
	},
	{
		"content": `We will not sell your information to telemarketers`
	},
	{
		"content": `A phone is required before submitting`
	},
	{
		"content": `Email`
	},
	{
		"content": `A valid email will allow us to contact you with our results`
	},
	{
		"content": `You must add a valid email`
	},
	{
		"content": `Message`
	},
	{
		"content": `Tell us a little about yourself. What are you looking to fix? Please include what is the best time to call you.`
	},
	{
		"content": `Please write something`
	},
	{
		"type": "div", //ON SUCCESS
		"content": `<div>
      <h2>Your form has been submitted</h2>
      <div class="">A representative will contact you shortly.</div>
    </div>`
	},
	{
		"type": "div", //ON FAIL
		"content": `<div>
      <h2>Something seems to be wrong</h2>
      <div class="">Please try again shortly.</div>
    </div>`
	}
];




	
export default PAGE;