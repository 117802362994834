const PAGE = [
  {
    "content": "Formulario de nuevo paciente"
  },
  {
    "content": `Cancelar`
  },
  {
    "content": `Crear`
  },
  {
    "content": `Nuevo paciente`
  },
  {
    "content": `Paciente`
  },
  {
    "content": `Primer nombre`
  },
  {
    "content": `Cada usuario necesita un nombre`
  },
  {
    "content": "Cada usuario necesita un nombre"
  },
  {
    "content": `Apellido`
  },
  {
    "content": `Cada usuario necesita un apellido`
  },
  {
    "content": "Cada usuario necesita un apellido"
  },
  {
    "content": `Elige un doctor`
  },
  {
    "content": `¿Qué médico se le asignará a este paciente?`
  },
  {
    "content": "A quién enviar el correo electrónico es obligatorio"
  },
  {
    "content": "Actualizar paciente"
  },
  {
    "content": "Crear Patient"
  },
  {
    "content": "Creando Patient"
  },
  {
    "content": "Por favor espere"
  },
  {
    "content": `Solicitud de plan de tratamiento`
  },
  {
    "content": `Imágenes iniciales de la boca del usuario`
  },
  {
    "content": `Necesitamos ver al nuevo usuario sonriendo desde el frente, el lado izquierdo y derecho. Luego necesitamos ver los dientes superiores y los dientes inferiores del usuario. Debe tener al menos 5 imágenes del usuario antes de que podamos comenzar.`
  },
  {
    "content": `Archivos de escaneo bucal`
  },
  {
    "content": `Necesitamos buenos escaneos STL de los dientes superiores e inferiores. Arrástrelos y suéltelos aquí.`
  },
  {
    "content": `Queja principal`
  },
  {
    "content": `¿Cuál es la queja principal que tiene este usuario? ¿Sobre mordida? ¿Bajo mordida? Ciertos dientes están torcidos, etc.`
  },
  {
    "content": `Notas`
  },
  {
    "content": `Agregue cualquier nota adicional que desee dejar para esta solicitud de plan de tratamiento. Puede ser cualquier cosa que desee, desde algo que debemos tener en cuenta hasta algo que desee recordar sobre este pedido.`
  },
  {
    "content": "Solicitud de actualización"
  },
  {
    "content": "Crear solicitud"
  },
  {
    "content": "Orden de actualización"
  },
  {
    "content": "Por favor espere"
  }  
];




  
export default PAGE;