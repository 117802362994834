//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="sectionTitle">Política de privacidad</h1>`
	},
	{
		"type": "div",
		"content": `<div class="textTemplate">     
      <div class="basicText">Última revisión: 01 de mayo de 2021</div>



      <h2 class="sectionTitle2">1. Lea atentamente</h2>
      <div class="basicText">Esta Política de privacidad describe la información que Smiles Club ("Compañía") recopila sobre usted a través de nuestros sitios web y todos los demás servicios que brindamos (colectivamente, los "Servicios"), cómo usamos y compartimos esa información y las opciones de privacidad que ofrecemos. Esta política se aplica a la información que recopilamos cuando accede o utiliza nuestros sitios web (colectivamente, el "Sitio"), cuando utiliza nuestros Servicios o cuando interactúa con nosotros. Esta Política de privacidad no cubre las prácticas de privacidad de Red Dental descritas en los Términos de Uso de la Compañía.</div>


      <h2 class="sectionTitle2">2. Cambios a esta Política de privacidad</h2>
      <div class="basicText">Podemos cambiar esta Política de privacidad de vez en cuando. Si hacemos cambios, se lo notificaremos publicando la política actualizada en nuestro Sitio y revisando la fecha de "Última revisión" (ver arriba). Le recomendamos que revise la Política de privacidad siempre que utilice nuestros Servicios para mantenerse informado sobre nuestras prácticas de información y sobre las formas en que puede ayudar a proteger su privacidad.</div>


      <h2 class="sectionTitle2">3. Confidencialidad de la información médica</h2>
      <div class="basicText">La información de salud que la Compañía recibe y / o crea sobre usted que lo identifica personalmente y se relaciona con su salud, tratamiento o pago de servicios de salud pasado, presente o futuro, puede ser "información de salud protegida" bajo la Ley de Responsabilidad y Portabilidad de Seguros de Salud. de 1996 (“HIPAA”) y la Ley de Tecnología de la Información de Salud para la Salud Económica y Clínica (“Ley HITECH”). Su información médica también puede estar protegida por las leyes y regulaciones estatales de privacidad.</div>
      <div class="basicText">La Compañía comprende que la información médica sobre usted y su salud es personal. Respaldamos su privacidad y nos aseguramos de que la transmisión y el uso de su información médica cumpla con todas las leyes. En este sentido, cuando corresponda, cumplimos con HIPAA, HITECH y otras leyes y regulaciones estatales relevantes. Al entrar en esta Política de Privacidad no nos responsabilizamos de cómo los proveedores dentales usarán y divulgarán su información médica. Cada proveedor dental tiene su propio Aviso de Prácticas de Privacidad. Si tiene preguntas sobre la privacidad de la información recopilada por los proveedores dentales que le brindan servicios, solicíteles una copia de su Aviso de Prácticas de Privacidad.</div>
      <div class="basicText">No cumplimos con GDPR o CPRA. Por lo tanto, Smiles Club atiende exclusivamente a residentes y ciudadanos de los Estados Unidos de América, excluidos los del estado de California. Si usted es un residente o ciudadano de algún otro país o del estado de California, no podemos servirle y no ofrecemos garantías en cuanto a su privacidad más allá de lo que es relevante y requerido para nuestra área de servicio. Por lo tanto, no revelará ninguna información de privacidad a Smiles Club y renunciará a todas y cada una de las reclamaciones contra Smiles Club por su incumplimiento con jurisdicciones externas.</div>



      <h2 class="sectionTitle2">4. Uso de servicios</h2>
      <div class="basicText">Su acceso y uso de nuestros Servicios están sujetos a ciertos términos y condiciones, que se establecen en nuestros Términos de Uso.</div>


      <h2 class="sectionTitle2">5. Recopilación de información</h2>
      <div class="basicText">
        <h5 class="sectionTitle3">5.1 Información que usted nos proporciona</h5>
        <div class="basicText">Recopilamos la información que usted nos proporciona, como cuando nos envía un correo electrónico, se registra a través de nuestro Sitio o envía información a través de nuestro Sitio. Podemos recopilar, entre otras cosas, la siguiente información: su nombre, género, dirección de correo electrónico, dirección postal, número de teléfono, fecha de nacimiento, información del proveedor de seguros, números de tarjetas de pago, número de cuenta bancaria, número de ruta bancaria, historial de atención médica y dental, y problemas con los que podemos ayudarlo.</div>


        <h5 class="sectionTitle3">5.2 Niños</h5>
        <div class="basicText">La Compañía no recopila ni mantiene a sabiendas información de identificación personal de personas menores de 18 años sin el consentimiento paterno/materno verificable, y ninguna parte de los Servicios está dirigida a personas menores de 18 años. Si es menor de 18 años, no utilice los Servicios. Si la Compañía se entera de que se ha recopilado información de identificación personal de personas menores de 18 años sin el consentimiento paterno o materno verificable, la Compañía tomará las medidas adecuadas para eliminar esta información. Para realizar dicha solicitud, contáctenos a <a href="mailto:support@Smiles.club">support@Smiles.club</a>.</div>
        <div class="basicText">Mas allá de la prohibición de esta sección 5.2 de que la Compañía no dirige ofertas directamente a menores, la Compañía brinda Servicios a menores con el consentimiento expreso de los padres o tutores. Todos estos registros se mantienen de conformidad con todas las leyes aplicables relevantes a nuestra área de servicio (Estados Unidos de América, excepto California).</div>


        <h5 class="sectionTitle3">5.3 Información que recopilamos de su uso de los Servicios</h5>
        <div class="basicText">Recopilamos información sobre usted cuando utiliza nuestro Sitio, incluidos, entre otros, los siguientes:</div>
          <ul>
            <li>Información de la cuenta. Cuando se registre con nosotros utilizando el Sitio para crear una cuenta y convertirse en un usuario registrado, deberá proporcionarnos cierta información de identificación personal para completar el registro, incluida la información que se puede utilizar para contactarlo o identificarlo y la tarjeta de crédito u otra información de facturación en algunos casos.</li>
            <li>Información del dispositivo. Podemos recopilar automáticamente cierta información sobre la computadora o los dispositivos (incluidos los dispositivos móviles) que utiliza para acceder a los Servicios. Por ejemplo, podemos recopilar y analizar información como (a) direcciones IP, información de geolocalización (como se describe en la siguiente sección a continuación), identificadores únicos de dispositivos y otra información sobre su teléfono móvil u otros dispositivos móviles, tipos de navegador, idioma del navegador, sistema operativo, estado o país desde el que accedió a los Servicios; e (b) información relacionada con las formas en las que interactúa con los Servicios, como: páginas de referencia y de salida y URL, tipo de plataforma, número de clics, nombres de dominio, páginas de destino, páginas y contenido visualizados y el orden de esas páginas, la cantidad de tiempo dedicado en páginas particulares, la fecha y hora en que usó los Servicios, la frecuencia de su uso de los Servicios, los registros de errores y otra información similar. Como se describe más adelante, podemos utilizar proveedores y tecnologías de análisis de terceros, incluidas cookies y herramientas similares, para ayudar a recopilar esta información.</li>
            <li>Información sobre la ubicación. Podemos recopilar diferentes tipos de información sobre su ubicación, incluida información general (por ejemplo, dirección IP, código postal) e información más específica (por ejemplo, funcionalidad basada en GPS en dispositivos móviles utilizados para acceder a los Servicios), y podemos usar esa información para personalizar los Servicios con información, publicidad y funciones basadas en la ubicación. Por ejemplo, si su dirección IP indica un origen en Las Vegas, Nevada, los Servicios pueden personalizarse con información y ofertas específicas de Las Vegas Para hacer esto, su información de ubicación puede ser compartida con nuestros agentes, proveedores o anunciantes. Si accede a los Servicios a través de un dispositivo móvil y no desea que su dispositivo nos proporcione información de rastreo de ubicación, puede deshabilitar el GPS u otras funciones de rastreo de ubicación en su dispositivo, siempre que su dispositivo le permita hacer esto. Consulte las instrucciones del fabricante de su dispositivo para obtener más detalles.</li>
            <li>Cookies y otras tecnologías electrónicas. Podemos utilizar las herramientas que se describen a continuación para comprender mejor a los usuarios. A medida que adoptemos tecnologías adicionales, también podemos recopilar información adicional a través de otros métodos.</li>
            <li>Cookies: Las "cookies" son pequeños archivos de computadora transferidos a su dispositivo informático que contienen información como la identificación de usuario, las preferencias del usuario, las listas de páginas visitadas y las actividades realizadas durante el uso de los Servicios. Usamos Cookies para ayudarnos a mejorar o adaptar los Servicios mediante el seguimiento de sus hábitos de navegación, almacenando su estado de autenticación para que no tenga que volver a ingresar sus credenciales cada vez que utilice los Servicios, personalizando su experiencia con los Servicios y para análisis y prevención del fraude.</li>
          </ul>
        <div class="basicText">Podemos utilizar un tipo de publicidad comúnmente conocida como publicidad basada en intereses o basada en el comportamiento en línea. Esto significa que algunos de nuestros socios comerciales utilizan Cookies para mostrar anuncios de la Compañía en otros sitios web y servicios basados en información sobre su uso de los Servicios y en sus intereses (como se infiere de su actividad en línea). Otras cookies utilizadas por nuestros socios comerciales pueden recopilar información cuando utiliza los Servicios, como la dirección IP, la identificación del dispositivo móvil, el sistema operativo, el navegador, las interacciones de la página web, la ubicación geográfica de su proveedor de servicios de Internet e información demográfica como el género y rango de edad. Estas cookies ayudan a la empresa a obtener más información sobre los comportamientos demográficos y de Internet de nuestros usuarios.</div>
        <div class="basicText">Para obtener más información sobre las cookies, visite http://www.allaboutcookies.org.</div>
          <ul>
            <li>Web Beacons: los "Web Beacons" (también conocidos como GIF transparentes o etiquetas de píxeles) son pequeños archivos de imágenes gráficas incrustados de manera invisible en una página web o correo electrónico que pueden usarse para recopilar información anónima sobre su uso de nuestros Servicios, los sitios web de anunciantes seleccionados y los correos electrónicos, promociones especiales o boletines que le enviamos. La información recopilada por Web Beacons nos permite analizar cuántas personas están utilizando los Servicios, utilizando los sitios web de los anunciantes seleccionados o abriendo nuestros correos electrónicos, y con qué propósito, y también nos permite mejorar nuestra publicidad basada en intereses.</li>
            <li>Análisis del sitio web: podemos utilizar servicios de análisis del sitio web de terceros en relación con el sitio web, lo que incluye, por ejemplo, registrar los clics del mouse, los movimientos del mouse, la actividad de desplazamiento y el texto que escribe en el sitio web o la aplicación móvil. Estos servicios de análisis de sitios web generalmente no recopilan información personal a menos que usted la proporcione voluntariamente y, por lo general, no rastrean sus hábitos de navegación en sitios web que no utilizan sus servicios. Usamos la información recopilada de estos servicios para ayudar a que el sitio web sea más fácil de usar.</li>
            <li>Identificadores de dispositivos móviles: los identificadores de dispositivos móviles son datos almacenados en su dispositivo móvil que pueden rastrear el dispositivo móvil y los datos y actividades que ocurren en y a través de él, así como las aplicaciones instaladas en él. Los identificadores de dispositivos móviles permiten la recopilación de información personal (como control de acceso a los medios, dirección y ubicación) y datos de tráfico. Los identificadores de dispositivos móviles ayudan a la Compañía a obtener más información sobre los comportamientos demográficos y de Internet de nuestros usuarios.</li>
          </ul>


        <h5 class="sectionTitle3">5.4 Información de terceros</h5>
        <div class="basicText">Podemos obtener información adicional sobre usted de terceros, como comercializadores, socios, investigadores y otros. Podemos combinar la información que recopilamos de usted con información sobre usted que obtenemos de dichos terceros e información derivada de cualquier otra suscripción, producto o servicio que proporcionemos.</div>


        <h5 class="sectionTitle3">5.5 Datos agregados o anonimizados</h5>
        <div class="basicText">Podemos agregar y / o anular la identificación de la información recopilada por los Servicios o por otros medios para que la información no tenga la intención de identificarlo. Nuestro uso y divulgación de información agregada y / o anónima no está sujeta a ninguna restricción en virtud de esta Política de privacidad, y podemos divulgarla a otros sin limitación para cualquier propósito, de acuerdo con las leyes y regulaciones aplicables.</div>
      </div>


      <h2 class="sectionTitle2">6. Uso de la información</h2>
      <div class="basicText">Usamos la información que recopilamos para los siguientes propósitos:</div>
      <div class="basicText">
        <ul>
          <li>Para los fines para los que proporcionó la información;</li>
          <li>Para contactarlo cuando sea necesario o solicitado;</li>
          <li>Para personalizar su experiencia con los Servicios informándole de los productos, programas, eventos, servicios y promociones de la Compañía, nuestros afiliados, nuestros socios y / o terceros que creemos que pueden ser de su interés (consulte la sección "Política de Participación”a continuación);</li>
          <li>Para completar su compra con nosotros, incluido, para procesar sus pagos, comunicarnos con usted con respecto a su compra o brindarle un servicio al cliente relacionado;</li>
          <li>Para proporcionar, mantener, administrar, mejorar o expandir los Servicios, realizar análisis comerciales o para otros fines internos para respaldar, mejorar o mejorar nuestro negocio, los Servicios y otros productos y servicios que ofrecemos;</li>
          <li>Para personalizar y adaptar su experiencia de los Servicios;</li>
          <li>Para enviar notificaciones móviles (puede optar por no participar en este servicio);</li>
          <li>Para enviar correos electrónicos y otras comunicaciones que muestren contenido que creemos que le interesará y de acuerdo con sus preferencias (puede optar por no participar en este servicio);</li>
          <li>Para enviarle noticias e información sobre nuestros Servicios;</li>
          <li>Para rastrear y analizar tendencias y uso en relación con nuestros Servicios;</li>
          <li>Para comprender mejor quién utiliza los Servicios y cómo podemos ofrecer una mejor experiencia de usuario;</li>
          <li>Para combinar la información recibida de terceros con la información que tenemos de usted o sobre usted y utilizar la información combinada para cualquiera de los propósitos descritos en esta Política de privacidad;</li>
          <li>Para utilizar la información estadística que recopilamos de cualquier manera permitida por la ley, incluso de terceros en relación con sus esfuerzos comerciales y de marketing;</li>
          <li>Para prevenir, detectar e investigar violaciones de seguridad, fraude y otras actividades potencialmente ilegales o prohibidas;</li>
          <li>Para hacer cumplir los términos legales que rigen su uso de los Servicios;</li>
          <li>Para proteger nuestros derechos o propiedad;</li>
          <li>Para administrar y solucionar problemas de los Servicios; y</li>
          <li>Para cualquier otro propósito que se le comunique en relación con nuestros Servicios.</li>
        </ul>
      </div>
      <div class="basicText">Podemos utilizar proveedores de servicios externos para procesar y almacenar información personal en los Estados Unidos y otros países.</div>


      <h2 class="sectionTitle2">7. Intercambio de información</h2>
        <div class="basicText">Podemos compartir información personal sobre usted de la siguiente manera:</div>
        <ul>
          <li>Con proveedores dentales, en relación con los Servicios que solicita;</li>
          <li>Con terceros para proporcionar, mantener y mejorar nuestros Servicios, incluidos los proveedores de servicios que acceden a su información para realizar servicios en nuestro nombre;</li>
          <li>Con nuestros afiliados y socios para que puedan utilizar dicha información para los fines descritos en esta Política de privacidad;</li>
          <li>Con nuestras afiliadas, socios u otros terceros para permitirles que se comuniquen con usted con respecto a productos, programas, servicios y promociones que nosotros y / o ellos creen que pueden ser de su interés (consulte la “Política de participación” a continuación);</li>
          <li>En relación con, o durante la negociación de, cualquier fusión, venta de acciones o activos de la compañía, financiamiento, adquisición, quiebra, desinversión o disolución de la totalidad o una parte de nuestro negocio (pero solo bajo acuerdos y protecciones de confidencialidad y no divulgación) ;</li>
          <li>Si creemos que la divulgación es razonablemente necesaria para cumplir con cualquier ley, regulación, proceso legal o solicitud gubernamental aplicable; para hacer cumplir los acuerdos o políticas de usuario aplicables; para proteger la seguridad o integridad de nuestros Servicios; y para protegernos a nosotros, a nuestros usuarios o al público de daños o actividades ilegales; y</li>
          <li>Con tu consentimiento.</li>
        </ul>
        <div class="basicText">También podemos compartir información agregada, no identificable personalmente con terceros.</div>


      <h2 class="sectionTitle2">8. Política de participación</h2>
      <div class="basicText">Cuando nos proporciona información de identificación personal en relación con su uso de los Servicios, es posible que se le solicite que indique si está interesado en recibir información nuestra sobre nuestras ofertas de productos y servicios y si desea que compartamos información de identificación personal sobre usted con nuestros afiliados, socios u otros terceros para sus fines de marketing. Si opta por participar, recibirá dichas comunicaciones y / o compartiremos su información de acuerdo con su consentimiento de "participación".</div>
      <div class="basicText">Por supuesto, puede optar por no recibir información de marketing adicional de nuestra parte o optar por no permitir que compartamos su información de identificación personal de la siguiente manera: En cualquier momento, puede seguir un enlace proporcionado en nuestros mensajes de correo electrónico relacionados con el marketing (pero excluyendo confirmaciones de comercio electrónico y otros correos electrónicos administrativos) para optar por no recibir dichas comunicaciones; o en cualquier momento, puede comunicarse con nosotros de acuerdo con la sección "Contáctenos" a continuación para optar por no recibir dichas comunicaciones.</div>
      <div class="basicText">Si decide ponerse en contacto con nosotros para cambiar sus preferencias de contacto y optar por no recibir nuestras comunicaciones, especifique claramente cuál de las siguientes opciones está excluyendo: (a) Recibir nuestras comunicaciones de marketing; (b) Permitirnos compartir información de identificación personal sobre usted con nuestros afiliados y socios para sus fines de marketing; y / o (c) Permitirnos compartir información de identificación personal sobre usted con otros terceros para sus fines de marketing.</div>
      <div class="basicText">Nos esforzaremos por implementar el cambio solicitado tan pronto como sea razonablemente posible después de recibir su solicitud. Tenga en cuenta que el cambio solicitado no entrará en vigor hasta que implementemos dicho cambio. Tenga en cuenta que, si elige no permitir que compartamos su información de identificación personal, no somos responsables de eliminar su información de identificación personal de las bases de datos de terceros con los que ya hemos compartido su información de identificación personal a la fecha de implementación. tu petición. Si desea dejar de recibir correos electrónicos relacionados con el marketing de estos terceros, comuníquese con ellos directamente o utilice cualquier mecanismo de exclusión voluntaria en sus políticas de privacidad o correos electrónicos relacionados con el marketing.</div>
      <div class="basicText">Tenga en cuenta que, si opta por no recibir nuestros mensajes relacionados con el marketing, es posible que aún le enviemos mensajes administrativos importantes. No puede optar por no recibir estos mensajes administrativos. Nos reservamos el derecho, de vez en cuando, de contactar a antiguos clientes o usuarios de los Servicios con fines administrativos o para cumplir con las leyes, reglas o regulaciones aplicables.</div>


      <h2 class="sectionTitle2">9. Redes sociales y plataformas de terceros</h2>
      <div class="basicText">Ciertas secciones o funcionalidades de nuestros Servicios pueden permitirle compartir información en sitios o plataformas de redes sociales de terceros como Facebook, Instagram, LinkedIn, Twitter, Google u otros sitios similares (colectivamente, "Sitios de redes sociales"). La Compañía no posee ni controla dichos Sitios de redes sociales y la publicación de su información en los Sitios de redes sociales está sujeta a la política de privacidad del tercero y a otros términos legales, que pueden no proporcionar las protecciones de privacidad que usted acepta. La empresa no es responsable de ningún acto u omisión de ningún sitio de redes sociales, ni somos responsables de las consecuencias de su elección de compartir su información en los sitios de redes sociales.</div>


      <h2 class="sectionTitle2">10. Seguridad</h2>
        <div class="basicText">Tomamos medidas razonables, incluidas salvaguardas administrativas, técnicas y físicas, para ayudar a proteger la información personal contra pérdida, robo, uso indebido, acceso no autorizado, divulgación, alteración y destrucción. Desafortunadamente, no se puede garantizar que ninguna transmisión de datos a través de Internet sea 100% segura. Como resultado, aunque nos esforzamos por proteger su información personal, la Compañía no puede garantizar ni garantizar la seguridad de la información que nos transmita o de nuestros productos o servicios en línea, y lo hace bajo su propio riesgo.</div>


      <h2 class="sectionTitle2">11. Sus opciones de privacidad</h2>


      <h5 class="sectionTitle3">11.1 Cómo puede acceder y actualizar su información</h5>
      <div class="basicText">Puede actualizar o corregir su información en cualquier momento o enviándonos un correo electrónico a <a href="mailto:support@Smiles.club">support@Smiles.club</a>.</div>


      <h5 class="sectionTitle3">11.2 Cookies</h5>
      <div class="basicText">La mayoría de los navegadores web están configurados para aceptar cookies de forma predeterminada. Si lo prefiere, normalmente puede optar por configurar su navegador para eliminar o rechazar las cookies; sin embargo, es posible que nuestros Servicios no funcionen correctamente si lo hace.</div>


      <h5 class="sectionTitle3">11.3 Opciones para optar por no recibir cookies e identificadores de dispositivos móviles</h5>
      <div class="basicText">Si está interesado en obtener más información sobre la publicidad basada en intereses y cómo puede controlar en general que las cookies se coloquen en su computadora para ofrecer publicidad personalizada, puede visitar el enlace de exclusión voluntaria del consumidor de la Network Advertising Initiative, la opción de consumidor de la Digital Advertising Alliance. O a la página de opciones de publicidad de TRUSTe para optar por no recibir publicidad personalizada de empresas que participan en esos programas. Tenga en cuenta que incluso después de excluirse de la publicidad basada en intereses, es posible que aún vea anuncios de la Compañía que no se basan en intereses (es decir, que no están dirigidos a usted). Además, optar por no participar no significa que la Compañía ya no esté utilizando sus herramientas de seguimiento; la Compañía aún puede recopilar información sobre su uso de los Servicios incluso después de que haya optado por no recibir anuncios basados en intereses y aún puede mostrarle anuncios a través de los Servicios basados en sobre la información que recopila a través de los Servicios.</div>


      <h5 class="sectionTitle3">11.4 Cómo responde la empresa a las señales de "no seguimiento" del navegador</h5>
      <div class="basicText">Nos comprometemos a brindarle opciones significativas sobre la información recopilada en nuestro Sitio para fines de terceros, y es por eso que proporcionamos anteriormente el enlace de "Exclusión del Consumidor" de la Iniciativa de Publicidad en Red, el Enlace de Exclusión del Consumidor de Digital Advertising Alliance, y la página de opciones publicitarias de TRUST.</div>
      

      <h5 class="sectionTitle3">11.5 Enlaces a otros sitios web</h5>
      <div class="basicText">Nuestros Servicios pueden contener enlaces a otros sitios web y esos sitios web pueden no seguir las mismas prácticas de privacidad que la Compañía. No somos responsables de las prácticas de privacidad de los sitios web de terceros. Le recomendamos que lea las políticas de privacidad de dichos terceros para obtener más información sobre sus prácticas de privacidad.</div>
      

      <h5 class="sectionTitle3">11.6 Sin derechos de terceros</h5>
      <div class="basicText">Esta Política de privacidad no crea derechos exigibles por terceros.</div>
      <br/>
      <h5 class="sectionTitle3">11.7 Cómo contactarnos</h5>
      <div class="basicText">Comuníquese con nosotros con cualquier pregunta o inquietud relacionada con esta Política de privacidad en: <a href="mailto:support@Smiles.club">support@Smiles.club</a></div>
    </div>`
	}
];




	
export default PAGE;