import React, { useState, useRef } from 'react';
import "styles/scss/lab/components/viewTreatmentPlanRequest.scss";
import { gql, useQuery, useMutation } from '@apollo/client';
import DownloadAllButton from 'global/components/DownloadAllButton';
import API_CALLS from 'API_CALLS/index';

import RejectionNotes from 'user/lab/components/RejectionNotes/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}








/*
FIRST CREATE NEW USER (PATIENT) (MUST HAVE DOCTOR ID)
THEN AUTO CREATE NEW TREATMENT PLAN FOR THAT USER

THEN UPLOAD ALL THINGS NEEDED FOR TREATMENT PLAN REQUEST
OBJECT TO CREATE NEW PATIENT
{
  DOCID
  firstName
  lastName
}

OBJECT TO CREATE TREATMENT PLAN REQUEST
{
  TPID
  offerRequest{
    chiefComplaint
    notes
    mouthPictures{
    }
    otherFiles{

    }
  }
}

*/



const Index = props => {
  const CONTENT  = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  var OnClick = (e)=>{
    e.preventDefault();
    window.GlobalUtil.triggerEvent("SlideInRightEvent", {
      TITLE: CONTENT[0],
      closeButtonText: CONTENT[1],
      // saveButtonText: "Create",
      customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog darkHeader",
      width: "1200px",
      CUSTOMBUTTONS: (curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2" onClick={window.GlobalUtil.DialogStack.closeTop}>{CONTENT[1]}</button>
            {props.onCreate && props.onCreate()}
          </React.Fragment>
        )
      },
      children: (propsChildren)=>{                                      
        return(
          <div className="dialogCustomZone">
            <ViewCurrentTreatmentPlanRequest 
              {...props}
              CONTENT={CONTENT}
            />
          </div>
        )
      }
    });
  }
  return(
    <React.Fragment>
      {
        props.customButtonText
        ? props.customButtonText(OnClick)
        : <div className="button button1 d-flex btn-color1 previewButtonIcon" style={{"maxWidth": "110px","minWidth": "110px"}} onClick={OnClick}>
            <div className="buttonIcon">
              <i className="fas fa-eye" style={{"cursor": "pointer"}}></i>
            </div>
          </div>
      }
    </React.Fragment>
  )
}





  

const ViewCurrentTreatmentPlanRequest = (props) => {
  var {isValid, loading, curUser, curTreatmentPlan, CONTENT=[]} = props;        
  var images = (curTreatmentPlan && curTreatmentPlan.offerRequest && curTreatmentPlan.offerRequest.mouthPictures) 
    ? curTreatmentPlan.offerRequest.mouthPictures.map((mouthPicture, index)=>{
        var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
        return {
          url: mouthPicture.url,
          title: `${userName}${mouthPicture.name}`,
          name: `${userName}${mouthPicture.name}`
        }
      })
    : [];

  var mouthScanFilesArray = (curTreatmentPlan && curTreatmentPlan.offerRequest && curTreatmentPlan.offerRequest.otherFiles) 
    ? curTreatmentPlan.offerRequest.otherFiles.map((mouthPicture, index)=>{
        var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
        return {
          url: mouthPicture.url,
          title: `${userName}${mouthPicture.name}`,
          name: `${userName}${mouthPicture.name}`
        }
      })
    : [];

  return (
    <div id="LabViewTreatmentPlanRequest" className="animated slideInRight">
      <div className="sectionDivider"><div className="sectionDividerBasic"><span className="sectionDividerText">{CONTENT[2]}</span><span className="sectionDividerBorder"></span></div></div>
      <div className="currentInfoZones">
        {
          window.GlobalUtil.deepGetFromString(props.curTreatmentPlan, "rejectStepNotes", false) && 
          <RejectionNotes rejectStepNotes={window.GlobalUtil.deepGetFromString(props.curTreatmentPlan, "rejectStepNotes", "")} />
        }

        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[3]}</div>
          <div className="initalPictures">
            {
              (curTreatmentPlan && curTreatmentPlan.offerRequest && curTreatmentPlan.offerRequest.mouthPictures.length)
              ? curTreatmentPlan.offerRequest.mouthPictures.map((mouthPicture, index)=>{
                  var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
                  return(
                    <div className="initalPicture" key={index}>
                      <img src={mouthPicture.url} onClick={()=>{
                        var imagesNew = [...images.slice(index), ...images.slice(0, index)];
                        window.GlobalUtil.triggerEvent("ShowLightboxImages", imagesNew)
                      }} />
                      <a className="button button2 downloadButton" href={mouthPicture.url} download={`${userName}${mouthPicture.name}`}>{CONTENT[4]}</a>
                    </div>
                  )
                })
              : null
            }
          </div>
        </div>

        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[5]}</div>
          <div className="mouthScanFiles">
            {
              (curTreatmentPlan && curTreatmentPlan.offerRequest && curTreatmentPlan.offerRequest.otherFiles.length)
              ? curTreatmentPlan.offerRequest.otherFiles.map((mouthPicture, index)=>{
                  var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
                  var fulName = `${userName}${mouthPicture.name}`;
                  return(
                    <div className="downloadFile" key={index}>
                      <div className="fileName">{fulName}</div>
                      <a className="button button2 downloadButton" href={mouthPicture.url} download={fulName}>{CONTENT[4]}</a>
                    </div>
                  )
                })
              : null
            }
          </div>
        </div>


        <div className="currentInfoZone">
          <div className="sectionTitle">{CONTENT[6]}</div>
          <div className="notes">
            {window.GlobalUtil.deepGetFromString(curTreatmentPlan, "offerRequest,chiefComplaint", "")}
          </div>
        </div>

        {
          window.GlobalUtil.deepGetFromString(curTreatmentPlan, "offerRequest,notes", false) && 
          <div className="currentInfoZone">
            <div className="sectionTitle">{CONTENT[7]}</div>
            <div className="notes">
              {window.GlobalUtil.deepGetFromString(curTreatmentPlan, "offerRequest,notes", "")}
            </div>
          </div>
        }
      </div>
    </div>
  );
}








export default Index;



