import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";

const DEFAULT_MUTATION_FIELDS = `
  _id
  ID
  UID
  curOrders
  paymentType
  CLNTSCRT
  active
  meta{
    createAt
    updateAt
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;



const DEFAULT_MUTATION_INPUT_VAR = ` 
  $UID: String,
  $curOrders: String,
  $paymentType: String,
  $CLNTSCRT: String
`;


const DEFAULT_MUTATION_INPUT = ` 
  UID: $UID,
  curOrders: $curOrders,
  paymentType: $paymentType,
  CLNTSCRT: $CLNTSCRT
`;


const CLEAN_FIELDS = { 
  "UID": "string",
  "curOrders": "string",
  "paymentType": "string",
  "CLNTSCRT": "string"
}

const REPORTS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  CLEAN_FIELDS: CLEAN_FIELDS
}

export default REPORTS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
};