//import React from 'react';
const PAGE = [
  {
    "content": "View Treatment Plan"
  },
  {
    "content": "Cancel"
  },
  {
    "content": "Patient Information"
  },
  {
    "type": "span",
    "content": "<span style=\"color:red,font-weight:700\">WARNING THIS HAS BEEN REJECTED</span> - Rejection Notes"
  },
  {
    "content": "The text below SHOULD explain why this was sent back to you. Please fix any problems and submit it again."
  },
  {
    "content": "User Info"
  },
  {
    "content": "Treatment Plan Info"
  },
  {
    "content": "Treatment Plan PDF"
  },
  {
    "content": "Initial User Mouth Pictures"
  },
  {
    "content": "Other Files"
  },
  {
    "content": "Download"
  },
  {
    "content": "Chief Complaint"
  },
  {
    "content": "Other Notes"
  }
];




  
export default PAGE;