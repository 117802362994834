import React, { useState, useRef } from 'react';
import TemplateSlideInRight from 'global/templates/templateSlideInRight/index';
import API_CALLS from 'API_CALLS/index';
import FormBuilder from 'global/components/FormBuilder/index';

import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
const PREVIEWUSER = API_CALLS.USERS.PREVIEW;
const PREVIEWPLAN = API_CALLS.TREATMENT_PLANS.PREVIEW;



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      Plan: {}, 
      hideForm: false, 
      ShowPreview: false,
      isLoading: false //WHEN LOADING FILES
    };    
    this.APICompleteTask = this.APICompleteTask.bind(this);
    this.onClose = this.onClose.bind(this);
    this.SlideInRight = this.SlideInRight.bind(this);
    this.FormBuilderZone = this.FormBuilderZone.bind(this);
    this.PREVIEWUSER = PREVIEWUSER;
    this.PREVIEWPLAN = PREVIEWPLAN;
    this.curObj = {
      status: "PENDING"
    };
    this.UploadingFilesEventKey = window.GlobalUtil.subscribeEvent("UploadingFiles", isLoading=>{
      this.setState({isLoading});
    });
  }
  
  componentDidMount(){    
    window.APIGetAgain = this.APIGet;
    this.APIGet();
    if(this.OnDidMount) this.OnDidMount();
  }

  componentWillUnmount(){
    window.APIGetAgain = undefined;
    this.UploadingFilesEventKey.unsubscribe();
    clearTimeout(this.timeoutListener);
  }

  APICompleteTask(){
    window.CurRefetchList(true);
    this.onClose();
  }



  onClose(slideInRightRef){
    window.CurRefetchList(true); //RELOAD LIST
    window.CurRefetchSideNavNumbers(); //RELOAD SIDE MENU NUMBERS
    
    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose(); //ANIMATION SLIDE OUT
    if(this.props.onClose) this.props.onClose(); //CLOSE THIS IN PARENT
  }


  previewUser=(UserObj)=>{          
    var PREVIEWUSER = this.PREVIEWUSER;
    return(
      <Row>
        <Column style={{"paddingBottom":"0px"}}>
          <div className="previewFormFieldTitles d-flex">
            <div style={{"flex":"1"}}>
              <div className="previewFormFieldTitle">User</div>
              <div className="previewFormFieldSubTitle">
                <span style={{"fontWeight":"600"}}>Name</span>{`: ${window.GlobalUtil.deepGetFromString(UserObj, "firstName", "")} ${window.GlobalUtil.deepGetFromString(UserObj, "lastName", "")}`}&nbsp;
                <span style={{"paddingLeft":"40px","fontWeight":"600"}}>ID: &nbsp;</span> 
                {UserObj._id}
              </div>
            </div>
            <div className="viewFullButtonWrapper">
              <button className="button button1 btn-color5 viewFullButton" onClick={()=>{
                this.setState({
                  ShowPreview: ()=><PREVIEWUSER
                      VARIABLES={{
                        id: UserObj._id
                      }}
                    />
                });
              }}>View Full <i className="fas fa-eye"></i></button>
            </div>
          </div>
        </Column>
      </Row>
    )
  }


  previewPlan=(PlanObj)=>{          
    var PREVIEWPLAN = this.PREVIEWPLAN;
    return(
      <Row>
        <Column style={{"paddingBottom":"0px"}}>
          <div className="previewFormFieldTitles d-flex">
            <div style={{"flex":"1"}}>
              <div className="previewFormFieldTitle">Treatment Plan</div>
              <div className="previewFormFieldSubTitle">Treatment Plan ID:&nbsp;{PlanObj._id}</div>
            </div>
            <div className="viewFullButtonWrapper">
              <button className="button button1 btn-color5 viewFullButton" onClick={()=>{
                this.setState({
                  ShowPreview: ()=><PREVIEWPLAN
                    VARIABLES={{
                      id: PlanObj._id
                    }}
                  />
                });
              }}>View Full <i className="fas fa-eye"></i></button>
            </div>
          </div>
        </Column>
      </Row>
    )
  }

  FormBuilderZone(FORM_FIELDS){
    var {hideForm} = this.state;
    return (
      <Container>
        <Row>
          <Column 
            className={""}>
            {
              (!hideForm && this.curObj && this.curObj.id) && 
              <div >
                <Container className="">
                  <FormBuilder
                    disableKeySubmit={true}
                    initialValues={this.curObj}
                    listOfFields={FORM_FIELDS(this.curObj)}
                    onSubmit={()=>{
                    }}
                    onChange={newObj => {
                      this.curObj = {...this.curObj, ...newObj};
                      //console.log("this.curObj","\n\n",this.curObj,"\n\n");
                      if(this.timeoutListener) clearTimeout(this.timeoutListener);
                      this.timeoutListener = setTimeout(()=>{
                        this.validate(true)
                        clearTimeout(this.timeoutListener)
                      }, 1000);   
                            
                    }}
                    onValidate={(isValid)=>{
                      this.setState({isValid})
                    }}
                  />
                </Container>
              </div>
            }
          </Column>
        </Row>
      </Container>
    )
  }


  SlideInRight(TITLE, BUTTONS, FIELDS){
    var {isValid, ShowPreview} = this.state;
    return (
      <TemplateSlideInRight
        ref={e=>this.slideInRightRef=e}
        title={TITLE}
        isValid={isValid}
        onClose={this.onClose}
        onSave={this.validate}
        customButtons={BUTTONS}>
        <React.Fragment>
          {FIELDS()}
          {
            ShowPreview &&
            <PreviewSlideOut
              onClose={()=>{
                this.setState({ShowPreview: undefined});
              }}
              showFunction={ShowPreview}
            />
          }
        </React.Fragment>
      </TemplateSlideInRight>
    )
  }
}






const PreviewSlideOut = (props)=>{
  return(
    <TemplateSlideInRight
      title={"View Preview"}
      onClose={props.onClose}
      onSave={props.validate}
      customButtons={(curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2" onClick={()=>{
              props.onClose()
            }}>Close</button>
          </React.Fragment>
        )
      }}
      >
      {props.showFunction()}
    </TemplateSlideInRight>
  )
}


export default Index;