import React, { useState, useRef } from 'react';
import FormBuilder from 'global/components/FormBuilder/index';
///Users/danielezaldivar/Sites/sonrisas/src/styles/global/EditNotes.scss

class Index extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {;  
    this.newNote = (this.props.currentNote ? this.props.currentNote : {
      note: '',
      title: '',
      createAt: new Date(),
      updateAt: new Date(),
      status: 'ACTIVE'
    });
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }
  onSave = ()=>{
    var newNote = {...this.newNote, updateAt:`${Date.now()}`};
    if(!newNote.createAt){
      newNote.createAt = Date.now();
    }
    if(newNote.category) newNote.category = newNote.category.toUpperCase(); //CAPITALIZE CATEGORIES
    return this.props.onSave(newNote);
  }
  render(){
    var {triggerButton, defaultCategories} = this.props;            
    return (
      <div className="editNoteTrigger" onClick={(e)=>{
        if(e) e.preventDefault()
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: <div className="flex-between"><div className="">Edit Note</div><i className="fas fa-trash-alt" onClick={()=>{
            this.newNote = {...this.newNote, status: 'INACTIVE'};
            this.onSave();
            window.GlobalUtil.DialogStack.closeTop();
          }}></i></div>,
          customClass: "frontEndSlideInRight mediumSlideInRight",
          width: " ",
          onClose: ()=>{
            // this.setState({activeNote: {
            //   note: '',
            //   createAt: new Date(),
            //   updateAt: new Date(),
            //   status: 'ACTIVE'
            // }}) 
          },
          onSave: async ()=>{
            await this.onSave();
            return;
          },
          //noFooter: true,
          children: (props)=>{                                      
            return(
              <EditNote 
                {...props}
                defaultCategories={defaultCategories}
                activeNote={this.newNote}
                onChange={(newNote)=>{
                  this.newNote = newNote;
                }}
              />
            )
          }
        });
      }}>
        {triggerButton ? triggerButton : <i className="fas fa-edit"></i>}
      </div>
    );
  }
}




const EditNote = (props) => {
  var {activeNote, defaultCategories=[]} = props;          
  return (
    <div className="editNoteSlideInForm">
      <FormBuilder
        didMount={()=>{
          // window.GlobalUtil.delay(1000).then(()=>{
          //   var notesSelector = document.querySelector("#slideInNotes textarea");
          //   if(notesSelector){
          //     notesSelector.focus();
          //     notesSelector.select();
          //   }
          // });
        }}
        initialValues={activeNote}
        listOfFields={[
          {
            title: <div className="formTitle"><span className="">Title</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Short title for this log.</span></div>,
            type: "text",
            //id: "slideInNotes",
            //rows: 10,
            name: "title",
            //placeholder: "First Name",
            required: true
          },
          {
            title: <div className="formTitle"><span className="">Notes</span></div>,
            type: "textArea",
            id: "slideInNotes",
            rows: 10,
            name: "note",
            //placeholder: "First Name",
            required: true
          },
          // {
          //   title: <div className="formTitle"><span className="">Date</span></div>,
          //   type: "datePicker",
          //   name: "date",
          //   //placeholder: "Last Name",
          //   required: true
          // },
          {
            title: <div className="formTitle"><span className="">Status</span></div>,
            //subTitle: <div className="formSubTitle"><span className="">Choose a Status for your Product. Active means it'll go live.</span></div>,
            type: "select",
            name: "status",
            options: [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "ACTIVE",
                "title": "ACTIVE"
              },
              {
                "value": "PENDING",
                "title": "PENDING"
              },
              {
                "value": "INACTIVE",
                "title": "INACTIVE"
              },
              {
                "value": "COMPLETE",
                "title": "COMPLETE"
              }
            ],
            required: true,
            defaultValue: "ACTIVE",
            defaultValuePath: "status",
            errorMessage: "Status is required"
          },
          {
            title: <div className="formTitle"><span className="">Category</span></div>,
            //subTitle: <div className="formSubTitle"><span className="">Choose a Status for your Product. Active means it'll go live.</span></div>,
            type: "select",
            name: "category",
            options: [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              ...defaultCategories
            ],
            required: true,
            defaultValue: "",
            defaultValuePath: "category",
            errorMessage: "Category is required"
          },
          {
            title: <div className="formTitle"><span className="">Or input new category</span></div>,
            //subTitle: <div className="formSubTitle"><span className="">Choose a Status for your Product. Active means it'll go live.</span></div>,
            type: "text",
            name: "category",
            required: true,
            defaultValue: "",
            defaultValuePath: "category",
            errorMessage: "Category is required"
          }
        ]}
        //onSubmit={this.onSubmitContact}
        onChange={newObj => {
          props.onChange(newObj);
        }}
        onValidate={(isValid)=>{
          //this.setState({curStatus: isValid});
        }}
      />
    </div>
  );
}


export default Index;
      
