import React from 'react';
import DEFAULT from './DEFAULT';
import ALIGNER_GROUP from './ALIGNER_GROUP';

const PREVIEW_FORMS = {
  DEFAULT,
  ALIGNER_GROUP
}


export default PREVIEW_FORMS;