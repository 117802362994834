import React from 'react';

//BY DEFAULT IT WILL FIND ALL THOSE WITH "active" AS TRUE
const DEFAULT_QUERY_INPUT_VAR = ` 
  $query:String,
  $argument:String
  $manual:[ManualInput],
  $offset: Int,
  $limit: Int,
  $sortBy:String,
  $descending:Boolean,
  $search:String,
  $type: String,
  $userType: String,
  $name: String,
  $dates: [Dates],
  $status: String,
  $active: Boolean,
  $include: [Include],
  $deepSearch: InputDeepSearch
`; 

const DEFAULT_QUERY_INPUT = ` 
  query: $query,
  argument: $argument,
  manual: $manual,
  offset: $offset,
  limit: $limit,
  sortBy: $sortBy,
  descending: $descending,
  search: $search,
  type: $type,
  userType: $userType,
  name: $name,
  dates: $dates,
  status: $status,
  active: $active,
  include: $include,
  deepSearch: $deepSearch
`;




/*
WHAT IT LOOKS LIKE FROM QUERY IN THE SERVER
input ManualInput {
  path: String,
  val: String,
  listValues: String, //STRING OF ARRAY "['DOG','CAT',2,3,4]"
  not: Boolean
}

input Pagination {
  query: String,
  argument: String
  manual: [ManualInput],
  offset: Int,
  limit: Int,
  sortBy: String,
  descending: Boolean,
  search: String,
  type: String,
  userType: String,
  name: String,
  dates: [Dates],
  status: String,
  include: [Include]
}

input Include {
  name: String,
  include: [Include]
}

input Dates{
  gte: String,
  gt: String,
  lte: String,
  lt: String,
  field: String,
  bool:Boolean
}

*/


export {
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT
};