//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Clear aligners made of premium materials without harmful toxins",
			"DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Number one safe affordable clear aligners for discreet invisible orthodontics.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h1 class="sectionTitle">Safe Clear aligners for modern teeth straightening!</h1>
						<h5>Remember, a confident smile starts with straight teeth for any lifestyle. Embrace the convenience of clear braces and set yourself on the path to a beautiful smile!</h5>
						<p>Teeth straightening at home has become an increasingly popular and affordable option for individuals seeking effective solutions to achieve a confident smile. Among the variety of options available, clear braces and invisible braces, often referred to as the best aligners, have gained significant attention. They are considered the safest aligners by many, offering the convenience of an at-home treatment plan.</p>
						<p>If you're looking for the number one clear aligners, Smiles Club has the latest technologies without any cheap plastics that can be harmful. We also offer financial options to get your aligner treatment now and pay later.</p>
					`
				},
				{
					"content": `Woman smiling with invisible braces`
				}
			],
			"Section2": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">Only Safe NON-Toxic Materials.</h2>
						<p>Toxic plastics pose a significant threat to both human health and the environment. Some may contain harmful chemicals, such as bisphenol A (BPA) and phthalates, which can leach to long-term health issues.</p>
						<h6><strong>WE ARE PROUND TO NOT USE ANY BPA PLASTICS!</strong></h6>
						<p>Our reputable clear aligners dental company uses the same <strong>SAFE PLASTICS used by Professional Dentist.</strong> It is crucial for individuals and industries to adopt more sustainable alternatives and reduce the  use of toxic plastics to protect human health and preserve the health of our planet.</p>
					`
				},
				{
					"content": `Mother and daughter looking at teeth straightening kits online`
				}
			],
			"Section3": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">Straighten teeth discreetly and comfortably.</h2>
						<p>The process starts by obtaining aligners, custom-made to fit your teeth perfectly. These aligners gradually shift your teeth into their desired position, following a carefully curated treatment plan. By wearing the aligners consistently, your teeth will gradually adjust, and you'll notice a significant improvement in your smile and overall dental alignment.</p>
						<p>With a clear aligners kit, individuals can easily manage their teeth alignment process on their own, without frequent visits to doctors or orthodontists. Just follow the instructions provided by our dental professionals to ensure your teeth straightening journey is a success.</p>
					`
				},
				{
					"content": `women viewing clear Orthodontics on a laptop`
				}
			],
			"Section4": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">One key advantages of our clear aligner treatment is it's flexibility.</h2>
						<p>You can straighten your teeth at your own pace, with the convenience of our Smiles club membership. Unlike traditional braces, clear aligners are removable, allowing you to maintain your oral hygiene routine easily. This means you can still enjoy your favorite foods without any worries.</p>
						<p>Once you complete your teeth straightening journey, you'll be provided with clear retainers. These retainers help to preserve the results achieved, ensuring your smile remains beautiful and aligned for years to come.</p>
					`
				},
				{
					"content": `A woman holding the best clear aligners and teeth.`
				}
			],
			"Section5": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">Smiles Club makes it easy to have a beautiful smile, without the hassle of surgery or braces.</h2>
						<p>Clear aligners have revolutionized orthodontic treatment, and many individuals are opting for this advanced and efficient approach. With clear aligner technology, the road to straighter teeth is more accessible and comfortable than ever before. Achieving straight teeth and improving your dental alignment has never been this transparent and user-friendly.</p>
						<p>Our orthodontists and dental professionals design your aligners for each stage of the straightening process, which typically lasts between 3 to 18 months. If you have questions or concerns, you should always feel free to contact the Smiles Club patient care team. We are here to help you meet your goals.</p>
					`
				},
				{
					"content": `Women holding clear aligners in her hand and smiling.`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
			"DESCRIPTION": "El programa de Sonrisas Club hace que sea fácil tener una hermosa sonrisa, sin la molestia de la cirugía o los frenos tradicionales.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h1 class="sectionTitle">¡Alineadores transparentes seguros para el enderezamiento de los dientes modernos!</h1>
						<h5>¡Recuerde, una sonrisa segura comienza con dientes rectos para cualquier estilo de vida. Aproveche la conveniencia de los frenos transparentes y póngase en el camino hacia una hermosa sonrisa!</h5>
						<p>El enderezamiento de los dientes en el hogar se ha convertido en una opción cada vez más popular y asequible para las personas que buscan soluciones efectivas para lograr una sonrisa segura. Entre la variedad de opciones disponibles, los aparatos ortopédicos transparentes y los aparatos ortopédicos invisibles, a menudo denominados los mejores alineadores, han ganado una atención significativa. Muchos los consideran los alineadores más seguros, ya que ofrecen la comodidad de un plan de tratamiento en el hogar.</p>
						<p>Si está buscando los alineadores transparentes número uno, Smiles Club tiene las últimas tecnologías sin plásticos baratos que pueden ser dañinos. También ofrecemos opciones financieras para obtener su tratamiento con alineadores ahora y pagar más tarde.</p>
					`
				},
				{
					"content": `Mujer sonriendo con aparatos ortopédicos invisibles`
				}
			],
			"Section2": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">Solo materiales libres de tóxicos peligrosos.</h2>
						<p>Los plásticos tóxicos representan una amenaza importante tanto para la salud humana como para el medio ambiente. Algunos pueden contener sustancias químicas dañinas, como bisfenol A (BPA) y ftalatos, que pueden filtrarse y causar problemas de salud a largo plazo.</p>
						<h6><strong>¡ESTAMOS ORGULLOSOS DE NO UTILIZAR NINGÚN PLÁSTICO BPA!</strong></h6>
						<p>Nuestra prestigiosa empresa dental de alineadores transparentes utiliza los mismos <strong>PLÁSTICOS SEGUROS utilizados por dentista profesional.</strong> Es crucial que las personas y las industrias adopten alternativas más sostenibles y reduzcan el uso de plásticos tóxicos para proteger la salud humana y preservar la salud de nuestro planeta.</p>
					`
				},
				{
					"content": `Madre e hija mirando kits para enderezar los dientes en línea`
				}
			],
			"Section3": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">Enderezar los dientes de forma discreta y cómoda.</h2>
						<p>El proceso comienza con la obtención de alineadores, hechos a medida para adaptarse perfectamente a sus dientes. Estos alineadores desplazan gradualmente los dientes a la posición deseada, siguiendo un plan de tratamiento cuidadosamente seleccionado. Al usar los alineadores constantemente, sus dientes se ajustarán gradualmente y notará una mejora significativa en su sonrisa y en la alineación dental general.</p>
						<p>Con un kit de alineadores transparentes, las personas pueden administrar fácilmente el proceso de alineación de sus dientes por su cuenta, sin visitas frecuentes a médicos u ortodoncistas. Simplemente siga las instrucciones proporcionadas por nuestros profesionales dentales para asegurarse de que su proceso de enderezamiento de los dientes sea un éxito.</p>
					`
				},
				{
					"content": `Mujeres viendo ortodoncia clara en una computadora portátil`
				}
			],
			"Section4": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">Una de las ventajas clave de nuestro tratamiento con alineadores transparentes es su flexibilidad.</h2>
						<p>Puede enderezar sus dientes a su propio ritmo, con la comodidad de nuestra membresía del club Smiles. A diferencia de los frenos tradicionales, los alineadores transparentes son removibles, lo que le permite mantener fácilmente su rutina de higiene bucal. Esto significa que aún puede disfrutar de sus comidas favoritas sin preocupaciones.</p>
						<p>Una vez que complete su viaje de enderezamiento de los dientes, se le proporcionarán retenedores transparentes. Estos retenedores ayudan a preservar los resultados logrados, asegurando que su sonrisa permanezca hermosa y alineada en los años venideros.</p>
					`
				},
				{
					"content": `Una mujer con los mejores alineadores y dientes transparentes.`
				}
			],
			"Section5": [
				{
					"type": "div",
					"className": "textSection",
					"content": `
						<h2 class="sectionTitle2">Smiles Club hace que sea fácil tener una hermosa sonrisa, sin la molestia de una cirugía o aparatos ortopédicos.</h2>
						<p>Los alineadores transparentes han revolucionado el tratamiento de ortodoncia y muchas personas optan por este enfoque avanzado y eficiente. Con la tecnología de alineadores transparentes, el camino hacia dientes más rectos es más accesible y cómodo que nunca. Conseguir dientes rectos y mejorar su alineación dental nunca ha sido tan transparente y fácil de usar.</p>
						<p>Nuestros ortodoncistas y profesionales dentales diseñan sus alineadores para cada etapa del proceso de enderezamiento, que suele durar entre 3 y 18 meses. Si tiene preguntas o inquietudes, no dude en comunicarse con el equipo de atención al paciente de Smiles Club. Estamos aquí para ayudarte a alcanzar tus metas.</p>
					`
				},
				{
					"content": `Mujeres con alineadores transparentes en la mano y sonriendo"`
				}
			]
		}
	}
};


	
export default PAGE;
