import React, { useEffect, useState, useCallback } from 'react';
import Loading from 'global/components/Loading/index';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './checkoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = (process.env.NODE_ENV === 'development') ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC) : loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE);
//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
export default function Index(props) {
  var Session = window.GlobalUtil.State.get("Session");
  var curOrders = window.GlobalUtil.State.get("newOrder");
  var {products=[], coupon} = curOrders;
  const CHECKOUT_TOTAL = window.GlobalUtil.checkoutCalculatorNew(products, coupon);  
  const [clientSecret, setClientSecret] = useState("");
  const tempCartObj = { //THIS IS THE OBJECT THAT WILL BE SAVED, TEMPARERALY UNTIL THEY SUCCEED OR FAIL THEIR NEXT STEP
    UID: Session.user._id,
    curOrders: JSON.stringify(curOrders) 
  }
  const shipping = {
    "name": `${Session.user.firstName} ${Session.user.lastName}`, 
    //UID: Session.user._id, 
    "address": {
      "line1": Session.user.address.street,
      "line2": Session.user.address.street2,
      "city": Session.user.address.city,
      "state": Session.user.address.state, //Session.user.address.state.slice(0,2).toUpperCase(),
      "postal_code": Session.user.address.zipcode,
      "country": "US"
    }
  }
  useEffect(() => {
    fetch(((process.env.NODE_ENV === 'development') ? "http://127.0.0.1:3001/create-payment-intent" : `${window.location.origin}:3001/create-payment-intent`), {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "amount": `${Number(CHECKOUT_TOTAL.total)*100}`, //MUST BE 194000 NOT 1940
        "metadata": {
          "firstName": Session.user.firstName,
          "lastName": Session.user.lastName,
          "phone": Session.user.phone,
          //"address": Session.user.address
        }
      }),
    })
    .then((res) => res.json())
    .then((data) => {
      //console.log('original clientSecret Key', data.clientSecret);
      setClientSecret(null);
      setClientSecret(data.clientSecret);
    });
  }, []);
  
  const appearance = {
    theme: 'stripe',
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';
  //console.log('clientSecret', clientSecret);
  
  return (
    <div id="StripePayment">
      {
        clientSecret
        ? <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
            <CheckoutForm shipping={shipping} tempCartObj={{...tempCartObj}}/>
          </Elements>
        : <Loading
            header={undefined}
            body={undefined}
            floating={false}
            overlay={false}
            inlineStyles={{
              loadingIcon: {
                "maxWidth": "150px"
              }
            }}
          />
      }
    </div>
  );
};
