import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  from
  to
  email
  phone
  type
  message
  language
  whoToEmail
  notes
  notesList{
    ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
  }
  status
  leadData
  bestTimeToContact
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $from: String,
  $to: String,
  $email: String,
  $phone: String,
  $type: String,
  $whoToEmail: String,
  $message: String,
  $language: String,
  $notes: String,
  $notesList: [NotesListItemInput],
  $leadData: String,
  $status: String,
  $bestTimeToContact: String
`;


const DEFAULT_MUTATION_INPUT = ` 
  from: $from,
  to: $to,
  email: $email,
  phone: $phone,
  type: $type,
  whoToEmail: $whoToEmail,
  message: $message,
  language: $language,
  notes: $notes,
  notesList: $notesList,
  leadData: $leadData,
  status: $status,
  bestTimeToContact: $bestTimeToContact
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "from": "string",
  "to": "string",
  "email": "string",
  "notes": "string",
  "notesList": [
    CLEAN_FIELDS_DEFAULT.NOTESLIST
  ], 
  "whoToEmail": "string",
  "phone": "string",
  "type": "string",
  "message": "string",
  "language": "string",
  "leadData": "string",
  "status": "string",
  "bestTimeToContact": "string",
  "meta": CLEAN_FIELDS_DEFAULT.META
}


const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    path: "from",
    type: "VALUE",
    name: "From",
    searchable: true
  },
  {
    path: "to",
    type: "VALUE",
    name: "To",
    searchable: true
  },
  {
    path: "language",
    type: "VALUE",
    name: "Language",
    searchable: true
  },
  {
    path: "type",
    type: "VALUE",
    name: "Type"
  },
  {
    path: "leadData",
    type: "VALUE",
    name: "Lead" ,
    searchable: true
  },
  {
    path: "status",
    type: "VALUE",
    name: "Status"
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  }
];





const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME":"ID"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "type",
            "TYPE": "STRING",
            "NAME": "Type"
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "FIELDS": [
          {
            "PATH": "from",
            "TYPE": "STRING",
            "NAME": "From"
          },
          {
            "PATH": "to",
            "TYPE": "STRING",
            "NAME": "To"
          },
          {
            "PATH": "bestTimeToContact",
            "TYPE": "DATE",
            "FORMAT": "n/d/Y @ H:mA",
            "NAME": "Best Time To Contact"
          },
          {
            "PATH": "email",
            "TYPE": "STRING",
            "NAME":"Email"
          },
          {
            "CONDITION": obj=>obj.whoToEmail,
            "PATH": "whoToEmail",
            "TYPE": "STRING",
            "NAME":"Who to Email"
          },
          {
            "PATH": "phone",
            "TYPE": "STRING",
            "NAME": "Phone"
          },
          {
            "PATH": "language",
            "TYPE": "STRING",
            "NAME": "Language"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME": "Status"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "noPadding",
        "CONDITION": (obj)=>obj.leadData,
        "FIELDS": [
          {
            "PATH": "leadData",
            "TYPE": "KEYVALUELISTSTRING",
            "NAME": "Lead Data"
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.MESSAGE(),
  PREVIEW_FORM_TEMPLATES.NOTES(),
  PREVIEW_FORM_TEMPLATES.NOTES_LIST({
    TITLE:"Communication History",
    SUB_TITLE:"Full history of our back and forth communication. Please fill out as good as you can."
  }),
  PREVIEW_FORM_TEMPLATES.META()
];


const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  if(!curCleanObj.whoToEmail) delete curCleanObj.whoToEmail;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  if(!curCleanObj.whoToEmail) delete curCleanObj.whoToEmail;
  return curCleanObj;
}


const COMS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default COMS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};