import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from 'global/templates/templatePage/index';



const QUERY = API_CALLS.COUPONS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.COUPONS.QUERIES.single();
const QUERY_COUNT = API_CALLS.COUPONS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.COUPONS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.COUPONS.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);

const FORM_FIELDS = API_CALLS.COUPONS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.COUPONS.LIST_HEADER_FIELDS;

const PREVIEW = API_CALLS.COUPONS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};








const PAGES = () => [
  {
    "id": "COUPONS00",
    "Name":"Active",
    "PageName": "Active",
    "BasePage": "coupons",
    "Path": "active",
    "TITLE": "Active Coupons",
    "DATA_FIELD": "coupons",
    "GlobalStateVar": ["couponsCountActive"],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "active": true,
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "dates": [
        {
          "field": "duration.startAt",
          "lte": `${Date.now()}`
        },
        {
          "field": "duration.endAt",
          "gt": `${Date.now()}`
        }
      ],
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "EDITBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "DELETEBUTTON": {
      NAME: "COUPONS"
    },
    "CLONEBUTTON": {
      NAME: "COUPONS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("coupons/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Coupon",
            "TITLE": "Coupon", //TITLE OF SLIDE OUT
            "APINAME": "COUPONS",
            "NAME": "Coupon",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "COUPONS01",
    "Name":"Upcoming",
    "PageName": "Upcoming",
    "BasePage": "coupons",
    "Path": "upcoming",
    "TITLE": "Upcoming Coupons",
    "DATA_FIELD": "coupons",
    "GlobalStateVar": ["couponsCountUpcomming"],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "dates": [
        {
          "field": "duration.startAt",
          "gt": `${Date.now()}`
        }
      ],
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "EDITBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "DELETEBUTTON": {
      NAME: "COUPONS"
    },
    "CLONEBUTTON": {
      NAME: "COUPONS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("coupons/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Coupon",
            "TITLE": "Coupon", //TITLE OF SLIDE OUT
            "APINAME": "COUPONS",
            "NAME": "Coupon",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "COUPONS02",
    "Name":"Pending",
    "PageName": "Pending",
    "BasePage": "coupons",
    "Path": "pending",
    "TITLE": "Pending Coupons",
    "DATA_FIELD": "coupons",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "PENDING",
      "active": true,
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "EDITBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "DELETEBUTTON": {
      NAME: "COUPONS"
    },
    "CLONEBUTTON": {
      NAME: "COUPONS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("coupons/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Coupon",
            "TITLE": "Coupon", //TITLE OF SLIDE OUT
            "APINAME": "COUPONS",
            "NAME": "Coupon",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "COUPONS03",
    "Name":"Expired",
    "PageName": "Expired",
    "BasePage": "coupons",
    "Path": "expired",
    "TITLE": "Coupon Expired",
    "DATA_FIELD": "coupons",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "active": true,
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "dates": [
        {
          "field": "duration.endAt",
          "lte": `${Date.now()}`
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "EDITBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "DELETEBUTTON": {
      NAME: "COUPONS"
    },
    "CLONEBUTTON": {
      NAME: "COUPONS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("coupons/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Coupon",
            "TITLE": "Coupon", //TITLE OF SLIDE OUT
            "APINAME": "COUPONS",
            "NAME": "Coupon",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "COUPONS04",
    "Name":"History",
    "PageName": "History",
    "BasePage": "coupons",
    "Path": "history",
    "TITLE": "Coupon History",
    "DATA_FIELD": "coupons",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "INACTIVE",
      "active": true,
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "EDITBUTTON": {
      NAME: "COUPONS",
      TITLE: "Coupon",
      FIELD: "coupon"
    },
    "DELETEBUTTON": {
      NAME: "COUPONS"
    },
    "CLONEBUTTON": {
      NAME: "COUPONS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("coupons/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Coupon",
            "TITLE": "Coupon", //TITLE OF SLIDE OUT
            "APINAME": "COUPONS",
            "NAME": "Coupon",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  }
];








const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminCoupons"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "couponCount",
            TITLE: "New Coupons Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "couponCount",
            TITLE: "Coupons this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "couponCount",
            TITLE: "Coupons this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "couponCount",
            TITLE: "Coupons this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/",
        "to": "active"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
