//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="sectionTitle">Contáctanos</h1>`
	},
	{
		"type": "div",
		"content": `<span></span>`
	},
	{
		"content": `Contáctanos`
	},
	{
		"content": `Enviar`
	},
	{
		"content": `Cerrar`
	},
	{
		"content": `Nombre`
	},
	{
		"content": `Por favor incluye tu nombre completo`
	},
	{
		"content": `Se requiere el nombre completo antes de enviar`
	},
	{
		"content": `Teléfono`
	},
	{
		"content": `No venderemos tu información a vendedores telefónicos.`
	},
	{
		"content": `Se requiere un teléfono antes de enviar`
	},
	{
		"content": `Email`
	},
	{
		"content": `Un correo electrónico válido nos permitirá comunicarnos contigo con nuestros resultados.`
	},
	{
		"content": `Debes agregar un correo electrónico válido`
	},
	{
		"content": `Mensaje`
	},
	{
		"content": `Cuéntanos un poco sobre ti. ¿Qué estás buscando arreglar? Incluye cuál es el mejor momento para llamarte.`
	},
	{
		"content": `Por favor escribe algo`
	},
	{
		"type": "div",
		"content": `<div>
      <h2>Tu solicitud ha sido enviada</h2>
      <div class="">Un representante se comunicará contigo a la brevedad.</div>
    </div>`
	},
	{
		"type": "div",
		"content": `<div>
      <h2>Algo parece estar mal</h2>
      <div class="">Inténtalo de nuevo más tarde.</div>
    </div>`
	}
];




	
export default PAGE;