import React from 'react';
import ALIGNER_GROUP from './ALIGNER_GROUP';
const PREVIEW_FORM = [
  {
    "TYPE": "ROW",
    "FIELDS": [
      ...ALIGNER_GROUP,
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"About",
        "SUB_TITLE":"About this Aligner Group",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME": "Aligner Group ID"
          },
          {
            "PATH": "TPID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Treatment Plan", //TITLE OF SLIDE OUT
              "APINAME": "TREATMENT_PLANS",
              "ID_PATH": "TPID",
              "EDITABLE": true
            },
            "NAME":"Treatment Plan ID"
          },
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "User", //TITLE OF SLIDE OUT
              "APINAME": "USERS",
              "ID_PATH": "UID",
              "EDITABLE": true
            },
            "NAME":"User ID"
          },
          {
            "PATH": "OIID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Order Item", //TITLE OF SLIDE OUT
              "APINAME": "ORDER_ITEMS",
              "ID_PATH": "OIID",
              "EDITABLE": true
            },
            "NAME":"Order Item ID"
          },
          // {
          //   "NAME": "Aligner ID's",
          //   "PATH": "AIDS",
          //   "TYPE": "ARRAY_STRINGS"
          // }
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Schedule",
        "SUB_TITLE":"Time Information for Aligner",
        "FIELDS": [
          {
            "CONDITION": (curObj)=>curObj.createdBy,
            "NAME": "Created By",
            "PATH": "createdBy",
            "TYPE": "STRING",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "createdAt",
            "TYPE": "DATE",
            "NAME": "Created At",
            "DEFAULT": "NULL"
          },
          {
            "NAME": "Step",
            "PATH": "step",
            "TYPE": "STRING"
          },
          {
            "NAME": "Status",
            "PATH": "status",
            "TYPE": "STRING"
          },
        ]
      },
    ]
  }
]


export default PREVIEW_FORM;