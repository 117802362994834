import React from 'react';
// import {NavLink, Link} from 'react-router-dom';
//import "styles/scss/website/index.scss";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutSummary: {productCount: 0},
      newOrder: (((typeof window !== 'undefined') && window.GlobalUtil.State.get("newOrder")) ? window.GlobalUtil.State.get("newOrder") : {products: [], coupon: {}})
    };
  }

  componentDidMount(){
    this.setupSystem();
    this.countProducts(window.GlobalUtil.State.get("newOrder"));
  }

  countProducts = (newOrder={}) => {
    var checkoutSummary = window.GlobalUtil.checkoutCalculatorNew(newOrder.products, newOrder.coupon)
    this.setState({
      checkoutSummary: checkoutSummary //CHECKOUT SUMMARY HAS SEVERAL OTHER VALUES THAT COULD BE USEFULL
    });
    //var newOrder = window.GlobalUtil.State.get("newOrder");
  }

  setupSystem = () => {
    this.HandleCartOrdersKey = window.GlobalUtil.State.subscribe("newOrder", newOrd=>{
      this.setState({
        newOrder: newOrd
      }, ()=>this.countProducts(newOrd));
    });
  }

  componentWillUnmount(){
    this.HandleCartOrdersKey.unsubscribe()
  }

  render(){
    if(typeof window === 'undefined') return( //SERVER SIDE
      <div className="cartButton">
        <div className="cartLink">
          <i className="cartIcon fas fa-shopping-cart"></i>
        </div>
      </div>
    );



    
    var {checkoutSummary={}} = this.state;
    var {productCount=0} = checkoutSummary;
    var showProductCount = (productCount && productCount != "0" && productCount != 0 && (Number(productCount) > 0)) ? true : false;
    return (
      <div className="cartButton">
        <div onClick={()=>window.GlobalUtil.triggerEvent("cartEvent", "show")} className="cartLink">
          {showProductCount && <div className="cartCount">{productCount}</div>}
          <i className="cartIcon fas fa-shopping-cart"></i>
        </div>
      </div>
    );
  }
}



export default Component;