//import React from 'react';
const PAGE = [
  {
    "content": `Enviar de vuelta`
  },
  {
    "content": "Rechazar el plan de tratamiento"
  },
  {
    "content": "Cancelar"
  },
  {
    "content": "Enviar"
  },
  {
    "content": "Orden de actualización"
  },
  {
    "content": "Por favor espere"
  },
  {
    "content": "Rechazar este plan de tratamiento"
  },
  {
    "content": "Motivo del rechazo"
  },
  {
    "content": "Dígale al laboratorio por qué rechazamos esta tarea."
  },
  {
    "content": "Cerrar"
  },
  {
    "content": "Guardar"
  },
  {
    "content": "Rechazar"
  }
];




  
export default PAGE;