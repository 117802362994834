/*
LAST WORKED ON FEB/19/2022 SAT. DURRING 1ST EVER GIVEAWAY, WHEN WE HAD 12 PEOPLE ON LIST. BEFORE WE MADE ANY SALES
WHAT DOCTORS NEEDS:
□ FIX CSS TO MATCH AND WORK FOR DR, LAB, AND NORMAL USER
□ ON BILLING
  □ CREATE A CUSTOM VIEWER FOR THEIR ORDER PREVIEW TO SHOW ALL THE USERS INCLUDED IN THE INVOICE THEY PAID

  

□ ON ADMIN SIDE
  □ CREATE WAY TO UPDATE DOCTOR'S ORDERS WHEN THEY PAY FOR THE ALIGNERS THEY'VE ORDERD
    □ CREATE WAY TO CREATE AN ORDER, ASSIGN IT TO SOME DOCTOR, AND SELECT ALL USERS THEY "PAID" FOR
  □ ~CREATE WAY TO CREATE NEW DOCTORS


  // {
  //   "PageName": "Billing",
  //   "Name": "Billing",
  //   "Paths": ["/doctor/billing"],
  //   "Menu": ["Header", "Mobile"],
  //   "Icon": "fas fa-money-check"
  // },
  // {
  //   "PageName": "Facturación",
  //   "Name": "Facturacion",
  //   "Paths": ["/doctor/facturacion"],
  //   "Menu": ["HeaderESP", "MobileESP"],
  //   "Icon": "fas fa-money-check"
  // },

*/


import React from 'react';
import "styles/scss/doctor/index.scss";

import Dashboard from './pages/dashboard/index';
import Patients from './pages/patients/index';
import Profile from './pages/profile/index';
import Help from './pages/help/index';


//import UserProgressChanger from './pages/userProgressChanger'; //THIS TEMP SHOWS UP TO ALLOW TESTING ALT SITUATIONS
import MobileMenu from './components/MobileMenu';
import {  Routes, Route, Navigate } from "react-router-dom";

import PAGES from './pages/pages.json'; //ADD NEW PAGES HERE
//import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
//import Loading from 'global/components/Loading/index';
import Header from 'user/doctor/components/Header/index';
import NavMenu from 'user/doctor/components/NavMenu/index';
import {GetFullUserInfo} from 'global/utils/helper-functions';

const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();


const PAGES_COMPONENTS = { //ADD ANY NEW PAGES HERE
  Dashboard: Dashboard,
  Patients: Patients,
  Profile: Profile,
  Help: Help
};


//window.Session.user.type === "ONLINE"
//GET TASK USER_PURCHASE_TREATMENT_PLAN IF AVALIBAL

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
    };              
  }

  componentDidMount(){
    window.ForceUserReload = ()=>GetFullUserInfo({
      noCache: true,
      callBackSuccess: ()=>{},
      callBackFailed: ()=>{}
    });
    if(document.querySelector("#Website")) document.querySelector("#Website").remove();

    // if(typeof window === 'undefined'){
    // }
  }

  componentWillUnmount(){
  }  
  

  onUpdateUser = (newObj, callBack=()=>{}) => {
    window.Client.mutate({ //API UPDATE
      mutation: MUTATION_USER_EDIT,
      variables: newObj
    })
    .then(async (obj)=>{
      await callBack();
      await window.ForceUserReload();
      //if(this) this.setState({date: new Date()});
      return true;
    })
    .catch((error)=>{
      console.log("error updating user");
      console.log(error,"\n\n");  
      callBack();
    });
  }

  render(){
    var {showMobileMenu} = this.state;
    var isLoggedIn = window.GlobalUtil.getGlobalVariable("isLoggedIn", false);
    if(typeof window === 'undefined') return null;
    if(window.Session && window.Session.user && (window.Session.user.role === "USER")) return(<Routes><Route path="*" element={<Navigate to="/user" replace />}/></Routes>); //IF IS USER, NOT DOCTOR THEN GO TO USER
    return (
      <div id="DoctorWrapper" className="animated fadeIn">
        {
          (!isLoggedIn || !window.Session.user)
          ? null
          : <div id="Doctor">
              {
                showMobileMenu && <MobileMenu onHideSideMenu={()=>this.setState({showMobileMenu: false})} onUpdateUser={this.onUpdateUser}/>
              }
              <div id="DoctorContent">
                <NavMenu />
                <div id="Content">
                  <Header onShowMobileMenu={()=>this.setState({showMobileMenu: true})} onUpdateUser={this.onUpdateUser}/>
                  <Routes>
                    {  
                      PAGES.map((page, index)=>{
                        const CurComp = PAGES_COMPONENTS[page.Name];
                        return page.Paths.map((path, indexSub)=>{                  
                          if(page.Name === "Dashboard"){
                            return(
                              <Route 
                                exact={path} 
                                //strict 
                                key={page.Name+indexSub}
                                path={path} 
                                loader={({ params }) => {
                                  console.log('params', params);
                                  window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                                  this.curPage = page;                  
                                  return {...params}
                                }}
                                element={<CurComp {...this.props} onUpdateUser={this.onUpdateUser}/>} 
                              />
                            )
                          }
                          
                          return <Route 
                            key={page.Name+indexSub}
                            path={path} 
                            loader={({ params }) => {
                              console.log('params', params);
                              window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                              this.curPage = page;                  
                              return {...params}
                            }}
                            element={<CurComp {...this.props} onUpdateUser={this.onUpdateUser}/>}
                          />
                        })
                      })
                    }
                    <Route
                        path="*"
                        element={<Navigate to="/user" replace />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}


export default Index;