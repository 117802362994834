import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';


const QUERYGETID = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetNewID{
    getID
  }
`;


const QUERIES = {
	getID: QUERYGETID,
}


export default QUERIES;