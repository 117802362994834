import React from 'react';
import {NavLink} from 'react-router-dom';

const Component = (props)=>{
  var {onClickLink=()=>{}, MenuToShow, inline, CleanLinks=[]} = props;
  if(!MenuToShow) return null;    
        
  return(
    <div className={`menu ${inline ? "nav" : ""}`}>
      {
        CleanLinks.map((Link, index)=>{
          return(
            <div className="menu-item" key={index}>
              <NavLink 
                className={({ isActive, isPending }) =>{
                  var classString = "nav-link";
                  if(isPending) classString += " pending";
                  if(isActive) classString += " active";
                  if(!Link.Hash && (window.location.pathname === Link.Paths[0])) classString += " active"; //THIS MAY HELP MARK AS ACTIVE IF HASH IS CORRECT
                  return classString;
                }}
                onClick={async ()=>{
                  await onClickLink()
                  window.GlobalUtil.triggerEvent("PageChangedEvent", Link);
                }} to={Link.Paths[0]}>{Link.PageName}</NavLink>
            </div>
          )
        })
      }
    </div>
  )
}

export default Component;