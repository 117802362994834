//import React from 'react';
const PAGE = [
  {
    "content": `Cargando`
  },
  {
    "content": `Enviando`
  },
  {
    "type": "div",
    "content": `<div class="preHeader">Ayuda de contraseña</div>`
  },
  {
    "type": "div",
    "content": `<h1 class="sectionTitle">Restablecer tu contraseña</h1>`
  },
  {
    "type": "div",
    "content": `<div class="">Complete el siguiente formulario para restablecer tu contraseña. Recuerde, por razones de seguridad, tu contraseña debe tener al menos 8 dígitos.</div>`
  },
  {
    "content": `Este token ha caducado`
  },
  {
    "content": `Parece que el token de restablecimiento de contraseña ha caducado. Haga clic en el enlace de abajo para solicitar uno nuevo.`
  },
  {
    "content": `Este token se ha utilizado`
  },
  {
    "content": `Por motivos de seguridad, solo puede utilizar los tokens de reinicio una vez. Este token se ha utilizado. Haga clic en el enlace de abajo para enviar un nuevo enlace de token a tu dirección de correo electrónico.`
  },
  {
    "content": `Token no se encuentra`
  },
  {
    "content": `Para restablecer tu contraseña, necesitamos un token de restablecimiento especial. No se ha encontrado ningún token en la URL de esta página. Haga clic en el enlace de abajo para enviar un nuevo enlace de token a tu dirección de correo electrónico.`
  },
  {
    "content": `Se ha cambiado la contraseña`
  },
  {
    "content": `Tu contraseña ha sido cambiada exitosamente. Ahora puede iniciar sesión con tu nueva contraseña.`
  },
  {
    "content": `Tenemos dificultades técnicas`
  },
  {
    "content": `Disculpe las molestias, pero parece que nuestros servidores están teniendo problemas. Vuelve más tarde e inténtalo de nuevo.`
  },
  {
    "content": `Haga clic aqui`
  }
];




  
export default PAGE;











