import React, { useState, useRef } from 'react';
import "styles/scss/lab/components/newTreatmentPlanOffer.scss";
import API_CALLS from 'API_CALLS/index';
import Loading from 'global/components/Loading/index';
import FormBuilder from 'global/components/FormBuilder/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}








/*
FIRST CREATE NEW USER (PATIENT) (MUST HAVE DOCTOR ID)
THEN AUTO CREATE NEW TREATMENT PLAN FOR THAT USER

THEN UPLOAD ALL THINGS NEEDED FOR TREATMENT PLAN REQUEST
OBJECT TO CREATE NEW PATIENT
{
  DOCID
  firstName
  lastName
}

OBJECT TO CREATE TREATMENT PLAN REQUEST
{
  TPID
  offerRequest{
    chiefComplaint
    notes
    mouthPictures{
    }
    otherFiles{

    }
  }
}

*/



const Index = props => {
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  var OnClick = (e)=>{
    e.preventDefault();
    window.GlobalUtil.triggerEvent("SlideInRightEvent", {
      TITLE: CONTENT[1],
      closeButtonText: CONTENT[2],
      saveButtonText: CONTENT[3],
      customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog treatmentPlanOffer",
      width: "1200px",
      onClose: ()=>{
        if(props.reloadMainList) props.reloadMainList();
      },
      noFooter: true,
      children: (propsChildren)=>{                                      
        return(
          <div className="dialogCustomZone">
            <NewTreatmentPlan 
              {...{...props, ...propsChildren}}
              CONTENT={CONTENT}
            />
          </div>
        )
      }
    });
  }
  return(
    <React.Fragment>
      {
        props.customButtonText
        ? props.customButtonText(OnClick)
        : <div className="button button1 btn-color5 actionButton" onClick={OnClick} style={{"margin":"0 5px 20px","display":"flex","alignItems":"center","justifyContent":"center"}}>
            <span className="text">{CONTENT[0]}&nbsp;</span>
            <span className="buttonIcon">
              <i className="fas fa-undo" style={{"cursor": "pointer"}}></i>
            </span>
          </div>
      }
    </React.Fragment>
  )
}







class NewTreatmentPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curTreatmentPlan: this.getFromFullObj({...this.props.curTreatmentPlan}),
      curUser: {...this.props.curUser},
      rejectStepNotes: ''
    };    
    window.CurObj = this.getFromFullObj({...this.props.curTreatmentPlan}) 
  }

  componentDidMount(){
  }
  
  componentWillUnmount(){
    if(this.props.reloadMainList) this.props.reloadMainList();
  }


  onChange = (curObj)=>{
    this.setState({rejectStepNotes: curObj.rejectStepNotes})
  }
  
  onValidate = (isValid)=>{
    this.setState({isValid: isValid});
  }

  onDoneLoading = (callBack=()=>{})=>{
    this.setState({loading: false, saving: false, uploading: false}, callBack)
  }


  getFromFullObj = (fullObj)=>{
    return {
      id: fullObj._id,
      _id: fullObj._id,
      rejectStepNotes: fullObj.rejectStepNotes
    }
  }


  APISendTaskBack = (rejectStepNotes) =>{
    this.setState({loading: true})
    var inputObj = {
      mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
      variables: {
        id: this.state.curTreatmentPlan._id,
        rejectStepNotes: this.state.rejectStepNotes,//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
        changeStep:  -1//MOVE TO NEXT STEP WHEN DONE
      }
    }

    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      this.onDoneLoading(window.GlobalUtil.DialogStack.closeAll);
    })
    .catch((error)=>{
      console.log("Reject APISendTaskBack catch", error);
      this.onDoneLoading(window.GlobalUtil.DialogStack.closeAll);
    });
  }


  render(){
    var {CONTENT} = this.props;
    var {isValid, loading, curUser, curTreatmentPlan, saving, uploading} = this.state;                                  
    return (
      <div id="LabEditTreatmentPlan">
        <div className="body animated slideInRight">
          {
            loading
            ? <Loading 
                header={CONTENT[4]}
                body={CONTENT[5]}
                inlineStyles={{
                  "loadingIcon": {
                    "maxWidth": "100px",
                    "paddingBottom": "20px"
                  },
                  "loadingHeader": {
                    "marginBottom": "5px",
                    "fontSize": "40px"
                  },
                  "loadingBody": {
                    "marginBottom": "0px",
                    "fontSize": "20px"
                  }
                }}
                floating={true}
                overlay={true}
              />
            : <FormBuilder
                disableKeySubmit={true}
                initialValues={curTreatmentPlan}
                listOfFields={[
                  {
                    title: <div className="sectionDividerBasic"><span className="sectionDividerText">{CONTENT[6]}</span><span className="sectionDividerBorder"></span></div>,
                    type: "sectionDivider",
                    required: false,
                    defaultValue: "",
                    defaultValuePath: ""
                  },
                  {
                    title: <div className="formTitle"><span className="">{CONTENT[7]}</span></div>,
                    subTitle: <div className="formSubTitle"><span className="">{CONTENT[8]}</span></div>,
                    type: "textArea",
                    name: "rejectStepNotes",
                    rows: 6,
                    required: true,
                    defaultValue: "",
                    defaultValuePath: "rejectStepNotes",
                    errorMessage: "Rejection reason is required"
                  }
                ]}
                onSubmit={()=>{}}
                onChange={this.onChange}
                onValidate={this.onValidate}
              />
          }
        </div>
        <div id="Footer">
          <div className="buttonGroup split">
            <button className="button button2" onClick={window.GlobalUtil.DialogStack.closeTop}>{CONTENT[9]}</button>
            {
              saving
              ? <button className={`button button1 btn-color4`}>{CONTENT[10]} <i className="fas fa-spinner iconSpin iconWhite"></i></button>
              : <button className={`button button1 btn-color5 ${!isValid ? "disabled" : ''}`} onClick={this.APISendTaskBack} disabled={!isValid}>
                  {CONTENT[11]}&nbsp;
                  <span className="buttonIcon">
                    <i className="fas fa-undo" style={{"cursor": "pointer"}}></i>
                  </span>
                </button>
            }
          </div>
        </div>
      </div>
    );
  }
}






export default Index;
      
