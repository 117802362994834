import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID
  REF_IDS{
    ID_NAME
    ID
  }
  EMAIL
  ADDRESS
  CCADDRESS
  SUCCEEDED
  active
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;

const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;

const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $TPID: String,
  $OIDS: [String],
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  TPID: $TPID,
  OIDS: $OIDS,
`;


const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "VALUE",
    name: "ID",
    searchable: true
  },
  {
    path: "EMAIL",
    type: "VALUE",
    name: "EMAIL",
    searchable: true
  },
  {
    path: "ADDRESS",
    type: "VALUE",
    name: "ADDRESS",
    searchable: true
  },
  {
    path: "CCADDRESS",
    type: "VALUE",
    name: "CCADDRESS",
    searchable: true
  },
  {
    path: "SUCCEEDED",
    type: "BOOLEAN",
    name: "SUCCEEDED",
    searchable: true
  },
  {
    type: "CUSTOM",
    name: "EMAIL INPUT ID'S",
    path: "# of Items", //THIS IS NEEDED TO ALLOW TABLE SETTINGS TO SAVE IT
    CUSTOM: val=>{
      var mapAllObj = val.REF_IDS.map((object, index)=>{
        return `${object.ID_NAME}: ${object.ID}`
      })
      return mapAllObj.join(", ")
      //var sentEmail
      //console.log(`val`,"\n\n",val,"\n\n");
      // var itemsArray = val.items.map(item=>item.type)
      // if(!itemsArray) return(<div className="">NONE</div>)
      // return(<div className="text-center">{itemsArray.join(",")}</div>)
    },
    sortable: false
  },
  {
    path: "active",
    type: "BOOLEAN",
    name: "ACTIVE",
    searchable: true
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "NONE"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "NONE"
  }
];


const EMAILS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS
}


export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
};
export default EMAILS;