import React from 'react';

//BY DEFAULT IT WILL FIND ALL THOSE WITH "active" AS TRUE
const FULL_ROW = ({
    FIELDS=[], 
    TITLE, 
    SUB_TITLE, 
    className
  }) => {
  return (
    {
      "TYPE": "ROW",
      "FIELDS": [
        {
          "TYPE": "COLUMN",
          "TITLE": TITLE,
          "SUB_TITLE": SUB_TITLE,
          "className": className,
          "FIELDS": FIELDS
        }
      ]
    }
  )
}


const NOTES_LIST = (props={}) => {
  const {
    FIELDS=[], 
    TITLE="Notes List", 
    SUB_TITLE="History of Notes", 
    PATH= "notesList",
    className,
    CONDITION=()=>true
  } = props;
  return (
    {
      "CONDITION": CONDITION,
      "TYPE": "NOTES_LIST",
      "TITLE": TITLE,
      "SUB_TITLE": SUB_TITLE,
      "PATH": PATH
    }
  )
}


const NOTES = (props={}) => {
  const {
    FIELDS=[], 
    TITLE="Full Notes", 
    SUB_TITLE="Any notes you've left about this.", 
    className,
    CONDITION=()=>true
  } = props;
  return (
    {
      "TYPE": "ROW",
      "CONDITION": CONDITION,
      "FIELDS": [
        {
          "TYPE": "COLUMN",
          "TITLE": TITLE,
          "SUB_TITLE": SUB_TITLE,
          "className": "noPadding",
          "FIELDS": [
            {
              "PATH": "notes",
              "TYPE": "STRING",
              //"NAME": "Full Notes"
            }
          ]
        }
      ]
    }
  )
}

const HTML_FULL = (props={}) => {
  const {
    PATH,
    TITLE,
    SUB_TITLE,
    className,
    CONDITION=()=>true
  } = props;
  return (
    {
      "TYPE": "ROW",
      "CONDITION": CONDITION,
      "FIELDS": [
        {
          "TYPE": "COLUMN",
          "TITLE": TITLE,
          "SUB_TITLE": SUB_TITLE,
          "FIELDS": [
            {
              "PATH": PATH,
              "TYPE": "HTML",
              "NOBORDER": true,
              //"NAME": "Notes",
              "DEFAULT": ""
            }
          ]
        }
      ]
    }
  )
}



const MESSAGE = () => {
  return (
    {
      "TYPE": "ROW",
      "FIELDS": [
        {
          "TYPE": "COLUMN",
          "className": "noPadding",
          "FIELDS": [
            {
              "PATH": "message",
              "TYPE": "STRING",
              "NAME": "Message"
            }
          ]
        }
      ]
    }
  )
}

const ADDRESS = (props={}) => {
  const {
    FIELDS=[], 
    TITLE="Address",
    SUB_TITLE="Current Address",
    className,
    CONDITION=()=>true
  } = props;
  return (
    {
      "TYPE": "ROW",
      "CONDITION": CONDITION,
      "FIELDS": [
        {
          "TYPE": "COLUMN",
          "TITLE": TITLE,
          "SUB_TITLE": SUB_TITLE,
          "FIELDS": [
            {
              "PATH": "address,street",
              "TYPE": "STRING",
              "NAME": "Street",
              "DEFAULT": "MISSING"
            },
            {
              "PATH": "address,street2",
              "TYPE": "STRING",
              "NAME": "Street2",
              "DEFAULT": "MISSING"
            },
            {
              "PATH": "address,city",
              "TYPE": "STRING",
              "NAME": "City",
              "DEFAULT": "MISSING"
            },
            {
              "PATH": "address,state",
              "TYPE": "STRING",
              "NAME": "State",
              "DEFAULT": "MISSING"
            },
            {
              "PATH": "address,zipcode",
              "TYPE": "STRING",
              "NAME": "Zipcode",
              "DEFAULT": "MISSING"
            },
            {
              "PATH": "address,country",
              "TYPE": "STRING",
              "NAME": "Country",
              "DEFAULT": "MISSING"
            },
          ]
        }
      ]
    }
  )
}


const PICTURES = (props={}) => {
  const {
    FIELDS=[],
    PATH, 
    TITLE,
    SUB_TITLE,
    className,
    CONDITION=()=>true
  } = props;
  return (
    {
      "CONDITION": CONDITION,
      "TYPE": "PICTURES",
      "TITLE": TITLE,
      "PATH": PATH,
      "SUB_TITLE": SUB_TITLE
    }
  )
}

const META = () => {
  return (
    {
      "TYPE": "ROW",
      "FIELDS": [
        {
          "TYPE": "COLUMN",
          "className": "noPadding",
          "FIELDS": [
            {
              "PATH": "meta,createAt",
              "TYPE": "DATE",
              "FORMAT": "n/d/Y @ H:mA",
              "NAME": "Create At"
            }
          ]
        },
        {
          "TYPE": "COLUMN",
          "className": "noPadding",
          "FIELDS": [
            {
              "PATH": "meta,updateAt",
              "TYPE": "DATE",
              "FORMAT": "n/d/Y @ H:mA",
              "NAME": "Last Update"
            }
          ]
        }
      ]
    }
  )
}



const DEFAULT = {
  FULL_ROW,
  ADDRESS,
  PICTURES,
  NOTES_LIST,
  NOTES,
  HTML_FULL,
  MESSAGE,
  META
};

export default DEFAULT;