import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
// import QUERIES from "./QUERIES";
// import MUTATIONS from "./MUTATIONS";
// import ImageUtils from "global/utils/image-utils";
// const IMAGE_UTILS =  new ImageUtils();

const FORM_FIELDS = (obj) => {
  //const QUERY_GET_DISTINCT = QUERIES.distinct();
  //const MUTATION_EDIT = MUTATIONS.update();
  return(
    [ //defaultValuePath used to set default value form object
      
    ]
  )
};






export default FORM_FIELDS;