import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import SortableListGroup from "./SortableListGroup";
import API_CALLS from 'API_CALLS/index';
import ImageUtils from "global/utils/image-utils";
import TemplateSlideInRight from 'global/templates/templateSlideInRight/index';
import FormBuilder from 'global/components/FormBuilder/index';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import "styles/scss/admin/components/ScheduleAligners.scss"
import LayoutBuilder from 'LayoutBuilder';
const IMAGE_UTILS =  new ImageUtils();

const {Container, Row, Column} = LayoutBuilder;
/*

//GET ALL STL'S TO PLAN->alignerFilesTemp
//GROUP THEM
//SUB GROUP THEM
//SCHEDULE TIME FOR ALL
//
//WHEN SUBMIT
//CREATE ALL THE ALIGNERS WITH LINKS AND SCHEDULEED DATE
//REMOVE ALL IMAGE LINKS FROM alignerFilesTemp
//ALL EXTRA IMAGES GO TO PLAN->offerRequest->otherFiles
//DOUBLE CHECK THE totalNumOfAligners
//RUN API TO SCHEDULE ANY NEW OVERDUE ALINGERS TO BE MADE

*/


const MIN_TO_PULL = `
  _id
  UID
  OID
  alignerFilesTemp{
    createAt
    url
    name
    type
    id
    customName
  }
  alignersSchedule{
    numOfAlignersTop
    numOfAlignersBottom
    started
  }
  rejectStepNotes
`;



class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      groupList: []
    };       
  }


  /*
    COPY GROUP LIST
    FOR EACH OBJ IN COPY, UPDATE IT'S "ITEMS" TO ONLY THE URL
    STRIP OUT ANY EXTRA _TYPENAME
    CALL API ALIGNER CREATE SCHEDUALED GROUP SENDING THE NEW GROUP LIST AND "TPID"
      //THAT API CALL SHOULD TAKE CARE OF CREATING ALL THE NEW ALIGNERS, REMOVING THE SELECTED FILES FROM THIS PLAN AND UPDATING THIS PLAN AT THE SERVER LEVEL
    THEN CLOSE THIS TASK AND UPDATE THE CURRENT PLAN OBJ WITH THE NEW ONE 
  */
  validate = (dontFinish=false)=>{
    var {groupList} = this.state;
    var curObj = {...this.curObj};
    curObj = window.GlobalUtil.stripOutFromObj(curObj, "__typename");
    var groupListCopy = window.GlobalUtil.stripOutFromObj(groupList.slice(), "__typename");
    groupListCopy = groupListCopy.filter(obj=>obj.items.length > 0)
    var groupListClean = groupListCopy.map((object, index)=>{
      return {
        date: `${object.date}`,
        items: object.items.map((obj, index)=>{
          return obj.id
        })
      };
    })          
    this.APIADDANDSCHEDULE({
      TPID: curObj.id, 
      groupList: groupListClean
    });
  }




  APIGet = ()=>{       
    var inputObj = {
      query: API_CALLS.TREATMENT_PLANS.QUERIES.single(MIN_TO_PULL),
      variables: {
        id: this.props.curObj._id,
        include: [
          {
            name: "user"
          }
        ]
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;            
      var treatmentPlan = window.GlobalUtil.stripOutFromObj(data.treatmentPlan, "__typename");
      this.curObj = {
        ...this.curObj, 
        ...treatmentPlan,
        id: treatmentPlan._id
      };
      window.CurObj = {...this.curObj};
      //console.log("window.CurObj","\n\n",window.CurObj,"\n\n");
      if(!this) return;
      this.setState({
        Plan: treatmentPlan,
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false})
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }


  


  APIUpdate = (variables, dontFinish, callBack=()=>{})=>{      
    var inputObj = {
      mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
      variables: variables
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      callBack()
      if(!dontFinish) {
        window.CurRefetchList(true);
        this.onClose(this.CurRef)
      }
    })
    .catch((error)=>{
      console.log("CREATE_AND_SCHEDULE_ALIGNER_GROUPS catch", error);
      this.onClose(this.CurRef)
    });
  }





  APIADDANDSCHEDULE = (variables)=>{      
    console.log(`APIADDANDSCHEDULE variables`,"\n\n",variables,"\n\n");          
    var inputObj = {
      mutation: API_CALLS.ALIGNER_GROUPS.MUTATIONS.createAndScheduleAlignerGroups(),
      variables: variables
    }          
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      window.CurRefetchList(true);
      this.onClose(this.CurRef)
    })
    .catch((error)=>{
      console.log("CREATE_AND_SCHEDULE_ALIGNER_GROUPS catch","\n\n", error);
      this.onClose(this.CurRef);
    });
  }




  render(){
    
    var {showSlideIn, isValid, Plan={}, hideForm, groupList=[], showRejectOptions} = this.state;             
    var CurTaskObj = this.props.curObj;
    var alignerFilesTemp = window.GlobalUtil.deepGetFromString(Plan, "alignerFilesTemp", []);      
    //console.log("Plan","\n\n",Plan,"\n\n");
    //console.log("groupList","\n\n",groupList,"\n\n");
                            
    return (
      this.SlideInRight(
        "Schedule Aligners", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <div className="buttonGroup">
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
                <button className={`button btn-color5`} onClick={()=>this.setState({showRejectOptions: true})}>
                  Send Back <i className="fas fa-undo" />
                </button>
              </div>
              <div className="buttonGroup">
                <button className={`button button1 ${true ? "" : "disabled"}`} onClick={()=>{ 
                  this.validate();
                  //if(curRef && returned) curRef.onClose()
                }}>Schedule <i className="fas fa-calendar-alt"></i></button>
                <DialogProgressLogs
                    ID={this.props.curObj._id}
                    API_NAME={'TREATMENT_PLANS'}
                    FIELD={'treatmentPlan'}
                    asButton={true}
                    className={'button button1 btn-color4'}
                    style={{"width":"100px","minWidth":"0"}}
                    customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                  />
              </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div id="ScheduleAligners">
              <SlideOutRejectTask
                showRejectOptions={showRejectOptions}
                onCancel={()=>this.setState({showRejectOptions: false})}
                onReject={(rejectStepNotes, callback)=>this.APIUpdate({
                  id: this.curObj.id,
                  rejectStepNotes: rejectStepNotes,
                  changeStep: -1 //MOVE STEP BACK
                }, false, callback)}
              />
              {
                (alignerFilesTemp && (alignerFilesTemp.length > 0)) &&
                <SortableListGroup
                  optionList={alignerFilesTemp}
                  startingNumber={Plan.alignerFilesTemp.length}
                  onChangeGroupList={(groupList)=>{this.setState({groupList})}}
                />
              }
            </div>
          )
        }
      )
    );
  }
}











class SlideOutRejectTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }; 
  }

  render(){
    var {rejectStepNotes="", isValid} = this.state;
    return(
      <div>
        {
          this.props.showRejectOptions &&
          <TemplateSlideInRight
            ref={e=>this.slideInRightRef=e}
            title={"Reject Task"}
            onClose={this.props.onCancel}
            onSave={()=>{}}
            customButtons={(curRef)=>{
              return(
                <React.Fragment>
                  <button className="button button2" onClick={()=>{
                    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                  }}>Cancel</button>
                  <button className={`button btn-color5 ${isValid ? "" : "disabled"}`} onClick={()=>{ 
                    this.props.onReject(rejectStepNotes, ()=>{
                      if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                    });
                  }}>Send Back <i className="fas fa-undo"></i></button>
                </React.Fragment>
              )
            }}>
            <div>
              <Container>
                <Row>
                  <Column 
                    className={""}>
                      <div >
                        <Container className="" style={{"padding": "30px 10px"}}>
                          <FormBuilder
                            disableKeySubmit={true}
                            initialValues={{}}
                            listOfFields={[
                              {
                                title: <div className="formTitle"><span className="">Reason for Rejection</span></div>,
                                subTitle: <div className="formSubTitle"><span className="">Tell the uploader why are we rejecting this task.</span></div>,
                                type: "textArea",
                                name: "rejectStepNotes",
                                rows: 6,
                                required: true,
                                defaultValue: "",
                                defaultValuePath: "rejectStepNotes",
                                errorMessage: "Rejection reason is required"
                              }
                            ]}
                            onSubmit={()=>{
                            }}
                            onChange={newObj => {
                              this.setState({rejectStepNotes: newObj.rejectStepNotes}) 
                            }}
                            onValidate={(isValid)=>{
                              this.setState({isValid})
                            }}
                          />
                        </Container>
                      </div>
                  </Column>
                </Row>
              </Container>
            </div>
          </TemplateSlideInRight>
        }
      </div>
    )
  }
}

export default Index;