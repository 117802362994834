import React, { useRef } from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import FormBuilder from 'global/components/FormBuilder/index';




const FORM_FIELDS = (CONTENT, currentCoupon, onCheckCode, currentCode)=>{
  return(
    [ //defaultValuePath used to set default value form CONTENTect
      {
        title: <div className="formTitle"><span className="">{CONTENT.EditCodeFormFields[0]}</span></div>,
        subTitle: <div className="formSubTitle">
          <span className="">{CONTENT.EditCodeFormFields[1]}</span>
        </div>,
        type: "text",
        name: "CODE",
        formatInput: (inputValue)=>{ //MAKE ALL INPUT UPPERCASE
          if(!inputValue) return inputValue;
          inputValue = inputValue.replace(/\s+/g, ''); //REMOVE ANY SPACES
          return inputValue.toUpperCase();
        },
        onBlur: async ({newValue, currentObject, onChangeValid=()=>{}, newErrorFunction=()=>{}})=>{  
          if(newValue === currentCode) {
            onChangeValid("CODE", true);
            newErrorFunction("");
            return;
          }
          var codeFound = await onCheckCode(newValue);
          if(codeFound) {
            onChangeValid("CODE", false);
            newErrorFunction(CONTENT.EditCodeFormFields[2]);
          } else {
            onChangeValid("CODE", true);
            newErrorFunction("");
          }
        },
        required: true,
        defaultValue: "",
        defaultValuePath: "CODE"
      }
    ]
  )
};




const Index = props => {
  const formRef = useRef(null);  
  return(
    <div className="iconButtonWrapper" onClick={(e)=>{
      e.preventDefault();
      window.GlobalUtil.triggerEvent("SlideInRightEvent", {
        TITLE: props.CONTENT.EditPromoCode[0],
        ICON: <i className="fas fa-edit" />,
        CLOSEICON: true,
        closeButtonText: props.CONTENT.EditPromoCode[1],
        saveButtonText: props.CONTENT.EditPromoCode[2],
        customClass: "frontEndSlideInRight shortSlideInRight",
        width: "600px",
        onSave: async ()=>{
          if(formRef) return await formRef.current.onSubmit()
        },
        children: (thatprops)=>{                                      
          return(
            <FormFunction
              {...{...thatprops, ...props}} 
              ref={formRef}
            />
          )
        }
      });
    }}>
      <i className="fas fa-edit" />
    </div>
  )
}





class FormFunction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateIsValid: false,
      curObj: {...this.props.curObj}
    };    
    this.curObj = {...this.props.curObj};
  }

  componentWillUnmount(){
    clearTimeout(this.checkCode);
  }

  onCheckCode = async(newValue) => {
    //console.log('newValue', newValue);
    return await window.Client.mutate({
      mutation: API_CALLS.COUPONS.QUERIES.couponFinder(),
      variables: {
        code: newValue
      }
    })
    .then(result => {
      var coupon = result.data.couponFinder;
      //console.log('coupon', coupon);
      if(coupon){ //IF CODE EXIST                    
        if(coupon.ID) return true;
        else return false;
      } else {
        console.log("CODE COULD NOT BE FOUND catch");
        return false; //IF CODE NOT FOUND RETURN FALSE
      }
      //console.log(`result.data`,"\n\n",result.data,"\n\n");
    })
    .catch((error)=>{
      console.log("API_CALLS.REFERRALS.QUERIES.all FAIL catch", error);
      return false;
    })
  }


  APIUpdateCode = async (newObj) => {
    return await window.Client.mutate({ //API UPDATE
      mutation: API_CALLS.COUPONS.MUTATIONS.updateCouponUserCode(),
      variables: {
        id: newObj.CPID,
        CODE: newObj.CODE,
        UID: newObj.UID
      }
    })
    .then((obj)=>{      
      //RELOAD COUPONS
      return true;
    })
    .catch((error)=>{
      console.log("error updating APIUpdateCode Coupon");
      console.log(error,"\n\n");  
      return false;
    });
  }

  onSubmit = async () => {    
    var codeFound = await this.onCheckCode(this.curObj.CODE);
    if(codeFound) return; //IF CODE EXIST DON'T SUBMIT

    if(this.curObj && (this.curObj.CODE === this.props.curObj.CODE)) return; //NO SUBMIT IF THE CODE HAS NOT CHANGED
    await this.APIUpdateCode(this.curObj);
    await this.props.onUpdate(this.curObj);
    return true;
  }

  render(){
    var {updateIsValid} = this.state;
    var {CONTENT, currentCoupon} = this.props;    
    return(
      <div id="EditPromoCode">
        <div className="couponInfoTitle">{CONTENT.EditPromoCode[3]}</div>
        <div className="couponWrapper">
          <div className="couponIcon">
            <div className="icon"><i className="fas fa-tag"></i></div>
          </div>
          <div className="couponInfo">
            <div className="name couponInfoText">
              <div className="cont">{currentCoupon.name}</div>
            </div>
            <div className="desc couponInfoText">
              <div className="cont">{window.GlobalUtil.dangerSet(currentCoupon.description)}</div>
            </div>
            <div className="commissions couponInfoText">
              <div className="cate">{CONTENT.EditPromoCode[4]}</div>
              <div className="cont">{window.GlobalUtil.convertToMoney(currentCoupon.commission)}</div>
            </div>
          </div>
        </div>
        <hr className="spacerhr"/>
        <div id="FormWrapper">
          <FormBuilder
            disableKeySubmit={true}
            initialValues={this.curObj}
            listOfFields={FORM_FIELDS(CONTENT, currentCoupon, this.onCheckCode, this.props.curObj.CODE)}
            onSubmit={this.onSubmit}
            onChange={newObj => {                  
              //this.setState({curObj: newObj})
              this.curObj = newObj;
            }}
            onValidate={obj=>{                              
              if(this.curObj && (this.curObj.CODE === this.props.curObj.CODE)) this.props.onChangeValid(false)
              //else this.props.onChangeValid(obj)
              clearTimeout(this.checkCode);
              if(obj){
                this.checkCode = setTimeout(async () => {
                  var couponFound = await this.onCheckCode(this.curObj.CODE)
                  this.props.onChangeValid(!couponFound)
                }, 500);    
              }
            }}
          />
          {CONTENT.EditPromoCode[5]}
        </div>
      </div>
    )
  }
}


export default Index;
      
