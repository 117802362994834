import React, { useState, useRef } from 'react';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";


/*
APISelectBox EXAMPLE:
{
  title: <div className="formTitle"><span className="">UID</span></div>,
  subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every task</span></div>,
  type: "selectAPI",
  onMount: (callBack) => { //when mounts run this then add it to "options" value
    window.Client.query({
      query: QUERY_GET_USERS
    })
    .then(result => {
      var usersOptions = result.data.users.map((user, index)=>{
        return({
          "value": user._id,
          "title": user.email
        })
      });
      callBack("options", [{
        "value": "",
        "title": "Choose one",
        "disabled": true
      }, ...usersOptions])
      // console.log("usersOptions");
      // console.log(usersOptions,"\n\n");
            
    });
  },
  name: "UID",
  required: true,
  defaultValue: "",
  defaultValuePath: "UID",
  errorMessage: "User ID is required"
},
*/

class APISelectBox extends ExtenderClass { 
  constructor(props) {
    super(props);
    this.state={
      options: []
    };
  }

  render(){
    var {field={}, id, value='', onChange, onTouch, meta} = this.props;
    var {options=[]} = this.state;
    var {name="", required, title, add, formatInput, formatValue} = field;
    if(formatValue) value = formatValue(value); //THIS LET'S ME FURTHER FORMAT ANY VALUE LIKE GOING INTO AN ARRAY
    if(!options.filter(obj=>(!obj.disabled && obj.value === value))[0]) value = ""; //IF CURRENT VALUE IS NOT ONE OF THE OPTIONS THEN SET CURRENT VALUE TO NOTHING.          
          
    return(
      <div>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <select 
          type="select" 
          className={`form-control ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}
          value={value}
          name={name}
          onChange={e=>{
            var value = e.target.value;
            if(formatInput) value = formatInput(value)
            this.props.onChange(name, value);
            if(required) this.Validate(value);
          }}
          onBlur={()=>{
            this.props.onTouch(name);
            if(required) this.Validate(value);
          }}>
          {
            options.length > 0 && 
            options.map((object, index)=>{
              if(object.disabled) return(<option key={index} value={""} disabled>{object.title}</option>)
              return(
                <option key={index} value={object.value}>{object.title}</option>
              )
            })
          }
        </select>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


export default APISelectBox;