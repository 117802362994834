import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID 
  UID
  title
  url
  summary
  content
  mainImage{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  otherImages{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  category
  keywords
  language
  duration{
    startAt
    endAt      
  }
  SEO{
    TITLE
    DESCRIPTION
    BASEURL
    KEYWORDS
  }
  status
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;



const DEFAULT_MUTATION_INPUT_VAR = ` 
  $UID: String,
  $url: String,
  $title: String,
  $language: String,
  $content: String,
  $summary: String,
  $otherImages: [FileObjectInput],
  $mainImage: [FileObjectInput],
  $category: String,
  $keywords: [String],
  $duration: DurationInput,
  $status: String,
  $SEO: SEOInput
`;


const DEFAULT_MUTATION_INPUT = ` 
  UID: $UID,
  url: $url,
  title: $title,
  content: $content,
  summary: $summary,
  language: $language,
  mainImage: $mainImage,
  otherImages: $otherImages,
  category: $category,
  keywords: $keywords,
  duration: $duration,
  status: $status,
  SEO: $SEO
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "UID": "string",
  "url": "string",
  "title": "string",
  "language": "string",
  "content": "string",
  "summary": "string",
  "mainImage": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "otherImages": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "SEO": {
    "TITLE": "string",
    "DESCRIPTION": "string",
    "BASEURL": "string",
    "KEYWORDS": ["string"]
  },
  "category": "string",
  "keywords": ["string"],
  "duration,startAt":"string",
  "duration,endAt":"string",
  "status": "string",
  "meta": CLEAN_FIELDS_DEFAULT.META
}


const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  
  if(!curCleanObj.mainImage) delete curCleanObj.mainImage;
  if(!curCleanObj.otherImages) delete curCleanObj.otherImages;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  
  if(!curCleanObj.mainImage) delete curCleanObj.mainImage;
  if(!curCleanObj.otherImages) delete curCleanObj.otherImages;
  return curCleanObj;
}

const LIST_HEADER_FIELDS = [
  {
    path: "mainImage.0.url",
    type: "IMAGE",
    name: "Main Image",
    defaultValue: "NONE"
  },
  {
    path: "title",
    type: "VALUE",
    name: "Title",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "url",
    type: "VALUE",
    name: "URL",
    defaultValue: "NONE"
  },
  {
    path: "language",
    type: "VALUE",
    name: "Language",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "status",
    type: "VALUE",
    name: "Status",
    defaultValue: "NONE"
  },
  {
    path: "category",
    type: "STRINGARRAY",
    name: "Category",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "duration.startAt",
    type: "DATE",
    name: "Start Working",
    defaultValue: "NONE"
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  },
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "ID",
    name: "UID",
    defaultValue: "",
    path: "UID",
    searchable: true
  }
];


const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME": "ID"
          },
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "NAME": "UID"
          },
          {
            "PATH": "url",
            "TYPE": "STRING",
            "NAME": "URL"
          },
          {
            "PATH": "title",
            "TYPE": "STRING",
            "NAME": "Title"
          },
          {
            "PATH": "summary",
            "TYPE": "STRING",
            "NAME": "Summary"
          },
          {
            "PATH": "content",
            "TYPE": "STRING",
            "NAME": "Content"
          },
          {
            "PATH": "language",
            "TYPE": "STRING",
            "NAME": "Language"
          },
          {
            "PATH": "category",
            "TYPE": "STRING",
            "NAME": "Category"
          },
          {
            "PATH": "keywords",
            "TYPE": "STRING",
            "NAME": "Keywords"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME": "Status"
          },
          {
            "PATH": "duration,startAt",
            "TYPE": "DATE",
            "NAME": "Duration Start At"
          },
          {
            "PATH": "duration,endAt",
            "TYPE": "DATE",
            "NAME": "Duration End At"
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.PICTURES({
    "PATH": "mainImage",
    "TITLE": "Blog Pictures",
    "SUB_TITLE": "Main Blog Pictures",
  }),
  PREVIEW_FORM_TEMPLATES.PICTURES({
    "PATH": "otherImages",
    "TITLE": "Other Images",
    "SUB_TITLE": "Other Images Used",
  }),
  PREVIEW_FORM_TEMPLATES.META()
];


const BLOGS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default BLOGS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};