//import React from 'react';
const PAGE = [
	{
		"content": `Cart`
	},
  {
    "content": `Cancel`
  },
  {
    "content": `Summary`
  },
  {
    "content": `Coupon/Promo Code`
  },
  {
    "content": `Apply`
  },
  {
    "content": `Subtotal`
  },
  {
    "content": `Discount`
  },
  {
    "content": `Setup Fee`
  },
  {
    "content": `Tax`
  },
  {
    "content": `Shipping`
  },
  {
    "content": `Total`
  },
];




	
export default PAGE;