import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import FormBuilder from 'global/components/FormBuilder/index';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
 
/*
SEE admin/index


<TemplateDBNew
  ref={e=>this.TemplateDBNewRef=e}
  NAME={"name"}
  isValidChange={(newValue)=>{
  }}
/>
*/
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideForm: false,
      isValid: false
    };    
    this.curObj = {};
    this.formBuilderZone = this.formBuilderZone.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  componentDidMount(){}
  componentWillUnmount(){
    //clearTimeout(this.timeoutListener);
  }

  formBuilderZone(FORM_FIELDS){
    var {hideForm, isValid} = this.state;
    return (
      <Container>
        <Row>
          <Column 
            className={""}>
            {
              (!hideForm && this.curObj) && 
              <div >
                <Container className="">
                  <FormBuilder
                    disableKeySubmit={true}
                    initialValues={this.curObj}
                    listOfFields={FORM_FIELDS(this.curObj)}
                    onSubmit={()=>{
                    }}
                    onChange={newObj => {
                      this.curObj = {...this.curObj, ...newObj};
                      //TODO: ILL ADD A VALIDATION LATER
                      //console.log("this.curObj","\n\n",this.curObj,"\n\n");
                      // if(this.timeoutListener) clearTimeout(this.timeoutListener);
                      // this.timeoutListener = setTimeout(()=>{
                      //   this.validate(true)
                      //   clearTimeout(this.timeoutListener)
                      // }, 1000);   
                            
                    }}
                    onValidate={(isValid)=>{
                      this.setState({isValid});
                      if(this.props.isValidChange) this.props.isValidChange(isValid);
                    }}
                  />
                </Container>
              </div>
            }
          </Column>
        </Row>
      </Container>
    )
  }


  onSave(onSuccess=()=>{}, onFail=()=>{}, close=true){
    if(!this.state.isValid) return;
    var {NAME} = this.props;
    var curObjClean = window.GlobalUtil.APISubmitObjCleaner(API_CALLS[NAME].CLEAN_FIELDS, this.curObj);
    if(API_CALLS[NAME].FINAL_MOD_NEW) curObjClean = API_CALLS[NAME].FINAL_MOD_NEW(curObjClean);
    console.log("curObjClean","\n\n",curObjClean,"\n\n");          
    //newObj.completeTaskID = this.curObj._id; //IF THIS EXSIT THEN IT WILL AUTO COMPLETE THIS TASK WHEN DONE
          
    var inputObj = {
      mutation: API_CALLS[NAME].MUTATIONS.create(),
      variables: curObjClean
    }
    //console.log(`inputObj`,"\n\n",inputObj,"\n\n");
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      if(close) onSuccess(data);
    })
    .catch((error)=>{
      console.log("API onSave templateDBNew onSave failed catch", error);
      onFail();
    });
  }

  render(){
    var {NAME} = this.props;          
    if(!NAME) return null;
    return (
      <div className="">
        <br/>
        <br/>
        {this.formBuilderZone(API_CALLS[NAME].FORM_FIELDS_NEW)}
        <br/>
        <br/>
      </div>
    );
  }
}









export default Index;