import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import PREVIEW from "./PREVIEW";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  type
  name
  codes{
    CODE
    UID
  }
  PROMO{
    CODE
    UID
  }
  description
  summary
  category
  discount
  commission
  appliesOnlyTo
  limit
  limitNumber
  status
  duration{
    startAt
    endAt      
  }
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $type: String,
  $name: String,
  $codes: [CouponCodeInput],
  $description: String,
  $summary: String,
  $category: String,
  $discount: String,
  $appliesOnlyTo: String,
  $commission: String,
  $limit: Boolean,
  $limitNumber: Int,
  $status: String,
  $duration: DurationInput,
  
`;


const DEFAULT_MUTATION_INPUT = ` 
  type: $type,
  name: $name,
  codes: $codes,
  description: $description,
  summary: $summary,
  category: $category,
  discount: $discount,
  commission: $commission,
  appliesOnlyTo: $appliesOnlyTo,
  limit: $limit,
  limitNumber: $limitNumber,
  status: $status,
  duration: $duration,
  
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "type": "string",
  "name": "string",
  "description": "string",
  "summary": "string",
  "category": "string",
  "discount": "string",
  "appliesOnlyTo": "string",
  "commission": "string",
  "limit": "boolean",
  "limitNumber": "number",
  "status": "string",
  "duration,startAt":"string",
  "duration,endAt":"string",
  "meta": CLEAN_FIELDS_DEFAULT.META
}

const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    path: "type",
    type: "VALUE",
    name: "Type",
    defaultValue: "NONE"
  },
  {
    path: "name",
    type: "VALUE",
    name: "Name",
    defaultValue: "NONE",
    searchable: true
  },
  // {
  //   path: "codes",
  //   type: "VALUE",
  //   name: "Code",
  //   defaultValue: "NONE",
  //   searchable: true
  // },
  {
    path: "discount",
    type: "VALUE",
    name: "Discount",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "category",
    type: "VALUE",
    name: "Category",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "summary",
    type: "VALUE",
    name: "Summary",
    defaultValue: "NONE",
    searchable: true
  },
  // {
  //   path: "status",
  //   type: "VALUE",
  //   name: "Status",
  //   defaultValue: "NONE"
  // },
  {
    path: "commission",
    type: "VALUE",
    name: "Affiliates Commission",
    defaultValue: "0"
  },
  {
    path: "duration.startAt",
    type: "DATE",
    name: "Start Working",
    defaultValue: "NONE"
  },
  {
    path: "duration.endAt",
    type: "DATE",
    name: "Stop Working",
    defaultValue: "NONE"
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "NONE"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "NONE"
  }
];



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "NAME": "ID",
            "PATH": "_id",
            "TYPE": "STRING"
          },
          {
            "NAME": "Type",
            "PATH": "type",
            "TYPE": "STRING"
          },
          {
            "NAME": "Name",
            "PATH": "name",
            "TYPE": "STRING"
          },
          {
            "CONDITION": (obj)=>{ // IF TYPE IS FIXED_PRICE THEN SHOW THIS DISCOUNT
              return obj.type === "FIXED_PRICE";
            },
            "NAME": "Discount - New Price",
            "PATH": "discount",
            "TYPE": "MONEY"
          },
          {
            "CONDITION": (obj)=>{ // IF TYPE IS MONEY_OFF THEN SHOW THIS DISCOUNT
              return obj.type === "MONEY_OFF";
            },
            "NAME": "Discount - Money off Price",
            "PATH": "discount",
            "TYPE": "MONEY"
          },
          {
            "CONDITION": (obj)=>{ // IF TYPE IS PERCENT_OFF THEN SHOW THIS DISCOUNT
              return obj.type === "PERCENT_OFF";
            },
            "NAME": "Discount - Percentage Off",
            "PATH": "discount",
            "TYPE": "NUMBER"
          },
          {
            "NAME": "Affiliates Commission",
            "PATH": "commission",
            "TYPE": "MONEY"
          },
          {
            "NAME": "Category",
            "PATH": "category",
            "TYPE": "STRING"
          },
          {
            "NAME": "Summary",
            "PATH": "summary",
            "TYPE": "STRING"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "NAME": "Description",
            "PATH": "description",
            "TYPE": "STRING"
          },
          {
            "NAME": "Applies Only To",
            "PATH": "appliesOnlyTo",
            "TYPE": "STRING"
          },
          {
            "NAME": "Limited # of Coupons?",
            "PATH": "limit",
            "TYPE": "BOOLEAN",
          },
          {
            "CONDITION": (obj)=>{ //IF LIMIT IS TRUE THEN SHOW LIMITED NUMBER              
              return window.GlobalUtil.inputToBool(obj.limit);
            },
            "NAME": "Max Uses",
            "PATH": "limitNumber",
            "TYPE": "NUMBER"
          },
          {
            "NAME": "Status",
            "PATH": "status",
            "TYPE": "STRING"
          },
          {
            "NAME": "Duration Start At",
            "PATH": "duration,startAt",
            "TYPE": "DATE"
          },
          {
            "NAME": "Duration End At",
            "PATH": "duration,endAt",
            "TYPE": "DATE"
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE":"CODES AND USERS",
        //"SUB_TITLE":"Current user mouth ",
        "FIELDS": [
          {
            "PATH": "codes",
            "TYPE": "ARRAY_CUSTOM",
            "CUSTOM": (obj)=>{
              return(
                <div className="d-flex">
                  <div className="" style={{padding: "0 0 0 10px"}}>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>User: </div>
                        <div>
                          <a className="" onClick={()=>{
                            window.GlobalUtil.triggerEvent("TemplateDBEvent", {
                              "PATH": "UID",
                              "NAME":"USER ID",
                              "TITLE": "User", //TITLE OF SLIDE OUT
                              "APINAME": "USERS",
                              "ID_PATH": "UID",
                              "EDITABLE": true,
                              TYPE: "PREVIEW",
                              ID: obj.UID
                            });
                          }}>
                            {obj.UID}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Code: </div>
                        <div>{obj.CODE}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.META()
];

const FINAL_MOD_NEW = (curCleanObj)=>{
  //ONLY FOR NEW COUPONS 
  //MAKE SURE IT HAS A DEFAULT CODE, AND THE DEFAULT USER IS THE ADMIN
  curCleanObj.codes[0].UID = window.Session.user._id;
  delete curCleanObj.ID; //TO ALLOW CLONEING REMOVE ANY ID
  delete curCleanObj.id; //TO ALLOW CLONEING REMOVE ANY ID
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const COUPONS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default COUPONS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};