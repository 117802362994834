//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation Update(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateCommission(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation NewCommission(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createCommission(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteCommission(
    $id: String,
  ) {
    deleteCommission(id: $id)
  }
`;


const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneCommission(
    $id: String,
  ) {
    cloneCommission(id: $id){
      _id
    }
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteCommissionList(
    $ids: [String]
  ) {
    deleteCommissionList(ids: $ids)
  }
`;


const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneCommissionList(
    $ids: [String]
  ) {
    cloneCommissionList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateCommission(
    $id: String,
  ) {
    deactivateCommission(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateCommissionList(
    $ids: [String]
  ) {
    deactivateCommissionList(ids: $ids)
  }
`;

const MUTATIONS = {
  update: MUTATION_EDIT,
  create: MUTATION_ADD,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;