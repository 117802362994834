import React from 'react';


const Index = {
  title: (TemplateObj)=>{
    return "Preview "+TemplateObj.TITLE;
  },
  onClose: (that, TEMP_UID, TemplateObj)=>{ //THIS FUNCTION RUNS AFTER THE ONCLOSE FUNCTION INSIDE TemplateSlideInRight RUNS, AND IT'S ALL CLOSED.
    var TemplateDBObj = {...that.state.TemplateDBObj};
    delete TemplateDBObj.PREVIEW[TEMP_UID];
    that.setState({TemplateDBObj: TemplateDBObj}, ()=>{
      if(that.SHOULDRELOAD) window.GlobalUtil.triggerEvent("APIMutationCalled", {
        NAME: TemplateObj.NAME
      });
      that.SHOULDRELOAD = false;
    });
  },
  customButtons: (that, TEMP_UID, TemplateObj)=>{
    return null
  }
}
export default Index;
      
