//import React from 'react';
const PAGE = [
  {
    "content": `Nombre`
  },
  {
    "content": `Identificación del tratamiento`
  },
  {
    "content": `Solicitado`
  },
  {
    "content": `Última actualización`
  },
  {
    "content": "Ver"
  },
  {
    "content": "Acción"
  },
  {
    "content": `Crear`
  },
  {
    "content": "No se encontraron artículos"
  }
];




  
export default PAGE;