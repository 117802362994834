//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="sectionTitle">Email Unsubscribe</h1>`
	},
	{
		"content": "Updating"
	},
	{
		"content": "Updating your user settings"
	},
	{
		"type": "div",
		"content": `<h4 class="">
      FAILED: Could not unsubscribe email address. Something must be wrong with our servers. Please try again later.
    </h4>`
	},
	{
		"type": "div",
		"content": `<h4 class="">
      SUCCESS: Your Email has been UNSUBSCRIBED.
    </h4>`
	}
];




	
export default PAGE;