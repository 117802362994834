import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from 'global/templates/templatePage/index';

const QUERY = API_CALLS.PRODUCTS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.PRODUCTS.QUERIES.single();
const QUERY_COUNT = API_CALLS.PRODUCTS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.PRODUCTS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.PRODUCTS.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);
const FORM_FIELDS = API_CALLS.PRODUCTS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.PRODUCTS.LIST_HEADER_FIELDS;

const PREVIEW = API_CALLS.PRODUCTS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  console.log("newObject");
  console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};





const PAGES = () => [
  {
    "id": "PRODUCTS01",
    "Name":"Active",
    "PageName": "Active",
    "BasePage": "products",
    "Path": "upcoming",
    "TITLE": "Active Products",
    "DATA_FIELD": "products",
    "GlobalStateVar": ["productCountUpcomming"],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "PRODUCTS",
      TITLE: "Product",
      FIELD: "product"
    },
    "EDITBUTTON": {
      NAME: "PRODUCTS",
      TITLE: "Product",
      FIELD: "product"
    },
    "DELETEBUTTON": {
      NAME: "PRODUCTS",
      REMOVE: true
    },
    "CLONEBUTTON": {
      NAME: "PRODUCTS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("products/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Product",
            "TITLE": "Product", //TITLE OF SLIDE OUT
            "APINAME": "PRODUCTS",
            "NAME": "Product",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "PRODUCTS02",
    "Name":"Pending",
    "PageName": "Pending",
    "BasePage": "products",
    "Path": "pending",
    "TITLE": "Pending Products",
    "DATA_FIELD": "products",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "PENDING",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "PRODUCTS",
      TITLE: "Product",
      FIELD: "product"
    },
    "EDITBUTTON": {
      NAME: "PRODUCTS",
      TITLE: "Product",
      FIELD: "product"
    },
    "DELETEBUTTON": {
      NAME: "PRODUCTS"
    },
    "CLONEBUTTON": {
      NAME: "PRODUCTS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("products/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Product",
            "TITLE": "Product", //TITLE OF SLIDE OUT
            "APINAME": "PRODUCTS",
            "NAME": "Product",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "PRODUCTS03",
    "Name":"History",
    "PageName": "History",
    "BasePage": "products",
    "Path": "history",
    "TITLE": "Product History",
    "DATA_FIELD": "products",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "INACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "PRODUCTS",
      TITLE: "Product",
      FIELD: "product"
    },
    "EDITBUTTON": {
      NAME: "PRODUCTS",
      TITLE: "Product",
      FIELD: "product"
    },
    "DELETEBUTTON": {
      NAME: "PRODUCTS"
    },
    "CLONEBUTTON": {
      NAME: "PRODUCTS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("products/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Product",
            "TITLE": "Product", //TITLE OF SLIDE OUT
            "APINAME": "PRODUCTS",
            "NAME": "Product",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  }
];





const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminProducts"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "productCount",
            TITLE: "New Products Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "productCount",
            TITLE: "Products this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "productCount",
            TITLE: "Products this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "productCount",
            TITLE: "Products this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/",
        "to": "upcoming"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
