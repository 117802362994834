import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObj: {},
      //loading: true
    };    
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  render(){
    var {currentObj} = this.state;
    return (
      <div id="Emails" className="">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="" col="12" xs="12" sm="12" md="" lg="" xl="">
              <div id="TemplateHeader">
                <div className="textZone">
                  <div className="textArea">
                    <div className="title">
                      Site Emails
                    </div>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
          <Row className="">
            <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <div className="">
                <div className="emailList">
                  
                </div>
              </div>
            </Column>
          </Row>
        </Container>
        
      </div>
    );
  }
}



export default Index;
      
