//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="pageHeader"><i class="fas fa-file-contract"></i><span>Treatment Plan</span></h1>`
	},
  {
    "content": `Your Personal Plan`
  },
  {
    "content": `The following is information concerning your personal unique treatment plan. Once your plan has been made you will be able to see a PDF of it below.`
  },
  {
    "type": "div",
    "content": `<div class="section">Treatment Plan ID:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Create at Date:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Plan for:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Created by:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Approved by:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Est. Total Aligners:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Est. Duration:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Dr. Notes:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Plan Status:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Plan Initialized:</div>`
  },
  {
    "content": `Purchase Plan` //IF THEY HAVE NOT PURCHASED IT YET THEN THIS WILL BE THE TEXT FOR THEM TO START THAT
  },
  {
    "content": `Plan Files:`
  },
  {
    "content": `View` //VIEW BUTTON TEXT
  },
  {
    "content": `Plan PDF:`
  }
];




	
export default PAGE;