import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import USERS from "../USERS/index";
import ORDER_ITEMS from "../ORDER_ITEMS/index";
import TREATMENT_PLANS from "../TREATMENT_PLANS/index";
import QUERIES from "./QUERIES";
import MUTATIONS from "./MUTATIONS";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();

const FORM_FIELDS = (obj={}) => {
  const MUTATION_EDIT = MUTATIONS.update();
  const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  const QUERY_GET_TREATMENT_PLANS = TREATMENT_PLANS.QUERIES.all(` `);
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Aligner ID</span></div>,
        type: "text",
        name: "_id",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "_id"
      },
      {
        title: <div className="formTitle"><span className="">Treatment Plan ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The treatment plan that goes with this aligner group</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_TREATMENT_PLANS,
            variables: {
              status: "ACTIVE"
            }
          })
          .then(result => {
            var usersOptions = result.data.treatmentPlans.map((treatmentPlan, index)=>{
              return({
                "value": treatmentPlan._id,
                "title": treatmentPlan._id
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...usersOptions])
            // console.log("usersOptions");
            // console.log(usersOptions,"\n\n");
                  
          });
        },
        name: "TPID",
        required: false,
        defaultValue: "",
        defaultValuePath: "TPID",
        errorMessage: "Treatment Plan ID is required"
      },
      {
        title: <div className="formTitle"><span className="">Order Item ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The order item that goes with this aligner group</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: ORDER_ITEMS.QUERIES.all(` `),
            variables: {
              status: "ACTIVE"
            }
          })
          .then(result => {
            var usersOptions = result.data.orderItems.map((orderItem, index)=>{
              return({
                "value": orderItem._id,
                "title": orderItem._id
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...usersOptions])
            // console.log("usersOptions");
            // console.log(usersOptions,"\n\n");
                  
          });
        },
        name: "OIID",
        required: false,
        defaultValue: "",
        defaultValuePath: "OIID",
        errorMessage: "Treatment Plan ID is required"
      },
      {
        title: <div className="formTitle"><span className="">User</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The user that goes with this aligner group</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: USERS.QUERIES.all(`email`),
            variables: {
              status: "ACTIVE"
            }
          })
          .then(result => {
            var usersOptions = result.data.users.map((user, index)=>{
              return({
                "value": user._id,
                "title": user.email
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...usersOptions])
            // console.log("usersOptions");
            // console.log(usersOptions,"\n\n");
                  
          });
        },
        name: "UID",
        required: false,
        defaultValue: "",
        defaultValuePath: "UID",
        errorMessage: "Treatment Plan ID is required"
      },
      {
        title: <div className="formTitle"><span className="">Created By</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What user made this aligner</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_USERS,
            variables: {
              status: "ACTIVE",
              query:"ADMIN",
              argument:"role"
            }
          })
          .then(result => {
            var usersOptions = result.data.users.map((user, index)=>{
              return({
                "value": user._id,
                "title": user.email
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...usersOptions])
            // console.log("usersOptions");
            // console.log(usersOptions,"\n\n");
                  
          });
        },
        name: "createdBy",
        required: false,
        defaultValue: "",
        defaultValuePath: "createdBy",
        errorMessage: "User ID is required"
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: false,
        defaultValue: "PENDING",
        defaultValuePath: "status"
      },
      {
        title: <div className="formTitle"><span className="">Schedualed Date</span></div>,
        subTitle: <div className="formSubTitle"><span className="">When it WILL be charged</span></div>,
        type: "datePicker",
        name: "scheduledAt",
        required: false,
        defaultValue: "",
        defaultValuePath: "scheduledAt",
        errorMessage: "The Task Name is required"
      },
      {
        title: <div className="formTitle"><span className="">Created Date</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Day this was created</span></div>,
        type: "datePicker",
        name: "createdAt",
        required: false,
        defaultValue: "",
        defaultValuePath: "createdAt"
      },
      {
        title: <div className="formTitle"><span className="">Shipped Date</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Day this was sent</span></div>,
        type: "datePicker",
        name: "shippedAt",
        required: false,
        defaultValue: "",
        defaultValuePath: "shippedAt"
      }
    ]
  )
};






export default FORM_FIELDS;