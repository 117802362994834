//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | How to take a perfect Impression for your Clear aligners",
			"DESCRIPTION": "Smiles Club's Clear Aligners Impression Kit Instructions and video. Learn how to take a good oral impression, and what to do after that.",
			"KEYWORDS": "Teeth straightening, Smiles Club, impression kit, clear braces instructions, impression instructions braces, impression kit video best aligners, safest aligners, number one clear aligners, step by step, Braces alternative"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `<div class="preHeader">Lets Get Started</div>
							  <h1 class="sectionTitle">How to use your Impression Kit</h1>
							  <div class="">Here, we'll go over how to use your impression kit and more. Before you take your oral impression please watch the full youtube video and read the full instructions below. This will help you take the best possible impression of your teeth.</div>
							  <div class="">If you don't have an impression kit yet, purchase it just below this by clicking the buy now button.</div>`
				}
			],
			"Section2": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">IMPRESSION KIT INSTRUCTIONS</div>
						<h2 class="sectionTitle2">Read completely before taking your impressions</h2>
						<div class="subHeader">For us to create the best most accurate treatment plan offer, we'll need good impressions of your teeth. So please be sure to follow the instructions to the best of your ability.</div>
						<br/>  
						<br/>
					`
				}
			],
			"Step1Images": [
				{
					"alt": "Smiles Club Clear Aligner Oral tray showing the difference between the upper and the lower."
				}
			],
			"Step1": [
				{
					"type": "div",
					//"className": "step",
					"content": `
						<div class="stepHeader">
						<div class="stepHeaderStep">Step 1.</div>
						<div class="stepHeaderTitle">Checking tray fit</div>
						</div>
						<div class="stepPostHeader">The top tray does have a curve and the bottom tray does not. If you get 4 bottom trays that's not a problem and will work fine.</div>
						<div class="stepContent">Please take 1 top and 1 bottom and try them in your mouth. If they DO NOT fit in your mouth, then contact us to get bigger trays.</div>
					`
				},
				{
					"content": `Contact Us`
				}
			],
			"Step2Images": [
				{
					"alt": "A woman's hands wearing gloves holding Smiles Club oral impression putty in her hands."
				},
				{
					"alt": "A woman's hands wearing gloves mixing Smiles Club oral impression putty in her hands."
				},
				{
					"alt": "A stopwatch indicating 60 seconds for this mixing step."
				}
			],
			"Step2": [
				{
					"type": "div",
				//"className": "step",
					"content": `
				  <div class="stepHeader">
					<div class="stepHeaderStep">Step 2.</div>
					<div class="stepHeaderTitle">Mixing the putty</div>
				  </div>
				  <div class="stepPostHeader">If the putty is warm it may set faster than expected. Therefore, if your putty is warm place it in the fridge for 20 min before starting.</div>
				  <div class="stepContent">Before starting leave putty compounds in the fridge for 15 min to cool down and slow down the hardening process. Once ready, put on gloves and mix one set of the two putties for 20 seconds, into one solid color.</div>
				`
				}
			],
			"Step3Images": [
				{
					"alt": "A woman's hand showing the Smiles.CLub's impression putty rolled into a hotdog shaped."
				},
				{
					"alt": "A woman's hand showing the Smiles.CLub's impression putty pushed into the first impression tray."
				}
			],
			"Step3": [
				{
					"type": "div",
				//"className": "step",
					"content": `
						<div class="stepHeader">
						<div class="stepHeaderStep">Step 3.</div>
						<div class="stepHeaderTitle">Fill out the mouth tray</div>
						</div>
						<div class="stepPostHeader">You can start with either top or bottom it does not matter. Just grab the correct tray and ready your timer.</div>
						<div class="stepContent">Roll out your impression putty into a hotdog shape. Then fill out your impression tray and even it out as much as possible.</div>
					`
				}
			],
			"Step4Images": [
				{
					"alt": "A women inserting the upper impression tray into her mouth."
				},
				{
					"alt": "A women pushing the upper impression tray up into her mouth with her thumbs."
				},
				{
					"alt": "A stopwatch indicating that the step should take 30 seconds."
				}
			],
			"Step4": [
				{
					"type": "div",
				//"className": "step",
					"content": `<div class="stepHeader">
								<div class="stepHeaderStep">Step 4.</div>
								<div class="stepHeaderTitle">Taking your impression</div>
							  </div>
							  <div class="stepPostHeader">Good impression are taken in the middle and the putty should cover your <strong>TEETH AND YOUR GUMS.</strong> So push all the way.</div>
							  <div class="stepContent">Place the tray in your mouth and then push UP towards teeth in <strong>ONE SMOOTH MOTION.</strong> Make sure to push <strong>ALL THE WAY</strong> so your front <strong>AND BACK</strong> teeth are touching the trays and the putty covers a good portion of your gums.</div>
							  <div class="stepContent">You'll have to put pressure on the back teeth to make sure the putty covers them too. Now stay still until the time is up.</div>`
				}
			],
			"Step5Images": [
				{
					"alt": "A woman wearing gloves removing an upper Smiles.Club impression kit tray."
				},
				{
					"alt": "A woman wearing gloves showing a finished upper Smiles.Club impression kit tray."
				}
			],
			"Step5": [
				{
					"type": "div",
				//"className": "step",
					"content": `<div class="stepHeader">
								<div class="stepHeaderStep">Step 5.</div>
								<div class="stepHeaderTitle">Removing your impression</div>
							  </div>
							  <div class="stepPostHeader">For the best impressions wait for the full 3:30 min for each impression.</div>
							  <div class="stepContent">After the time has passed put your fingers on the top edge of the impression tray, and use it to pull the impression off.</div>`
				}
			],
			"Step6Images": [
				{
					"alt": "A group of used and finished impression trays, showing to upper and two lower trays."
				}
			],
			"Step6": [
				{
					"type": "div",
				//"className": "step",
					"content": `<div class="stepHeader">
								<div class="stepHeaderStep">Step 6.</div>
								<div class="stepHeaderTitle">Repeat for remaining 3 trays</div>
							  </div>
							  <div class="stepContent">Repeat the impression process on the bottom and then again the top and bottom. You should have a total of 4 impressions when you're done. 2 uppers and 2 lowers.</div>`
				},
			],
			"Section3": [
				{
					"type": "div",
					"content": `<div class="preHeader">DON'T STOP NOW, WE'RE ALMOST DONE!</div>
							  <h2 class="sectionTitle2">Important Final Steps!</h2>`
				}
			],
			"Section4Images": [
				{
					"alt": "An icon of a moving van indicating the package must be returned."
				}
			],
			"Section4": [
				{
					"type": "div",
					"content": `<div class="stepHeader">
									<div class="stepHeaderStep">Return impressions</div>
								</div>
								<div class="stepContent">Inside your impression kit you should've found a prepaid return envelope. Rinse off each of your impressions under cold water, place them in the return envelope and send them back.</div>
								<div class="stepContent">Once we received your impressions we will begin creating your treatment plan offer.</div>`
				}
			],
			"Section5Images": [
				{
					"alt": "An icon with a camera and film indicating the user must take an upload the required selfies."
				}
			],
			"Section5": [
				{
					"type": "div",
					"content": `<div class="stepHeader">
								<div class="stepHeaderStep">Take Selfies</div>
							  </div>
							  <div class="stepContent">Login to your account online and take all the requested selfies. You will be asked to take a selfie that matches the shown images. Once taken, you should click on the image, on the user side, and upload the correct selfie. These will further help our doctors know how to treat your case.</div>`
				},
				{
					"content": `Login Here`
				},
				{
					"content": `/user`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Cómo tomar una impresión perfecta para sus alineadores transparentes",
			"DESCRIPTION": "Instrucciones de Sonrisas Club y un video del kit de impresión de alineadores. Aprenda cómo tomar una buena impresión oral y qué hacer después de eso",
			"KEYWORDS": "Sonrisas Club, Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">EMPECEMOS</div>
						<h1 class="sectionTitle">Cómo utilizar su kit de impresión</h1>
						<div class="">Aquí, repasaremos cómo usar su kit de impresión y más. Antes de tomar su impresión oral, mire el video completo de YouTube y lea las instrucciones completas a continuación. Esto le ayudará a tomar la mejor impresión posible de sus dientes.</div>
						<div class="">Si aún no tiene un kit de impresión, cómprelo debajo de este haciendo clic en el botón Comprar ahora.</div>
					`
				}
			],
			"Section2": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">INSTRUCCIONES BÁSICAS DEL KIT DE IMPRESIÓN</div>
						<h2 class="sectionTitle2">Lea completamente antes de tomar sus impresiones</h2>
						<div class="subHeader">Para que podamos crear la mejor oferta de plan de tratamiento más precisa, necesitaremos buenas impresiones de sus dientes. Por lo tanto, asegúrese de seguir las instrucciones lo mejor que pueda.</div>
						<br/>  
						<br/>
					`
				}
			],
			"Step1Images": [
				{
					"alt": "La bandeja oral de Smiles Club Clear Aligner muestra la diferencia entre la parte superior y la inferior."
				}
			],
			"Step1": [
				{
				"type": "div",
				//"className": "step",
				"content": `
					<div class="stepHeader">
					<div class="stepHeaderStep">Paso 1.</div>
					<div class="stepHeaderTitle">Verificación del tamaño de la bandeja</div>
					</div>
					<div class="stepPostHeader">La bandeja superior tiene una curva y la bandeja inferior no. Si obtiene 4 bandejas inferiores, no hay problema y funcionará bien.</div>
					<div class="stepContent">Por favor, tome 1 bandeja superior y 1 inferior y pruébelas en su boca. Si NO le caben en la boca, consúltenos para conseguir bandejas más grandes.</div>
				`
				},
				{
				"content": `Contáctanos`
				}
			],
			"Step2Images": [
				{
					"alt": "Las manos de una mujer con guantes y masilla de impresión oral Smiles Club en sus manos."
				},
				{
					"alt": "Las manos de una mujer con guantes mezclando la masilla de impresión oral Smiles Club en sus manos."
				},
				{
					"alt": "Un cronómetro que indica 60 segundos para este paso de mezcla."
				}
			],
			"Step2": [
				{
					"type": "div",
					//"className": "step",
					"content": `
					  <div class="stepHeader">
						<div class="stepHeaderStep">Paso 2.</div>
						<div class="stepHeaderTitle">Mezclando la masilla</div>
					  </div>
					  <div class="stepPostHeader">Si la masilla está tibia, es posible que fragüe más rápido de lo esperado. Por lo tanto, si su masilla está tibia, colóquela en el refrigerador durante 15 minutos antes de comenzar.</div>
					  <div class="stepContent">Antes de comenzar, deje los compuestos de masilla en el refrigerador durante 15 minutos para que se enfríen y ralenticen el proceso de endurecimiento. Una vez listo, póngase los guantes y mezcle un juego de las dos masillas durante 20 segundos, en un color sólido.</div>
					`
				  }
			],
			"Step3Images": [
				{
					"alt": "La mano de una mujer que muestra la masilla de impresión de Smiles.CLub enrollada en forma de perrito caliente."
				},
				{
					"alt": "La mano de una mujer que muestra la masilla de impresión de Smiles.CLub empujada hacia la cubeta de primera impresión."
				}
			],
			"Step3": [
				{
					"type": "div",
					//"className": "step",
					"content": `<div class="stepHeader">
									<div class="stepHeaderStep">Paso 3.</div>
									<div class="stepHeaderTitle">Rellenar la bandeja de la boca</div>
								  </div>
								  <div class="stepPostHeader">Comenzaremos con una bandeja SUPERIOR, así que tome la que tiene el bulto y prepare su cronómetro.</div>
								  <div class="stepContent">Estire la masilla de impresión en forma de salchicha. Luego llene la bandeja de impresión y nivélela tanto como sea posible.</div>`
				  }
			],
			"Step4Images": [
				{
					"alt": "Una mujer insertándose la cubeta de impresión superior en su boca."
				},
				{
					"alt": "Una mujer empujando la cubeta de impresión superior hacia su boca con los pulgares."
				},
				{
					"alt": "Un cronómetro que indica que el paso debe tomar 30 segundos."
				}
			],
			"Step4": [
				{
					"type": "div",
					//"className": "step",
					"content": `<div class="stepHeader">
									<div class="stepHeaderStep">Paso 4.</div>
									<div class="stepHeaderTitle">Tomando su impresión</div>
								  </div>
								  <div class="stepPostHeader">Se toman buenas impresiones en el medio y la masilla debe cubrir los DIENTES Y LAS ENCÍAS. Así que empuje todo lo que pueda.</div>
								  <div class="stepContent">Coloque la bandeja en su boca y luego empújela hacia ARRIBA hacia sus dientes con UN MOVIMIENTO SUAVE. Asegúrese de empujar TOTALMENTE para que sus dientes delanteros Y POSTERIORES toquen la bandeja y la masilla cubra una buena parte de sus encías.</div>
								  <div class="stepContent">Tendrá que presionar los dientes posteriores para asegurarte de que la masilla también los cubra. Ahora quédese quieto hasta que se acabe el tiempo.</div>`
				  }
			],
			"Step5Images": [
				{
					"alt": "Una mujer con guantes quitando una bandeja superior del kit de impresión Smiles.Club."
				},
				{
					"alt": "Una mujer con guantes que muestra una bandeja del kit de impresión Smiles.Club superior terminada."
				}
			],
			"Step5": [
				{
					"type": "div",
					//"className": "step",
					"content": `<div class="stepHeader">
									<div class="stepHeaderStep">Paso 5.</div>
									<div class="stepHeaderTitle">Removiendo tu impresión</div>
								  </div>
								  <div class="stepPostHeader">Para obtener una buena impresión, espere los 3:30 minutos completos para cada impresión.</div>
								  <div class="stepContent">Después de que haya pasado el tiempo, coloque los dedos en el borde superior de la bandeja de impresión y utilícelos para remover la impresión.</div>`
				  }
			],
			"Step6Images": [
				{
					"alt": "Un grupo de cubetas de impresión usadas y terminadas, mostrando una cubeta superior y dos cubetas inferiores."
				}
			],
			"Step6": [
				{
					"type": "div",
					//"className": "step",
					"content": `<div class="stepHeader">
									<div class="stepHeaderStep">Paso 6.</div>
									<div class="stepHeaderTitle">Repita lo mismo para las 3 bandejas restantes</div>
									</div>
									<div class="stepContent">Repita el proceso de impresión en la parte inferior y luego nuevamente en la parte superior e inferior. Debería tener un total de 4 impresiones cuando haya terminado. 2 superiores y 2 inferiores./div>`
					}
			],
			"Section3": [
				{
					"type": "div",
					"content": `<div class="preHeader">DON'T STOP NOW, WE'RE ALMOST DONE!</div>
								  <h2 class="sectionTitle2">¡Pasos finales importantes!</h2>`
				  }
			],
			"Section4Images": [
				{
					"alt": "Un icono de un camión de mudanzas que indica que el paquete debe devolverse."
				}
			],
			"Section4": [
				{
					"type": "div",
					"content": `<div class="stepHeader">
									<div class="stepHeaderStep">Retornar las impresiones</div>
								  </div>
								  <div class="stepContent">Dentro de su kit de impresión, debería haber encontrado un sobre de devolución prepago. Enjuague cada una de sus impresiones con agua fría, colóquelas en el sobre de devolución y envíenoslas de vuelta.</div>
								  <div class="stepContent">Una vez que recibamos sus impresiones, comenzaremos a crear su plan de tratamiento.</div>`
				  }
			],
			"Section5Images": [
				{
					"alt": "Un ícono con una cámara y una película que indica que el usuario debe tomar y cargar las selfies requeridas."
				}
			],
			"Section5": [
				{
					"type": "div",
					"content": `<div class="stepHeader">
									<div class="stepHeaderStep">Toma Fotos</div>
								  </div>
								  <div class="stepContent">Inicie sesión en su cuenta en línea y tome todas las fotos solicitadas. Estas ayudarán aún más a nuestros médicos a saber cómo tratar su caso.</div>`
				  },
				  {
					"content": `Entre aquí`
				  },
				{
					"content": `/user/inicio`
				}
			]
		}
	}
}


	
export default PAGE;
