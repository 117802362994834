import React from 'react';
import "styles/scss/lab/components/mobileMenu.scss";
import Nav from 'user/lab/components/Nav/index';
class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animatedClass: ['fadeIn','slideInRight']
    };    
    this.onClose = this.onClose.bind(this);
  }
  
  componentWillUnmount(){
    clearTimeout(this.TimeoutPointer);
  }

  onClose(){
    this.setState({animatedClass: ['fadeOut','slideOutRight']},
      ()=>{
        clearTimeout(this.TimeoutPointer);
        this.TimeoutPointer = setTimeout(this.props.onHideSideMenu, 500);
      });
  }

  render(){
    var {animatedClass} = this.state;
    return (
      <div id="MobileMenu">
        <div className={`overlay animated ${animatedClass[0]}`} onClick={this.onClose}></div>
        <div className={`sideMenu animated ${animatedClass[1]}`}>
          <div className="menuTitle">
            <div className="title">Menu</div>
            <div className="exist" onClick={this.onClose}><i className="fas fa-times"></i></div>
          </div>
          <div className="navigationButtons">
            <Nav onClickLink={this.onClose} MenuToShow={(window.Session.Language === "ENGLISH") ? "Mobile" : "MobileESP"}/>
            <div className="menu otherButtons">
              <div className="menu-item">
                <a className={"nav-link"}  onClick={()=>window.GlobalUtil.logout(true)}>
                  <div className="inline-nav-link">
                    <div className="icon"><i className="fas fa-sign-out-alt"></i></div>
                    <div className="text">Logout</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Component;