import React, { useState, useRef } from 'react';
import TemplateSlideInRight from "../templateSlideInRight/index";
import { gql, useQuery, useMutation } from '@apollo/client';

import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true
    };    
    this.onClose = this.onClose.bind(this);

  }
  componentDidMount(){  
  }

  componentWillUnmount(){
  }

  onClose(){    
    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose(); //ANIMATION SLIDE OUT
    if(this.props.onClose) this.props.onClose(); //CLOSE THIS IN PARENT
  }



  render(){
    var {isValid} = this.state;
    var {obj={}, title, customButtons} = this.props;          
                                        
    return (
      <TemplateSlideInRight
        ref={e=>this.slideInRightRef=e}
        title={title}
        isValid={isValid}
        saveButtonText={"Done"}
        onClose={this.onClose}
        onSave={this.onClose}
        customButtons={customButtons}>
        {this.props.children}
      </TemplateSlideInRight>
    );
  }
}





export default Index;