import React, { useState, useRef } from 'react';
import {NavLink, Link} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from 'global/components/FormBuilder/index';
import API_CALLS from 'API_CALLS/index';

function NewUserForm (props) {
  const formRef = useRef(null);
  var [currentObject, setCurObj] = useState({});
  var [curStatus, setCurStatus] = useState(false);
  var [showCustomError, setCustomError] = useState(false);
  const [savePassword, { data }] = useMutation(API_CALLS.USERS.MUTATIONS.resetForgottenPassword(), {
    onError: (error)=>{
        let errorMessage = error.message;
        console.log("errorMessage");
        console.dir(errorMessage,"\n\n");
        formRef.current.overrideCurrentObject({}); //THIS OVERRIDES THE FROM'S INTERNAL OBJECT
        if(props.onFail) props.onFail(errorMessage); //UPDATE THE DATABASE PAGE AREA    
      },
      onCompleted: ({signup})=>{
        console.log("Signup onCompleted data");
        //console.dir(signup,"\n\n");
        setCurObj({});
        if(props.onSuccess) props.onSuccess(signup); //UPDATE THE DATABASE PAGE AREA      
      }
  });

  const onSubmitContact = () => {
    if(props.onSubmit) props.onSubmit();
    const newVar = JSON.parse(JSON.stringify(currentObject));
    if(!curStatus) return;      
    savePassword({ 
      variables: {
        token: props.token,
        newPassword: newVar.newPassword
      }, 
      //refetchQueries: [{query: UserQueries}], //RELOAD THE QUERIES FOR THIS PAGE
    });
  }

  return (
    <div id={props.id} className={props.className}>
      <FormBuilder
        ref={formRef}
        initialValues={currentObject}
        listOfFields={[
          {
            title: <div className="formTitle"><span className="">{(window.Session.Language === "ESPAÑOL") ? "Nueva Contraseña" : "New Password"}</span></div>,
            type: "password",
            name: "newPassword",
            minChar: 8,
            //placeholder: "",
            required: true
          },
          {
            title: <div className="formTitle"><span className="">{(window.Session.Language === "ESPAÑOL") ? "Repite la Contraseña" : "Repeat Password"}</span></div>,
            type: "password",
            name: "newPasswordRepeat",
            minChar: 8,
            //placeholder: "",
            customError: (showCustomError ? ((window.Session.Language === "ESPAÑOL") ? "Las contraseñas no coinciden" : "Passwords don't match") : ''),
            required: true
          }
        ]}
        onSubmit={onSubmitContact}
        onChange={newObj => setCurObj(newObj)}
        onValidate={(isValid)=>{
          if(!currentObject || !currentObject.newPassword || !currentObject.newPasswordRepeat || (currentObject.newPassword !== currentObject.newPasswordRepeat)){
            setCurStatus(false);
            setCustomError(true);
          }
          else{
            setCurStatus(true)
            setCustomError(false);
          }
        }}
      />
      <button className={`button button1 ${curStatus ? '' : 'disabled'}`} onClick={onSubmitContact} disabled={!curStatus}>{props.buttonText ? props.buttonText : ((window.Session.Language === "ESPAÑOL") ? "Enviar" : "Submit")}</button>
    </div>
  );
}


export default NewUserForm;