//import React from 'react';
const PAGE = {
  "ENGLISH": {
    "HEADER": {
      "BASEURL": "https://smiles.club",
      "TITLE": "Smiles Club | Clear aligners made of premium materials without harmful toxins",
      "DESCRIPTION": "Which clear aligners are the best? Smiles Club's! Safest USA made clear aligners with no hidden fees. Risk free orthodontics.",
      "KEYWORDS": "Best in Clear Aligners, Teeth Straightening Treatments, Smiles.Club, Smiles Club, clear treatment, state-of-the-art Clear Braces, risk free experience, flexible payment options, compare aligners, price compare"
    },
    "CONTENT": {
      "UserHome": [
        {
          "content": `Dashboard`
        }
      ],
      "AccountSummary": [
        {
          "content": `Account Summary`
        },
        {
          "content": `Name:`
        },
        {
          "content": `Phone`
        },
        {
          //"type": "div",
          "content": `Email:`
        },
        {
          "content": `Treatment Plan:`
        },
        {
          "content": `View Treatment Plan` //"Preview Treatment Plan:" responce 
        },
        {
          "content": `Purchased Plan:` //"Preview Treatment Plan:" responce 
        }
      ],
      "CreatingTreatmentPlan": [
        {
          "content": `Creating Treatment Plan`
        },
        { 
          "content": `We are now creating your custom treatment plan offer.`
        }
      ]
    }
  },
  "ESPAÑOL": {
    "HEADER": {
      "BASEURL": "https://sonrisas.club",
      "TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
      "DESCRIPTION": "¿Qué alineadores transparentes son los mejores? ¡Club Sonrisas! Safest USA fabricó alineadores transparentes sin tarifas ocultas. Ortodoncia sin riesgos.",
      "KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
    },
    "CONTENT": {
      "UserHome": [
        {
          "content": `Inicio`
        }
      ],
      "AccountSummary": [
        {
          "content": `Resumen de la cuenta`
        },
        {
          "content": `Usuario ID:`
        },
        {
          "content": `Nombre:`
        },
        {
          //"type": "div",
          "content": `Email confirmado:`
        },
        {
          "content": `Vista previa del plan de tratamiento:`
        },
        {
          "content": `Ver plan de tratamiento` //"Preview Treatment Plan:" responce 
        },
        {
          "content": `Tratamiento comprado:` //"Preview Treatment Plan:" responce 
        }
      ],
      "CreatingTreatmentPlan": [
        { //17
          "content": `Inicializado Plan de Tratamiento`
        },
        { //18
          "content": `Estamos creando tu oferta de plan de tratamiento personalizado.`
        }
      ]
    }
  }
};



export default PAGE;


