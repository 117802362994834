//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation UpdateCoupon(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateCoupon(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_UPDATE_COUPON_USER_CODE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation UpdateCouponUserCode(
    $id: String,
    $CODE: String,
    $UID: String
  ) {
    updateCouponUserCode(
      input: {
        id: $id,
        CODE: $CODE,
        UID: $UID
      } 
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation NewCoupon(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createCoupon(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteCoupon(
    $id: String,
  ) {
    deleteCoupon(id: $id)
  }
`;

const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteCouponList(
    $ids: [String]
  ) {
    deleteCouponList(ids: $ids)
  }
`;

const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneCoupon(
    $id: String,
  ) {
    cloneCoupon(id: $id){
      _id
    }
  }
`;



const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneCouponList(
    $ids: [String]
  ) {
    cloneCouponList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateCoupon(
    $id: String,
  ) {
    deactivateCoupon(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateCouponList(
    $ids: [String]
  ) {
    deactivateCouponList(ids: $ids)
  }
`;

const MUTATIONS = {
  update: MUTATION_EDIT,
  updateCouponUserCode: MUTATION_UPDATE_COUPON_USER_CODE,
  create: MUTATION_ADD,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;