import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';

const FORM_FIELDS = (obj={}) => {
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">ID CANT BE CHANGED</span></div>,
        type: "text",
        name: "id",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "_id",
        hide: true
      },
      {
        title: <div className="formTitle"><span className="">API Call</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What API call triggered this new activity?</span></div>,
        type: "text",
        name: "APICALL",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "APICALL"
      },
      {
        title: <div className="formTitle"><span className="">Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What is this activity called? This could help identify any subactivity iniside an api. Maybe Update + what's being updated.</span></div>,
        type: "text",
        name: "NAME",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "NAME"
      },
      {
        title: <div className="formTitle"><span className="">Notes</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Any additional notes for this activity that could help us know what happened.</span></div>,
        type: "text",
        name: "NOTES",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "NOTES"
      },
      {
        title: <div className="formTitle"><span className="">Category</span></div>,
        subTitle: <div className="formSubTitle"><span className="">A possible category for sorting these activities later.</span></div>,
        type: "text",
        name: "CATEGORY",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "CATEGORY"
      },
      {
        title: <div className="formTitle"><span className="">Who</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Who triggered or caused this activity.</span></div>,
        type: "text",
        name: "WHO",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "WHO"
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The status of the activity</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: false,
        defaultValue: "PENDING",
        defaultValuePath: "status"
      }
    ]
  )
};




export default FORM_FIELDS;