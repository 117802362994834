//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation Update(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateAlignerGroup(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation NewAlignerGroup(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createAlignerGroup(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;

const CREATE_AND_SCHEDULE_ALIGNER_GROUPS = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreateAndScheduleAlignerGroups(
    $TPID: String,
    $groupList: [GroupListInput],
  ) {
    createAndScheduleAlignerGroups(
      input: {
        TPID: $TPID,
        groupList: $groupList
      }
    )
  }
`;

const ALIGNER_GROUP_CREATE_ORDERS = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation AlignerGroupCreateOrders(
    $id: String
  ) {
    alignerGroupCreateOrders(
      input: {
        id: $id
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteAlignerGroup(
    $id: String,
  ) {
    deleteAlignerGroup(id: $id)
  }
`;


const MUTATION_DELETE_FOREVER = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteAlignerGroupRemove(
    $id: String,
  ) {
    deleteAlignerGroupRemove(id: $id)
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteAlignerGroupList(
    $ids: [String]
  ) {
    deleteAlignerGroupList(ids: $ids)
  }
`;



const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneAlignerGroup(
    $id: String,
  ) {
    cloneAlignerGroup(id: $id){
      _id
    }
  }
`;



const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateAlignerGroup(
    $id: String,
  ) {
    deactivateAlignerGroup(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateAlignerGroupList(
    $ids: [String]
  ) {
    deactivateAlignerGroupList(ids: $ids)
  }
`;




const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneAlignerGroupList(
    $ids: [String]
  ) {
    cloneAlignerGroupList(ids: $ids)
  }
`;



const RECREATE_ALIGNERS = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation RecreateAligners(
    $id: String,
  ) {
    recreateAligners(id: $id){
      _id
    }
  }
`;

const MUTATIONS = {
  update: MUTATION_EDIT,
  create: MUTATION_ADD,
  createAndScheduleAlignerGroups: CREATE_AND_SCHEDULE_ALIGNER_GROUPS,
  alignerGroupCreateOrders: ALIGNER_GROUP_CREATE_ORDERS,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  deleteRemove: MUTATION_DELETE_FOREVER,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  recreateAligners: RECREATE_ALIGNERS,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;