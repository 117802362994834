import React from 'react';
import TemplatePage from 'website/templates/templatePage/index.js';
import 'styles/scss/website/pages/resetPassword.scss';
import HeaderImage from 'website/components/HeaderImage/index';
import ResetPW from 'global/components/ResetPW/index';
import API_CALLS from 'API_CALLS/index';
//import Main1 from 'global/img/squareGirlLaptop.jpg';
//import Main1_600 from 'global/img/squareGirlLaptop_600.jpg';
import Main1_500 from 'global/img/squareGirlLaptop_500.jpg';
//import Main1_400 from 'global/img/squareGirlLaptop_400.jpg';
import ResetPasswordLink from 'global/components/ResetPasswordLink/index';
import ESPAÑOL from "./ESPANOL";
import ENGLISH from "./ENGLISH";
import Loading from 'global/components/Loading/index';
//import FormBuilder from 'global/components/FormBuilder/index';
import LayoutBuilder from 'LayoutBuilder';

const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = LayoutBuilder;
/*
//?TOKEN=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7IlVJRCI6IjYxMzdhMjMzODUxNjNkNDNlYWQwMmIyNCIsIlRPSUQiOiI2MmY0MTkzMDU4NjhkYjlmNTRmNTdjODQifSwiaWF0IjoxNjYwMTY0NDAwLCJleHAiOjE2NjAyNTA4MDB9.7W0DVJHgC4s7h4VIMR9Kj6T2OLuxF2OqA_nQndg6uyM
*/

class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      TOKEN: undefined,
      status: 'loading'
    };    
    this.APICheckTokenIsStillGood = this.APICheckTokenIsStillGood.bind(this);
  }

  didMount(){        
    document.querySelector("#Content").classList.add("noMinHeight")
    document.addEventListener('keydown', this.ChangeStep);
    if(!window.Client) window.GlobalUtil.subscribeEventOnce("ClientEvent", this.APICheckTokenIsStillGood);
    else this.APICheckTokenIsStillGood();
  }

  willUnmount(){
    document.querySelector("#Content").classList.remove("noMinHeight")
    document.removeEventListener('keydown', this.ChangeStep);
  }

  getToken = () => {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var TOKEN = urlParams.get('TOKEN');
    //if(TOKEN.slice(0,1) !== "/") TOKEN = "/"+TOKEN;
    //console.log("this.TOKEN","\n\n",this.TOKEN,"\n\n");
    //console.log(`this.TOKEN`,"\n\n",this.TOKEN,"\n\n");
    this.setState({TOKEN: TOKEN})
    return TOKEN;
  }

  ChangeStep = (e) => {
    return;
    //console.log(`e`,"\n\n",e,"\n\n");
    // const KeyDic = {
    //   '1': 'true',
    //   '2': 'TokenExpiredError',
    //   '3': 'TokenUsedError',
    //   '4': 'MissingToken',
    //   '5': 'PasswordChanged',
    //   '6': 'ServerDown',
    //   '7': 'loading',
    //   '8': 'loading'
    // }
    // if(KeyDic[e.key]){
    //   this.setState({status: KeyDic[e.key]});
    // }         <meta name="robots" content="noindex">
  }

  APICheckTokenIsStillGood(){ //SET THIS PAGE AS PAGE TO COME BACK TO ONCE THE ADMIN SIGNS IN
    var TOKEN = this.getToken();
    console.log(`TOKEN`,"\n\n",TOKEN,"\n\n");
    if(!TOKEN){
      this.setState({loading: false, status: 'MissingToken'})
      return;
    }
    window.Client.query({
      query: API_CALLS.SITE_SETTINGS.QUERIES.tokenCheck(),
      variables: {
        token: TOKEN
      },
      fetchPolicy: "no-cache"
    })    
    .then(({data={}}) => {
      const {tokenCheck} = data;
      if(this) this.setState({status: tokenCheck, loading: false});
    })
    .catch((error)=>{
      console.dir("APICheckTokenIsStillGood error", error);
      if(this) this.setState({status: 'ServerDown', loading: false});
    })
  }

  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    var {loading, status, submitting, TOKEN} = this.state;
    return (
      <div className="contentPage" id="PageResetPassword">
        <div id="TopMainSection" className="containerSection backgroundImage3">
          <Container className="">
            <Row className="">
              <Column className="" col="12" xs="12" sm="12" md="5" lg="5" xl="5">
                <div className="bigPicture">
                  <HeaderImage
                    img={Main1_500}
                    alt="Girl on laptop"
                    right={true}
                  />
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="7" lg="7" xl="7">
                <React.Fragment>
                  {
                    loading 
                    ? <Loading 
                        //floating="true"
                        header={CONTENT[0]}
                        body={''}
                        inlineStyles={{
                          "LoadingScreen": {
                            "paddingTop": "100px"
                          }
                        }}
                      />
                    : <React.Fragment>
                      {
                        status === "true" &&
                        <div className="">
                          {CONTENT[2]}
                          {CONTENT[3]}
                          {CONTENT[4]}
                          <br/>
                          {submitting &&  <Loading 
                            //floating="true"
                            header={CONTENT[1]}
                            body={''}
                            inlineStyles={{
                              "LoadingScreen": {
                                "paddingTop": "100px"
                              }
                            }}
                          />}
                          <ResetPW
                            onFail={(error)=>{
                              this.setState({submitting: false, status: 'ServerDown'});
                            }}
                            onSuccess={()=>{
                              this.setState({submitting: false, status: 'PasswordChanged'});
                            }}
                            onSubmit={()=>{
                              this.setState({submitting: true});
                            }}
                            id=''
                            className=''
                            token={TOKEN}
                            //token={TOKEN}
                          />
                        </div>
                      }
                      {
                        ((status === "JsonWebTokenError") || (status === "TokenExpiredError")) &&
                        <div className="">
                          {CONTENT[2]}
                          {CONTENT[3]}
                          <div className="error">
                            <div className="icon">
                              <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className="textWrapper">
                              <div className="header">{CONTENT[5]}</div>
                              <div className="text">{CONTENT[6]}</div>
                              <ResetPasswordLink resetText={CONTENT[15]} />
                            </div>
                          </div>
                        </div>
                      }
                      {
                        status === "TokenUsedError" &&
                        <div className="">
                          {CONTENT[2]}
                          {CONTENT[3]}
                          <div className="error">
                            <div className="icon">
                              <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className="textWrapper">
                              <div className="header">{CONTENT[7]}</div>
                              <div className="text">{CONTENT[8]}</div>
                              <ResetPasswordLink resetText={CONTENT[15]} />
                            </div>
                          </div>
                        </div>
                      }
                      {
                        status === "MissingToken" &&
                        <div className="">
                          {CONTENT[2]}
                          {CONTENT[3]}
                          <div className="error">
                            <div className="icon">
                              <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className="textWrapper">
                              <div className="header">{CONTENT[9]}</div>
                              <div className="text">{CONTENT[10]}</div>
                              <ResetPasswordLink resetText={CONTENT[15]} />
                            </div>
                          </div>
                        </div>
                      }
                      {
                        status === "PasswordChanged" &&
                        <div className="">
                          {CONTENT[2]}
                          {CONTENT[3]}
                          <div className="success">
                            <div className="icon">
                              <i className="far fa-smile-wink"></i>
                            </div>
                            <div className="textWrapper">
                              <div className="header">{CONTENT[11]}</div>
                              <div className="text">{CONTENT[12]}</div>
                            </div>
                          </div>
                        </div>
                      }
                      {
                        status === "ServerDown" &&
                        <div className="">
                          {CONTENT[2]}
                          {CONTENT[3]}
                          <div className="error">
                            <div className="icon">
                              <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className="textWrapper">
                              <div className="header">{CONTENT[13]}</div>
                              <div className="text">{CONTENT[14]}</div>
                            </div>
                          </div>
                        </div>
                      }
                    </React.Fragment>
                  }
                </React.Fragment>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}





export default Index;

      
