import React from 'react';


const Index = {
  title: (TemplateObj)=>{
    return "Edit "+TemplateObj.TITLE;
  },
  onClose: (that, TEMP_UID, TemplateObj)=>{ //THIS FUNCTION RUNS AFTER THE ONCLOSE FUNCTION INSIDE TemplateSlideInRight RUNS, AND IT'S ALL CLOSED.
    var TemplateDBObj = {...that.state.TemplateDBObj};
    delete TemplateDBObj.EDIT[TEMP_UID];
    that.setState({TemplateDBObj: TemplateDBObj}, ()=>{
      if(that.SHOULDRELOAD) window.GlobalUtil.triggerEvent("APIMutationCalled", {
        NAME: TemplateObj.NAME
      });
      that.SHOULDRELOAD = false;
    });
  },
  onSave: (that, TEMP_UID)=>{
    if(that.TemplateDBEditRef[TEMP_UID]) that.TemplateDBEditRef[TEMP_UID].onSave(
      (onSuccess)=>{
        if(that.SlideInRightRef[TEMP_UID]){
          that.SHOULDRELOAD = true;
          that.SlideInRightRef[TEMP_UID].onClose();
        }
      },
      (onFail)=>{
        if(that.SlideInRightRef[TEMP_UID]){
          that.SHOULDRELOAD = true;
          that.SlideInRightRef[TEMP_UID].onClose();
        }
      }
    );
  },
  customButtons: (that, TEMP_UID, TemplateObj)=>{
    return (TemplateObj.CUSTOMBUTTONS 
      ? ()=>{                    
        return TemplateObj.CUSTOMBUTTONS(that.SlideInRightRef[TEMP_UID], that.TemplateDBEditRef[TEMP_UID], TemplateObj);
      } 
      : null
    )
  }
}
export default Index;
      
