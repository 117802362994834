import React from 'react';
import {Helmet} from "react-helmet-async";
import TemplatePage from 'website/templates/templatePage/index.js';
import CONTENTS from "./CONTENTS";
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   //this.state = {...this.state
  //   //};    
  // }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER;  
    //var {showSideMenu} = this.state;
    return (
      <div className="contentPage" id="PageConsent">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <Container className="">
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                {CONTENT.Section1[0]}
              </div>
            </Column>
          </Row>
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT.Section2[0]}

{/*<div className="basicText">Signature(s)</div>
<div className="basicText">I am at least 18 years of age and am my own legal guardian and understand, agree and accept to be bound by all of the foregoing and hereby give my consent to be treated by Company and its dental professionals.</div>
<div className="basicText">Name ____________________________________________         Date ________________</div>
<div className="basicText">Date of Birth ____________________________________</div>
<div className="basicText">I am the parent or legal guardian of ______________________________, a minor child born _______________ at _______________________________.</div>
<div className="basicText">I have read and understood the foregoing terms and accept and agree to be bound by the same for myself and for my minor child, and hereby give my consent for my minor child to be treated by Company and its dental professionals. </div>
<div className="basicText">Name __________________________________________         Date ________________</div>
<div className="basicText"><strong>[a]</strong>I have been looking into the issue of listing a specific arbitration service in the Terms of Use. I believe that in order for the client to consent to the terms, and for enforceability of arbitration consent, that a specific group needs to be addressed in the terms. I will continue to look into this, if this is indeed the case, is this the group that we would like to go with?</div>
*/}

            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}


export default Index;
      