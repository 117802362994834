import React, { useState, useRef } from 'react';


const TITLE = (title, required, requiredMessage="")=>{
  const Title = (title ? <div className="input-header">{title}{required && (requiredMessage ? requiredMessage : <span className="orange-text requiredStar"><i className="fas fa-star-of-life" /></span>)}</div> : null)
  return(Title)
}


const SUBTITLE = (subTitle)=>{
  const SubTitle = (subTitle ? <div className="input-header-sub">{subTitle}</div> : null)
  return(SubTitle)
}

const INVALID_CLASS_CHECK = (meta, required, failed, success) => {
  return (meta.error && meta.touched ? failed : success);
}

class ExtenderClass extends React.Component {
  constructor(props) {
    super(props);
    this.Validate = this.Validate.bind(this);
  }
  componentDidMount(){
    this.Validate(this.props.value);
    if(this && this.props.field && this.props.field.onMount) this.props.field.onMount((newName, newValue)=>{this.setState({[newName]: newValue})});
    if(this && this.onMount) this.onMount();
  }

  Validate(newValue){         
    if(!this) return;
    var {field={}} = this.props;
    var {required, name=""} = field;
    if(!required){
      this.props.onChangeValid(name, true);
      return;
    }

    var valid = true;
    if(!newValue) valid = false;
    if(valid && this.extraCheck) valid = this.extraCheck(newValue)
    this.props.onChangeValid(name, valid, newValue);
  }
}




export {TITLE, SUBTITLE, INVALID_CLASS_CHECK, ExtenderClass};