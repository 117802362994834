import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}






const Index = props => {
  const APIApproveTreatmentPlanOffer = () => {  
    var {curTreatmentPlan} = props;  
    var newObj = {
      id: curTreatmentPlan._id,
      rejectStepNotes:  "",//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      changeStep:  1,//MOVE TO NEXT STEP WHEN DONE
      HISTORY: {...curTreatmentPlan.HISTORY}
    }
    window.GlobalUtil.deepSetFromString(newObj, "HISTORY,DOCTOR,APPROVED_PLAN", `${Date.now()}`);
    return window.Client.mutate({
      mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.upload(),
      variables: newObj
    })
    .then(data=>{
      if(props.reloadMainList) props.reloadMainList();
      window.GlobalUtil.DialogStack.closeAll();
    })
    .catch(failed=>{
      console.log(`Failed to create patient`,"\n\n",failed,"\n\n");
      if(props.reloadMainList) props.reloadMainList();
      window.GlobalUtil.DialogStack.closeAll();
    });
  }
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 

  return(
    <div 
      className="button button1 btn-color2 actionButton" 
      style={{"margin":"0 5px 20px","display":"flex","alignItems":"center","justifyContent":"center"}} 
      onClick={APIApproveTreatmentPlanOffer}>
      <span className="text">{CONTENT[0]}&nbsp;</span>
      <span className="buttonIcon">
        <i className="fas fa-check" style={{"cursor": "pointer"}}></i>
      </span>
    </div>
  )                             
}






export default Index;
      
