//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=>gql`
  mutation UpdateItem(
    $id: String,
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateSiteSettings(
      input: {
        id: $id,
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;




const MUTATIONS = {
  update: MUTATION_EDIT,
}


export default MUTATIONS;