//import React from 'react';
const PAGE = [
  {
    "content": `Upload`
  },
  {
    "content": "Upload Treatment Plan Files"
  },
  {
    "content": "Cancel"
  },
  {
    "content": "Submit"
  },
  {
    "content": "Updating Order"
  },
  {
    "content": "Please stand by"
  },
  {
    "type": "span",
    "content": "<span style=\"color:red,font-weight:700\">WARNING THIS HAS BEEN REJECTED</span> - Rejection Notes"
  },
  {
    "content": "The text below SHOULD explain why this was sent back to you. Please fix any problems and submit it again."
  },
  {
    "content": "Upload the FULL treatment plan files"
  },
  {
    "content": "Upload Aligners STL's"
  },
  {
    "content": "Drag all aligner STL files below. When done press the submit button on the bottom of the page."
  },
  {
    "content": "Uploading"
  },
  {
    "content": "Please stand by"
  },
  {
    "content": "Close"
  },
  {
    "content": "Saving"
  },
  {
    "content": "Submit"
  }
];




  
export default PAGE;