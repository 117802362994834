import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";

const DEFAULT_MUTATION_FIELDS = `
  ID
  APICALL
  INPUT
  FAILED_WHERE
  FAILED_MESSAGE
  status
  meta{
    createAt
    updateAt
  }
`;


const FULL_MUTATION_FIELDS = ()=>{
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  return FullMutation;
};


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;

 
  
const DEFAULT_MUTATION_INPUT_VAR = ` 
  $APICALL: String,
  $INPUT: String,
  $FAILED_WHERE: String,
  $FAILED_MESSAGE: String,
  $status: String
`;


const DEFAULT_MUTATION_INPUT = ` 
  APICALL: $APICALL,
  INPUT: $INPUT,
  FAILED_WHERE: $FAILED_WHERE,
  FAILED_MESSAGE: $FAILED_MESSAGE,
  status: $status
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "APICALL": "string",
  "INPUT": "string",
  "FAILED_WHERE": "string",
  "FAILED_MESSAGE": "string",
  "status": "string",
  "meta,createAt": "string",
  "meta,updateAt": "string"
}



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE": "Error",
        "SUB_TITLE": "Full error saved",
        "FIELDS": [
          {
            "TYPE": "ROW",
            "FIELDS": [
              {
                "TYPE": "COLUMN",
                "FIELDS": [
                  {
                    "PATH": "_id",
                    "TYPE": "STRING",
                    "NAME": "ID"
                  },
                  {
                    "PATH": "APICALL",
                    "TYPE": "STRING",
                    "NAME": "API Call"
                  },
                  {
                    "PATH": "INPUT",
                    "TYPE": "STRING",
                    "NAME": "Input"
                  },
                  {
                    "PATH": "FAILED_WHERE",
                    "TYPE": "STRING",
                    "NAME": "Failed Where"
                  },
                  {
                    "PATH": "FAILED_MESSAGE",
                    "TYPE": "STRING",
                    "NAME": "Failed Message"
                  },
                  {
                    "PATH": "status",
                    "TYPE": "STRING",
                    "NAME": "Status"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.META()
];


const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    path: "FAILED_WHERE",
    type: "VALUE",
    name: "Failed Where",
    searchable: true
  },
  {
    path: "APICALL",
    type: "VALUE",
    name: "API CALL",
    searchable: true
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  },
];


const FAILEDS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS
}

export {
  DEFAULT_MUTATION_FIELDS,
  FULL_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};
export default FAILEDS;