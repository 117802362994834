import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';


const QUERY_REPORTS_DASHBOARD = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetReportsDashboard{
    dashboard
  }
`;

const QUERY_REPORTS_COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetReportsCount{
    reports_count
  }
`;


const QUERY_REPORTS_COUNT_RECENT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetReportsCountRecent(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    reports_count_recent(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const QUERY_REPORTS_PAGES = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetReportsPages(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    reports_pages(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERY_REPORTS_DOCTOR_DASHBOARD = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetReportsDoctorDashboard{
    doctor_dashboard
  }
`;

const QUERY_REPORTS_NAV_COUNT= (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetReportsNavCount{
    reports_nav_count
  }
`;

const QUERY_GET_DOCTOR_NAME = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetDoctorName($id: String){
    getDoctorName(id: $id)
  }
`;


const QUERY_GET_DOCTOR_NAMES = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetDoctorNames{
    getDoctorNames{
      _id
      name
      email
    }
  }
`;


const QUERY_GET_DOCTOR_EMAIL = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetDoctorEmail($id: String){
    getDoctorEmail(id: $id)
  }
`;


const GET_ZIP_LINK = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetZipLink($fileListString: String){
    getZipLink(fileListString: $fileListString)
  }
`;


const UPDATEDB = ()=> gql`
  query UpdateDB{
    updateDB
  }
`;


const PROMO_PAID_REPORT = ()=> gql`
  query PromoPaidReport($UID: String){
    promoPaid(UID: $UID){
      ADMIN_CHECK_NUMBER
      ADMIN_PAID_DATE
      TOTAL
      CODES{
        EARNED
        CODE
        DATE
        CPID
      }
    }
  }
`;



const QUERY_GET_AFFILIATE_SALES = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAffiliateSales(
    $UID: String,
    $CODE: String,
    $CPID: String,
    $APPROVED: String,
    $startAt: String,
    $endAt: String
  ){
    affiliateSales(
      query:{
        UID: $UID,
        CODE: $CODE,
        CPID: $CPID,
        APPROVED: $APPROVED,
        startAt: $startAt,
        endAt: $endAt
      }
    ){
      COUPONS{
        CPID
        CODE
        PENDING{
          CODE
          UID
          OIID
          CPID
          DATE
          EARNED
          APPROVED
          ADMIN_PAID
          ADMIN_PAID_DATE
          ADMIN_CHECK_NUMBER
        }
        APPROVED{
          CODE
          UID
          OIID
          CPID
          DATE
          EARNED
          APPROVED
          ADMIN_PAID
          ADMIN_PAID_DATE
          ADMIN_CHECK_NUMBER
        }
        TOTALS{
          APPROVED
          PENDING
          EARNED
        }
      }
      TOTALS{
        APPROVED
        PENDING
        EARNED
      }
    }
  }
`;


const QUERIES = {
	dashboard: QUERY_REPORTS_DASHBOARD,
  reports_count: QUERY_REPORTS_COUNT,
  reports_count_recent: QUERY_REPORTS_COUNT_RECENT,
  reports_pages: QUERY_REPORTS_PAGES,
  doctor_dashboard: QUERY_REPORTS_DOCTOR_DASHBOARD,
  getDoctorName: QUERY_GET_DOCTOR_NAME,
  getDoctorNames: QUERY_GET_DOCTOR_NAMES,
  getDoctorEmail: QUERY_GET_DOCTOR_EMAIL,
  getNavCount: QUERY_REPORTS_NAV_COUNT,
  updateDB: UPDATEDB,
  getZipLink: GET_ZIP_LINK,
  getAffiliateSales: QUERY_GET_AFFILIATE_SALES,
  promoPaidReport: PROMO_PAID_REPORT
}


export default QUERIES;