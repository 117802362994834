import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import PREVIEW from "./PREVIEW";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID
  status
  language
  params
  page
  url
  type
  meta {
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;
/*
DEFAULT_MUTATION_FIELDS CAN ALSO USE
User
*/

const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;



const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $status: String,
  $url: String,
  $language: String,
  $params: String,
  $page: String,
  $type: String,
  
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  status: $status,
  url: $url,
  language: $language,
  params: $params,
  page: $page,
  type: $type,
  
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "status": "string",
  "language": "string",
  "params": "string",
  "url": "string",
  "page": "string",
  "type": "string",
  "meta": CLEAN_FIELDS_DEFAULT.META
}


const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    path: "status",
    type: "VALUE",
    name: "Status",
    defaultValue: "NONE"
  },
  {
    path: "language",
    type: "VALUE",
    name: "Language",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "params",
    type: "VALUE",
    name: "Params",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "url",
    type: "VALUE",
    name: "URL",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "page",
    type: "VALUE",
    name: "Page",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "type",
    type: "VALUE",
    name: "Type",
    defaultValue: "NONE",
    searchable: true
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "NA"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "NA"
  }
];




  
  
  
  
  
  
  
  
  
  

const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME": "ID"
          },
          {
            "PATH": "language",
            "TYPE": "STRING",
            "NAME": "Language"
          },
          {
            "PATH": "params",
            "TYPE": "KEYVALUELISTSTRING",
            "NAME": "Params"
          },
          {
            "PATH": "page",
            "TYPE": "STRING",
            "NAME": "Page"
          },
          {
            "PATH": "url",
            "TYPE": "STRING",
            "NAME": "URL"
          },
          {
            "PATH": "type",
            "TYPE": "STRING",
            "NAME": "Type"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME": "Status"
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.META()
];



const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const TRACKING = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default TRACKING;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};