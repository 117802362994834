import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT, FULL_MUTATION_FIELDS} from './index';

  

const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    alignerGroups(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    alignerGroupCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    alignerGroups(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    alignerGroupCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query Single($id: String){
    alignerGroup(id: $id){
      _id
      ${whatToPull}
    }
  }`


const QUERY_SINGLE_FULL = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query Single($id: String){
    alignerGroup(id: $id, include: [
      {
        name: "aligners"
      }
    ]){
      _id
      ${FULL_MUTATION_FIELDS(["aligners"])}
    }
  }`


const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    alignerGroupDistinct(field:$field)
  }
`;







const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  count: COUNT,
  single: QUERY_SINGLE,
  singleFull: QUERY_SINGLE_FULL,
  distinct: QUERY_GET_DISTINCT
}


export default QUERIES;