import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
//import {DEFAULT_MUTATION_FIELDS as DEFAULT_FIELDS_TREATMENT_PLANS} from "../TREATMENT_PLANS/index";
import {FULL_MUTATION_FIELDS as FULL_MUTATION_FIELDS_TREATMENT_PLANS} from "../TREATMENT_PLANS/index";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_FIELDS_ORDERS} from "../ORDERS/index";

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import FORM_FIELDS_NEW_USER_SIDE from "./FORM_FIELDS_NEW_USER_SIDE";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID
  TPID
  OIDS
  DOCID
  PATIENTIDS
  AFFILIATE{
    isAffiliate
    codes {
      CPID
      CODE
    }
  }
  firstName
  lastName
  email
  phone
  role
  status
  confirmedEmail
  userFlowStatus
  preferredLanguage
  purchasedKit
  purchasedPlan
  approved
  approvedAt
  website
  userPicture{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  progressPictures{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  type
  RESET_PASSWORD
  settings{
    sendOffersEmail
  }
  address{
    ${MUTATION_FIELDS_DEFAULT.ADDRESS}
  }
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;


const FULL_MUTATION_FIELDS = (include=["treatmentPlan"])=>{
  var IncludeDic = {
    orders: `
      orders{
        _id
        ${DEFAULT_FIELDS_ORDERS}
      }
    `,
    treatmentPlan: `
      treatmentPlan{
        _id
        ${FULL_MUTATION_FIELDS_TREATMENT_PLANS()}
      }
    `
  };
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  include.map((key, index)=>{
    if(IncludeDic[key]) FullMutation += IncludeDic[key];
  })
  return FullMutation;
};


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;

const DEFAULT_QUERY_FIELDS_BASIC = `
  ID
  firstName
  lastName
  email
  phone
  role
  preferredLanguage
  AFFILIATE {
    isAffiliate
    codes{
      CPID
      CODE
    }
  }
  purchasedKit
  purchasedPlan
  website
  approved
  approvedAt
  address{
    ${MUTATION_FIELDS_DEFAULT.ADDRESS}
  }
`;

 
  

const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $TPID: String,
  $OIDS: [String],
  $DOCID: String,
  $PATIENTIDS: [String],
  $AFFILIATE: UserAffiliateInput,
  $email: String,
  $firstName: String,
  $lastName: String,
  $newPassword: String,
  $address: AddressInput,
  $phone: String,
  $role: String,
  $confirmedEmail: Boolean,
  $userPicture: [FileObjectInput],
  $purchasedPlan: Boolean,
  $purchasedKit: Boolean,
  $userFlowStatus: String,
  $status: String,
  $website: String,
  $preferredLanguage: String,
  $progressPictures: [FileObjectInput],
  $meta: MetaInput,
  $settings: UserSettingsInput,
  $type: String,
  $approved: Boolean,
  $approvedAt: String,
  $createRequestTreatmentPlanTask: Boolean,
  $sendWelcomeResetPasswordEmail: Boolean,
  $RESET_PASSWORD: Boolean,
  $sendWelcomeEmail: Boolean
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  TPID: $TPID,
  OIDS: $OIDS,
  DOCID: $DOCID,
  PATIENTIDS: $PATIENTIDS,
  AFFILIATE: $AFFILIATE,
  email: $email,
  firstName: $firstName,
  lastName: $lastName,
  newPassword: $newPassword,
  preferredLanguage: $preferredLanguage,
  address: $address,
  phone: $phone,
  role: $role,
  confirmedEmail: $confirmedEmail,
  userPicture: $userPicture,
  purchasedPlan: $purchasedPlan,
  purchasedKit: $purchasedKit,
  userFlowStatus: $userFlowStatus,
  status: $status,
  website: $website,
  progressPictures: $progressPictures,
  meta: $meta,
  settings: $settings,
  type: $type,
  approved: $approved,
  approvedAt: $approvedAt,
  createRequestTreatmentPlanTask: $createRequestTreatmentPlanTask,
  RESET_PASSWORD: $RESET_PASSWORD,
  sendWelcomeResetPasswordEmail: $sendWelcomeResetPasswordEmail,
  sendWelcomeEmail: $sendWelcomeEmail
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "TPID": "string",
  "OIDS": ["string"],
  "DOCID": "string",
  "AFFILIATE": {
    "isAffiliate": "boolean",
    "codes": [
      {
        "CPID": "string",
        "CODE": "string"
      }
    ]
  },
  "PATIENTIDS": ["string"],
  "email": "string",
  "firstName": "string",
  "lastName": "string",
  "newPassword": "string",
  "phone": "string",
  "role": "string",
  "website": "string",
  "userFlowStatus": "string",
  "preferredLanguage": "string",
  "status": "string",
  "type": "string",
  "confirmedEmail": "boolean",
  "approved": "boolean",
  "approvedAt": "string",
  "purchasedKit": "boolean",
  "purchasedPlan": "boolean",
  "RESET_PASSWORD": "boolean",
  "settings,sendOffersEmail": "boolean",
  "userPicture": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  progressPictures: [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "address": CLEAN_FIELDS_DEFAULT.ADDRESS,
  "meta": CLEAN_FIELDS_DEFAULT.META,
  "createRequestTreatmentPlanTask": "boolean",
  "sendWelcomeEmail": "boolean"
}



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE":"User",
        "SUB_TITLE":"Person looking for aligners",
        "FIELDS": [
          {
            "TYPE": "ROW",
            "FIELDS": [
              {
                "CONDITION": obj=>(obj.userPicture && obj.userPicture[0]),
                "TYPE": "COLUMN",
                "col": 12,
                "xs": 12,
                "sm": 12,
                "md": 2,
                "lg": 2,
                "xl": 2,
                "FIELDS": [
                  {
                    "NAME":"Image",
                    "PATH": "userPicture,0,url",
                    "TYPE": "IMAGE"
                  }
                ]
              },
              {
                "TYPE": "COLUMN",
                "FIELDS": [
                  {
                    "PATH": "_id",
                    "TYPE": "STRING",
                    "NAME":"ID"
                  },
                  {
                    "PATH": "firstName",
                    "TYPE": "STRING",
                    "NAME":"First Name"
                  },
                  {
                    "PATH": "lastName",
                    "TYPE": "STRING",
                    "NAME":"Last Name",
                    "DEFAULT": "Not Purchased yet"
                  },
                  {
                    "PATH": "email",
                    "TYPE": "STRING",
                    "NAME":"Email",
                    "DEFAULT": "Not Purchased yet"
                  },
                  {
                    "PATH": "confirmedEmail",
                    "TYPE": "STRING",
                    "NAME":"Confirmed Email",
                    "DEFAULT": "FALSE"
                  },
                  {
                    "PATH": "approved",
                    "TYPE": "STRING",
                    "NAME":"Approved",
                    "DEFAULT": "FALSE"
                  },
                  {
                    "PATH": "approvedAt",
                    "TYPE": "DATE",
                    "FORMAT": "n/d/Y @ H:mA",
                    "NAME": "Create At"
                  },
                  {
                    "PATH": "AFFILIATE,isAffiliate",
                    "TYPE": "STRING",
                    "NAME": "Is Affiliate"
                  },
                  // {
                  //   "CONDITION": obj=>(obj.LISTITEM && obj.LISTITEM.length),
                  //   "PATH": "LISTITEM",
                  //   "TYPE": "ARRAY_STRINGS",
                  //   "PREVIEW_LINK": {
                  //     "TYPE": "PREVIEW_BUTTON",
                  //     "TITLE": "Coupon", //TITLE OF SLIDE OUT
                  //     "APINAME": "COUPONS",
                  //     "ID_PATH": "LISTITEM"
                  //   },
                  //   "NAME": "LIST ITEM NAME"
                  // },
                  {
                    "PATH": "preferredLanguage",
                    "TYPE": "STRING",
                    "NAME":"Preferred Language",
                    "DEFAULT": "ENGLISH"
                  },
                  {
                    "PATH": "website",
                    "TYPE": "STRING",
                    "NAME":"Website",
                    "DEFAULT": "ENGLISH"
                  },
                  {
                    "PATH": "TPID",
                    "TYPE": "STRING",
                    "PREVIEW_LINK": {
                      "TYPE": "PREVIEW_BUTTON",
                      "BUTTON_TITLE": "Treatment Plan",
                      "TITLE": "Treatment Plan", //TITLE OF SLIDE OUT
                      "APINAME": "TREATMENT_PLANS",
                      "ID_PATH": "TPID",
                      "EDITABLE": true
                    },
                    "NAME":"Treatment Plan ID"
                  },
                  {
                    "CONDITION": obj=>obj.DOCID,
                    "PATH": "DOCID",
                    "TYPE": "STRING",
                    "PREVIEW_LINK": {
                      "TYPE": "PREVIEW_BUTTON",
                      "BUTTON_TITLE": "Doctor",
                      "TITLE": "Doctor", //TITLE OF SLIDE OUT
                      "APINAME": "USERS",
                      "ID_PATH": "DOCID",
                      "EDITABLE": true
                    },
                    "NAME":"Doctor ID"
                  },
                  {
                    "CONDITION": obj=>(obj.OIDS && obj.OIDS.length),
                    "PATH": "OIDS",
                    "TYPE": "ARRAY_STRINGS",
                    "PREVIEW_LINK": {
                      "TYPE": "PREVIEW_BUTTON",
                      "TITLE": "Order", //TITLE OF SLIDE OUT
                      "APINAME": "ORDERS",
                      "ID_PATH": "OIDS"
                    },
                    "NAME": "Order IDs"
                  },
                  {
                    "CONDITION": obj=>(obj.PATIENTIDS && obj.PATIENTIDS.length),
                    "PATH": "PATIENTIDS",
                    "TYPE": "ARRAY_STRINGS",
                    "PREVIEW_LINK": {
                      "TYPE": "PREVIEW_BUTTON",
                      "TITLE": "Patient", //TITLE OF SLIDE OUT
                      "APINAME": "USERS",
                      "ID_PATH": "PATIENTIDS"
                    },
                    "NAME": "Patient IDs"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "TYPE": "ROW",
        "FIELDS": [
          {
            "TYPE": "COLUMN",
            "TITLE":"CODES AND COUPONS",
            //"SUB_TITLE":"Current user mouth ",
            "FIELDS": [
              {
                "PATH": "AFFILIATE,codes",
                "TYPE": "ARRAY_CUSTOM",
                "CUSTOM": (obj)=>{
                  return(
                    <div className="d-flex">
                      <div className="" style={{padding: "0 0 0 10px"}}>
                        <div className="">
                          <div className="d-flex">
                            <div style={{"paddingRight":"10px","fontWeight":"600"}}>Coupon: </div>
                            <div>
                              <a className="" onClick={()=>{
                                window.GlobalUtil.triggerEvent("TemplateDBEvent", {
                                  "PATH": "CPID",
                                  "NAME":"COUPON ID",
                                  "TITLE": "Coupon", //TITLE OF SLIDE OUT
                                  "APINAME": "COUPONS",
                                  "ID_PATH": "CPID",
                                  "EDITABLE": true,
                                  TYPE: "PREVIEW",
                                  ID: obj.CPID
                                });
                              }}>
                                {obj.CPID}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="d-flex">
                            <div style={{"paddingRight":"10px","fontWeight":"600"}}>Code: </div>
                            <div>{obj.CODE}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              }
            ]
          }
        ]
      },
    ]
  },
  // {
  //   "TYPE": "ROW",
  //   "FIELDS": [
  //     {
  //       "TYPE": "COLUMN",
  //       "TITLE":"Address",
  //       "SUB_TITLE":"Users Current Address",
  //       "FIELDS": [
  //         {
  //           "PATH": "address,street",
  //           "TYPE": "STRING",
  //           "NAME": "Street",
  //           "DEFAULT": "MISSING"
  //         },
  //         {
  //           "PATH": "address,street2",
  //           "TYPE": "STRING",
  //           "NAME": "Street2",
  //           "DEFAULT": "MISSING"
  //         },
  //         {
  //           "PATH": "address,city",
  //           "TYPE": "STRING",
  //           "NAME": "City",
  //           "DEFAULT": "MISSING"
  //         },
  //         {
  //           "PATH": "address,state",
  //           "TYPE": "STRING",
  //           "NAME": "State",
  //           "DEFAULT": "MISSING"
  //         },
  //         {
  //           "PATH": "address,zipcode",
  //           "TYPE": "STRING",
  //           "NAME": "Zipcode",
  //           "DEFAULT": "MISSING"
  //         },
  //         {
  //           "PATH": "address,country",
  //           "TYPE": "STRING",
  //           "NAME": "Country",
  //           "DEFAULT": "MISSING"
  //         },
  //       ]
  //     }
  //   ]
  // },
  PREVIEW_FORM_TEMPLATES.ADDRESS(),
  PREVIEW_FORM_TEMPLATES.PICTURES({
    "PATH": "userPicture",
    "TITLE": "User Profile Pictures",
    "SUB_TITLE": "Main Profile Pictures",
  }),
  PREVIEW_FORM_TEMPLATES.PICTURES({
    "PATH": "progressPictures",
    "TITLE":"Progress Pictures",
    "SUB_TITLE":"Current user mouth condition",
  }),
  PREVIEW_FORM_TEMPLATES.META()  
];


const LIST_HEADER_FIELDS = [
  {
    path: "userPicture.0.url",
    type: "IMAGE",
    name: "Image",
    defaultValue: "NONE"
  },
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    path: "firstName",
    type: "VALUE",
    name: "FirstName",
    searchable: true
  },
  {
    path: "lastName",
    type: "VALUE",
    name: "LastName",
    searchable: true
  },
  {
    path: "email",
    type: "EMAIL",
    name: "Email",
    searchable: true
  },
  {
    path: "phone",
    type: "PHONE",
    name: "Phone",
    searchable: true,
    minWidth: "120px"
  },
  {
    path: "role",
    type: "VALUE",
    name: "Role",
    searchable: true
  },
  {
    path: "AFFILIATE.isAffiliate",
    type: "BOOLEAN",
    name: "Is Affiliate",
    searchable: true
  },
  {
    path: "website",
    type: "VALUE",
    name: "Website",
    searchable: true
  },
  {
    path: "approved",
    type: "BOOLEAN",
    name: "Approved",
    searchable: true
  },
  {
    path: "approvedAt",
    type: "DATE",
    name: "Approved At",
    searchable: true
  },
  // {
  //   path: "status",
  //   type: "VALUE",
  //   name: "Status",
  // },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  },
];

const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;
  const ObjRemove = ["ID","__typename"];
  const ObjNoNull = ["approvedAt","userPicture","newPassword","progressPictures","DOCID", "phone"];
  const ArrayNoNull = ["PATIENTIDS"];
  const BoolianList = ["confirmedEmail","purchasedKit","purchasedPlan","RESET_PASSWORD","approved"];


  if(!curCleanObj.AFFILIATE) curCleanObj.AFFILIATE = {};
  if(!curCleanObj.AFFILIATE.isAffiliate) curCleanObj.AFFILIATE.isAffiliate = false;
  if(!curCleanObj.AFFILIATE.codes) delete curCleanObj.AFFILIATE.codes;
  
  for(var curVal of ObjRemove){ //REMOVE ALL IF THEY EXIST
    delete curCleanObj[curVal];
  }

  for(var curVal of BoolianList){ //Convert to true or false
    curCleanObj[curVal] = window.GlobalUtil.inputToBool(curCleanObj[curVal])
  }

  for(var curVal of ObjNoNull){ //REMOVE IF THEY ARE NULL OR FALSE OR UNDEFINED
    if(!curCleanObj[curVal]) delete curCleanObj[curVal];
  }

  for(var curVal of ArrayNoNull){ //REMOVE IF IS NOT ARRAY OR ARRAY HAS NO VALUE
    if(!curCleanObj[curVal] || !Array.isArray(curCleanObj[curVal]) || (curCleanObj[curVal].length < 1)) delete curCleanObj[curVal];
  }

  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;
  const ObjRemove = ["ID","__typename"];
  const ObjNoNull = ["approvedAt","userPicture","newPassword","progressPictures","DOCID", "phone"];
  const ArrayNoNull = ["PATIENTIDS"];
  const BoolianList = ["confirmedEmail","purchasedKit","purchasedPlan","RESET_PASSWORD","approved"];
  
  if(!curCleanObj.AFFILIATE) delete curCleanObj.AFFILIATE;
  if(curCleanObj.AFFILIATE && (curCleanObj.AFFILIATE.isAffiliate === undefined)) delete curCleanObj.AFFILIATE.isAffiliate;
  if(!curCleanObj.AFFILIATE.codes) delete curCleanObj.AFFILIATE.codes;

  for(var curVal of ObjRemove){ //REMOVE ALL IF THEY EXIST
    delete curCleanObj[curVal];
  }

  for(var curVal of BoolianList){ //Convert to true or false
    curCleanObj[curVal] = window.GlobalUtil.inputToBool(curCleanObj[curVal])
  }

  for(var curVal of ObjNoNull){ //REMOVE IF THEY ARE NULL OR FALSE OR UNDEFINED
    if(!curCleanObj[curVal]) delete curCleanObj[curVal];
  }

  for(var curVal of ArrayNoNull){ //REMOVE IF IS NOT ARRAY OR ARRAY HAS NO VALUE
    if(!curCleanObj[curVal] || !Array.isArray(curCleanObj[curVal]) || (curCleanObj[curVal].length < 1)) delete curCleanObj[curVal];
  }


  if(curCleanObj.AFFILIATE && (curCleanObj.AFFILIATE.isAffiliate !== undefined)) curCleanObj.AFFILIATE.isAffiliate = window.GlobalUtil.inputToBool(curCleanObj[curCleanObj.AFFILIATE.isAffiliate])
  
  return curCleanObj;
}


const USERS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  FORM_FIELDS_NEW_USER_SIDE: FORM_FIELDS_NEW_USER_SIDE,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export {
  DEFAULT_MUTATION_FIELDS,
  FULL_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_FIELDS_BASIC,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};
export default USERS;