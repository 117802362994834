import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import PREVIEW_FORMS from "./PREVIEW_FORMS/index";
import QUERIES from "./QUERIES";
import FormPreview from 'global/components/FormPreview/index';
///Users/danielezaldivar/Sites/sonrisas/src/global/components/FormPreview.js
/*
<PREVIEW
  VARIABLES={{
    id: TPID
  }}
/>
*/

function PREVIEW(props) {     
  var {VARIABLES={}, PREVIEW_FORM, TITLE, SUB_TITLE, EDITABLE} = props;
  VARIABLES.include = [
    {
      name: "alignerGroups",
      include: [
        {
          name: "aligners"
        }
      ]
    }
  ];
  //console.log(`VARIABLES`,"\n\n",VARIABLES,"\n\n");
        
  const { loading, error, data, refetch } = useQuery(QUERIES.singleFull(), {
    variables: VARIABLES,
    fetchPolicy: "no-cache"
  });
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  //console.log(`data`,"\n\n",data,"\n\n");
        
  if(!data.treatmentPlan) return null;
  var NewObj = window.GlobalUtil.stripOutFromObj(data.treatmentPlan, "__typename");  
  //console.log(`NewObj`,"\n\n",NewObj,"\n\n");
        
  window.CurObj = {...NewObj, id: NewObj._id};
  window.CurRefetchEdit = refetch;        
  if(props.onLoad) props.onLoad(window.CurObj);     
  const TitleText = (TITLE !== undefined) ? TITLE : "Plan Preview";  
  const Title = <div className="headerWithButton">
    <div className="previewFormTitle">{TitleText}</div>
    <button className="button button1 btn-color5 editHeaderButton" onClick={()=>{
      window.GlobalUtil.triggerEvent("TemplateDBEvent", {
        NAME: "TREATMENT_PLANS",
        TITLE: "Treatment Plan",
        FIELD: "treatmentPlan",
        TYPE: "EDIT",
        ID: NewObj._id,
        // INCLUDE: [ //OPTIONAL
        //   {
        //     name: "user"
        //   }
        // ]
      });
      }}><i className="fas fa-edit"></i></button>
  </div>
  return (
    <FormPreview
      TITLE={EDITABLE ? Title : TitleText}
      SUB_TITLE={(SUB_TITLE !== undefined) ? SUB_TITLE : "Full Preview of current plan."}
      NewObj={NewObj}
      PREVIEW_FORM={(PREVIEW_FORM ? PREVIEW_FORM : PREVIEW_FORMS.DEFAULT)}
    />
  );
}



export default PREVIEW;