//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="pageHeader"><i class="fas fa-user"></i> Profile</h1>`
	},
  {
    "content": `My Profile`
  },
  {
    "content": `First Name:`
  },
  {
    "content": `Last Name:`
  },
  {
    "content": `Phone:`
  },
  {
    "content": `Email:`
  },
  {
    "content": `Email Confirmed:`
  },
  {
    "content": `Progess Pictures`
  },
  {
    "content": `Shipping Address`
  },
  {
    "content": `Street 1:`
  },
  {
    "content": `Street 2:`
  },
  {
    "content": `City:`
  },
  {
    "content": `State:`
  },
  {
    "content": `Zipcode:`
  },
  {
    "content": `Country:`
  },
  {
    "content": `My Settings`
  },
  {
    "content": `Send Promotional Emails`
  }
];




	
export default PAGE;