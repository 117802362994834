import React, { useState, useRef } from 'react';
import {NavLink, Link} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from 'global/components/FormBuilder/index';
import API_CALLS from 'API_CALLS/index';
import ResetPasswordLink from 'global/components/ResetPasswordLink/index';
import SignUpButton from 'global/components/SignUpButton/index';
import LoginWithGoogleButton from 'global/components/LoginWithGoogleButton/index';
import LoginWithFacebookButton from 'global/components/LoginWithFacebookButton/index';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: {email: (window.Session.user && window.Session.user.email ? window.Session.user.email : "")},
      showForm: true,
      hasFailed: false,
      emailPasswordWrong: false,
      curStatus: false,
    };    
  }


  onSubmitLogin = () => {
    var {showForm, hasFailed, emailPasswordWrong, curStatus, currentObject} = this.state;    
    const newVar = JSON.parse(JSON.stringify(currentObject));
    if(!curStatus) return;
    window.Client.query({
      query: API_CALLS.USERS.QUERIES.login(),
      variables: newVar,
      fetchPolicy: "no-cache"
    })
    .then(({data={}}) => {
      //console.log(`data`,"\n\n",data,"\n\n");
      this.setState({
        currentObject: {}, //THIS OVERRIDES THE EXISTING OBJECT
      });
      if(this.props.onSuccess) this.props.onSuccess(data.login); //UPDATE THE DATABASE PAGE AREA            
    })
    .catch((error)=>{
      let errorMessage = error.message;
      console.log("onSubmitLogin errorMessage");
      console.dir(errorMessage,"\n\n");
      console.dir(error,"\n\n");
      if(errorMessage == 'Unexpected error value: "PASSWORD_WRONG"' || errorMessage == 'Unexpected error value: "NO_EMAIL_EXISITS"'){
        //TO RESET THE FORM DATA AND HAVE IT MARKED AS INVALID AGAIN HIDE AND SHOW THE FORM FROM SCRATCH
        this.setState({
          currentObject: {...currentObject, password: ""}, //THIS OVERRIDES THE EXISTING OBJECT
          emailPasswordWrong: true, //THIS SHOWS THE ERROR MESSAGE
          showForm: false,
          hasFailed: true
        }, ()=>{
            this.setState({
              showForm: true
            }, ()=>{
              //FOCUSE ON PASSWORD AGAIN
              var passwordRef = document.querySelectorAll("input[type=password]")
              if(passwordRef[0]) passwordRef[0].focus()
            })
          }
        );
      }

      if(this.props.onFail) this.props.onFail(); //UPDATE THE DATABASE PAGE AREA    
    })
  }

  render(){
    var {showForm, hasFailed, emailPasswordWrong, curStatus, currentObject} = this.state;            
    return (
      <div id={this.props.id} className={`loginForm ${this.props.className} ${hasFailed ? "hasFailed" : ""}`}>
        {
          emailPasswordWrong && this.props.emailOrPasswordWrongMessage && <div className="failedMessage">{this.props.emailOrPasswordWrongMessage}</div>
        }
        <LoginWithGoogleButton
          autoCreate={true}
          buttonText="Continue with Google"
          onFail={this.props.onFail}
          onSuccess={this.props.onSuccess}
        />
        <LoginWithFacebookButton
          autoCreate={true}
          buttonText="Continue with Facebook"
          onFail={this.props.onFail}
          onSuccess={this.props.onSuccess}
        />
        <div className="orSpacer" style={{"display":"flex","borderBottom":"1px solid #ccc","alignItems":"center","justifyContent":"center", "margin": "20px 0"}}>
          <div style={{"background":"#FFF","width":"60px","textAlign":"center","marginBottom":"-10px"}}>OR</div>
        </div>
        {
          showForm &&
          <FormBuilder
            //ref={formRef}
            initialValues={currentObject}
            listOfFields={[
              {
                title: <div className="formTitle"><span className="">Email</span></div>,
                type: "email",
                name: "email",
                //placeholder: "email",
                required: true,
                emailErrorMessage: "Email Error"
              },
              {
                title: <div className="formTitle"><span className="">{(window.Session.Language === "ESPAÑOL") ? "Contraseña" : "Password"}</span></div>,
                type: "password",
                name: "password",
                //placeholder: "password",
                required: true
              }
            ]}
            onSubmit={this.onSubmitLogin}
            onChange={newObj => this.setState({currentObject: newObj})}
            onValidate={(isValid)=>{this.setState({curStatus: isValid})}}
          />
        }
        <button className={`button button1 buttonFull ${!curStatus ? "disabled" : ""}`} onClick={this.onSubmitLogin} disabled={!curStatus}>{this.props.buttonText ? this.props.buttonText : "Login"}</button>
        <ResetPasswordLink />
        {
          this.props.showSignUpButton &&
          <React.Fragment>
            <div className="orSpacer" style={{"display":"flex","borderBottom":"1px solid #ccc","alignItems":"center","justifyContent":"center", "margin": "20px 0"}}>
              <div style={{"background":"#FFF","width":"60px","textAlign":"center","marginBottom":"-10px"}}>OR</div>
            </div>
            <SignUpButton {...this.props}/>
          </React.Fragment>
        }

      </div>
    );
  }
}


export default Index;