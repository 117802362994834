import React from 'react';
import UserProfile from 'user/normal/pages/profile/index';
import "styles/scss/lab/pages/profile.scss";

const Index = (props) => {
  return(
    <div id="LabProfile">
      <UserProfile
        {...props}
      />
    </div>
  )
}


export default Index;
      
