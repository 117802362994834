//import React from 'react';
const PAGE = {
  "StartTreatment": [
    //COUNT DOWN PART
    { //0
      "content": `Comienza tu tratamiento`
    },
    { //1
      "content": `Cuando obtenga sus alineadores, haga clic a continuación para comenzar su plan de tratamiento.`
    },
    { //2
      "content": `COMENZAR`
    },
    { //3
      "content": `Creando tu plan`
    },
    { //4
      "content": `Una vez que se haya creado su plan, podrá comenzar su plan de tratamiento aquí.`
    },
  ],
  "CurAlignerComponent1": [
    { //0
      "content": `Paso`
    },
    { //1
      "content": `Paso actual del alineador`
    },
    { //2
      "content": `No iniciado`
    },
    { //3
      "content": `Iniciado`
    },
  ],
  "CurAlignerComponent2": [
    { //0
      "content": `Alerta de alineación`
    },
    { //1
      "content": `Cuándo cambiar a su próximo alineador.`
    },
    { //2
      "content": `No iniciado`
    },
    { //3
      "content": `¡TRATAMIENTO FINALIZADO!`
    },
  ],
  "AlignerScheduleComponent": [
    { //0
      "content": `Horario de alineación`
    },
    { //1
      "content": `Vea su calendario completo de alineadores.`
    },
    { //2
      "content": `No ha comenzado`
    },
    { //3
      "type": "span",
      "content": `<span style="font-weight: 600;font-size: 18px;">Inicio</span>`
    },
    { //4
      "content": `Superior`
    },
    { //5
      "content": `y`
    },
    { //6
      "content": `Bajo`
    },
    { //7
      "content": `Historia`
    },
    { //8
      "content": `Iniciar alineador`
    }
  ],
  "CountDown": [
    { //0
      "content": "Dias"
    },
    { //1
      "content": "Hrs"
    },
    { //2
      "content": "Mnt"
    },
    { //3
      "content": "Seg"
    },
  ]
};




	
export default PAGE;