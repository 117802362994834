//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="pageHeader"><i class="fas fa-file-contract"></i><span>Plan de Tratamiento</span></h1>`
	},
  {
    "content": `Tu plan personal`
  },
  {
    "content": `La siguiente es información sobre su plan de tratamiento personal único. Una vez que haya elaborado su plan, podrá ver un PDF del mismo a continuación.`
  },
  {
    "type": "div",
    "content": `<div class="section">ID del Plan:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Fecha de creación:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Plan para:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Creado por:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Aprobado por:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Alineadores totales:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Duración:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Notas del Doctor:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Estado del plan:</div>`
  },
  {
    "type": "div",
    "content": `<div class="section">Plan inicializado:</div>`
  },
  {
    "content": `Plan comprado` //IF THEY HAVE NOT PURCHASED IT YET THEN THIS WILL BE THE TEXT FOR THEM TO START THAT
  },
  {
    "content": `Archivos del plan:`
  },
  {
    "content": `Vista` //VIEW BUTTON TEXT
  },
  {
    "content": `Plan PDF:`
  }
];




	
export default PAGE;