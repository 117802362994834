//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Use a promo code here to get your Clear Aligners EVEN CHEAPER!",
			"DESCRIPTION": "Got a promotional code from your favorite influencer? Use it here to get the full Straightening Treatments at a significant discounted price!",
			"KEYWORDS": "Promotional Code, Influencer Clear Aligners, Teeth Straightening Treatments, Smiles.Club, Smiles Club, clear treatment code, non-toxic aligners, risk free experience, flexible payment options, compare aligners, price compare"
		},
		"CONTENT": {
			"CodeZone": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Promotions</div>
						<h1 class="sectionTitle">Enter your exclusive promotional code below!</h1>
						<div class="">Unlocking a dazzling smile has never been more affordable with the use of a promotional code to secure a fantastic discount on clear aligners. Imagine getting your dream smile without compromising your budget! 
						<br/><br/>If you have a referral code, enter it below. Once it's been verified you will receive any discounts that come with that code. Don't miss out on this amazing deal today!</div>
						<br/>
					`
				}
			],
			"HeaderImage": [
				{
					"alt": `Smiling man holding Smiles Club Clear Aligners and showing great teeth.`
				}
			],
			"ImpressionKitContainer": [
				{
					"content": `OFFER UNLOCKED`
				}
			],
			"StepsZone": [
				{
					"type": "div",
					"className": "stepsZone",
					"content": `
						<div class="preHeader">Steps</div>
						<h2 class="sectionTitle2 limitWidth">How it works</h2>
					`
				}
			],
			"SimpleStepsList": [
				{
					"type": "div",
					"className": "text",
					"content": `
						<div class="textHeader">
						<div class="textHeaderStep">Step 1.</div>
						<div class="textHeaderTitle">Buy the FULL Treatment Plan</div>
						</div>
						<div class="textPostHeader">Purchased the full plan we'll send you an impression kit in the mail.</div>
						
					`
				},
				{
					"type": "div",
					"className": "text",
					"content": `
						<div class="textHeader">
						<div class="textHeaderStep">Step 2.</div>
						<div class="textHeaderTitle">Get your Impression Kit</div>
						</div>
						<div class="textPostHeader">Take your impressions with some pictures and send them back to us.</div>
						
					`
				},
				{
					"type": "div",
					"className": "text",
					"content": `<div class="textHeader">
									<div class="textHeaderStep">Step 3.</div>
									<div class="textHeaderTitle">Start your Aligners!</div>
									</div>
									<div class="textPostHeader">We'll send you your clear aligners with instructions so you can get started.</div>
								`
					}
			],
			"ReferralCodeSearch": [
				{
					"content": `Searching`
				},
				{
					"content": `Searching for referral Code`
				},
				{
					"content": `ERROR`
				},
				{
					"content": `Referral Code not Found`
				},
				{
					"content": `Sorry, but that code does not match any records we have. If you wish to try again press the button below.`
				},
				{
					"content": `Try Again`
				},
				{
					"content": `SUCCESS!`
				},
				{
					"content": `Referral Code Found`
				},
				{
					"content": null
				},
				{
					"content": `Enter Code Here`
				},
				{
					"content": `Submit`
				},
				{
					"content": `Submit`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Use un código de promoción aquí para obtener sus alineadores transparentes AÚN MÁS BARATOS",
			"DESCRIPTION": "¿Tienes un código promocional de uno de tus influencers favoritos? ¡Úselo en este sitio web para obtener los tratamientos de alisado completos a un precio con un descuento significativo!",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"CodeZone": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Promociones</div>
						<h1 class="sectionTitle">¡Ingrese su código promocional exclusivo a continuación!</h1>
						<div class="">Desbloquear una sonrisa deslumbrante nunca ha sido más asequible con el uso de un código promocional para asegurar un fantástico descuento en alineadores transparentes. ¡Imagine obtener la sonrisa de sus sueños sin comprometer su presupuesto!
						<br/><br/>Si tiene un código de referencia, ingréselo a continuación. Una vez que se haya verificado, recibirá los descuentos que vienen con ese código. ¡No te pierdas esta increíble oferta hoy!</div>
						<br/>
					`
				}
			],
			"HeaderImage": [
				{
					"alt": `Hombre sonriente que sostiene los alineadores transparentes Smiles Club y muestra una gran dentadura.`
				}
			],
			"ImpressionKitContainer": [
				{
					"content": `OFFER UNLOCKED`
				}
			],
			"StepsZone": [
				{
					"type": "div",
					"className": "stepsZone",
					"content": `
						<div class="preHeader">Pasos</div>
						<h2 class="sectionTitle2">Cómo funciona</h2>
					`
				}
			],
			"SimpleStepsList": [
				{
					"type": "div",
					"className": "text",
					"content": `
					  <div class="textHeader">
						<div class="textHeaderStep">Paso 1.</div>
						<div class="textHeaderTitle">Compra el Plan de Tratamiento.</div>
					  </div>
					  <div class="textPostHeader">Una vez que haya comprado el plan completo, le enviaremos un kit de impresión. </div>
					  
					`
				  },
				  {
					"type": "div",
					"className": "text",
					"content": `
					  <div class="textHeader">
						<div class="textHeaderStep">Paso 2.</div>
						<div class="textHeaderTitle">Tome las impresiones.</div>
					  </div>
					  <div class="textPostHeader">Luego tome las impresiones de tus dientes, tome algunas fotos y envíenos las impresiones.</div>
					  
					`
				  },
				  {
					"type": "div",
					"className": "text",
					"content": `<div class="textHeader">
									<div class="textHeaderStep">Paso 3.</div>
									<div class="textHeaderTitle">Consigue tus alineadores!</div>
								  </div>
								  <div class="textPostHeader">Le enviaremos tus alineadores transparentes para enderezar tus dientes.</div>
							  `
				  }
			],
			"ReferralCodeSearch": [
				  {
					"content": `Buscando`
				  },
				  {
					"content": `Buscando código de referencia`
				  },
				  {
					"content": `ERROR` 
				  },
				  {
					"content": `Código de referencia no encontrado` 
				  },
				  {
					"content": `Lo sentimos, pero ese código no coincide con ningún registro que tengamos. Si desea volver a intentarlo presione el botón de abajo.` 
				  },
				  {
					"content": `Intentar otra vez` 
				  },
				  {
					"content": `ÉXITO!` 
				  },
				  {
					"content": `Código de referencia encontrado` 
				  },
				  {
					"content": `` 
				  },
				  {
					"content": `Pon el Codigo` 
				  },
				  {
					"content": `Enviar`
				  }
			]
		}
	}
};


	
export default PAGE;


