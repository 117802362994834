//import React from 'react';
const PAGE = [
  {
    "content": "Ver Solicitud de Plan de Tratamiento"
  },
  {
    "content": "Cerrar"
  },
  {
    "content": "Información del paciente"
  },
  {
    "content": "Imágenes iniciales de la boca del usuario"
  },
  {
    "content": "Guardar"
  },
  {
    "content": "Archivos de escaneo de la boca"
  },
  {
    "content": "Queja principal"
  },
  {
    "content": "Otras notas"
  }
];




  
export default PAGE;