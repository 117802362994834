import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID
  todo
  notesList {
    ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
  }
  toEmailList
  ccEmailList
  tableSettings
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }   
`;



const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;


const DEFAULT_MUTATION_INPUT_VAR = ` 
  $todo: [String],
  $toEmailList: [String],
  $ccEmailList: [String],
  $notesList: [NotesListItemInput],
  $tableSettings: String
`;


const DEFAULT_MUTATION_INPUT = ` 
  todo: $todo,
  toEmailList: $toEmailList,
  ccEmailList: $ccEmailList,
  notesList: $notesList,
  tableSettings: $tableSettings
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "todo": ["string"],
  "toEmailList": ["string"],
  "ccEmailList": ["string"],
  "notesList": [
    CLEAN_FIELDS_DEFAULT.NOTESLIST
  ], 
  "tableSettings": "string",
  "meta": CLEAN_FIELDS_DEFAULT.META,
}




const DEFAULT_PREVIEW_FORM = () => [
  PREVIEW_FORM_TEMPLATES.NOTES_LIST({
    TITLE:"Todo Checklist",
    SUB_TITLE:"Use this as a todo checklist",
    PATH:"notesList",
  }),
  PREVIEW_FORM_TEMPLATES.META()
];


const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  
  if(!curCleanObj.logos) delete curCleanObj.logos;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  
  if(!curCleanObj.logos) delete curCleanObj.logos;
  return curCleanObj;
}

const SITE_SETTINGS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default SITE_SETTINGS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};