import React from 'react';
import DEFAULT from './DEFAULT';
import OFFER_REQUEST from './OFFER_REQUEST';
import PLAN from './PLAN';

const PREVIEW_FORMS = {
  DEFAULT,
  PLAN,
  OFFER_REQUEST
}


export default PREVIEW_FORMS;