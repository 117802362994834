/*
Example:
<SlideInRight

  />
*/
import React from 'react';
import ReactDOM from 'react-dom';

class SlideInRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animatedClass: "slideInRight"
    }
    this.onClose = this.onClose.bind(this);
    this.el = document.createElement('div');
    this.el.setAttribute("class", "SlideInRight");
  }

  componentDidMount() {
    this.DialogStackRef = window.GlobalUtil.DialogStack.add(this.onClose) 
    document.getElementById('Root').appendChild(this.el);
  }

  componentWillUnmount() {
    this.DialogStackRef.close();
    document.getElementById('Root').removeChild(this.el);
    clearTimeout(this.setTimeoutListener);
  }

  onClose(){
    this.setState({
      animatedClass: "slideOutRight"
    }, ()=>{
      if(this.setTimeoutListener) return;
      clearTimeout(this.setTimeoutListener);
      this.setTimeoutListener = setTimeout(()=>{
        if(this.el) this.props.onClose();
        this.setTimeoutListener = null;
      }, 400);
    })
  }

  render() {
    var {animatedClass} = this.state;
    var {showOverlay, width="auto", maxWidth="none", header, footer, customClass} = this.props;
    var content = <React.Fragment>
        {showOverlay ? <div id="SlideInRightOverlay" className="animated fadeIn" onClick={this.onClose}></div> : null }
        <div id="SlideInRightContent" className={`animated ${animatedClass} ${customClass ? customClass : ''}`} style={{width: width, maxWidth: maxWidth}} >
          {header ? <div id="Header">{header}</div> : null}
          <div id="Body">{this.props.children}</div>
          {footer ? <div id="Footer">{footer}</div> : null}
        </div>
      </React.Fragment>
    return(
      ReactDOM.createPortal(
        content,
        this.el
      )
    )
  }
}


export default SlideInRight;