//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';





const MUTATION_SEND_EMAIL = ()=> gql`
  mutation SendEmail(
    $whatEmail: String,
    $emailData: String
  ) {
    sendEmail(whatEmail: $whatEmail, emailData: $emailData)
  }
`;


const MUTATIONS = {
  sendEmail: MUTATION_SEND_EMAIL
}


export default MUTATIONS;