import React from 'react';
import {NavLink, Link} from 'react-router-dom';
// OIID
// UID: Schema.Types.ObjectId, 
// scheduledAt: {type: Date}, //WHEN THE ALIGNER SHOULD BE MADE
// shippedAt: {type: Date},
// createdAt: {type: Date}, //THIS IS WHEN THE PHYSICAL ALIGNER WAS CREATED NOT WHEN THE DB OBJECT WAS LIKE IN META
// createdBy: Schema.Types.ObjectId,
// status: {type: String, default: "PENDING"}, //PENDING, ACTIVE, INACTIVE, PAUSED, 
// step: {type: String, default: "MAKE"}, 
// aligners {
//   TPID: Schema.Types.ObjectId, 
//   AGID: Schema.Types.ObjectId, 
//   UID: Schema.Types.ObjectId, 
//   files: [
//     {
//       createAt: {type: Date, default: Date.now()},
//       url: {type: String, default: ""},
//       name: {type: String, default: ""},
//       type: {type: String, default: ""},
//       id: {type: String, default: mongoose.Types.ObjectId()}
//     }
//   ],
//   status: {type: String, default: "PENDING"}, //PENDING, ACTIVE, INACTIVE, PAUSED, 
//   meta: {
//     updateAt: {type: Date, default: Date.now()},
//     createAt: {type: Date, default: Date.now()}
//   }
// }
const PREVIEW_FORM = [
  {
    "TYPE": "CUSTOM_CODE",
    "CODE": (obj)=>{
      if(!obj.alignerGroups) return null;
      //console.log(`obj`,"\n\n",obj.alignerGroups,"\n\n");
      return(<AlignerGroups groups={obj.alignerGroups} />)
    }
  }
]




class AlignerGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: undefined
    };    
  }
  componentDidMount(){
    this.setState({isActive: -1})
  }
  render(){
    var {isActive} = this.state;
    var {groups} = this.props;
    var groupsSorted = groups.sort((a, b) => {
      if (a.scheduledAt > b.scheduledAt) {
        return 1;
      }
      if (a.scheduledAt < b.scheduledAt) {
        return -1;
      }
      return 0;
    });
    return (
      <div className="alignerGroups">
        {
          groupsSorted.map((group, index)=>{
            var isThisActive = ((isActive === undefined) || (isActive === index));
            return(
              <AlignerGroup 
                key={index} 
                index={index}
                group={group} 
                isThisActive={isThisActive}
                toggleActive={()=>{
                  if(isThisActive) this.setState({isActive: -1});
                  else this.setState({isActive: index});
                }}
              />
            )
          })
        }
      </div>
    );
  }
}






class AlignerGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateDiff:  "NOT SCHEDULED YET",
      scheduledAt: "NOT SCHEDULED YET"
    };    
  }
  componentDidMount(){
    this.setState({
      scheduledAt: window.GlobalUtil.dateBasic(Number(this.props.group.scheduledAt)),
      dateDiff: (this.props.group.scheduledAt ? new Date(Number(this.props.group.scheduledAt)).formatDiffFull("YNWD") :  "NOT SCHEDULED YET")
    })
  }
  render(){
    var {dateDiff, scheduledAt} = this.state;
    var {group, isThisActive, index} = this.props;
    var shippedAt = (
      group.OIID 
      ? <NavLink 
          className="" 
          to={`/admin/shipping/sendAligners/${(group.OIID ? group.OIID : '')}`} 
          onClick={()=>{
            window.GlobalUtil.DialogStack.closeAll()
          }}>
          NOT SHIPPED YET
        </NavLink> 
      : "NOT SHIPPED YET"
    );
    if(group.shippedAt) shippedAt = window.GlobalUtil.dateBasic(Number(group.shippedAt));

    return(
      <div className="group">
        <div className="title" onClick={this.props.toggleActive}>
          <div className="textZone">
            <div className="checkWrapper">
              <div className="check">
                {
                  group.shippedAt
                  ? <i className={`checkbox active fas fa-check-square`}></i>
                  : <i className={`checkbox ${(group.step === "SHIP") ? 'ship': ''} far fa-square`}></i>
                }
                <div className="checkText">Shipped</div>
              </div>
            </div>
            <div className="text">
              Aligner Group {index+1}
            </div>
          </div>
          <div className="fa">
            {
              isThisActive
              ? <i className="fas fa-plus animated fadeIn" />
              : <i className="fas fa-minus animated fadeIn" />
            }
          </div>
        </div>
        <div className={`accordionZone ${isThisActive ? "active" : ""}`}>
          <div className={`accordionZoneInner`}>
            <div className="cont">
              <div className="info infoTop">
                <div className="text">
                  <div className="desc">ID: </div>
                  <div className="val">{group._id}</div>
                </div>
                <div className="text">
                  <div className="desc">Scheduled At: </div>
                  <div className="val">{scheduledAt} {dateDiff ? `(${dateDiff})` : ''}</div>
                </div>
                <div className="text">
                  <div className="desc">Shipped At: </div>
                  <div className="val">{shippedAt}</div>
                </div>
                <div className="text">
                  <div className="desc">Step: </div>
                  <div className="val">{group.step}</div>
                </div>
              </div>
            </div>
            <div className="aligners">
              {
                group.aligners.map((aligner, index)=>{
                  return(
                    <div className="aligner" key={index}>
                      <div className="cont">
                        <div className="checkWrapper">
                          <div className="check">
                            {
                              (group.step === "SHIP") 
                              ? <i className={`checkbox active fas fa-check-square`}></i>
                              : <i className={`checkbox far fa-square`}></i>
                            }
                            <div className="checkText">Created</div>
                          </div>
                        </div>
                        <div className="info">
                          <div className="text">
                            <div className="desc">Number: </div>
                            <div className="val">{aligner.files[0].customName}</div>
                          </div>
                          <div className="text">
                            <div className="desc">Name: </div>
                            <div className="val">{aligner.files[0].name}</div>
                          </div>
                          <div className="text">
                            <div className="desc">ID: </div>
                            <div className="val">{aligner._id}</div>
                          </div>
                          <div className="text">
                            <div className="desc">URL: </div>
                            <div className="val"><a href={aligner.files[0].url} download target="_blank">{aligner.files[0].url}</a></div>
                          </div>
                          <div className="text">
                            <div className="desc">Type: </div>
                            <div className="val">{aligner.files[0].type}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default PREVIEW_FORM;