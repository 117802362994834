/*
LAST WORKED ON FEB/19/2022 SAT. DURRING 1ST EVER GIVEAWAY, WHEN WE HAD 12 PEOPLE ON LIST. BEFORE WE MADE ANY SALES
WHAT DOCTORS NEEDS:
□ ADD API TO PULL ALL USER'S WHO ARE RELATED TO THIS DOCTOR
□ FILTER BY THOSE WHO'VE BEEN PURCHASED (HAVE A OID) AND THOSE WHO DON'T
□ ON DASHBOARD 
  □ TEST AND REMOVE ALL CHECK THE BUTTON AND API TO CREATE USERS AND START TREATMENT PLANS
  □ COPY THE LIST COMPONENT IN BILLING AND CHANGE IT TO (WORK) FOR ORDER LIST
    □ ADD WORKING PREVIEW DIALOG FOR ORDER LIST AND INVOICE LIST
  □ COPY THE LIST COMPONENT IN BILLING AND CHANGE IT TO (WORK) FOR INVOICE LIST
    □ CREATE A PREVIEW FOR INVOICE LIST
    □ ADD AN OPTION TO "PRINT" INVOICE LIST AND GENORATE AN INVOICE PDF
  □ ADD WAY FOR DOCTOR TO "APPROVE" OF TREATMENT PLANS OR REJCTED THEM, AND INCLUDE THE REASONS WHY
    □ SEND US AN EMAIL WHEN THEY "REJECT" 
□ ON PAITENTS 
  □ QUERY ALL USERS WHO BELONG TO THE DOCTOR AND HAVE NOT BEEN PAID YET
    □ RESOLVE WITH NEW WORKING ORDER LIST
  □ QUERY ALL USERS WHO BELONG TO THE DOCTOR AND HAVE BEEN PURCHASED
    □ RESOLVE WITH NEW WORKING ORDER LIST
□ ON BILLING
  □ CREATE A CUSTOM VIEWER FOR THEIR ORDER PREVIEW TO SHOW ALL THE USERS INCLUDED IN THE INVOICE THEY PAID

  

□ ON ADMIN SIDE
  □ CREATE WAY TO UPDATE DOCTOR'S ORDERS WHEN THEY PAY FOR THE ALIGNERS THEY'VE ORDERD
    □ CREATE WAY TO CREATE AN ORDER, ASSIGN IT TO SOME DOCTOR, AND SELECT ALL USERS THEY "PAID" FOR
  □ ~CREATE WAY TO CREATE NEW DOCTORS



*/


import React from 'react';
import "styles/scss/lab/index.scss";

import Dashboard from './pages/dashboard/index';
//import Billing from './pages/billing/index';
import Profile from './pages/profile/index';
import History from './pages/history/index';
import Help from './pages/help/index';


//import UserProgressChanger from './pages/userProgressChanger'; //THIS TEMP SHOWS UP TO ALLOW TESTING ALT SITUATIONS
import MobileMenu from './components/MobileMenu';
import {  Routes, Route, Navigate } from "react-router-dom";

import PAGES from './pages/pages.json'; //ADD NEW PAGES HERE
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import Loading from 'global/components/Loading/index';
import Header from 'user/lab/components/Header/index';
import NavMenu from 'user/lab/components/NavMenu/index';
import {GetFullUserInfo} from 'global/utils/helper-functions';

const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();


const PAGES_COMPONENTS = { //ADD ANY NEW PAGES HERE
  Dashboard: Dashboard,
  //Billing: Billing,
  History: History,
  Profile: Profile,
  Help: Help,


  // Inicio: Dashboard,
  // Historia: History,
  // Perfil: Profile,
  // Ayuda: Help
};


//window.Session.user.type === "ONLINE"
//GET TASK USER_PURCHASE_TREATMENT_PLAN IF AVALIBAL

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
    };              
  }

  componentDidMount(){
    window.ForceUserReload = ()=>GetFullUserInfo({
      noCache: true,
      callBackSuccess: ()=>{},
      callBackFailed: ()=>{}
    });
    if(document.querySelector("#Website")) document.querySelector("#Website").remove();

    // if(typeof window === 'undefined'){
    // }
  }

  componentWillUnmount(){
  }  
  

  onUpdateUser = (newObj, callBack=()=>{}) => {
    window.Client.mutate({ //API UPDATE
      mutation: MUTATION_USER_EDIT,
      variables: newObj
    })
    .then(async (obj)=>{
      await callBack();
      await window.ForceUserReload();
      //if(this) this.setState({date: new Date()});
      return true;
    })
    .catch((error)=>{
      console.log("error updating user");
      console.log(error,"\n\n");  
      callBack();
    });
  }

  render(){
    var {showMobileMenu} = this.state;
    var isLoggedIn = window.GlobalUtil.getGlobalVariable("isLoggedIn", false);
    if(typeof window === 'undefined') return null;
    if(window.Session && window.Session.user){
      if(window.Session.user.role === "DOCTOR") return(<Routes><Route path="*" element={<Navigate to="/doctor" replace />}/></Routes>);
      if(window.Session.user.role === "USER") return(<Routes><Route path="*" element={<Navigate to="/user" replace />}/></Routes>);
    }
    return (
      <div id="LabWrapper" className="animated fadeIn">
        {
          (!isLoggedIn || !window.Session.user)
          ? null
          : <div id="Lab">
              {
                showMobileMenu && <MobileMenu onHideSideMenu={()=>this.setState({showMobileMenu: false})} onUpdateUser={this.onUpdateUser}/>
              }
              <div id="LabContent">
                <NavMenu />
                <div id="Content">
                  <Header onShowMobileMenu={()=>this.setState({showMobileMenu: true})} onUpdateUser={this.onUpdateUser}/>
                  <Routes>
                    {  
                      PAGES.map((page, index)=>{
                        const CurComp = PAGES_COMPONENTS[page.Name];
                        return page.Paths.map((path, indexSub)=>{                  
                          if(page.Name === "Dashboard"){
                            return(
                              <Route 
                              exact={path} 
                                //strict 
                                key={page.Name+indexSub}
                                path={path} 
                                loader={({ params }) => {
                                  console.log('params', params);
                                  window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                                  this.curPage = page;                  
                                  return {...params}
                                }}
                                element={<CurComp {...this.props} onUpdateUser={this.onUpdateUser}/>} 
                              />
                            )
                          }
                          
                          return <Route 
                            key={page.Name+indexSub}
                            path={path} 
                            loader={({ params }) => {
                              console.log('params', params);
                              window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                              this.curPage = page;                  
                              return {...params}
                            }}
                            element={<CurComp {...this.props} onUpdateUser={this.onUpdateUser}/>}
                          />
                        })
                      })
                    }
                    <Route
                        path="*"
                        element={<Navigate to="/user" replace />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}


export default Index;