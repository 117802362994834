//import React from 'react';
const PAGE = [
  {
    //NUM 0
    "content": "Enter the email associated with your account and we will send you an email with instructions to reset your password."
  },
  {
    //NUM 1
    "content": "Send Instructions"
	},
  {
    //NUM 2
    "content": "Your reset email has been sent"
  },
  {
    //NUM 3
    "content": "Go to your email address to find your reset password instructions. Some emails may take a few minutes to arrive. Please make sure to check your spam and other folders before submitting the request again."
  },
  {
    //NUM 4
    "content": "Thank You"
  },
  {
    //NUM 6
    "content": "Oh no, somthing went wrong",
  },
  {
    //NUM 7
    "content": "It seems our email servers may be down for maintinence. Please try again later to reset your password.",
  },
  {
    //NUM 8
    "content": "Sorry",
  },
  {
    //NUM 8
    "content": "Close",
  }
];




	
export default PAGE;