//import React from 'react';
const PAGE = [
	{
		"type": "div",
		"content": `<h1 class="sectionTitle">Email Confirmation</h1>`
	},
	{
		"content": "Updating"
	},
	{
		"content": "Updating Your Email Confirmation"
	},
	{
		"type": "div",
		"content": `<h4 class="">
      FAILED: Could not confirm email address.
    </h4>`
	},
	{
		"type": "div",
		"content": `<h4 class="">
      SUCCESS: Your Email Address has been confirmed.
    </h4>`
	}
];




	
export default PAGE;