import React from 'react';

function Index(props){
  return(
    <div className="sectionValueWrapper">
      <div className="sectionValue">
        {props.section && <div className="section">
          {props.section}
        </div>}
        {props.value && <div className="value">
          {props.value}
        </div>}
      </div>
    </div>
  )
}

export default Index;
