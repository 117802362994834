import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import USERS from "../USERS/index";
import QUERIES from "./QUERIES";
import MUTATIONS from "./MUTATIONS";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();


const FORM_FIELDS = (obj={})=>{
  const QUERY_GET_DISTINCT = QUERIES.distinct();
  const MUTATION_EDIT = MUTATIONS.update();
  const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  return [
    {
      title: <div className="formTitle"><span className="">ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Blog ID CANT BE CHANGED</span></div>,
      type: "text",
      name: "id",
      required: false, //true
      editable: false,
      defaultValue: "",
      defaultValuePath: "_id",
      hide: true
    },
    {
      title: <div className="formTitle"><span className="">UID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every task</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_USERS,
          variables: {
            "manual": [
              {
                path: "role",
                val: "ADMIN"
              }
            ]
          }
        })
        .then(result => {
          var usersOptions = result.data.users.map((user, index)=>{
            return({
              "value": user._id,
              "title": user.email
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...usersOptions])
          // console.log("usersOptions");
          // console.log(usersOptions,"\n\n");
                
        });
      },
      name: "UID",
      required: false,
      defaultValue: "",
      defaultValuePath: "UID",
      errorMessage: "User ID is required"
    },
    {
      title: <div className="formTitle"><span className="">Post Image</span></div>,
      type: "editableImages",
      name: "mainImage",
      maxNumber: 1,
      required: false,
      defaultValue: "",
      defaultValuePath: "mainImage",
      maxWidth: "150px",
      filePath: [
        "BLOGS",
        obj.id,
        "MAIN_IMAGE"
      ],
      onChangeImage: (index, newImageObj)=>IMAGE_UTILS.onChangeImage(
        index, 
        obj.id,
        newImageObj, 
        "mainImage", 
        MUTATION_EDIT
      )
    },
    {
      title: <div className="formTitle"><span className="">Other Images</span></div>,
      type: "fileUploadList",
      name: "otherImages",
      maxNumber: 10,
      required: false,
      defaultValue: "",
      defaultValuePath: "otherImages",
      maxWidth: "150px",
      filePath: [
        "BLOGS",
        obj.id,
        "OTHER_IMAGES"
      ],
      showCopyURL: true,
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "otherImages", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain()
        })
    },
    {
      title: <div className="formTitle"><span className="">Language</span></div>,
      subTitle: <div className="formSubTitle"><span className="">This is to help filter by language.</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_DISTINCT,
          variables: {
            field: "language" //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          ///console.log("result.data","\n\n",result.data,"\n\n");
                
          var fullOptionList = result.data.blogDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
            {
              "value": "ENGLISH",
              "title": "ENGLISH"
            },
            {
              "value": "ESPAÑOL",
              "title": "ESPAÑOL"
            }
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          window.GlobalUtil.consoleLogNew({LOCATION:`src/API_CALLS/BLOGS/FORM_FIELDS_NEW`, NAME:`uniqueArray`, CONTENT:[uniqueArray]});
          callBack("options", uniqueArray)
        });
      },
      name: "language",
      required: false, //true
      defaultValue: "",
      defaultValuePath: "language",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">URL</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The url link where this post can be found. Should have no capital letters and dashes between words. Example: why-we-dont-go-shopping</span></div>,
      type: "urlPicker",
      name: "url",
      urlAPI: QUERIES.count, //API TO CALL TO FIND IF THIS URL ALREADY EXIST
      urlPATH: "url", //WHAT FIELD IN THE DB WILL WE BE LOOKING FOR THAT MATCHES THIS URL
      urlFIELD: "blogCount", //WHEN THE QUERY RETURNS WHAT IS THE FILE CALLED
      required: true,
      defaultValue: "",
      defaultValuePath: "url"
    },
    {
      title: <div className="formTitle"><span className="">Title</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Comment that shows up for this product.</span></div>,
      type: "text",
      name: "title",
      required: false, //true
      defaultValue: "",
      defaultValuePath: "title",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Quick Summary</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Text that shows up when query all blog posts.</span></div>,
      type: "textArea",
      rows: 6,
      name: "summary",
      required: false, //true
      defaultValue: "",
      defaultValuePath: "summary",
      errorMessage: "Summary is required"
    },
    {
      title: <div className="formTitle"><span className="">Content</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Comment that shows up for this product.</span></div>,
      type: "wizyWig",
      name: "content",
      required: false, //true
      defaultValue: "",
      defaultValuePath: "content",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Categories</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Categories that this post shows up in.</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_DISTINCT,
          variables: {
            field: "category" //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          var fullOptionList = result.data.blogDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            }
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          window.GlobalUtil.consoleLogNew({LOCATION:`src/API_CALLS/BLOGS/FORM_FIELDS_NEW`, NAME:`uniqueArray`, CONTENT:[uniqueArray]});
          callBack("options", uniqueArray)
        });
      },
      name: "category",
      required: false, //true
      defaultValue: "",
      defaultValuePath: "category",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Keywords</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Keywords to find this post.</span></div>,
      type: "wordList",
      name: "keywords",
      formatText: (newWord)=>{
        return newWord.toLowerCase();
      },
      required: false, //true
      defaultValue: "",
      defaultValuePath: "keywords",
      errorMessage: "Comments is required"
    },
    {
      title: <div className="formTitle"><span className="">Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The status of the blog. "ACTIVE" = Start when Date Start is correct.</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "PENDING",
          "title": "PENDING"
        },
        {
          "value": "INACTIVE",
          "title": "INACTIVE"
        }
      ],
      required: false,
      defaultValue: "",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Date Start</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When will the blog start showing</span></div>,
      type: "datePicker",
      name: "duration,startAt",
      required: false, //true
      defaultValue: "",
      defaultValuePath: "duration,startAt"
    },
    {
      title: <div className="formTitle"><span className="">Date End</span></div>,
      subTitle: <div className="formSubTitle"><span className="">When will the blog stop showing</span></div>,
      type: "datePicker",
      name: "duration,endAt",
      required: false,
      defaultValue: "",
      defaultValuePath: "duration,endAt"
    },
    {
      title: <div className="formTitle"><span className="">SEO</span></div>,
      subTitle: <div className="formSubTitle">This is what seo the page will show when loading.</div>,
      type: "conditional",
      condition: (curObj)=>{            
        //if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
        return true;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">BASEURL</span></div>,
          subTitle: <div className="formSubTitle"><span className="">This is to help filter by language.</span></div>,
          type: "select",
          options: [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
            {
              "value": "https://smiles.club",
              "title": "Smiles.Club"
            },
            {
              "value": "https://sonrisas.club",
              "title": "Sonrisas.Club"
            },
          ],
          name: "SEO,BASEURL",
          required: false, //true
          defaultValue: "",
          defaultValuePath: "SEO,BASEURL",
          errorMessage: "This is required"
        },
        {
          title: <div className="formTitle"><span className="">Page Title</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Should be 65 char or less.</span></div>,
          type: "text",
          name: "SEO,TITLE",
          required: false, //true
          maxNumChar: 65,
          defaultValuePath: "SEO,TITLE",
          errorMessage: "Comments is required"
        },
        {
          title: <div className="formTitle"><span className="">Page Description</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Should be 70-155 Char.</span></div>,
          type: "text",
          name: "SEO,DESCRIPTION",
          required: false, //true
          maxNumChar: 155,
          showNumChar: true,
          defaultValuePath: "SEO,DESCRIPTION",
          errorMessage: "Comments is required"
        },
        {
          title: <div className="formTitle"><span className="">SEO Keywords</span></div>,
          subTitle: <div className="formSubTitle"><span className="">We may or maynot want to copy the same keywords as the page keywords.</span></div>,
          type: "wordList",
          formatText: (newWord)=>{
            return newWord.toLowerCase();
          },
          name: "SEO,KEYWORDS",
          required: false, //true
          defaultValue: "",
          defaultValuePath: "SEO,KEYWORDS",
          errorMessage: "Comments is required"
        },
        {
          title: <div className="formTitle"><span className=""></span></div>,
          subTitle: <div className="formSubTitle"><span className=""></span></div>,
          type: "customInput",
          customFunction: (that)=>{            
            function CopyKeywords (){
              //if(!that.props.currentObject.SEO) that.props.currentObject.SEO = {};
              //that.props.currentObject.SEO.KEYWORDS = that.props.currentObject.keywords.slice();
              that.props.onChange("SEO,TITLE", that.props.currentObject.title.slice())
              that.props.onChange("SEO,DESCRIPTION", that.props.currentObject.summary.slice())
              that.props.onChange("SEO,KEYWORDS", that.props.currentObject.keywords.slice())
              that.props.onReload();
            }
            return(
              <div className="">
                <button className="button button1 btn-color5" onClick={CopyKeywords}>Copy Page Keywords</button>
              </div>
            )
          },
          name: "KEYWORDS"
        }
      ]
    }
  ]
};






export default FORM_FIELDS;