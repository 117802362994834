import React from 'react';

const PREVIEW_FORM = [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE": "Request Info",
        "SUB_TITLE": "All the user's info to request a treatment plan from the doctor.",
        "FIELDS": [
          {
            "PATH": "offerRequest,createAt",
            "TYPE": "DATE",
            "NAME": "Create At",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "offerRequest,chiefComplaint",
            "TYPE": "STRING",
            "NAME": "Chief Complaint",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "offerRequest,notes",
            "TYPE": "STRING",
            "NAME": "Additional Notes",
            "DEFAULT": "NULL"
          }
        ]
      }
    ]
  },
  
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE": "User Starting Mouth Pictures",
        "SUB_TITLE": "The user's mouth as it looks before the treatment takes place.",
        "FIELDS": [
          {
            "PATH": "offerRequest,mouthPictures",
            "TYPE": "ARRAY_OBJECT",
            "NAME":"Aligner Files",
            "col": 12,
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12, 
            "xl": 12,
            "STYLE": {paddingBottom: "10px"},
            "FIELDS": [
                {
                "TYPE": "ROW",
                "FIELDS": [
                  {
                    "TYPE": "COLUMN",
                    "STYLE": {margin: "-10px 0",paddingBottom: "0px"},
                    "FIELDS": [
                      {
                        "PATH": "url",
                        "TYPE": "LINK",
                        "LINK_NAME_PATH": "name",
                        "NAME":"File",
                        "DOWNLOAD": true
                      }
                    ]
                  },
                  {
                    "TYPE": "COLUMN",
                    "STYLE": {margin: "-10px 0",paddingBottom: "0px"},
                    "FIELDS": [
                      {
                        "PATH": "createAt",
                        "TYPE": "DATE",
                        "NAME":"Create At"
                      }
                    ]
                  },
                  {
                    "TYPE": "COLUMN",
                    "STYLE": {margin: "-10px 0",paddingBottom: "0px"},
                    "FIELDS": [
                      {
                        "PATH": "type",
                        "TYPE": "STRING",
                        "NAME":"Type"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },

 {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE": "Other Request Files",
        "SUB_TITLE": "Any other files, such as mouth scans and any anything else ones wishes to include for the doctor creating this treatment plan.",
        "FIELDS": [
          {
            "PATH": "offerRequest,otherFiles",
            "TYPE": "ARRAY_OBJECT",
            "NAME":"Aligner Files",
            "col": 12,
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12, 
            "xl": 12,
            "STYLE": {paddingBottom: "10px"},
            "FIELDS": [
              {
                "TYPE": "INLINE_FILE",
                "PATH": "ALL",
                "DOWNLOAD": true
              }
            ]
          }
        ]
      }
    ]
  }
]


export default PREVIEW_FORM;