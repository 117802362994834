import React, { useState, useRef } from 'react';
import NewPatient from 'user/doctor/components/NewPatient/index';



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };    
  }

  render(){
    var {
      showSideMenu, 
      dashboardObj={}
    } = this.state;
    var {data} = this.props;
    return (
      <NewPatient 
        TITLE="Add New Patient"
        customButton={(props)=>{
          return(
            <button className="button button1 btn-color5 bigIconButtons"  onClick={props.onClick}>
              <span>New Patient</span>
              &nbsp;&nbsp;<i className="fas fa-user-injured" style={{"cursor": "pointer"}}></i>
            </button>
          )
        }}
      />
    );
  }
}



export default Index;
      
