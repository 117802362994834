//import React from 'react';
const ESPAÑOL = [
  {
    "content": `Edit Shipping` //SLIDE IN HEADER TEXT
  },
  {
    "content": `Close` //SLIDE IN BUTTONS TEXT
  },
  {
    "content": `Update` //SLIDE IN BUTTONS TEXT
  },
  {
    "content": `Street`
  },
  {
    "content": `Street address where things will be shipped`
  },
  {
    "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
  },
  {
    "content": `Street 2`
  },
  {
    "content": `You can use this for floor, suite, and unit numbers, etc.`
  },
  {
    "content": `City`
  },
  {
    "content": `What is the locations city`
  },
  {
    "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
  },
  {
    "content": `State`
  },
  {
    "content": `Please choose a state`
  },
  {
    "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
  },
  {
    "content": `Country`
  },
  {
    "content": `What country is this going to`
  },
  {
    "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
  },
  {
    "content": `Zipcode`
  },
  {
    "content": `Please include your zipcode too`
  },
  {
    "content": `Each user needs this for shipping` //ERROR INPUT MESSAGE
  }
];




  
export default ESPAÑOL;
export {ESPAÑOL}