//import React from 'react';
const PAGE = [
  {
    "content": "Plan de tratamiento"
  },
  {
    "content": "Cerrar"
  },
  {
    "content": "Información del paciente"
  },
  {
    "type": "span",
    "content": "<span style=\"color:red,font-weight:700\">ADVERTENCIA ESTO HA SIDO RECHAZADO</span> - Notas de rechazo"
  },
  {
    "content": "El texto a continuación DEBERÍA explicar por qué se le devolvió esto. Solucione cualquier problema y envíelo de nuevo."
  },
  {
    "content": "Paciente"
  },
  {
    "content": "Información del plan de tratamiento"
  },
  {
    "content": "Plan de tratamiento PDF"
  },
  {
    "content": "Archivos"
  },
  {
    "content": "Archivos de escaneo de la boca"
  },
  {
    "content": "Guardar"
  },
  {
    "content": "Queja principal"
  },
  {
    "content": "Otras notas"
  }
];




  
export default PAGE;