import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import SectionValue from 'user/normal/components/SectionValue/index';
//import ProgressIcon from 'global/img/progress_icon.svg';
import {NavLink, Link} from 'react-router-dom';
//import {Product, GetProducts} from 'user/normal/components/Products/index';
//import EditableImage from 'global/components/EditableImage/index';
import RequestPlan from 'user/normal/pages/treatmentPlan/requestPlan/index'; 
import Step4 from 'global/img/infoVectors/Step4.jpg';
import Loading from 'global/components/Loading/index';

import AlignerScheduleZone from './AlignerScheduleZone/index';

import CONTENTS from "./CONTENTS";
//const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();

import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;


/*
TODO: 
//ON UPLOAD ALL IMAGES 
  this.props.APICompleteTask(TASKID)

//USER_PURCHASE_TREATMENT_PLAN
  this.props.APICompleteTask(TASKID)


  -CLEAN UP PREVIEW TREATMENT PLAN ADMIN SIDE
  -ADD UPLOAD IMAGE OPTION FOR EACH STEP
  -UPLOAD TO LIVE
  -UPDATE ALL LIVE

  -MAKE SPANISH ENGLISH COMPATABLE
*/



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alignerScheduleEventsPast: undefined,
      alignerScheduleEventsUpcomming: undefined,
      loading: false
    }; 
    this.checkIfShouldAllowNewProgressImage = this.checkIfShouldAllowNewProgressImage.bind(this);   
  }

  componentDidMount(){
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    var treatmentPlan = (currentUser.treatmentPlan ? currentUser.treatmentPlan : {});
    if(treatmentPlan && treatmentPlan._id && treatmentPlan.OID &&  treatmentPlan.alignersSchedule){
      var Today = new Date();
      var alignersSchedule = JSON.parse(JSON.stringify(treatmentPlan.alignersSchedule));
      
      alignersSchedule.alignerHistory = alignersSchedule.alignerHistory.map(obj=>{
        var StartedTime = new Date(Number(obj.startedAt));
        obj.month =  StartedTime.getMonthName().slice(0,3);
        obj.date =  StartedTime.getDate();
        obj.dateTime =  StartedTime.getTime().toString();
        obj.dateString =  StartedTime.formatDate("n/d/y");
        obj.done = (Today.getTime() > StartedTime.getTime()) ? true : false;
        return(obj)
      })
  
  
      var alignerScheduleEventsPast = alignersSchedule.alignerHistory.filter(obj=>(obj.done === true)).slice();
      var alignerScheduleEventsUpcomming = alignersSchedule.alignerHistory.filter(obj=>(obj.done !== true)).slice();
      
      this.setState({
        alignersSchedule,
        alignerScheduleEventsPast,
        alignerScheduleEventsUpcomming
      })
    }
    
  }

  componentWillUnmount(){
  }


  checkIfShouldAllowNewProgressImage(curProgressPictures, allowNewProggressImage){
    if(!curProgressPictures || curProgressPictures.length < 2) return true;
    if(curProgressPictures && curProgressPictures.length > 0 && curProgressPictures[curProgressPictures.length-1].createAt){
      var lastImageTime = new Date(Number(curProgressPictures[curProgressPictures.length-1].createAt));
      lastImageTime.setDate(lastImageTime.getDate()+5); //Move forward 1 week
      var todayDateString = new Date().getTime();
      if(todayDateString >= lastImageTime.getTime()) allowNewProggressImage = true;
    }
    return allowNewProggressImage;
  }


  onStartTreatmentPlan = () => {
    this.setState({loading: true});
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    window.Client.mutate({ //API UPDATE
      mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.startTreatmentPlan(),
      variables: {
        id: currentUser.TPID,
        started: `${Date.now()}`
      }
    })
    .then(async (obj)=>{
      await window.ForceUserReload();
      window.GlobalUtil.delay(2000).then(()=>{
        window.location.reload();
      })
    })
    .catch((error)=>{
      console.log("error updating user");
      console.log(error,"\n\n");  
    });
  }

  render(){
    var {alignersSchedule={alignerHistory: []}, alignerScheduleEventsPast=null, alignerScheduleEventsUpcomming=null, loading} = this.state       
    
    var alignerActiveEvent = (alignerScheduleEventsPast ? alignerScheduleEventsPast.pop() : null);
    if(!alignerScheduleEventsPast || !alignerScheduleEventsPast.length) alignerScheduleEventsPast = null;
    if(!alignerScheduleEventsUpcomming || !alignerScheduleEventsUpcomming.length) alignerScheduleEventsUpcomming = null;
    
    var fileList = [];
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    currentUser.id = currentUser._id;
    var {userPicture=[], progressPictures=[], email={}, firstName, lastName, phone, confirmedEmail, id, userFlowStatus=1} = currentUser;
    var phoneArray = (phone ? window.GlobalUtil.formatPhone(phone) : false);
    var nextPaymentDue = Date.now();//window.GlobalUtil.deepGetFromString(data, "meta,createAt", null);
    if(nextPaymentDue) nextPaymentDue = `${window.GlobalUtil.dateBasic(nextPaymentDue, "DAY")}`
    var allowNewProggressImage = this.checkIfShouldAllowNewProgressImage(progressPictures.slice(), false);
    var boughtPlan = false;         
    //console.log(`currentUser`,"\n\n",currentUser,"\n\n");
    
    if(currentUser.treatmentPlan && currentUser.treatmentPlan.status === "ACTIVE") boughtPlan = true;
    
    var treatmentPlan = (currentUser.treatmentPlan ? currentUser.treatmentPlan : {});
    var TP_STEP = treatmentPlan.step;
    //if(currentUser.userFlowStatus === "ACTIVE") boughtPlan = true;
    
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    window.GlobalUtil.consoleLogNew({LOCATION:`src/user/normal/pages/dashboard/index.js`, NAME:`treatmentPlan`, CONTENT:treatmentPlan});
    const UserPictureURL = window.GlobalUtil.getImageUrl({sitefile:(userPicture ? userPicture[0] : undefined), boxSize:"300x300"});
    //TP_STEP = "FINISHED";
    const confirmDic = {
      "ENGLISH": "Yes",
      "ESPAÑOL": "Si"
    }
    if(loading) {
      return(
        <div id="UserHome" className="scrollZone">
          <div className="">
            <Container className="" fluid="true">
              <Row className="">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                  <h1 className="pageHeader"><i className="fas fa-tachometer-alt"></i> {CONTENT.UserHome[0]}</h1>
                </Column>
              </Row>
            </Container> 
            <Container className="" fluid="">
              <Row className="">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                  <Loading 
                    floating="true"
                    header={"Starting"}
                    body={"Creating your plan"}
                  />
                </Column>
              </Row>
            </Container> 
          </div>
        </div>
      )
    }
    return (
      <div id="UserHome" className="scrollZone">
        <div className="">
          <Container className="" fluid="true">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <h1 className="pageHeader"><i className="fas fa-tachometer-alt"></i> {CONTENT.UserHome[0]}</h1>
              </Column>
            </Row>
          </Container> 
          <Container className="" fluid="true">        
            <Row className="">
              <Column className="">
                <div className="component">
                  <Row className="">
                    <Column className="" col="12" xs="12" sm="12" md="4" lg="3" xl="2">
                      <div className="userImage">
                        <NavLink className={"notification"} onClick={()=>{}} to={"/user/profile"}>
                          <img src={UserPictureURL[0]} />
                        </NavLink>
                      </div>
                    </Column>
                    <Column id="AccountSummary" className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
                      <div className="fullHeight overflowRightScroll">
                        <div className="itemHeader">
                          <div className="itemHeaderTextZone">
                            <div className="itemHeaderText withIcon">
                              <div className="text">{CONTENT.AccountSummary[0]}</div>
                              {/* <NavLink className={"notification"} onClick={()=>{}} to={"/user/profile"}>
                                <i className="fas fa-pencil-alt" ></i>
                              </NavLink> */}
                            </div>
                          </div>
                        </div>
                        <div className="sectionValueZone">
                          <SectionValue
                            section={CONTENT.AccountSummary[1]}
                            value={`${firstName} ${lastName}`}
                          />
                          <SectionValue
                            section={CONTENT.AccountSummary[2]}
                            value={phoneArray && (`(${phoneArray[0]}) ${phoneArray[1]} - ${phoneArray[2]}`)}
                          />
                          <SectionValue
                            section={CONTENT.AccountSummary[3]}
                            value={email}
                          />
                          {/* <SectionValue
                            section={CONTENT.AccountSummary[3]}
                            value={confirmedEmail ? confirmDic[window.Session.Language] : "No"}
                          /> */}
                          <SectionValue
                            section={CONTENT.AccountSummary[4]}
                            value={
                              (treatmentPlan && treatmentPlan.OID)
                              ? <NavLink className={"notification"} onClick={()=>{}} to={"/user/treatmentplan"}>
                                  {CONTENT.AccountSummary[5]}
                                </NavLink>
                              : <span className="">
                                  To get started <a href="/buy" target="_blank">purchase a treatement plan.</a>
                                </span>
                              
                            }
                          />
                          {/* <SectionValue
                            section={CONTENT.AccountSummary[6]}
                            value={(currentUser.purchasedPlan ? confirmDic[window.Session.Language] : "No")}
                          /> */}
                        </div>
                      </div>
                    </Column>
                  </Row>   
                </div>
                <br/>
              </Column>
            </Row>   
          </Container>      
          <Container className="" fluid="true">      
            {
              !treatmentPlan.OID
              ? <div className="component text-center orderTreatmentPlanMessage">
                  <h3>Buy Treatment Plan</h3> 
                  <p className="">To get started on your aligner journey purchase a treatement plan</p>
                  <NavLink className={"button button1"} onClick={()=>{}} to={"/buy"}>
                    Buy Now
                  </NavLink>
                </div>
              : null
            }                          
            {
              treatmentPlan.OID
              ? (TP_STEP === "REQUEST")
                ? <RequestPlan/>
                : null
              : null
            }
            {
              treatmentPlan.OID
              ? (
                  (TP_STEP === "UPLOAD") ||
                  (TP_STEP === "APPROVE") ||
                  (TP_STEP === "PURCHASE") ||
                  (TP_STEP === "REQUEST_FULL") ||
                  (TP_STEP === "UPLOAD_ALIGNERS") ||
                  (TP_STEP === "SCHEDULE_ALIGNERS")
                )
                ? <Row className="">
                    <Column className="matchHeight" style={{"minWidth": "300px"}} col="" xs="" sm="" md="" lg="" xl="">
                      <div className="fullHeight component">
                        <div className="itemHeader d-flex text-center">
                          <div className="itemHeaderTextZone">
                            <div className="itemHeaderText">{CONTENT.CreatingTreatmentPlan[0]}</div>
                            <div className="itemHeaderSubText">{CONTENT.CreatingTreatmentPlan[1]}.</div>
                          </div>
                        </div>
                        <div className="image" style={{"maxWidth": "300px", "margin":"auto"}}>
                          <img src={Step4} alt=""/>
                        </div>
                      </div>
                    </Column>
                  </Row>
                : null
              : null
            }
            {
              treatmentPlan.OID
              ? ((TP_STEP === "ACTIVE") || (TP_STEP === "FINISHED"))
                ? <AlignerScheduleZone 
                    alignersSchedule={alignersSchedule}
                    alignerActiveEvent={alignerActiveEvent}
                    alignerScheduleEventsPast={alignerScheduleEventsPast}
                    alignerScheduleEventsUpcomming={alignerScheduleEventsUpcomming}
                    onStartTreatmentPlan={this.onStartTreatmentPlan}
                  />
                : null
              : null
            }
          </Container>
        </div>
      </div>
    );
  }
}







export default Index;