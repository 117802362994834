import React, { useState, useRef } from 'react';
//import { FacebookProvider, LoginButton, Login } from 'react-facebook';
import SignUp from 'global/components/SignUp/index';
import FacebookIcon from 'global/img/facebookLogo2.svg';
import {LoginWith3rdPary} from 'global/utils/helper-functions';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

const Index = (props) => {
  var {
    buttonText="Continue with Facebook",
    autoCreate=true
  } = props;
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  return(
    <button className="facebookButton" onClick={()=>{
      window.FB.login(function(response) {              
        if (response && response.authResponse && response.authResponse.accessToken) {
          console.log(`Facebook Login success`);
          //console.log(`response`,"\n\n",response,"\n\n");
          LoginWith3rdPary({
            token: response.authResponse.accessToken, 
            type: "FACEBOOK", 
            autoCreate: autoCreate,
            callBackSuccess: (login)=>{
              if(props.onSuccess) props.onSuccess(login)
            }, 
            callBackFailed: ()=>{
              if(props.onFailed) props.onFailed()
            }
          });
        } else {
         console.log('User cancelled login or did not fully authorize.', response);
        }
      });
    }}>
    <div className="icon"><img src={FacebookIcon} alt="" /></div>
    <div className="text">{buttonText}</div>
  </button>
  )
}

export default Index;