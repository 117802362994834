//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_QUERY_FIELDS, DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT, FULL_MUTATION_FIELDS } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation Update(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateUser(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreateUser(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    signup(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      token
      user{
        _id
        ${whatToPull}
      }
    }
  }
`;


const MUTATION_ADD_PATIENT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreatePatient(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createPatient(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteUser(
    $id: String,
  ) {
    deleteUser(id: $id)
  }
`;



const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneUser(
    $id: String,
  ) {
    cloneUser(id: $id){
      _id
    }
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteUserList(
    $ids: [String]
  ) {
    deleteUserList(ids: $ids)
  }
`;


const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneUserList(
    $ids: [String]
  ) {
    cloneUserList(ids: $ids)
  }
`;


const FORGOT_PASSWORD = (whatToPull=FULL_MUTATION_FIELDS())=>gql`
  mutation ForgotPassword(
    $email: String,
    $website: String,
    $language: String
  ) {
    forgotPassword(
      input: {
        email: $email,
        website: $website,
        language: $language
      }
    )
  }
`;


const RESET_FORGOTTEN_PASSWORD = (whatToPull=FULL_MUTATION_FIELDS())=>gql`
  mutation ResetForgottenPassword(
    $token: String,
    $newPassword: String
  ) {
    resetForgottenPassword(
      input: {
        token: $token,
        newPassword: $newPassword
      }
    )
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateUser(
    $id: String,
  ) {
    deactivateUser(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateUserList(
    $ids: [String]
  ) {
    deactivateUserList(ids: $ids)
  }
`;


const MUTATIONS = {
  update: MUTATION_EDIT,
  create: MUTATION_ADD,
  forgotPassword: FORGOT_PASSWORD,
  resetForgottenPassword: RESET_FORGOTTEN_PASSWORD,

  createPatient: MUTATION_ADD_PATIENT,

  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;