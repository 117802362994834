import React from 'react';
import "styles/scss/doctor/pages/dashboard.scss";

import OrderList from 'user/doctor/components/OrderList/index';
import InvoiceList from 'user/doctor/components/InvoiceList/index';
import NewPatient from 'user/doctor/components/NewPatient/index';

import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";

import LayoutBuilder from 'LayoutBuilder';

const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = LayoutBuilder;


const Index = (props) => {
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  return (
    <div id="DoctorHome" className="scrollZone">
      <div className="">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="headerButton">
                <h1 className="pageHeader"><i className="fas fa-tachometer-alt"></i> {CONTENT[0]}</h1>
                <NewPatient DOCID={window.Session.user._id}/>
              </div>
            </Column>
          </Row>
        </Container> 
        <Container className="" fluid="true">
          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader d-flex">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[1]}</div>
                  </div>
                </div>
                <OrderList 
                  CurRefetchListVar={"CurRefetchListVar1"}
                  stepOptionsArray={["REQUEST"]}
                 />
              </div>
            </Column>
          </Row>

          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader d-flex">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[2]}</div>
                  </div>
                </div>
                <OrderList 
                  CurRefetchListVar={"CurRefetchListVar1"}
                  stepOptionsArray={["UPLOAD"]}
                 />
              </div>
            </Column>
          </Row>

          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader d-flex">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[3]}</div>
                  </div>
                </div>
                <OrderList 
                  CurRefetchListVar={"CurRefetchListVar1"}
                  stepOptionsArray={["APPROVE"]}
                 />
              </div>
            </Column>
          </Row>

          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader d-flex">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[4]}</div>
                  </div>
                </div>
                <InvoiceList 
                  CurRefetchListVar={"CurRefetchListVar4"}
                  stepOptionsArray={["PURCHASE"]}
                 />
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  );
}








export default Index;