import React from 'react';
import User from 'user/normal/index';
import Doctor from 'user/doctor/index';
import Lab from 'user/lab/index';
import Admin from 'admin/index';
import Loading from 'global/components/Loading/index';
import {  Routes, Route } from "react-router-dom";
import { gql, useQuery, useMutation } from '@apollo/client';
import {GetFullUserInfo} from 'global/utils/helper-functions';
import withNavigateHook from 'global/components/WithNavigateHook';




class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideMenu: false,
      loading: true
    };    
  }
  
  componentDidMount(){
    //console.log('backed Wrapper componentDidMount');
    this.ClientEventKey = window.GlobalUtil.subscribeEventOnce("ClientEvent", readyToGo=>{
      //console.log('ClientEvent');
      
      GetFullUserInfo({
        noCache: true,
        callBackSuccess: this.isLoggedIn,
        callBackFailed: this.redirectPage,
      });
    });
    if(window.Session.user && !window.Session.user.treatmentPlan) window.Session.user.treatmentPlan = {};

    if(window.Client){      
      if(!window.Session.user.ID || (window.Session.user.userPicture === undefined)) GetFullUserInfo({ //IF NO window.Session.user.userPicture THEN WE ONLY GOT THE BASIC USER, NOT THE FULL. SO WE NEED TO RUN, GET THE FULL USER ASAP.
        noCache: true,
        callBackSuccess: this.isLoggedIn,
        callBackFailed: this.redirectPage
      });
      else this.checkLoggedIn();
    }
  }

  componentWillUnmount(){
    if(this.ClientEventKey) this.ClientEventKey.unsubscribe();
    if(this.redirectTimout) clearTimeout(this.redirectTimout);
  }
      
  redirectPage = ()=>{
    if(typeof window === 'undefined'){ //IF REACT SNAP THEN DON'T CHECK LOGGED IN
      this.isLoggedIn();
      return true;
    }  
    if(this.redirectTimout) clearTimeout(this.redirectTimout);
    window.GlobalUtil.consoleLogNew({LOCATION:`src/BackendWrapper.js`, NAME:`BackendWrapper redirectPage Logout`, CONTENT:[]});
    this.setState({loading: true});
    this.props.navigation('/login');
  }
    
  checkLoggedIn = ()=>{          
    if(typeof window === 'undefined'){ //IF REACT SNAP THEN DON'T CHECK LOGGED IN
      this.isLoggedIn();
      return true;
    }    
    var isLoggedInNow = window.GlobalUtil.getGlobalVariable("isLoggedIn", false);
    //console.log('isLoggedInNow', isLoggedInNow);
    
    if(isLoggedInNow) return this.isLoggedIn();

    if(this.redirectTimout) clearTimeout(this.redirectTimout);
    this.redirectTimout = setTimeout(()=>{ //REDIRECT AFTER 6 SECONDS UNLESS THEY CLEAR THIS TIMEOUT
      var isLoggedInNow = window.GlobalUtil.getGlobalVariable("isLoggedIn", false);
      if(!isLoggedInNow) this.redirectPage();
    }, (window.GlobalVar.NumberOfDBConnectRetrys*window.GlobalVar.TimeBTDBConnectRetrys));
  }


  isLoggedIn = () => {    
    if(this.redirectTimout) clearTimeout(this.redirectTimout);
    this.setState({loading: false});
  }



  render(){
    var {showSideMenu, loading} = this.state;     
    //console.log('backend wrapper loading', loading);
    
    //return null;
    if(loading) return (
      <Routes>
         <Route path="*" element={
          <Loading
            header={undefined}
            body={undefined}
            floating={true}
            overlay={true}
            inlineStyles={{
              loadingIcon: {
                "maxWidth": "250px"
              }
            }}
          />
         } />
      </Routes>
    )
    return (
      <Routes>
        { (this.props.path==="/user") ? <Route path="*" element={<User {...this.props}/>} /> : null }
        { (this.props.path==="/doctor") ? <Route path="*" element={<Doctor {...this.props}/>} /> : null }
        { (this.props.path==="/admin") ? <Route path="*" element={<Admin {...this.props}/>} /> : null }
        { (this.props.path==="/lab") ? <Route path="*" element={<Lab {...this.props}/>} />  : null }
      </Routes>
    );
  }
}


export default withNavigateHook(Index);
      
