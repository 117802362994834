import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
//import {DEFAULT_MUTATION_FIELDS as DEFAULT_FIELDS_TREATMENT_PLANS} from "../TREATMENT_PLANS/index";
//import {FULL_MUTATION_FIELDS as FULL_MUTATION_FIELDS_TREATMENT_PLANS} from "../TREATMENT_PLANS/index";
//import {DEFAULT_MUTATION_FIELDS as DEFAULT_FIELDS_ORDERS} from "../ORDERS/index";

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";

const DEFAULT_MUTATION_FIELDS = `
  ID
  name
  group
  description
  notesList{
    ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
  }
  legal
  category
  subcategory
  keywords
  startAt
  endAt
  pictures{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  winners
  contCount
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
  active
`;


const FULL_MUTATION_FIELDS = (include)=>{
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  return FullMutation;
};


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;

 
  

const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $group: String,
  $name: String,
  $description: String,
  $notesList: [NotesListItemInput], 
  $legal: String,
  $category: String,
  $subcategory: String,
  $keywords: [String],
  $startAt: String,
  $endAt: String,
  $pictures: [FileObjectInput],
  $winners: [String],
  $meta: MetaInput,
  $active: Boolean
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  group: $group,
  name: $name,
  description: $description,
  notesList: $notesList,
  legal: $legal,
  category: $category,
  subcategory: $subcategory,
  keywords: $keywords,
  startAt: $startAt,
  endAt: $endAt,
  pictures: $pictures,
  winners: $winners,
  meta: $meta,
  active: $active
`;



const CLEAN_FIELDS = { 
  "id": "string",
  "group": "string",
  "name": "string",
  "description": "string",
  "notesList": [
    CLEAN_FIELDS_DEFAULT.NOTESLIST
  ], 
  "legal": "string",
  "category": "string",
  "subcategory": "string",
  "keywords": ["string"],
  "startAt": "string",
  "endAt": "string",
  "pictures": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "winners": ["string"],
  "meta": CLEAN_FIELDS_DEFAULT.META,
  "active": "boolean"
}



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "CONDITION": (curObj)=>{
          if(!curObj.pictures[0]) return false;
          return true;
        },
        "TYPE": "COLUMN",
        "col": 12,
        "xs": 12,
        "sm": 12,
        "md": 2,
        "lg": 2,
        "xl": 2,
        "FIELDS": [
          {
            "NAME": "Image",
            "PATH": "pictures,0,url",
            "TYPE": "IMAGE"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME": "ID"
          },
          {
            "PATH": "name",
            "TYPE": "STRING",
            "NAME": "Name"
          },
          {
            "PATH": "group",
            "TYPE": "STRING",
            "NAME": "Group"
          },
          {
            "PATH": "category",
            "TYPE": "STRING",
            "NAME": "Category",
            "DEFAULT": "FALSE"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "subcategory",
            "TYPE": "STRING",
            "NAME": "Subcategory",
            "DEFAULT": "FALSE"
          },
          {
            "PATH": "keywords",
            "TYPE": "ARRAY_STRINGS",
            "NAME": "Keywords",
            "INROW": true,
            "DEFAULT": [""]
          },
          {
            "PATH": "startAt",
            "TYPE": "DATE",
            "FORMAT": "n/d/Y @ H:mA",
            "NAME": "Starts At"
          },
          {
            "PATH": "endAt",
            "TYPE": "DATE",
            "FORMAT": "n/d/Y @ H:mA",
            "NAME": "Ends At"
          },
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.HTML_FULL({
    "CONDITION": (curObj)=>(curObj.description),
    "PATH": "description",
    "TITLE": "Description",
    "SUB_TITLE": "All about this giveaway",
  }),
  // PREVIEW_FORM_TEMPLATES.NOTES_LIST({
  //   TITLE:"Giveaway Note History",
  //   SUB_TITLE:"Full history of whatever you want.",
  // }),
  PREVIEW_FORM_TEMPLATES.HTML_FULL({
    "CONDITION": (curObj)=>(curObj.legal),
    "PATH": "legal",
    "TITLE": "Legal Info",
    "SUB_TITLE": "Add any legal stuff here. We can use that later for the site.",
  }),
  PREVIEW_FORM_TEMPLATES.PICTURES({
    "CONDITION": (curObj)=>(curObj.pictures && curObj.pictures[0]),
    "PATH": "pictures",
    "TITLE": "Pictures",
    "SUB_TITLE": "Current user mouth condition",
  }),
  PREVIEW_FORM_TEMPLATES.META()  
];


const LIST_HEADER_FIELDS = [
  {
    path: "pictures.0.url",
    type: "IMAGE",
    name: "Image",
    defaultValue: "NONE",
    sortable: false
  },
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    path: "name",
    type: "VALUE",
    name: "Name",
    searchable: true
  },
  {
    path: "contCount",
    type: "VALUE",
    name: "Num Contestants",
    defaultValue: 0
  },
  {
    path: "group",
    type: "VALUE",
    name: "Group",
    searchable: true
  },
  {
    path: "category",
    type: "VALUE",
    name: "Category",
    searchable: true
  },
  {
    path: "subcategory",
    type: "VALUE",
    name: "Subcategory",
    searchable: true
  },
  {
    path: "startAt",
    type: "DATE",
    name: "Starts At",
    defaultValue: ""
  },
  {
    path: "endAt",
    type: "DATE",
    name: "Ends At",
    defaultValue: ""
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  },
];

const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  //if(!curCleanObj.userPicture) delete curCleanObj.userPicture;
  //if(!curCleanObj.newPassword) delete curCleanObj.newPassword; //IF BLANK NEW PASSWORD THEN REMOVE IT. WE MUST ALWAYS HAVE A REAL PASSWORDS
  curCleanObj.active = true;
  if(curCleanObj.group) curCleanObj.group = curCleanObj.group.toUpperCase();
  if(curCleanObj.category) curCleanObj.category = curCleanObj.category.toUpperCase();
  if(curCleanObj.subcategory) curCleanObj.subcategory = curCleanObj.subcategory.toUpperCase();        
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  if(curCleanObj.group) curCleanObj.group = curCleanObj.group.toUpperCase();
  if(curCleanObj.category) curCleanObj.category = curCleanObj.category.toUpperCase();
  if(curCleanObj.subcategory) curCleanObj.subcategory = curCleanObj.subcategory.toUpperCase();        
  if(!curCleanObj.notesList || (curCleanObj.notesList.length < 1)) delete curCleanObj.notesList;
  if(!curCleanObj.winners || (curCleanObj.winners.length < 1)) delete curCleanObj.winners;        
  delete curCleanObj.active;

  return curCleanObj;
}


const GIVEAWAYS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export {
  DEFAULT_MUTATION_FIELDS,
  FULL_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};
export default GIVEAWAYS;