//import React from 'react';
const PAGE = [
	{
		"content": `Help`
	},
  {
    "content": `Contact Form`
  },
  {
    "content": `Info`
  }
];




	
export default PAGE;