import React, { useState, useRef } from 'react';
import SendPWResetEmail from 'global/components/SendPWResetEmail/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const Index = (props) => {
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  return(
    <div className="resetPWButton">
      <a href="#" onClick={(e)=>{
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: CONTENT[1],
          customClass: "frontEndSlideInRight shortSlideInRight",
          width: " ",
          noFooter: true,
          children: (props)=>{                                      
            return(
              <SendPWResetEmail {...props}/>
            )
          }
        });
      }}>
        {props.resetText ? props.resetText : CONTENT[0]}
      </a>
    </div>
  )
}


export default Index;