//import React from 'react';
const PAGE = [
  {
    "content": `Loading`
  },
  {
    "content": `Submitting`
  },
  {
    "type": "div",
    "content": `<div class="preHeader">Password Help</div>`
  },
  {
    "type": "div",
    "content": `<h1 class="sectionTitle">Reset your Password</h1>`
  },
  {
    "type": "div",
    "content": `<div class="">Fill out the following form to reset your password. Remember, for security reasons, your password must be at least 8 digits long.</div>`
  },
  {
    "content": `This Token has expired`
  },
  {
    "content": `It seems the password reset token has expired. Click the link below to request a new one.`
  },
  {
    "content": `This Token has been used`
  },
  {
    "content": `For security purposes, you can only use the reset tokens once. This token has been used. Please click the link below to send a new token link to your email address.`
  },
  {
    "content": `Token not found`
  },
  {
    "content": `In order to reset your password we need a special reset token. No token has been found in this pages URL. Please click the link below to send a new token link to your email address.`
  },
  {
    "content": `Password has been changed`
  },
  {
    "content": `Your password has been successfully changed. You can now login with your new password.`
  },
  {
    "content": `We're having Technical Difficulties`
  },
  {
    "content": `Sorry for the inconvenience but it seems like our servers are having problems. Please come back later and try again.`
  },
  {
    "content": `Reset Passord Here`
  }
];




  
export default PAGE;