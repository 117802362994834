//import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import { gql} from '@apollo/client';

import { DEFAULT_MUTATION_FIELDS, DEFAULT_MUTATION_INPUT_VAR, DEFAULT_MUTATION_INPUT } from './index';


const MUTATION_EDIT = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation UpdateGiveawayCont(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    updateGiveawayCont(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const MUTATION_ADD = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CreateGiveawayCont(
    ${DEFAULT_MUTATION_INPUT_VAR}
  ) {
    createGiveawayCont(
      input: {
        ${DEFAULT_MUTATION_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;



const MUTATION_DELETE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeleteGiveawayCont(
    $id: String,
  ) {
    deleteGiveawayCont(id: $id)
  }
`;


const MUTATION_DELETE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeleteGiveawayContList(
    $ids: [String]
  ) {
    deleteGiveawayContList(ids: $ids)
  }
`;


const MUTATION_CLONE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneGiveawayCont(
    $id: String,
  ) {
    cloneGiveawayCont(id: $id){
      _id
    }
  }
`;

const MUTATION_CLONE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation CloneGiveawayContList(
    $ids: [String]
  ) {
    cloneGiveawayContList(ids: $ids)
  }
`;


const MUTATION_DEACTIVATE = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql` 
  mutation DeactivateGiveawayCont(
    $id: String,
  ) {
    deactivateGiveawayCont(id: $id)
  }
`;

const MUTATION_DEACTIVATE_LIST = (whatToPull=DEFAULT_MUTATION_FIELDS)=> gql`
  mutation DeactivateGiveawayContList(
    $ids: [String]
  ) {
    deactivateGiveawayContList(ids: $ids)
  }
`;


const MUTATIONS = {
  update: MUTATION_EDIT,
  create: MUTATION_ADD,
  delete: MUTATION_DELETE,
  deleteList: MUTATION_DELETE_LIST,
  clone: MUTATION_CLONE,
  cloneList: MUTATION_CLONE_LIST,
  deactivate: MUTATION_DEACTIVATE,
  deactivateList: MUTATION_DEACTIVATE_LIST
}


export default MUTATIONS;