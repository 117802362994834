import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/store.scss';
// import React, { useRef, useEffect } from 'react';
// import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import CONTENTS from "./CONTENTS";
import { useNavigate, useParams } from "react-router-dom";
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
const QUERY = API_CALLS.PRODUCTS.QUERIES.all();


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      loading: true
    };    
    this.getProducts = this.getProducts.bind(this);
    this.ClientEventRef =  window.GlobalUtil.subscribeEvent("ClientEvent", (newValue)=>{ //WAIT UNTIL window.Client EXIST
      window.GlobalUtil.delay(500).then(()=>{
        this.getProducts();
      })
    });
  }

  componentDidMount(){
    if(window.Client) this.getProducts();
  }

  componentWillUnmount(){
    this.ClientEventRef.unsubscribe();
  }

  getProducts(){
    window.Client.query({
      query: API_CALLS.PRODUCTS.QUERIES.all(),
      variables: {
        type: "NORMAL",
        manual: [
          {
            path: "url",
            val: this.props.params.storeId
          },
          {
            path: "status",
            val: "ACTIVE"
          },
          // {
          //   path: "ref",
          //   val: (this.props.API_ref ? this.props.API_ref : "TREATMENT_PLAN")
          // },
          {
            path: "website",
            val: (window.Session.Language === "ENGLISH") ? "SMILES" : "SONRISAS"
          }
        ],
        status: "ACTIVE",
      }
    })
    .then(result => {
      console.log("result");
      console.log(result,"\n\n");
            
      this.setState({product: result.data.products[0], loading: false})
    })
    .catch(()=>{
      console.log("catch");
            
      this.setState({curStatus: "failed", loading: false})
    })
  }


 

  render(){
    var {product={}, loading} = this.state;
    if(typeof window === 'undefined') return <div id="PageStoreSingle" className={`containerSection`}></div>;
    if(loading) return <div id="PageStoreSingle" className="containerSection "></div>;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    const ImagesURL = window.GlobalUtil.deepGetFromString(product, "images", []);      
    var {discount, cost, originalCost} = window.GlobalUtil.productCostCal(product);  
    var savings = (originalCost - (cost-discount))
    
    return (
      <div className="contentPage" id="PageStoreSingle">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <Container className="">
          {/* <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                {CONTENT.PageHeader[0]}
              </div>
            </Column>
          </Row> */}
          {
            product._id
            ? <Row id="SingleProduct" className="">
                <Column className="productColumnWrapper" col="12" xs="12" sm="12" md="10" lg="10" xl="10">
                  <div id="product" className="productLayout1 d-flex">
                    <ProductImage className="d-none d-lg-block d-x-block" Images={ImagesURL} />
                    <div className="productText">
                      <ProductImage className="d-block d-lg-none" Images={ImagesURL} />
                      <div className="productTextPreHeader">
                        <span className="productTextPreHeaderCategories">Categories:</span> Products
                      </div>
                      <div className="productTextName">
                        {product.name}
                      </div>
                      <div className="productTextCost d-flex">
                        <div className="originalPrice">{window.GlobalUtil.convertToMoney(originalCost)}</div>
                        <div className="cost">{window.GlobalUtil.convertToMoney(cost-discount)}</div>
                      </div>
                      {
                        savings
                        ? <div className='savings'> YOU SAVE <strong>{window.GlobalUtil.convertToMoney(savings)}</strong></div>
                        : null
                      }
                      <div className="productTextDesc">
                        {window.GlobalUtil.dangerSet(product.description)}
                      </div>
                      {/*<div className="">
                        Category: {product.category}
                      </div>*/}
                      {
                        (product.includes && (product.includes.length > 0))
                        ?<div className="productIncludes">
                            <hr/> 
                            <div className="includesTitle">What is included: </div>
                            <ul className="includeWrapper">
                              {
                                product.includes.map((includeObj, index)=>{
                                  return(
                                    <li className="includeItem" key={index}>
                                      <div className="includeItemDiv">
                                        {window.GlobalUtil.dangerSet(includeObj)}
                                      </div>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        : null
                      }
                      {
                        CONTENT.Single[1]
                      }
                      <div className="d-none d-lg-block d-x-block">
                        <div className="buttonGroup">
                          <div className='button inputNumber'>1</div>
                          <button className="button button1 dontCloseCart" onClick={()=>{
                            if(this.props.onClickAddToCart){
                              this.props.onClickAddToCart().then(()=>{
                                window.GlobalUtil.addToCart({product: {...product}, replaceAll: true, onlyOne: true}, ()=>{
                                  //window.GlobalUtil.triggerEvent("cartEvent", "show");
                                  this.props.navigation('/checkout')
                                });
                              });
                            } else {
                              window.GlobalUtil.addToCart({product: {...product}, replaceAll: true, onlyOne: true}, ()=>{
                                //window.GlobalUtil.triggerEvent("cartEvent", "show");
                                this.props.navigation('/checkout')
                              });
                            }
                          }}>
                          {
                            CONTENT.Single[0]
                          }
                          </button>
                        </div>
                      </div>
                      <div className="d-flex d-lg-none d-x-none floatingCheckoutBar">
                        <div className="productTextCost d-flex">
                          <div className="originalPrice">{window.GlobalUtil.convertToMoney(originalCost)}</div>
                          <div className="cost">{window.GlobalUtil.convertToMoney(cost-discount)}</div>
                        </div>
                        <div className="buttonGroup">
                          <button className="button button1 dontCloseCart" onClick={()=>{
                            if(this.props.onClickAddToCart){
                              this.props.onClickAddToCart().then(()=>{
                                window.GlobalUtil.addToCart({product: {...product}, replaceAll: true, onlyOne: true}, ()=>{
                                  //window.GlobalUtil.triggerEvent("cartEvent", "show");
                                  this.props.navigation('/checkout')
                                });
                              });
                            } else {
                              window.GlobalUtil.addToCart({product: {...product}, replaceAll: true, onlyOne: true}, ()=>{
                                //window.GlobalUtil.triggerEvent("cartEvent", "show");
                                this.props.navigation('/checkout')
                              });
                            }
                          }}>
                          {
                            CONTENT.Single[0]
                          }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>   
                </Column>
              </Row>
            : null
          }
        </Container>
      </div>
    );
  }
}


class ProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImage: (this.props.Images ? this.props.Images[0] : {})
    };    
  }

  componentDidMount(){    
  }

  componentWillUnmount(){
  }


  render(){
    var {activeImage} = this.state;
    var {Images, className} = this.props;
    return (
      <div className={"productImageWrapper " + className}>
        <div className="productImage">
          <div className="activeImage"><img src={activeImage.url} alt="Sonrisas Club Impression Kit"/></div>
          {
            Images && Images.length
            ? <div className="smollImages">
                {
                  Images.map((imgObj, index)=>{
                    return(
                      <div className={`smollImage ${activeImage===imgObj ? "active" : ""}`} key={index} onClick={()=>{
                        this.setState({activeImage: imgObj})
                       }}>
                        <img src={imgObj.url} alt="Sonrisas Club Impression Kit"/>
                      </div>
                    )
                  })
                }
              </div>
            : null
          }
        </div>
      </div>
    );
  }
}


function ParamsWrapper(props) {
  // Get the userId param from the URL.
  const params = useParams();
  const navigation = useNavigate();
  return(
    <Index {...props} params={params} navigation={navigation} />
  )
}

export default ParamsWrapper;
      

