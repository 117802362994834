//import React from 'react';
const PAGE = [
  {
    "content": `History`
  },
  { 
    "type": "div",
    "className": "itemHeaderText",
    "content": `FINISHED: <span style="font-weight:400">Treament Plan Offers</span>`
  },
  { 
    "type": "div",
    "className": "itemHeaderText",
    "content": `FINISHED: <span style="font-weight:400">Full Treatment Plans</span>`
  }
];




  
export default PAGE;