//import React from 'react';
const PAGE = [
  {
    "content": `Approve`
  }
];




  
export default PAGE;