//import React from 'react';
const PAGE = [
  {
    "content": `Dirección`
  },
  {
    "content":  `Se requiere el nombre completo antes de enviar`
  },
  {
    "content":  `Dirección 2`
  },
  {
    "content":  `Se requiere el nombre completo antes de enviar`
  },
  {
    "content":  `Pueblo / Ciudad`
  },
  {
    "content":  `Se requiere el nombre completo antes de enviar`
  },
  {
    "content":  `Estado`
  },
  {
    "content":  `Se requiere el nombre completo antes de enviar`
  },
  {
    "content":  `País`
  },
  {
    "content":  `Por ahora solo enviamos en el USA.`
  },
  {
    "content":  `Código postal`
  },
  {
    "content":  `Se requiere el nombre completo antes de enviar`
  },
  {
    "content": `Telefono`
  },
  {
    "content": `Incluya su teléfono para que podamos contactarlo con soporte.`
  }
];




  
export default PAGE;