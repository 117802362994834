import React, { useRef } from 'react';
//import React, { useState, useRef } from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from 'global/components/FormBuilder/index';

const FORM_FIELDS = (CONTENT)=>{
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">{CONTENT[0]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[1]}</span></div>,
        type: "text",
        name: "address,street",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,street",
        errorMessage: CONTENT[2]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[3]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[4]}</span></div>,
        type: "text",
        name: "address,street2",
        required: false,
        defaultValue: "",
        defaultValuePath: "address,street2"
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[5]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[6]}</span></div>,
        type: "text",
        name: "address,city",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,city",
        errorMessage: CONTENT[7]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[9]}</span></div>,
        type: "selectState",
        name: "address,state",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,state",
        errorMessage: CONTENT[10]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[11]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[12]}</span></div>,
        type: "text",
        name: "address,country",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,country",
        errorMessage: CONTENT[13]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[14]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[15]}</span></div>,
        type: "text",
        name: "address,zipcode",
        maxNumChar: 5,
        required: true,
        defaultValue: "",
        defaultValuePath: "address,zipcode",
        errorMessage: CONTENT[16]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[17]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[18]}</span></div>,
        type: "phone",
        name: "phone",
        required: true,
        defaultValue: "",
        defaultValuePath: "phone",
        errorMessage: CONTENT[19]
      }
    ]
  )
};





const Index = props => {
  var {CONTENT} = props;
  const formRef = useRef(null);  
  return(
    <button 
      className="button button1" 
      onClick={(e)=>{
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: CONTENT.EditShipping[1],
          closeButtonText: CONTENT.EditShipping[2],
          saveButtonText: CONTENT.EditShipping[3],
          customClass: "frontEndSlideInRight shortSlideInRight",
          width: "700px",
          onSave: async ()=>{
            if(formRef) return await formRef.current.onSubmit()
          },
          children: (thatprops)=>{                                      
            return(
              <FormFunction
                {...{...thatprops, ...props}} 
                ref={formRef}
              />
            )
          }
        });
      }}>
        {CONTENT.EditShipping[0]} <i className="fas fa-user-astronaut"></i>
    </button>
  )
}





class FormFunction extends React.Component {
  constructor(props) {
    super(props);
    this.curObj = {
      address: {...this.props.currentUser.address},
      phone: this.props.currentUser.phone,
      id: this.props.currentUser._id,
      _id: this.props.currentUser._id
    }
    this.state = {
      updateIsValid: false,
      curObj: {...this.curObj}
    };    
  }

  onSubmit = async() => {
    await this.props.onUpdateUser(this.curObj, window.ForceUserReload);
    await this.props.onSubmit();
    return true;
  }

  render(){
    var {CONTENT} = this.props;
    return(
      <div id="RequestJoinAffiliates">
        {CONTENT.EditShipping[4]}
        <FormBuilder
          {...this.props} 
          disableKeySubmit={true}
          initialValues={this.curObj}
          listOfFields={FORM_FIELDS(CONTENT.EditShippingForm)}
          onSubmit={this.onSubmit}
          onChange={newObj => {this.curObj = newObj}}
          onValidate={this.props.onChangeValid}
        />
      </div>
    )
  }
}


export default Index;
      
