//import React from 'react';
const PAGE = [
  {
    //NUM 0
    "content": "Login"
  },
  {
    //NUM 1
    "content": "Login"
	}
];




	
export default PAGE;