//import React from 'react';
const PAGE = [
  {
    //NUM 0
    "content": "Sign Up"
  },
  {
    //NUM 1
    "content": "Sign Up"
	}
];




	
export default PAGE;