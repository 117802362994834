import React, { useState, useRef } from 'react';
import {NavLink, Link} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from 'global/components/FormBuilder/index';
import API_CALLS from 'API_CALLS/index';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curStatus: false
    };    
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  APICheckEmailWorks = (email) => {
    var cleanObj = {email: email};   
    return window.Client.mutate({
      mutation: API_CALLS.USERS.QUERIES.emailchecker(),
      variables: cleanObj
    })
    .then(result => {
      if(result.data.emailchecker) return true;
      return false;
    })
    .catch((error)=>{
      console.log("APICheckEmailWorks SIGNUP FAIL catch", error);
    })
  }

  APICreatUser = (newObj) => {  
    var newObjTemp = {...newObj, website: window.SiteSettings.siteNameAllCaps};
    return window.Client.mutate({
      mutation: API_CALLS.USERS.MUTATIONS.create(),
      variables: newObjTemp
    })
    .then(result => {
      console.log(`APICreatUser result`,"\n\n",result,"\n\n");
      return result.data.signup;
    })
    .catch((error)=>{
      console.log("APICreatUser SIGNUP FAIL catch", error);
    })
  }


  onSubmitContact = () => {
    var {curObj} = this.state;
    const newVar = JSON.parse(JSON.stringify(curObj));
    this.APICheckEmailWorks(newVar.email)
      .then((emailExist)=>{
        if(emailExist) { //IF EMAIL EXIST THEN MARK SUBMIT AS INVALID AND EMAIL AS ERROR                
          curObj.newPassword = '';
          this.setState({
            curObj: curObj,
            resetObj: true
          }, ()=>{
            this.setState({
              resetObj: false,
              emailError: (
                (window.Session.Language === "ESPAÑOL") 
                  ?  "Esta dirección de correo electrónico ya existe"
                  :  "This Email Address already Exist"
                )
            });
          })
        }
        else {
          this.APICreatUser(newVar)
            .then((signup)=>{
              this.formRef.overrideCurrentObject({});
              if(this.props.onSuccess) this.props.onSuccess(signup); //UPDATE THE DATABASE PAGE AREA 
            })
            .catch((error)=>{
              console.log(`error`,"\n\n",error,"\n\n");    
            });
        }
      })
      .catch((error)=>{
        console.log(`error`,"\n\n",error,"\n\n");    
      });
  }

  render(){
    var {curObj={}, curStatus, emailError, resetObj} = this.state;     
    if(resetObj) return null;           
    return (
      <div id={this.props.id} className={this.props.className}>
        <FormBuilder
          ref={e => this.formRef = e}
          initialValues={curObj}
          listOfFields={[
            {
              title: <div className="formTitle"><span className="">{(window.Session.Language === "ESPAÑOL") ? "Nombre" : "First Name"}</span></div>,
              type: "text",
              name: "firstName",
              //placeholder: "First Name",
              required: true
            },
            {
              title: <div className="formTitle"><span className="">{(window.Session.Language === "ESPAÑOL") ? "Apellido" : "Last Name"}</span></div>,
              type: "text",
              name: "lastName",
              //placeholder: "Last Name",
              required: true
            },
            {
              title: <div className="formTitle"><span className="">Email</span></div>,
              type: "email",
              name: "email",
              customError: emailError,
              //checkAvailable: true,
              //placeholder: "Email",
              required: true
            },
            {
              title: <div className="formTitle"><span className="">{(window.Session.Language === "ESPAÑOL") ? "Contraseña" : "Password"}</span></div>,
              type: "password",
              name: "newPassword",
              minChar: 8,
              //placeholder: "",
              required: true
            }
          ]}
          onSubmit={this.onSubmitContact}
          onChange={newObj => {
            this.setState({
              curObj: newObj,
              emailError: ''
            });
          }}
          onValidate={(isValid)=>{
            this.setState({curStatus: isValid});
          }}
        />
        <button className={`button button1 buttonFull ${(!emailError && curStatus) ? '' : 'disabled'}`} onClick={this.onSubmitContact} disabled={!curStatus || emailError}>{this.props.buttonText ? this.props.buttonText : ((window.Session.Language === "ESPAÑOL") ? "Inscribirse" : "Sign Up")}</button>
      </div>
    );
  }
}



export default Index;