import React, { useState, useRef } from 'react';
import "styles/scss/lab/components/newTreatmentPlanOffer.scss";
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import Loading from 'global/components/Loading/index';
import ImageUtils from "global/utils/image-utils";
import FormBuilder from 'global/components/FormBuilder/index';
import RejectionNotes from 'user/lab/components/RejectionNotes/index';

import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const IMAGE_UTILS =  new ImageUtils();








/*
FIRST CREATE NEW USER (PATIENT) (MUST HAVE DOCTOR ID)
THEN AUTO CREATE NEW TREATMENT PLAN FOR THAT USER

THEN UPLOAD ALL THINGS NEEDED FOR TREATMENT PLAN REQUEST
OBJECT TO CREATE NEW PATIENT
{
  DOCID
  firstName
  lastName
}

OBJECT TO CREATE TREATMENT PLAN REQUEST
{
  TPID
  offerRequest{
    chiefComplaint
    notes
    mouthPictures{
    }
    otherFiles{

    }
  }
}

*/




const Index = props => {
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  var OnClick = (e)=>{
    e.preventDefault();
    window.GlobalUtil.triggerEvent("SlideInRightEvent", {
      TITLE: CONTENT[1],
      closeButtonText: CONTENT[2],
      saveButtonText: CONTENT[3],
      customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog treatmentPlanOffer",
      width: "1200px",
      onClose: ()=>{
        if(props.reloadMainList) props.reloadMainList();
      },
      noFooter: true,
      children: (propsChildren)=>{                                      
        return(
          <div className="dialogCustomZone">
            <NewTreatmentPlan 
              {...{...props, ...propsChildren}}
              CONTENT={CONTENT}
            />
          </div>
        )
      }
    });
  }
  return(
    <React.Fragment>
      {
        props.customButtonText
        ? props.customButtonText(OnClick)
        : <div className="button button1 btn-color5 actionButton" onClick={OnClick}>
            <div className="text">{CONTENT[0]}</div>
            <div className="buttonIcon">
              <i className="fas fa-pencil-alt" style={{"cursor": "pointer"}}></i>
            </div>
          </div>
      }
    </React.Fragment>
  )
}











class NewTreatmentPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curTreatmentPlan: this.getFromFullObj({...this.props.curTreatmentPlan}),
      curUser: {...this.props.curUser}
    };    
    window.CurObj = this.getFromFullObj({...this.props.curTreatmentPlan}) 
  }

  componentDidMount(){
  }


  onChange = (newTreatmentPlanObj)=>{
    this.setState({saving: true})
    clearTimeout(this.onChangeTimeout);      
    this.onChangeTimeout = setTimeout(() => {
      this.setState({curTreatmentPlan: newTreatmentPlanObj},
        ()=>{
          this.APIUpdateTreatmentPlanRequest(this.cleanSubmition(newTreatmentPlanObj));
        })
    }, 500);
  }
  
  onValidate = (isValid)=>{
    this.setState({isValid: isValid});
  }

  onDoneLoading = (callBack=()=>{})=>{
    this.setState({loading: false, saving: false, uploading: false}, callBack)
  }


  getFromFullObj = (fullObj)=>{
    return {
      id: fullObj._id,
      _id: fullObj._id,
      alignerFilesTemp: fullObj.alignerFilesTemp,
      HISTORY: fullObj.HISTORY 
    }
  }

  cleanSubmition = (obj)=>{
    var cleanObj = {...obj};
    if(!cleanObj.notes) delete cleanObj.notes;
    if(!cleanObj.planFiles || (cleanObj.planFiles.length === 0)) delete cleanObj.planFiles;
    if(!cleanObj.totalNumOfAligners) delete cleanObj.totalNumOfAligners;
    else cleanObj.totalNumOfAligners = Number(cleanObj.totalNumOfAligners);
    return cleanObj;
  }


  APIGetTreatmentPlan =() => {  
    var {curTreatmentPlan} = this.state;                   
    this.setState({loading: true}, ()=>{
      window.Client.query({
        query: API_CALLS.TREATMENT_PLANS.QUERIES.single(),
        fetchPolicy: "no-cache",
        variables: {
          id: this.state.curTreatmentPlan._id
        }
      })
      .then(result => {
        var NewObj = window.GlobalUtil.stripOutFromObj(result.data.treatmentPlan, "__typename"); 
        NewObj.id = NewObj._id;
        if(!NewObj.id) return;            
        window.CurObj = this.getFromFullObj(NewObj);
        this.setState({
          curTreatmentPlan: this.getFromFullObj(NewObj)
        }, this.onDoneLoading);
      })
      .catch(failed=>{
        console.log(`Failed to get treatment plan`,"\n\n",failed,"\n\n");
        this.onDoneLoading()
      });
    })   
  }


  APIUpdateTreatmentPlanRequest = (objToUpdate) => {  
    this.setState({loading: true})
    return window.Client.mutate({
      mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
      variables: objToUpdate
    })
    .then(data=>{
      this.onDoneLoading()
    })
    .catch(failed=>{
      this.onDoneLoading()
      console.log(`Failed APIUpdateTreatmentPlanRequest`,"\n\n",failed,"\n\n");
    });
  }


  APISubmitTreatmentPlanOffer = () => {  
    this.setState({loading: true});
    var newObj = {
      id: this.state.curTreatmentPlan._id,
      rejectStepNotes:  "",//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      changeStep:  1,//MOVE TO NEXT STEP WHEN DONE
      HISTORY: this.state.curTreatmentPlan.HISTORY 
    }
    window.GlobalUtil.deepSetFromString(newObj, "HISTORY,LABORATORY,UPLOADED_FULL", `${Date.now()}`);
    console.log(`newObj`,"\n\n",newObj,"\n\n");
          
    return window.Client.mutate({
      mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
      variables: newObj
    })
    .then(data=>{
      this.onDoneLoading(window.GlobalUtil.DialogStack.closeAll);
    })
    .catch(failed=>{
      console.log(`Failed to create patient`,"\n\n",failed,"\n\n");
      this.onDoneLoading(window.GlobalUtil.DialogStack.closeAll);
    });
  }


  render(){
    var {CONTENT} = this.props;
    var {isValid, loading, curUser, curTreatmentPlan, saving, uploading} = this.state;                
    var isValidReal = ((curTreatmentPlan.alignerFilesTemp && curTreatmentPlan.alignerFilesTemp.length) ? true : false)
    return (
      <div id="LabEditTreatmentPlan">
        <div className="body animated slideInRight">
          {
            loading
            ? <Loading 
                header={CONTENT[4]}
                body={CONTENT[5]}
                inlineStyles={{
                  "loadingIcon": {
                    "maxWidth": "100px",
                    "paddingBottom": "20px"
                  },
                  "loadingHeader": {
                    "marginBottom": "5px",
                    "fontSize": "40px"
                  },
                  "loadingBody": {
                    "marginBottom": "0px",
                    "fontSize": "20px"
                  }
                }}
                floating={true}
                overlay={true}
              />
            : <React.Fragment>
                {
                  window.GlobalUtil.deepGetFromString(this.props.curTreatmentPlan, "rejectStepNotes", false) && 
                  <RejectionNotes rejectStepNotes={window.GlobalUtil.deepGetFromString(this.props.curTreatmentPlan, "rejectStepNotes", "")} />
                }
                <FormBuilder
                  disableKeySubmit={true}
                  initialValues={curTreatmentPlan}
                  listOfFields={[
                    {
                      title: <div className="sectionDividerBasic"><span className="sectionDividerText">{CONTENT[8]}</span><span className="sectionDividerBorder"></span></div>,
                      type: "sectionDivider",
                      required: false,
                      defaultValue: "",
                      defaultValuePath: ""
                    },
                    {
                      title: <div className="formTitle"><span className="">{CONTENT[9]}</span></div>,
                      subTitle: <div className="formSubTitle"><span className="">{CONTENT[10]}</span></div>,
                      type: "fileUploadList",
                      name: "alignerFilesTemp",
                      maxNumber: 1,
                      required: false,
                      defaultValue: "",
                      defaultValuePath: "alignerFilesTemp",
                      maxWidth: "150px",
                      rename: true,
                      filePath: [
                        "USERS",
                        curTreatmentPlan.UID,
                        "TREATMENT_PLANS",
                        curTreatmentPlan.id,
                        "ALIGNERS"
                      ],
                      functionOnDrop: ()=>{
                        this.setState({uploading: true});
                      },
                      onChange: (newFileList, callBack=()=>{})=>{
                        this.setState({saving: true});
                        IMAGE_UTILS.onChangeFile(
                          newFileList, 
                          curTreatmentPlan.id,
                          "alignerFilesTemp", 
                          API_CALLS.TREATMENT_PLANS.MUTATIONS.update(),
                          window.CurObj,
                          ()=>{
                            this.APIGetTreatmentPlan();
                            //callBack();
                          })
                      }
                    }
                  ]}
                  onSubmit={()=>{}}
                  onChange={this.onChange}
                  onValidate={this.onValidate}
                />
                {
                  uploading &&
                  <Loading 
                    header={CONTENT[11]}
                    body={CONTENT[12]}
                    inlineStyles={{
                      "loadingIcon": {
                        "maxWidth": "100px",
                        "paddingBottom": "20px"
                      },
                      "loadingHeader": {
                        "marginBottom": "5px",
                        "fontSize": "40px"
                      },
                      "loadingBody": {
                        "marginBottom": "0px",
                        "fontSize": "20px"
                      }
                    }}
                    floating={true}
                    overlay={true}
                  />
                }
              </React.Fragment> 
          }
        </div>
        <div id="Footer">
          <div className="buttonGroup split">
            <button className="button button2" onClick={window.GlobalUtil.DialogStack.closeTop}>{CONTENT[13]}</button>
            {
              saving
              ? <button className={`button button1 btn-color4`}>{CONTENT[14]} <i className="fas fa-spinner iconSpin iconWhite"></i></button>
              : <button className={`button button1 btn-color5 ${!isValidReal ? "disabled" : ''}`} onClick={this.APISubmitTreatmentPlanOffer} disabled={!isValidReal}>{CONTENT[15]}</button>
            }
          </div>
        </div>
      </div>
    );
  }
}






export default Index;
      
