const PAGE = {
	"ENGLISH": {
	  "HEADER": {
		  "BASEURL": "https://smiles.club",
		  "TITLE": "Smiles Club | Purchase your Full Clear Aligners Kit Here! No risk invisible aligners",
		  "DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Purchase invisible orthodontics without BPA here.",
		  "KEYWORDS": "Teeth straightening at home"
	  },
	  "CONTENT": {
			"IntroText": [
				{
					"content": `Are Clear Aligners Right For You?`
				},
				{
					"content": `Take our 30-second survey to learn if you're a candidate.`
				},
				{
					"content": `Start`
				}
			],
			"Questions": [
				{
					"type": "span",
					"content": `Have you previously worn braces or invisible aligners?`
				  },
				  {
					"type": "span",
					"content": `How’s your teeth <span className="emph">crowding?</span>`
				  },
				  {
					"type": "span",
					"content": `How’s your teeth <span className="emph">spacing?</span>`
				  },
				  {
					"type": "span",
					"content": `How’s your bite?`
				  },
				  {
					"type": "span",
					"content": `Have you lost all your baby teeth?`
				  },
				  {
					"type": "span",
					"content": `Do you have any existing dental work?`
				  },
				  {
					"type": "span",
					"content": `What is most important to you in an aligner system?`
				  }
			],
			"QuestionsNoHTML": [
				{
					"content": "Have you previously worn braces or invisible aligners?"
				},
				{
					"content": "How’s your teeth crowding?"
				},
				{
					"content": "How’s your teeth spacing?"
				},
				{
					"content": "How’s your bite?"
				},
				{
					"content": "Have you lost all your baby teeth?"
				},
				{
					"content": "Do you have any existing dental work?"
				},
				{
					"content": "What is most important to you in an aligner system?"
				}
			],
			"Steps": [
				{
					"content": `Start`
				},
				{
					"content": `Step 1`
				},
				{
					"content": `Step 2`
				},
				{
					"content": `Step 3`
				},
				{
					"content": `Step 4`
				},
				{
					"content": `Step 5`
				},
				{
					"content": `Step 6`
				},
				{
					"content": `Step 7`
				},
				{
					"content": `Finish`
				}
			],
			"Question1": [
				{
					"content": "Yes"
				},
				{
					"content": "No"
				}
			],
			"Question2": [
				{
					"content": "Mild"
				},
				{
					"content": "Moderate"
				},
				{
					"content": "Excessive"
				},
				{
					"content": "Normal"
				}
			],
			"Question3": [
				{
					"content": "Mild"
				},
				{
					"content": "Moderate"
				},
				{
					"content": "Excessive"
				},
				{
					"content": "Normal"
				}
			],
			"Question4": [
				{
					"content": "Crossbite"
				},
				{
					"content": "Underbite"
				},
				{
					"content": "Overbite"
				},
				{
					"content": "Normal"
				}
			],
			"Question5": [
				{
					"content": "Yes"
				},
				{
					"content": "No"
				}
			],
			"Question6": [
				{
					"content": "BRIDGE WORK"
				},
				{
					"content": "MISSING TEETH"
				},
				{
					"content": "NO"
				},
				{
					"content": "OTHER"
				}
			],
			"Question7": [
				{
					"content": "FAST RESULTS"
				},
				{
					"content": "EASE OF USE"
				},
				{
					"content": "LOW PRICE"
				},
				{
					"content": "CUSTOMER SERVICE"
				}
			],
			"Question8": [
				{
					"type": "div",
					"className": "nextStepText",
					"content": `
						<div class="questionText">Congratulations,<br/> YOU'RE A GREAT CANDIDATE!<small style="font-size: 20px;color: #8c8c8c;width: 15px;display: inline-block;">*</small></div>
						<div class="subTitle">So what do you do next?</div>
						<p class="instruction">Well next you should buy a treatment plan. We'll send you an impression kit to get a copy of what your teeth look like. With that our doctors will be able to create a plan for you.</p>
						<p class="instruction">Remember that if for whatever reason our doctors determine you don't qualify, we'll give you back a 100% refund. <br/><br/><strong>IT'S A RISK FREE PROCESS!</strong></p>
						<small class="finePrint">*Final approval subject to doctors decision based on impression, pictures of customers mouth, and more. Being a Candidate does not mean or imply anyone is 100% guaranteed to qualify.</small>
					`
				}
			],
			"PopupBodyRow4": [
				{
					"type": "div",
					"content": `<h1 class="sectionTitle">Contact Us</h1>`
				},
				{
					"type": "div",
					"content": `<span></span>`
				}
			],
			"PopupButtons": [
				{
					"content": `Cost`
				},
				{
					"content": `You Save`
				},
				{
					"content": `Go to Product`
				}
			]
	  }
  },
  "ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | ¡Adquiere aquí tu kit completo de alineadores transparentes! Alineadores invisibles sin riesgo",
			"DESCRIPTION": "Sonrisas Club proporciona un enderezamiento fácil y hermoso de los dientes desde casa. Compra ortodoncia invisible sin BPA aquí.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"PageStoreLink": [
				{
					"content": `tienda`
				}
			],
			"PageHeader": [
				{
					"type": "div",
					//"className": "d-none",
					"content": `
						<div class="preHeader">Productos</div>
						<h1 class="sectionTitle">Tienda</h1>
						<div class="">Haga clic en el producto para ver su descripción completa y lo que incluye.</div>
						<br/>
					`
				}
			],
			"Product": [
				{
					"content": `Costo`
				},
				{
					"content": `Guardas`
				},
				{
					"content": `Ir al producto`
				}
			]
		}
	}
};


  
export default PAGE;
