//import React from 'react';
const PAGE = [
  {
    "content": `¡Mira quién ganó cada sorteo!`
  },
  {
    "content": `Nuestros ganadores`
  },
  {
    "content": `Abajo, puede ver nuestra lista de ganadores, así como el valor de lo que ahorraron. También puede ver cuánto hemos regalado. Nuestro objetivo es tratar de regalar $100,000 o MÁS en alineadores gratis para fin de año.`
  }
];




  
export default PAGE;