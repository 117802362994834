import React from 'react';
import 'styles/scss/website/pages/about.scss';
import {Helmet} from "react-helmet-async";
import TemplatePage from 'website/templates/templatePage/index.js';
import Selfie from 'global/img/selfie.jpg';
import MainFooter_500 from 'global/img/squareGirlAligner_500.jpg';
import squareGirlDaughter_500 from 'global/img/squareGirlDaughter_500.jpg';
import squareGirlLaptop_500 from 'global/img/squareGirlLaptop_500.jpg';
import squareGirlAlignerHappy_500 from 'global/img/squareGirlAlignerHappy_500.jpg';

import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';

import CONTENTS from "./CONTENTS";
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
class Index extends TemplatePage {
  constructor(props) {
    super(props);
    //this.state = {...this.state,
    //};    
  }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    //var {showSideMenu} = this.state;
    //console.log('this.props', this.props);
    //{CONTENT.Section6[0]}
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PageAbout">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div id="Section1" className='backgroundSVG2'>
          <Container >
            <Row id="Row1" className="sectionRows title">
              <Column className="d-flex d-lg-none imageCenterMDMain" col="12" xs="12" sm="12" md="12" lg="5" xl="6">
                <div id="MainImage">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={Selfie} 
                    alt={CONTENT.Section1[1]}
                  />
                </div>
              </Column>
              <Column className="textColumn" col="12" xs="12" sm="12" md="12" lg="7" xl="6">
                {CONTENT.Section1[0]}
              </Column>
              <Column className="d-none d-lg-block" col="12" xs="12" sm="12" md="12" lg="5" xl="6">
                <div id="MainImage">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={Selfie} 
                    alt={CONTENT.Section1[1]}
                  />
                </div>
              </Column>
            </Row>
          </Container>
        </div>


        <div id="Section2" className='backgroundSVG1'>
          <Container>
            <Row id="Row2" className="sectionRows sectionRowsSmaller">
              <Column className="imageCenterMD" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                <div className="floatingImage floatingImageRight">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={squareGirlDaughter_500} 
                    alt={CONTENT.Section2[1]}
                  />
                </div>
              </Column>
              <Column className="textColumn" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                {CONTENT.Section2[0]}
              </Column>
            </Row>
          </Container>
        </div>


        <div id="Section3">
          <Container>
            <Row id="Row3" className="sectionRows sectionRowsSmaller">
              <Column className="d-flex d-lg-none imageCenter" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                <div className="floatingImage floatingImageLeft">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={squareGirlLaptop_500} 
                    alt={CONTENT.Section3[1]}
                  />
                </div>
              </Column>
              <Column className="textColumn" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                {CONTENT.Section3[0]}
              </Column>
              <Column className="d-none d-lg-block" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                <div className="floatingImage floatingImageLeft">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={squareGirlLaptop_500} 
                    alt={CONTENT.Section3[1]}
                  />
                </div>
              </Column>
            </Row>
          </Container>
        </div>


        <div id="Section4" className='backgroundSVG3'>
          <Container>
            <Row id="Row4" className="sectionRows sectionRowsSmaller">
              <Column className="imageCenterMD" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                <div className="floatingImage floatingImageRight">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={squareGirlAlignerHappy_500} 
                    alt={CONTENT.Section4[1]}
                  />
                </div>
              </Column>
              <Column className="textColumn" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                {CONTENT.Section4[0]}
              </Column>
            </Row>
          </Container>
        </div>



        <div id="Section5">
          <Container>
            <Row id="Row5" className="sectionRows sectionRowsSmaller">
              <Column className="d-flex d-lg-none imageCenter" col="12" xs="" sm="" md="" lg="" xl="">
                <div className="floatingImage floatingImageLeft">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={MainFooter_500} 
                    alt={CONTENT.Section5[1]}
                  />
                </div>
              </Column>
              <Column className="textColumn" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                {CONTENT.Section5[0]}
              </Column>
              <Column className="d-none d-lg-block" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                <div className="floatingImage floatingImageLeft">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={MainFooter_500} 
                    alt={CONTENT.Section5[1]}
                  />
                </div>
              </Column>
            </Row>
          </Container>
        </div>
        <TreatmentPlanBar/>
      </div>
    );
  }
}


export default Index;
      