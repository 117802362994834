import React, { useState, useRef } from 'react';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";
import API_CALLS from 'API_CALLS/index';
//CSS /Users/danielezaldivar/Sites/sonrisas/src/styles/scss/global/FormBuilder.scss


class APIAddProducts extends ExtenderClass { 
  constructor(props) {
    super(props);
    this.state={
      options: [],
      products: []
    };

  }

  onMount = ()=>{
    //window.GlobalUtil.State.set("newOrder", {products: []});
    window.Client.query({
      query: API_CALLS.PRODUCTS.QUERIES.all()
    })
    .then(result => {
      var curObj = window.GlobalUtil.stripOutFromObj(result.data.products, "__typename");
      var Options = curObj.map((product, index)=>{
        return({
          "value": product._id,
          "title": product.name
        })
      });
      this.setState({
        "products": curObj.slice(),
        "options": [{
          "value": "",
          "title": "Choose one",
          "disabled": true
        }, ...Options]
      });
      // console.log("Options");
      // console.log(Options,"\n\n");
    });
  }

  onAddProduct = (newProduct)=>{
    var {selectedProduct=[]} = this.state;  
    newProduct.quantity = 1;
    window.GlobalUtil.addToCart({product: newProduct, replaceAll: true}, ()=>{
      this.setState({selectedProduct: [newProduct]}, ()=>{
        var newOrder = window.GlobalUtil.State.get("newOrder");
        this.addToCurOrder(newOrder.products);
      });
    });
    selectedProduct.push(newProduct)
    //this.props.onChange(this.props.field.name, selectedProduct);    
  }


  addToCurOrder = async (products)=>{       
                
    var {currentObject} = this.props;
    var curOrders = window.GlobalUtil.State.get("newOrder");
    var {coupon} = curOrders;
    const CHECKOUT_TOTAL = window.GlobalUtil.checkoutCalculatorNew(products, coupon);
    var productsList = window.GlobalUtil.APICleanObj(products);  
    var cost = {
      subtotal: (CHECKOUT_TOTAL.subtotal ? Number(Number(CHECKOUT_TOTAL.subtotal).toFixed(2)) : 0),
      discount: (CHECKOUT_TOTAL.discount ? Number(Number(CHECKOUT_TOTAL.discount).toFixed(2)) : 0),
      subtotalMinusDis: (CHECKOUT_TOTAL.subtotalMinusDis ? Number(Number(CHECKOUT_TOTAL.subtotalMinusDis).toFixed(2)) : 0),
      tax: (CHECKOUT_TOTAL.tax ? Number(Number(CHECKOUT_TOTAL.tax).toFixed(2)) : 0),
      setupFee: 0,
      shipping: (CHECKOUT_TOTAL.shipping ? Number(Number(CHECKOUT_TOTAL.shipping).toFixed(2)) : 0),
      total: (CHECKOUT_TOTAL.total ? Number(Number(CHECKOUT_TOTAL.total).toFixed(2)) : 0)
    }  
    //await this.props.onChange("products", productsList);
    await this.props.onChange("productsStringArray", JSON.stringify(productsList));
    await this.props.onChange("cost", cost);
    if(this.props.field.required) this.Validate(productsList);
  }


  APIGetUserTPID = async (UID)=>{
    return await window.Client.query({
      query: API_CALLS.USERS.QUERIES.single("TPID"), 
      variables: {
        id: UID
      }
    })
    .then(result => {
      console.log(`result.data.user`,"\n\n",result.data.user,"\n\n");
            
      return result.data.user.TPID;
    });
  }

  render(){
    var {field={}, id, value='', onTouch, meta} = this.props;
    var {options=[], products=[], selectedProduct=[]} = this.state;
    var {name="", required, title, add} = field;
    if(!options.filter(obj=>(!obj.disabled && obj.value === value))[0]) value = ""; //IF CURRENT VALUE IS NOT ONE OF THE OPTIONS THEN SET CURRENT VALUE TO NOTHING.          
                              
    return(
      <div id="AddProductsFormField">
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="subTitle">Current Products</div>
        <div className="products">
          {
            selectedProduct && selectedProduct.map((product, index)=>{
              return(
                <div className="product" key={index}>
                  <div className="img">
                    <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", "")} alt=""/>
                  </div>
                  <div className="name">
                    {product.name}
                  </div>
                  <div className="cost">
                    {window.GlobalUtil.convertToMoney((product.cost-product.discount)*product.quantity)}
                  </div>
                  {/*<div className="quantity">
                    {product.quantity}
                  </div>*/}
                  {/*<div className="trash">
                    <i className="fas fa-trash" onClick={()=>{
                      window.GlobalUtil.State.set("newOrder", {products: []})
                      this.setState({selectedProduct: []}, ()=>{
                        var newOrder = window.GlobalUtil.State.get("newOrder");
                        this.addToCurOrder(newOrder.products);
                      });
                    }}></i>
                  </div>*/}
                </div>
              )
            })
          }
        </div>
        <div className={`${selectedProduct.length ? "change" : ""}`}>
          <div className="subTitle" >{!selectedProduct.length ? "Add Product" : "Change Product"}</div>
          <select 
            type="select" 
            className={`form-control`}
            value={value}
            name={name}
            onChange={e=>this.onAddProduct(JSON.parse(JSON.stringify(products[e.target.value])))}>
            {
              options.length > 0 && 
              options.map((object, index)=>{
                if(object.disabled) return(<option key={index-1} value={""} disabled>{object.title}</option>)
                return(
                  <option key={index-1} value={index-1}>{object.title}</option>
                )
              })
            }
          </select>
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


export default APIAddProducts;