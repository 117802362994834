import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT } from './index';

  

const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    coupons(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    couponCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    coupons(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
   ${DEFAULT_QUERY_INPUT_VAR} 
  ){
    couponCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query Single($id: String){
    coupon(id:$id){
      _id
      ${whatToPull}
    }
  }
`;



const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    couponDistinct(field:$field)
  }
`;


const QUERY_COUPON_FINDER = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query SingleCoupon($code: String!){
    couponFinder(code: $code){
      _id
      ${whatToPull}
    }
  }`;


const QUERY_COUPON_FINDER_USER = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query CouponFinderByUser($UID: String!){
    couponFinderUser(UID: $UID){
      _id
      ${whatToPull}
    }
  }`;







const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  count: COUNT,
  single: QUERY_SINGLE,
  distinct: QUERY_GET_DISTINCT,
  couponFinder: QUERY_COUPON_FINDER,
  couponFinderUser: QUERY_COUPON_FINDER_USER
}


export default QUERIES;