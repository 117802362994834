import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DEFAULT_QUERY_FIELDS, DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT, FULL_MUTATION_FIELDS, DEFAULT_QUERY_FIELDS_BASIC } from './index';

  

const QUERY = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR}
  ){
    users(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const QUERYANDCOUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR}
  ){
    userCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
    users(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    ){
      _id
      ${whatToPull}
    }
  }
`;


const COUNT = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query GetAll(
    ${DEFAULT_QUERY_INPUT_VAR}
  ){
    userCount(
      query:{
        ${DEFAULT_QUERY_INPUT}
      }
    )
  }
`;


const QUERY_SINGLE = (whatToPull=DEFAULT_QUERY_FIELDS)=> gql`
  query Single($id: String, $include: [Include]){
    user(id:$id, include: $include){
      _id
      ${whatToPull}
    }
  }
`;


const QUERY_GET_DISTINCT = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query ListDistinct($field: String!){
    userDistinct(field:$field)
  }
`;


const QUERY_CHECK_EMAIL_EXIST = (whatToPull=DEFAULT_QUERY_FIELDS) => gql`
  query EmailChecker($email: String!){
    emailchecker(email:$email)
  }
`;



const LOGIN_WITH_TOKEN = (whatToPull=DEFAULT_QUERY_FIELDS_BASIC)=>gql`
  query UserLoginWithToken(
    $token: String!
  ) {
    loginWithToken(
      token: $token
    ){
      token
      user{
        _id
        ${whatToPull}
      }
    }
  }
`;


const LOGIN = (whatToPull=DEFAULT_QUERY_FIELDS_BASIC)=>gql`
  query UserLogin(
    $email: String!,
    $password: String!
  ) {
    login(
      email: $email,
      password: $password
    ){
      token
      user{
        _id
        ${whatToPull}
      }
    }
  }
`;



const LOGIN_3RD_PARTY = (whatToPull=DEFAULT_QUERY_FIELDS_BASIC)=>gql`
  query Login3rdParty(
    $token: String!,
    $type: String!,
    $website: String!,
    $autoCreate: Boolean
  ) {
    login3rdParty(
      token: $token,
      type: $type,
      website: $website,
      autoCreate: $autoCreate
    ){
      token
      user{
        _id
        ${whatToPull}
      }
    }
  }
`;



const ME = (whatToPull=DEFAULT_QUERY_FIELDS_BASIC)=>gql`
  query ME{
    me {
      _id
      ${whatToPull}
    }
  }
`;


const USER_FULL = (whatToPull=FULL_MUTATION_FIELDS())=>gql`
  query UserFull{
    userFull {
      _id
      ${whatToPull}
    }
  }
`;




const QUERIES = {
  all: QUERY,
  allAndCount: QUERYANDCOUNT,
  count: COUNT,
  single: QUERY_SINGLE,
  distinct: QUERY_GET_DISTINCT,
  emailchecker: QUERY_CHECK_EMAIL_EXIST,
  loginWithToken: LOGIN_WITH_TOKEN,
  login: LOGIN,
  userFull: USER_FULL,
  me: ME,
  login3rdParty: LOGIN_3RD_PARTY
}


export default QUERIES;