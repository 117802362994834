import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW from "./PREVIEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
//import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_FIELDS_USER} from "../USERS/index";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_FIELDS_COUPON} from "../COUPONS/index";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  CODE
  UID
  CPNID
  name
  description
  summary
  status
  active
  PASSWORD
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
`;
  // user {
  //   _id
  //   firstName 
  //   lastName 
  //   email 
  // }

const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;

const FULL_MUTATION_FIELDS = (include=["user"])=>{
  var IncludeDic = {
    user: `
      user{
        _id
        firstName 
        lastName 
        email 
        ${""/*DEFAULT_MUTATION_FIELDS_USER*/}
      }
    `,
    coupon: `
      coupon {
        _id
        ${DEFAULT_MUTATION_FIELDS_COUPON}
      }
    `
  };
  var FullMutation = `
    ${DEFAULT_MUTATION_FIELDS}
  `;
  include.map((key, index)=>{
    if(IncludeDic[key]) FullMutation += IncludeDic[key];
  })
  //console.log(`FullMutation`,"\n\n",FullMutation,"\n\n");
        
  return FullMutation;
};




const DEFAULT_MUTATION_INPUT_VAR = ` 
  $ID: String,
  $CODE: String,
  $UID: String,
  $CPNID: String,
  $PASSWORD: String,
  $name: String,
  $summary: String,
  $description: String,
  $status: String,
  $active: Boolean,
  $meta: MetaInput
`;


const DEFAULT_MUTATION_INPUT = ` 
  ID: $ID,
  CODE: $CODE,
  UID: $UID,
  CPNID: $CPNID,
  PASSWORD: $PASSWORD,
  name: $name,
  summary: $summary,
  description: $description,
  status: $status,
  active: $active,
  meta: $meta
`;


const CLEAN_FIELDS = { 
  "ID": "string",
  "CODE": "string",
  "UID": "string",
  "CPNID": "string",
  "PASSWORD": "string",
  "name": "string",
  "summary": "string",
  "description": "string",
  "status": "string",
  //"active": "boolean",
  "meta": CLEAN_FIELDS_DEFAULT.META
}




const LIST_HEADER_FIELDS = [
  {
    type: "VALUE",
    name: "CODE",
    defaultValue: "",
    path: "CODE",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Name",
    defaultValue: "",
    path: "name",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Summary",
    defaultValue: "",
    path: "summary",
    searchable: true
  },
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "VALUE",
    name: "User",
    defaultValue: "",
    path: "user.email"
  },
  {
    type: "VALUE",
    name: "CPNID",
    defaultValue: "",
    path: "CPNID",
    searchable: true
  },
  {
    type: "DATE",
    name: "Create At",
    defaultValue: "",
    path: "meta.createAt"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "",
    path: "status"
  }
];



const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"About",
        "SUB_TITLE":"Id's and who created it",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME":"Aligner ID"
          },
          {
            "PATH": "name",
            "TYPE": "STRING",
            "NAME": "Name",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "summary",
            "TYPE": "STRING",
            "NAME": "Short Summary",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "description",
            "TYPE": "STRING",
            "NAME": "Description",
            "DEFAULT": "NULL"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "className": "borderColor1",
        "TITLE":"Schedule",
        "SUB_TITLE":"Time Information for Aligner",
        "FIELDS": [
          {
            "PATH": "CODE",
            "TYPE": "STRING",
            "NAME": "CODE",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "NAME": "USER ID",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "User", //TITLE OF SLIDE OUT
              "APINAME": "USERS",
              "ID_PATH": "UID"
            },
            "DEFAULT": "NULL"
          },
          {
            "PATH": "CPNID",
            "TYPE": "STRING",
            "NAME": "COUPON ID",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Coupon", //TITLE OF SLIDE OUT
              "APINAME": "COUPONS",
              "ID_PATH": "CPNID"
            },
            "DEFAULT": "NULL"
          },
          {
            "PATH": "PASSWORD",
            "TYPE": "STRING",
            "NAME": "PASSWORD",
            "DEFAULT": "NULL"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME": "Status",
            "DEFAULT": "NULL"
          },
        ]
      },
    ]
  },
  PREVIEW_FORM_TEMPLATES.META()
]



const FINAL_MOD_NEW = (curCleanObj)=>{
  if(curCleanObj.active == undefined) delete curCleanObj.active;
  if(curCleanObj.ID == undefined) delete curCleanObj.ID;
  if(curCleanObj.id == undefined) delete curCleanObj.id;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  if(curCleanObj.active == undefined) delete curCleanObj.active;
  if(curCleanObj.ID == undefined) delete curCleanObj.ID;
  if(curCleanObj.id == undefined) delete curCleanObj.id;
  return curCleanObj;
}


const REFERRALS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default REFERRALS;
export {
  FULL_MUTATION_FIELDS,
  DEFAULT_PREVIEW_FORM,
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
};