import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import Pages from 'admin/pages/index'; //ADD NEW PAGES HERE
import API_CALLS from 'API_CALLS/index';

const TodoNumbersEventFunction = (newObj)=>{        
  var todoNumbers = {...newObj, ...window.GlobalUtil.State.get("todoNumbers")};
  //console.log("todoNumbers","\n\n",todoNumbers,"\n\n");
  
}

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}; 
    window.CurRefetchSideNavNumbers = this.API_ALL_COUNTS;
    window.FlashMenuItem = this.flashMenuItem;
  }
  
  
  componentDidMount(){
    this.RefTodoNumbers = window.GlobalUtil.State.subscribe("todoNumbers", newTodo=>{
      //console.log('newTodo', newTodo)
      this.setState({
        ...(newTodo ? newTodo : {}),
      })
    });
    this.API_ALL_COUNTS();
  }



  componentWillUnmount(){
    clearTimeout(this.TimeoutPointer);
    this.RefTodoNumbers.unsubscribe();
  }


  API_ALL_COUNTS = (QUERY) => {
    window.Client.query({
      query: API_CALLS.REPORTS.QUERIES.getNavCount(),
      variables: {},
      fetchPolicy: "no-cache"
    })
    .then(result => {            
      var newObj = (result.data.reports_nav_count ? JSON.parse(result.data.reports_nav_count) : "")  
      //console.log(`API_ALL_COUNTS reports_nav_count`,"\n\n",newObj,"\n\n");
      console.log(`todoNumbers`,"\n\n",newObj,"\n\n");
            
      window.GlobalUtil.State.set("todoNumbers", newObj);
    })
    .catch((error)=>{
      console.log("API_ALL_COUNTS error", error)
    });
  }


  flashMenuItem = (linkPath) => {
    if(this.TimeoutPointer) clearTimeout(this.TimeoutPointer);
    this.setState({
      menuToToggle: linkPath
    }, ()=>{
      this.TimeoutPointer = setTimeout(()=>{
        this.setState({
          menuToToggle: undefined
        });
      }, 2000);
    })
  }




  countTotal = (arrayToCount) => {
    var reduced = arrayToCount.reduce((lastValue, currentObj)=>{
      var newValue = window.GlobalUtil.deepGetFromString(this.state, currentObj, 0);            
      return(lastValue+newValue)
    }, 0);
    return reduced
  }




  render(){
    var {menuToToggle} = this.state;
    var {onClickLink=()=>{}, MenuToShow, inline} = this.props;
    if(!MenuToShow) return null;
    var PAGES = Pages();          
    const CleanLinks = PAGES.filter(Page=>Page.Menu.indexOf(MenuToShow) !== -1);                          
    
    return(
      <div className={`menu`}>
        {
          CleanLinks.map((Link, index)=>{
            var path = `${Link.Path}`;
            var isActiveLink = ((window.location.pathname.search(path) > -1) ? true : false);
            var todoNumber = 0;                  
            if(Link.GlobalStateVar) todoNumber = this.countTotal(Link.GlobalStateVar);                  
            if(path == "/") return(
              <div className="menu-item" key={index}>
                <NavLink 
                  className={({ isActive, isPending }) =>{
                    var classString = "nav-link";
                    if(isPending) classString += " pending";
                    if(isActive) classString += " active";
                    if((menuToToggle && (menuToToggle === path))) classString += " flash";
                    return classString;
                  }}                
                  onClick={()=>{}} 
                  to={"/admin"}>
                    <div className="inline-nav-link">
                      <div className="icon"><i className={Link.Icon}></i></div>
                      <div className="text">{Link.PageName}</div>
                    </div>
                </NavLink>
                {
                  Link.SubPaths && 
                  <SubMenu
                    menuToToggle={menuToToggle}
                    SubPaths={Link.SubPaths}
                    countTotal={this.countTotal}
                    isActiveLink={isActiveLink}
                  />
                }
              </div>
            )

            return(
              <div className="menu-item" key={index}>
                <NavLink 
                  className={({ isActive, isPending }) =>{
                    var classString = "nav-link";
                    if(isPending) classString += " pending";
                    if(isActive) classString += " active";
                    if((menuToToggle && (menuToToggle === path))) classString += " flash";
                    return classString;
                  }}  
                  onClick={()=>{}} 
                  to={path}>
                    <div className="inline-nav-link">
                      <div className="icon"><i className={Link.Icon}></i></div>
                      <div className="text d-flex">
                        <div className="texttext">{Link.PageName}</div> 
                        {
                          (todoNumber != 0 && todoNumber !== undefined) && 
                          <div className="todoNumber">{todoNumber}</div>
                        }
                      </div>
                    </div>
                </NavLink>
                {
                  Link.SubPaths && 
                  <SubMenu
                    menuToToggle={menuToToggle}
                    CurPath={path}
                    SubPaths={Link.SubPaths}
                    countTotal={this.countTotal}
                    isActiveLink={isActiveLink}
                  />
                }
              </div>
            )
          })
        }
        <div className="menu-item">
          <NavLink 
            className={({ isActive, isPending }) =>{
              var classString = "nav-link";
              return classString;
            }}
            to={(window.Session.Language === "ENGLISH") ? "/" : "/inicio"} 
            onClick={()=>{window.GlobalUtil.logout()}}>
            <div className="inline-nav-link">
              <div className="icon"><i className="fas fa-sign-out-alt"></i></div>
              <div className="text">Logout</div>
            </div>
          </NavLink>
        </div>
      </div>
    );
  }
}



class SubMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: "1000px",
      minHeight: "1000px",
      className: "no-trasition"
    };    
  }

  componentDidMount(){
    this.setState({
      maxHeight: this.WrapperRef.offsetHeight+10+"px",
      minHeight: "0px"
    }, ()=>window.GlobalUtil.delay(500).then(()=>{this.setState({className: ""})}));
  }

  componentWillUnmount(){
  }

  ForceUpdateDB=()=>{
    this.setState({hideAll: true},()=>{
      this.setState({hideAll: false})
    })
  }

  render(){
    var {maxHeight, minHeight, className} = this.state;
    var {SubPaths, isActiveLink, countTotal={}, menuToToggle, CurPath} = this.props; 
    var styles = {
      maxHeight: (isActiveLink ? maxHeight : minHeight),
    }
    return (
      <div className={"sub-menu-wrapper " + className} style={styles} ref={e=>this.WrapperRef=e}>
        {
          SubPaths.map((Link, index)=>{
            var path = `${CurPath}/${Link.Path}`;            
            var isActiveSubLink = ((window.location.pathname.search(path) > -1) ? true : false);                 
            var todoNumber = 0;
            if(Link.GlobalStateVar) todoNumber = countTotal(Link.GlobalStateVar);
            return(
              <div key={index} className="sub-menu-item-wrapper">
                <div className="sub-menu-item">
                  <NavLink 
                    className={({ isActive, isPending }) =>{
                        var classString = "nav-link";
                        if(isPending) classString += " pending";
                        if(isActive) classString += " active";
                        if((menuToToggle && (menuToToggle === path))) classString += " flash";
                        return classString;
                      }} 
                    onClick={()=>{}} 
                    to={path}>
                    <div className="inline-nav-link">
                      <div className="icon"><i className="fas fa-angle-right"></i></div>
                      <div className="text d-flex">
                        <div className="texttext">{Link.PageName}</div> 
                        {
                          (todoNumber != 0 && todoNumber !== undefined) && 
                          <div className={`todoNumber ${Link.UserProblem ? "userNumber" : ""}`}>{todoNumber}</div>
                        }
                      </div>
                    </div>
                  </NavLink>
                  {
                    Link.SubPaths && 
                    <SubSubMenu
                      menuToToggle={menuToToggle}
                      SubPaths={Link.SubPaths}
                      CurPath={path}
                      countTotal={countTotal}
                      isActiveSubLink={isActiveSubLink}
                    />
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}




class SubSubMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: "1000px",
      minHeight: "1000px",
      className: "no-trasition"
    };    
  }

  componentDidMount(){
    this.setState({
      maxHeight: this.WrapperRef.offsetHeight+10+"px",
      minHeight: "0px"
    }, ()=>window.GlobalUtil.delay(500).then(()=>{this.setState({className: ""})}));
  }

  componentWillUnmount(){
  }

  ForceUpdateDB=()=>{
    this.setState({hideAll: true},()=>{
      this.setState({hideAll: false})
    })
  }

  render(){
    var {maxHeight, minHeight, className} = this.state;
    var {SubPaths, isActiveSubLink, countTotal={}, menuToToggle, CurPath} = this.props; 
    var styles = {
      maxHeight: (isActiveSubLink ? maxHeight : minHeight),
    }
    return (
      <div className={"sub-sub-menu-wrapper " + className} style={styles} ref={e=>this.WrapperRef=e}>
        {
          SubPaths.map((Link, index)=>{
            var path = `${CurPath}/${Link.Path}`;
            var todoNumber = 0;
            if(Link.GlobalStateVar) todoNumber = countTotal(Link.GlobalStateVar);
            return(
              <div key={index} className="sub-sub-menu-item-wrapper">
                <div className="sub-sub-menu-item">
                  <NavLink 
                    className={({ isActive, isPending }) =>{
                      var classString = "nav-link";
                      if(isPending) classString += " pending";
                      if(isActive) classString += " active";
                      if((menuToToggle && (menuToToggle === path))) classString += " flash";
                      return classString;
                    }}
                    onClick={()=>{}} 
                    to={path}>
                    <div className="inline-nav-link">
                      <div className="icon"><i className="fas fa-caret-right"></i></div>
                      <div className="text d-flex">
                        <div className="texttext">{Link.PageName}</div> 
                        {
                          (todoNumber != 0 && todoNumber !== undefined) && 
                          <div className={`todoNumber ${Link.UserProblem ? "userNumber" : ""}`}>{todoNumber}</div>
                        }
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}


export default Component;