import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import USERS from "../USERS/index";
import COUPONS from "../COUPONS/index";
import QUERIES from "./QUERIES";

const FORM_FIELDS = (obj={}) => {
  const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  const QUERY_GET_COUPONS = COUPONS.QUERIES.all(`name`);
  const QUERY_GET_REFERRALS = QUERIES.all()
  return(
    [ //defaultValuePath used to set default value form object
      // {
      //   title: <div className="formTitle"><span className="">ID</span></div>,
      //   subTitle: <div className="formSubTitle"><span className="">ID CANT BE CHANGED</span></div>,
      //   type: "text",
      //   name: "id",
      //   required: false,
    
      //   defaultValue: "",
      //   defaultValuePath: "_id",
      //   hide: true
      // },


      {
        title: <div className="formTitle"><span className="">CODE</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What code is used to find this?</span></div>,
        type: "text",
        name: "CODE",
        formatInput: (inputValue)=>{ //MAKE ALL INPUT UPPERCASE
          if(!inputValue) return inputValue;
          if(inputValue.length > 3) inputValue = inputValue.slice(0,3);
          return inputValue.toUpperCase();
        },
        onBlur: ({newValue, currentObject, onChangeValid=()=>{}, newErrorFunction=()=>{}})=>{  
          window.Client.mutate({
            mutation: QUERY_GET_REFERRALS,
            variables: {
              "manual": [
                {
                  path: "CODE",
                  val: newValue
                }
              ],
            }
          })
          .then(result => {
            var referrals = result.data.referrals;
            if(referrals && referrals.length){ //IF CODE EXIST                    
              if(!currentObject._id || (referrals[0]._id !== currentObject._id)){ //IF CODE IS NOT CURRENT CODE
                onChangeValid("CODE", false);
                newErrorFunction("THIS CODE IS BEING USED");
              } else {
                onChangeValid("CODE", true);
                newErrorFunction("");
              }
            } else {
              if(newValue){ //AS LONG AS A CODE EXIST THEN ALLOW
                onChangeValid("CODE", true);
                newErrorFunction("");
              }
            }
            //console.log(`result.data`,"\n\n",result.data,"\n\n");
          })
          .catch((error)=>{
            console.log("API_CALLS.REFERRALS.QUERIES.all FAIL catch", error);
          })
        },
        required: false,
        defaultValue: "",
        defaultValuePath: "CODE"
      },
      {
        title: <div className="formTitle"><span className="">Password</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This password is only used to query and get reports.</span></div>,
        type: "text",
        name: "PASSWORD",
        required: false,
        defaultValue: "",
        defaultValuePath: "PASSWORD"
      },
      {
        title: <div className="formTitle"><span className="">Referral Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Who is getting this? Company, Influencer, etc.</span></div>,
        type: "text",
        name: "name",
        required: false,
        defaultValue: "",
        defaultValuePath: "name"
      },
      {
        title: <div className="formTitle"><span className="">Summary</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This will be our admin quick reference summary of what the deal is.</span></div>,
        type: "textArea",
        name: "summary",
        required: false,
        defaultValue: "",
        defaultValuePath: "summary"
      },
      {
        title: <div className="formTitle"><span className="">Description</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This text will show up when people add the code. So make it clear what they get.</span></div>,
        type: "textArea",
        name: "description",
        required: false,
        defaultValue: "",
        defaultValuePath: "description"
      },
      {
        title: <div className="formTitle"><span className="">User</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Which user is assigned to this referral?</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_USERS
          })
          .then(result => {
            var Options = result.data.users.map((user, index)=>{
              return({
                "value": user._id,
                "title": user.email
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...Options])
            // console.log("Options");
            // console.log(Options,"\n\n");
                  
          });
        },
        name: "UID",
        required: false,
        defaultValue: "",
        defaultValuePath: "UID"
      },
      {
        title: <div className="formTitle"><span className="">Coupon</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Which Coupon is assigned to this referral?</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_COUPONS
          })
          .then(result => {
            var Options = result.data.coupons.map((coupon, index)=>{
              return({
                "value": coupon._id,
                "title": coupon.name
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...Options])
            // console.log("Options");
            // console.log(Options,"\n\n");
                  
          });
        },
        name: "CPNID",
        required: false,
        defaultValue: "",
        defaultValuePath: "CPNID"
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The status of the referral.</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: false,
        defaultValue: "ACTIVE",
        defaultValuePath: "status"
      }
    ]
  )
};




export default FORM_FIELDS;