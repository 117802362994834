//import React, { useState, useEffect } from 'react';
import React from 'react';
import axios from 'axios';
import ESPAÑOL from "./unsubscribe/ESPAÑOL";
import ENGLISH from "./unsubscribe/ENGLISH";
import Loading from 'global/components/Loading/index';
import LayoutBuilder from 'LayoutBuilder';



const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

const {Container, Row, Column} = LayoutBuilder;


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curStatus: "loading"
    };    
    this.onSubmitUpdate = this.onSubmitUpdate.bind(this);
  }

  componentDidMount(){
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    this.userID = urlParams.get('ID');
    this.LoopRef = window.GlobalUtil.retryLoop(this.onSubmitUpdate, 5, 1000, ()=>{
      this.setState({curStatus: "failed"});
    }); //THIS WILL TRY UP TO 5 TIMES. IF IT GETS A RESPONCE THEN IT WILL STOP.
  }

  componentWillUnmount(){
    if(this.LoopRef) this.LoopRef.abort();
  }

  onSubmitUpdate(){
    //console.log("onSubmitUpdate","\n\n","\n\n");
    //console.log("this.userID","\n\n",this.userID,"\n\n");
    if(!this.userID){
      this.setState({curStatus: "failed"});
      return true; //THIS RETURNS TRUE BECAUSE NO MATTER HOW MANY LOOPS WE DO IT WONT CHANGE
    } 
    return axios({
      method: "post",
      url: ((process.env.NODE_ENV === 'development') ? `http://localhost:3001/unsubscribeemail/${this.userID}` : `https://Smiles.Club/unsubscribeemail/${this.userID}`),
      headers: { "content-type": "application/json" },
      data: {userID: this.userID}
    })
    .then(result => {
      //console.log("result","\n\n",result,"\n\n");
      if (result.data) {
        this.setState({curStatus: "success"});
        return true; //THIS MEANS THE API WORKED AND FINISHED
      } else {
        this.setState({curStatus: "failed"});
        return false; //THIS MEANS IT RAN BUT DIDN'T WORK
      }
    })
    .catch(error => {
      return true; //THIS RETURNS TRUE BECAUSE NO MATTER HOW MANY LOOPS WE DO IT WONT CHANGE
    });
  }


  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    var {curStatus} = this.state;          
    //var {showSideMenu} = this.state;
    return (
      <div className="contentPage" id="PageEmailUnsubscribe">
        <Container className="">
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                {CONTENT[0]}
              </div>
            </Column>
          </Row>
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                <UpdateEmailConfirm
                  curStatus={curStatus}
                  CONTENT={CONTENT}
                />
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}








const UpdateEmailConfirm = ({curStatus, CONTENT}) => {
  return (
    <div id="">
      {
        curStatus === "loading" &&
        <div className="">
          <Loading 
            floating="true"
            header={CONTENT[1]}
            body={CONTENT[2]}
          />
        </div>
      }
      {
        curStatus === "failed" && CONTENT[3]
      }
      {
        curStatus === "success" && CONTENT[4]
      }
    </div>
  );
}





export default Index;
      
