import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import FilterSideMenu from 'global/templates/templateLists/filter';
import TableSettings from 'global/templates/templateLists/tableSettings';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
///Users/danielezaldivar/Sites/sonrisas/src/styles/admin/pages/templateHeader.scss
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      selectedItems: []
    };    
    this.AfterInitial = false;
  }
  
  componentDidMount(){
    this.TotalListEventKey = window.GlobalUtil.subscribeEvent("TotalListEvent", total=>{
      if(!this.AfterInitial) return this.setState({total});
      this.setState({
        total: total,
        highlight: true
      }, ()=>{
        if(this.TimoutListener) clearTimeout(this.TimoutListener);
        this.TimoutListener = setTimeout(() => {
          clearTimeout(this.TimoutListener);
          this.setState({
            highlight: false
          });
        }, 2000);
      });
    });
  
    this.SelectedListEventKey = window.GlobalUtil.subscribeEvent("SelectedListEvent", selectedItems=>{
      this.setState({
        selectedItems: selectedItems
      });
    });
    
    window.GlobalUtil.delay(2000).then(()=>{
      this.AfterInitial = true;
    })
  }

  componentWillUnmount(){          
    this.TotalListEventKey.unsubscribe();
    this.SelectedListEventKey.unsubscribe();
    clearTimeout(this.TimoutListener);
  }


  render(){
    var {TITLE, SUBTITLE, NEWBUTTON, DELETEBUTTON, CLONEBUTTON, INACTIVEBUTTON, SearchBox} = this.props;
    var {total, highlight, selectedItems} = this.state;
    return (
      <div id="TemplateHeader">
        <div className="textZone" col="" xs="" sm="" md="" lg="" xl="">
          <div className="textArea">
            <div className="title">{TITLE}</div>
            {SUBTITLE && <div className="subtitle">{SUBTITLE}</div>}
            <div className={`found ${highlight ? "highlight" : ''}`}>Found: <span className="totalNumber">{total}</span></div>
          </div>
        </div>
        <div className="buttonZone" col="" xs="" sm="" md="" lg="" xl="">
          <div className="buttonGroupWrapper">
            <div className="buttonGroup">
              <SearchBox />
              {
                NEWBUTTON 
                ? NEWBUTTON.CUSTOM
                  ? NEWBUTTON.CUSTOM
                  : <button 
                      style={{"maxWidth":"none"}}
                      className={`sideButton button button1`} 
                      onClick={()=>{
                        window.GlobalUtil.triggerEvent("TemplateDBEvent", {
                          TYPE: "NEW",
                          ...NEWBUTTON
                        });
                      }}><span>New {NEWBUTTON.TITLE}</span> <i className="fas fa-plus"></i>
                    </button>
                : null
              }
              {/*<button className={`sideButton button button2`} 
                onClick={()=>{
                  window.GlobalUtil.triggerEvent("ToggleFilterEvent", true);
                }}><span>Filter List </span> <i className="fas fa-filter"></i>
              </button>*/}
              <FilterSideMenu />
              {
                (DELETEBUTTON && (selectedItems.length > 0)) && 
                <button 
                  className={`sideButton button deleteButton ${selectedItems.length < 1 ? "disabled" : ""}`} 
                  onClick={()=>{
                    var confirmation = window.confirm(DELETEBUTTON.CONFIRMMESSAGE ? DELETEBUTTON.CONFIRMMESSAGE : "Are you sure you want to delete this?");
                    if (confirmation == true) {
                      var inputObj = {
                        mutation: (!DELETEBUTTON.REMOVE ? API_CALLS[DELETEBUTTON.NAME].MUTATIONS.deleteList() : API_CALLS[DELETEBUTTON.NAME].MUTATIONS.deleteRemoveList()),
                        variables: {
                          ids: selectedItems
                        }
                      }
                      window.Client.mutate(inputObj)
                      .then((obj) => {
                        var {data, loading} = obj;
                        window.GlobalUtil.triggerEvent("SelectedListEvent", []);
                        if(window.CurRefetchList) window.CurRefetchList(true);
                        if(DELETEBUTTON.ONSUCCESS) DELETEBUTTON.ONSUCCESS(); 
                      })
                      .catch((error)=>{
                        console.log("TemplatePage delete button failed catch", error);
                        
                      });
                    }
                  }}><i className="fas fa-trash"></i>
                </button>
              }
              {
                (INACTIVEBUTTON && (selectedItems.length > 0)) && 
                <button 
                  className={`sideButton button deleteButton ${selectedItems.length < 1 ? "disabled" : ""}`} 
                  onClick={()=>{
                    var confirmation = window.confirm(INACTIVEBUTTON.CONFIRMMESSAGE ? INACTIVEBUTTON.CONFIRMMESSAGE : "Are you sure you want to deactivate these?");
                    if (confirmation == true) {
                      var inputObj = {
                        mutation: API_CALLS[INACTIVEBUTTON.NAME].MUTATIONS.deactivateList(),
                        variables: {
                          ids: selectedItems
                        }
                      }
                      window.Client.mutate(inputObj)
                      .then((obj) => {
                        var {data, loading} = obj;
                        window.GlobalUtil.triggerEvent("SelectedListEvent", []);
                        if(window.CurRefetchList) window.CurRefetchList(true);
                        if(INACTIVEBUTTON.ONSUCCESS) INACTIVEBUTTON.ONSUCCESS(); 
                      })
                      .catch((error)=>{
                        console.log("TemplatePage delete button failed catch", error);
                        
                      });
                    }
                  }}><i className="fas fa-trash"></i>
                </button>
              }
              {
                (DELETEBUTTON && (selectedItems.length > 0)) && 
                <button 
                  className={`sideButton button cloneButton ${selectedItems.length < 1 ? "disabled" : ""}`} 
                  onClick={()=>{
                    var confirmation = window.confirm(CLONEBUTTON.CONFIRMMESSAGE ? CLONEBUTTON.CONFIRMMESSAGE : "Are you sure you want to clone this?");
                    if (confirmation == true) {
                      var inputObj = {
                        mutation: API_CALLS[CLONEBUTTON.NAME].MUTATIONS.cloneList(),
                        variables: {
                          ids: selectedItems
                        }
                      }
                      //console.log(`inputObj`,"\n\n",inputObj,"\n\n");
                      window.Client.mutate(inputObj)
                      .then((obj) => {
                        var {data, loading} = obj;
                        window.GlobalUtil.triggerEvent("SelectedListEvent", []);
                        if(window.CurRefetchList) window.CurRefetchList(true);
                        if(CLONEBUTTON.ONSUCCESS) CLONEBUTTON.ONSUCCESS(); 
                      })
                      .catch((error)=>{
                        console.log("TemplatePage clone button failed catch", error);
                        
                      });
                    } 
                  }}><i className="fas fas fa-copy"></i>
                </button>
              }
              <TableSettings />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;