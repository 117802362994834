//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Contact Page. Contact us for information on clear aligners.",
			"DESCRIPTION": "If you need to get in contact with Smiles Club, use this page. User our contact form or call us M-F.",
			"KEYWORDS": "Contact Form, Smiles Club, Smiles Contact, USA Company, Non-toxic Aligners, Toxic Free Braces, Hours of Operation, Braces alternative, Address, Phone Number, Invisible Aligners"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `
						<h1 class="sectionTitle">Contact Us</h1>
						<p>Thank you for considering Smiles Club for your journey to a confident smile! Our dedicated team is here to assist you with any questions, inquiries, or assistance you may need. We value your interest in our clear aligner solutions and are committed to providing you with exceptional support.</p>
						
					`
				},
				{	
					"type": "h3",
					"className": "sectionTitle3",
					"content": `Contact Information:`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-building"></i>
							<span class="title">Company:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-envelope"></i>
							<span class="title">Address:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-phone"></i>
							<span class="title">Phone Number:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-map-pin"></i>
							<span class="title">Location:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-clock"></i>
							<span class="title">Customer Support:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"content": `
						Our friendly customer support representatives are available to help you Monday through Friday, from 9 AM to 6 PM (GMT). Whether you have questions about our clear aligner treatment process, pricing, or payment options, our team is here to provide you with detailed and personalized information.
					`
				},
				{	
					"type": "p",
					"content": `Thank you for considering Smiles Club for your orthodontic needs. We look forward to assisting you on your path to a confident and radiant smile!
					`
				},
				
			],
			"Section2": [
				{
					"type": "div",
					"content": `
						<h2 class="sectionTitle2">Contact Form:</h2>
						<p>Feel free to use the contact form below to reach out to us. We value your privacy and assure you that any information you provide will be kept strictly confidential. Please allow us up to 24 hours to respond to your inquiry.</p>
					`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Pagina de contacto. Póngase en contacto con nosotros para obtener información sobre los alineadores transparentes.",
			"DESCRIPTION": "Si necesita ponerse en contacto con Smiles Club, utilice esta página. Utilice nuestro formulario de contacto o llámenos de lunes a viernes.",
			"KEYWORDS": "Formulario de contacto, Club de Sonrisas, Contacto de Sonrisas, Sonrisas Club, Empresa de EE. UU., Alineadores no tóxicos, Frenos libres de tóxicos, Horario de atención, Alternativa de frenos, Dirección, Número de teléfono, Alineadores invisibles"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `
						<h1 class="sectionTitle">Contáctenos</h1>
						<p>¡Gracias por considerar a Sonrisas Club para su viaje hacia una sonrisa segura! Nuestro equipo dedicado está aquí para ayudarlo con cualquier pregunta, consulta o asistencia que pueda necesitar. Valoramos su interés en nuestras soluciones de alineadores transparentes y estamos comprometidos a brindarle un soporte excepcional.</p>
						
					`
				},
				{	
					"type": "h3",
					"className": "sectionTitle3",
					"content": `Información del contacto:`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-building"></i>
							<span class="title">Compañía:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-envelope"></i>
							<span class="title">Direccíon:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-phone"></i>
							<span class="title">Número de teléfono:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-map-pin"></i>
							<span class="title">Ubicación:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"className": "iconTitle",
					"content": `
						<h4>
							<i class="fas fa-clock"></i>
							<span class="title">Atención al cliente:</span>
						</h4>
					`
				},
				{
					"type": "div",
					"content": `
					Nuestros amables representantes de atención al cliente están disponibles para ayudarlo de lunes a viernes, de 9 a. m. a 6 p. m. (GMT). Ya sea que tenga preguntas sobre nuestro proceso de tratamiento con alineadores transparentes, precios u opciones de pago, nuestro equipo está aquí para brindarle información detallada y personalizada.
					`
				},
				{	
					"type": "p",
					"content": `Gracias por considerar Sonrisas Club para sus necesidades de ortodoncia. ¡Esperamos poder ayudarlo en su camino hacia una sonrisa segura y radiante!
					`
				},
				
			],
			"Section2": [
				{
					"type": "div",
					"content": `
						<h2 class="sectionTitle2">Formulario de contacto:</h2>
						<p>No dude en utilizar el siguiente formulario de contacto para comunicarse con nosotros. Valoramos su privacidad y le aseguramos que cualquier información que proporcione se mantendrá estrictamente confidencial. Permítanos hasta 24 horas para responder a su consulta.</p>
					`
				}
			]
		}
	}
};


	
export default PAGE;
