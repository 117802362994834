import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

export default class ImageUtils {
  constructor() {
    this.events = {};
  }

  
  onChangeImage(index, id, newImageObj, imageDBName, MUTATION_EDIT, curObj=window.CurObj, callBack=()=>{}){          
    var listArray = imageDBName.split(",");
    var baseObjString = listArray[0];          
    var CurImageArray = window.GlobalUtil.deepGetFromString(curObj, imageDBName, []);
    if(!newImageObj){
      CurImageArray.splice(index, 1);
    } else {
      var newPictures = {...newImageObj};
      CurImageArray[index] = newPictures;
    }  
    window.GlobalUtil.deepSetFromString(curObj, imageDBName, CurImageArray);
    var variables = {
      id: id,
      [baseObjString]: window.GlobalUtil.stripOutFromObj(window.GlobalUtil.deepGetFromString(curObj, baseObjString, ""), "__typename")
      //[baseObjString]: window.GlobalUtil.deepGetFromString(curObj, baseObjString, "")
    };
    window.Client.mutate({ //API UPDATE
      mutation: MUTATION_EDIT,
      variables: variables
    }) 
    .then((obj)=>{
      
      //if(window.setCurObj) window.setCurObj(window.CurObj);
      //if(window.CurRefetchEdit) window.CurRefetchEdit(); //RELOAD API FROM CACHE WITH NEW VALUES
      if(window.ReloadEditSideMenu) window.ReloadEditSideMenu()
      callBack();
    })
    .catch((error)=>{
      console.log("DDDDD error saving image");
      console.log(error,"\n\n");  
      callBack();
    });
  }


  onChangeFile(newFileList=[], id, imageDBName, MUTATION_EDIT, curObj=window.CurObj, callBack=()=>{}){
    curObj = window.GlobalUtil.stripOutFromObj(curObj, "__typename")
    window.GlobalUtil.deepSetFromString(curObj, imageDBName, newFileList)
    var listArray = imageDBName.split(",");
    var baseObjString = listArray[0];
    var variables = {
      id: id,
      [baseObjString]: window.GlobalUtil.stripOutFromObj(window.GlobalUtil.deepGetFromString(curObj, baseObjString, ""), "__typename")
    };
    //console.log("variables","\n\n",variables,"\n\n");
          
    window.Client.mutate({ //API UPDATE
      mutation: MUTATION_EDIT,
      variables: variables
    }) 
    .then((obj)=>{
      
      if(window.setCurObj) window.setCurObj(curObj);
      if(window.CurRefetchEdit) window.CurRefetchEdit(); //RELOAD API FROM CACHE WITH NEW VALUES
      if(window.ReloadEditSideMenu) window.ReloadEditSideMenu();
      callBack();
    })
    .catch((error)=>{
      console.log("error uploading file obj");
      console.log(error,"\n\n");  
      callBack();
    });
  }
}




