//import React from 'react';
const PAGE = [
  {
    "content": `Enviar`
  },
  {
    "content": "Oferta de plan de tratamiento"
  },
  {
    "content": "Cancelar"
  },
  {
    "content": "Enviar"
  },
  {
    "content": "Orden de actualización"
  },
  {
    "content": "Por favor espere"
  },
  {
    "content": "Crear oferta de plan de tratamiento"
  },
  {
    "content": "Número total de alineadores"
  },
  {
    "content": "Estimación del número total de alineadores que recibirá el cliente."
  },
  {
    "content": "Archivos de planes"
  },
  {
    "content": "Arrastra el PDF de la oferta y cualquier video aquí."
  },
  {
    "content": "Notas"
  },
  {
    "content": "Notas que le gustaría dejar al paciente sobre su plan de tratamiento."
  },
  {
    "content": "Guardando"
  },
  {
    "content": "Por favor espere"
  },
  {
    "content": "Cerrar"
  },
  {
    "content": "Guardar"
  },
  {
    "content": "Enviar"
  }
];




  
export default PAGE;