//import React from 'react';
const PAGE = [
  {
    "content": "Guardar Todo"
  },
  {
    "content": "Guardando"
  },
  {
    "content": "Guardado"
  }
];




  
export default PAGE;