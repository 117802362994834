//import React from 'react';
const PAGE = [
  {
    "content": `Send Back`
  },
  {
    "content": "Reject Treatment Plan"
  },
  {
    "content": "Cancel"
  },
  {
    "content": "Submit"
  },
  {
    "content": "Updating Order"
  },
  {
    "content": "Please stand by"
  },
  {
    "content": "Reject this Treatment Plan"
  },
  {
    "content": "Reason for Rejection"
  },
  {
    "content": "Tell the lab why are we rejecting this task."
  },
  {
    "content": "Close"
  },
  {
    "content": "Saving"
  },
  {
    "content": "Reject"
  }
];




  
export default PAGE;