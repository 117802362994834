import React from 'react';
import 'styles/scss/website/pages/mouthMoving.scss';

import step1 from 'global/img/candidateQuiz/mouthMovingFrames/Step_01.png';
import step2 from 'global/img/candidateQuiz/mouthMovingFrames/Step_02.png';
import step3 from 'global/img/candidateQuiz/mouthMovingFrames/Step_03.png';
import step4 from 'global/img/candidateQuiz/mouthMovingFrames/Step_04.png';
import step5 from 'global/img/candidateQuiz/mouthMovingFrames/Step_05.png';
import step6 from 'global/img/candidateQuiz/mouthMovingFrames/Step_06.png';
import step7 from 'global/img/candidateQuiz/mouthMovingFrames/Step_07.png';
import step8 from 'global/img/candidateQuiz/mouthMovingFrames/Step_08.png';


class Index extends  React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: 0
    };    
  }
  
  componentDidMount(){
    this.slowlyMoveAnimation();
  }

  componentWillUnmount(){
  }

  handleMinChange = e => {
    var value = e.target.value;
    value = Math.max(value, 0);
    value = Math.min(value, 100);
    this.setState({
      sliderValue: value
    });
  }

  slowlyMoveAnimation = () => {
    clearTimeout(this.slowlyMoveAnimationMoving)
    this.slowlyMoveAnimationMoving = setTimeout(()=>{
      var {sliderValue, forward} = this.state;
      var value = sliderValue+(forward ? .3 : -.3);
      value = Math.max(value, 0);
      value = Math.min(value, 100);
      if(value === 0) forward = true;
      if(value === 100) forward = false;
      this.setState({
        sliderValue: value,
        forward: forward
      }, ()=>{
        if(value === 0){ //ONCE IT REACHES THE END DELAY FOR A LITTLE BEFORE STARTING AGAIN
          this.delayThenRestart(2000)
        } else this.slowlyMoveAnimation()
      });
    },10)
  }

  resetMovement = () => {
    clearTimeout(this.slowlyMoveAnimationMoving)
    this.setState({
      forward: true
    })
  }
  

  delayThenRestart = (delayTime) => {
    clearTimeout(this.slowlyMoveAnimationMoving)
    this.slowlyMoveAnimationMoving = setTimeout(this.slowlyMoveAnimation, delayTime);
  }


  render(){
    var {sliderValue} = this.state;
    var incriments = (100/8);
    return(
      <div id="MouthMoving">
        <div className="stepImagesWrapper">
          <div className="stepImages">
            <img 
              className={`stepImage step1 ${(sliderValue < incriments) ? "active" : ""}`}
              src={step1} 
              alt={"Clear Aligner Step 1 mouth moving aimation"} 
            />
            <img 
              className={`stepImage step2 ${((sliderValue >= (incriments * 1)) && (sliderValue < (incriments * 2))) ? "active" : ""}`}
              src={step2} 
              alt={"Clear Aligner Step 2 mouth moving aimation"} 
            />
            <img 
              className={`stepImage step3 ${((sliderValue >= (incriments * 2)) && (sliderValue < (incriments * 3))) ? "active" : ""}`}
              src={step3} 
              alt={"Clear Aligner Step 3 mouth moving aimation"} 
            />
            <img 
              className={`stepImage step4 ${((sliderValue >= (incriments * 3)) && (sliderValue < (incriments * 4))) ? "active" : ""}`}
              src={step4} 
              alt={"Clear Aligner Step 4 mouth moving aimation"} 
            />
            <img 
              className={`stepImage step5 ${((sliderValue >= (incriments * 4)) && (sliderValue < (incriments * 5))) ? "active" : ""}`}
              src={step5} 
              alt={"Clear Aligner Step 5 mouth moving aimation"} 
            />
            <img 
              className={`stepImage step6 ${((sliderValue >= (incriments * 5)) && (sliderValue < (incriments * 6))) ? "active" : ""}`}
              src={step6} 
              alt={"Clear Aligner Step 6 mouth moving aimation"} 
            />
            <img 
              className={`stepImage step7 ${((sliderValue >= (incriments * 6)) && (sliderValue < (incriments * 7))) ? "active" : ""}`}
              src={step7} 
              alt={"Clear Aligner Step 7 mouth moving aimation"} 
            />
            <img 
              className={`stepImage step8 ${(sliderValue >= (incriments * 7)) ? "active" : ""}`}
              src={step8} 
              alt={"Clear Aligner Step 8 mouth moving aimation"} 
            />
          </div>
          <div className="behindPicture">
            <div className="behindPictureColor"></div>
          </div>
        </div>
        <div className="sliderBar">
          <div className="loadedDarker" style={{"width": `${sliderValue}%`}}></div>
          <input className="sliderBarLoader" type="range" min="0" max="100" step={.0001} value={sliderValue} 
            onChange={this.handleMinChange} 
            onMouseDown={this.resetMovement}
            onMouseUp={()=>this.delayThenRestart(4000)}
            onMouseOver={()=>clearTimeout(this.slowlyMoveAnimationMoving)}
            onMouseOut={()=>this.delayThenRestart(500)}
          /> 
        </div>
      </div>
    )
  }
}

export default Index;