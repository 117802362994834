import React from 'react';

const Index = (props)=>{
  return(
    <div id="HeaderImage" className={`${props.right ? 'right' : ''}`}>
      <div className="headerImageWrapper">
        <div className="img">
          <img 
            src={props.img} 
            alt={props.alt} 
          />
        </div>
        <div className="behindPicture">
          <div className="behindPictureColor"></div>
        </div>
      </div>
    </div>
  )
}

export default Index;