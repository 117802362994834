import React, { useState, useRef } from 'react';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//.setHours(0,0,0,0)
class CompDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zIndex: 1
    }
    //this.extraCheck = (newValue)=>{return((!newValue || !newValue.name || !newValue.url) ? false : true)}
    this.Validate = this.Validate.bind(this);
  }

  componentDidMount(){
    this.Validate(this.props.value);
  }


  Validate(newValue){
    var {field={}} = this.props;
    var {required, name=""} = field;
    if(!required){
      this.props.onChangeValid(name, true);
      return;
    }

    var valid = true;
    if(!newValue) valid = false;
    if(valid && this.extraCheck) valid = this.extraCheck(newValue)
    this.props.onChangeValid(name, valid);
  }


  render(){
    var {zIndex} = this.state;
    var {field={}, id, value, onChange, onTouch, meta} = this.props;
    var {name="", required, title, options=[], add, accept, def, showTimeSelect, customTimeFormat, startOfDay} = field;
    if(value) value = new Date(Number(value)) 
    return(
      <div>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="" style={{...STYLES.datePickerWrapper, "zIndex":zIndex}}>
          {
            !value && 
            <div className="nothingSelected" style={STYLES.nothingSelected} onClick={()=>{
              var newTime = new Date();
              this.props.onChange(name, newTime.getTime());
            }}><span>{(window.Session.Language === "ENGLISH") ? "Click to Select" : "Clic para seleccionar"}</span></div>
          }
          <DatePicker
            selected={(!value ? new Date() : value)}
            onSelect={(newValue)=>{
              if(required) this.Validate(newValue);
            }} //when day is clicked
            showTimeSelect={showTimeSelect ? showTimeSelect : false}
            dateFormat={customTimeFormat ? customTimeFormat :  (showTimeSelect ? "Pp" : "MMMM d, yyyy")}
            className="form-control "
            onChange={(newValue)=>{
              var newTime = (newValue ? newValue.getTime() : undefined);
              if(startOfDay && newTime) { //SET DATE TIME TO START OF DAY
                newTime = new Date(newTime)
                newTime = newTime.getStartOfDay().getTime()
              }
              this.props.onChange(name, newTime);
              if(required) this.Validate(newTime);
            }} //only when value has changed
            onCalendarClose={()=>{
              this.setState({zIndex: 1})
            }}
            onCalendarOpen={()=>{
              this.setState({zIndex: 1000})
            }}
          />
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


const STYLES = {
  imageWrapper: {
    "display": "flex", 
    "alignItems": "center", 
    "margin": "0 -1%",
    "flexWrap": "wrap"
  },
  tempStyles: {
    "height": "auto",
    "display": "block",
    "padding": "15px 10px",
    "margin": "0 0 15px 0",
    "borderColor": "#ddd"
  },
  imageDiv: {
    "maxWidth":"100%",
    "minWidth":"100%",
    "border":"1px solid #ddd",
    "margin":"0 1% 30px",
    "position":"relative",
  },
  deleteDiv: {
    "background": "red",
    "width": "30px",
    "height": "30px",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "position": "absolute",
    "right": "0px",
    "top": "0px",
    "cursor": "pointer"
  },
  deleteIcon: {
    "color": "#FFF"
  },
  datePickerWrapper: {
    "position": "relative"
  },
  nothingSelected: {
  }
}

export default CompDatePicker;