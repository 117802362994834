const PAGE = {
	"ENGLISH": {
	  "HEADER": {
		  "BASEURL": "https://smiles.club",
		  "TITLE": "Smiles Club | Purchase your Full Clear Aligners Kit Here! No risk invisible aligners",
		  "DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Purchase invisible orthodontics without BPA here.",
		  "KEYWORDS": "Teeth straightening at home, affordable, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
	  },
	  "CONTENT": {
			"PageStoreLink": [
				{
					"content": `store`
				}
			],
			"PageHeader": [
				{
					"type": "div",
					//"className": "d-none",
					"content": `
					<div class="preHeader">Products</div>
						<h1 class="sectionTitle">Store</h1>
						<div class="">Click on the product to see it's full description and what it includes.</div>
						<br/>
					`
				}
			],
			"Product": [
				{
					"content": `Cost`
				},
				{
					"content": `You Save`
				},
				{
					"content": `Go to Product`
				}
			]
	  }
  },
  "ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | ¡Adquiere aquí tu kit completo de alineadores transparentes! Alineadores invisibles sin riesgo",
			"DESCRIPTION": "Sonrisas Club proporciona un enderezamiento fácil y hermoso de los dientes desde casa. Compra ortodoncia invisible sin BPA aquí.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"PageStoreLink": [
				{
					"content": `tienda`
				}
			],
			"PageHeader": [
				{
					"type": "div",
					//"className": "d-none",
					"content": `
						<div class="preHeader">Productos</div>
						<h1 class="sectionTitle">Tienda</h1>
						<div class="">Haga clic en el producto para ver su descripción completa y lo que incluye.</div>
						<br/>
					`
				}
			],
			"Product": [
				{
					"content": `Costo`
				},
				{
					"content": `Guardas`
				},
				{
					"content": `Ir al producto`
				}
			]
		}
	}
};


  
export default PAGE;
