//import React from 'react';
const PAGE = [
  {
    "content": `Fill out to finish your Treatment Plan Request`
  },
  {
    "content": `Please submit each of the following pictures, and write anything you want on the "Chief Complaint". This will give us all the information we need to create your personal dental aligner plan.`
  },


  {
    "type": "div",
    "content": `<div><strong>Picture 1:</strong> Serious Selfy</div>`
  },
  {
    "content": `Take a selfy from the front without smiling. Follow the shown example picture.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Picture 2:</strong> Smiling Selfy</div>`
  },
  {
    "content": `Take a selfy from the front without smiling. Follow the shown example picture.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Picture 3:</strong> Side Profile</div>`
  },
  {
    "content": `Take a selfy from the side without smiling. Follow the shown example picture.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Picture 1:</strong> Teeth Front</div>`
  },
  {
    "content": `Take a selfy with the mouth expander showing the FRONT of your teeth while bitting down.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Picture 2:</strong> Teeth Left</div>`
  },
  {
    "content": `Take a selfy with the mouth expander showing the LEFT SIDE of your teeth while bitting down.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Picture 3:</strong> Teeth Right</div>`
  },
  {
    "content": `Take a selfy with the mouth expander showing the RIGHT SIDE of your teeth while bitting down.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Picture 4:</strong> Teeth Top</div>`
  },
  {
    "content": `Take a selfy with the mouth expander showing the TOP of your teeth with your mouth open.`
  },
  {
    "type": "div",
    "content": ` <div><strong>Picture 5:</strong> Teeth Bottom</div>`
  },
  {
    "content": `Take a selfy with the mouth expander showing the BOTTOM of your teeth with your mouth open.`
  },
  



  {
    "content": `Chief Complaint`
  },
  {
    "content": `What is your main complaint with your smile? Is it a specific group of teeth that are bothering you, or something more general? Please feel free to be as detailed as you like.`
  }
];




	
export default PAGE;