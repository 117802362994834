import React from 'react';
import Step1 from 'global/img/infoVectors/Step1.jpg';
import Step4 from 'global/img/infoVectors/Step4.jpg';
import Step5 from 'global/img/infoVectors/Step5.jpg';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1
    };    
  }

  componentDidMount(){
    //this.startScroller()
    document.querySelector("#SimpleStepsList").addEventListener("scroll", this.slideFinished)
    document.querySelector("#SimpleStepsList").addEventListener("mousedown", this.pauseScroll)
    document.querySelector("#SimpleStepsList").addEventListener("mouseup", this.continueScroll)
    document.querySelector("#SimpleStepsList").addEventListener("touchstart", this.pauseScroll)
    document.querySelector("#SimpleStepsList").addEventListener("touchend", this.continueScroll)
    
  }

  componentWillUnmount(){
    clearTimeout(this.isSwitchingScroll); 
    clearTimeout(this.isScrolling);        
    document.querySelector("#SimpleStepsList").removeEventListener("scroll", this.slideFinished)
    document.querySelector("#SimpleStepsList").removeEventListener("mousedown", this.pauseScroll)
    document.querySelector("#SimpleStepsList").removeEventListener("mouseup", this.continueScroll)
    document.querySelector("#SimpleStepsList").removeEventListener("touchstart", this.pauseScroll)
    document.querySelector("#SimpleStepsList").removeEventListener("touchend", this.continueScroll)
  }

  scrollSetup = ()=>{
    this.isPaused = true;
    if(this.isSwitchingScroll !== null) {
      clearTimeout(this.isSwitchingScroll);        
    }
  };

  pauseScroll = ()=>{
    this.isPaused = true;
    if(this.isSwitchingScroll !== null) {
      clearTimeout(this.isSwitchingScroll);        
    }
  };

  continueScroll = ()=>{
    this.isPaused = false;
    this.startScroller()
  };

  startScroller = () => {
    if(this.isSwitchingScroll !== null) {
      clearTimeout(this.isSwitchingScroll);        
    }
    this.isSwitchingScroll = setTimeout(() => {
      //var {activeStep} = this.state;
      const Selected = document.querySelector("#SimpleStepsList");
      const BaseWidth = Selected.childNodes[0].scrollWidth;

      // var FirstChild = Array.from(Selected.childNodes).shift()
      // //Selected.removeChild(FirstChild);
      // Selected.appendChild(FirstChild);


      // if(activeStep === 3) activeStep = 0;
      // activeStep++;
      var newLeft = Selected.scrollLeft+(2*(BaseWidth/3));
      if(newLeft > BaseWidth*2) newLeft = 0;
      Selected.scrollTo({
        top: 0,
        left: newLeft,
        behavior: 'smooth'
      });
    }, 3000);
  }
  
  slideFinished = (e) => {
    if(this.isSwitchingScroll !== null) {
      clearTimeout(this.isSwitchingScroll);        
    }
    if(this.isScrolling !== null) {
        clearTimeout(this.isScrolling);        
    }
    this.isScrolling = setTimeout(() => {
      //const Selected = document.querySelector("#SimpleStepsList");
      //var activeStep = 1;
      //const BaseWidth = Selected.childNodes[0].scrollWidth;
      //const CurrentScrollWidth = Selected.scrollLeft;
      if(!this.isPaused){
        this.startScroller();
      }
    }, 150);
  }

  stepSlider = () => {
    
  }
  

  render(){    
    const {CONTENT} = this.props
    
    return (     
      <div id="SimpleStepsList" className="containerSection">
        <div className="stepWrapper">
          <div className="step">
            <div className="image">
              <img 
                src={Step1} 
                alt="women researching clear aligners on her laptop"
              />
            </div>
            <div className="textWrapper">
              {CONTENT[0]}
            </div>
          </div>
        </div>
        <div className="stepWrapper">
          <div className="step">
            <div className="image">
              <img 
                src={Step4} 
                alt="women researching clear aligners on her laptop"
              />
            </div>
            <div className="textWrapper">
              {CONTENT[1]}
            </div>
          </div>
        </div>
        <div className="stepWrapper">
          <div className="step">
            <div className="image">
              <img 
                src={Step5} 
                alt="women getting clear aligners at her home"
              />
            </div>
            <div className="textWrapper">
              {CONTENT[2]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;