import React, { useState, useRef, forwardRef } from 'react';
import {  Routes, Route, Navigate } from "react-router-dom";
import TemplateHeader from "../templateHeader/index";
import TemplateList from "../templateLists/index";
import TemplateReports from "../templateReports1/index";
import API_CALLS from 'API_CALLS/index';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;

class Index extends React.Component {
  constructor(props) {
    super(props); 
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    var {ID, data, API_CALLS_FULL, REDIRECT, REDIRECT_ARRAY, REPORTS, PAGES, ValidateObject} = this.props;    
    //console.log('PAGES', PAGES);
    
    return (
      <div id={ID}>
        {
          REPORTS && 
          <TemplateReports
            reports={REPORTS}
          />
        }
        <Container className="templateListWrapper" fluid="true">
          <Row className="">
            <Column className="">
              <Routes> 
                {
                  PAGES.map((page, index)=>{
                    var path = `${page.Path}`;
                    if(page.SubPaths) return (
                      page.SubPaths.map((subPage, index2)=>{                        
                        let PATHS = [`${path}/${subPage.Path}/:ACTIVEITEM`, `${path}/${subPage.Path}`];
                        return PATHS.map((singlePath, index3)=>{
                          const id = `${index}${index2}${subPage.id}`;
                          return(
                            <Route 
                              key={`Route${id}`}
                              path={singlePath} 
                              loader={({ params }) => {
                                //console.log('params', params);         
                                return {...params}
                              }}
                              element={
                                <TemplatePageLayout
                                  {...{...this.props, ...subPage}}
                                  key={`TemplatePageLayout${id}`}
                                  ValidateObject={ValidateObject}
                                  page={subPage}
                                />
                              } 
                            />
                          )
                        })
                      })
                    )

                    let PATHS = [`${path}/:ACTIVEITEM`, path];                    
                    return PATHS.map((singlePath, index3)=>{    
                      const id = `${index}${index3}${page.id}`;
                      //console.log('id', id);                  
                      //console.log('singlePath', singlePath);
                      
                      return(
                        <Route 
                          key={`Route${id}`}
                          path={singlePath} 
                          loader={({ params }) => {
                            //console.log('params', params);
                            window.GlobalUtil.triggerEvent("PageChangedEvent", page);             
                            return {...params}
                          }}
                          element={
                            <TemplatePageLayout
                              {...{...this.props, ...page}}
                              key={`TemplatePageLayout${id}`}
                              page={page}
                              ValidateObject={ValidateObject}
                            />
                          } 
                        />
                      )
                    })
                  })
                }
                {
                  (REDIRECT_ARRAY && (REDIRECT_ARRAY.length > 0)) && 
                  REDIRECT_ARRAY.map((REDIRECT_OBJ, index)=>{
                    return(
                      <Route 
                        key={index} 
                        path={REDIRECT_OBJ.path}
                        element={<Navigate to={REDIRECT_OBJ.to} replace />}
                      />
                    )
                  })
                }
                {
                  REDIRECT && 
                  <Route path={REDIRECT.path}
                    element={<Navigate to={REDIRECT.to} replace />}
                  />
                }
              </Routes>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}





class TemplatePageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSlideIn: false,
      query: "",
      argument: ""
    };    
    this.TemplateListRef = React.createRef();
  }

  componentDidMount(){
    window.GlobalUtil.triggerEvent("PageChangedEvent", this.props.page);
    //console.log('componentDidMount TemplatePageLayout');
  }

  componentWillUnmount(){
  }

  standardButtons = () => {
    var {BUTTONS=[], EDITBUTTON} = this.props;
    var NewButton = [];
    if(EDITBUTTON){
      NewButton.push({       
        category: "Edit",
        width: "40px",
        buttonText: "",//"Edit",
        className: "button",
        iconClass: "fas fa-edit",
        onClick: (curObj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            ...EDITBUTTON, 
            TYPE: "EDIT",
            ID: curObj._id
          });
        }
      });
    }
    return([...NewButton, ...BUTTONS]);
  }

  onSearch = (argument, query) => {
    this.setState({query: query, argument: argument});
    if(this.TemplateListRef) this.TemplateListRef.updateQuery(argument, query)
  }

  render(){
    var {showSlideIn, argument, query} = this.state;
    var {LIST_HEADER_FIELDS, ValidateObject, ActionSlideIn, VARIABLES, TITLE, DATA_FIELD, BUTTONS=[], NEWBUTTON, id, SHOWSELECTBOXES, SUBTITLE} = this.props;
    var BUTTONS = this.standardButtons();
          
    //FORM_FIELDS,
    return (
      <div className="">
        <TemplateHeader
          TITLE={TITLE}
          SUBTITLE={SUBTITLE}
          NEWBUTTON={NEWBUTTON}
          DELETEBUTTON={this.props.DELETEBUTTON}
          INACTIVEBUTTON={this.props.INACTIVEBUTTON}
          CLONEBUTTON={this.props.CLONEBUTTON}
          SearchBox={()=><SearchBox
            LIST_HEADER_FIELDS={LIST_HEADER_FIELDS.filter(obj=>obj.searchable)}
            query={query}
            argument={argument}
            onSearch={this.onSearch}
          />}
        />
        <TemplateList
          ref={this.TemplateListRef}
          PAGEID={id}
          VARIABLES={VARIABLES}
          ACTIVEITEM={window.GlobalUtil.deepGetFromString(this, 'props,match,params,ACTIVEITEM', undefined)}
          LIST={{
            "dataField": DATA_FIELD,
            "QUERY": (this.props.API_CALLS ? this.props.API_CALLS.QUERY : null),
            "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS
          }}
          SHOWSELECTBOXES={SHOWSELECTBOXES}
          BUTTONS={BUTTONS.map(obj=>{
            if(obj.onClick) return obj;
            obj.onClick=(planToMake)=>{
              this.setState({showSlideIn: planToMake})
            }
            return obj;
          })}
          query={query}
        />
        {
          showSlideIn && 
          <ActionSlideIn
            curObj={showSlideIn}
            API_CALLS={this.props.API_CALLS}
            onClose={()=>{
              this.setState({showSlideIn: false});
            }}
          />
        }
      </div>
    );
  }
}





class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: (this.props.query ? this.props.query : ""),
      argument: (this.props.argument ? this.props.argument : "")
    };    
  }

  componentDidMount(){
  }

  componentWillUnmount(){          
  }

  onChangeQuery = (e) => {
    this.setState({query: e.target.value})
  }

  onChangeArg = (e) => {
    this.setState({argument: e.target.value})
  }

  onSubmit = () => {
    this.props.onSearch(this.state.argument, this.state.query) 
  }

  render(){
    var {LIST_HEADER_FIELDS} = this.props;
    var {query, argument} = this.state;
    if(!LIST_HEADER_FIELDS.length) return null;
    return (
      <div id="SearchBox">
        <form action="" className="SearchBoxForm" onSubmit={(e)=>{
          e.preventDefault();
          this.onSubmit()
        }} >{/*onKeyPress={(event)=>{if(!disableKeySubmit && event.key === 'Enter') this.onSubmit()}}*/}
          <select 
            type="select" 
            className={`searchArg`}
            value={argument}
            name={"argument"}
            onChange={this.onChangeArg}>
            <option value={""} disabled>Search By</option>
            {
              LIST_HEADER_FIELDS.length > 0 && 
              LIST_HEADER_FIELDS.map((object, index)=>{
                return(
                  <option key={index} value={object.path}>{object.name}</option>
                )
              })
            }
          </select>
          <input className={`searchQuery`} type="text" value={query} onChange={this.onChangeQuery} />
          <button className={`searchButton`} type={"submit"}><i className="fas fa-search" /></button>
        </form>
      </div>
    );
  }
}



export default Index;