//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Invisible aligners we'll refund you if you don't qualify. There's no obligation or commitment",
			"DESCRIPTION": "Smiles Club provides clear orthodontics at no obligation or commitment. Money back for those who are not candidates. You have nothing to lose",
			"KEYWORDS": "Smiles Club, Smiles Aligners, Clear retainers, affordable ortho, clear braces, invisible ortho, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Invisalign alternative"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `<h1 class="sectionTitle">Guarantees</h1>`
				},
				{
					"type": "div",
					"content": `
						<div class="basicText">You have nothing to lose. We'll refund you if you are not a candidate and there is no obligation or commitment from your part after receiving and reviewing our proposed treatment.</div>
						<div class="basicText">If you must pause the treatment, continue using the last aligner until you want to resume, and we'll accommodate. No penalties.</div>
						<div class="basicText">If our orthodontist determines that you are not a good candidate, we'll refund the cost of your impression kit.</div>
						<div class="basicText">If you are not happy with your treatment within the first month, we'll refund your cost.</div>
						<h2 class="sectionTitle2">Aligners Treatment</h2>
						<div class="basicText">To make your treatment plan a complete success please:<br/>
						<ul>
							<li> Wear your aligners every single day for at least as directed.</li>
							<li> Upload photos to your account section at the indicated check-in times. Simply snap a photo while not wearing your aligners of your upper teeth and another of your lower teeth. These are just like the photos you snapped and sent in with your Impression Kit!</li>
							<li> Keep all of your aligners until your treatment is complete and you have received your retainers. This is necessary because you may be directed to go back to a previous aligner if certain issues arise during your treatment or if you lose or break an aligner.</li>
						</ul>
						</div>
						<div class="basicText">If at the end of your treatment plan, having followed these guidelines, you aren't happy contact us so Smiles Club' care group can re-evaluate your results. If your re-evaluation is approved, you may be eligible for additional aligners. Please contact us within 15 days of your original prescribed treatment end date.</div>
						<div class="basicText">If you receive a product that is damaged let us know by sending an email to support@Smiles.club and we will replace it. For your safety, we do not accept returns.</div>
					`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
			"DESCRIPTION": "El programa de Sonrisas Club hace que sea fácil tener una hermosa sonrisa, sin la molestia de la cirugía o los frenos tradicionales.",
			"KEYWORDS": "Sonrisas Club, Sonrisa Club, Retenedores transparentes, ortopedia asequible, frenos transparentes, ortopedia invisible, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, la alternativa de Invisalign"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `<h1 class="sectionTitle">Garantías</h1>`
				},
				{
					"type": "div",
					"content": `
						<div class="basicText">No tienes nada que perder. Te reembolsaremos si no eres un candidato pare el tratamiento y no existe ninguna obligación o compromiso de tu parte después de recibir y revisar nuestro tratamiento propuesto.</div>
						<div class="basicText">Si debes pausar el tratamiento, continúa usando el último alineador hasta que desees reanudarlo y te acomodaremos. Sin penalizaciones.</div>
						<div class="basicText">Si nuestro Ortodoncista determina que no eres un buen candidato para el tratamiento, te reembolsaremos el costo del kit de impresión.</div>
						<div class="basicText">Si no estás satisfecho con tu tratamiento durante el primer mes, te reembolsaremos el costo.</div>
						<h2 class="sectionTitle2">Tratamiento de alineadores</h2>
						<div class="basicText">Para que tu plan de tratamiento sea un éxito total::<br/>
						<ul>
							<li> Usa tus alineadores todos los días según las indicaciones.</li>
							<li> UploadSube fotos a la sección de tu cuenta en los tiempos indicados. Simplemente toma una foto sin usar los alineadores de tus dientes superiores y otro de tus dientes inferiores. ¡Son como las fotos que tomaste y enviaste con su kit de impresión!</li>
							<li> KeepGuarda todos tus alineadores hasta que tu tratamiento esté completo y hayas recibido tus retenedores. Esto es necesario porque es posible que se te indique que vuelvas a un alineador anterior si surgen ciertos problemas durante tu tratamiento o si pierdes o rompes un alineador.</li>
						</ul>
						</div>
						<div class="basicText">Si al final de tu plan de tratamiento, después de haber seguido estas pautas, no está satisfecho, comunícate con nosotros para que el grupo de atención de Smiles Club pueda reevaluar tus resultados. Si se aprueba tu reevaluación, puede ser elegible para alineadores adicionales. Comunícate con nosotros dentro de los 15 días posteriores a la fecha de finalización del tratamiento.</div>
						<div class="basicText">Si recibes un producto que está dañado, avísanos enviando un correo electrónico a <a href="mailto:support@Smiles.club">support@Smiles.club</a> y lo reemplazaremos. Por tu seguridad, no aceptamos devoluciones.</div>
					`
				}
			]
		}
	}
};


	
export default PAGE;
