//import React from 'react';
const PAGE = [
  {
    //NUM 0
    "content": "¿Has olvidado tu contraseña?"
  },
  {
    //NUM 1
    "content": "Restablecer la contraseña"
  }
];




  
export default PAGE;