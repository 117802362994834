import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery, useMutation } from '@apollo/client';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import FormBuilder from 'global/components/FormBuilder/index';
import {
  APIProvider,
  useMapsLibrary
} from "@vis.gl/react-google-maps";

const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

const FORM_FIELDS = (CONTENT)=>{
  return(
    [ //defaultValuePath used to set default value form object
      // {
      //   title: <div className="formTitle"><span className="">{CONTENT[3]}</span></div>,
      //   subTitle: <div className="formSubTitle"><span className="">{CONTENT[4]}</span></div>,
      //   type: "text",
      //   name: "address,street",
      //   required: true,
      //   defaultValue: "",
      //   defaultValuePath: "address,street",
      //   errorMessage: CONTENT[5]
      // },
      {
        title: <div className="formTitle"><span className="">{CONTENT[6]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[7]}</span></div>,
        type: "text",
        name: "address,street2",
        required: false,
        defaultValue: "",
        defaultValuePath: "address,street2"
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[9]}</span></div>,
        type: "text",
        name: "address,city",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,city",
        errorMessage: CONTENT[10]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[11]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[12]}</span></div>,
        type: "selectState",
        name: "address,state",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,state",
        errorMessage: CONTENT[13]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[14]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[15]}</span></div>,
        type: "text",
        name: "address,country",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,country",
        errorMessage: CONTENT[16]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[17]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[18]}</span></div>,
        type: "text",
        name: "address,zipcode",
        maxNumChar: 5,
        required: true,
        defaultValue: "",
        defaultValuePath: "address,zipcode",
        errorMessage: CONTENT[19]
      }
    ]
  )
};






// class Index extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       updateIsValid: false,
//       curObj: this.props.curObj,
//       showAddress: true
//     };    
//   }

//   onSubmit = async() => {
//     var {curObj} = this.state;
//     await this.props.onUpdateUser(curObj, window.ForceUserReload);
//     return true;
//   }

//   onChangeAutoFill = (newValue) => {
//     var {curObj} = this.state;
//     const Address = newValue.formatted_address;
//     if(Address){      
//       const States = window.GlobalUtil.states();
//       var labelArray = Address.split(", ");
//       var stateAndZip = labelArray[2].split(" ");
//       curObj.address.street = labelArray[0];
//       curObj.address.city = labelArray[1];
//       curObj.address.country = labelArray[3];
//       curObj.address.state = States[stateAndZip[0]];
//       curObj.address.zipcode = stateAndZip[1];
//       this.setState({
//         curObj,
//         showAddress: false
//       }, ()=>{
//         // this.setState({
//         //   showAddress: true
//         // })
//         // window.GlobalUtil.DialogStack.closeTop()
//         // this.TriggerEdit.click();
//         //this.FormBuilder.onReload();
//       });
//     }
//   } 

//   render(){
//     var {updateIsValid, curObj, showAddress} = this.state;
//     const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);     
//     return(
//       <i className="fas fa-pencil-alt" ref={e=>this.TriggerEdit=e} onClick={(e)=>{
//         e.preventDefault();
//         window.GlobalUtil.triggerEvent("SlideInRightEvent", {
//           TITLE: this.props.CONTENT[0],
//           closeButtonText: this.props.CONTENT[1],
//           saveButtonText: this.props.CONTENT[2],
//           customClass: "frontEndSlideInRight shortSlideInRight",
//           width: "700px",
//           onSave: this.onSubmit,
//           children: (props)=>{                                      
//             return(
//               <div className="">
//                 {
//                   showAddress && 
//                   <React.Fragment>
//                     <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY}>
//                       <PlaceAutocomplete onPlaceSelect={this.onChangeAutoFill} defaultValue={(curObj && curObj.address) ? curObj.address.street : undefined} CONTENT={CONTENT}/>
//                     </APIProvider>
//                     <FormBuilder
//                       {...props} 
//                       ref={e=>this.FormBuilder=e}
//                       disableKeySubmit={true}
//                       initialValues={curObj}
//                       listOfFields={FORM_FIELDS(CONTENT)}
//                       onSubmit={this.onSubmit}
//                       onChange={newObj => this.setState({curObj: newObj})}
//                       onValidate={props.onChangeValid}
//                     />
//                   </React.Fragment>
//                 }
//               </div>
//             )
//           }
//         });
//       }} />
//     )
//   }
// }




const Index = props => {
  const [curObj, setCurObj] = useState(props.curObj);

  var onSubmit = async() => {
    await props.onUpdateUser(curObj, window.ForceUserReload);
    return true;
  }

  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);     
  return(
    <i className="fas fa-pencil-alt" onClick={(e)=>{
      e.preventDefault();
      window.GlobalUtil.triggerEvent("SlideInRightEvent", {
        TITLE: props.CONTENT[0],
        closeButtonText: props.CONTENT[1],
        saveButtonText: props.CONTENT[2],
        customClass: "frontEndSlideInRight shortSlideInRight",
        width: "700px",
        onSave: onSubmit,
        children: (props2)=>{                                      
          return(
            <EditAddress {...props} props2={props2} onSubmit={onSubmit} onChange={newObj=>setCurObj(newObj)}/>
          )
        }
      });
    }} />
  )
  
}



class EditAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateIsValid: false,
      curObj: this.props.curObj,
      showAddress: true
    };    
  }

  onChangeAutoFill = (newValue) => {
    var {curObj} = this.state;
    const Address = newValue.formatted_address;
    if(Address){
      //console.log('Address', Address);
      const States = window.GlobalUtil.states();
      var labelArray = Address.split(", ");
      var stateAndZip = labelArray[2].split(" ");
      curObj.address.street = labelArray[0];
      curObj.address.city = labelArray[1];
      curObj.address.country = labelArray[3];
      curObj.address.state = States[stateAndZip[0]];
      curObj.address.zipcode = stateAndZip[1];
      //console.log('curObj', curObj);
      
      this.props.onChange(curObj);
      this.setState({
        curObj,
        showAddress: false
      }, ()=>{
        this.setState({
          showAddress: true
        })
        // window.GlobalUtil.DialogStack.closeTop()
        // this.TriggerEdit.click();
        //this.FormBuilder.onReload();
      });
    }
  } 

  render(){
    var {updateIsValid, curObj, showAddress} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);     
    if(!showAddress) return <div className=""></div>;
    return(
      <div className="">
        <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY}>
          <PlaceAutocomplete onPlaceSelect={this.onChangeAutoFill} defaultValue={(curObj && curObj.address) ? curObj.address.street : undefined} CONTENT={CONTENT}/>
        </APIProvider>
        <FormBuilder
          {...this.props.props2} 
          ref={e=>this.FormBuilder=e}
          disableKeySubmit={true}
          initialValues={curObj}
          listOfFields={FORM_FIELDS(CONTENT)}
          onSubmit={this.props.onSubmit}
          onChange={newObj => {
            this.setState({curObj: newObj});
            this.props.onChange(newObj);
          }}
          onValidate={this.props.props2.onChangeValid}
        />
      </div>
    )
  }
}





const PlaceAutocomplete = ({ onPlaceSelect, defaultValue, CONTENT}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const [inputValue, setinputValue] = useState(defaultValue);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="form-group" style={{"marginBottom":"1.5rem"}}>
      <div>
        <div className="input-header">
          <div className="formTitle">
            <span style={{"fontWeight":"500","fontSize":"16px"}}>{CONTENT[3]}</span>
            <span style={{"color":"red","fontSize":"9px","paddingLeft":"10px","paddingTop":"6px"}}><i className="fas fa-star-of-life" style={{"fontSize":"7px"}}></i> <span style={{"paddingLeft":"4px"}}>REQUIRED</span></span>
          </div>
        </div>
        <input ref={inputRef} type="text" className="form-control" value={inputValue} onChange={e=>setinputValue(e.target.value)}/>
      </div>
    </div>
  );
};




export default Index;
      
